/*
useQueryParamsGen2 is a crack at managing structured data when stored/reflected in URL query params.
This is applicable to GET queries only, where things are sent via URL query params, instead of in payload
bodies (as you'd see for PUT/POSTs).

Picture the following payload:

  {
    id: 123,
    name: null,
    withIds: [1, 2],
    sort: {
      col: "test",
      dir: "ing"
    }
  }

  If this were sent via GET, the encoded URL would look something like this:
  https://www.test.com?id=123&name=null&withIds=%5B1,2%5Dsort=%7B%22col%22:%22test%22,%22dir%22:%22ing%22%7D

Looks aside, thats not going to work well at all when you try to "unparse" it: ie. null would be a string "null",
and all bets are off on the [] or nested {} types. In other words, URL query params kind of suck if used "as is".

--------------------------------------------------------------------------------------------

This solution fixes that ^ problem by standardizing how we use URL params. Instead of 1:1 (queryParamName:<value>),
we use a single, standardized query param (z), and store **base64 encoded, serialized JSON** on it. That sounds
like a lot, but here's all it is (using the same payload as above):

  stepA = JSON.stringify({
    id: 123,
    name: null,
    withIds: [1, 2],
    sort: {
      col: "test",
      dir: "ing"
    }
  })
  // stepA = '{"id":123,"name":null,"withIds":[1,2],"sort":{"col":"test","dir":"ing"}}'

  // then we take that serialized JSON string and base64 encode it...
  stepB = btoa(step1)
  // stepB = 'eyJpZCI6MTIzLCJuYW1lIjpudWxsLCJ3aXRoSWRzIjpbMSwyXSwic29ydCI6eyJjb2wiOiJ0ZXN0IiwiZGlyIjoiaW5nIn19'

  // then we set that back on the URL as the 'z' param:
  history.location.search = `?z={stepB}`

Now, if we reload the page, we simply reverse those steps and get a properly unmarshalled "POJO" object (in pseudocode below):

  zParam = history.location.search('z') // get this value into a variable: 'eyJpZCI6MTIzLCJuYW1lIjpudWxsLCJ3aXRoSWRzIjpbMSwyXSwic29ydCI6eyJjb2wiOiJ0ZXN0IiwiZGlyIjoiaW5nIn19'
  unbase64d = atob(zParam)
  jsonObj = JSON.parse(unbase64d)
  // jsonObj = <the payload above>; where name is ACTUALLY null, and withIds is ACTUALLY an array (of ints), etc etc

--------------------------------------------------------------------------------------------

Example use with a component:

  **Its presumed the 'data' value returned by this hook is **immediately stored into state in the component (eg. useState)**
  
  export default function MyComponent(props : any) {
    const {queryData, setQueryData} = useQueryParamsGen2()
    const [myData, setMyData] = useState({...queryData})

    // now, when 'myData' changes, we'd want that data reflected back into the URL, so wire up an effect...
    useEffect(() => {
      setQueryData(myData)
    }, [myData])
  }

And you're done. Now, if myData changes, its immediately reflected back onto the URL as the 'z' param, and
if you refresh the page, its reflected as 'myData'.

--------------------------------------------------------------------------------------------

Optional override function:

  While base64'ing saves a lot of headache, we still run into issues where we want to allow external applications
  to link back to specific pages, passing their own parameters. For example: if a Sigma dashboard needs to display
  a link to the Savings Review page, passing an Employer ID to use on page filters. NOTE: if your component doesn't
  need to honor external linking strategies, YOU SHOULD NOT USE THIS.

  In this case, other apps aren't going to base64 -> serializedJSON... so an optional 'overRides' function is 
  available for callers of this hook. If provided, the callback will receive **any other URL parameters** sent
  besides the 'z' param. This function would be in charge of determining whether the value passed in is valid,
  and if so, would return it in an object that WILL BE MERGED WITH THE OBJECT REPRESENTED BY THE z PARAM. In
  other words, the callback function would return an object that will have its properties merged with whatever
  was unmarshalled from 'z'.

  Taking a smaller example:
  https://whatever.com?z=eyJhIjoxLCJiIjoyfQ== // here, z represents: {a:1, b:2}

    // implementing component has:
    const {queryData, setQueryData} = useQueryParamsGen2()
    // queryData = {a:1, b:2}
    

  However, if the URL is:
  https://whatever.com?z=eyJhIjoxLCJiIjoyfQ==&b=999 // here, z represents: {a:1, b:2} again, but there's an additional query param

    // implementing component has:
    const {queryData, setQueryData} = useQueryParamsGen2((otherURLParams : any) : any => {
      const merger = {}
      if (otherURLParams.b && _.parseInt(otherURLParams.b) >= 0) {
        merger.b = _.parseInt(otherURLParams.b)
      }
      return merger
    })
    // queryData = {a:1, b:999}

  Its important to recognize that the 'merger' object in the callback above can return any fields it wants and it'll
  be merged into the 'queryData' value... which is not necessarily a good thing. For example, setting

      merger.employerId = 123
      // when you meant to set
      merger.EmployerID = 123
      // could result in: {EmployerID:null, employerId: 123}, and the rest of the app is using EmployerID

  will be a bad time. The override callback is intentionally flexible to allow arbitrary use cases by callers,
  but that makes it less safe too. Buyer beware.

--------------------------------------------------------------------------------------------

Quickly inspect whatever is stored and encoded in the 'z' query param:

  atob((new URLSearchParams(window.location.search))?.get('z'))

*/
import { useCallback, useEffect, useMemo, useState } from 'react'
import history from '../utils/history'

export const SCOPES = {
  EDIT_CONTACT_INFO: 'edit_contact_info',
}

interface opts {
  disabled?: boolean
  scope?: string | null
  overRides?: (otherURLParams: any) => any
}

const store = {
  _data: JSON.parse(
    atob(new URLSearchParams(window.location.search).get('z') || '') || '{}'
  ),
  setData(scopeKey: string, v: any, overWrite: boolean = false) {
    Object.assign(this._data, { [scopeKey]: v })
    setZParam(this._data, overWrite)
  },
  purge(scopeKey: string) {
    delete this._data[scopeKey]
    setZParam(this._data)
  },
}

history.listen((location: any, action: any) => {
  if (action === 'PUSH' || action === 'POP') {
    store._data = JSON.parse(
      atob(new URLSearchParams(location?.search).get('z') || '') || '{}'
    )
  }
})

export default function useQueryParamsGen2(opts?: opts): any {
  const [scopeKey] = useState<string>(opts?.scope || 'unscoped')
  const [bump, setBump] = useState<number>(0)

  // called once, immediately, and never again for the lifecycle of the hook
  useMemo(() => {
    if (!opts?.overRides || opts.disabled) return

    const otherURLParams = Object.fromEntries(
      new URLSearchParams(window.location.search)
    )
    // if z param is present, ignore it
    delete otherURLParams['z']
    if (Object.keys(otherURLParams).length === 0) return

    // hit the overrides function and see if we come back with anything
    const overs = opts.overRides(otherURLParams)
    if (!overs || !Object.keys(overs).length) return

    store.setData(scopeKey, overs, true)
  }, [scopeKey])

  // when the implementing component is unmounted, purge its' data from the store
  useEffect(() => {
    return () => {
      if (opts?.disabled) return
      store.purge(scopeKey)
    }
  }, [scopeKey])

  const queryData = useMemo(() => {
    if (opts?.disabled) return {}

    return new Proxy(store, {
      get(target, prop) {
        return target?._data?.[scopeKey]?.[prop]
      },
      has(target, prop) {
        return Reflect.has(target?._data?.[scopeKey] || {}, prop)
      },
      ownKeys(target) {
        return Reflect.ownKeys(target?._data?.[scopeKey] || {})
      },
      getOwnPropertyDescriptor(target, prop) {
        return {
          value: Reflect.get(target?._data?.[scopeKey] || {}, prop),
          enumerable: true,
          configurable: true,
        }
      },
    })
  }, [scopeKey])

  const setQueryData = useCallback(
    (v: any) => {
      if (opts?.disabled) return
      store.setData(scopeKey, v)
      setBump((x: number) => x + 1)
    },
    [scopeKey]
  )

  return useMemo(() => ({ queryData, setQueryData }), [bump])
}

function setZParam(data: any = {}, overWrite: boolean = false): void {
  if (overWrite) {
    history.replace({ search: `?z=${btoa(JSON.stringify(data))}` })
    return
  }

  const params = Object.fromEntries(new URLSearchParams(window.location.search))
  if (Object.keys(data).length === 0) {
    delete params['z']
  } else {
    params['z'] = btoa(JSON.stringify(data))
  }

  history.replace({ search: `?${new URLSearchParams(params).toString()}` })
}
