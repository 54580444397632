import React, { Component } from 'react'

// needs to be made generic if we want to use elsewhere.
export default function getCheckboxFormatter(propKey, customHandler, groupNr) {
  const formatter = (cell, row) => {
    const handler = (checked) => {
      row[propKey] = checked
      customHandler && customHandler(row, propKey, checked)
    }
    const enabled = row.GroupNr === groupNr

    return (
      <CheckboxFormatter active={cell} onCheck={handler} disabled={!enabled} />
    )
  }
  return formatter
}

class CheckboxFormatter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      checked: false,
    }
  }

  onCheckChange = (e) => {
    this.setState({ checked: e.target.checked })
    this.props.onCheck(e.target.checked)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ checked: nextProps.active })
  }

  render() {
    return (
      <input
        type="checkbox"
        checked={this.state.checked}
        onChange={this.onCheckChange}
        disabled={this.props.disabled}
      />
    )
  }
}
