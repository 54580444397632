import React, { useState, useEffect, useRef } from 'react'
import * as api from '../../../../../services/thezerocard/api-helper'
import DesignSuite2023 from '../../../../../components/DesignSuite2023'
import * as ClaimTable from '../../../Components/ClaimTable'
import { apiResponse } from './index'
import useErrorHandlers from '../../../../../hooks/useErrorHandlers'
// import useSnackbar from '../../../../../hooks/useSnackbar'
import { Typography, Button } from '@material-ui/core'
import { omit as _omit } from 'lodash'
import dateTime from '../../../../../utils/dateTime'

interface props {
  fixable: apiResponse
}

const omitFieldsOnBillingAddressDisplay = [
  // 'CreatedAt',
  'UUID',
  'RemittanceDisplayAddress',
  'RemittanceAddressKey',
]

export default function FixClaimBillingEntities({
  ...spread
}: props): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <button onClick={() => setIsOpen(true)}>Fix Claims</button>

      <DesignSuite2023.Drawer
        open={isOpen}
        width="80vw"
        onClose={() => setIsOpen(false)}>
        {isOpen && <RenderComponent {...spread} />}
      </DesignSuite2023.Drawer>
    </>
  )
}

function RenderComponent({ fixable }: props): React.ReactElement {
  const [involvedObservations, setInvolvedObservations] = useState<any>(null)
  const { catchAPIError } = useErrorHandlers()
  // const { showForDuration: showSnackbar } = useSnackbar()
  const refClaimsTable = useRef<any>(null)

  useEffect(() => {
    if (!fixable) return
    apiRouteGetInvolvedObservations({
      BillingEntity2ID: fixable.BillingEntity2ID,
    })
      .then((res: any) => {
        if (res.error) throw res
        setInvolvedObservations(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading billing entity report',
        })
      )
  }, [fixable])

  function onDoAssign() {
    apiRoutePutReassignBillingEntity({
      FromBillingEntity2ID: fixable.BillingEntity2ID,
      ToBillingEntity2ID: fixable.Current!.ID,
    })
      .then((res: any) => {
        if (res.error) throw res
        alert('OK!')
        refClaimsTable.current?.refresh?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading billing entity report',
        })
      )
  }

  return (
    <div style={{ padding: '1rem' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          columnGap: '1rem',
        }}>
        <div>
          <Typography variant="body2">
            These are the records observed on claims (what we received from
            providers):
          </Typography>
          {involvedObservations?.map((obs: any) => (
            <DesignSuite2023.StyledPre key={obs.Hash}>
              {JSON.stringify(obs, null, 2)}
            </DesignSuite2023.StyledPre>
          ))}
        </div>
        <div>
          <Typography variant="body2">
            This is the previous record in ZERO's system the claims below are
            currently tied to:
          </Typography>
          <DesignSuite2023.StyledPre>
            {JSON.stringify(
              _omit(
                fixable.PointInTimeRecord,
                omitFieldsOnBillingAddressDisplay
              ),
              null,
              2
            )}
          </DesignSuite2023.StyledPre>
          <span>
            Last Changed:{' '}
            {dateTime
              .parse(fixable.PointInTimeRecord.CreatedAt)
              .format(dateTime.formats.PrettyVerbose)}
          </span>
        </div>
        <div>
          <Typography variant="body2">
            This is the most recent record in ZERO's system that all the claims
            below will be updated to use:
          </Typography>
          <DesignSuite2023.StyledPre>
            {JSON.stringify(
              _omit(fixable.Current, omitFieldsOnBillingAddressDisplay),
              null,
              2
            )}
          </DesignSuite2023.StyledPre>
          <span>
            Last Changed:{' '}
            {dateTime
              .parse(fixable.Current!.CreatedAt)
              .format(dateTime.formats.PrettyVerbose)}
          </span>
          <Button onClick={onDoAssign} size="small" variant="outlined">
            Assign This Billing Entity
          </Button>
        </div>
      </div>

      <ClaimTable.Table
        ref={refClaimsTable}
        apiEndpoint={apiRouteCustomClaimsTableGet}
        passFilters={{
          CurrentlyAssignedBillingEntityID: fixable.BillingEntity2ID,
        }}
        initPageSize={100}
      />
    </div>
  )
}

function apiRouteCustomClaimsTableGet(params: any) {
  return api.search('/zclaims/billing_entity/fixables', params)
}

function apiRoutePutReassignBillingEntity(params: any) {
  return api.put('/zclaims/billing_entity/reassign', params)
}

function apiRouteGetInvolvedObservations(params: any) {
  return api.get('/zclaims/billing_entity/involved_observations', null, params)
}
