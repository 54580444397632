import utils from '../utils'
import dateTime from '../utils/dateTime'
const READ_PERMISSION = 'r:provider_prices'

const STATUS_DRAFT = 0
const STATUS_PRODUCTION = 1

const ComponentFeeSchedule = {
  editFields: {
    ID: 0,
    Name: '',
    Notes: '',
    StartDate: '',
    EndDate: '',
    Status: STATUS_DRAFT,
    StatusModifiedByUserID: null,
    Type: '',
  },
  validators: {
    Name: {
      isValid: (val) => {
        return val && val.length > 1
      },
      msg: 'Name is required',
    },
    StartDate: {
      isValid: (val) => dateTime.parse(val).isValid(),
      msg: 'Start Date is required',
    },
  },
  hasAccess: () => {
    return utils.checkPerms(READ_PERMISSION)
  },
}

export default ComponentFeeSchedule

export { STATUS_DRAFT, STATUS_PRODUCTION }
