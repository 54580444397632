import React, { useEffect, useState } from 'react'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Step as MUIStep,
  StepLabel as MUIStepLabel,
  Stepper as MUIStepper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { OrgActions } from '../../actions'
import { Organization as OrganizationType } from './types'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import dateTime from '../../utils/dateTime'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
import styled from 'styled-components'
import { renderSwitchField as RenderSwitchField } from '../../components/Inputs/standard'
import { getEmployers } from '../../actions/EmployerActions'
import { Autocomplete } from '@material-ui/lab'
import { Warning } from '@material-ui/icons'

const StyledDialogContent = styled(DialogContent)`
  min-height: 65vh;
  max-height: 65vh;
`

const EmployerAccessWarning = styled.div`
  background: #e5e5e5;
  border-radius: 8px;
  margin: 1rem 0;
  padding: 10px;
`
interface props {
  organizationID?: number | null
  onDone?(): void
  ButtonProps?: any
}

// all facilities, fee schedules, and price records are normalized on the backend into
// condensed objects with the following fields
interface entity {
  [index: string]: {
    ID: number
    Label: string
    Launch: string | null
    Term: string | null
  }
}

interface launchInfo extends OrganizationType {
  PracticeFacilityCount: number
  FeeScheduleCount: number
  FeeSchedulePriceCount: number
  Entities: {
    Facilities: entity[]
    FeeSchedules: entity[]
    Prices: entity[]
  }
  AcceptingReferrals: boolean
  AllEmployers: boolean
  EmployerIDs: number[]
}

interface launchStep {
  id: string
  step: number
  label: string
  component: React.FC<any>
  isComplete: () => boolean
  previousLabel: string
  previousAction: () => void
  nextLabel: string
  nextAction: () => void
}

const defaultPayload = {
  LaunchDate: null,
  TermDate: null,
  ProductionizeFeeSchedules: true,
  AcceptingReferrals: false,
  AllEmployers: false,
  EmployerIDs: [] as number[],
}
const defaultInputErrs = { LaunchDate: false, TermDate: false }

export default function DialogLaunchOrg({
  organizationID,
  onDone,
  ButtonProps,
}: props): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [activeStep, setActiveStep] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(false)
  const [launchInfo, setLaunchInfo] = useState<launchInfo | null>(null)
  const [payload, setPayload] = useState({ ...defaultPayload })
  const [viewed, setViewed] = useState<boolean[]>([false, false, false])
  const [allEmployers, setAllEmployers] = useState<any[]>([])
  const [selectedEmployers, setSelectedEmployers] = useState<any[]>([])
  const [openAccordion, setOpenAccordion] = useState<string | null>(null)
  const [inputErrs, setInputErrs] = useState<any>({ ...defaultInputErrs })
  const { catchAPIError } = useErrorHandlers()
  const { showDurationShort } = useSnackbar()

  const steps: launchStep[] = [
    {
      id: 'employer-access',
      step: 0,
      label: 'Employer Access',
      component: StepEmployerAccess,
      isComplete: () => viewed[0],
      previousLabel: 'Cancel',
      previousAction: () => setIsOpen(false),
      nextLabel: 'Next',
      nextAction: doStepForward,
    },
    {
      id: 'accepting-referrals',
      step: 1,
      label: 'Accepting Referrals',
      component: StepAcceptingReferrals,
      isComplete: () => viewed[1],
      previousLabel: 'Back',
      previousAction: doStepBackward,
      nextLabel: 'Next',
      nextAction: doStepForward,
    },
    {
      id: 'launch-date',
      step: 2,
      label: 'Launch Date',
      component: StepLaunchDate,
      isComplete: () => viewed[2] && payload.LaunchDate !== null,
      previousLabel: 'Back',
      previousAction: doStepBackward,
      nextLabel: 'Launch',
      nextAction: doLaunch,
    },
  ]

  useEffect(() => {
    getEmployers({
      sort: ['Employer.Name', 'asc'],
      filter: { NoLimit: true },
    })
      .then((res: any) => {
        setAllEmployers(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to fetch Employers; please contact Engineering',
        })
      )
  }, [organizationID])

  useEffect(() => {
    let newViewed = [...viewed]
    newViewed[activeStep] = true
    setViewed(newViewed)
  }, [activeStep])

  useEffect(() => {
    if (!isOpen) {
      return
    }
    // pre-clear all state before reloading
    setLaunchInfo(null)
    setViewed([true, false, false])
    setPayload({ ...defaultPayload })
    setInputErrs({ ...defaultInputErrs })
    load()
  }, [isOpen])

  useEffect(() => {
    if (payload.LaunchDate) {
      const v = dateTime.parse(payload.LaunchDate)
      setInputErrs((curr: any) => ({ ...curr, LaunchDate: !v.isValid() }))
    }
    if (payload.TermDate) {
      const v = dateTime.parse(payload.TermDate)
      setInputErrs((curr: any) => ({ ...curr, TermDate: !v.isValid() }))
    }
  }, [payload])

  function load() {
    setIsLoading(true)
    OrgActions.getOrgLaunch(organizationID)
      .then((data: launchInfo) => {
        setLaunchInfo(data)
        const launchIDs = new Set(data.EmployerIDs)
        setSelectedEmployers(allEmployers.filter((v) => launchIDs.has(v.ID)))
        setPayload({
          ...payload,
          AcceptingReferrals: data.AcceptingReferrals,
          AllEmployers: data.AllEmployers,
          EmployerIDs: [...data.EmployerIDs],
        })
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading org launch info...',
          withError(_: any) {
            setIsOpen(false)
          },
        })
      )
      .finally(() => {
        setIsLoading(false)
      })
  }

  function doLaunch() {
    for (let k in inputErrs) {
      if (inputErrs[k]) {
        showDurationShort(
          'Please fix any errors before continuing',
          SnackbarTypeError
        )
        return
      }
    }
    if (!payload.LaunchDate) {
      showDurationShort('LaunchDate is required', SnackbarTypeError)
      return
    }

    if (!allStepsComplete()) {
      showDurationShort(
        `Please complete all steps before launching`,
        SnackbarTypeError
      )
      return
    }

    OrgActions.putOrgLaunch(organizationID, {
      ...payload,
      LaunchDate: dateTime
        .parse(payload.LaunchDate)
        .format(dateTime.formats.ISODate),
      TermDate: dateTime
        .parse(payload.TermDate)
        .format(dateTime.formats.ISODate),
    })
      .then(() => {
        setIsOpen(false)
        showDurationShort(
          'Organization launch workflow succeeded!',
          SnackbarTypeSuccess
        )
        onDone && onDone()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed launching org...',
          withError(_: any) {
            setIsOpen(false)
          },
        })
      )
  }

  function doStepBackward() {
    setActiveStep(activeStep - 1)
  }

  function doStepForward() {
    if (!steps[activeStep].isComplete()) {
      showDurationShort(
        'Please complete the step before proceeding',
        SnackbarTypeError
      )
      return
    }

    setActiveStep(activeStep + 1)
  }

  function onOpenAccordion(panel: string): any {
    return (ev: React.SyntheticEvent, isExpanded: boolean) => {
      setOpenAccordion(isExpanded ? panel : null)
    }
  }
  function handleAllEmployersChange(value: boolean) {
    let newPayload = { ...payload, AllEmployers: value }

    let launchIDs = new Set(launchInfo?.EmployerIDs)
    let selectedEmployers = value
      ? []
      : allEmployers.filter((v) => launchIDs.has(v.ID))

    newPayload.EmployerIDs = selectedEmployers.map((v: any) => v.ID)

    setSelectedEmployers(selectedEmployers)
    setPayload(newPayload)
  }

  function allStepsComplete() {
    return steps.map((step) => step.isComplete()).every((v) => v)
  }

  if (!organizationID) {
    return null
  }

  const ActiveStepComponent = steps[activeStep].component

  return (
    <>
      <Tooltip title='Open the "Launch Workflow" dialog'>
        <Button
          {...ButtonProps}
          variant="outlined"
          onClick={() => {
            setIsOpen(true)
            setActiveStep(0)
          }}>
          Launch Org Workflow
        </Button>
      </Tooltip>

      <Dialog
        open={isOpen}
        maxWidth="md"
        fullWidth
        onClose={() => setIsOpen(false)}>
        {isLoading && <DialogContent>Loading...</DialogContent>}
        {!isLoading && launchInfo && (
          <>
            <StyledDialogContent>
              <MUIStepper activeStep={activeStep}>
                {steps.map((step, i) => {
                  return (
                    <MUIStep
                      key={step.id}
                      onClick={() => setActiveStep(i)}
                      completed={steps[i].isComplete()}>
                      <MUIStepLabel>{step.label}</MUIStepLabel>
                    </MUIStep>
                  )
                })}
              </MUIStepper>
              <ActiveStepComponent
                key={`as:${steps[activeStep].id}`}
                payload={payload}
                setPayload={setPayload}
                allEmployers={allEmployers}
                selectedEmployers={selectedEmployers}
                setSelectedEmployers={setSelectedEmployers}
                handleAllEmployersChange={handleAllEmployersChange}
                openAccordion={openAccordion}
                onOpenAccordion={onOpenAccordion}
                launchInfo={launchInfo}
                inputErrs={inputErrs}
              />
            </StyledDialogContent>

            <DialogActions>
              <Grid container spacing={2} justify="space-between">
                <Grid item xs="auto">
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={steps[activeStep].previousAction}>
                    {steps[activeStep].previousLabel}
                  </Button>
                </Grid>
                <Grid item xs="auto">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={steps[activeStep].nextAction}
                    disabled={!steps[activeStep].isComplete()}>
                    {steps[activeStep].nextLabel}
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  )
}

function StepEmployerAccess({
  payload,
  allEmployers,
  selectedEmployers,
  setSelectedEmployers,
  setPayload,
  handleAllEmployersChange,
}: any) {
  return (
    <>
      <Typography style={{ margin: '1rem 0' }}>
        Set the <strong>Employer Access</strong> for this organization
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={9}>
          <Autocomplete
            fullWidth
            disabled={payload.AllEmployers}
            multiple
            getOptionLabel={(option) => option.Name}
            getOptionSelected={(option) =>
              payload.EmployerIDs.includes(option.ID)
            }
            options={allEmployers}
            value={selectedEmployers}
            onChange={(ev: any, value: any) => {
              let selected = [...new Set(value)]
              setSelectedEmployers(selected)
              setPayload({
                ...payload,
                EmployerIDs: selected.map((v: any) => v.ID),
              })
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                variant="outlined"
                label="Employers"
                placeholder="Search..."
                InputLabelProps={{ shrink: true }}
              />
            )}
            renderTags={(value: any, getProps: any) =>
              value.map((option: any, index: number) => (
                <Chip
                  variant="outlined"
                  label={option.Name}
                  {...getProps({ index })}
                />
              ))
            }
          />
        </Grid>
        <Grid item xs={3}>
          <RenderSwitchField
            name={'AllEmployers'}
            label={'All Employers'}
            value={payload.AllEmployers}
            setter={({ value }: any) => handleAllEmployersChange(value)}
          />
        </Grid>
      </Grid>

      {!payload.AllEmployers && payload.EmployerIDs.length === 0 && (
        <EmployerAccessWarning>
          <Grid container spacing={2}>
            <Grid item xs={1}>
              <Warning />
            </Grid>
            <Grid item xs={11}>
              <Typography>
                This organization is configured to LIMITED access, but no
                Employers have been selected for access.
              </Typography>
            </Grid>
          </Grid>
        </EmployerAccessWarning>
      )}
    </>
  )
}

function StepAcceptingReferrals({ payload, setPayload }: any) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Typography>
            Set the <strong>Accepting Referrals</strong> status in GoZERO for
            this organization
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <RenderSwitchField
            name={'AcceptingReferrals'}
            label={'Accepting Referrals'}
            value={payload.AcceptingReferrals}
            setter={({ value }: any) =>
              setPayload({ ...payload, AcceptingReferrals: value })
            }
          />
        </Grid>
      </Grid>
    </>
  )
}

function StepLaunchDate({
  payload,
  setPayload,
  openAccordion,
  onOpenAccordion,
  launchInfo,
  inputErrs,
}: any) {
  return (
    <>
      <Typography>
        Bulk-set the <strong>Launch</strong> and{' '}
        <strong>Termination Dates</strong> dates on the Organization, associated
        Practice Facilities, Fee Schedules, and Fee Schedule Prices at the same
        time.
      </Typography>

      <small>
        IMPORTANT: this will overwrite existing start/termination dates on all
        the associated things, and will purposely fail if we detect that this
        organization has any fee schedules that are already in Production.
      </small>

      <div
        style={{
          padding: '1rem 1rem 0.5rem',
          margin: '2rem 1rem',
          background: '#e1e1e1',
          borderRadius: '8px',
        }}>
        <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
          <Grid item xs={6}>
            <ManagedDateInput
              key="LaunchDate"
              name="LaunchDate"
              label="Launch Date"
              value={payload.LaunchDate}
              setter={({ name, value }) => {
                setPayload((curr: any) => ({ ...curr, [name]: value }))
              }}
              {...(inputErrs.LaunchDate && {
                error: true,
                helperText: 'Invalid date',
              })}
            />
          </Grid>
          <Grid item xs={6}>
            <ManagedDateInput
              key="TermDate"
              name="TermDate"
              label="Termination Date"
              value={payload.TermDate}
              setter={({ name, value }) => {
                setPayload((curr: any) => ({ ...curr, [name]: value }))
              }}
              {...(inputErrs.TermDate && {
                error: true,
                helperText: 'Invalid date',
              })}
            />
          </Grid>
        </Grid>

        <div style={{ textAlign: 'center' }}>
          <Tooltip title="This will transition *ALL* fee schedules to production. The normal business rules apply. If overlaps occur, this entire process will fail.">
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={payload.ProductionizeFeeSchedules}
                  onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                    const ProductionizeFeeSchedules = ev.target.checked
                    setPayload((curr: any) => ({
                      ...curr,
                      ProductionizeFeeSchedules,
                    }))
                  }}
                />
              }
              label="Set all fee schedules to Production"
            />
          </Tooltip>
        </div>
      </div>

      <p>
        The following things will have their dates changed to what you set
        above. Note: any dates displayed below show the <i>current values</i>{' '}
        (that will be modified).
      </p>

      <Accordion
        expanded={openAccordion === 'ac1'}
        onChange={onOpenAccordion('ac1')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            Practice Facilities ({launchInfo?.PracticeFacilityCount})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RenderEntitiesByDateList
            list={launchInfo?.Entities?.Facilities || []}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={openAccordion === 'ac2'}
        onChange={onOpenAccordion('ac2')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            Fee Schedules ({launchInfo?.FeeScheduleCount || 0})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RenderEntitiesByDateList
            list={launchInfo?.Entities?.FeeSchedules || []}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={openAccordion === 'ac3'}
        onChange={onOpenAccordion('ac3')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>
            Fee Schedule Prices ({launchInfo?.FeeSchedulePriceCount || 0})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RenderEntitiesByDateList list={launchInfo?.Entities?.Prices || []} />
        </AccordionDetails>
      </Accordion>
    </>
  )
}

function RenderEntitiesByDateList({
  list,
}: {
  list: entity[]
}): React.ReactElement {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Label</TableCell>
          <TableCell>Launch</TableCell>
          <TableCell>Term</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list.map((v: any) => (
          <TableRow>
            <TableCell>{v.ID}</TableCell>
            <TableCell>{v.Label}</TableCell>
            <TableCell>{v.Launch}</TableCell>
            <TableCell>{v.Term}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
