import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import * as T from './types'
import * as utils from './utils'
import * as api from '../../services/thezerocard/api-helper'
import StopLossModel from '../../models/StopLoss'
import { setSnackbarMessage } from '../../actions/SnackbarActions'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import { deleteStopLossContracts } from '../../actions/StopLossActions'

export const useStopLossForm = (contractId?: string, employerID?: number) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [attemptedSave, setAttemptedSave] = React.useState(false)
  const { catchAPIError } = useErrorHandlers()

  const form = useForm<T.StopLossForm>(
    {
      EmployerID: employerID ? employerID : null,
      HasStopLoss: false,
      IsZeroIncluded: false,
      ContractStartDate: null,
      ContractEndDate: null,
      IncurredMonths: null,
      PaymentMonths: null,
      SpecificDeductibleAmount: null,
      AggregateDeductibleAmount: null,
      SpecificMax: null,
      AggregateMax: null,
      StopLossCarrierPartnerID: null,
      StopLossCaptiveID: null,
      CoordinatingPartnerID: null,
      ReportingNotes: '',
      GeneralNotes: '',
      IsActive: true,
      AttachmentPointFactor: null,
    },
    StopLossModel.validators
  )

  const clone = async () => {
    setAttemptedSave(true)
    if (form.isValid()) {
      const newForm = { ...form }
      newForm.data.ID = undefined
      const res: any = await api
        .post(`/stoploss`, utils.toRequestData(newForm.data))
        .catch(
          catchAPIError({ defaultMessage: 'Error cloning stop loss contract' })
        )
      if (res.error) {
        return dispatch(
          setSnackbarMessage(`Error cloning stop loss contract.`, 'error')
        )
      } else {
        dispatch(
          setSnackbarMessage(`Success cloning stop loss contract.`, 'success')
        )
        history.push(`/stop_loss_contract/${res.Data.ID}`)
      }
    }
  }

  const save = async () => {
    setAttemptedSave(true)
    if (form.isValid()) {
      const res: any = contractId
        ? await api.put(
            `/stoploss/${contractId}`,
            utils.toRequestData(form.data)
          )
        : await api.post(`/stoploss`, utils.toRequestData(form.data))

      if (res.error) {
        dispatch(
          setSnackbarMessage(
            `Error ${contractId ? 'editing' : 'creating'} stop loss contract.`,
            'error'
          )
        )
      } else {
        dispatch(
          setSnackbarMessage(
            `Success ${contractId ? 'editing' : 'creating'} stop loss contract.`,
            'success'
          )
        )
      }

      if (!contractId) {
        history.push(`/stop_loss_contract/${res.Data.ID}`)
      }
    }
  }

  const del = async () => {
    setAttemptedSave(true)
    const res: any = contractId
    // return if no contractId
    if (!contractId) {
      return
    }
    await deleteStopLossContracts([+contractId])
    if (res.error) {
      dispatch(
        setSnackbarMessage(`Error deleting stop loss contract.`, 'error')
      )
    } else {
      dispatch(
        setSnackbarMessage(`Success deleting stop loss contract.`, 'success')
      )

      history.replace(`/stop_loss_contracts`)
    }
  }

  return { form, save, clone, del, errors: attemptedSave ? form.errors : {} }
}
