import React, { forwardRef } from 'react'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import { getNetworks } from '../../actions/NetworkActions'
import utils from '../../utils'
// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../hooks/useStandardTableSetup'

export const defaultColumns = Object.freeze({
  ID: { name: 'ID', details: { hidden: true } },
  Name: { name: 'Name', details: { sortName: 'network.name' } },
  IsPrivate: {
    name: 'Is Private',
    details: { dataFormat: utils.boolYesNoFormatter },
  },
  Notes: { name: 'Notes', details: {} },
  UseSpecificCostKeys: {
    name: 'Use Specific Cost Keys',
    details: {
      dataFormat(_: any, row: any): string {
        return `${row.UseSpecificCostKeys}`
      },
    },
  },
})

const defaultSort = { col: 'network.name', dir: 'asc' }

export const Table = forwardRef(function NetworkTableList(
  {
    customColumns = defaultColumns,
    apiEndpoint = getNetworks,
    fnLinkOnRowClick = (row: any) => `/network/${row.ID}`,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      fnLinkOnRowClick,
    },
    ref
  )

  return <>{TableDisplay}</>
})
