import React, { useState, useEffect, useRef } from 'react'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { putInboundStagedUnsetProcessableFiles } from '../../../actions/EngineeringActions'
import * as TableEligInboundStaged from './TableEligInboundStaged'
import { ShapeEligInboundStaged } from './types'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import { Button } from '@material-ui/core'

export default function ViewPreProcessManifest(): React.ReactElement {
  const [selected, setSelected] = useState<ShapeEligInboundStaged[]>([])
  const [isProcessing, setIsProcessing] = useState(false)
  const snackbar = useSnackbar()
  const { catchAPIError } = useErrorHandlers()
  const refTbl = useRef<any>(null)

  useEffect(() => {
    setIsProcessing(false)
  }, [])

  function doUnstage() {
    putInboundStagedUnsetProcessableFiles({
      EligInboundStagedIDs: selected.map((v: ShapeEligInboundStaged) => v.ID),
    })
      .then(() => {
        refTbl.current?.refresh()
        snackbar.showForDuration(
          'Files moved back to the previous step OK. You can go back to the Prepare tab to re-set the processable file info.',
          SnackbarTypeSuccess,
          10000
        )
      })
      .catch(
        catchAPIError({ defaultMessage: 'Failed unsetting processable files' })
      )
      .finally(() => {
        setIsProcessing(false)
      })
  }

  return (
    <>
      <TableEligInboundStaged.Table
        ref={refTbl}
        onCheckHandler={setSelected}
        passFilters={{ HasProcessableFile: true, HasBeenPreProcessed: false }}
        customColumns={TableEligInboundStaged.columnsWithProcessableFile}
        LeftHeaderItems={
          <TableEligInboundStaged.StandardFilterSearch
            autoFocus
            tooltip="Searches S3 Bucket + Key, Notes"
          />
        }
        RightHeaderItems={
          <>
            {!!selected.length && (
              <Button color="primary" variant="outlined" onClick={doUnstage}>
                Unstage{' '}
                {isProcessing && (
                  <>
                    &nbsp;
                    <DesignSuite2023.LoadingSpinner size={15} />
                  </>
                )}
              </Button>
            )}
          </>
        }
      />
    </>
  )
}
