import React, { useState, useEffect } from 'react'
import { editEmployerMemberDeactivationDate } from '../../actions/MemberActions'
import DesignSuite2023 from '../../components/DesignSuite2023'
import dateTime from '../../utils/dateTime'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import {
  renderSwitchField as RenderSwitchField,
  renderNotesField as RenderNotesField,
  SetterArgs,
} from '../ViewHelpers'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'
import * as shapes from './types'

interface props {
  Trigger: any // React.ReactComponent
  empMemRec: shapes.shapeEmployerMember | null
  onSaved?(): void
}

export default function DialogSetDeActivationDate({
  Trigger,
  empMemRec,
  onSaved,
}: props): React.ReactElement | null {
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const [isOpen, setIsOpen] = useState(false)
  const [payload, setPayload] = useState<any>({
    DeactivationDate: empMemRec?.DeactivationDate || null,
    NoDeactivationDate: false,
    Notes: null,
    IncludedDependentEmployerMemberIDs: [],
  })
  const [inclDeps, setInclDeps] = useState(false)

  useEffect(() => {
    if (!isOpen) return
    setPayload({
      DeactivationDate: empMemRec?.DeactivationDate || null,
      NoDeactivationDate: false,
      Notes: null,
      IncludedDependentEmployerMemberIDs: [],
    })
    setInclDeps(false)
  }, [isOpen])

  useEffect(() => {
    if (payload.NoDeactivationDate) {
      setter({ name: 'DeactivationDate', value: null })
    }
  }, [payload.NoDeactivationDate])

  useEffect(() => {
    if (!inclDeps) {
      setter({
        name: 'IncludedDependentEmployerMemberIDs',
        value: [],
      })
      return
    }
    setter({
      name: 'IncludedDependentEmployerMemberIDs',
      value: empMemRec?.Dependents?.map((x: any) => x.EmployerMemberID),
    })
  }, [inclDeps])

  function setter({ name, value }: SetterArgs) {
    setPayload((curr: any) => ({ ...curr, [name]: value }))
  }

  function doSave() {
    if (!payload.NoDeactivationDate) {
      const d = dateTime.parse(payload.DeactivationDate)
      if (!d.isValid() || d.isBefore(dateTime.presets.Y2015)) {
        alert(
          'Invalid date: currently we do not allow setting the deactivation date to zero (ie. never) or any year before 2015'
        )
        return
      }
    }

    editEmployerMemberDeactivationDate({
      ...payload,
      EmployerMemberID: empMemRec?.ID,
    })
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar('Deactivation date updated OK!', SnackbarTypeSuccess)
        onSaved && onSaved()
      })
      .catch(
        catchAPIError({ defaultMessage: 'Failed updating deactivation date' })
      )
      .finally(() => setIsOpen(false))
  }

  if (!empMemRec) return null

  return (
    <>
      <DesignSuite2023.Tooltip title="Manually set deactivation date">
        <Trigger onClick={() => setIsOpen(true)} />
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Change Employer Member Deactivation Date</DialogTitle>
        <DialogContent>
          <DesignSuite2023.AlertInfo style={{ marginBottom: '1rem' }}>
            <strong>Notice</strong>: during the next eligibility run for the
            given employer, if the record for this person appears again, this
            could be overridden.
          </DesignSuite2023.AlertInfo>

          {!payload.NoDeactivationDate && (
            <ManagedDateInput
              name="DeactivationDate"
              label="Deactivation Date"
              value={payload.DeactivationDate}
              setter={setter}
            />
          )}

          <div style={{ padding: '1rem 0' }}>
            <RenderSwitchField
              setter={setter}
              name="NoDeactivationDate"
              label={
                <>
                  <span>No Deactivation Date</span>
                  <small style={{ display: 'block' }}>
                    Sets deactivation date to never (ie. eligible in
                    perpetuity).
                  </small>
                </>
              }
              value={payload.NoDeactivationDate}
            />
          </div>

          {empMemRec?.IsSubscriber && (
            <>
              <RenderSwitchField
                setter={({ name, value }: any) => {
                  setInclDeps(value)
                }}
                name=""
                label={
                  <>
                    <span>Apply to Dependents</span>
                    <small style={{ display: 'block' }}>
                      Deactivation date will propagate to dependents if this
                      member is a subscriber. Leaving this unchecked will cause
                      deactivation date of dependents to <strong>NOT</strong> be
                      changed.
                    </small>
                  </>
                }
                value={inclDeps}
              />

              {inclDeps && (
                <>
                  <DesignSuite2023.AlertWarning>
                    <strong>Warning</strong>: this will set the deactivation
                    date for the following dependents (all that are not
                    currently marked retired):
                    <ul>
                      {empMemRec?.Dependents?.map((d: any) => (
                        <li key={d.EmployerMemberID}>
                          (EmployerMemberID: {d.EmployerMemberID}) {d.FirstName}{' '}
                          {d.LastName}
                        </li>
                      ))}
                    </ul>
                  </DesignSuite2023.AlertWarning>
                </>
              )}
            </>
          )}

          <RenderNotesField
            setter={setter}
            name="Notes"
            label="Notes"
            value={payload.Notes}
            opts={{
              margin: 'normal',
              variant: 'outlined',
              size: 'small',
              helperText:
                "Warning: don't ever put sensitive information in notes!",
            }}
          />

          {/* <pre>{JSON.stringify(payload, null, '  ')}</pre> */}
        </DialogContent>
        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            }
            right={
              <Button color="primary" variant="contained" onClick={doSave}>
                Save
              </Button>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
