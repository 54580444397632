import util from './utils'
import { GroupActions, CLEAR_SAVE_RESULT } from '../actions'
const {
  FIND_GROUPS,
  GET_NEW_GROUP,
  GET_GROUP_BY_ID,
  SAVE_GROUP,
  SAVE_NEW_GROUP,
  RESET_GROUP_STATE,
  CLEAR_BUNDLERS,
  FIND_GROUP_ORGANIZATIONS,
  FIND_GROUP_USERS,
  FIND_GROUP_FACILITIES,
  FIND_GROUP_EMPLOYERS,
  FIND_GROUP_BUNDLERS,
  SAVE_GROUP_ENTITIES,
  SAVE_USER_GROUPS,
  SAVE_GROUP_USERS,
} = GroupActions

export default function (state = null, action) {
  switch (action.type) {
    case FIND_GROUPS:
      if (action.payload.error) {
        return { ...state, searchError: util.errorResponse(action) }
      }
      return {
        ...state,
        groups: action.payload.Data,
        count: action.payload.Meta.Total,
        searchError: null,
      }
    case GET_GROUP_BY_ID:
      if (action.payload.error) {
        return { ...state, getGroupErr: util.errorResponse(action) }
      }
      return {
        ...state,
        group: action.payload.Data,
        getGroupErr: null,
      }
    case GET_NEW_GROUP:
      return {
        ...state,
        group: {},
      }
    case SAVE_GROUP:
    case SAVE_NEW_GROUP:
      if (action.payload.error) {
        return {
          ...state,
          saveResult: util.errorResponse(action),
        }
      }
      return {
        ...state,
        group: action.payload.Data,
        saveResult: {
          saved: true,
          type: action.type,
        },
      }
    case SAVE_GROUP_ENTITIES:
      if (action.payload.error) {
        return {
          ...state,
          saveResult: util.errorResponse(action),
        }
      }
      return {
        ...state,
        saveResult: {
          saved: true,
          type: action.type,
        },
      }
    case SAVE_GROUP_USERS:
    case SAVE_USER_GROUPS:
      if (action.payload.error) {
        return {
          ...state,
          saveResult: util.errorResponse(action),
        }
      }
      return {
        ...state,
        saveResult: {
          saved: true,
          type: action.type,
        },
      }
    case CLEAR_SAVE_RESULT:
      return {
        ...state,
        saveResult: null,
      }
    case CLEAR_BUNDLERS:
      return {
        ...state,
        bundlers: [],
      }
    case RESET_GROUP_STATE:
      return {
        ...state,
        groups: [],
        saveResult: null,
        getGroupError: null,
      }
    case FIND_GROUP_USERS: {
      return {
        ...state,
        users: action.payload.Data,
      }
    }
    case FIND_GROUP_FACILITIES: {
      return {
        ...state,
        facilities: action.payload.Data,
      }
    }
    case FIND_GROUP_EMPLOYERS: {
      return {
        ...state,
        employers: action.payload.Data,
      }
    }
    case FIND_GROUP_ORGANIZATIONS: {
      return {
        ...state,
        organizations: action.payload.Data,
      }
    }
    case FIND_GROUP_BUNDLERS: {
      return {
        ...state,
        bundlers: action.payload.Data,
      }
    }
    default:
      return { ...state }
  }
}
