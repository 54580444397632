import React, { useState, useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { BillingPlanActions } from '../../actions'
import { BillingPlanList } from './BillingPlanList'
import { BillingPlanDetails } from './BillingPlanDetails'
import { BillingPlanDialog } from './BillingPlanDialog'
import { BillingPlanComponentPricer } from './ComponentPriceFinder'
import { BillingPlan } from '../../models/BillingPlan'
import {
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useSetFrameHeaderLeft } from '../../components/AppFrame'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import dateTime from '../../utils/dateTime'
const { getFSPriceBillingPlans, getFeeSchedulePrice } = BillingPlanActions

interface BillingPlanProps {
  feeSchedulePriceID: number
  billingPlanID: number | undefined
  onDelete: () => void
  onSelect: (bpID: number | undefined) => void
}

type BillingPlanParams = {
  fsp_id: string
  id: string
}

type BillingPlanRoutableProps = RouteComponentProps<BillingPlanParams>

const BillingPlanRoutableComponent: React.FC<BillingPlanRoutableProps> = ({
  match,
  history,
}) => {
  const fspID = match.params.fsp_id ? Number(match.params.fsp_id) : undefined
  const id = match.params.id ? Number(match.params.id) : undefined

  const handleDelete = () => {
    history.push(`/fee_schedule_price/${fspID}/billing_plan`)
  }

  const handleSelect = (bpID: number | undefined) => {
    if (bpID && fspID)
      history.push(`/fee_schedule_price/${fspID}/billing_plan/${bpID}`)
  }

  if (!fspID && !id) return <div />
  return (
    <BillingPlanComponent
      feeSchedulePriceID={fspID as number}
      billingPlanID={id}
      onDelete={handleDelete}
      onSelect={handleSelect}
    />
  )
}

const BillingPlanComponent: React.FC<BillingPlanProps> = ({
  feeSchedulePriceID,
  billingPlanID,
  onDelete,
  onSelect,
}) => {
  const [selectedBpID, setSelectedBpID] = useState<number | undefined>(
    billingPlanID
  )
  const [addEditDialogOpen, setAddEditDialogOpen] = useState<boolean>(false)
  const [billingPlans, setBillingPlans] = useState<BillingPlan[]>([])
  const [fsPriceInfo, setFsPriceInfo] = useState<any>()
  const [updateCounter, setUpdateCounter] = useState<number>(0)
  const { show: showSnackbar } = useSnackbar()
  const [title, setTitle] = useState<any>('Billing Plan')

  useSetFrameHeaderLeft(() => <h1 className="page-title">{title}</h1>, [title])

  const openAddEditDialog = () => {
    setAddEditDialogOpen(true)
  }

  const onClose = () => {
    setAddEditDialogOpen(false)
  }

  useEffect(() => {
    if (billingPlanID === selectedBpID) return
    setSelectedBpID(billingPlanID)
  }, [billingPlanID])

  useEffect(() => {
    onSelect(selectedBpID)
  }, [selectedBpID])

  useEffect(() => {
    if (!feeSchedulePriceID) return
    getFeeSchedulePrice({ feeSchedulePriceID })
      .then((fsp: any) => {
        setFsPriceInfo(fsp)
        setTitle(`Billing Plan - ${fsp?.FeeScheduleName}`)
      })
      .catch((err: any) => {
        showSnackbar(err, SnackbarTypeError)
      })
    getFSPriceBillingPlans({ feeSchedulePriceID })
      .then((bps: any) => {
        setBillingPlans(bps)
      })
      .catch((err: any) => {
        showSnackbar(err, SnackbarTypeError)
      })
    return () => {
      setTitle(<h1 className="page-title">Billing Plan</h1>)
    }
  }, [feeSchedulePriceID, updateCounter])

  const handleDelete = () => {
    setSelectedBpID(undefined)
    setUpdateCounter(updateCounter + 1)
    onDelete()
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Accordion defaultExpanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div>
                <Typography variant="h5">
                  {fsPriceInfo?.CostKey?.LaymanDescr}
                </Typography>
                <Typography variant="body1" color="textSecondary">
                  ${fsPriceInfo?.Price}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <div>Code: {fsPriceInfo?.CostKey?.Code}</div>
                <div>Category: {fsPriceInfo?.CostKey?.Category}</div>
                <div>
                  Start:{' '}
                  {dateTime
                    .parse(fsPriceInfo?.DateStart)
                    .format(dateTime.formats.ISODate)}
                </div>
                <div>
                  End:{' '}
                  {dateTime
                    .parse(fsPriceInfo?.DateEnd)
                    .format(dateTime.formats.ISODate, 'N/A')}
                </div>
                <div>{fsPriceInfo?.CostKey?.Descr}</div>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <AddNewContainer>
            <Button
              onClick={openAddEditDialog}
              variant="outlined"
              color="primary"
              fullWidth>
              + Add New Billing Plan
            </Button>
          </AddNewContainer>
          <BillingPlanList
            plans={billingPlans}
            selectedBpID={selectedBpID}
            onSelect={(id) => {
              setSelectedBpID(id)
            }}
          />
          {/* may want current prices to be passed in */}
          <BillingPlanComponentPricer
            onAdd={() => setUpdateCounter(updateCounter + 1)}
            billingPlanID={selectedBpID}
          />
        </Grid>
        <Grid item xs={8}>
          <BillingPlanDetails
            id={selectedBpID}
            defaultCode={fsPriceInfo?.CostKey?.Code}
            fsID={fsPriceInfo?.FeeScheduleID}
            onUpdate={() => setUpdateCounter(updateCounter + 1)}
            priceToMatch={fsPriceInfo?.Price}
            updateCounter={updateCounter}
            onDelete={handleDelete}
          />
          {fsPriceInfo?.FeeScheduleID && (
            <LinkBackContainer>
              <LinkBack to={`/fee_schedule/${fsPriceInfo.FeeScheduleID}`}>
                Back to Fee Schedule
              </LinkBack>
            </LinkBackContainer>
          )}
        </Grid>
      </Grid>
      <BillingPlanDialog
        defaultCode={fsPriceInfo?.CostKey?.Code}
        open={addEditDialogOpen}
        feeSchedulePriceID={feeSchedulePriceID}
        onCancel={onClose}
        onUpdate={() => setUpdateCounter(updateCounter + 1)}
      />
    </div>
  )
}

const AddNewContainer = styled.div`
  padding-top: 10px;
  width: 100%;
`

const LinkBackContainer = styled.div`
  display: flex;
  margin-top: 10px;
  width: 100%;
  justify-content: flex-end;
`

const LinkBack = styled(Link)`
  font-size: 18px;
`

// @ts-ignore
const BPRoutable = withRouter(BillingPlanRoutableComponent)

export {
  BPRoutable as BillingPlanRoutable,
  BillingPlanComponent as BillingPlan,
}
