import React, { forwardRef } from 'react'
import * as PracticeFacilityTable from '../PracticeFacilities/PracticeFacilityTable2'
import { findFeeScheduleFacilities } from '../../actions/FeeScheduleActions'

const StandardFilterSearch = PracticeFacilityTable.StandardFilterSearch
const FilterShowInactiveFacilities =
  PracticeFacilityTable.FilterShowInactiveFacilities

const columns = Object.freeze({
  ...PracticeFacilityTable.defaultColumns,
  LaunchDate: {
    name: 'Facility Launch Date',
    details: { sortName: 'PracticeFacility.LaunchDate' },
  },
  TerminationDate: {
    name: 'Facility Termination Date',
    details: { sortName: 'PracticeFacility.TerminationDate' },
  },
  FeeScheduleRemovedOn: {
    name: 'Facility Removed From Fee Schedule Date',
    details: {},
  },
})

interface props extends PracticeFacilityTable.props {
  feeScheduleID: number
}

const Table = forwardRef(function FeeSchedulePracticeFacilities(
  { feeScheduleID, ...otherProps }: props & Partial<any>,
  ref: any
): React.ReactElement {
  function apiEndpoint(payload: any): Promise<any> {
    return findFeeScheduleFacilities(feeScheduleID, payload)
  }

  return (
    <PracticeFacilityTable.Table
      passFilters={{ ActiveStatus: true }}
      {...otherProps}
      ref={ref}
      customColumns={columns}
      apiEndpoint={apiEndpoint}
    />
  )
})

export default Table
export { Table, StandardFilterSearch, FilterShowInactiveFacilities }
