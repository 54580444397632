import React, { useState, useEffect } from 'react'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import * as api from '../../services/thezerocard/api-helper'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { FileTypeData } from './types'
import { Alert } from '@material-ui/lab'

export enum FileTypeEntities {
  Organization = 'isOrganizationType',
  Employer = 'isEmployerType',
  LOA = 'isLoaType',
  Partner = 'isPartnerType',
  Referrer = 'isReferrerType',
  ExplicitType = 'isExplicitType',
  IsPHI = 'isPHIType',
  EmployerMember = 'isEmployerMemberType',
}

interface props {
  onChange?(fileTypeRecord: FileTypeData | null): void
  entityTypes?: readonly FileTypeEntities[]
  defaultValue?: number | null
  errorMessage?: string
}

/*
FileTypeFilter is a simple auto-complete of available file types; it's intended to be
*injected into the v2 file list*, or can be used wherever you want to filter by file types.

Example usage with FileList:
-----------------------------------------------------------------------------------------
import FileTypeFilter, { FileTypeEntities } from '../../Files/v2/fileTypeFilter'

export default function MyComponent() {
  const [selectedFileType, setSelectedFileType] = useState<any>(null)

  return (<>
    ...other things...

    <FileListV2
      passFilters={{fileTypeID: selectedFileType && selectedFileType.ID}}
      LeftHeaderItems={<>
        <FileTypeFilter
          entityTypes={[FileTypeEntities.organization]} <-- optional; omit entityTypes prop to return all file types
          onChange={setSelectedFileType}
        />
        ... other things you want here too ...
      </>}
    />
  </>)
}

Search for uses of this component for other examples.
*/
export default function FileTypeFilter({
  onChange,
  entityTypes,
  defaultValue,
  errorMessage,
}: props): any {
  const [data, setData] = useState([])
  const [selected, setSelected] = useState<any>()
  const [isReady, setIsReady] = useState(false)
  const [showError, setShowError] = useState(false)
  const { showDurationShort: showSnackbarDuration } = useSnackbar()

  useEffect(() => {
    setIsReady(false)

    const params = { filter: {}, sort: ['file_type.description', 'asc'] } as any
    if (!!entityTypes && entityTypes.length > 0) {
      params.filter = entityTypes?.reduce(
        (prev: any, curr: FileTypeEntities) => {
          prev[curr] = true
          return prev
        },
        {}
      )
    }

    Object.assign(params.filter, { NoLimit: true })
    const res = api.search(`/file_type`, params)
    if (!res) {
      showSnackbarDuration(
        'Indeterminate error: file types may not have loaded correctly',
        SnackbarTypeError
      )
      return
    }

    res
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        setData(res.Data.map((r: any) => ({ ...r, Label: r.Description })))
        setIsReady(true)
      })
      .catch((err: any) => {
        if (err.Error?.Message) {
          showSnackbarDuration(err.Error.Message, SnackbarTypeError)
          return
        }
        showSnackbarDuration('Failed loading file types', SnackbarTypeError)
      })
  }, [])

  useEffect(() => {
    if (!data || !defaultValue) return
    const initialValue = data.filter((type: any) => type.ID === defaultValue)[0]
    setSelected(initialValue)
  }, [data])

  // watch the defaultValue specifically; and if it changes, update the selected value
  // to reflect it
  useEffect(() => {
    if (!data || !defaultValue) return
    const initialValue = data.filter((type: any) => type.ID === defaultValue)[0]
    setSelected(initialValue)
  }, [defaultValue])

  if (!isReady) return null

  if (defaultValue && !selected) {
    return (
      <Alert severity="warning">
        File Type selector disabled; FileTypeID:{defaultValue} may be
        misconfigured.
      </Alert>
    )
  }

  return (
    <>
      <Autocomplete
        options={data}
        getOptionLabel={(opt: any) => {
          return opt.Label || ''
        }}
        getOptionSelected={(opt: any) => {
          return selected ? selected.ID === opt.ID : false
        }}
        value={selected}
        defaultValue={null}
        onChange={(_: any, opt: any | null) => {
          if (!opt) {
            setShowError(true)
            setSelected(null)
            onChange && onChange(null)
            return
          }
          setShowError(false)
          setSelected(opt)
          onChange && onChange(opt)
        }}
        renderInput={(params: any) => (
          <TextField
            {...params}
            variant="outlined"
            label="File Type"
            placeholder="File Type"
            size="small"
            InputLabelProps={{ shrink: true }}
            style={{ minWidth: 200 }}
            error={showError}
            helperText={showError ? errorMessage : null}
            FormHelperTextProps={{ error: showError }}
          />
        )}
      />
    </>
  )
}
