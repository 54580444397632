import React, { useState } from 'react'
import { Chip, Typography } from '@material-ui/core'
import { randomID } from '../helpers'

export interface ChipInformationDisplayProps {
  label: string
  value: any
  opts?: any
}

export default function ChipInformationDisplay({
  label,
  value,
  opts = {},
}: ChipInformationDisplayProps): React.ReactElement {
  const [id] = useState(randomID())

  return (
    <>
      <Chip id={id} size={opts.size || 'small'} label={label} />
      &nbsp;&nbsp;&nbsp;
      <Typography
        component="span"
        variant={opts.variant || 'body2'}
        style={{ ...opts.style }}>
        {value}
      </Typography>
      &nbsp;&nbsp;&nbsp;
    </>
  )
}
