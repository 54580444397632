import util from './utils'
import dateTime from '../utils/dateTime'
import { PracticeFacilityActions, CLEAR_SAVE_RESULT } from '../actions'
const {
  SAVE_PRACTICE_FACILITY,
  SAVE_NEW_PRACTICE_FACILITY,
  FIND_PRACTICE_FACILITIES,
  GET_PRACTICE_FACILITY,
  GET_NEW_PRACTICE_FACILITY,
  CLEAR_FACILITIES,
  GET_ALL_PRACTICE_FACILITY_SPECIALIZATIONS,
} = PracticeFacilityActions

export default function (state = null, action) {
  switch (action.type) {
    case FIND_PRACTICE_FACILITIES:
      if (action.payload.error) {
        return {
          ...state,
          practiceFacilities: [],
          count: 0,
          getError: util.errorResponse(action),
        }
      }
      return {
        ...state,
        practiceFacilities: action.payload.Data,
        count: action.payload.Meta.Total,
      }
    case CLEAR_FACILITIES: {
      return {
        ...state,
        practiceFacilities: [],
        count: 0,
        getError: null,
      }
    }
    case GET_PRACTICE_FACILITY:
      if (action.payload.error) {
        return {
          ...state,
          getError: util.errorResponse(action),
        }
      }
      return {
        ...state,
        practiceFacility: transformPF(action.payload.Data),
      }
    case GET_NEW_PRACTICE_FACILITY:
      return {
        ...state,
        practiceFacility: {},
      }
    case CLEAR_SAVE_RESULT:
      return {
        ...state,
        saveResult: null,
      }
    case GET_ALL_PRACTICE_FACILITY_SPECIALIZATIONS:
      if (action.payload.error) {
        return {
          ...state,
          getError: util.errorResponse(action),
        }
      }
      return {
        ...state,
        specializations: action.payload.Data,
      }
    case SAVE_NEW_PRACTICE_FACILITY:
    case SAVE_PRACTICE_FACILITY:
      if (action.payload.error) {
        return {
          ...state,
          saveResult: util.errorResponse(action),
        }
      }
      return {
        ...state,
        practiceFacility: transformPF(action.payload.Data),
        saveResult: {
          saved: true,
          type: action.type,
        },
      }
    default:
      return {
        ...state,
      }
  }
}

function transformPF(pf) {
  return {
    ...pf,
    LaunchDate: dateTime.parse(pf.LaunchDate).format(dateTime.formats.ISODate),
    TerminationDate: dateTime
      .parse(pf.TerminationDate)
      .format(dateTime.formats.ISODate),
  }
}
