import React from 'react'
import utils from '../utils'
import dateTime from '../utils/dateTime'
import {
  OrgNameResolver,
  PFNameResolver,
  ReferralStatusResolver,
} from '../utils/ReactFormatters'

const READ_PERMISSION = 's:referral'

const referral = {
  columns: {
    Descr: { name: 'Description', details: {} },
    CreatedAt: {
      name: 'Created On',
      details: { dataFormat: dateTime.cellFormatter() },
    },
    Organization: { name: 'Organization', details: { dataFormat: getOrgName } },
    Facility: { name: 'Facility', details: { dataFormat: getFacilityName } },
    StatusID: {
      name: 'Status',
      details: { dataFormat: getStatusDescr, width: '100px' },
    },
    Notes: { name: 'Notes', details: {} },
  },
  hasAccess: () => {
    return utils.checkPerms(READ_PERMISSION)
  },
  getMemberName,
}

function getMemberName(cell, row) {
  const { FirstName, LastName, FirstNameAka, LastNameAka } = cell
  let first = FirstName
  let last = LastName

  if (FirstNameAka !== undefined && FirstNameAka !== '') {
    first = FirstNameAka
  }
  if (LastNameAka !== undefined && LastNameAka !== '') {
    last = LastNameAka
  }

  return `${first} ${last}`
}

function getOrgName(cell, row) {
  return <OrgNameResolver orgId={row.OrganizationID} />
}

function getFacilityName(cell, row) {
  return (
    <PFNameResolver orgId={row.OrganizationID} pfId={row.PracticeFacilityID} />
  )
}

function getStatusDescr(cell, row) {
  return <ReferralStatusResolver id={row.StatusID} />
}

export default referral
