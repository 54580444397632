import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Grid, Button, InputAdornment, IconButton } from '@material-ui/core'
import { HighlightOff as IconHighlightOff } from '@material-ui/icons'
import { renderTextField, Setter, SetterArgs } from '../ViewHelpers'
import { CostKeyActions, SnackbarActions } from '../../actions'
import useErrorHandlers from '../../hooks/useErrorHandlers'
const { getCostKeyBillingTranslation, saveCostKeyBillingTranslation } =
  CostKeyActions
const { setSnackbarMessage } = SnackbarActions

interface btProps {
  classes?: any
  costKeyID: number
  getCostKeyBillingTranslation(params: Object): any
  saveCostKeyBillingTranslation(params: Object, body: any): any
  setSnackbarMessage(message: string, level: string): void
}

interface btObject {
  CostKeyID: number | null
  Definition: btDefinition
}

interface btDefinition {
  PrimaryCPT: string
  AncillaryCPTs: Array<string>
}

/*
BillingTranslation component
*/
const BillingTranslation = ({
  costKeyID,
  getCostKeyBillingTranslation,
  saveCostKeyBillingTranslation,
  setSnackbarMessage,
  ...props
}: btProps) => {
  const [fields, setFields]: [btObject, any] = useState({
    CostKeyID: costKeyID || null,
    Definition: {
      PrimaryCPT: '',
      AncillaryCPTs: [],
    },
  })
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    getCostKeyBillingTranslation({ costKeyID })
      .then((r: any) => {
        const { error, Error, Data } = r.payload
        if (error && Error) {
          if (Error.StatusCode === 404) {
            return
          }
          return setSnackbarMessage(Error.Message, 'error')
        }
        setFields(Data)
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to fetch Billing Translation; please contact Engineering',
        })
      )
  }, [costKeyID, getCostKeyBillingTranslation, setSnackbarMessage])

  const setter = ({ name, value }: SetterArgs) => {
    const Definition = Object.assign({}, fields.Definition, { [name]: value })
    const newFields = Object.assign({}, fields, { Definition })
    setFields(newFields)
  }

  const _setAncillary = (index: number): Setter => {
    return ({ value }: SetterArgs) => {
      const newFields = Object.assign({}, fields)
      newFields.Definition.AncillaryCPTs[index] = value
      setFields(newFields)
    }
  }

  const _addAncillary = () => {
    const newFields = Object.assign({}, fields)
    newFields.Definition.AncillaryCPTs.push('')
    setFields(newFields)
  }

  const _removeAnciallary = (index: number) => {
    const newFields = Object.assign({}, fields)
    newFields.Definition.AncillaryCPTs.splice(index, 1)
    setFields(newFields)
  }

  const _save = () => {
    saveCostKeyBillingTranslation({ costKeyID }, fields)
      .then((r: any) => {
        const { error, Error } = r.payload
        if (error) {
          return setSnackbarMessage(Error.Message, 'error')
        }
        setSnackbarMessage(
          'Billing translation updated successfully!',
          'success'
        )
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to update Billing Translation; please contact Engineering',
        })
      )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <h4>Manage Billing Translation</h4>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="center">
          <Grid item xs={2} key="primary">
            {renderTextField({
              name: 'PrimaryCPT',
              label: 'Primary CPT',
              setter,
              value: fields.Definition.PrimaryCPT,
              opts: { variant: 'outlined', size: 'small' },
            })}
          </Grid>
          {fields.Definition.AncillaryCPTs.map((v, i) => {
            return (
              <Grid item xs={2} key={i}>
                {renderTextField({
                  name: `AncillaryCPT[${i}]`,
                  label: 'Ancillary',
                  setter: _setAncillary(i),
                  value: v,
                  opts: {
                    variant: 'outlined',
                    size: 'small',
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={_removeAnciallary.bind(null, i)}
                            edge="end">
                            <IconHighlightOff />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  },
                })}
              </Grid>
            )
          })}
          <Grid item xs={2} key="last-btn">
            <Button color="primary" variant="contained" onClick={_addAncillary}>
              Add Ancillary
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button
          color="primary"
          variant="contained"
          onClick={_save}
          style={{ float: 'right' }}>
          Save
        </Button>
      </Grid>
    </Grid>
  )
}

BillingTranslation.defaultProps = {
  getCostKeyBillingTranslation,
  saveCostKeyBillingTranslation,
}

export default connect(
  (state: any) => {
    return {}
  },
  (dispatch: any) => {
    return bindActionCreators(
      {
        getCostKeyBillingTranslation,
        saveCostKeyBillingTranslation,
        setSnackbarMessage,
      },
      dispatch
    )
  }
)(BillingTranslation)
