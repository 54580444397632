import React from 'react'
import DesignSuite2023 from '../../components/DesignSuite2023'
import { TextField, TextFieldProps } from '@material-ui/core'
import styled from 'styled-components'

export enum SourceTypes {
  SourceTypeEDI = 'edi',
  SourceTypeGoZero = 'gozero',
  SourceTypeRxngo = 'rxngo',
  SourceTypeVBA = 'vba',
  SourceTypeManual = 'manual',
  SourceTypeClone = 'clone',
}

export enum ClaimTypes {
  Professional = 'pr',
  Institutional = 'in',
  RX = 'rx',
}

export function ClaimTypeDisplay({
  claimType,
  valueOnEmpty = '(Unknown type)',
}: {
  claimType: ClaimTypes
  valueOnEmpty?: string
}): React.ReactElement {
  let emoji, text, tip
  switch (claimType) {
    case ClaimTypes.Professional:
      emoji = '🏢'
      text = 'Prof'
      tip = 'Professional (CMS form 1500 / HCFA)'
      break
    case ClaimTypes.Institutional:
      emoji = '🏥'
      text = 'Inst'
      tip = 'Institutional (CMS form 1450 / UB-04)'
      break
    case ClaimTypes.RX:
      emoji = '💊'
      text = 'RX'
      tip = 'Pharmacy (NCPDP form)'
      break
    default:
      return <span>{valueOnEmpty}</span>
  }

  return (
    <DesignSuite2023.Tooltip title={tip}>
      <span
        style={{ display: 'inline-flex', alignItems: 'center', lineHeight: 1 }}>
        <span
          style={{
            fontSize: '128%',
            paddingRight: '0.35rem',
            display: 'inline-block',
          }}>
          {emoji}
        </span>
        <span style={{ display: 'inline-block' }}>{text}</span>
      </span>
    </DesignSuite2023.Tooltip>
  )
}

export function randomID(): string {
  return `${new Date().getTime()}${(Math.random() * 100000).toFixed(0)}`
}

export function IconFromBool({ value }: any): React.ReactElement {
  if (!!value === true) {
    return (
      <DesignSuite2023.CommonIcons.IconCheck
        fontSize="small"
        style={{ color: '#3dce3d' }}
      />
    )
  }
  return (
    <DesignSuite2023.CommonIcons.IconDeny
      fontSize="small"
      style={{ color: 'gray' }}
    />
  )
}

export function intToAlpha(n: number | null): string {
  if (n === null) return ''
  return String.fromCharCode(n - 1 + 'A'.charCodeAt(0))
}

// important, there is a difference between NULL and 0! if you were to pass
// the result back to .intToAlpha, 0 = A, whereas null is empty
export function alphaToInt(s: string | null): number | null {
  if (!s) return null
  return parseInt(String(s), 36) - 9
}

export const ExtraSmallTextField = styled(
  React.forwardRef<any, TextFieldProps>(
    ({ size = 'small', variant = 'outlined', ...spread }: any, ref: any) => (
      <TextField size={size} variant={variant} ref={ref} {...spread} />
    )
  )
)`
  font-size: 90%;
  line-height: 1;

  .MuiInputBase-root {
    font-size: inherit;
    line-height: inherit;
    .MuiInputAdornment-root {
      font-size: inherit;
      line-height: inherit;
      .MuiTypography-root {
        font-size: inherit;
        line-height: inherit;
      }
    }
    .MuiInputBase-input {
      font-size: inherit;
      line-height: inherit;
      padding: 0.2rem 0.45rem 0.25rem 0;
    }
    fieldset.MuiOutlinedInput-notchedOutline {
      top: -8px;
    }
    .MuiInputAdornment-positionStart {
      margin-right: 5px;
    }

    &.MuiInputBase-adornedStart {
      padding-left: 7px;
      .MuiInputBase-input {
        padding-left: 0;
      }
    }
    &.MuiInputBase-adornedEnd {
      padding-right: 7px;
      .MuiInputBase-input {
        padding-right: 0;
      }
      .MuiInputAdornment-positionEnd {
        margin-left: 0;
      }
    }
  }

  &.managed-date-input {
    .MuiInputBase-input {
      ::placeholder {
        font-family: monospace;
      }
    }
  }
`

export const FlexAlign = styled(
  React.forwardRef(
    (
      { tag = 'div', spaced = false, className, children, ...spread }: any,
      ref: any
    ) => {
      const klasses = [className]
      if (spaced) klasses.push('space-between')
      const pass = {
        className: klasses.join(' '),
        ...spread,
      }
      return React.createElement(tag, { ref, ...pass }, children)
    }
  )
)`
  display: flex;
  align-items: center;
  justify-content: start;

  &.space-between {
    justify-content: space-between;
  }
`

export function falsy(value: any): boolean {
  if (
    value === null ||
    value === undefined ||
    value === false ||
    value === '' ||
    value === 0
  )
    return true
  return false
}

export function truthy(value: any): boolean {
  return !falsy(value)
}

export function UUIDShorty(v: string | null): string | null {
  if (!v) return null
  return v.slice(-7)
}

export default {
  randomID,
  IconFromBool,
  intToAlpha,
  alphaToInt,
  ExtraSmallTextField,
  FlexAlign,
  falsy,
  truthy,
  ClaimTypeDisplay,
  UUIDShorty,
}
