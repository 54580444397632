import { MULTI_SELECT_UPDATED } from '../actions'

export default function (state = null, action) {
  switch (action.type) {
    case MULTI_SELECT_UPDATED:
      return {
        ...state,
        [action.stateKey]: action.selections,
      }
    default:
      return {
        ...state,
      }
  }
}
