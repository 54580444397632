import React from 'react'
import { Paper, InputAdornment, Tooltip } from '@material-ui/core'
import { Search as IconSearch, InfoOutlined } from '@material-ui/icons'
import * as LOATable from './Table'
import styled from 'styled-components'

const PaperWrapper = styled(Paper)`
  padding: 0.25rem 1rem 1rem;
`

const ToolTipIcon = styled(InfoOutlined)`
  margin-left: 2px;
  color: #a2a2a2;
  height: 16px;
  width: auto;
  cursor: pointer;
`

const loaInputProps = {
  startAdornment: (
    <InputAdornment position="start">
      <IconSearch />
    </InputAdornment>
  ),
  endAdornment: (
    <Tooltip title="LOAs can be searched by ID, Member Name, Employer Name, Org Name, CPT Code and Cost Key Code">
      <ToolTipIcon />
    </Tooltip>
  ),
}

export default function LOAs(): React.ReactElement {
  return (
    <PaperWrapper elevation={2}>
      <LOATable.Table
        enableURLReflection
        passFilters={
          {
            /* none by default... */
          }
        }
        LeftHeaderItems={
          <>
            <LOATable.StandardFilterSearch
              autoFocus
              InputProps={loaInputProps}
            />
            &nbsp;&nbsp;
            <LOATable.FilterLOAStatus />
          </>
        }
      />
    </PaperWrapper>
  )
}
