import utils from '../utils'
import { Checkbox } from '@material-ui/core'
import { Config } from '../views/Engineering/types'

interface ModelProps {
  columns: cols
  hasAccess(): boolean
  isValid(config: Config): boolean
}

interface cols {
  ID: col
  Name: col
  Description: col
  EmployerName: col
  PartnerName: col
  AuthenticationType: col
  Username: col
  PublicKey: col
  WhitelistIPs: col
  UseOldSftpServer: col
}

interface col {
  name: string
  details?: any
}

const InboundSftpConfig: ModelProps = {
  columns: {
    Name: {
      name: 'Name',
      details: { width: '100px', sortName: 'sftp_inbound_config.name' },
    },
    Username: {
      name: 'Username',
      details: { sortName: 'sftp_inbound_config.username' },
    },
    Description: { name: 'Description', details: { width: '100px' } },
    EmployerName: { name: 'Employer', details: {} },
    PartnerName: { name: 'Partner', details: {} },
    AuthenticationType: { name: 'Authentication Type', details: {} },
    PublicKey: {
      name: 'Public Key',
      details: { dataFormat: utils.copyDataFormatter },
    },
    WhitelistIPs: {
      name: 'Whitelist IPs',
      details: { dataFormat: utils.arrayIPsFormatter },
    },
    UseOldSftpServer: {
      name: 'Old SFTP Server',
      details: {
        dataFormat: utils.boolCheckboxFormatter,
        EditComponent: Checkbox,
        valProperty: 'checked',
        width: '90px',
        editable: true,
      },
    },
    ID: {
      name: 'Share',
      details: {
        dataFormat: (id: string) =>
          utils.shareLinkFormatter(
            id,
            `https://${window.location.host}/etl/inbound_sftp/`
          ),
      },
    },
  },
  hasAccess: () => true,
  isValid: (config: Config) => {
    if (
      !config.Name ||
      !config.Username ||
      !config.AuthenticationType ||
      (!config.PartnerID && !config.EmployerID)
    )
      return false
    return true
  },
}

export default InboundSftpConfig
