import React from 'react'
import ManagedDateInput, {
  props as managedDateInputProps,
} from '../../../../../components/Inputs/managedDateInput'
import styled from 'styled-components'
import { TextField, TextFieldProps } from '@material-ui/core'

export default function CustomDateInput(
  props: managedDateInputProps
): React.ReactElement | null {
  return (
    <ManagedDateInput {...props} UseComponent={CustomTextInput} margin="none" />
  )
}

const CustomTextInput = styled(
  React.forwardRef<any, TextFieldProps>(
    ({ size = 'small', variant = 'outlined', ...spread }: any, ref: any) => (
      <TextField size={size} variant={variant} ref={ref} {...spread} />
    )
  )
)`
  font-size: inherit;
  font-family: inherit;
  font-weight: normal;
  line-height: 1;
  width: 160px !important;

  .MuiInputBase-root {
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    padding-left: 0.25rem !important;
    .MuiInputAdornment-root {
      font-size: inherit;
      font-family: inherit;
      line-height: inherit;
      .MuiTypography-root {
        font-size: inherit;
        font-family: inherit;
        line-height: inherit;
      }
    }
    .MuiInputBase-input {
      font-size: inherit;
      font-family: inherit;
      line-height: inherit;
      padding: 0.2rem 0.45rem 0.25rem 0.25rem;
    }
    fieldset.MuiOutlinedInput-notchedOutline {
      top: -8px;
    }

    &.MuiInputBase-adornedStart {
      padding-left: 7px;
      .MuiInputBase-input {
        padding-left: 0;
      }
    }
    &.MuiInputBase-adornedEnd {
      padding-right: 7px;
      .MuiInputBase-input {
        padding-right: 0;
      }
    }

    fieldset {
      border: 0;
    }
  }
`
