import { ShapeAddress } from '../../components/Address'

export const enum BillingEntityCreationSource {
  Manual = 'manual',
  EDI = 'edi',
  ReviewedEDI = 'reviewed_edi',
  VBA = 'vba',
}

export interface ShapeBillingEntity {
  ID: number
  CreatedAt: string
  UUID: string
  IsLatest: boolean | null
  OrganizationID: number | null
  OrganizationName?: string // decorated api response
  OrganizationLaunchDate?: string // decorated api response
  OrganizationTerminationDate?: string // decorated api response
  Name: string | null
  TIN: string | null
  NPI: string | null
  DateStart: string | null
  DateEnd: string | null
  IsContracted: boolean | null
  IsRetired?: boolean
  IsCurrentlyActive?: boolean // decorated api response
  CreationSource: string | null
  OriginatingSrcHash: string | null
  ModifiedByUserID: number | null
  Notes: string | null

  // Shape is transformed to this by API
  RemittanceAddressID: number | null
  // The union here b/w ShapeAddress and the {} with [key:string]:any
  // loosens up typescript's squawking when we're building a dynamic
  // setter
  RemittanceAddress: ShapeAddress & {
    [key: string]: any // indicates other fields that aren't typed here
  }
}

// deprecated; factor this out
export interface ShapeBillingEntity2QueryResponse {
  // core record
  ID: number
  CreatedAt: string
  UUID: string
  IsLatest: boolean | null
  OrganizationID: number | null
  Name: string | null
  TIN: string | null
  NPI: string | null
  AddressID: number | null
  DateStart: string | null
  DateEnd: string | null
  IsContracted: boolean | null
  ModifiedByUserID: number | null

  // decorated from query builder
  OrganizationName: string | null
  RemittanceAddressCreatedAt: string | null
  RemittanceAddress1: string | null
  RemittanceAddress2: string | null
  RemittanceCity: string | null
  RemittanceState: string | null
  RemittanceZip: string | null
  RemittanceCountry: string | null
  RemittanceDisplayAddress: string | null
  RemittanceAddressKey: string | null
  RemittanceGeocoded: boolean | null
  RemittanceLat: number
  RemittanceLong: number
  Notes: string | null
}
