import React from 'react'
// @ts-ignore
import { createRoot } from 'react-dom/client'
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'

interface props {
  content?: React.ReactNode | string | null
  onConfirm(): void
  onCancel?(): void
  title?: React.ReactNode | string | null
  confirmButtonText?: string
  cancelButtonText?: string
}

/*
Generic "confirmation" dialog that's meant to be used *without* requiring
rendering react components inside the thing that wants to show a confirmation.
IOW - its a "plain old javascript function" you can use - anywhere - to show a
confirmation dialog.

Example:
----------------------------------------
import ConfirmDialog from '../../components/ConfirmDialog

export function MyPage() : React.ReactElement {
  function onClickDoSumnBad() {
    ConfirmDialog({
      content: 'You really wanna do sumthin bad? May not be able to recover from this...',
      onConfirm() {
        // ... fire nuke
      }
    })
  }

  return (<div>
    <p>hey this is a page</p>
    <button onClick={onClickDoSumnBad}>hey this is a button!</button>
  </div>)
}
----------------------------------------
*/
export default function ConfirmDialog(p: props) {
  const root = createRoot(document.createDocumentFragment())
  root.render(
    <Confirmer
      {...p}
      _cleanup={() => {
        requestAnimationFrame(() => {
          root.unmount()
        })
      }}
    />
  )
}

// The actual component; the exported one above wraps this and enables the "use anywhere"
// semantics.
function Confirmer({
  content,
  onConfirm,
  onCancel,
  title = null,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  // this is a "private" prop that's used to cleanup the root; it's not meant to be exposed
  // or used by callers
  _cleanup = () => {},
}: props & { _cleanup?(): void }) {
  const [isOpen, setIsOpen] = React.useState(true)

  React.useEffect(() => {
    if (isOpen) return
    _cleanup()
  }, [isOpen])

  function handleClose() {
    setIsOpen(false)
    onCancel && onCancel()
  }

  function handleConfirm() {
    setIsOpen(false)
    onConfirm && onConfirm()
  }

  let display: any
  switch (true) {
    case typeof content === 'string':
      display = <DialogContentText>{content}</DialogContentText>
      break
    case React.isValidElement(content):
      display = content
      break
    default:
      display = (
        <DialogContentText>
          Confirmation required: are you sure you want to continue?
        </DialogContentText>
      )
  }

  return (
    <Dialog open={isOpen}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{display}</DialogContent>
      <DialogActions>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs="auto">
            <Button color="secondary" variant="outlined" onClick={handleClose}>
              {cancelButtonText}
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button color="primary" variant="contained" onClick={handleConfirm}>
              {confirmButtonText}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
