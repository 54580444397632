const sesNotification = {
  columns: {
    ID: { name: 'ID' },
    CreatedAt: { name: 'Sent On' },
    ToAddress: { name: 'To Address' },
    Status: { name: 'Status' },
  },
}

export default sesNotification
