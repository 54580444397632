import React, { useState, useEffect } from 'react'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import { getLOAStatuses } from '../../actions/LoaActions'
import {
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  OutlinedInput,
} from '@material-ui/core'

interface props {
  selectedID: number | null
  onChange(statusID: number | null): void
}

// relevant: https://stackoverflow.com/questions/56472438/select-outlineinput-label-with-shrink-property-not-same-as-textfield-when-empty
export default function LOAStatusPicker({
  selectedID,
  onChange,
}: props): React.ReactElement {
  const [statuses, setStatuses] = useState<Array<any>>([])
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    getLOAStatuses()
      .then((res: any) => {
        if (res.error) throw res
        setStatuses(res.Data)
      })
      .catch(
        catchAPIError({ defaultMessage: 'Failed loading list of LOA statuses' })
      )
  }, [])

  function _onChange(e: any, sel: any) {
    if (!sel.props.value || sel.props.value === 'all') {
      onChange(null)
      return
    }
    onChange(sel.props.value)
  }

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      style={{ minWidth: 150 }}>
      <InputLabel shrink>Status</InputLabel>
      <Select
        autoWidth
        value={selectedID || 'all'}
        onChange={_onChange}
        input={<OutlinedInput notched label="Status" />}>
        <MenuItem key="all" value="all">
          All
        </MenuItem>
        {statuses.map((s: any) => (
          <MenuItem key={s.ID} value={s.ID}>
            {s.Descr}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
