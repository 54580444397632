import utils from '../utils'

const READ_PERMISSION = 'r:configurable_network'
const UPDATE_PERMISSION = 'u:configurable_network'
const CREATE_PERMISSION = 'c:configurable_network'

interface NetworkModel {
  hasAccess: () => boolean
  canUpdate: () => boolean
  canCreate: () => boolean
}

const configurableNetwork: NetworkModel = {
  hasAccess: () => utils.checkPerms(READ_PERMISSION),
  canUpdate: () => utils.checkPerms(UPDATE_PERMISSION),
  canCreate: () => utils.checkPerms(CREATE_PERMISSION),
}

export default configurableNetwork
