import React, { useState, useEffect } from 'react'
import { BillingPlan } from '../../models/BillingPlan'
import { List, ListItem, ListItemText } from '@material-ui/core'
import styled from 'styled-components'

type BillingPlanListProps = {
  plans: BillingPlan[]
  onSelect: (id: number) => void
  selectedBpID?: number
}

export const BillingPlanList: React.FC<BillingPlanListProps> = ({
  plans,
  onSelect,
  selectedBpID,
}) => {
  const [selected, setSelected] = useState<number | undefined>(selectedBpID)

  const handleClick = (bpID: number) => {
    return (e: any) => {
      updateSelection(bpID)
    }
  }

  const updateSelection = (bpID: number) => {
    setSelected(bpID)
    onSelect(bpID)
  }

  useEffect(() => {
    if (!selectedBpID && plans && plans.length > 0) {
      updateSelection(plans[0].ID as number)
      return
    }
    updateSelection(selectedBpID as number)
  }, [selectedBpID, plans])

  return (
    <List>
      {plans.map((bp) => {
        return (
          <StyledListItem
            key={bp.ID}
            button
            onClick={handleClick(bp.ID as number)}
            selected={bp.ID === selected}>
            <ListItemText primary={bp.Name} />
          </StyledListItem>
        )
      })}
    </List>
  )
}

const StyledListItem = styled(ListItem)`
  border-radius: 6px;
`
