import * as api from '../services/thezerocard/api-helper'
export const FIND_USERS = 'FIND_USERS'
export const GET_USER_BY_ID = 'GET_USER_BY_ID'
export const SAVE_USER = 'SAVE_USER'
export const SAVE_NEW_USER = 'SAVE_NEW_USER'
export const RESET_USER_STATE = 'RESET_USER_STATE'
export const SEND_VERIFICATION_EMAIL = 'SEND_VERIFICATION_EMAIL'
export const CLEAR_USER = 'CLEAR_USER'
export const DELETE_USER = 'DELETE_USER'
export const CHANGE_MEMBER_EMAIL = 'CHANGE_MEMBER_EMAIL'
export const CHANGE_EMAIL = 'CHANGE_EMAIL'
export const OFFBOARD_USER = 'OFFBOARD_USER'

export const findUsers = (params) => {
  return {
    type: FIND_USERS,
    payload: api.search('/users', params),
  }
}

export const getUser = () => {
  return api
    .get('/user')
    .then((res) => {
      if (res.Data) {
        return res.Data
      }
    })
    .catch((err) => {
      return err
    })
}

export const getUserByID = (params) => {
  return {
    type: GET_USER_BY_ID,
    payload: api.get(`/user/${params.userID}`),
  }
}

export const changeMemberUserEmail = (payload) => {
  return api.put(`/user/${payload.userID}/change_member_email`, payload)
}

export const changeUserEmail = (body) => {
  const { userID } = body
  return {
    type: CHANGE_EMAIL,
    payload: api.put(`/user/${userID}/change_email`, body),
  }
}

export const saveUser = (params, body) => {
  const { userID } = params
  return {
    type: SAVE_USER,
    payload: api.put(`/user/${userID}`, body),
  }
}

export const saveNewUser = (body) => {
  return {
    type: SAVE_NEW_USER,
    payload: api.post('/user', body),
  }
}

export const deleteUser = ({ id }) => {
  return {
    type: DELETE_USER,
    payload: api.del(`/engineering/user/${id}`),
  }
}

export const getUserGroupMembership = (params) => {
  return api.get(`/user/${params.userID}/group-membership`)
}

export function getAuth0Config() {
  return api.get('/users/auth0-info')
}

export function sendChangePasswordRequest({ userID, clientID }) {
  return api.post(`/user/change_password`, {
    UserID: userID,
    ClientID: clientID,
  })
}

export const saveToggleUserIsTest = (body) => {
  return api.put(`/user/${body.UserID}/toggle_test_flag`, body)
}

export const clearUser = () => {
  return { type: CLEAR_USER }
}

export const resetUserState = () => {
  return { type: RESET_USER_STATE }
}

export const sendVerificationEmail = ({ userID, clientID }) => {
  return {
    type: SEND_VERIFICATION_EMAIL,
    payload: api.post('/user/verify_email', {
      UserID: userID,
      ClientID: clientID,
    }),
  }
}

export const getUserByIDV2 = (id) => {
  return api.get(`/user/${id}`).then((res) => res.Data)
}

export const offboardUser = (id) => {
  return {
    type: OFFBOARD_USER,
    payload: api.put(`/user/${id}/offboard`),
  }
}
