import React, { useState, useEffect } from 'react'
import DesignSuite2023 from '../../components/DesignSuite2023'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import { changeMemberUserEmail } from '../../actions/UserActions'
import { renderTextField as RenderTextField, SetterArgs } from '../ViewHelpers'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'

interface props {
  userID: number | null
  currentEmail: string | null
  onSaved?(): void
}

export default function DialogChangeMyZEROEmail({
  userID,
  currentEmail,
  onSaved,
}: props): React.ReactElement | null {
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const [isOpen, setIsOpen] = useState(false)
  const [email, setEmail] = useState<string | null>(null)

  useEffect(() => {
    if (!isOpen) return
    setEmail(currentEmail)
  }, [isOpen, currentEmail])

  function setter({ value }: SetterArgs) {
    setEmail(value)
  }

  function doSave() {
    if (!userID || !email) {
      return alert('userID or email is missing (contact engineering)')
    }
    const ship = { userID, newEmail: email }
    changeMemberUserEmail(ship)
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar('Email updated OK!', SnackbarTypeSuccess)
        onSaved && onSaved()
      })
      .catch(catchAPIError({ defaultMessage: 'Failed updating email' }))
      .finally(() => setIsOpen(false))
  }

  if (!userID) return null

  return (
    <>
      <DesignSuite2023.Tooltip
        title={`Change email for this member's MyZERO account`}>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => setIsOpen(true)}>
          Change Email
        </Button>
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Change MyZERO Email</DialogTitle>
        <DialogContent>
          <RenderTextField
            name="Email"
            label="Email"
            value={email || ''}
            setter={setter}
            opts={{
              margin: 'none',
              variant: 'outlined',
              size: 'small',
              style: { minWidth: '20rem' },
            }}
          />
        </DialogContent>
        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            }
            right={
              <Button color="primary" variant="contained" onClick={doSave}>
                Change
              </Button>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
