import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar from '../../hooks/useSnackbar'
import styled from 'styled-components'
import DesignSuite2023 from '../../components/DesignSuite2023'
import { deleteFileClaim } from '../../actions/OpportunityAnalysisActions'
import { getFileClaims } from '../../actions/FileActions'

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
    },
  },
  getContentAnchorEl: null,
}

const StyledSelect = styled(Select)`
  min-width: 400px;
`

export default function RemoveOppAnalysis(): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const [fileClaims, setFileClaims] = useState<any[]>([])
  const [selectedClaim, setSelectedClaim] = useState<any>(null)

  useEffect(() => {
    if (!isOpen) {
      return
    }
    setSelectedClaim(null)
    getFileClaims().payload.then((response: any) => {
      response.Data.sort((a: any, b: any) => {
        return b.ID - a.ID
      })
      setFileClaims(response.Data)
    })
  }, [isOpen])
  function isDisabled() {
    return selectedClaim === null
  }

  function removeOppAnalysis() {
    deleteFileClaim(selectedClaim.ID)
      .then(() => {
        showSnackbar('Opportunity Analysis Removed', 'success')
        fileClaims.splice(fileClaims.indexOf(selectedClaim), 1)
        setSelectedClaim(null)
        setIsOpen(false)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to remove opportunity analysis',
        })
      )
  }

  function handleChange(event: any) {
    setSelectedClaim(event.target.value)
  }

  return (
    <>
      <DesignSuite2023.Tooltip
        placement="left"
        title="Remove Opportunity Analysis">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsOpen(true)
          }}>
          Remove Opp Analysis
        </Button>
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen}>
        <StyledDialogContent>
          <Typography variant="h6">
            Select the Opportunity Analysis to Remove
          </Typography>
          <Typography variant="subtitle2">
            Note: Only the analysis will be removed. The file will still remain
            in S3.
          </Typography>
          <StyledSelect
            label="File Claims"
            renderValue={(claim: any) =>
              claim ? `FileClaim ${claim.ID}: ${claim.Descr}` : ''
            }
            value={selectedClaim || ''}
            onChange={handleChange}
            MenuProps={MenuProps}>
            {fileClaims.map((claim) => (
              <MenuItem key={claim.ID} value={claim}>
                <ListItemText
                  primary={`FileClaim ${claim.ID}: ${claim.Descr}`}
                />
              </MenuItem>
            ))}
          </StyledSelect>
        </StyledDialogContent>

        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  setIsOpen(false)
                }}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                disabled={isDisabled()}
                color="primary"
                variant="contained"
                onClick={removeOppAnalysis}>
                Remove Opp Analysis
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

const StyledDialogContent = styled(DialogContent)`
  min-width: 500px;
`
