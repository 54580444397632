import util from './utils'
import { ComponentFeeScheduleActions } from '../actions'
const {
  CLONE_COMPONENT_FEE_SCHEDULE,
  FIND_COMPONENT_FEE_SCHEDULE,
  GET_NEW_COMPONENT_FEE_SCHEDULE,
  GET_COMPONENT_FEE_SCHEDULE,
  GET_COMPONENT_FEE_SCHEDULE_TYPES,
  SAVE_COMPONENT_FEE_SCHEDULE,
  SAVE_NEW_COMPONENT_FEE_SCHEDULE,
  MOVE_COMPONENT_TO_PRODUCTION,
  CLEAR_COMPONENT_FEE_SCHEDULE_SAVE_RESULT,
} = ComponentFeeScheduleActions

export default function (state = null, action) {
  switch (action.type) {
    case FIND_COMPONENT_FEE_SCHEDULE:
      if (action.payload.error) {
        return { ...state, searchError: util.errorResponse(action) }
      }
      return {
        ...state,
        componentFeeSchedules: action.payload.Data,
        count: action.payload.Meta.Total,
        searchError: null,
      }
    case GET_COMPONENT_FEE_SCHEDULE:
      if (action.payload.error) {
        return {
          ...state,
          getComponentFeeScheduleErr: util.errorResponse(action),
        }
      }
      return {
        ...state,
        componentFeeSchedule: action.payload.Data,
        getComponentFeeScheduleErr: null,
      }
    case GET_COMPONENT_FEE_SCHEDULE_TYPES:
      const componentTypes = util.formatTypes(action.payload.Data)
      return {
        ...state,
        componentTypes,
      }
    case GET_NEW_COMPONENT_FEE_SCHEDULE:
      return {
        ...state,
        componentFeeSchedule: {},
      }
    case SAVE_COMPONENT_FEE_SCHEDULE:
    case SAVE_NEW_COMPONENT_FEE_SCHEDULE:
    case CLONE_COMPONENT_FEE_SCHEDULE:
      if (action.payload.error) {
        return {
          ...state,
          saveResult: util.errorResponse(action),
        }
      }
      return {
        ...state,
        componentFeeSchedule: action.payload.Data,
        saveResult: {
          saved: true,
          type: action.type,
        },
      }
    case MOVE_COMPONENT_TO_PRODUCTION:
      if (action.payload.error) {
        return {
          ...state,
          saveResult: util.errorResponse(action),
        }
      }
      return {
        ...state,
        saveResult: {
          saved: true,
          type: action.type,
        },
      }
    case CLEAR_COMPONENT_FEE_SCHEDULE_SAVE_RESULT:
      return {
        ...state,
        saveResult: { type: action.type },
      }
    default:
      return { ...state }
  }
}
