import React, { useEffect, useState } from 'react'
import { DataTable } from '../../components/DataTable'
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core'
import {
  getSFTPConfigs,
  postSFTPConfig,
  updateSFTPConfig,
  terminateSFTPConfig,
} from '../../actions/InboundSftpActions'
import {
  SearchParams,
  Config,
  SelectItem,
  SortArgs,
  PageChangeArgs,
  TerminateModalProps,
  ConfigModalProps,
  Sortable,
} from '../Engineering/types'
import {
  renderTextField,
  SetterArgs,
  renderSwitchField,
  renderNotesField,
  renderSelectField,
} from '../ViewHelpers'
import { EmployerSearcher, PartnerSearcher } from '../../components/Searchers'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import Models from '../../models'
import styled from 'styled-components'
import dateTime from '../../utils/dateTime'

const Title = styled.p`
  font-size: 20px;
`

const WideContent = styled(DialogContent)`
  width: 550px;
`

const FilterGrid = styled(Grid)`
  flex: 1;
  margin-right: 20px;
`

const SearchContainer = styled(Grid)`
  width: 300px;
  margin-right: 10px;
`

const SelectContainer = styled(Grid)`
  flex: 1;
  max-width: 500px;
`

const AddConfigButton = styled(Button)`
  height: 50px;
`

const AuthGrid = styled(Grid)`
  margin-top: 20px;
`

const AuthLabel = styled(InputLabel)`
  margin-bottom: 0px;
`

const AuthSelect = styled(Select)`
  margin-left: 10px;
  flex: 1;
`

const ErrorText = styled(DialogContentText)`
  font-size: 12px;
  color: red;
  margin-bottom: 0px;
`

const defaultParams: SearchParams = {
  sort: ['sftp_inbound_config.name', 'asc'],
  filter: { q: '' },
  page: 1,
  pageSize: 50,
}

const InboundSftp = (props: any) => {
  const [searchParams, setSearchParams] = useState(defaultParams)
  const [configModalOpen, setConfigModalOpen] = useState(false)
  const [configs, setConfigs] = useState<Array<Config>>([])
  const [rowToEdit, setRowToEdit] = useState<Config | null>(null)
  const [selectedEntity, setSelectedEntity] = useState<string>('All')
  const [entityList, setEntityList] = useState<Array<SelectItem>>([])
  const [employerIDs, setEmployerIDs] = useState<Array<number>>([])
  const [sortable, setSortable] = useState<Sortable>({
    col: 'sftp_inbound_config.name',
    dir: 'asc',
  })
  const [updateCount, setUpdateCount] = useState<number>(0)
  const columns = Models.InboundSftpConfig.columns

  useEffect(() => {
    if (configModalOpen) return
    if (searchParams.filter.q && searchParams.filter.q.length < 3) return
    getSFTPConfigs(searchParams).then((res: Array<Config>) => {
      if (!entityList.length) setEntityList(getEntities(res))
      if (props.match.params.id && updateCount < 1) {
        const editRow = res.filter(
          (fig) => fig.ID === parseInt(props.match.params.id)
        )[0]
        if (editRow) {
          setRowToEdit(editRow)
          setConfigModalOpen(true)
        }
      }
      setConfigs(res)
      setUpdateCount(updateCount + 1)
    })
  }, [configModalOpen, searchParams])

  const renderEditModal = (row: Config) => {
    setRowToEdit(row)
    setConfigModalOpen(true)
  }

  const handleEdit = () => false

  const sortHandler = ({ col, dir }: SortArgs) => {
    setSortable({ col, dir })
    setSearchParams({ ...searchParams, sort: [col, dir] })
  }

  const setSearch = ({ name, value }: SetterArgs) =>
    setSearchParams({
      ...searchParams,
      filter: { ...searchParams.filter, q: value },
    })
  const handlePageChange = ({ page, pageSize }: PageChangeArgs) =>
    setSearchParams({ ...searchParams, page, pageSize })

  // entities = partners + employers
  const setEntity = (select: Pick<SelectItem, 'value'>) => {
    setSelectedEntity(select.value)
    if (select.value === 'All')
      return setSearchParams(Object.assign({}, searchParams, { filter: {} }))
    const searchVal = employerIDs.includes(parseInt(select.value))
      ? 'EmployerIDs'
      : 'PartnerIDs'
    setSearchParams(
      Object.assign({}, searchParams, {
        filter: { [searchVal]: [parseInt(select.value)] },
      })
    )
  }

  const getEntities = (figs: Array<Config>) => {
    if (!figs.length) return []
    const resultArray: Array<SelectItem> = [{ label: 'All', value: 'All' }]
    const partnerVals: Array<number> = []
    const employerVals: Array<number> = []
    figs.forEach((fig: Config) => {
      if (
        (fig.PartnerID && partnerVals.includes(fig.PartnerID)) ||
        (fig.EmployerID && employerVals.includes(fig.EmployerID))
      )
        return null
      if (fig.PartnerID) {
        partnerVals.push(fig.PartnerID)
        resultArray.push(
          Object.assign({ label: fig.PartnerName, value: fig.PartnerID })
        )
        return
      }
      if (fig.EmployerID) {
        employerVals.push(fig.EmployerID)
        resultArray.push(
          Object.assign({ label: fig.EmployerName, value: fig.EmployerID })
        )
        return
      }
    })
    setEmployerIDs(employerVals)
    return resultArray
  }

  const rowOptsApplier = (row: Config) => {
    if (row.TerminatedAt) return { className: 'tr-warning' }
    return null
  }

  return (
    <Grid direction="column">
      <Title>Inbound SFTP Configs</Title>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center">
        <FilterGrid container direction="row">
          <SearchContainer>
            {renderTextField({
              name: 'Search',
              label: 'Search',
              value: searchParams.filter.q,
              setter: setSearch,
            })}
          </SearchContainer>
          <SelectContainer>
            {renderSelectField({
              name: 'EmployerID',
              label: 'Employers & Partners',
              setter: setEntity,
              items: entityList,
              value: selectedEntity,
            })}
          </SelectContainer>
        </FilterGrid>
        <AddConfigButton
          variant="contained"
          color="primary"
          onClick={() => setConfigModalOpen(true)}>
          Add Inbound Config
        </AddConfigButton>
      </Grid>
      <DataTable
        initPage={searchParams.page}
        initPageSize={searchParams.pageSize}
        rowsPerPage={[100, 250]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePageChange}
        sortable={sortable}
        sortHandler={sortHandler}
        columns={columns}
        keyProp="ID"
        allowEditing={true}
        handleCustomEdit={renderEditModal}
        data={configs}
        count={configs.length}
        onEditRow={handleEdit}
        rowOptsApplier={rowOptsApplier}
      />
      <ConfigModal
        open={configModalOpen}
        setConfigModalOpen={setConfigModalOpen}
        row={rowToEdit}
        setRowToEdit={setRowToEdit}
      />
    </Grid>
  )
}

const ConfigModal = ({
  open,
  setConfigModalOpen,
  row,
  setRowToEdit,
}: ConfigModalProps) => {
  const defaultConfigFields: Config = {
    Name: '',
    EmployerID: null,
    PartnerID: null,
    Description: null,
    Username: '',
    Password: null,
    AuthenticationType: '',
    PublicKey: null,
    WhitelistIPs: [],
    UseOldSftpServer: false,
    Notes: null,
  }

  const [configFields, setConfigFields] = useState<Config | null>(
    defaultConfigFields
  )
  const [showUsernameError, setShowUsernameError] = useState<boolean>(false)
  const [terminateModalOpen, setTerminateModalOpen] = useState<boolean>(false)
  const snackbar = useSnackbar()

  useEffect(() => {
    if (!row) return
    setConfigFields(row)
  }, [row])

  useEffect(() => {
    if (configFields && /\s/g.test(configFields.Username)) {
      return setShowUsernameError(true)
    }
    if (showUsernameError) setShowUsernameError(false)
  }, [configFields])

  const setter = ({ name, value }: SetterArgs) => {
    const newFields = Object.assign({}, configFields, { [name]: value })
    setConfigFields(newFields)
  }

  const ipSetter = ({ name, value }: SetterArgs) => {
    const newValue = value ? value.replace(/[, ]+/g, ' ').split('\n') : []
    const newFields = Object.assign({}, configFields, { [name]: newValue })
    setConfigFields(newFields)
  }

  const errMessage =
    'Config Invalid! (Missing Name, Username, Auth Type, or Partner/Employer)'

  const handleSubmit = () => {
    if (!configFields || showUsernameError) return
    if (!Models.InboundSftpConfig.isValid(configFields))
      return snackbar.show(errMessage, SnackbarTypeError)
    postSFTPConfig(configFields)
      .then(() => {
        setConfigFields(defaultConfigFields)
        setConfigModalOpen(false)
      })
      .catch((err: any) => {
        if (err.Error && err.Error.Message)
          return snackbar.show(err.Error.Message, SnackbarTypeError)
        snackbar.show(
          'Failed to create new SFTP Partner Config, please contact engineering',
          SnackbarTypeError
        )
      })
  }

  const handleEdit = () => {
    if (!row || !configFields || showUsernameError) return
    if (!Models.InboundSftpConfig.isValid(configFields))
      return snackbar.show(errMessage, SnackbarTypeError)
    updateSFTPConfig(row.ID, configFields)
      .then(() => {
        setConfigFields(defaultConfigFields)
        setRowToEdit(null)
        setConfigModalOpen(false)
      })
      .catch((err: any) => {
        if (err.Error && err.Error.Message)
          return snackbar.show(err.Error.Message, SnackbarTypeError)
        snackbar.show(
          'Failed to edit SFTP Partner Config, please contact engineering',
          SnackbarTypeError
        )
      })
  }

  const handleClose = () => {
    setConfigFields(defaultConfigFields)
    setRowToEdit(null)
    setConfigModalOpen(false)
    setTerminateModalOpen(false)
  }

  const handleEmployerChange = (id: number) => {
    const newFields = Object.assign({}, configFields, { EmployerID: id })
    setConfigFields(newFields)
  }

  const handlePartnerChange = (id: number) => {
    const newFields = Object.assign({}, configFields, { PartnerID: id })
    setConfigFields(newFields)
  }

  const handleAuthChange = (event: any) => {
    const newFields = Object.assign({}, configFields, {
      AuthenticationType: event.target.value,
    })
    setConfigFields(newFields)
  }

  const parseIPs = () => {
    if (!configFields || !configFields.WhitelistIPs) return ''
    return configFields.WhitelistIPs.join('\n')
  }

  if (!configFields) return <></>
  return (
    <Dialog open={open}>
      {terminateModalOpen ? (
        <TerminateModal
          id={configFields.ID}
          config={configFields}
          handleClose={handleClose}
          setTerminateModalOpen={setTerminateModalOpen}
        />
      ) : (
        <>
          <DialogTitle>
            {row ? 'Edit Inbound Config' : 'Add New Inbound Config'}
          </DialogTitle>
          <WideContent>
            {renderTextField({
              name: 'Name',
              label: 'Name',
              value: configFields.Name,
              setter,
            })}
            {renderTextField({
              name: 'Username',
              label: 'Username',
              value: configFields.Username,
              setter,
              disabled: !!row,
            })}
            {showUsernameError && (
              <ErrorText>* Username cannot contain any whitespace!</ErrorText>
            )}
            {!configFields.PartnerID && (
              <EmployerSearcher
                onChange={handleEmployerChange}
                selectedEmployerID={configFields.EmployerID}
              />
            )}
            {!configFields.EmployerID && (
              <PartnerSearcher
                onChange={handlePartnerChange}
                selectedPartnerID={configFields.PartnerID}
              />
            )}
            {renderTextField({
              name: 'Description',
              label: 'Description',
              value: configFields.Description,
              setter,
            })}
            <AuthGrid container direction="row" alignItems="center">
              <AuthLabel>Authentication Type:</AuthLabel>
              <AuthSelect
                onChange={handleAuthChange}
                value={configFields.AuthenticationType}>
                <MenuItem value={'password'}>password</MenuItem>
                <MenuItem value={'key'}>key</MenuItem>
                <MenuItem value={'both'}>both</MenuItem>
              </AuthSelect>
            </AuthGrid>
            {configFields.AuthenticationType !== 'key' &&
              renderTextField({
                name: 'Password',
                label: 'Password',
                value: configFields.Password,
                setter,
              })}
            {configFields.AuthenticationType !== 'password' &&
              renderNotesField({
                name: 'PublicKey',
                label: 'Public Key',
                value: configFields.PublicKey,
                setter,
              })}
            {renderNotesField({
              name: 'WhitelistIPs',
              label: 'Whitelisted IPs',
              value: parseIPs(),
              setter: ipSetter,
            })}
            <DialogContentText>
              Please input Whitelisted IPs as new line separated list
            </DialogContentText>
            {renderSwitchField({
              name: 'UseOldSftpServer',
              label: 'Use Old SFTP Server',
              value: configFields.UseOldSftpServer,
              setter,
            })}
            {renderNotesField({
              name: 'Notes',
              label: 'Notes',
              value: configFields.Notes,
              setter,
            })}
            {configFields.CreatedAt && (
              <DialogContentText>
                Created At:{' '}
                {dateTime.parse(configFields.CreatedAt).local().format()}
              </DialogContentText>
            )}
            {configFields.UpdatedAt && (
              <DialogContentText>
                Last Updated At:{' '}
                {dateTime.parse(configFields.UpdatedAt).local().format()}
              </DialogContentText>
            )}
            {row && !configFields.TerminatedAt && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setTerminateModalOpen(true)}>
                Terminate
              </Button>
            )}
            {row && configFields.TerminatedAt && (
              <DialogContentText>
                Terminated At:{' '}
                {dateTime.parse(configFields.TerminatedAt).local().format()}
              </DialogContentText>
            )}
          </WideContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={row ? handleEdit : handleSubmit}>
              Submit
            </Button>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

const TerminateModal = ({
  id,
  config,
  handleClose,
  setTerminateModalOpen,
}: TerminateModalProps) => {
  const snackbar = useSnackbar()

  const handleTerminate = () => {
    terminateSFTPConfig(id, config)
      .then(() => {
        handleClose()
      })
      .catch((err: any) => {
        if (err.Error && err.Error.Message)
          return snackbar.show(err.Error.Message, SnackbarTypeError)
        snackbar.show(
          'Failed to terminate Inbound SFTP Config, please contact engineering',
          SnackbarTypeError
        )
      })
  }

  return (
    <>
      <DialogTitle>Terminate Inbound SFTP Config</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure that you want to terminate this Inbound SFTP Config?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleTerminate}>
          Confirm
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setTerminateModalOpen(false)}>
          Cancel
        </Button>
      </DialogActions>
    </>
  )
}

export default InboundSftp
