import React, { useState, useEffect } from 'react'
import * as api from '../../../services/thezerocard/api-helper'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../../hooks/useSnackbar'
import {
  CircularProgress,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import dateTime from '../../../utils/dateTime'

interface NoteShape {
  ID: number
  CreatedAt: string
  CreatedByUserID: string
  CreatedByFirstName: string
  CreatedByLastName: string
  MemberID: string
  Note: string | null
}

export default function DisplayMemberNotes({
  memberID,
  ...props
}: any): React.ReactElement {
  const [isLoading, setIsLoading] = useState(false)
  const [note, setNote] = useState<NoteShape>({ Note: '' } as NoteShape)
  const [origNoteText, setOrigNoteText] = useState('')
  const [noteHistory, setNoteHistory] = useState<NoteShape[]>([])
  const [showHistory, setShowHistory] = useState(false)
  const { showDurationShort: showSnackbarDuration } = useSnackbar()

  useEffect(() => {
    if (!memberID) {
      return
    }
    loadNotes(memberID).finally(() => {
      setIsLoading(false)
    })
  }, [memberID])

  const onChangeNote = (ev: any) => {
    const v = ev.target?.value
    setNote((curr: NoteShape) => ({ ...curr, Note: v }))
  }

  const onSaveNote = () => {
    setIsLoading(true)

    const { Note } = note
    api
      .post(`/member/${memberID}/note`, { Note })
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        showSnackbarDuration('Member note saved!', SnackbarTypeSuccess)
        return loadNotes(memberID)
      })
      .catch((err: any) => {
        if (err.Error?.Message) {
          showSnackbarDuration(err.Error.Message, SnackbarTypeError)
          return
        }
        showSnackbarDuration('Failed saving member note', SnackbarTypeError)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const canSave = () => {
    if (!note.Note) {
      return false
    }
    if (note.Note === origNoteText) {
      return false
    }
    return true
  }

  const doCloseHistoryDialog = () => {
    setShowHistory(false)
  }

  const onClickShowHistory = () => {
    if (!noteHistory.length) {
      return
    }
    setShowHistory(true)
  }

  function loadNotes(memberID: number): Promise<any> {
    setIsLoading(true)

    const res = api.get(`/member/${memberID}/note`)
    if (!res) {
      setIsLoading(false)
      showSnackbarDuration(
        'Indeterminate error: failed loading member notes',
        SnackbarTypeError
      )
      return Promise.reject()
    }

    return res
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        if (Array.isArray(res.Data) && res.Data.length >= 1) {
          setNote(res.Data[0])
          setOrigNoteText(res.Data[0].Note || '')
          setNoteHistory(res.Data)
        }
      })
      .catch((err: any) => {
        if (err.Error?.Message) {
          showSnackbarDuration(err.Error.Message, SnackbarTypeError)
          return
        }
        showSnackbarDuration('Failed loading member note', SnackbarTypeError)
      })
  }

  if (isLoading) {
    return <CircularProgress />
  }

  return (
    <>
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'stretch',
        }}>
        <h5 style={{ margin: 0, padding: '0 0 0.75rem' }}>Member Note</h5>
        <TextField
          variant="outlined"
          placeholder="Enter notes here..."
          fullWidth
          multiline
          rowsMax={8} // this is deprecated in future MUI versions and should become 'maxRows'
          style={{ flexGrow: 2, height: '100%', marginBottom: '0.5rem' }}
          value={note.Note || ''}
          onChange={onChangeNote}
          InputProps={{ style: { height: '100%', padding: '0.5rem' } }}
        />
        <div style={{ textAlign: 'right' }}>
          {!!noteHistory.length && (
            <small
              onClick={onClickShowHistory}
              style={{
                cursor: 'pointer',
                marginRight: '0.5rem',
                textDecoration: 'underline',
              }}>
              View Note History (modified {noteHistory.length}x)
            </small>
          )}
          <Button
            disabled={!canSave()}
            color="primary"
            variant="contained"
            onClick={onSaveNote}>
            Save Note
          </Button>
        </div>
      </div>

      {noteHistory.length >= 1 && (
        <DialogNoteHistory
          isOpen={showHistory}
          closeDialog={doCloseHistoryDialog}
          noteHistory={noteHistory}
        />
      )}
    </>
  )
}

function DialogNoteHistory({ isOpen, closeDialog, noteHistory }: any): any {
  return (
    <Dialog open={isOpen} onClose={closeDialog}>
      <DialogTitle>Note History</DialogTitle>
      <DialogContent>
        {!!noteHistory && (
          <div style={{ marginBottom: '1rem' }}>
            {noteHistory.map((n: NoteShape, index: number): any => {
              return (
                <div
                  key={`${n.ID}.${index}`}
                  style={{
                    position: 'relative',
                    padding: index === 0 ? '2.35rem 1rem 1rem' : '1rem',
                    marginBottom: '0.5rem',
                    background: 'rgba(0,0,0,0.1)',
                    borderRadius: '4px',
                    overflow: 'hidden',
                  }}>
                  {index === 0 && (
                    <span
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        fontSize: '90%',
                        background: '#000',
                        color: '#fff',
                        padding: '0.15rem 0.35rem',
                        textAlign: 'center',
                      }}>
                      CURRENT
                    </span>
                  )}
                  <p>{n.Note}</p>
                  <small>
                    <strong>By:</strong> {n.CreatedByFirstName}{' '}
                    {n.CreatedByLastName} on{' '}
                    {dateTime
                      .parse(n.CreatedAt)
                      .local()
                      .format(dateTime.formats.PrettyVerbose)}
                  </small>
                </div>
              )
            })}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={closeDialog}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
