export interface CardProps {
  title: string
  subheader?: string
}

export interface ButtonGroupProps {
  back: () => void
  save: () => void
  clone: () => void
  del: () => void
  contractId: string | null
}

export interface StopLossForm {
  ID?: number
  EmployerID: number | null
  HasStopLoss: boolean | null
  IsZeroIncluded: boolean | null
  ContractStartDate: string | null // MM-DD-YYYY
  ContractEndDate: string | null
  IncurredMonths: number | null
  PaymentMonths: number | null
  SpecificDeductibleAmount: string | null
  AggregateDeductibleAmount: string | null
  SpecificMax: string | null
  AggregateMax: string | null
  StopLossCarrierPartnerID: number | null
  StopLossCaptiveID: number | null
  CoordinatingPartnerID: number | null
  ReportingNotes: string | null
  GeneralNotes: string | null
  IsActive: boolean | null
  AttachmentPointFactor: string | null
}

export interface StopLossRequest {
  EmployerID: number
  HasStopLoss: boolean
  IsZeroIncluded: boolean
  ContractStartDate: string | null // YYYY-MM-DD
  ContractEndDate: string | null
  IncurredMonths: number | null
  PaymentMonths: number | null
  SpecificDeductibleAmount: string | null
  AggregateDeductibleAmount: string | null
  SpecificMax: string | null
  AggregateMax: string | null
  StopLossCarrierPartnerID: number | null
  StopLossCaptiveID: number | null
  CoordinatingPartnerID: number | null
  ReportingNotes: string | null
  GeneralNotes: string | null
  IsActive: boolean | null
  AttachmentPointFactor: string | null
}

export const CONTACT_TYPE_STOP_LOSS = 10
