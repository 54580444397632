import React, { useEffect } from 'react'
import * as tzcHelper from '../../services/thezerocard/helper'
import * as typed from './typed'
import isNumber from 'lodash/isNumber'
import { Launch as IconLaunch } from '@material-ui/icons'
import {
  Checkbox,
  TextField,
  Button,
  FormControlLabel,
  Grid,
} from '@material-ui/core'
import { ReviewStateSelector } from './inputs'
import dateTime from '../../utils/dateTime'

interface props {
  activeGroup: typed.GroupRecord | undefined
  activeGroupFields: any
  setActiveGroupFields: Function
  disabled: boolean
  onClickCostKeySearch(ev: React.MouseEvent): void
  onClickSaveGroup(ev: React.MouseEvent): void
}

export const activeFieldDefaults = {
  costKey: '',
  costKeyDescr: '',
  typicalPPOPrice: 0.0,
  typicalHrrPpoPrice: 0.0,
  notes: null,
  reviewState: null,
  isIgnored: false,
}

export default function GroupFiltersAndList({
  activeGroup,
  activeGroupFields,
  setActiveGroupFields,
  disabled,
  onClickCostKeySearch,
  onClickSaveGroup,
}: props) {
  // watch review group index changes; update or clear activeGroupFields on change
  useEffect(() => {
    if (!activeGroup) {
      setActiveGroupFields({ ...activeFieldDefaults })
      return
    }
    setActiveGroupFields({
      ...activeFieldDefaults,
      costKey: activeGroup.CostKey,
      costKeyDescr: activeGroup.CostKeyDescr,
      typicalPPOPrice: Math.round(activeGroup.TypicalPPOPrice * 100.0) / 100.0,
      typicalHrrPpoPrice:
        Math.round(activeGroup.TypicalHrrPpoPrice * 100.0) / 100.0,
      notes: activeGroup.Notes || null,
      reviewState: activeGroup.ReviewState,
      isIgnored: activeGroup.IsIgnored,
    })
  }, [activeGroup, setActiveGroupFields])

  function priceHint(price: number): any {
    if (!activeGroup) {
      return
    }
    return (
      <small className="helper-text">
        ({tzcHelper.moneyFormat(price - activeGroup.PaidAmount)} /{' '}
        {tzcHelper.floatFormat(
          Math.round(((price - activeGroup.PaidAmount) / price) * 100)
        )}
        %)
      </small>
    )
  }

  function onChangeReviewState(val: any): void {
    if (disabled) {
      return
    }
    setActiveGroupFields((prev: any) => ({ ...prev, reviewState: val }))
  }

  if (!activeGroup) {
    return null
  }

  return (
    <aside className="group-info">
      <Grid container spacing={2}>
        <Grid item xs="auto" style={{ flex: 1 }}>
          <div className="data-display">
            <span>
              <strong>Employer</strong> {activeGroup.EmployerName}
            </span>
            <span>
              <strong>Service Date</strong>{' '}
              {dateTime.parse(activeGroup.ServiceDate).format()}
            </span>
            <span>
              <strong>Payment Date</strong>{' '}
              {dateTime.parse(activeGroup.PaymentDate).format()}
            </span>
            <span className="claim-link">
              <strong>Claim ID</strong>
              <a
                href={`https://app.sigmacomputing.com/zero-health/workbook/Claim-Status-Lookup-PHI-5YEds1Ptwwu7jhZw7EKcpF?farley_claim_id=${activeGroup.ClaimNr}`}
                target="_blank"
                rel="noopener noreferrer">
                {activeGroup.ClaimNr}
                <IconLaunch style={{ width: '12px' }} />
              </a>
            </span>
            <span>
              <strong>Group Nr</strong> {activeGroup.GroupNr}
            </span>
            <span>
              <strong>Providers</strong> {activeGroup.Providers}
            </span>
            <span>
              <strong>HRR</strong>{' '}
              {activeGroup.HrrCity || activeGroup.HrrState
                ? `${activeGroup.HrrCity}, ${activeGroup.HrrState}`
                : `---`}
            </span>
            <span>
              <strong>Paid Amount</strong>{' '}
              {tzcHelper.moneyFormat(activeGroup.PaidAmount)}
            </span>
          </div>

          <div className="gridded">
            <div className="item">
              <TextField
                type="number"
                value={activeGroupFields.typicalPPOPrice}
                variant="standard"
                placeholder="YYY.ZZ"
                // hiddenLabel
                label="Typical PPO"
                size="small"
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                  if (disabled) {
                    return
                  }
                  const val = ev.target.valueAsNumber
                  setActiveGroupFields((prev: any) => ({
                    ...prev,
                    typicalPPOPrice: val,
                  }))
                }}
                disabled={disabled}
                helperText={priceHint(activeGroup.TypicalPPOPrice)}
                style={{ width: 'min-content', minWidth: 100 }}
              />
            </div>
            <div className="item">
              <TextField
                fullWidth
                type="number"
                value={activeGroupFields.typicalHrrPpoPrice}
                variant="standard"
                placeholder="YYY.ZZ"
                // hiddenLabel
                label="Typical HRR PPO"
                size="small"
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                  if (disabled) {
                    return
                  }
                  const val = ev.target.valueAsNumber
                  setActiveGroupFields((prev: any) => ({
                    ...prev,
                    typicalHrrPpoPrice: val,
                  }))
                }}
                disabled={disabled}
                helperText={priceHint(activeGroup.TypicalHrrPpoPrice)}
                style={{ width: 'min-content', minWidth: 100 }}
              />
            </div>
            <div className="item">
              <ReviewStateSelector
                value={
                  isNumber(activeGroupFields.reviewState)
                    ? activeGroupFields.reviewState
                    : ''
                }
                onChange={onChangeReviewState}
                disabled={disabled}
              />
            </div>
            <div className="item display-cost-key">
              <strong>Cost Key</strong>
              <span onClick={onClickCostKeySearch}>
                {/* <IconEdit className='trigger-edit-icon' /> */}
                {activeGroupFields.costKey}
              </span>
              <small>{activeGroupFields.costKeyDescr}</small>
            </div>
            <div className="item notes">
              <TextField
                fullWidth
                value={activeGroupFields.notes || ''}
                variant="standard"
                placeholder="Notes"
                size="small"
                multiline
                rows={3}
                // hiddenLabel
                label="Notes"
                InputLabelProps={{ shrink: true }}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                  if (disabled) {
                    return
                  }
                  const val = ev.target.value
                  setActiveGroupFields((prev: any) => ({
                    ...prev,
                    notes: val || null,
                  }))
                }}
                disabled={disabled}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs="auto">
          <div className="save-review">
            <Button
              disabled={disabled}
              onClick={onClickSaveGroup}
              variant="contained"
              color="default"
              disableElevation
              size="medium"
              fullWidth>
              Save Review
            </Button>
            <div>
              <FormControlLabel
                label="Ignore"
                control={
                  <Checkbox
                    checked={activeGroupFields.isIgnored}
                    onChange={() => {
                      if (disabled) {
                        return
                      }
                      setActiveGroupFields((prev: any) => ({
                        ...prev,
                        isIgnored: !prev.isIgnored,
                      }))
                    }}
                    disabled={disabled}
                  />
                }
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </aside>
  )
}
