import React, { useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { renderTextField, SetterArgs } from '../../ViewHelpers'
import { EmployerActions, SnackbarActions } from '../../../actions'
const { setSnackbarMessage } = SnackbarActions

interface componentProps {
  open: boolean
  employerID: number
  onCancel(): void
  onSuccess(): void
  setSnackbarMessage(message: string, level: string): void
}

interface editFields {
  FirstName: string
  LastName: string
  Email: string
}

const DialogAddTeamZeroUser = ({
  employerID,
  open,
  onCancel,
  onSuccess,
  setSnackbarMessage,
}: componentProps) => {
  const defaultState = {
    FirstName: '',
    LastName: '',
    Email: '',
  }

  const [fields, setFields]: [editFields, any] = useState(defaultState)

  const setter = ({ name, value }: SetterArgs) => {
    setFields(Object.assign({}, fields, { [name]: value }))
  }

  const doCreate = () => {
    EmployerActions.createTeamZeroUser({ employerID }, fields)
      .then((res: any) => {
        if (res.Error) {
          return setSnackbarMessage(res.Error.Message, 'error')
        }
        setSnackbarMessage('New TeamZERO user created OK', 'success')
        setFields(defaultState)
        onSuccess()
      })
      .catch((res: any) => {
        let msg =
          'An error occurred creating this user; please contact Engineering'
        if (res.Error && res.Error.DetailedMessage) {
          msg = res.Error.DetailedMessage
        }
        setSnackbarMessage(msg, 'error')
      })
  }

  const cancel = () => {
    setFields(defaultState)
    onCancel()
  }

  return (
    <Dialog open={open} onClose={cancel}>
      <DialogTitle>Create New TeamZERO User</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This will setup a new user account, configured with access to only
          this employer. The user will need to use the password reset mechanism
          in order to finalize their account setup.{' '}
          <strong>
            As a favor to your favorite engineering team, please{' '}
            <i>double and triple check the email address before completing!</i>
          </strong>
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {renderTextField({
              name: 'FirstName',
              label: 'First Name',
              setter,
              value: fields.FirstName,
              opts: { variant: 'outlined', size: 'small' },
            })}
          </Grid>
          <Grid item xs={6}>
            {renderTextField({
              name: 'LastName',
              label: 'Last Name',
              setter,
              value: fields.LastName,
              opts: { variant: 'outlined', size: 'small' },
            })}
          </Grid>
          <Grid item xs={12}>
            {renderTextField({
              name: 'Email',
              label: 'Email',
              setter,
              value: fields.Email,
              opts: { variant: 'outlined', size: 'small' },
            })}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={cancel}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={doCreate}>
          Create User
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogAddTeamZeroUser.defaultProps = {}

export default connect(
  (_state: any) => {
    return {}
  },
  (dispatch: any) => {
    return bindActionCreators({ setSnackbarMessage }, dispatch)
  }
)(DialogAddTeamZeroUser)
