import React, { useEffect, useState } from 'react'
import useQueryParamsGen2 from '../../hooks/useQueryParamsGen2'
import PageClaims from './pageClaims'
import PageFunding from './pageFunding'
import PageInvoicing from './pageInvoicing'
import PageReceipts from './pageReceipts'
import PagePayables from './pagePayables'
import { useSetFrameHeaderLeft } from '../../components/AppFrame'
import { Chip } from '@material-ui/core'
//@ts-ignore
import styled from 'styled-components'

const StyledComponent = styled.div`
  height: 100%;
`

const StyledHeaderTabs = styled.div``

const TAB_CLAIMS = 'tab_claims'
const TAB_FUNDING_REVIEW = 'tab_funding_review'
const TAB_INVOICING = 'tab_invoicing'
const TAB_RECEIPTS = 'tab_receipts'
const TAB_PAYABLES = 'tab_payables'

export default function ZClaims(/*props : any*/): React.ReactElement {
  const { queryData, setQueryData } = useQueryParamsGen2({
    scope: 'zclaims.tabs',
  })
  const [tabVal, setTabVal] = useState(queryData?.tabVal || TAB_CLAIMS)

  useEffect(() => {
    setQueryData({ tabVal })
  }, [tabVal])

  useSetFrameHeaderLeft(
    () => (
      <div>
        <StyledHeaderTabs>
          <Chip
            variant={tabVal === TAB_CLAIMS ? 'default' : 'outlined'}
            label="Claims"
            onClick={() => setTabVal(TAB_CLAIMS)}
          />
          &nbsp;
          <Chip
            variant={tabVal === TAB_FUNDING_REVIEW ? 'default' : 'outlined'}
            color="primary"
            label="Funding Review"
            onClick={() => setTabVal(TAB_FUNDING_REVIEW)}
          />
          &nbsp;
          <Chip
            variant={tabVal === TAB_INVOICING ? 'default' : 'outlined'}
            color="primary"
            label="Invoicing"
            onClick={() => setTabVal(TAB_INVOICING)}
          />
          &nbsp;
          <Chip
            variant={tabVal === TAB_RECEIPTS ? 'default' : 'outlined'}
            color="primary"
            label="Receipts"
            onClick={() => setTabVal(TAB_RECEIPTS)}
          />
          &nbsp;
          <Chip
            variant={tabVal === TAB_PAYABLES ? 'default' : 'outlined'}
            color="primary"
            label="Payables"
            onClick={() => setTabVal(TAB_PAYABLES)}
          />
        </StyledHeaderTabs>
      </div>
    ),
    [tabVal, setTabVal]
  )

  return (
    <StyledComponent>
      {tabVal === TAB_CLAIMS && <PageClaims />}
      {tabVal === TAB_FUNDING_REVIEW && <PageFunding />}
      {tabVal === TAB_INVOICING && <PageInvoicing />}
      {tabVal === TAB_RECEIPTS && <PageReceipts />}
      {tabVal === TAB_PAYABLES && <PagePayables />}
    </StyledComponent>
  )
}
