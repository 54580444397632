import React, { useState } from 'react'
import DesignSuite2023 from '../../components/DesignSuite2023'
import ConfirmDialog from '../../components/ConfirmDialog'
import { retireBillingEntities } from '../../actions/BillingEntityActions'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import { Button } from '@material-ui/core'

interface props {
  ids: number[]
  onDone?(): void
}

export default function BtnRetireBillingEntities({
  ids,
  onDone,
}: props): React.ReactElement | null {
  const [isWorking, setIsWorking] = useState(false)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const isDisabled = React.useMemo(() => {
    if ((ids || []).length === 0) return true
    if (isWorking) return true
    return false
  }, [ids, isWorking])

  function doDelete() {
    ConfirmDialog({
      content:
        'Do you really want to retire the selected Billing Entity(s)? There is no way to re-activate these records once they are retired; they are only kept around for historical purposes - but cannot be used in ZClaims or GoZERO.',
      onConfirm() {
        setIsWorking(true)
        retireBillingEntities({
          BillingEntity2IDs: ids,
        })
          .then((res: any) => {
            if (res.error) throw res
            showSnackbar('Billing Entity(s) deleted OK', SnackbarTypeSuccess)
          })
          .catch(
            catchAPIError({
              defaultMessage: 'Failed deleting billing entities',
            })
          )
          .finally(() => {
            setIsWorking(false)
            onDone?.()
            // refTable.current?.refresh()
          })
      },
    })
  }

  return (
    <DesignSuite2023.Tooltip title="Disable the selected Billing Entities">
      <span>
        <Button
          disabled={isDisabled}
          variant="contained"
          color="secondary"
          onClick={doDelete}>
          Retire
          {isWorking && (
            <>
              &nbsp;
              <DesignSuite2023.LoadingSpinner size={20} show={isWorking} />
            </>
          )}
        </Button>
      </span>
    </DesignSuite2023.Tooltip>
  )
}
