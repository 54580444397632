import React, { forwardRef } from 'react'
import * as stdTableSetup from '../../../../../hooks/useStandardTableSetup'
import { findUnprocessedZClaimSrcGoZero } from '../../../../../actions/ZClaimActions'
import dateTime from '../../../../../utils/dateTime'
import styled from 'styled-components'

// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../../../../hooks/useStandardTableSetup'

const StyledTableWrap = styled.div`
  .MuiIconButton-sizeSmall {
    padding: 7px;
    font-size: 1.3rem;
  }
`

export const defaultColumns = Object.freeze({
  ID: { name: 'ID', details: { sortName: `default` } },
  CreatedAt: {
    name: 'Created',
    details: {
      dataFormat: dateTime.cellFormatter(dateTime.formats.PrettyVerbose),
    },
  },
  ReferralInvoiceID: { name: 'Referral Invoice ID' },
  MemberName: { name: 'Member Name' },
  OrganizationName: { name: 'Organization Name' },
  ClaimLinesCount: { name: 'Line Count' },
})

const defaultSort = { col: 'zclaim_src_go_zero.id', dir: 'desc' }

export const Table = forwardRef(function GoZeroTable(
  {
    customColumns = defaultColumns,
    apiEndpoint = findUnprocessedZClaimSrcGoZero,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      initPageSize: 25,
      DataTableProps: {
        ...DataTableProps,
        density: 'small',
        rowsPerPage: [10, 25, 50, 100, 250, 500],
      },
    },
    ref
  )

  return <StyledTableWrap>{TableDisplay}</StyledTableWrap>
})
