// @ts-ignore
import React, { useState } from 'react'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import * as PGPKeysTable from './Table'
import { deletePGPKeys } from '../../../actions/EngineeringActions'
// @ts-ignore
import styled from 'styled-components'
import * as DialogPGPKey from './DialogPGPKey'
import * as types from './types'
import {
  Paper,
  Button,
  // @ts-ignore
} from '@material-ui/core'

const PaperWrapper = styled(Paper)`
  padding: 0.25rem 1rem 1rem;
`

export default function PGPKeys() {
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const [checkedItems, setCheckedItems] = useState<any[]>([])

  const refTable: any = React.useRef()
  const refPGPDialog = React.useRef<DialogPGPKey.DialogAPI>(null)

  function onRowClick(row: any) {
    refPGPDialog.current?.editPGPKeyPublicID(row.ID)
  }

  function onCheckHandler(selected: any[]) {
    setCheckedItems(selected)
  }

  function onDialogSave() {
    refTable.current?.refresh()
  }

  function onClickDelete() {
    deletePGPKeys(checkedItems.map((v: types.PGPKey) => v.ID))
      .then((res: any) => {
        if (res.error) throw res
        refTable.current?.refresh()
        showSnackbar('PGP Keys deleted OK', SnackbarTypeSuccess)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed deleting one or more PGP keys',
        })
      )
  }

  return (
    <>
      <PaperWrapper elevation={2}>
        <PGPKeysTable.Table
          enableURLReflection
          ref={refTable}
          onCheckHandler={onCheckHandler}
          onRowClick={onRowClick}
          RightHeaderItems={
            <>
              <small>
                Notice: keys can only be added via SFTPaas Recipients
              </small>
            </>
          }
          LeftHeaderItems={
            <>
              <PGPKeysTable.StandardFilterSearch autoFocus />
            </>
          }
          DataTableProps={{
            LeftFooterItems: (
              <>
                {!!checkedItems.length && (
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={onClickDelete}>
                    Delete
                  </Button>
                )}
              </>
            ),
          }}
        />
      </PaperWrapper>

      <DialogPGPKey.Dialog ref={refPGPDialog} onSave={onDialogSave} />
    </>
  )
}
