import util from './utils'
import { PriceActions } from '../actions'
const {
  SAVE_NEW_PRICE,
  SAVE_PRICE,
  FIND_PRICES,
  CLEAR_SAVE_RESULT,
  RESET_PRICE_STATE,
  DEACTIVATE_PRICES,
  BACKDATE_PRICES,
  DELETE_PRICES,
  FIND_COMPONENT_PRICES,
  SAVE_NEW_COMPONENT_PRICE,
  UPDATE_COMPONENT_PRICE,
} = PriceActions

export default function (state = null, action) {
  switch (action.type) {
    case FIND_PRICES:
    case FIND_COMPONENT_PRICES:
      if (action.payload.error) {
        return {
          ...state,
          getError: util.errorResponse(action),
        }
      }
      return {
        ...state,
        prices: action.payload.Data,
        pricesCount: action.payload.Meta.Total,
      }
    case SAVE_PRICE:
    case UPDATE_COMPONENT_PRICE:
    case SAVE_NEW_PRICE:
    case SAVE_NEW_COMPONENT_PRICE:
    case DEACTIVATE_PRICES:
    case BACKDATE_PRICES:
    case DELETE_PRICES:
      if (action.payload.error) {
        return {
          ...state,
          saveResult: util.errorResponse(action),
        }
      }
      return {
        ...state,
        saveResult: {
          saved: true,
          type: action.type,
        },
      }
    case CLEAR_SAVE_RESULT:
      return {
        ...state,
        saveResult: null,
      }
    case RESET_PRICE_STATE:
      return {
        prices: [],
        pricesCount: 0,
        saveResult: null,
      }
    default:
      return {
        ...state,
      }
  }
}
