import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { DataTable } from '../../components/DataTable'
import { debounce } from 'lodash'
import * as api from '../../services/thezerocard/api-helper'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
  SnackbarTypeWarning,
} from '../../hooks/useSnackbar'
import { useConfig } from '../../providers/Config'
import CopyIcon from '../../components/CopyIcon'
import utils from '../../utils'
import { fileTypeLinksFormatter } from '../../utils/ReactFormatters'
import Employer from '../../models/Employer'
import useQueryParamsGen2 from '../../hooks/useQueryParamsGen2'
import { Grid, InputAdornment, TextField } from '@material-ui/core'
import { Cancel as IconCancel, Search as IconSearch } from '@material-ui/icons'
import dateTime from '../../utils/dateTime'

require('./invoiceTable.scss')

/*
The component's output is shaped like below, whereas the ...HeaderItems things are all
optional, and will be dropped into these places. Idea is to keep the API surface small
and allow injecting whatever components you want, and have them display in a predictable
manner, instead of tightly coupling all the components *within* this InvoiceTable one,
then passing a ton of different options (like ShowStatusFilter:true, CustomizeStatusFilter: {...}, etc)

________________________________________________________
| LeftHeaderItems |                 | RightHeaderItems |
--------------------------------------------------------
|                   SubHeaderItems                     |
--------------------------------------------------------
|                      DataTable                       |
*/
interface props {
  // RightHeaderItems can be used to inject child components into the top right section of a
  // <Grid> that's part of this component; it should be used to inject other things like custom
  // filter components (eg. file type filters), then pass those filter values through the
  // passFilters prop. This is how we avoid overly tight coupling
  RightHeaderItems?: React.ReactNode
  LeftHeaderItems?: React.ReactNode
  SubHeaderItems?: React.ReactNode
  passFilters?: any // customize filters sent to API /invoices request
  forceFilters?: any
  customColumns?: any
  disableSearch?: boolean
  DataTableProps?: any
  enableURLReflection?: boolean
}

export const InvoiceTableColumnsDefault = {
  ID: { name: 'ID', details: { hidden: true } },
  Number: { name: 'Invoice No.', details: { sortName: 'InvoiceNumber' } },
  CopyTeamLink: {
    name: 'Copy Link',
    details: {
      dataFormat: (cell: any, row: any) => (
        <CopyTeamLink cell={cell} row={row} />
      ),
    },
  },
  DateOfIssue: {
    name: 'Issued',
    details: { sortName: 'DateOfIssue', dataFormat: dateTime.cellFormatter() },
  },
  Files: { name: 'Files', details: { dataFormat: fileTypeLinksFormatter } },
  Status: {
    name: 'Status',
    details: {
      sortName: 'StatusID',
      dataFormat(cell: any, _: any) {
        return cell.Descr
      },
    },
  },
  Amount: {
    name: 'Amount',
    details: { sortName: 'InvoiceAmount', dataFormat: utils.moneyFormatter },
  },
  Type: {
    name: 'Type',
    details: {
      dataFormat(cell: any, _: any) {
        return cell.Descr
      },
    },
  },
  ACHWeekday: {
    name: 'ACH',
    details: {
      dataFormat(cell: any, _: any) {
        const opts = Employer.achWeekdayOptions
        const val = opts.find((opt) => opt.value === cell)
        return val ? val.label : ''
      },
    },
  },
  BillingMethod: {
    name: 'Method',
    details: {
      dataFormat(cell: any, _: any) {
        const opts = Employer.billingMethodOptions
        const val = opts.find((opt) => opt.value === cell)
        return val ? val.label : ''
      },
    },
  },
  Notes: { name: 'Notes', details: {} },
}

export default forwardRef(function InvoiceTable(
  {
    RightHeaderItems = null,
    LeftHeaderItems = null,
    SubHeaderItems = null,
    passFilters = {},
    forceFilters = null,
    disableSearch = false,
    customColumns = InvoiceTableColumnsDefault,
    DataTableProps = {},
    enableURLReflection = false,
  }: props,
  ref: any
): React.ReactElement {
  const { queryData, setQueryData } = useQueryParamsGen2()
  const [lastRefresh, setLastRefresh] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [results, setResults] = useState<any[]>([])
  const [resultCount, setResultCount] = useState(0)
  const [filter, setFilter] = useState({
    q: '',
    ...(enableURLReflection ? queryData.filter : {}),
    ...passFilters,
  }) // important that passFilters is always spread LAST (props should override selectable-configs)
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    ...(enableURLReflection ? queryData.pagination : {}),
  })
  const [sortable, setSortable] = useState({
    col: 'DateOfIssueAndEmployer',
    dir: 'desc',
    ...(enableURLReflection ? queryData.sortable : {}),
  })
  const [columns] = useState({ ...customColumns })
  const { showForDuration: showSnackbar } = useSnackbar()

  const _doUpdate = useCallback(() => {
    setLastRefresh(new Date().toISOString())
  }, [setLastRefresh])

  /*
    This exposes a public api via a ref to the parent component. Is this a great idea?
    Probably not - but if someone else can figure out a good way to have a component refresh
    it's state in a more simple way, please enlighten me.
  */
  useImperativeHandle(
    ref,
    () => ({
      refresh: _doUpdate,
    }),
    [_doUpdate]
  )

  const loadInvoices = useCallback(
    debounce((payload: any) => {
      const body = { ...payload }
      if (!!body.filter.q && body.filter.q.length < 3) {
        body.filter.q = ''
        showSnackbar(
          'Text search will not apply until at least 3 characters',
          SnackbarTypeWarning,
          2000
        )
      }

      ;(api.search(`/invoice`, body) as Promise<any>)
        .then((res: any) => {
          if (res.error) {
            throw res
          }
          setResults(res.Data || [])
          setResultCount(res.Meta?.Total || 0)
        })
        .catch((err: any) => {
          if (err.Error?.Message) {
            showSnackbar(err.Error.Message, SnackbarTypeError)
            return
          }
          showSnackbar(
            'Request failed; please contact engineering',
            SnackbarTypeError
          )
        })
        .finally(() => {
          setIsLoading(false)
        })
    }, 1000),
    [setIsLoading, setResults, setResultCount]
  )

  useEffect(() => {
    setIsLoading(true)

    // Force filters are a concept where a set of filters can be passed in that will NOT
    // be merged with existing ones; IOW force filters are all that will be sent to the
    // backend and applied. Generally filters are treated as additive, but this avoids that
    // and empowers callers of this component to specify it's use in purposefully restrictive ways
    if (!!forceFilters) {
      loadInvoices({
        filter: forceFilters,
        sort: [sortable.col, sortable.dir],
        ...pagination,
      })
      return
    }

    enableURLReflection && setQueryData({ filter, pagination, sortable })
    loadInvoices({
      filter,
      sort: [sortable.col, sortable.dir],
      ...pagination,
    })
  }, [
    filter,
    forceFilters,
    pagination,
    sortable,
    setQueryData,
    setIsLoading,
    loadInvoices,
    lastRefresh,
  ])

  useEffect(() => {
    setFilter((ff: any) => {
      return { ...ff, ...passFilters }
    })
  }, [passFilters, setFilter])

  return (
    <div className="invoice-table-wrap">
      <Grid container spacing={2} justify="space-between">
        <Grid
          item
          xs={12}
          md="auto"
          className="invoice-table-header-items-left">
          <Grid container spacing={2} alignItems="center">
            {disableSearch !== true && (
              <Grid item xs="auto">
                <TextField
                  autoFocus
                  placeholder="Start typing to search"
                  size="small"
                  variant="outlined"
                  value={filter.q}
                  onChange={(ev: any) => {
                    const q = ev.target?.value
                    setFilter((ff: any) => {
                      return { ...ff, q }
                    })
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconSearch />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconCancel
                          color="action"
                          fontSize="small"
                          onClick={() => {
                            setFilter((curr: any) => ({ ...curr, q: '' }))
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            )}
            {!!LeftHeaderItems && (
              <Grid item xs="auto">
                {LeftHeaderItems}
              </Grid>
            )}
          </Grid>
        </Grid>
        {!!RightHeaderItems && (
          <Grid
            item
            xs={12}
            md="auto"
            className="invoice-table-header-items-right">
            {RightHeaderItems}
          </Grid>
        )}
      </Grid>

      {!!SubHeaderItems && (
        <div className="invoice-table-subheader-items">{SubHeaderItems}</div>
      )}

      <DataTable
        className="invoice-data-table"
        loading={isLoading}
        keyProp="ID"
        data={results}
        columns={columns}
        initPage={pagination.page * 1}
        initPageSize={pagination.pageSize * 1}
        count={resultCount * 1}
        onChangePage={setPagination}
        onChangeRowsPerPage={setPagination}
        rowsPerPage={[5, 10, 25, 50, 100, 150]}
        sortHandler={setSortable}
        sortable={sortable}
        {...DataTableProps}
      />
    </div>
  )
})

function CopyTeamLink({ row }: any): React.ReactElement {
  const { teamzeroUrl } = useConfig()
  const { showForDuration: showSnackbar } = useSnackbar()
  const { ID: invoiceID, EmployerId: employerId } = row
  const link = `${teamzeroUrl}/employer/${employerId}/invoices?viewingID=${invoiceID}&viewing=true`
  const onCopied = useCallback(() => {
    showSnackbar(
      `TeamZERO link copied for Invoice ID: ${invoiceID}`,
      SnackbarTypeSuccess
    )
  }, [showSnackbar])
  const onCopyErr = useCallback(() => {
    showSnackbar(
      'Failed copying link to clipboard: careful what you paste next, it may be incorrect.',
      SnackbarTypeError
    )
  }, [])
  return (
    <CopyIcon value={link} onCopySuccess={onCopied} onCopyError={onCopyErr} />
  )
}
