import React, { useCallback } from 'react'
import Models from '../../models'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
import dateTime from '../../utils/dateTime'
require('./dateInputsByNextStatus.scss')

const { STATUS_PAID_FULL, STATUS_PAID_FARLEY, STATUS_SENT, STATUS_APPROVED } =
  Models.Invoice

interface collection {
  DateOfIssue?: any // if its a SINGLE invoice, this would be applicable
  SentDate: any
  ApprovedDate: any
  FundedDate: any
  FarleyPaidDate: any
}

// this is the 'setter' function passed below, satisifed by the second argument
// returned from a destructured useState() call
interface DateInputsCollectionSetterFn {
  (c: collection): collection
}

interface props {
  nextStatusID: number
  entity: collection
  setter(fn: DateInputsCollectionSetterFn): void
}

export default function DateInputsByNextStatus({
  nextStatusID = 0,
  entity = {} as collection,
  setter,
}: props): React.ReactElement | null {
  const showIfStatus = useCallback(
    (stats: number[], el: React.ReactElement): React.ReactElement => {
      if (stats.find((st) => st === nextStatusID)) {
        return el
      }
      return <span>N/A</span>
    },
    [nextStatusID]
  )

  if (!nextStatusID || !entity) {
    return null
  }

  return (
    <div className="invoice-date-inputs-by-next-status">
      {!!entity.DateOfIssue && (
        <>
          <strong>Issued On</strong>
          <div>{dateTime.parse(entity.DateOfIssue).format()}</div>
        </>
      )}

      <strong>Sent On</strong>
      <div>
        {showIfStatus(
          [STATUS_SENT, STATUS_APPROVED, STATUS_PAID_FULL, STATUS_PAID_FARLEY],
          <ManagedDateInput
            name="SentDate"
            label="Sent Date"
            value={entity.SentDate}
            setter={({ name, value }) =>
              setter((c: collection) => ({ ...c, [name]: value }))
            }
          />
        )}
      </div>

      <strong>Approved On</strong>
      <div>
        {showIfStatus(
          [STATUS_APPROVED, STATUS_PAID_FULL, STATUS_PAID_FARLEY],
          <ManagedDateInput
            name="ApprovedDate"
            label="Approved Date"
            value={entity.ApprovedDate}
            setter={({ name, value }) =>
              setter((c: collection) => ({ ...c, [name]: value }))
            }
          />
        )}
      </div>

      <strong>Funded On</strong>
      <div>
        {showIfStatus(
          [STATUS_PAID_FULL, STATUS_PAID_FARLEY],
          <ManagedDateInput
            name="FundedDate"
            label="Funded Date"
            value={entity.FundedDate}
            setter={({ name, value }) =>
              setter((c: collection) => ({ ...c, [name]: value }))
            }
          />
        )}
      </div>

      <strong>Farley Paid On</strong>
      <div>
        {showIfStatus(
          [STATUS_PAID_FARLEY],
          <ManagedDateInput
            name="FarleyPaidDate"
            label="Farley Paid Date"
            value={entity.FarleyPaidDate}
            setter={({ name, value }) =>
              setter((c: collection) => ({ ...c, [name]: value }))
            }
          />
        )}
      </div>
    </div>
  )
}
