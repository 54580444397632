import React, { useState, useEffect, useCallback } from 'react'
import {
  getReviewableClaims,
  updateClaimSummary,
  updateClaimLines,
} from '../../actions/SavingsReviewActions'
import * as typed from './typed'
import GroupDisplay, { filterFieldDefaults } from './groupDisplay'
import GroupFiltersAndList, { activeFieldDefaults } from './groupFiltersAndList'
import LinesDisplay from './linesDisplay'
import CostKeyQuickSearcher from './costKeyQuickSearch'
import ShortcutButtons from './shortcutButtons'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import { Button, Paper } from '@material-ui/core'
import { GridSortModel } from '@mui/x-data-grid'
import { isEqual, isNumber, debounce } from 'lodash'
import useQueryParamsGen2 from '../../hooks/useQueryParamsGen2'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import './style.scss'

export default function SavingsReview(props: any): any {
  // See docs/comments for useQueryParamsGen2 about the optional callback provided to this
  // hook. Also, take special note of the casing differences (EmployerID vs employerId); the latter
  // being what we want to publicly honor as the contract.
  const { queryData, setQueryData } = useQueryParamsGen2({
    overRides(otherURLParams: any): any {
      const res: any = {}
      if (+otherURLParams.employerId > 0) {
        res.EmployerID = +otherURLParams.employerId
      }
      if (otherURLParams.claimNr) {
        res.ClaimNr = `${otherURLParams.claimNr}`
      }
      if (otherURLParams.groupNr) {
        res.GroupNr = `${otherURLParams.groupNr}`
      }
      return res
    },
  })

  const { showForDuration: showSnackbarDuration } = useSnackbar()
  const [reviewGroupIndex, setReviewGroupIndex] = useState(0)
  const [reviewGroups, setReviewGroups] = useState<typed.GroupRecord[]>([])
  const [costKeySearchOpen, setCostKeySearchOpen] = useState(false)
  const [activeGroupFields, setActiveGroupFields] = useState<any>({
    ...activeFieldDefaults,
  })
  const [filterFields, setFilterFields] = useState<typed.GroupFilterFields>({
    ...filterFieldDefaults,
    ...queryData,
  })
  const [trackedLines, setTrackedLines] = useState<typed.TrackedLines>({
    lines: [],
    selectedIDs: [],
    initiallySelectedIDs: [],
    changed: false,
  } as typed.TrackedLines)
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    setQueryData(filterFields)
  }, [filterFields, setQueryData])

  // Hack: the layout of page containers used by React app defaults messes with the
  // ability to use position:sticky CSS, so we're screwing with that stuff for *for this
  // page/component only* in the CSS. This useEffect below will add a class to the HTML
  // root when mounted, and remove it when unmounted.
  useEffect(() => {
    document.documentElement.classList.add('savings-review-v2-page')
    return () => {
      document.documentElement.classList.remove('savings-review-v2-page')
    }
  }, [])

  const loadReviewGroups = useCallback(
    debounce((ff: typed.GroupFilterFields) => {
      getReviewableClaims(ff)
        .then(({ Data }: { Data: typed.GroupRecord[] }) => {
          if (!Data || !(Data.length > 0)) {
            setReviewGroups([])
            setReviewGroupIndex(0)
            return
          }
          setReviewGroups(
            Data.map((v: typed.GroupRecord) => {
              v.id = v.ID
              return v /* data grid requires 'id' property */
            })
          )
          setReviewGroupIndex(0)
        })
        .catch(
          catchAPIError({
            defaultMessage: 'Failed loading savings review claim summaries',
          })
        )
    }, 500),
    []
  )

  /*
    As filter fields change, reload from server
  */
  useEffect(() => {
    loadReviewGroups(filterFields)
  }, [loadReviewGroups, filterFields])

  const onSelectCostKey = (v: typed.CostKeyResult): void => {
    setActiveGroupFields((prev: any) => {
      const next = { ...prev, costKey: v.Code, costKeyDescr: v.Descr }
      if (!isNaN(parseFloat(v.PricingInfo.NationalTypicalPPO || ''))) {
        next.typicalPPOPrice = parseFloat(
          v.PricingInfo.NationalTypicalPPO || ''
        )
      }
      if (!isNaN(parseFloat(v.PricingInfo.CurrentHRR || ''))) {
        next.typicalHrrPpoPrice = parseFloat(v.PricingInfo.CurrentHRR || '')
      }
      return next
    })
  }

  const onClickOpenCCSearch = () => {
    setCostKeySearchOpen(true)
  }

  const onClickStatusShortcutBtn = (ReviewState: number) => {
    if (!reviewGroups[reviewGroupIndex]) {
      return
    }
    doSaveGroup({ ReviewState })
  }

  const onClickSaveGroup = () => {
    doSaveGroup()
  }

  const onClickSaveChangedLines = (ev: React.MouseEvent<HTMLButtonElement>) => {
    if (!trackedLines.changed) {
      // user shouldn't ever be able to do this, but for insurance, its easy to prevent **just in case**
      showSnackbarDuration(
        'No changes made (nothing to do)',
        SnackbarTypeError,
        2000
      )
      return
    }
    if (trackedLines.selectedIDs.length === 0) {
      showSnackbarDuration(
        'At least one line must be selected (invalid changes)',
        SnackbarTypeError,
        2000
      )
      return
    }
    const data = {
      GroupNr: reviewGroups[reviewGroupIndex].GroupNr,
      AddedIDs: [] as Array<number>,
      RemovedIDs: [] as Array<number>,
    }
    trackedLines.selectedIDs.forEach((id: number) => {
      if (!trackedLines.initiallySelectedIDs.includes(id)) {
        data.AddedIDs.push(id)
      }
    })
    trackedLines.initiallySelectedIDs.forEach((id: number) => {
      if (!trackedLines.selectedIDs.includes(id)) {
        data.RemovedIDs.push(id)
      }
    })
    updateClaimLines(data)
      .then(({ Data }: any) => {
        loadReviewGroups(filterFields)
      })
      .catch(catchAPIError({ defaultMessage: 'Failed saving claim lines' }))
  }

  const onSortGroupsTable = (sm: GridSortModel) => {
    // https://mui.com/components/data-grid/sorting/
    if (isEqual(sm, filterFields.SortBy)) {
      return
    }
    setFilterFields((prev: typed.GroupFilterFields) => ({
      ...prev,
      SortBy: sm,
    }))
  }

  function doSaveGroup(edits?: any) {
    const payload = {
      ID: reviewGroups[reviewGroupIndex].ID,
      CostKey: activeGroupFields.costKey,
      TypicalPPOPrice: activeGroupFields.typicalPPOPrice,
      TypicalHrrPpoPrice: activeGroupFields.typicalHrrPpoPrice,
      ReviewState: activeGroupFields.reviewState,
      Notes: activeGroupFields.notes,
      IsIgnored: activeGroupFields.isIgnored,
      ...edits,
    }

    if (!payload.CostKey) {
      showSnackbarDuration(
        'Cost key is required (refusing group save)',
        SnackbarTypeError,
        2000
      )
      return
    }
    if (!isNumber(payload.ReviewState)) {
      showSnackbarDuration(
        'Review state is required (refusing group save)',
        SnackbarTypeError,
        2000
      )
      return
    }

    updateClaimSummary(payload)
      .then(() => {
        // remove the just-finished review line from reviewGroups, then
        // set the next in line to active
        let activeIndex = reviewGroupIndex
        setReviewGroups((prev: typed.GroupRecord[]): typed.GroupRecord[] => {
          let cloned = prev.slice()
          cloned.splice(reviewGroupIndex, 1)
          if (cloned.length === reviewGroupIndex) {
            activeIndex--
          }
          return cloned
        })
        setReviewGroupIndex(activeIndex)
      })
      .catch(catchAPIError({ defaultMessage: 'Failed saving group' }))
  }

  return (
    <div className="service-review-v2">
      <GroupDisplay
        filterFields={filterFields}
        setFilterFields={setFilterFields}
        reviewGroups={reviewGroups}
        setReviewGroups={setReviewGroups}
        reviewGroupIndex={reviewGroupIndex}
        setReviewGroupIndex={setReviewGroupIndex}
        onSortGroupsTable={onSortGroupsTable}
      />

      <Paper className="group-info-container" elevation={6}>
        <GroupFiltersAndList
          activeGroup={reviewGroups[reviewGroupIndex]}
          onClickCostKeySearch={onClickOpenCCSearch}
          onClickSaveGroup={onClickSaveGroup}
          activeGroupFields={activeGroupFields}
          setActiveGroupFields={setActiveGroupFields}
          disabled={trackedLines.changed}
        />
        <ShortcutButtons
          onClickStatusButton={onClickStatusShortcutBtn}
          disabled={trackedLines.changed}
        />
      </Paper>

      <LinesDisplay
        activeGroup={reviewGroups[reviewGroupIndex]}
        trackedLines={trackedLines}
        setTrackedLines={setTrackedLines}
      />

      <CostKeyQuickSearcher
        activeGroup={reviewGroups[reviewGroupIndex]}
        onSelectCostKey={onSelectCostKey}
        isOpen={costKeySearchOpen}
        setIsOpen={setCostKeySearchOpen}
      />

      {trackedLines.changed && (
        <Button
          className="btn-save-changed-lines"
          onClick={onClickSaveChangedLines}
          variant="contained"
          color="primary"
          disableElevation
          size="medium">
          Save Changed Lines
        </Button>
      )}

      <div className="power-user-tips">
        <strong className="blocked">Power user tips:</strong>
        <ul>
          <li>
            <code>CTRL+K</code> to open cost key search. The cost key you select
            will be assigned to the group currently being edited.
          </li>
        </ul>
      </div>
    </div>
  )
}
