import React, { useState, useEffect } from 'react'
import { SchmipitBody } from './types'
import FileLinkBtn from '../../components/FileLinkBtn/FileLinkBtn'
import { RouteComponentProps } from 'react-router'
import { fetchSchmipit } from '../../actions/SchmipitActions'
import { getPresignedUrlForDownload } from '../../actions/FileActions'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import styled from 'styled-components'
import dateTime from '../../utils/dateTime'
import DesignSuite2023 from '../../components/DesignSuite2023'
import { Grid, Typography } from '@material-ui/core'

const SchmipitPreview = styled.img`
  margin-top: 20px;
`

type SchmipitParams = {
  id: string
}

type SchmipitRoutableProps = RouteComponentProps<SchmipitParams>

const Schmipit: React.FC<SchmipitRoutableProps> = ({ match }) => {
  const id = match.params.id ? match.params.id : null

  const [schmipit, setSchmipit] = useState<SchmipitBody | null>(null)
  const [fileUrl, setFileUrl] = useState('')
  const imageStrings = ['.jpg', '.png', '.svg']
  const snackbar = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (!id) return
    fetchSchmipit(id)
      .then((res: any) => setSchmipit(res))
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to fetch Schmipit; please contact Engineering',
        })
      )
  }, [id])

  useEffect(() => {
    if (schmipit && schmipit.FileID) {
      getPresignedUrlForDownload(schmipit.FileID)
        .then((res: any) => setFileUrl(res.url))
        .catch(
          catchAPIError({
            defaultMessage:
              'Failed to retrieve presigned URL for download; please contact Engineering',
          })
        )
    }
  }, [schmipit])

  const handleGetDownloadURLFail = () => {
    snackbar.show(
      'Failed to retreive presigned URL for download, please contact Engineering',
      SnackbarTypeError
    )
  }

  const isFilePreviewable = () => {
    if (schmipit && schmipit.OriginalFileName) {
      let fileIsImage = false
      imageStrings.forEach((str) => {
        if (schmipit.OriginalFileName.includes(str)) fileIsImage = true
      })
      return fileIsImage
    }
    return false
  }

  return (
    <>
      <DesignSuite2023.LayoutContainer>
        <DesignSuite2023.LayoutPrimary>
          <Grid container justify="space-between">
            <Grid item xs={12}>
              <Typography variant="h5">
                A schmipit has been shared with you
              </Typography>
              {schmipit && schmipit.CreatedBy && schmipit.CreatedAt && (
                <Typography variant="subtitle1" color="textSecondary">
                  Created By: {schmipit.CreatedBy.FirstName}{' '}
                  {schmipit.CreatedBy.LastName} on{' '}
                  {dateTime.parse(schmipit.CreatedAt).local().format()}
                </Typography>
              )}
              <div>
                {schmipit && schmipit.FileID && (
                  <FileLinkBtn
                    phi={false}
                    loa={false}
                    fileId={schmipit.FileID}
                    filename={schmipit.OriginalFileName}
                    variant="outlined"
                    handleGetDownloadURLFail={handleGetDownloadURLFail}
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              {schmipit && schmipit.Notes && (
                <Typography variant="subtitle2">{schmipit.Notes}</Typography>
              )}
            </Grid>
          </Grid>
        </DesignSuite2023.LayoutPrimary>
      </DesignSuite2023.LayoutContainer>
      {isFilePreviewable() && (
        <>
          <DesignSuite2023.Divider style={{ marginTop: '0.5rem' }} />
          <SchmipitPreview src={fileUrl} />
        </>
      )}
    </>
  )
}

export default Schmipit
