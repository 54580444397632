function errorResponse(action) {
  const res = action.payload || action
  let message = ''

  if (res.Error) {
    message = `${res.Error.DetailedMessage || res.Error.Message}`
  }

  return {
    error: true,
    requestId: res.RequestId,
    message: message,
    data: res.Data,
  }
}

/**
 * Index file types that come in from the server
 * @param {Array} files
 */
function indexFileTypes(files) {
  const indexed = files
    .map((file) => {
      file.FileTypeDescr = file.FileType.Description
      return file
    })
    .sort((a, b) => {
      return b.ID - a.ID
    })

  return indexed
}

// format component types
const formatTypes = (data) => {
  return data.map((val) => ({ value: val, label: val }))
}

export default { errorResponse, indexFileTypes, formatTypes }
