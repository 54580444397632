import React from 'react'
import styled from 'styled-components'
import zcLogo from '../assets/zc-logo-2020.png'
import { LinearProgress } from '@material-ui/core'

const StyledCentral = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  color: #666;
  text-align: center;

  .wrap1 {
    display: inline-block;
    max-width: 520px;

    .zero-logo {
      display: block;
      max-width: 50%;
      margin: 0 auto;
      border-bottom: 3px double rgba(0, 0, 0, 0.25);
      padding: 1rem 2rem;
      margin-bottom: 1rem;
    }
  }

  .progresser {
    max-width: 50%;
    margin: 1rem auto 0;
  }
`

export default function DisplayPreState({
  children,
  showProgress = true,
}: any): React.ReactElement {
  return (
    <StyledCentral>
      <div className="wrap1">
        <img className="zero-logo" src={zcLogo} alt="ZeroCard Logo" />
        <div>{children}</div>
        {showProgress && <LinearProgress className="progresser" />}
      </div>
    </StyledCentral>
  )
}
