import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Chip } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'

import Models from '../../models'
import { DataTable } from '../../components/DataTable'
import { BundlerActions, GroupActions } from '../../actions'
import { defaultStyleCreator } from '../../components/DetailView/DetailView'

// TODO: remove the group actions when we cut over to bundler actions
const { findGroupBundlers, clearBundlers } = GroupActions
const { findBundlers } = BundlerActions

const styles = (theme) => {
  const defaults = defaultStyleCreator(theme)

  return Object.assign(defaults, {
    searchField: {
      display: 'inline-flex',
      marginTop: '16px',
    },
    field: {
      width: 200,
    },
  })
}

class BundlerTable extends React.Component {
  state = {
    page: 1,
    pageSize: 10,
    sortable: { col: 'Bundler.Name', dir: 'ASC' },
    filter: {
      q: '',
    },
  }

  componentWillUnmount() {
    this.props.clearBundlers()
  }

  UNSAFE_componentWillMount() {
    const { filter, page, pageSize, sortable } = this.state

    this.doFindBundlers({ filter, page, pageSize, sortable })
  }

  handleBundlerClick = (e, { ID }) => {
    console.debug(
      'Tried to click a bundler, but we dont have a detail page yet'
    )
  }

  handleShowChange = (val) => {
    const { filter, sortable } = this.state
    filter.show = val
    this.setState({ filter })
    this.doFindBundlers({ filter, page: 1, pageSize: 10, sortable })
  }

  doFindBundlers = ({ filter, page, pageSize, sortable }) => {
    const { groupIds } = this.props
    this.setState({ loading: true })

    if (groupIds && groupIds.length > 0) filter.AccessGroupIDs = groupIds

    const payload = { filter, page, pageSize }
    if (sortable.col && sortable.dir) {
      payload.sort = [sortable.col, sortable.dir]
    }
    // TODO: remove this when we hookup bundlers
    if (groupIds.length === 0) return
    this.props.findGroupBundlers({ groupId: groupIds[0] }).then((res) => {
      this.setState({ loading: false })
    })

    // use this when we hookup bundlers
    // this.props.findBundlers(payload).then(res => {
    //   this.setState({ loading: false })
    // })
  }

  handleChangePage = ({ page, pageSize }) => {
    const { filter, sortable } = this.state
    this.setState({ page, pageSize })
    this.doFindBundlers({ filter, page, pageSize, sortable })
  }

  rowOptsApplier = (row) => {
    if (row && row.ActiveProvider === false) {
      return { className: 'tr-warning' }
    }
    return null
  }

  renderStaticFilters = () => {
    const { groupIds, orgId } = this.props
    return (
      <div>
        {orgId && (
          <div>
            <Chip
              label={`Org Id: ${orgId}`}
              className={this.props.classes.chip}
            />
          </div>
        )}
        {groupIds && (
          <div>
            {groupIds.map((item) => {
              return (
                <Chip
                  label={`Group Id: ${item}`}
                  key={item}
                  className={this.props.classes.chip}
                />
              )
            })}
          </div>
        )}
      </div>
    )
  }

  renderTable = () => {
    const { searchError, bundlers, count, initParams } = this.props
    const { loading } = this.state
    const { page, pageSize } = initParams

    return (
      <DataTable
        columns={Models.Bundler.columns}
        keyProp="ID"
        data={bundlers}
        loading={loading}
        onRowClick={this.handleBundlerClick}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangePage}
        count={count}
        rowOptsApplier={this.rowOptsApplier}
        error={searchError}
        initPage={page * 1}
        initPageSize={pageSize * 1}
      />
    )
  }

  render() {
    return (
      <div>
        {this.renderStaticFilters()}
        {this.renderTable()}
      </div>
    )
  }
}

BundlerTable.propTypes = {
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  groupIds: PropTypes.array,
  initParams: PropTypes.object,
  showFilterOpts: PropTypes.object,
}

BundlerTable.defaultProps = {
  page: 1,
  pageSize: 10,
  initParams: {},
  showFilterOpts: { visible: true, disabled: false },
}

function mapStateToProps(state) {
  return {
    bundlers: state.groups.bundlers || [], // needs to be .bundlers when we hook them up
    count: (state.groups.bundlers && state.groups.bundlers.length) || 0,
    searchError: state.bundlers.getError || null,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { findBundlers, findGroupBundlers, clearBundlers },
    dispatch
  )
}

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BundlerTable)
