import React from 'react'
import { useConfig } from '../../../providers/Config'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import * as shapes from '../types'
import { Button } from '@material-ui/core'
import Models from '../../../models'

interface props {
  empMemRec: shapes.shapeEmployerMember | null
  memberData: shapes.shapeMember | null
}

const btnProps = {
  fullWidth: true,
  variant: 'outlined' as any, // build doesn't like this without 'any' ¯\_(ツ)_/¯
  endIcon: <DesignSuite2023.CommonIcons.IconOpenInNew />,
  target: '_blank',
}

export default function DisplaySidebarActionButtons({
  empMemRec,
  memberData,
}: props): React.ReactElement | null {
  const { referralAppLink } = useConfig()

  if (!empMemRec || !memberData) return null

  return (
    <>
      <Button {...btnProps} href={`/search?zipcode=${memberData?.Address.Zip}`}>
        Find a Procedure
      </Button>

      <Button
        {...btnProps}
        href={`${referralAppLink}/find_member?employerMemberID=${empMemRec.ID}`}>
        New Referral
      </Button>

      {!!empMemRec?.IsCurrentlyActive && Models.Eligibility.canAddLoa() && (
        <Button
          {...btnProps}
          href={`/loa?IntegrationId=${empMemRec?.IntegrationId}`}>
          New LOA
        </Button>
      )}
    </>
  )
}
