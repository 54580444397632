import React from 'react'
import * as api from '../../services/thezerocard/api-helper'
import GoZeroUserList from '../../components/GoZeroUsers/List'
import { useConfig } from '../../providers/Config'

export default function OrganizationGoZeroUsers({ organizationID }: any): any {
  const { referralAppLink } = useConfig()

  function fnSearchUsersToAdd(params: any): Promise<any> {
    return api.get(
      `/organization/${organizationID}/gozero/user_search`,
      null,
      params
    )
  }

  function fnGetUsers(): Promise<any> {
    return api.get(`/organization/${organizationID}/gozero/users`)
  }

  function fnFnGetAllGoZeroUserIDs(): Promise<any> {
    return api.get(`/organization/${organizationID}/gozero/all_user_ids`)
  }

  function fnPutToggleAdmin(userID: number, to: boolean): Promise<any> {
    return api.put(
      `/organization/${organizationID}/gozero/admin_user/${userID}`,
      { isAdmin: to }
    )
  }

  function fnPostSendPasswordResetEmail(userID: number): Promise<any> {
    return api.post(
      `/organization/${organizationID}/gozero/change_password_email`,
      { UserID: userID }
    )
  }

  function fnPutGoZeroUsers(payload: any): Promise<any> {
    return api.put(`/organization/${organizationID}/gozero/users`, payload)
  }

  function fnCreateGoZeroUser(body: any): Promise<any> {
    return api.post(`/organization/${organizationID}/gozero/user`, body)
  }

  function fnDeleteGoZeroUsers(payload: any): Promise<any> {
    return api.del(`/organization/${organizationID}/gozero/users`, payload)
  }

  const customColumns = {
    EditPerms: {
      name: 'Edit Permissions',
      details: {
        width: 200,
        dataFormat: (cell: any, row: any) => {
          const permLink = `${referralAppLink}/user_management/organization/${row.OrganizationID}/users/${row.UserID}/facility_permissions`
          return (
            <a href={permLink} target="_blank" rel="noopener noreferrer">
              Edit Permissions
            </a>
          )
        },
      },
    },
  }

  return (
    <GoZeroUserList
      fnSearchUsersToAdd={fnSearchUsersToAdd}
      fnGetUsers={fnGetUsers}
      fnGetAllGoZeroUserIDs={fnFnGetAllGoZeroUserIDs}
      fnPutToggleAdmin={fnPutToggleAdmin}
      fnPostSendPasswordResetEmail={fnPostSendPasswordResetEmail}
      fnPutGoZeroUsers={fnPutGoZeroUsers}
      fnCreateGoZeroUser={fnCreateGoZeroUser}
      fnDeleteGoZeroUsers={fnDeleteGoZeroUsers}
      customColumns={customColumns}
    />
  )
}
