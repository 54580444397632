import React, { useState, useEffect, useRef } from 'react'
import {
  getZClaimSrcRecord,
  putZClaimSrcRecordDefaulterBillingEntity,
} from '../../../../actions/ZClaimActions'
import DesignSuite2023 from '../../../../components/DesignSuite2023'
import styled from 'styled-components'
import DisplayKeyValueData from '../../Components/DisplayKeyValueData'
import * as BillingEntityTable from '../../../BillingEntities/BillingEntityTable2'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import * as ClaimTable from '../../Components/ClaimTable'
import { Typography } from '@material-ui/core'
import BtnCreateUncontractedBillingEntity from '../../Components/BtnCreateUncontractedBillingEntity'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'
import useSnackbar from '../../../../hooks/useSnackbar'
import dateTime from '../../../../utils/dateTime'
import {
  BillingEntityCreationSource,
  ShapeBillingEntity,
} from '../../../BillingEntities/types'
import BtnCreateNewBillingEntity from '../../../BillingEntities/BtnCreateNew'

const StyledWrap = styled.div`
  padding: 1.5rem;

  .std-table .header-items {
    margin-top: 0;
  }

  h4 {
    margin: 0;
  }

  .flexd {
    display: flex;
    align-items: start;
    width: 100%;

    .src-info {
      padding-right: 1rem;
    }

    .right {
      flex: 1;
      overflow: clip;
      overflow-clip-margin: 1rem;
    }
  }
`

interface props {
  kind: string
  hash: string
}

export default function ReconcileSrcBillingEntity({
  kind,
  hash,
}: props): React.ReactElement {
  const [srcRec, setSrcRec] = useState<any>(null)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const refClaimsTable = useRef<any>(null)
  const refBillEntTable = useRef<any>(null)
  // const refDlgBillingEntity = useRef<any>(null)

  useEffect(() => {
    getZClaimSrcRecord({ kind, hash }).then(({ Data }: any) => {
      setSrcRec(Data)
    })
  }, [kind, hash])

  function onSelect(row: any) {
    console.log('onSelect', row)
    ConfirmDialog({
      content: (
        <div>
          <Typography variant="h5">{row.Name}</Typography>
          <Typography variant="body2" color="textSecondary">
            <strong>Organization:</strong> {row.OrganizationName}
            <br />
            <strong>NPI:</strong> {row.NPI || '?'} | <strong>TIN:</strong>{' '}
            {row.TIN || '?'}
            <br />
            <strong>Address:</strong>{' '}
            {row.RemittanceAddress?.DisplayAddress || '?'}
          </Typography>
          <DesignSuite2023.Divider />
          <Typography variant="body1" paragraph>
            This Billing Entity will be set on all claims{' '}
            <strong>currently in adjudication</strong>. NO CLAIM IN FUNDING
            REVIEW OR FARTHER ALONG WILL BE ADJUSTED. Are you sure you want to
            continue?
          </Typography>
          {/* <Typography variant="body1" paragraph>
            You selected the above Billing Entity as the default for this source
            record. All future claims with the same data will automatically link
            to this Billing Entity, and any current claims **that have not
            manually assigned the Billing Entity** will be auto-set.
          </Typography>
          <Typography variant="body2" color="textSecondary">
            If you think this Billing Entity even <strong>might</strong> be
            inaccurate, given what you see in the source data, do not continue
            and talk to a manager.
          </Typography> */}
        </div>
      ),
      onConfirm() {
        putZClaimSrcRecordDefaulterBillingEntity({
          Hash: srcRec.Hash,
          BillingEntityID: row.ID,
        })
          .then(() => {
            showSnackbar(
              'Assigned default billing entity for source record OK',
              'success'
            )
            refClaimsTable.current?.refresh()
          })
          .catch(
            catchAPIError({
              defaultMessage: 'Failed assigning defaulter',
            })
          )
      },
    })
  }

  function onNewBillingEntityCreated(be: ShapeBillingEntity) {
    refBillEntTable.current?.setFilterImmediate({
      q: be.TIN,
      organizationIDs: [],
      contractedStatus: null,
      creationSource: null,
    })
    alert(
      'New Billing Entity created OK. We attempted to filter the table to show the new Billing Entity for you, but if it does not apepar, you may need to toggle filters (like Source or Uncontracted to "Any").'
    )
  }

  return (
    <StyledWrap>
      <div className="flexd">
        <div className="src-info">
          <h4>Billing Provider Source Record</h4>
          <DisplayKeyValueData
            hideEmptyFields={false}
            data={srcRec}
            style={{ margin: '0.5rem 0' }}
            customRender={{
              TIN(v: any) {
                return (
                  <DesignSuite2023.Tooltip title="Click to search billing entities by this TIN">
                    <span
                      style={{
                        fontWeight: 'bold',
                        color: 'lightblue',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        // 'q' is the standard name for search property in all tables
                        refBillEntTable.current?.setFilterImmediate({ q: v })
                      }}>
                      {JSON.stringify(v)}
                    </span>
                  </DesignSuite2023.Tooltip>
                )
              },
              NPI(v: any) {
                return (
                  <DesignSuite2023.Tooltip title="Click to search billing entities by this NPI">
                    <span
                      style={{
                        fontWeight: 'bold',
                        color: 'lightblue',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        // 'q' is the standard name for search property in all tables
                        refBillEntTable.current?.setFilterImmediate({ q: v })
                      }}>
                      {JSON.stringify(v)}
                    </span>
                  </DesignSuite2023.Tooltip>
                )
              },
            }}
          />
          <DesignSuite2023.GridLR
            left={
              // <BtnCreateUncontractedBillingEntity
              //   srcRecID={currentInfo.SrcBillingProvider?.ID}
              //   onConfirm={() => refTable.current?.refresh()}
              // />
              <BtnCreateUncontractedBillingEntity
                srcRecID={srcRec?.ID}
                autoFixAttachedClaims={true}
                onConfirm={() => {
                  refClaimsTable.current?.refresh()
                }}
              />
            }
            right={
              !!srcRec?.ID ? (
                <BtnCreateNewBillingEntity
                  onSave={onNewBillingEntityCreated}
                  creationSource={BillingEntityCreationSource.ReviewedEDI}
                  tooltip="Create a Billing Entity from this info. You should only do this if you have already searched for, and could not locate, an existing Billing Entity to assign."
                  initValues={{
                    Name: srcRec.Name,
                    TIN: srcRec.TIN,
                    NPI: srcRec.NPI,
                    DateStart: dateTime.now().format(dateTime.formats.ISODate),
                    Notes: `Created from observed billing provider hash: ${srcRec.Hash}`,
                    OriginatingSrcHash: srcRec.Hash,
                    RemittanceAddress: {
                      ID: 0,
                      Address1: srcRec.Address1,
                      Address2: srcRec.Address2,
                      City: srcRec.City,
                      State: srcRec.State,
                      Zip: srcRec.Zip?.slice(0, 5),
                    },
                  }}
                />
              ) : null
            }
          />
        </div>
        <div className="right">
          <BillingEntityTable.Table
            ref={refBillEntTable}
            DataTableProps={{ density: 'small' }}
            onRowClick={onSelect}
            customColumns={BillingEntityTable.densifiedColumns}
            passFilters={{ isRetired: 'no' }}
            initPageSize={10}
            LeftHeaderItems={
              <>
                <BillingEntityTable.StandardFilterSearch />
                &nbsp;&nbsp;
                <BillingEntityTable.FilterContractedStatus />
                &nbsp;&nbsp;
                <BillingEntityTable.FilterOrganization />
              </>
            }
            RightHeaderItems={
              <>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ whiteSpace: 'nowrap' }}>
                  Use the filters to locate a <strong>Billing Entity</strong>
                </Typography>
              </>
            }
          />
        </div>
      </div>

      <DesignSuite2023.Divider />

      {srcRec?.Hash && (
        <ClaimTable.Table
          ref={refClaimsTable}
          passFilters={{ linkedToSrcHash: srcRec?.Hash }}
          LeftHeaderItems={
            <>
              <h4>Claims where this record appears</h4>
            </>
          }
        />
      )}
    </StyledWrap>
  )
}
