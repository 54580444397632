import { SnackbarActions } from '../actions'
const { SET_MESSAGE, CLEAR_SNACKBAR } = SnackbarActions

export default function (state = null, action) {
  switch (action.type) {
    case SET_MESSAGE: {
      const { type, message } = action.payload
      return {
        ...state,
        open: true,
        message,
        type,
      }
    }
    case CLEAR_SNACKBAR: {
      return {
        ...state,
        open: false,
        message: null,
        type: null,
      }
    }
    default:
      return {
        ...state,
      }
  }
}
