import React, { useState, useEffect } from 'react'
import { Button } from '@material-ui/core'
import Models from '../../models'
import { DataTable } from '../../components/DataTable'
import EditEligibilityFileConfigDetails from './EditEligibilityFileConfigDetails'
import styled from 'styled-components'

interface Props {
  getEligibilityFileConfigs?: any
  initParams: ConfigInitParams
  employerID: number
  eligibilityFileConfigs?: any
  count: number
  setSnackbarMessage?: any
  searchError?: any
  onViewConfig: (vcProps: ViewConfigProps) => void
  onCloseConfig: () => void
}

export interface ConfigInitParams {
  editing: boolean
  configID: number
}

export interface ViewConfigProps {
  configID: number
}

const AddNewBtn = styled(Button)`
  float: right;
  margin: 10px;
`

const EligibilityFileConfigsTable = ({
  searchError,
  eligibilityFileConfigs,
  initParams,
  employerID,
  ...props
}: Props) => {
  const [configEditOpen, setConfigEditOpen] = useState(false)
  const [clickedRowID, setClickedRowID] = useState(0)

  const rowOptsApplier = (row: any) => {
    if (!row.IsActive) {
      return { className: 'tr-warning' }
    }
    return null
  }

  const handleRowClick = (e: any, { ID }: any) => {
    props.onViewConfig({ configID: ID })
    setConfigEditOpen(true)
    setClickedRowID(ID)
  }

  const handleAddNew = () => {
    setClickedRowID(0)
    setConfigEditOpen(true)
  }

  const handleClose = () => {
    setConfigEditOpen(false)
    setClickedRowID(0)
    props.onCloseConfig()
  }

  const renderConfigEdit = () => {
    return (
      <EditEligibilityFileConfigDetails
        configID={clickedRowID}
        employerID={employerID}
        open={configEditOpen}
        handleClose={handleClose}
      />
    )
  }

  const renderTable = () => {
    return (
      <DataTable
        keyProp="ID"
        columns={Models.EligibilityFileConfig.columns}
        data={eligibilityFileConfigs}
        pagination={false}
        error={searchError}
        onRowClick={handleRowClick}
        rowOptsApplier={rowOptsApplier}
      />
    )
  }

  useEffect(() => {
    if (
      initParams &&
      initParams.editing === true &&
      initParams.configID !== 0
    ) {
      setConfigEditOpen(initParams.editing)
      setClickedRowID(initParams.configID)
    }
  }, [initParams])

  return (
    <div>
      {renderTable()}
      {configEditOpen && renderConfigEdit()}
      <AddNewBtn onClick={handleAddNew} color="primary" variant="contained">
        Add New
      </AddNewBtn>
    </div>
  )
}

EligibilityFileConfigsTable.defaultProps = {
  employerID: 0,
  eligibilityFileConfigs: [],
  count: 0,
  searchError: null,
  onViewConfig: () => null,
  onCloseConfig: () => null,
  initParams: { editing: false, configID: 0 },
}

export default EligibilityFileConfigsTable
