import React, { useEffect, useState } from 'react'
import { Grid, Button, CircularProgress } from '@material-ui/core'
import {
  renderSelectFieldWithTooltip,
  SelectItem,
  SetterArgs,
} from '../ViewHelpers'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import * as api from '../../services/thezerocard/api-helper'

interface InvoiceBatch {
  InvoiceBatchID: number
  InvoiceBatchDate: string // date
  InvoiceBatchCreatedAt: string //date
  AchNotificationApprovedAts: string[] //dates
  InvoicesInBatch: number
}

export const InvoiceBatchNotifier = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [batchSelectItems, setBatchSelectItems] = useState<SelectItem[]>(
    [] as SelectItem[]
  )
  const [selectedBatchID, setSelectedBatchID] = useState<number>(0)
  const [lastSentBatchID, setLastSentBatchID] = useState<number>(0)
  const { showForDuration: showSnackbar } = useSnackbar()

  useEffect(loadBatches, [])

  const handleNotifyClick = async () => {
    if (selectedBatchID) {
      setLoading(true)
      setLastSentBatchID(selectedBatchID)
      const res = (await api.post(`/invoice_ach_notification_approved`, {
        batchID: selectedBatchID,
      })) as any
      setLoading(false)
      if (res && !res.Error) {
        showSnackbar('Notifications triggered to send OK.', SnackbarTypeSuccess)
        loadBatches()
      } else {
        if (res.Error?.Message) {
          showSnackbar(res.Error.Message, SnackbarTypeError)
          return
        }
        showSnackbar(
          'Request failed; please contact engineering',
          SnackbarTypeError
        )
      }
    }
  }

  const setter = ({ name, value }: SetterArgs) => {
    console.debug('Invoice Notifier Setter: ', name, value)
    setSelectedBatchID(value)
  }

  function loadBatches(): void {
    setLoading(true)
    api
      .get(`/invoice_batch`)
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        setBatchSelectItems(convertBatchesToItems(res.Data as InvoiceBatch[]))
      })
      .catch((err: any) => {
        if (err.Error?.Message) {
          showSnackbar(err.Error.Message, SnackbarTypeError)
          return
        }
        showSnackbar(
          'Request failed; please contact engineering',
          SnackbarTypeError
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {loading ? (
          <CircularProgress />
        ) : (
          renderSelectFieldWithTooltip(
            {
              name: 'InvoiceBatchID',
              label: 'Select Invoice Batch',
              value: selectedBatchID,
              items: batchSelectItems,
              setter,
            },
            'The date on which the invoices were issued. This will send notifications for the entire batch.'
          )
        )}
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          disabled={
            loading || !selectedBatchID || selectedBatchID === lastSentBatchID
          }
          onClick={handleNotifyClick}>
          Send notification emails
        </Button>
        {selectedBatchID === lastSentBatchID && selectedBatchID > 0 && (
          <small style={{ display: 'block', paddingTop: '1rem' }}>
            You've already sent the currently selected batch: button is disabled
            to prevent accidental re-sending. If you think you want to resend,
            please refresh the page.
          </small>
        )}
      </Grid>
    </Grid>
  )
}

function convertBatchesToItems(batches: InvoiceBatch[]): SelectItem[] {
  const getLabel = (batch: InvoiceBatch) => {
    console.debug(
      'getlabel: ',
      batch,
      batch.AchNotificationApprovedAts,
      batch.AchNotificationApprovedAts.length
    )
    if (
      batch &&
      batch.AchNotificationApprovedAts &&
      batch.AchNotificationApprovedAts.length > 0
    ) {
      return `${batch.InvoiceBatchDate} (${batch.InvoicesInBatch} invoice(s)) - Notified ${batch.AchNotificationApprovedAts.length} time(s)`
    }
    return `${batch.InvoiceBatchDate} (${batch.InvoicesInBatch} invoice(s))`
  }

  return batches.map((batch) => {
    return {
      label: getLabel(batch),
      value: batch.InvoiceBatchID,
    }
  })
}
