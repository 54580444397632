import * as api from '../services/thezerocard/api-helper'
import { NetworkPayload, NetworkSearchParams } from '../views/Networks/types'

export const getNetworks = (payload: NetworkSearchParams & Partial<any>) => {
  return api.search(`/configurable_network`, payload).then((res: any) => res)
}

export const getNetwork = (id: number) => {
  return api.get(`/configurable_network/${id}`).then((res: any) => res.Data)
}

export const saveNewNetwork = (body: NetworkPayload & Partial<any>) => {
  return api.post(`/configurable_network`, body).then((res: any) => res.Data)
}

export const updateNetwork = (
  id: number,
  body: NetworkPayload & Partial<any>
) => {
  return api
    .put(`/configurable_network/${id}`, body)
    .then((res: any) => res.Data)
}

export const putAddNetworkEntities = (id: number, params: any) => {
  return api
    .put(`/configurable_network/${id}/add_entities`, params)
    .then((res: any) => {
      if (res.error) throw res
      return res.Data
    })
}

export const putRemoveNetworkEntities = (id: number, params: any) => {
  return api
    .put(`/configurable_network/${id}/remove_entities`, params)
    .then((res: any) => {
      if (res.error) throw res
      return res.Data
    })
}

export const postTriggerCacheRefresh = () => {
  return api
    .post(`/configurable_network/trigger_cache_refresh`)
    .then((res: any) => {
      if (res.error) throw res
    })
}
