import React from 'react'
import { Link } from 'react-router-dom'
import utils from '../../utils'

const providerSearch = {
  columns: {
    KeyColumn: 'Name',
    Name: {
      name: 'Facility Name',
      details: { width: '250px', dataFormat: pfLinkFormatter },
    },
    OrganizationName: {
      name: 'Org Name',
      details: { width: '200px', dataFormat: orgLinkFormatter },
    },
    OrganizationPHANotes: {
      name: 'PHA Notes',
      details: {
        width: '350px',
        dataFormat: (cell, row) => row.OrganizationPHANotes || '',
      },
    },
    DisplayAddress: {
      name: 'Address',
      details: { width: '350px', dataFormat: getDisplayAddress },
    },
    Phone: {
      name: 'Phone',
      details: { width: '150px', dataFormat: utils.phoneFormatter },
    },
    Fastpass: { name: 'Fastpass', details: { width: '75px' } },
    Distance: {
      name: 'Distance',
      details: { width: '75px', dataFormat: utils.distanceFormatter },
    },
  },
}

function orgLinkFormatter(cell, row) {
  return (
    <>
      <Link
        to={`/organization/${row.OrganizationId}`}
        target="_blank"
        name="view-link">
        {row.OrganizationName}
      </Link>
      {row.OrganizationAcceptingReferrals === false && (
        <span style={{ color: 'red' }}>**</span>
      )}
    </>
  )
}

function pfLinkFormatter(cell, row) {
  return (
    <Link
      to={`/organization/${row.OrganizationId}/practice_facility/${row.ID}`}
      target="_blank"
      name="view-link">
      {row.Name}
    </Link>
  )
}

function getDisplayAddress(cell, row) {
  return row.Address.DisplayAddress
}

export default providerSearch
