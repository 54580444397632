import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { DataTable } from '../../../components/DataTable'
import { debounce } from 'lodash'
import * as api from '../../../services/thezerocard/api-helper'
import * as types from './types'
import _pick from 'lodash/pick'
import { Grid, TextField, InputAdornment, Button } from '@material-ui/core'
import {
  Search as IconSearch,
  Refresh as IconRefresh,
} from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'
import DialogRetrySendLog from './DialogRetrySendLog'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import { bytesToFriendly } from '../../../utils'
import * as DesignSuite2023 from '../../../components/DesignSuite2023'

interface props {
  onRowClick?(ID: number): void
  onCheckHandler?(selected: any[]): void
  passFilters?: any // any filters to pass to query API endpoint
  disableFilterRecipient?: boolean
  disableFilterStatus?: boolean
  RightHeaderItems?: React.ReactNode
  // if you want to customize the columns, pass here; otherwise uses defaults declared below
  customColumns?: any
}

const STATUS_DEFERRED = 'deferred'
const STATUS_PENDING = 'pending'
const STATUS_INBATCH = 'inbatch'
const STATUS_INFLIGHT = 'inflight'
const STATUS_SUCCESS = 'success'
const STATUS_FAIL = 'fail'

const statuses = [
  { value: STATUS_PENDING, label: 'Pending' },
  { value: STATUS_INBATCH, label: 'In Batch' },
  { value: STATUS_INFLIGHT, label: 'In Flight' },
  { value: STATUS_SUCCESS, label: 'Success' },
  { value: STATUS_FAIL, label: 'Fail' },
]

export const defaultColumns = Object.freeze({
  ID: { name: 'ID', details: {} },
  CreatedAt: { name: 'Created', details: { sortName: 'created_at' } },
  RecipientName: {
    name: 'Recipient Name',
    details: { sortName: 'recipient_name' },
  },
  // Status: {name: 'Status', details: { dataFormat(cell: string, row: any): any {
  //   if (cell === STATUS_FAIL) return (<DialogRetrySendLog id={row.ID} retryCount={row.RetryCount} retriedByUserID={row.RetriedByUserID} retriedAt={row.RetriedAt} previousSendErrors={row.PreviousSendErrors} sendError={row.SendError} onSuccess={_doUpdate} />)
  //   return cell
  // }}},
  BytesSent: {
    name: 'BytesSent',
    details: {
      dataFormat(cell: string, row: any): any {
        return (
          <DesignSuite2023.Tooltip title={`Exact bytes: ${row.BytesSent}`}>
            <span>{bytesToFriendly(row.BytesSent)}</span>
          </DesignSuite2023.Tooltip>
        )
      },
    },
  },
  SendKickoffTime: { name: 'SendKickoffTime', details: {} },
  FinishedTime: { name: 'FinishedTime', details: {} },
  DropDestination: { name: 'Drop Destination', details: {} },
  CreatedByUserID: { name: 'CreatedByUserID', details: {} },
  FileID: { name: 'FileID', details: {} },
  UpdatedAt: { name: 'Updated', details: { sortName: 'updated_at' } },
  PGPEncr: {
    name: 'PGPEncryption',
    details: {
      dataFormat(_: any, row: any): any {
        switch (row.Status) {
          case STATUS_DEFERRED:
          case STATUS_PENDING:
          case STATUS_INBATCH:
          case STATUS_INFLIGHT:
            return row.RecipientHasPGPKeyPublic
              ? 'Will Encrypt'
              : 'Not Encrypting'
          case STATUS_SUCCESS:
          case STATUS_FAIL:
            return !!row.UsedPGPKeyPublicID
              ? 'Sent Encrypted'
              : 'Sent Unencrypted'
        }
        return '?'
      },
    },
  },
  SendError: {
    name: 'SendError',
    details: {
      dataFormat(_: any, row: any): any {
        return <div style={{ whiteSpace: 'pre' }}>{row.SendError}</div>
      },
    },
  },
})

export default forwardRef(function SendLogTableList(
  {
    onRowClick,
    onCheckHandler,
    passFilters,
    disableFilterRecipient,
    disableFilterStatus,
    RightHeaderItems,
    customColumns,
  }: props,
  ref: any
): any {
  const [lastRefresh, setLastRefresh] = useState<any>(null)
  const [results, setResults] = useState([])
  const [resultCount, setResultCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState({
    q: '',
    recipientID: null,
    status: null,
    ...passFilters,
  })
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 })
  const [sortable, setSortable] = useState({ col: 'created_at', dir: 'desc' })
  const [allRecipients, setAllRecipients] = useState([])
  const { catchAPIError } = useErrorHandlers()
  /*
    Holy SMOKETS this sucks, but here's the dl: in order to allow us to continue customizing columns in
    an easy way, **without redefining them entirely (eg. copying the whole 'PGPEncr' column definition)**
    wherever the parent/calling component wants to do so, we need them _exported_ from this file. The vast
    majority of the time this isn't a problem, since columns don't need "internal" access to their components.
    HOWEVER - this case is different, because the 'Status' column renders a retry modal which, after retrying,
    needs to trigger a refresh of *this* components state... meaning it needs visibility to the _doUpdate
    method. That doesn't work if columns are defined externally. So what we do instead is leave Status off the
    exported column definitions (yes, that means it can't be used if columns are customized), then we add just
    that one column in here. But also - objects in JS retain ordering, so to have it inserted in the right place
    in the table, we need to do this fancy (read: super annoying) object merge where we pluck the properties, in
    order, as necessary.
  */
  const [columns] = useState(
    customColumns
      ? { ...customColumns }
      : {
          ..._pick(defaultColumns, 'ID', 'CreatedAt', 'RecipientName'),
          Status: {
            name: 'Status',
            details: {
              dataFormat(cell: string, row: any): any {
                if (cell === STATUS_FAIL)
                  return (
                    <DialogRetrySendLog
                      id={row.ID}
                      retryCount={row.RetryCount}
                      retriedByUserID={row.RetriedByUserID}
                      retriedAt={row.RetriedAt}
                      previousSendErrors={row.PreviousSendErrors}
                      sendError={row.SendError}
                      onSuccess={_doUpdate}
                    />
                  )
                return cell
              },
            },
          },
          ..._pick(
            defaultColumns,
            'BytesSent',
            'SendKickoffTime',
            'FinishedTime',
            'DropDestination',
            'CreatedByUserID',
            'FileID',
            'UpdatedAt',
            'PGPEncr',
            'SendError'
          ),
        }
  )

  const _doUpdate = useCallback(() => {
    setLastRefresh(new Date().toISOString())
  }, [setLastRefresh])

  /*
    This exposes a public api via a ref to the parent component. Is this a great idea?
    Probably not - but if someone else can figure out a good way to have a component refresh
    it's state in a more simple way, please enlighten me.
  */
  useImperativeHandle(
    ref,
    () => ({
      refresh: _doUpdate,
    }),
    [_doUpdate]
  )

  const loadSendLogs = useCallback(
    debounce((payload: any) => {
      // @ts-ignore
      api
        .search(`/engineering/sftpaas/send-log`, payload)
        .then((res: any) => {
          if (res.error) {
            throw res
          }
          setResults(res.Data)
          setResultCount(res.Meta?.Total || 0)
        })
        .catch(
          catchAPIError({
            defaultMessage:
              'Failed loading send logs; please contact engineering',
          })
        )
        .finally(() => {
          setLoading(false)
        })
    }, 400),
    [setLoading, setResults, setResultCount]
  )

  useEffect(() => {
    setLoading(true)
    // compose payload
    const payload = {
      filter: { ...filter },
      sort: [sortable.col, sortable.dir],
      page: pagination.page,
      pageSize: pagination.pageSize,
    }
    if (payload.filter.q && payload.filter.q.length < 3) {
      // @ts-ignore
      payload.filter.q = null
    }
    loadSendLogs(payload)
  }, [filter, pagination, sortable, setLoading, loadSendLogs, lastRefresh])

  useEffect(() => {
    setFilter((f: any) => {
      return { ...f, ...passFilters }
    })
  }, [passFilters])

  useEffect(() => {
    if (disableFilterRecipient) {
      return
    }
    // @ts-ignore
    api
      .search('/engineering/sftpaas/recipient', { filter: { NoLimit: true } })
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        setAllRecipients(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed loading recipients; please contact engineering',
        })
      )
  }, [disableFilterRecipient, setAllRecipients])

  const onClickRefresh = _doUpdate

  return (
    <>
      <Grid container spacing={2} justify="space-between" alignItems="center">
        <Grid item xs={12} md="auto">
          <Grid container spacing={2} alignItems="center">
            <Grid item xs="auto">
              <Button variant="outlined" onClick={onClickRefresh}>
                <IconRefresh />
              </Button>
            </Grid>
            <Grid item xs="auto">
              <TextField
                autoFocus
                placeholder="Start typing to search"
                size="small"
                variant="outlined"
                value={filter.q}
                onChange={(ev: any) => {
                  const q = ev.target?.value
                  setFilter((c: any) => {
                    return { ...c, q }
                  })
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconSearch />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {disableFilterRecipient !== true && (
              <Grid item xs="auto">
                <Autocomplete
                  options={allRecipients || []}
                  getOptionLabel={(opt: types.recipient) => {
                    return opt.Name || ''
                  }}
                  getOptionSelected={(opt: types.recipient) => {
                    return filter.recipientID === opt.ID
                  }}
                  value={
                    allRecipients.find(
                      (r: types.recipient) => r.ID === filter.recipientID
                    ) || null
                  }
                  defaultValue={null}
                  onChange={(_: any, opt: types.recipient | null) => {
                    if (!opt) {
                      setFilter({ ...filter, recipientID: null })
                      return
                    }
                    setFilter({ ...filter, recipientID: opt.ID })
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Recipient"
                      placeholder="Recipient"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      style={{ minWidth: 200 }}
                    />
                  )}
                />
              </Grid>
            )}
            {disableFilterStatus !== true && (
              <Grid item xs="auto">
                <Autocomplete
                  options={statuses}
                  getOptionLabel={(opt: any) => {
                    return opt.label || ''
                  }}
                  getOptionSelected={(opt: any) => {
                    return filter.status === opt.value
                  }}
                  value={
                    statuses.find((r: any) => r.value === filter.status) || null
                  }
                  defaultValue={null}
                  onChange={(_: any, opt: any | null) => {
                    if (!opt) {
                      setFilter({ ...filter, status: null })
                      return
                    }
                    setFilter({ ...filter, status: opt.value })
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Status"
                      placeholder="Status"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      style={{ minWidth: 160 }}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        {!!RightHeaderItems && (
          <Grid item xs={12} md="auto">
            {RightHeaderItems}
          </Grid>
        )}
      </Grid>

      <div style={{ whiteSpace: 'nowrap' }}>
        <DataTable
          loading={loading}
          keyProp="ID"
          data={results}
          columns={columns}
          initPage={pagination.page * 1}
          initPageSize={pagination.pageSize * 1}
          count={resultCount * 1}
          onRowClick={(_: any, { ID }: any) => {
            onRowClick && onRowClick(ID)
          }}
          onChangePage={setPagination}
          onChangeRowsPerPage={setPagination}
          sortHandler={setSortable}
          sortable={sortable}
          checkHandler={onCheckHandler}
        />
      </div>
    </>
  )
})
