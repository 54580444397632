import React, { useLayoutEffect, useState, useCallback } from 'react'
import { Auth0Client } from '@auth0/auth0-spa-js'
import { useConfig } from './Config'
import history from '../utils/history'
import DisplayPreState from './displayPreState'
import { getUser } from '../actions/UserActions'
import { Button, Typography } from '@material-ui/core'

interface ShapeAuthContext {
  isAuthenticated: boolean
  perms: string[]
  user: any
  loading: boolean
  logout: any
}

const defaultRedirectURI = `${window.location.origin}/login_callback`
const windowURLOnLoad = `${window.location.href}`

const AuthContext = React.createContext({} as ShapeAuthContext)
export const useAuth = () => React.useContext<ShapeAuthContext>(AuthContext)

export function AuthProvider({
  children,
  ...initOptions
}: { testingHistory?: any } & Partial<any>): React.ReactElement {
  const { auth0ClientID, auth0Domain } = useConfig()
  const [authClient, setAuthClient] = useState<any>(null)
  const [isAuthd, setIsAuthd] = useState(false)
  const [auth0User, setAuth0User] = useState<any>(null)
  const [zeroUser, setZeroUser] = useState<any>(null)
  const [perms, setPerms] = useState<string[]>([])
  const [errState, setErrState] = useState<any>(null)
  const _history = initOptions.testingHistory || history

  useLayoutEffect(() => {
    if (!auth0ClientID || !auth0Domain) return
    if (authClient) return
    setAuthClient(
      getAuthClient({
        clientId: auth0ClientID,
        domain: auth0Domain,
        useRefreshTokens: false,
        authorizationParams: {
          redirect_uri: defaultRedirectURI,
        },
      })
    )
  }, [auth0ClientID, auth0Domain, authClient])

  useLayoutEffect(() => {
    if (!authClient) return
    if (isAuthd) return
    triggerAuthFlow(authClient)
  }, [authClient, isAuthd])

  useLayoutEffect(() => {
    if (!authClient || !isAuthd) return
    if (auth0User || perms.length > 0) return
    triggerLoadUser(authClient)
  }, [authClient, isAuthd, auth0User, perms])

  const fnDoLogout = useCallback(
    (...p: any[]) => {
      if (!authClient)
        return console.error('[AuthProvider.Logout] No authClient')
      authClient.logout(...p)
      localStorage.removeItem('id_token')
      localStorage.removeItem('perms')
    },
    [authClient]
  )

  async function triggerAuthFlow(authClient: any) {
    if (!authClient)
      return console.error('[AuthProvider.AuthFlow] No authClient')
    if (isAuthd)
      return console.warn('[AuthProvider.AuthFlow] Already authenticated')

    if (windowURLOnLoad.includes('login_callback')) {
      if (windowURLOnLoad.includes('error=')) {
        console.error(
          '[AuthProvider.AuthFlow] login_callback error',
          windowURLOnLoad
        )
        const params = new URL(windowURLOnLoad).searchParams
        const obj = {} as any
        for (const [k, v] of params.entries()) {
          obj[k] = v
        }
        if (obj?.error === 'password_expired') {
          obj.error_description = (
            <blockquote style={{ textAlign: 'left' }}>
              <Typography variant="h6">
                Your password has expired and needs to be reset.
              </Typography>
              <Typography>
                We have sent you an email with instructions. If you do not
                receive an email please{' '}
                <a href="mailto:support@zero.health">Contact Us</a>
              </Typography>
              <Typography variant="body2">
                Note: Passwords are a backup for our Okta authentication, and
                need to be kept up date with our password policy even if we
                arent actively using them to login.
              </Typography>
            </blockquote>
          )
        }
        setErrState(obj)
        return
      }

      try {
        const rdc = await authClient.handleRedirectCallback(windowURLOnLoad)
        // console.log('[AuthProvider.AuthFlow] handleRedirectCallback, requeuing auth flow...', rdc)
        _history.replace(rdc?.appState?.destURL || '/', {})
      } catch (err) {
        const errish = err as any
        console.error('[AuthProvider.AuthFlow] handleRedirectCallback', errish)
        setErrState({
          error: 'Login failure',
          error_description:
            errish?.message || 'Unknown error occurred during authentication',
        })
      }
    }

    try {
      await authClient.getTokenSilently()
      // console.log('[AuthProvider.AuthFlow] received token silently', tkn)
      setIsAuthd(true)
      return
    } catch (err) {
      const errish = err as any
      if (errish?.error === 'login_required') {
        authClient.loginWithRedirect({
          appState: {
            destURL: windowURLOnLoad.includes('login_callback')
              ? '/'
              : window.location.pathname + window.location.search,
          },
        })
      }
    }
  }

  async function triggerLoadUser(authClient: any) {
    if (!authClient)
      return console.error('[AuthProvider.LoadUser] No authClient')

    try {
      const auth0User = await authClient.getUser()
      setAuth0User(auth0User)

      const claims = (await authClient.getIdTokenClaims()) as any
      const perms = extractPerms(claims)
      setPerms(perms)

      localStorage.setItem('id_token', claims?.__raw)
      localStorage.setItem('perms', JSON.stringify(perms))

      // load user data from zero API (but don't do anything with it currently, just
      // hit the endpoint to ensure that it works)
      if (authClient?._isMocked) {
        setZeroUser({})
        return
      }
      const zeroUser = await getUser()
      setZeroUser(zeroUser)
    } catch (err) {
      const errish = err as any
      console.error('[AuthProvider.LoadUser]', errish)
      setErrState({
        error: 'Login failure',
        error_description:
          errish?.message || 'Unknown error occurred during authentication',
      })
    }
  }

  if (errState) {
    return (
      <DisplayPreState showProgress={false}>
        <div>
          <strong>Error: </strong> {errState?.error || 'unknown'}
        </div>
        <div>
          <span>
            {errState?.error_description ||
              'There was a problem authenticating with ZERO Admin; please retry logging in, or contact engineering.'}
          </span>
        </div>
        <Button
          href="/"
          size="small"
          variant="contained"
          style={{ marginTop: '0.5rem' }}>
          Retry Login
        </Button>
      </DisplayPreState>
    )
  }

  const loading = !(!!auth0User && !!zeroUser)

  if (loading) {
    return (
      <DisplayPreState>
        <span>Authenticating...</span>
      </DisplayPreState>
    )
  }

  return (
    <AuthContext.Provider
      value={
        {
          isAuthenticated: isAuthd,
          loading,
          perms,
          user: auth0User,
          logout: fnDoLogout,
        } as ShapeAuthContext
      }>
      {children}
    </AuthContext.Provider>
  )
}

let _client: any = null
function getAuthClient(params: any) {
  // @ts-ignore
  if (Auth0Client?._isMocked) {
    console.log('HERE WITH MOCKED CLIENT')
    _client = Auth0Client
  }

  if (_client) return _client
  _client = new Auth0Client(params)
  return _client
}

function extractPerms(claims: any): string[] {
  if (claims) {
    let cls = claims['https://zero.health/_permissions']
    // @todo deprecated
    if (!cls) {
      cls = claims['https://www.thezerocard.com/_permissions']
    }
    return cls
  }
  return []
}
