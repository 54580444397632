import { defaultStyleCreator } from '../../components/DetailView/DetailView'

export default (theme) => {
  const defaults = defaultStyleCreator(theme)
  const classes = {
    dialog: {
      '& > div': {
        overflowY: 'visible',
      },
    },
    tableHeading: {
      border: '3px solid #e1e1e1',
      '& tr': {
        verticalAlign: 'top',
      },
      '& th': {
        padding: '0.35rem',
      },
      '& select': {
        fontSize: '12px',
        fontWeight: 200,
        width: '100%',
      },
    },
    enabledValidator: {
      display: 'block',
      width: '100%',
      padding: '0.25rem',
      fontSize: '12px',
    },
    removeValidatorBtn: {
      display: 'inline-block',
      background: '#ccc',
      borderRadius: '50%',
      width: '18px',
      height: '18px',
      textAlign: 'center',
      lineHeight: '1.25',
      marginRight: '5px',
      cursor: 'pointer',
      paddingLeft: '1px',
    },
    tableBody: {
      border: '3px solid #e1e1e1',
      '& > tr': {
        borderBottom: '1px dashed #ddd',
        verticalAlign: 'top',
        '& > td': {
          padding: '0.2rem 0.5rem',
          position: 'relative',
        },
      },
    },
    styleExcludeRow: {
      color: '#c1c1c1',
      textDecoration: 'line-through',
      '& > td': {
        background: '#111 !important',
        opacity: 0.7,
      },
    },
    rowNumber: {
      fontSize: '10px',
      color: '#aaa',
    },
    typeCastedIndicator: {
      position: 'absolute',
      top: 0,
      right: 0,
      fontSize: '8px',
      padding: '2px',
      textTransform: 'uppercase',
    },
  }
  return Object.assign(classes, defaults)
}
