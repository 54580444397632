// @ts-ignore
import React, { useState, useEffect, useRef } from 'react'
import * as api from '../../../services/thezerocard/api-helper'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
// @ts-ignore
import styled from 'styled-components'
import {
  Paper,
  Button,
  Tooltip,
  CircularProgress,
  MenuItem,
  Menu,
  TextField,
  // @ts-ignore
} from '@material-ui/core'

const PaperWrapper = styled(Paper)`
  padding: 0.25rem 1rem 1rem;

  label {
    display: inline-block;
    white-space: nowrap;
    margin-right: 1rem;
    input {
      display: inline-block;
    }
    span {
      white-space: nowrap;
      display: inline-block;
    }
  }
`

const RenderSpace = styled.div`
  display: flex;
  background: #222;
  padding: 2rem;
  position: relative;

  .loading-icon {
    position: absolute;
    left: 50%;
    top: 5%;
    transform: translateX(-50%);
    z-index: 99;
  }

  .image-container {
    display: inline-block;
    line-height: 1;
    position: relative;

    image {
      display: block;
      pointer-events: none;
    }

    .extracted-text {
      display: inline-block;
      position: absolute;
      color: #e1e1e1;
      font-size: 14px;
      line-height: 1;
      cursor: pointer;

      span {
        visibility: hidden;
        display: inline-flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }

      &.show-overlay {
        span {
          visibility: visible;
          background: rgba(0, 0, 0, 0.85);
        }
      }
    }
  }

  .request-builder {
    background: #fff;
    padding: 1.5rem;
    margin-left: 1rem;

    .MuiFormControl-root {
      margin-bottom: 1rem;
    }
  }
`

const StyledTip = styled.div`
  color: #fff;
  font-size: 15px;
  text-align: center;
  padding: 0.5rem;

  small {
    display: block;
    font-size: 11px;
    padding-top: 0.5rem;
    opacity: 0.75;
  }
`

const refFieldsTemplate = {
  member: {
    name: { label: 'Name', value: null },
    ssnLast4: { label: 'Ssn Last 4', value: null },
    integrationID: { label: 'Integration ID', value: null },
    address: { label: 'Address', value: null },
    contactPhone: { label: 'Contact Phone', value: null },
    dob: { label: 'Date Of Birth', value: null },
  },
  refDetails: {
    serviceDate: { label: 'Service Date', value: null },
    orgName: { label: 'OrgName', value: null },
    facilityName: { label: 'Facility Name', value: null },
    facilityAddress: { label: 'Facility Address', value: null },
    referrerName: { label: 'Referrer Name', value: null },
    descriptionNotes: { label: 'Description Notes', value: null },
    orderingPhysicianName: { label: 'Ordering Physician Name', value: null },
    orderingPhysicianPhone: { label: 'Ordering Physician Phone', value: null },
    orderingPhysicianPhoneExt: {
      label: 'Ordering Physician Phone Ext',
      value: null,
    },
    orderingPhysicianFax: { label: 'Ordering Physician Fax', value: null },
  },
  // member: [
  //   {name: 'name', value: null},
  //   {name: 'ssnLast4', value: null},
  //   {name: 'integrationID', value: null},
  //   {name: 'address', value: null},
  //   {name: 'contactPhone', value: null},
  //   {name: 'dob', value: null},
  // ],
  // refDetails: [
  //   {name: 'orgName', value: null},
  //   {name: 'facilityName', value: null},
  //   {name: 'facilityAddress', value: null},
  //   {name: 'referrerName', value: null},
  //   {name: 'descriptionNotes', value: null},
  //   {name: 'orderingPhysicianName', value: null},
  //   {name: 'orderingPhysicianPhone', value: null},
  //   {name: 'orderingPhysicianPhoneExt', value: null},
  //   {name: 'orderingPhysicianFax', value: null},
  // ]
}

export default function TextractSandbox(): React.ReactElement {
  const { catchAPIError } = useErrorHandlers()
  // @ts-ignore
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [analyzed, setAnalyzed] = useState<any>(null)
  const [nodes, setNodes] = useState<any>(null)
  const [showOverlay, setShowOverlay] = useState(false)
  const [imageOpacity, setImageOpacity] = useState(80)
  const [isLoading, setIsLoading] = useState(false)
  const [menuAnchor, setMenuAnchor] = useState<any>(null)
  const [refFields, setRefFields] = useState<any>({ ...refFieldsTemplate })
  const [useBlockType, setUseBlockType] = useState('LINE')
  const [fontSize, setFontSize] = useState(13)
  const imgRef: any = useRef(null)

  useEffect(() => {
    setSelectedFile(null)
    setAnalyzed(null)
    setNodes(null)
    setShowOverlay(false)
    setImageOpacity(80)
    setIsLoading(false)
    setMenuAnchor(null)
    setRefFields({ ...refFieldsTemplate })
    setUseBlockType('LINE')
  }, [])

  useEffect(() => {
    if (!selectedFile) return
    if (!!imgRef.current && imgRef.current !== null) {
      imgRef.current.src = URL.createObjectURL(selectedFile)
    }
    setIsLoading(true)
    apiUploadAndScan(selectedFile)
      .then((res: any) => {
        if (res.error) throw res
        setAnalyzed(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed...',
        })
      )
      .finally(() => {
        setIsLoading(false)
      })
  }, [selectedFile])

  useEffect(() => {
    if (!analyzed) return
    const reduced = analyzed.Blocks?.reduce((acc: any, curr: any): any => {
      if (!(curr?.BlockType === useBlockType)) return acc
      acc.push({
        id: curr.Id,
        confidence: curr.Confidence,
        geom: curr.Geometry,
        text: curr.Text,
      })
      return acc
    }, [])
    setNodes(reduced)
  }, [analyzed, useBlockType, setNodes])

  function onChange(ev: any) {
    if (ev.target?.files?.length === 0) return
    setSelectedFile(ev.target.files[0])
  }

  function computeStyle(n: any): any {
    if (!imgRef.current) return {}
    const bb = imgRef.current?.getBoundingClientRect()
    return {
      width: n.geom.BoundingBox?.Width * bb.width,
      height: n.geom.BoundingBox?.Height * bb.height,
      left: n.geom.BoundingBox?.Left * bb.width,
      top: n.geom.BoundingBox?.Top * bb.height,
      fontSize,
      // opacity: showOverlay ? 100 : 0
    }
  }

  return (
    <>
      <PaperWrapper elevation={2}>
        <h2 style={{ textAlign: 'center' }}>
          Pretend This Is Zendesk ¯\_(ツ)_/¯{' '}
          <small>and that you wouldn't need to upload a file...</small>
        </h2>

        <hr />

        <input type="file" onChange={onChange} />

        <hr />

        {!!nodes && (
          <>
            <label>
              <input
                type="checkbox"
                checked={showOverlay}
                onChange={(ev: any) => {
                  setShowOverlay((curr: any) => !curr)
                }}
              />
              <span>Show Extracted Text Overlay</span>
            </label>
            <div style={{ display: 'inline-block' }}>
              <label>
                <input
                  type="radio"
                  value="WORD"
                  checked={useBlockType === 'WORD'}
                  onChange={() => {
                    setUseBlockType('WORD')
                  }}
                />
                <span>Words</span>
              </label>
              <label>
                <input
                  type="radio"
                  value="LINE"
                  checked={useBlockType === 'LINE'}
                  onChange={() => {
                    setUseBlockType('LINE')
                  }}
                />
                <span>Lines</span>
              </label>
            </div>
            <label>
              <input
                value={`${fontSize}`}
                onChange={(ev: any) => {
                  setFontSize(+ev.target?.value)
                }}
                style={{ width: 40 }}
              />
              <span>Font Size</span>
            </label>
            <label>
              <input
                value={imageOpacity}
                type="range"
                min="1"
                max="100"
                style={{ maxWidth: 200 }}
                onChange={(ev: any) => {
                  setImageOpacity(+ev.target.value)
                }}
              />
              <span>Image Opacity ({imageOpacity}%)</span>
            </label>
          </>
        )}
        <RenderSpace>
          {isLoading && <CircularProgress size={50} className="loading-icon" />}
          <div className="image-container">
            <img
              alt="whatever"
              ref={imgRef}
              style={{ opacity: imageOpacity / 100 }}
            />
            {!!nodes && (
              <>
                {nodes.map((n: any) => {
                  return (
                    <Tooltip
                      key={n.id}
                      placement="top"
                      arrow
                      title={renderTooltip(n)}>
                      <div
                        key={n.id}
                        className={`extracted-text ${showOverlay && 'show-overlay'}`}
                        style={computeStyle(n)}
                        onClick={(ev: any) => {
                          setMenuAnchor(ev.currentTarget)
                        }}>
                        <span>{n.text}</span>
                      </div>
                    </Tooltip>
                  )
                })}
              </>
            )}
          </div>
          {!!nodes && (
            <div className="request-builder">
              <h5>Member Info</h5>
              {Object.keys(refFields.member).map((n: any) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={refFields.member[n].label}
                  placeholder={refFields.member[n].label}
                  value={refFields.member[n].value || ''}
                  onChange={(ev: any) => {
                    const v = ev.target?.value
                    refFields.member[n].value = v
                    setRefFields({ ...refFields })
                  }}
                />
              ))}

              <h5>Referring Entity Info</h5>
              {Object.keys(refFields.refDetails).map((n: any) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={refFields.refDetails[n].label}
                  placeholder={refFields.refDetails[n].label}
                  value={refFields.refDetails[n].value || ''}
                  onChange={(ev: any) => {
                    const v = ev.target?.value
                    refFields.refDetails[n].value = v
                    setRefFields({ ...refFields })
                  }}
                />
              ))}

              <Button fullWidth color="primary" variant="contained">
                Create Referral Request
              </Button>
              <small
                style={{
                  lineHeight: 1.3,
                  display: 'inline-block',
                  marginTop: '0.5rem',
                }}>
                Note: this fax will automatically be linked with the referral
                request for later reference
              </small>
            </div>
          )}
        </RenderSpace>

        <hr />
        <h5>Debug Info</h5>
        <pre>{JSON.stringify(analyzed, null, '  ')}</pre>

        <Menu
          anchorEl={menuAnchor}
          keepMounted
          open={Boolean(menuAnchor)}
          onClose={() => {
            setMenuAnchor(null)
          }}>
          <MenuItem>
            <h5>Member Info</h5>
          </MenuItem>
          {Object.keys(refFields.member).map((n: any) => (
            <MenuItem
              dense
              onClick={(ev: any) => {
                refFields.member[n].value =
                  (menuAnchor && menuAnchor.textContent) || null
                setRefFields({ ...refFields })
                setMenuAnchor(null)
              }}>
              Set {refFields.member[n].label}
            </MenuItem>
          ))}
          <MenuItem>
            <h5>Referral Request Deets</h5>
          </MenuItem>
          {Object.keys(refFields.refDetails).map((n: any) => (
            <MenuItem
              dense
              onClick={(ev: any) => {
                refFields.refDetails[n].value =
                  (menuAnchor && menuAnchor.textContent) || null
                setRefFields({ ...refFields })
                setMenuAnchor(null)
              }}>
              Set {refFields.refDetails[n].label}
            </MenuItem>
          ))}
        </Menu>
      </PaperWrapper>
    </>
  )
}

function renderTooltip(n: any): React.ReactElement {
  const lvl = n.confidence.toFixed(2)
  return (
    <StyledTip key={n.id}>
      {n.text}{' '}
      <small
        style={{
          color: lvl <= 90 ? (lvl < 80 ? 'lightcoral' : 'yellow') : '#fff',
        }}>
        (Confidence: {lvl}%)
      </small>
    </StyledTip>
  )
}

function apiUploadAndScan(fileObj: File): Promise<any> {
  const fd = new FormData()
  fd.append(`file`, fileObj, fileObj.name)
  return api.postFormData('/engineering/docxtract/scan', fd)
}
