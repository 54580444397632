import React, { ReactElement } from 'react'
import { customDisplayProps } from './types'
import { TextField, LinearProgress, Grid } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import FileIcon from './FileIcon'
import FileTypeFilter, {
  FileTypeEntities,
} from '../../views/Files/FileTypeFilter'
import { bytesToFriendly } from '../../utils'
import styled from 'styled-components'
import ManagedDateInput from '../Inputs/managedDateInput'
import dateTime from '../../utils/dateTime'

interface props extends customDisplayProps {
  showInputs?: boolean
  fileTypeEntities?: readonly FileTypeEntities[]
  customFileTypeFilter?: () => ReactElement
}

export default function DefaultSingleFileInput({
  file,
  setter,
  showInputs = true,
  fileTypeEntities,
  customFileTypeFilter,
}: props): React.ReactElement | null {
  const fileTypeErrorMessage = 'File Type is Required'
  const dateProps = {} as any
  if (file.postFileData?.EffectiveDate) {
    if (!dateTime.parse(file.postFileData?.EffectiveDate).isValid()) {
      dateProps.error = true
      dateProps.helperText = 'Invalid date'
    }
  }

  return (
    <DefaultSingleFileInputWrapper>
      {file.err && !file.fileRec && (
        <Alert className="alert-error" severity="error">
          {file.err}
        </Alert>
      )}
      <div className="selected-file">
        <span className="selected-file-name">
          Selected File: <strong>{file.domFileObj.name}</strong>
        </span>
        <LinearProgress variant="determinate" value={file.progress} />
      </div>

      {file.fileRec?.ID && (
        <Grid className="file-confirmed" container spacing={2}>
          <Grid item>
            <FileIcon ext={file.domFileObj.name.split('.').pop()} />
          </Grid>
          <Grid item>
            <div>
              <strong>File Uploaded OK!</strong>
            </div>
            <div>FileName: {file.fileRec.S3Key.split('/').pop()}</div>
            <div>Size: {bytesToFriendly(file.fileRec.Size)}</div>
          </Grid>
        </Grid>
      )}

      {!file.fileRec && showInputs && (
        <>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs>
              {customFileTypeFilter ? (
                customFileTypeFilter()
              ) : (
                <FileTypeFilter
                  entityTypes={fileTypeEntities}
                  onChange={(ft: any) => {
                    setter({ FileTypeID: ft ? ft.ID : null })
                  }}
                  errorMessage={fileTypeErrorMessage}
                />
              )}
            </Grid>
            <Grid item xs="auto">
              <ManagedDateInput
                {...dateProps}
                name="EffectiveDate"
                label="Effective Date"
                shrink
                margin="none"
                value={file.postFileData?.EffectiveDate}
                setter={({ name, value }) => setter({ [name]: value })}
              />
            </Grid>
          </Grid>
          <TextField
            multiline
            fullWidth
            label="Notes"
            rows="3"
            name="Notes"
            margin="normal"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            value={file.postFileData?.Notes || ''}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
              setter({ Notes: ev.target.value })
            }}
          />
        </>
      )}
    </DefaultSingleFileInputWrapper>
  )
}

/*
Styled components below
*/
const DefaultSingleFileInputWrapper = styled.div`
  min-width: 520px;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #bbb;

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }

  .alert-error {
    margin-bottom: 0.75rem;
  }

  .selected-file {
    margin-bottom: 1rem;

    .selected-file-name {
      display: block;
      padding-bottom: 0.5rem;
    }
  }

  .file-confirmed {
    margin: 0 auto;
    max-width: fit-content;
  }
`
