import React, { useEffect, useState } from 'react'
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import { BillingPlanActions } from '../../actions'
import { ComponentFSPicker } from './ComponentFSPicker'
import { ComponentPricePicker } from './ComponentPricePicker'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import useSnackbar, {
  SnackbarTypeSuccess,
  SnackbarTypeError,
} from '../../hooks/useSnackbar'
const { addPriceToBillingPlan } = BillingPlanActions

interface BillingPlanComponentPricerProps {
  billingPlanID?: number
  onAdd(): void
}

export const BillingPlanComponentPricer: React.FC<
  BillingPlanComponentPricerProps
> = ({ billingPlanID, onAdd }) => {
  const [selectedFS, setSelectedFS] = useState<any>(null)
  const [requestedFSPriceID, setRequestedFSPriceID] = useState<any>(null)
  const [expanded, setExpanded] = useState<string>('')
  const { show: showSnackbar } = useSnackbar()

  const handleFSSelected = (fs: any) => {
    setSelectedFS(fs)
    setRequestedFSPriceID(null)
    setExpanded('fsp')
  }

  const handleFSPriceAdded = (fspID: any) => {
    setRequestedFSPriceID(fspID)
  }

  const handleExpand = (toExpand: string) => {
    return () => {
      setExpanded(toExpand === expanded ? '' : toExpand)
    }
  }

  useEffect(() => {
    setRequestedFSPriceID(null)
  }, [billingPlanID])

  useEffect(() => {
    if (selectedFS !== null && requestedFSPriceID !== null) {
      console.debug('attempting to save fsp: ', selectedFS, requestedFSPriceID)
      addPriceToBillingPlan({
        bpID: billingPlanID as number,
        fspID: requestedFSPriceID as number,
      })
        .then(() => {
          showSnackbar(`Added price to billing plan!`, SnackbarTypeSuccess)
          onAdd()
        })
        .catch((err: string) => {
          showSnackbar(err, SnackbarTypeError)
        })
    }
  }, [requestedFSPriceID])

  return (
    <div>
      <Accordion
        disabled={!billingPlanID}
        expanded={expanded === 'fs'}
        onChange={handleExpand('fs')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {selectedFS ? (
            <div>
              <Typography variant="h5">{selectedFS.Name}</Typography>
            </div>
          ) : (
            <div>
              <Typography variant="h5">
                Select a Component Fee Schedule
              </Typography>
              <Typography variant="body1" color="textSecondary">
                Pick the fee schedule, then find the price
              </Typography>
            </div>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <ComponentFSPicker onAdd={handleFSSelected} />
        </AccordionDetails>
      </Accordion>
      <Accordion
        disabled={!!!selectedFS}
        expanded={expanded === 'fsp'}
        onChange={handleExpand('fsp')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div>
            <Typography variant="h5">Select a price to add</Typography>
            {selectedFS ? (
              <Typography variant="body1" color="textSecondary">
                {selectedFS.Type}
              </Typography>
            ) : null}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <ComponentPricePicker
            onAdd={handleFSPriceAdded}
            fsID={selectedFS?.ID}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
