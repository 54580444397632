import util from './utils'
import { BundlerActions } from '../actions'
const { FIND_BUNDLERS } = BundlerActions

export default function (state = null, action) {
  switch (action.type) {
    case FIND_BUNDLERS:
      if (action.payload.error) {
        return {
          ...state,
          bundlers: [],
          getError: util.errorResponse(action),
        }
      }
      return {
        ...state,
        bundlers: action.payload.Data,
        count: action.payload.Meta.Total,
      }
    default:
      return {
        ...state,
      }
  }
}
