import React, { useState, useEffect, useRef } from 'react'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { putInboundStagedMatchFileConfigs } from '../../../actions/EngineeringActions'
import * as TableEligInboundStaged from './TableEligInboundStaged'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import { IgnoreDialog } from './IgnoreDialog'
import { ResetDialog } from './ResetDialog'
import { Button } from '@material-ui/core'

export default function ViewStaged(): React.ReactElement {
  const [isProcessing, setIsProcessing] = useState(false)
  const [selected, setSelected] = useState([])
  const [selectedInboundIDs, setSelectedInboundIDs] = useState([] as number[])
  const [ignoreDialogOpen, setIgnoreDialogOpen] = useState(false)
  const [resetDialogOpen, setResetDialogOpen] = useState(false)

  const snackbar = useSnackbar()
  const { catchAPIError } = useErrorHandlers()
  const refTbl = useRef<any>(null)

  useEffect(() => {
    setIsProcessing(false)
    setSelected([])
  }, [])

  function doMatch() {
    setIsProcessing(true)
    putInboundStagedMatchFileConfigs()
      .then(() => {
        refTbl.current?.refresh()
        snackbar.showForDuration(
          'Elig file config matchers completed OK. Any files that were matched are now visible in "Ready" tab, and warnings are attached for unmatched ones.',
          SnackbarTypeSuccess,
          10000
        )
      })
      .catch(
        catchAPIError({ defaultMessage: 'Failed running file config matcher' })
      )
      .finally(() => {
        setIsProcessing(false)
      })
  }

  function openIgnoreDialog() {
    const ids = selected.map((sel: any) => sel.OriginalEligInboundID as number)
    setSelectedInboundIDs(ids)
    setIgnoreDialogOpen(true)
  }

  function onCloseIgnoreDialog() {
    setIgnoreDialogOpen(false)
    setSelectedInboundIDs([])
    setSelected([])
    refTbl.current?.refresh()
  }

  function openResetDialog() {
    const ids = selected.map((sel: any) => sel.OriginalEligInboundID as number)
    setSelectedInboundIDs(ids)
    setResetDialogOpen(true)
  }

  function onCloseResetDialog() {
    setResetDialogOpen(false)
    setSelectedInboundIDs([])
    setSelected([])
    refTbl.current?.refresh()
  }

  return (
    <>
      <TableEligInboundStaged.Table
        ref={refTbl}
        onCheckHandler={setSelected}
        passFilters={{ HasEligFileConfig: false }}
        customColumns={TableEligInboundStaged.columnsWithMatchWarnings}
        LeftHeaderItems={
          <TableEligInboundStaged.StandardFilterSearch
            autoFocus
            tooltip="Searches S3 Bucket + Key, Notes"
          />
        }
        RightHeaderItems={
          <>
            {!!selected.length && (
              <>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={openResetDialog}>
                  Reset
                </Button>
                &nbsp;&nbsp;
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={openIgnoreDialog}>
                  Ignore
                </Button>
                &nbsp;&nbsp;
              </>
            )}
            <Button color="primary" variant="outlined" onClick={doMatch}>
              Match All File Configs{' '}
              {isProcessing && (
                <>
                  &nbsp;
                  <DesignSuite2023.LoadingSpinner size={15} />
                </>
              )}
            </Button>
          </>
        }
      />
      {ignoreDialogOpen && (
        <IgnoreDialog
          open={ignoreDialogOpen}
          selected={selectedInboundIDs}
          onClose={onCloseIgnoreDialog}
          onCancel={() => setIgnoreDialogOpen(false)}
        />
      )}
      {resetDialogOpen && (
        <ResetDialog
          open={resetDialogOpen}
          selected={selectedInboundIDs}
          onClose={onCloseResetDialog}
          onCancel={() => setResetDialogOpen(false)}
        />
      )}
    </>
  )
}
