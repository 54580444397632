import React, { forwardRef } from 'react'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import { findCostKeys } from '../../actions/CostKeyActions'
import Models from '../../models'
// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../hooks/useStandardTableSetup'

export const defaultColumns = Object.freeze(Models.CostKeyCode.columns)

const defaultSort = { col: 'code', dir: 'asc' }

export const Table = forwardRef(function CostKeyTableList(
  {
    customColumns = defaultColumns,
    apiEndpoint = findCostKeys,
    fnLinkOnRowClick = (row: any) => `/cost_key/${row.ID}`,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      fnLinkOnRowClick,
      DataTableProps: {
        ...DataTableProps,
        rowOptsApplier: (row: any) =>
          row.Deprecated ? { className: 'tr-warning' } : null,
      },
    },
    ref
  )

  return <>{TableDisplay}</>
})
