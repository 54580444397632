import React, { useState, useEffect } from 'react'
import InvoiceStatusFilter from './statusFilter'
import * as api from '../../services/thezerocard/api-helper'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import Models from '../../models'
import DateInputsByNextStatus from './dateInputsByNextStatus'
import {
  CircularProgress,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
require('./dialogEditInvoice.scss')

const { STATUS_APPROVED } = Models.Invoice

interface props {
  isOpen: boolean
  invoiceID?: number | null
  onDialogClose?(): void
  doAfterSave?(): void
}

export default function DialogEditInvoice({
  isOpen,
  invoiceID,
  onDialogClose,
  doAfterSave,
}: props): React.ReactElement {
  const [isLoading, setIsLoading] = useState(false)
  const [invObj, setInvObj] = useState<any>({})
  const [initialStatus, setInitialStatus] = useState<number | null>(null)
  const [confirmReqd, setConfirmReqd] = useState<any>(null)
  const { showForDuration: showSnackbar } = useSnackbar()

  useEffect(() => {
    !isOpen && handleClose()
  }, [isOpen])

  useEffect(() => {
    if (!invoiceID) {
      return
    }
    setIsLoading(true)
    api
      .get(`/invoice/${invoiceID}`)
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        setInvObj(res.Data)
        setInitialStatus(res.Data.StatusId)
      })
      .catch((err: any) => {
        handleClose()
        if (err.Error?.Message) {
          showSnackbar(err.Error.Message, SnackbarTypeError)
          return
        }
        showSnackbar(
          'Request failed; please contact engineering',
          SnackbarTypeError
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [invoiceID])

  function handleClose() {
    onDialogClose && onDialogClose()
  }

  function handleSave() {
    // check if status was rolled back
    if (
      invObj.StatusId < Number(initialStatus) &&
      Number(initialStatus) >= STATUS_APPROVED
    ) {
      new Promise((res: any, rej: any) => {
        setConfirmReqd({ resolve: res, reject: rej })
      })
        .then(doSave)
        .catch(() => {
          /* no-op; the reject promise doesn't need to do anything */
        })
        .finally(() => {
          setConfirmReqd(null)
        })
      return
    }
    doSave()
  }

  function doSave() {
    setIsLoading(true)
    api
      .put(`/invoice/${invObj.ID}`, invObj)
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        showSnackbar(`Invoice #${invObj.Number} Saved OK!`, SnackbarTypeSuccess)
        handleClose()
        doAfterSave && doAfterSave()
      })
      .catch((e: any) => {
        showSnackbar(e.Error?.Message, SnackbarTypeError)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function onStatusChange(s: any) {
    setInvObj((inv: any) => {
      return { ...inv, StatusId: s.ID, Status: s }
    })
  }

  return (
    <>
      <Dialog
        className="dialog-edit-invoice"
        open={isOpen}
        onClose={handleClose}
        maxWidth={false}>
        <DialogTitle>Edit Invoice</DialogTitle>
        <DialogContent>
          {isLoading && (
            <div className="loading-wrapper">
              <CircularProgress />
            </div>
          )}

          {!isLoading && invObj.ID > 0 && (
            <>
              <InvoiceStatusFilter
                fullWidth
                disableClearable
                selectedID={invObj.StatusId}
                onChange={onStatusChange}
                TextFieldProps={{ label: 'Status' }}
              />
              <DateInputsByNextStatus
                nextStatusID={invObj.StatusId}
                entity={invObj}
                setter={setInvObj}
              />
              <TextField
                fullWidth
                placeholder="..."
                label="Notes"
                multiline
                rows={5}
                rowsMax={8}
                size="small"
                variant="outlined"
                margin="normal"
                value={invObj.Notes}
                onChange={(ev: any) => {
                  const v = ev.target?.value
                  setInvObj((x: any) => ({ ...x, Notes: v }))
                }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions className="dialog-footerbar">
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button
                disabled={isLoading}
                color="secondary"
                variant="outlined"
                onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                disabled={isLoading}
                color="primary"
                variant="contained"
                onClick={handleSave}>
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      {confirmReqd && (
        <RenderConfirmStatusChange
          resolve={confirmReqd.resolve}
          reject={confirmReqd.reject}
        />
      )}
    </>
  )
}

function RenderConfirmStatusChange({
  resolve,
  reject,
}: any): React.ReactElement | null {
  return (
    <Dialog open={!!resolve || !!reject}>
      <DialogTitle>Confirm Status Changes</DialogTitle>
      <DialogContent>
        You are attempting to change the status of an invoice to a previous
        status. This will clear out any dates that have been set as part of
        another status change (Funded, Farley Paid Date). Do you wish to
        continue and update the status?
      </DialogContent>
      <DialogActions className="dialog-footerbar">
        <Grid container spacing={2} justify="space-between">
          <Grid item xs="auto">
            <Button color="secondary" variant="outlined" onClick={reject}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button color="primary" variant="contained" onClick={resolve}>
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
