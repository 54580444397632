import * as api from '../services/thezerocard/api-helper'
export const GET_COST_KEY_CODE = 'GET_COST_KEY_CODE'
export const GET_COST_KEY_CODES = 'GET_COST_KEY_CODES'
export const GET_ALL_COST_KEY_KEYWORDS = 'GET_ALL_COST_KEY_KEYWORDS'
export const GET_PRICES_FOR_COST_KEY = 'GET_PRICES_FOR_COST_KEY'
export const DEACTIVATE_PRICES_FOR_COST_KEY = 'DEACTIVATE_PRICES_FOR_COST_KEY'
export const DELETE_COST_KEY_CODE = 'DELETE_COST_KEY_CODE'
export const SAVE_NEW_COST_KEY_CODE = 'SAVE_NEW_COST_KEY_CODE'
export const SAVE_COST_KEY_CODE = 'SAVE_COST_KEY_CODE'
export const GET_COST_KEY_CODE_TIERS = 'GET_COST_KEY_CODE_TIERS'
export const GET_COST_KEY_BILLING_TRANSLATION =
  'GET_COST_KEY_BILLING_TRANSLATION'
export const SAVE_COST_KEY_BILLING_TRANSLATION =
  'SAVE_COST_KEY_BILLING_TRANSLATION'
export const GET_COST_KEY_CATEGORIES = 'GET_COST_KEY_CATEGORIES'

export const findCostKeysRedux = (params) => {
  return {
    type: GET_COST_KEY_CODES,
    payload: api.search('/cost_keys', params),
  }
}

export const getCostKeyCode = (params) => {
  const { ID } = params

  return {
    type: GET_COST_KEY_CODE,
    payload: api.get(`/cost_key/${ID}`),
  }
}

// @todo: deprecate this and remove the route on the backend; use the normal
// findCostKeys (non-redux) action
export const findCostKeyByCode = (params) => {
  const qsParams = { cost_key: params.costKey }

  return api.get('/find_cost_key', qsParams)
}

export const getNewCostKeyCode = () => {
  return {
    type: GET_COST_KEY_CODE,
    payload: {},
  }
}

export const getAllCostKeyKeywords = () => {
  return {
    type: GET_ALL_COST_KEY_KEYWORDS,
    payload: api.get('/cost_key_keyword'),
  }
}

export const saveCostKeyCode = (params, body) => {
  const { ID } = params

  return {
    type: SAVE_COST_KEY_CODE,
    payload: api.put(`/cost_key/${ID}`, body),
  }
}

export const saveNewCostKeyCode = (body) => {
  return {
    type: SAVE_NEW_COST_KEY_CODE,
    payload: api.post('/cost_key', body),
  }
}

export const deleteCostKeyCode = (params) => {
  const { ID } = params

  return {
    type: DELETE_COST_KEY_CODE,
    payload: api.del(`/cost_key/${ID}`),
  }
}

export const getCostKeyCodeRuleSet = (params) => {
  return api.get(`/cost_key/${params.costKeyID}/rule_set`)
}

export const saveCostKeyCodeRuleSet = (params, body) => {
  return api.put(`/cost_key/${params.costKeyID}/rule_set`, body)
}

export const getCostKeyBillingTranslation = (params) => {
  return {
    type: GET_COST_KEY_BILLING_TRANSLATION,
    payload: api.get(`/cost_key/${params.costKeyID}/billing_translation`),
  }
}

export const saveCostKeyBillingTranslation = (params, body) => {
  return {
    type: SAVE_COST_KEY_BILLING_TRANSLATION,
    payload: api.put(`/cost_key/${params.costKeyID}/billing_translation`, body),
  }
}

export const getCostKeyCategories = () => {
  return {
    type: GET_COST_KEY_CATEGORIES,
    payload: api.get('/cost_key_categories'),
  }
}

export const findCostKeys = (params) => {
  return api.search('/cost_keys', params)
}

export function findCostKeyCategories(params) {
  return api.search('/cost_key_category', params)
}

export function getCostKeyCategoryByID(id) {
  return api.get(`/cost_key_category/${id}`)
}
