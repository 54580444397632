import { DataTable } from '../../../components/DataTable'

const Model = {
  columns: {
    CostKey: { name: 'Cost Key', details: {} },
    Price: { name: 'Price', details: {} },
    ExcludeFromOppAnalysis: { name: 'Excl. Opp', details: {} },
    ExcludeFromPublicSearch: { name: 'Excl. Search', details: {} },
    Note: { name: 'Note', details: {} },
    PracticeFacilityID: { name: 'Practice Facility ID', details: {} },
    ExtPracticeID: { name: 'External Practice ID', details: {} },
    Cause: { name: 'Cause', details: {} },
  },
}

export default function ErrantLines({ errantLines, fileName }) {
  if (!errantLines || !errantLines.length) {
    return null
  }

  const expanded = errantLines.map((line) => {
    return { ...line.Line, Cause: line.Cause }
  })

  return (
    <DataTable
      columns={Model.columns}
      initPage={1}
      initPageSize={errantLines.length}
      rowsPerPage={[errantLines.length]}
      data={expanded}
      count={errantLines.length}
      csvExportable={true}
      csvExportableFilename={`${fileName}-errant-lines.csv`}
    />
  )
}
