import React, { useState, useEffect } from 'react'
import {
  CircularProgress,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import * as api from '../../services/thezerocard/api-helper'
import { Link } from 'react-router-dom'
import DesignSuite2023 from '../../components/DesignSuite2023'

export default function SendgridMarketingExport(): any {
  const [isOpen, setIsOpen] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [wasSuccessful, setWasSuccessful] = useState<any>(null)
  const { show: showSnackbar } = useSnackbar()

  useEffect(() => {
    if (!isOpen) {
      return
    }
    setIsWorking(false)
  }, [isOpen])

  const doTrigger = () => {
    setIsWorking(true)
    api
      .post('/sendgrid_export_request')
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        showSnackbar('success', SnackbarTypeSuccess)
        setWasSuccessful(true)
      })
      .catch((err: any) => {
        if (Array.isArray(err.errList) && err.errList.length > 0) {
          showSnackbar(
            <div>
              One or more errors occurred:
              {err.errList.map((m: any) => {
                return <span style={{ display: 'block' }}>{m}</span>
              })}
            </div>,
            SnackbarTypeError
          )
          return
        }
        if (err.Error?.Message) {
          showSnackbar(err.Error.Message, SnackbarTypeError)
          return
        }
        showSnackbar(
          'Request failed; please contact engineering',
          SnackbarTypeError
        )
      })
      .finally(() => {
        setIsWorking(false)
      })
  }

  return (
    <>
      <DesignSuite2023.Tooltip placement="right" title="Launch workflow dialog">
        <Button
          variant="contained"
          color="primary"
          onClick={setIsOpen.bind(null, true)}>
          Sendgrid Marketing Export
        </Button>
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen} onClose={setIsOpen.bind(null, false)}>
        <DialogTitle>Sendgrid Marketing Export</DialogTitle>
        <DialogContent>
          {!!wasSuccessful && (
            <>
              <p>Find files here:</p>
              <p>
                <Link to="partner/173" target="_blank">
                  Sendgrid partner
                </Link>
              </p>
            </>
          )}

          {!wasSuccessful && (
            <>
              <p>
                Note: due to all the background/network stuff, this API request
                could take a while. Please do not refresh while it's running.
              </p>
              <p>
                This will trigger an export from the DataWarehouse of Sendgrid
                marketing data (updates and deletes) and store the file under
                the Sendgrid partner.
              </p>
              <div style={{ textAlign: 'center' }}>
                {isWorking && <CircularProgress />}
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button
                color="secondary"
                variant="outlined"
                onClick={setIsOpen.bind(null, false)}>
                Close
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                color="secondary"
                variant="contained"
                disabled={isWorking || !!wasSuccessful}
                onClick={doTrigger}>
                Trigger
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}
