import React, { useEffect, useState } from 'react'
import { Button, Dialog } from '@material-ui/core'
import CollectionPicker from '../../../Components/Collections/Picker'
import * as api from '../../../../../services/thezerocard/api-helper'
import useErrorHandlers from '../../../../../hooks/useErrorHandlers'
import useSnackbar, {
  SnackbarTypeSuccess,
} from '../../../../../hooks/useSnackbar'

export default function TurnVBAIntoNormalClaim(): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [collectionIDs, setCollectionIDs] = useState<Array<number>>([])
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  useEffect(() => {
    if (!isOpen) return
    setCollectionIDs([])
  }, [isOpen])

  function proceed() {
    api
      .post('/zclaims/tools/clone_vba_claim_to_new', {
        CollectionID: collectionIDs[0],
      })
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar(
          'VBA claims turned into normal (cloned) claims',
          SnackbarTypeSuccess
        )
        setIsOpen(false)
        alert(
          'Claims created from original VBA records. The new claims have automatically been ADDED to the collection you passed.'
        )
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed resetting claims from EDI',
        })
      )
  }

  return (
    <>
      <Button size="small" variant="outlined" onClick={() => setIsOpen(true)}>
        Turn VBA Claim Into New Normal Claim
      </Button>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <div style={{ padding: '1rem', minWidth: 500 }}>
          {isOpen && (
            <CollectionPicker
              inputLabel="Choose Collection"
              handleChange={setCollectionIDs}
              currentCollectionIDs={collectionIDs}
            />
          )}
          <p>
            The collection you pick must contain only claims
            <ul>
              <li>originally ingested from VBA</li>
              <li>claims that have not already been copied using this tool</li>
            </ul>
          </p>

          <p>
            After cloning the original VBA claim, all the newly created onees
            will be added to the same collection, and all original VBA ones will
            be added to zclaim_trash.
          </p>
          <Button variant="contained" size="small" onClick={proceed}>
            Continue
          </Button>
        </div>
      </Dialog>
    </>
  )
}
