import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  CircularProgress,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core'
import * as FileUpload from '../../components/FileUpload'
import {
  getProviderClaimInvoiceOptions,
  postSendProviderClaimFileID,
} from '../../actions/EngineeringActions'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import styled from 'styled-components'
import DesignSuite2023 from '../../components/DesignSuite2023'

interface option {
  OrganizationID: number
  Label: string
  DateFormat: string
}

interface data {
  FileTypeID: number
  Options: Array<option>
}

interface getOptionsData {
  error?: boolean
  Error?: Array<string>
  Data?: data
}

export default function SendProviderClaims(): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { catchAPIError } = useErrorHandlers()
  const { show: showSnackbar } = useSnackbar()
  const [data, setData] = useState<data | null>(null)
  const [selectedOption, setSelectedOption] = useState<option | null>(null)
  const [capturedFile, setCapturedFile] =
    useState<FileUpload.postFileResponse | null>(null)
  const [stableAcceptFileTypePresets] = useState({
    'text/plain': ['.csv'],
  })

  useEffect(() => {
    if (!isOpen) {
      return
    }
    setData(null)
    setSelectedOption(null)
    setIsLoading(true)
    getConfig()
  }, [isOpen])

  useEffect(() => {
    if (!selectedOption?.OrganizationID) {
      setCapturedFile(null)
      return
    }
    if (!data) {
      return
    }
    for (let i = 0; i < data.Options.length; i++) {
      if (data.Options[i].OrganizationID === selectedOption.OrganizationID) {
        return
      }
    }
  }, [data, selectedOption])

  function getConfig() {
    getProviderClaimInvoiceOptions()
      .then((res: getOptionsData & any) => {
        if (res.error) {
          throw res
        }
        setData(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed loading sendable claims options; please contact engineering',
        })
      )
      .finally(() => {
        setIsLoading(false)
      })
  }

  function doSend() {
    if (!capturedFile) {
      return
    }
    postSendProviderClaimFileID({ FileID: capturedFile.ID })
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        showSnackbar(
          'Queued provider claim request processing OK!',
          SnackbarTypeSuccess
        )
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed sending provider claim processing request',
        })
      )
      .finally(() => {
        setIsOpen(false)
      })
  }

  function onRadioChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const org = data
      ? data.Options.find((opt) => opt.OrganizationID === +ev.target.value)
      : null
    setSelectedOption(org || null)
  }

  function isDisabled(): boolean {
    if (!(capturedFile || {}).ID) {
      return true
    }
    return false
  }

  return (
    <>
      <DesignSuite2023.Tooltip
        placement="right"
        title="Opens a dialog (does not immediately perform an action)">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsOpen(true)
          }}>
          Send Provider Claims Workflow
        </Button>
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen}>
        {/* set the width here to a minimum so that the fileUpload display doesn't cause bouncing around */}
        <StyledDialogContent>
          {isLoading && <CircularProgress />}

          {!isLoading && (
            <>
              <Accordion expanded={!selectedOption}>
                <AccordionSummary>
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item sm="auto">
                      <Typography>
                        {(selectedOption && selectedOption.Label) ||
                          'Select Organization'}
                      </Typography>
                    </Grid>
                    {!!selectedOption && (
                      <Grid item sm="auto">
                        <Button
                          onClick={() => {
                            setSelectedOption(null)
                          }}>
                          Clear
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="organization"
                      name="organization"
                      value={selectedOption?.OrganizationID}
                      onChange={onRadioChange}>
                      {data &&
                        data.Options.map((o: option) => {
                          return (
                            <FormControlLabel
                              key={o.OrganizationID}
                              value={o.OrganizationID}
                              label={o.Label}
                              control={<Radio />}
                            />
                          )
                        })}
                    </RadioGroup>
                  </FormControl>
                </AccordionDetails>
              </Accordion>

              <Accordion expanded={!!selectedOption}>
                <AccordionSummary>
                  <Typography>
                    Select File{' '}
                    {selectedOption ? (
                      <span>
                        - Date Format: <code>{selectedOption.DateFormat}</code>
                      </span>
                    ) : null}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {!!selectedOption && data && (
                    <FileUpload.Uploader
                      autoSign
                      autoUniqueName
                      acceptPresets={stableAcceptFileTypePresets}
                      defaultFileData={{
                        FileTypeID: data.FileTypeID,
                        OrganizationID: selectedOption.OrganizationID,
                      }}
                      CustomFileDisplay={(props: any) => {
                        return (
                          <FileUpload.DefaultSingleFileInput
                            {...props}
                            showInputs={false}
                          />
                        )
                      }}
                      onSuccess={(res: FileUpload.postFileResponse[]) => {
                        setCapturedFile(res[0])
                      }}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </StyledDialogContent>

        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  setIsOpen(false)
                }}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                disabled={isDisabled()}
                color="primary"
                variant="contained"
                onClick={doSend}>
                Send Provider Claim
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

const StyledDialogContent = styled(DialogContent)`
  min-width: 560px;
`
