import React from 'react'
import { ActiveFormatter } from '../utils/ReactFormatters'
import utils from '../utils'
import goZeroUsers from './GoZeroUsers'
import { Network } from '../views/Networks/types'

export const READ_PERMISSION = 'r:provider'

export interface OrgRecordShape {
  ID: number | null
  Hash: string | null
  CreatedAt: string | null
  UpdatedAt: string | null
  ModifiedByUserID: number | null
  Name: string | null
  Notes: string | null
  LoaOnly: boolean | null
  BillingNotes: string | null
  PHANotes: string | null
  Ranking: number | null
  ZeroRanking: number | null
  RankingNotes: string | null
  Descr: string | null
  AcceptingReferrals: boolean | null
  GoZeroBilling: boolean | null
  LaunchDate: string | null
  TerminationDate: string | null
  TerminationNotes: string | null
  EdiBilling: boolean | null
  PaperBilling: boolean | null
  Website: string | null
  SchedulingPortalURL: string | null
  PaymentMethod: string | null
  BillingCompanyName: string | null
  Specializations: string[]
  ImplementationStatus: string | null
  EMRSoftware: string | null
  IsReferenceProvider: boolean | null
  ContractingType: number | null
  ConfigurableNetworks: Network[]
  DefaultGoZeroAccessGroupID?: number | null
  IsActive?: boolean | null
}

export const defaultOrg: OrgRecordShape = {
  ID: 0,
  Hash: null,
  CreatedAt: null,
  UpdatedAt: null,
  ModifiedByUserID: null,
  Name: null,
  LoaOnly: false,
  Notes: null,
  BillingNotes: null,
  PHANotes: null,
  Ranking: 3,
  ZeroRanking: 3,
  RankingNotes: null,
  Descr: null,
  AcceptingReferrals: false,
  LaunchDate: null,
  TerminationDate: null,
  TerminationNotes: null,
  GoZeroBilling: false,
  EdiBilling: false,
  PaperBilling: false,
  Website: null,
  SchedulingPortalURL: null,
  PaymentMethod: null,
  BillingCompanyName: null,
  Specializations: [],
  ImplementationStatus: null,
  EMRSoftware: null,
  IsReferenceProvider: null,
  ContractingType: null,
  DefaultGoZeroAccessGroupID: null,
  ConfigurableNetworks: [],
}

export const diffChangedFields = Object.freeze([
  { name: 'Name', label: 'Name' },
  { name: 'Notes', label: 'Notes' },
  { name: 'LoaOnly', label: 'LOA Only' },
  { name: 'BillingNotes', label: 'Billing Notes' },
  { name: 'PHANotes', label: 'PHA Notes' },
  { name: 'Ranking', label: 'Ranking' },
  { name: 'ZeroRanking', label: 'ZeroRanking' },
  { name: 'RankingNotes', label: 'RankingNotes' },
  { name: 'Descr', label: 'Description' },
  { name: 'AcceptingReferrals', label: 'GoZERO' },
  { name: 'GoZeroBilling', label: 'GoZERO Billing' },
  { name: 'LaunchDate', label: 'Launch Date' },
  { name: 'EdiBilling', label: 'EDI Billing' },
  { name: 'PaperBilling', label: 'Paper Billing' },
  { name: 'Website', label: 'Website' },
  { name: 'SchedulingPortalURL', label: 'Scheduling Portal URL' },
  { name: 'PaymentMethod', label: 'Payment Method' },
  { name: 'BillingCompanyName', label: 'Billing Company' },
  { name: 'ImplementationStatus', label: 'Implementation Status' },
  { name: 'EMRSoftware', label: 'EMR Software' },
  { name: 'IsReferenceProvider', label: 'Reference Provider' },
  { name: 'ContractingType', label: 'Contracting Type' },
  { name: 'ConfigurableNetworks', label: 'Networks' },
])

export const columns = Object.freeze({
  Name: {
    name: 'Name',
    details: {},
  },
  IsActive: {
    name: 'Active Provider',
    details: { width: '100px', dataFormat: getActive },
  },
  Ranking: { name: 'Ranking' },
  Notes: { name: 'Notes' },
})

export const legendItems = {
  Inactive: {
    color: 'yellow',
    label: 'Inactive',
  },
}

export const paymentMethodOptions = [
  { label: 'EFT', value: 'eft' },
  { label: 'Paper Check', value: 'paper_check' },
]

export const implementationStatusOptions = [
  { label: 'Pricing Review', value: 'Pricing Review' },
  { label: 'Contract Signed', value: 'Contract Signed' },
  { label: 'goZERO Trained', value: 'goZERO Trained' },
  { label: 'Implemented', value: 'Implemented' },
]

export const rankingDescriptions = {
  1: 'Our relationship is in trouble',
  2: 'Our relationship needs some attention',
  3: 'Our relationship is fine (default)',
  4: 'We have a pretty good relationship with this provider',
  5: 'We have a glowing relationship with this provider',
}

export const hasGoZeroUserAccess = () => {
  return goZeroUsers.hasAccess() && utils.checkPerms(READ_PERMISSION)
}

function getActive(_: any, row: OrgRecordShape) {
  return <ActiveFormatter active={row.IsActive} />
}
