import get from 'lodash/get'
import utils from '../utils'

const UPDATE_PERMISSION = 'u:user'
const CREATE_PERMISSION = 'c:user'
const OFFBOARD_USER = 'a:engineering'

const user = {
  columns: {
    ID: { name: 'ID' },
    IsActive: {
      name: 'Active',
      details: { dataFormat: utils.activeFormatter('IsActive') },
    },
    ExtUsername: {
      name: 'Username',
      details: {
        dataFormat(_, row) {
          return get(row, 'ExtUsername', '---')
        },
      },
    },
    Email: {
      name: 'Email',
      details: {
        dataFormat(_, row) {
          return get(row, 'Email', '---')
        },
      },
    },
    LastName: {
      name: 'Last Name',
      details: {
        dataFormat(_, row) {
          return get(row, 'LastName', '---')
        },
      },
    },
    FirstName: {
      name: 'First Name',
      details: {
        dataFormat(_, row) {
          return get(row, 'FirstName', '---')
        },
      },
    },
    IsGoZero: {
      name: 'GoZero',
      details: { dataFormat: utils.activeFormatter('IsGoZero') },
    },
    IsTest: {
      name: 'TestUser?',
      details: { dataFormat: utils.activeFormatter('IsTest') },
    },
  },
  editFields: {
    ExtUsername: '',
    FirstName: '',
    LastName: '',
    Email: '',
    IsActive: true,
    IsGoZero: false,
    IsTest: false,
    ZendeskUserID: '',
  },
  canCreate: () => {
    return utils.checkPerms(CREATE_PERMISSION)
  },
  canUpdate: () => {
    return utils.checkPerms(UPDATE_PERMISSION)
  },
  canOffboard: () => {
    return utils.checkPerms(OFFBOARD_USER)
  },
}

export default user
