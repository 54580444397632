import React, { useState, useEffect } from 'react'
import * as api from '../../../services/thezerocard/api-helper'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Button,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import { Clear as IconClear } from '@material-ui/icons'
import * as types from './types'

interface props {
  existingRecipient: types.recipient
  onSave?: () => void
}

interface shapeLinkedRecipient {
  Name: string | null
  Handle: string | null
  Notes: string | null
  DefaultDestPath: string | null
  NotifiableEmails: string[] | null
  ParentRecipientID: number
}

export default function DialogLinkRecipient({
  existingRecipient,
  onSave,
}: props): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [linkedRecipient, setLinkedRecipientData] =
    useState<shapeLinkedRecipient>({
      Name: existingRecipient.Name,
      Handle: null,
      Notes: null,
      DefaultDestPath: null,
      NotifiableEmails: [],
      ParentRecipientID: existingRecipient.ID as number,
    })
  const { catchAPIError } = useErrorHandlers()
  const { show: showSnackbar } = useSnackbar()

  useEffect(() => {
    if (!isOpen) return
    setLinkedRecipientData({
      Name: existingRecipient.Name,
      Handle: null,
      Notes: null,
      DefaultDestPath: null,
      NotifiableEmails: [],
      ParentRecipientID: existingRecipient.ID as number,
    })
  }, [isOpen])

  useEffect(() => {
    setIsValid(linkedRecipient.Name !== existingRecipient.Name)
  }, [linkedRecipient.Name])

  function rmEmailField(idx: number) {
    setLinkedRecipientData(
      (curr: shapeLinkedRecipient): shapeLinkedRecipient => {
        const NotifiableEmails = curr.NotifiableEmails || []
        NotifiableEmails.splice(idx, 1)
        return { ...curr, NotifiableEmails }
      }
    )
  }

  function addEmailField() {
    setLinkedRecipientData(
      (curr: shapeLinkedRecipient): shapeLinkedRecipient => {
        const NotifiableEmails = curr.NotifiableEmails || []
        NotifiableEmails.push('')
        return { ...curr, NotifiableEmails }
      }
    )
  }

  function doSave() {
    api
      .post(
        `/engineering/sftpaas/recipient/${existingRecipient.ID}/link-child`,
        linkedRecipient
      )
      .then(() => {
        showSnackbar('New recipient linked OK', SnackbarTypeSuccess)
        onSave && onSave()
      })
      .catch(catchAPIError({ defaultMessage: 'Failed cloning recipient' }))
  }

  // if existing recipient id is not >= 1, don't return anything - this feature is disabled
  if (!(parseInt(existingRecipient?.ID as any) >= 1)) return <></>
  // if existing recipient is itself a linked one, this feature is disabled
  if (parseInt(existingRecipient?.ParentRecipientID as any) >= 1) return <></>

  return (
    <>
      <DesignSuite2023.Tooltip
        title={`(There is another step): Use this to create a *new* recipient that shares *this recipient's* credential set.`}>
        <Button variant="outlined" onClick={() => setIsOpen(true)}>
          New Linked Recipient
        </Button>
      </DesignSuite2023.Tooltip>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="dialog-recipient"
        maxWidth={false}>
        <Grid
          container
          spacing={2}
          justify="space-between"
          alignItems="center"
          className="dialog-pseudo-title">
          <Grid item xs="auto">
            <h2>
              New Linked Recipient: <strong>{linkedRecipient.Name}</strong>
              &nbsp;<small>(links {existingRecipient.Name})</small>
            </h2>
          </Grid>
          <Grid item xs="auto">
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setIsOpen(false)}>
              Close
            </Button>
          </Grid>
        </Grid>

        <DialogContent style={{ width: 1000 }}>
          <DesignSuite2023.AlertInfo style={{ margin: '1rem 0 1.5rem' }}>
            This will create a new recipient that uses the same credential set
            as the parent being linked <i>from</i>. You can adjust the setting
            below, but crucially,{' '}
            <strong>
              the credentials <i>and PGP encryption configuration</i>
              &nbsp;defined by the existing recipient will be used
            </strong>
            . Also, just like when creating a new recipient, the{' '}
            <strong>handle</strong> is automatically derived from the name by
            the system.
          </DesignSuite2023.AlertInfo>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                label="Name"
                placeholder="..."
                variant="outlined"
                value={linkedRecipient.Name || ''}
                onChange={(ev: any) => {
                  setLinkedRecipientData({
                    ...linkedRecipient,
                    Name: ev.target.value,
                  })
                }}
                error={linkedRecipient.Name === existingRecipient.Name}
                helperText={
                  linkedRecipient.Name === existingRecipient.Name
                    ? 'Name must be different from parent recipient'
                    : null
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                size="small"
                label="Default Destination Path"
                placeholder="~/zerocard_drops/folder_a"
                variant="outlined"
                value={linkedRecipient.DefaultDestPath || ''}
                onChange={(ev: any) => {
                  setLinkedRecipientData({
                    ...linkedRecipient,
                    DefaultDestPath: ev.target.value,
                  })
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={5}
                size="small"
                label="Notes"
                placeholder="..."
                variant="outlined"
                margin="normal"
                value={linkedRecipient.Notes || ''}
                onChange={(ev: any) => {
                  setLinkedRecipientData({
                    ...linkedRecipient,
                    Notes: ev.target.value,
                  })
                }}
                InputProps={{ style: { fontSize: 14 } }}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item>
              <div className="notifiable-emails">
                <h5>Notifiable Emails</h5>
                {!!linkedRecipient.NotifiableEmails &&
                  linkedRecipient.NotifiableEmails.map(
                    (x: any, idx: number) => {
                      return (
                        <TextField
                          key={idx}
                          autoFocus
                          variant="outlined"
                          size="small"
                          label="Email"
                          placeholder="x@y.com"
                          value={x || ''}
                          onChange={(ev: any) => {
                            const v = ev.target.value
                            setLinkedRecipientData(
                              (
                                curr: shapeLinkedRecipient
                              ): shapeLinkedRecipient => {
                                const NotifiableEmails =
                                  curr.NotifiableEmails || []
                                NotifiableEmails[idx] = v
                                return { ...curr, NotifiableEmails }
                              }
                            )
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={rmEmailField.bind(null, idx)}
                                  edge="end">
                                  <IconClear />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )
                    }
                  )}
                <Button variant="outlined" onClick={addEmailField}>
                  Add
                </Button>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DesignSuite2023.Divider />
        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            }
            right={
              <Button
                disabled={!isValid}
                color="primary"
                variant="contained"
                onClick={doSave}>
                Save
              </Button>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
