import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import DetailView, {
  defaultStyleCreator,
} from '../../components/DetailView/DetailView'
import DRGCodeSearcher from '../../components/Searchers/DRGCodeSearcher'
import Models from '../../models'
import { PriceActions, SnackbarActions } from '../../actions'

import {
  CircularProgress,
  Divider,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import dateTime from '../../utils/dateTime'
import ManagedDateInput from '../../components/Inputs/managedDateInput'

const { clearSaveResult, saveNewComponentPrice, SAVE_NEW_COMPONENT_PRICE } =
  PriceActions
const { setSnackbarMessage } = SnackbarActions

const styles = (theme) => {
  const defaults = defaultStyleCreator(theme)

  return Object.assign(
    {
      CPTField: {
        marginBottom: '25px',
        marginTop: '0px',
      },
      validationText: {
        marginTop: '25px',
      },
      paper: {
        width: 600,
      },
    },
    defaults
  )
}

export class AddPriceDialog extends DetailView {
  constructor(props) {
    super(props)

    this.initialState = {
      saving: false,
      validCPT: false,
      CPTValidationMessage: '',
      open: true,
      selectedDRG: null,
      fields: Object.assign({}, Models.ComponentPrice.editFields),
    }
    this.state = this.initialState

    this.renderContent = this.renderContent.bind(this)
  }

  handleSave = (e) => {
    this.setState({ saving: true })
    const { fields, selectedDRG } = this.state
    const { fsId, saveNewComponentPrice } = this.props
    const rowToSave = {
      StartDate: dateTime
        .parse(fields.StartDate)
        .format(dateTime.formats.ISODate),
      EndDate: dateTime.parse(fields.EndDate).format(dateTime.formats.ISODate),
      Units: parseInt(fields.Units),
      Price: fields.Price,
      CPTCode: fields.CPT,
      DRGCode: selectedDRG,
      ComponentFeeScheduleID: fsId,
    }

    saveNewComponentPrice(rowToSave)
  }

  componentWillUnmount() {
    this.props.clearSaveResult()
    this.setState(this.initialState)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { saveResult } = nextProps

    if (saveResult) {
      if (saveResult.error) {
        const msg = `An error occurred while saving: ${saveResult.message}`
        this.props.setSnackbarMessage(msg, 'error')
      } else if (saveResult.type === SAVE_NEW_COMPONENT_PRICE) {
        this.props.setSnackbarMessage('Price added!', 'success')
        this.props.onSave()
      }

      this.setState({ saving: false, saveResult })
      this.props.clearSaveResult()
    }
  }

  handleCPTChange = (e) => {
    const val = e.target.value

    if (val && val.length === 5) {
      this.setState({
        validCPT: true,
        CPTValidationMessage: '',
        fields: {
          ...this.state.fields,
          CPTCode: val,
        },
      })
    } else {
      this.setState({
        CPTValidationMessage: 'Invalid CPT Code',
        validCPT: false,
      })
    }

    this.handleChange(e)
  }

  renderDialog() {
    const { classes } = this.props

    return (
      <div>
        <Dialog
          classes={{ paper: classes.paper }}
          open={this.props.open}
          onClose={this.onCancel}>
          <DialogTitle>Add New Component Price</DialogTitle>
          <DialogContent>{this.renderContent()}</DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleSave}
              disabled={!this.state.validCPT}
              color="primary">
              Add
            </Button>
            <Button onClick={this.props.onCancel} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  renderContent() {
    const { classes } = this.props
    const { loading, selectedDRG } = this.state
    if (loading) return <CircularProgress className={classes.progress} />

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {this.renderTextField('CPT', 'CPT Code', {
              onChange: this.handleCPTChange,
              className: classes.CPTField,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            <DRGCodeSearcher
              selectedDRGCode={selectedDRG}
              onChange={(selectedDRG) => this.setState({ selectedDRG })}
              TextFieldProps={{
                InputLabelProps: {
                  shrink: true,
                },
                label: 'DRG CODE',
              }}
            />
          </Grid>
        </Grid>
        <Divider className={classes.sectionDivider} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {this.renderPriceField('Price', 'Price')}
            {/* {this.renderDateField('StartDate', 'Start Date')}
          {this.renderDateField('EndDate', 'End Date')} */}
            <ManagedDateInput
              name="StartDate"
              label="Start Date"
              value={this.state.fields.StartDate}
              setter={({ name, value }) => this.handleFieldUpdate(name, value)}
            />
            <ManagedDateInput
              name="EndDate"
              label="End Date"
              value={this.state.fields.EndDate}
              setter={({ name, value }) => this.handleFieldUpdate(name, value)}
            />
            {this.renderTextField('Units', 'Units')}
          </Grid>
          <Grid item sm={12} lg={9}>
            {this.state.saveResult && this.state.saveResult.error && (
              <Typography type="title" className={classes.errorMsg}>
                {this.state.saveResult.message}
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>
    )
  }

  render() {
    return <div>{this.renderDialog()}</div>
  }
}

AddPriceDialog.propTypes = {
  onSave: PropTypes.func,
  fsId: PropTypes.number.isRequired,
  isProd: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    saveResult: state.prices.saveResult,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { saveNewComponentPrice, setSnackbarMessage, clearSaveResult },
    dispatch
  )
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(AddPriceDialog)
