/*
@todo: anything that uses this DetailView should be considered a legacy
component that is fair game to be refactored. Goal should be to redo any
things that this, then delete this entirely.
*/
import React, { Component } from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { PhoneExtInput, PhoneInput, PriceInput, SSNInput } from '../Inputs'
import { Done, ErrorOutline, InfoOutlined } from '@material-ui/icons'
import { SnackMessage } from '../SnackMessage'
import scrollToElement from 'scroll-to-element'
import utils from '../../utils'
import queryString from 'query-string'
import _ from 'lodash'

import { red } from '@material-ui/core/colors'
import NotesField from '../NotesField'

export var defaultStyleCreator = (theme: any) => {
  return {
    adjustedTooltipIcon: {
      marginTop: '-30px',
    },
    filterChip: {
      margin: 4,
      marginTop: 15,
    },
    childTableBtn: {
      float: 'right',
      margin: 15,
    },
    chip: {
      margin: 4,
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    mainContent: {
      padding: '20px',
      paddingTop: '0px',
    },
    searchBar: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      marginRight: '16px',
    },
    subContent: {
      padding: '20px',
    },
    field: {
      width: 250,
    },
    wideField: {
      width: 400,
    },
    fieldColumn: {
      maxWidth: 300,
    },
    fieldColumnExpanded: {
      maxWidth: 500,
    },
    cardColumn: {
      maxWidth: 316,
      minWidth: 316,
    },
    wideCardColumn: {
      minWidth: 450,
    },
    cardDivision: {
      maxWidth: 664,
      minWidth: 664,
    },
    formControl: {
      marginTop: '16px',
    },
    childContainer: {
      marginTop: theme.spacing(),
    },
    progress: {
      margin: `0 ${theme.spacing(2)}px`,
    },
    button: {
      marginLeft: theme.spacing(),
      marginRight: theme.spacing(),
    },
    otherActionButton: {
      marginTop: theme.spacing(),
      marginRight: theme.spacing(),
    },
    actionGrid: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    sectionDivider: {
      marginTop: '20px',
      marginBottom: '20px',
    },
    childRow: {
      cursor: 'pointer',
    },
    bigNotesField: {
      width: '600px',
    },
    responsiveNotesField: {
      width: '100%',
    },
    statusIcon: {
      height: '36px',
      width: '36px',
      marginRight: '8px',
      marginLeft: '8px',
    },
    statusIconContainer: {
      float: 'left',
      display: 'inline-block',
    },
    saveButtonContainer: {
      float: 'right',
    },
    errorMsg: {
      textAlign: 'center',
      color: red[500],
    },
    addNewBtn: {
      float: 'right',
    },
    errorIcon: {
      height: '150px',
      width: '150px',
    },
    searchBtnGroup: {
      paddingTop: '30px',
    },
    searchBtn: {
      marginRight: theme.spacing(),
    },
    searchField: {
      display: 'inline-flex',
      marginTop: '0px',
    },
    tabContent: {
      minHeight: 850,
    },
  }
}

export interface Props {
  history: any
  classes: any
}

export interface State {
  openStates: any[]
  fields: any
  showValidations: boolean
  saveResult: any
  confirmUnsavedChanges: boolean
  changedFields: any
  trapFocus: boolean
  originalFields: any
  tabValue: any
}

interface Options {
  name?: string
  className?: any
  rows?: number
  rowsMax?: number
  label?: string
  error?: boolean
  errorMessage?: string
}

export class DetailView extends Component<Props, State> {
  saveResultTimer: any
  isScrolling: boolean
  pathname: string
  validators: any
  handleIgnoreUnsavedChanges: any

  constructor(props: Props) {
    super(props)
    this.isScrolling = false
    this.pathname = ''
    this.handleTabChange = this.handleTabChange.bind(this)
    this.handleNestedChange = this.handleNestedChange.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    this.checkHash()
  }

  componentDidUpdate() {
    this.checkHash()
  }

  componentWillUnmount() {
    clearTimeout(this.saveResultTimer)
  }

  /**
   * Equality function for selected tag array comparison
   */
  areTagArraysEqual = (arr1: any, arr2: any): any => {
    if (arr1 && arr2) {
      // If both arrays are empty they are the same
      if (arr1.length === 0 && arr2.length === 0) {
        return true
      }

      // If the length are different than they aren't equal
      if (arr1.length !== arr2.length) {
        return false
      }

      // Compare the ID of each index
      for (let i = 0; i < arr1.length; i += 1) {
        if (arr1[i].ID !== arr2[i].ID) {
          return false
        }
      }
    }

    // It passed all the checks they are equal
    return true
  }

  checkHash = () => {
    const { history } = this.props
    if (!history) {
      console.warn(
        'Navigated to a page that isnt setup with the withRouter component'
      )
      return
    }
    const { location } = history
    const { hash, pathname } = location

    if (!this.isScrolling && pathname !== this.pathname) {
      const element = hash !== '' ? hash : 'main'
      // when we have a specific element to scroll to, make it look nice with an easing function and delay.
      // when we are going to the top, just go immediately.
      const duration = hash !== '' ? 1000 : 1
      this.isScrolling = true
      this.pathname = pathname
      setTimeout(() => {
        const scroller: any = scrollToElement(element, {
          duration,
          offset: 0,
        })

        if (scroller) {
          scroller.on('end', () => {
            console.debug('Done scrolling to ', element)
            this.isScrolling = false
          })
        }
      }, 200)

      console.debug('Scrolling to element: ', element)
    }
  }

  renderSelect(name: any, label: string, items: any[], opts: any = {}) {
    const valOpts = this.getValidationOpts(name)
    let open
    if (
      this.state.openStates !== undefined &&
      this.state.openStates[name] !== undefined
    ) {
      open = this.state.openStates[name]
    } else {
      open = false
    }
    const menuProps = { open: open }
    let className = this.props.classes.field
    if (opts.fullWidth) {
      className = ''
    }
    const margin = opts.margin || 'normal'

    return (
      <FormControl
        className={className}
        margin={margin}
        fullWidth={opts.fullWidth}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Select
          {...opts}
          {...valOpts}
          value={this.state.fields[name]}
          open={open}
          MenuProps={menuProps}
          onOpen={this.handleSelectOpen(name)}
          onClose={this.handleSelectClose(name)}
          onChange={
            opts.onChange ? opts.onChange : this.handleSelectChange(name)
          }
          // @ts-ignore
          input={<Input open name={name} id={name} />}>
          {items.map((item) => {
            return (
              // @ts-ignore
              <MenuItem open key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            )
          })}
        </Select>
        {opts && opts.helperText && (
          <FormHelperText error={opts.error}>{opts.helperText}</FormHelperText>
        )}
        {valOpts && valOpts.helperText && (
          <FormHelperText error={valOpts.error}>
            {valOpts.helperText}
          </FormHelperText>
        )}
      </FormControl>
    )
  }

  renderSSNField(name: any, label: string, opts: any = {}) {
    const valOpts = this.getValidationOpts(name)
    return (
      <div>
        <SSNInput
          {...opts}
          {...valOpts}
          className={this.props.classes.field}
          label={label}
          onChange={this.formatAndHandleChange(utils.stripNonNumeric)}
          name={name}
          value={this.state.fields[name]}
        />
      </div>
    )
  }

  renderPhoneField(name: any, label: string, opts: any = {}) {
    const valOpts = this.getValidationOpts(name)
    return (
      <div>
        <PhoneInput
          {...opts}
          {...valOpts}
          className={this.props.classes.field}
          label={label}
          onChange={this.formatAndHandleChange(utils.stripNonNumeric)}
          name={name}
          value={this.state.fields[name]}
        />
      </div>
    )
  }

  renderPhoneExtField(name: any, label: string, opts: any = {}) {
    const valOpts = this.getValidationOpts(name)
    return (
      <div>
        <PhoneExtInput
          {...opts}
          {...valOpts}
          className={this.props.classes.field}
          label={label}
          onChange={this.formatAndHandleChange(utils.stripNonNumeric)}
          name={name}
          value={this.state.fields[name]}
        />
      </div>
    )
  }

  renderNotesField = (opts: Options = {}) => {
    const { classes } = this.props
    const {
      name = 'Notes',
      label = 'Notes',
      rows = 4,
      rowsMax = 8,
      className = classes.field,
    } = opts
    const valOpts = this.getValidationOpts(name)

    return (
      <NotesField
        {...opts}
        {...valOpts}
        id={`${name}-notes`} // necessary?
        label={label}
        multiline
        placeholder="Enter notes here"
        rows={rows}
        rowsMax={rowsMax}
        className={className}
        value={this.state.fields[name] || ''}
        onChange={this.handleChange}
        name={name}
        margin="normal"
      />
    )
  }

  renderBigNotesField = (opts = {}) => {
    const { classes } = this.props
    return this.renderNotesField({ className: classes.bigNotesField })
  }

  renderPriceField(name: any, label: string, opts: any = {}) {
    return this.renderTextField(name, label, {
      ...opts,
      InputProps: {
        inputComponent: PriceInput,
      },
    })
  }

  validToSave = () => {
    const { fields } = this.state
    const { validators } = this
    if (!validators) return true

    let valid = true
    Object.keys(validators).forEach((key) => {
      if (!validators[key].isValid(fields[key])) {
        this.setState({ showValidations: true })
        valid = false
        return false
      }
    })
    return valid
  }

  getValidationOpts(name: any): any {
    const val = this.state.fields[name] ? this.state.fields[name] : ''
    const { showValidations } = this.state
    let valOpts = {}

    if (
      showValidations &&
      this.validators &&
      this.validators[name] &&
      !this.validators[name].isValid(val)
    ) {
      valOpts = {
        error: true,
        helperText: this.validators[name].msg,
        FormHelperTextProps: { error: true },
      }
    }

    return valOpts
  }

  renderTextField(name: any, label: string, opts: any = {}) {
    const handleChange = opts.onChange || this.handleChange
    const val = this.state.fields[name] ? this.state.fields[name] : ''
    const valOpts = this.getValidationOpts(name)

    return (
      <TextField
        {...valOpts}
        label={label}
        className={
          opts.wide ? this.props.classes.wideField : this.props.classes.field
        }
        value={val}
        onChange={handleChange}
        name={name}
        margin="normal"
        {...opts}
      />
    )
  }

  renderNestedTextField(name: any, label: string, parent: any, opts: any) {
    if (!this.state.fields[parent]) {
      return <div />
    }

    return (
      <TextField
        {...opts}
        label={label}
        className={this.props.classes.field}
        value={this.state.fields[parent][name]}
        onChange={this.handleNestedChange}
        name={[parent, name].join('|')}
        margin="normal"
      />
    )
  }

  renderSwitchField(name: any, label: string, opts: any) {
    opts = Object.assign(
      {},
      {
        color: 'primary',
        checked: this.state.fields[name],
        onChange: this.handleSwitchChange(name),
      },
      opts
    )
    const { LabelProps } = opts
    return (
      <FormControlLabel
        {...LabelProps}
        control={<Switch {...opts} />}
        label={label}
      />
    )
  }

  renderSwitchFieldWithTooltip(
    name: any,
    label: string,
    opts: any,
    tooltip: string
  ) {
    return (
      <Grid container spacing={1} alignItems="flex-end">
        <Grid item xs={11}>
          {this.renderSwitchField(name, label, opts)}
        </Grid>
        <Grid item xs={1}>
          <Tooltip
            className={this.props.classes.adjustedTooltipIcon}
            title={tooltip}>
            <InfoOutlined />
          </Tooltip>
        </Grid>
      </Grid>
    )
  }

  renderCheckboxField(name: any, label: string, opts: any) {
    opts = Object.assign(
      {},
      {
        color: 'primary',
        checked: this.state.fields[name],
        onChange: this.handleCheckboxChange(name),
      },
      opts
    )
    return <FormControlLabel control={<Checkbox {...opts} />} label={label} />
  }

  renderSnackbar() {
    return <SnackMessage />
  }

  renderError(errorMsg: string) {
    const { classes } = this.props
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs />
          <Grid item xs={6} className={classes.errorMsg}>
            <ErrorOutline className={classes.errorIcon} />
            <div>
              {/* @ts-ignore */}
              <Typography type="title">{errorMsg}</Typography>
            </div>
          </Grid>
          <Grid item xs />
        </Grid>
      </div>
    )
  }

  renderStatusIcon() {
    let result
    const { classes } = this.props
    if (this.state.saveResult && this.state.saveResult.saved) {
      result = <Done className={classes.statusIcon} />
      this.saveResultTimer = setTimeout(() => {
        this.setState({ saveResult: null })
      }, 3000)
    }

    return (
      <div className={this.props.classes.statusIconContainer}>{result}</div>
    )
  }

  renderConfirmUnsavedChanges() {
    const { classes } = this.props

    return (
      <div>
        <Dialog
          className={classes.dialog}
          open={this.state.confirmUnsavedChanges}
          onClose={this.handleIgnoreUnsavedChanges}>
          <DialogTitle>Unsaved changes</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You have unsaved changes to {this.getChangedFields()}, please save
              them before continuing.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseUnsavedChanges} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  checkAndWarnForUnsavedChanges = (e: any) => {
    if (this.hasChanges()) {
      this.setState({ confirmUnsavedChanges: true })
      return true
    }
    return false
  }

  handleCloseUnsavedChanges = (e: any) => {
    this.setState({ confirmUnsavedChanges: false })
  }

  handleSwitchChange(name: any) {
    const handler = (e: any) => {
      this.setState({
        fields: {
          ...this.state.fields,
          [name]: e.target.checked,
        },
        changedFields: {
          ...this.state.changedFields,
          [name]: e.target.checked,
        },
      })
    }

    return handler
  }

  handleCheckboxChange(name: any) {
    return this.handleSwitchChange(name)
  }

  handleSelectOpen = (name: any) => (event: any) => {
    this.setState({
      trapFocus: false,
      openStates: {
        ...this.state.openStates,
        [name]: true,
      },
    })
  }

  handleSelectClose = (name: any) => (event: any) => {
    this.setState({
      trapFocus: true,
      openStates: {
        ...this.state.openStates,
        [name]: false,
      },
    })
  }

  handleSelectChange = (name: any) => (event: any) => {
    this.setState({
      fields: {
        ...this.state.fields,
        [name]: event.target.value,
      },
      changedFields: {
        ...this.state.changedFields,
        [name]: event.target.value,
      },
    })
  }

  handleNestedChange(e: any) {
    const [parent, name] = e.target.name.split('|')
    this.setState({
      fields: {
        ...this.state.fields,
        [parent]: {
          ...this.state.fields[parent],
          [name]: e.target.value,
        },
      },
      changedFields: {
        ...this.state.changedFields,
        [parent]: {
          ...(this.state.changedFields ? this.state.changedFields[parent] : {}),
          [name]: e.target.value,
        },
      },
    })
  }

  formatAndHandleChange(formatter: any) {
    return (e: any) => {
      const formatted = formatter(e.target.value)
      this.handleFieldUpdate(e.target.name, formatted)
    }
  }

  handleFieldUpdate = (name: any, value: string) => {
    this.setState({
      fields: {
        ...this.state.fields,
        [name]: value,
      },
      changedFields: {
        ...this.state.changedFields,
        [name]: value,
      },
    })
  }
  handleChangeDirectInput(changeOpts: { name: string; value: string }) {
    this.handleFieldUpdate(changeOpts.name, changeOpts.value)
  }

  handleChange(e: any) {
    this.handleFieldUpdate(e.target.name, e.target.value)
  }

  hasChanges = () => {
    return this.getChangedFields().length > 0
  }

  getChangedFields = (): any[] => {
    const { changedFields, originalFields } = this.state
    const fields: any[] = []

    _.forOwn(changedFields, (value, prop) => {
      // consider nested
      // eslint-disable-next-line
      if (originalFields[prop] != value) {
        fields.push(prop)
      }
    })

    return fields
  }

  renderChanges = () => {
    const changes = this.getChangedFields()
    let changesMessage = ''
    if (changes.length > 0) {
      changesMessage = `Unsaved changes to: ${changes.join(', ')}`
    }
    // @ts-ignore
    return <Typography type="body1">{changesMessage}</Typography>
  }

  handleTabChange(e: any, value: any) {
    const { history } = this.props
    this.setState({ tabValue: value })
    if (history) {
      const existingParams = queryString.parse(history.location.search)
      const stringified = queryString.stringify({
        ...existingParams,
        active_tab: value,
      })
      history.push(`?${stringified}`)
    }
  }

  render() {
    return <div />
  }
}

export default DetailView
