export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'
export const CLEAR_SNACKBAR = 'CLEAR_SNACKBAR'
export const SET_MESSAGE = 'SET_MESSAGE'

export const clearSnackbar = () => {
  return { type: CLEAR_SNACKBAR }
}
export const closeSnackbar = () => {
  return { type: CLOSE_SNACKBAR }
}

export const setSnackbarMessage = (message, type) => {
  return {
    type: SET_MESSAGE,
    payload: {
      message,
      type,
    },
  }
}
