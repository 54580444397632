import React from 'react'
import * as styler from '@material-ui/core/styles'

const theme = styler.createMuiTheme({
  palette: {
    // @todo: heaps of prework...
    // type: 'dark',
    // primary: {
    //   main: '#1976d2',
    // },
    // secondary: {
    //   main: '#dc004e',
    // },
  },
})

export default function ZeroThemeProvider({
  children,
}: any): React.ReactElement {
  return <styler.ThemeProvider theme={theme}>{children}</styler.ThemeProvider>
}
