import React, { useState, useEffect } from 'react'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import * as api from '../../services/thezerocard/api-helper'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { ContactTypeData, ContactTypeOwner } from './types'
import { Alert } from '@material-ui/lab'

interface props {
  onChange?(contactTypeRecord: ContactTypeData | null): void
  entityType: ContactTypeOwner
  defaultValue?: number | null
  errorMessage?: string
}

export default function ContactTypeFilter({
  onChange,
  entityType,
  defaultValue,
  errorMessage,
}: props): any {
  const [data, setData] = useState([])
  const [selected, setSelected] = useState<any>()
  const [isReady, setIsReady] = useState(false)
  const [showError, setShowError] = useState(false)
  const { showDurationShort: showSnackbarDuration } = useSnackbar()

  useEffect(() => {
    setIsReady(false)

    const res = api.get(`/contact_type/${entityType}`)
    if (!res) {
      showSnackbarDuration(
        'Indeterminate error: contact types may not have loaded correctly',
        SnackbarTypeError
      )
      return
    }

    res
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        setData(res.Data.map((r: any) => ({ ...r, Label: r.Description })))
        setIsReady(true)
      })
      .catch((err: any) => {
        if (err.Error?.Message) {
          showSnackbarDuration(err.Error.Message, SnackbarTypeError)
          return
        }
        showSnackbarDuration('Failed loading contact types', SnackbarTypeError)
      })
  }, [])

  useEffect(() => {
    if (!data || !defaultValue) return
    const initialValue = data.filter((type: any) => type.ID === defaultValue)[0]
    setSelected(initialValue)
  }, [data])

  // watch the defaultValue specifically; and if it changes, update the selected value
  // to reflect it
  useEffect(() => {
    if (!data || !defaultValue) return
    const initialValue = data.filter((type: any) => type.ID === defaultValue)[0]
    setSelected(initialValue)
  }, [defaultValue])

  if (!isReady) return null

  if (defaultValue && !selected) {
    return (
      <Alert severity="warning">
        Contact Type selector disabled; ContactType:{defaultValue} may be
        misconfigured.
      </Alert>
    )
  }

  return (
    <>
      <Autocomplete
        options={data}
        getOptionLabel={(opt: any) => {
          return opt.Label || ''
        }}
        getOptionSelected={(opt: any) => {
          return selected ? selected.ID === opt.ID : false
        }}
        value={selected}
        defaultValue={null}
        onChange={(_: any, opt: any | null) => {
          if (!opt) {
            setSelected(null)
            onChange && onChange(null)
            return
          }
          setShowError(false)
          setSelected(opt)
          onChange && onChange(opt)
        }}
        renderInput={(params: any) => (
          <TextField
            {...params}
            variant="outlined"
            label="Contact Type"
            placeholder="Contact Type"
            size="small"
            InputLabelProps={{ shrink: true }}
            style={{ minWidth: 200 }}
            error={showError}
            helperText={showError ? errorMessage : null}
            FormHelperTextProps={{ error: showError }}
          />
        )}
      />
    </>
  )
}
