import React, { useState, useEffect } from 'react'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import * as OrgTable from '../Organizations/OrganizationTable'
import { putAddNetworkEntities } from '../../actions/NetworkActions'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
} from '@material-ui/core'
import styled from 'styled-components'

const StyledDialogContent = styled(DialogContent)`
  .header-items {
    margin-top: 0 !important;
    h3 {
      margin: 0;
      padding: 0;
    }
  }
`

export interface props {
  networkID: number
  onClose?(): void
}

export default function DialogAddOrganization({
  networkID,
  onClose,
}: props): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState<Array<any>>([])
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  useEffect(() => {
    setSelected([])
  }, [isOpen])

  function doClose() {
    setIsOpen(false)
    onClose && onClose()
  }

  function doAdd() {
    putAddNetworkEntities(networkID, {
      OrganizationIDs: selected.map((e: any) => e.ID),
    })
      .then((_: any) => {
        showSnackbar('Added to network OK!', SnackbarTypeSuccess)
      })
      .catch(
        catchAPIError({ defaultMessage: 'Failed adding record to network' })
      )
      .finally(doClose)
  }

  function isDisabled() {
    return (selected || []).length === 0
  }

  if (!networkID) return null
  return (
    <>
      <Tooltip title="(EngineeringOnly): launch bulk-add workflow">
        <Button variant="outlined" onClick={setIsOpen.bind(null, true)}>
          Add Organization(s)
        </Button>
      </Tooltip>

      <Dialog open={isOpen} maxWidth="lg">
        <StyledDialogContent>
          <OrgTable.Table
            onRowClick={() => {}}
            onCheckHandler={setSelected}
            passFilters={{ activeStatus: true }}
            LeftHeaderItems={
              <>
                <h3>Add Organization To Network</h3>
              </>
            }
            RightHeaderItems={
              <>
                <OrgTable.StandardFilterSearch autoFocus />
              </>
            }
            DataTableProps={{
              LeftFooterItems: (
                <>
                  <small>
                    <strong>Inactive organizations are not displayed.</strong>
                  </small>
                </>
              ),
            }}
          />
        </StyledDialogContent>
        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button color="secondary" variant="outlined" onClick={doClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                disabled={isDisabled()}
                color="primary"
                variant="contained"
                onClick={doAdd}>
                Proceed
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}
