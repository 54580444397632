import React, { useEffect, useState, useCallback } from 'react'
import classNames from 'classnames'
import * as api from '../../services/thezerocard/api-helper'
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircle from '@material-ui/icons/CheckCircle'
import {
  Dialog,
  DialogContent,
  TextField,
  InputAdornment,
  Chip,
} from '@material-ui/core'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import * as typed from './typed'

interface findCostKeyResult {
  Data?: Array<typed.CostKeyResult>
  error?: boolean
  errList?: Array<string>
}

interface props {
  activeGroup: typed.GroupRecord | undefined
  onSelectCostKey(v: typed.CostKeyResult): void
  isOpen: boolean
  setIsOpen(v: boolean): void
}

// https://usehooks.com/useKeyPress/
// https://stackoverflow.com/questions/37557990/detecting-combination-keypresses-control-alt-shift
export default React.memo(function CostKeyQuickSearcher({
  activeGroup,
  onSelectCostKey,
  isOpen,
  setIsOpen,
}: props) {
  const [searchText, setSearchText] = useState('')
  const [costKeyData, setCostKeyData] =
    useState<Array<typed.CostKeyResult> | null>(null)
  const { show: showSnackbar } = useSnackbar()

  const onHotKeys = useCallback(
    (ev: any) => {
      if (ev.ctrlKey && ev.key === 'k') {
        if (!activeGroup) {
          showSnackbar(
            'CTRL+K shortcut only enabled when an active group is selected',
            SnackbarTypeError
          )
          return
        }
        setIsOpen(true)
      }
    },
    [activeGroup, setIsOpen, showSnackbar]
  )

  const onCloseDialog = () => {
    setIsOpen(false)
  }

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(ev.target.value)
  }

  const onClickCostKey = (r: typed.CostKeyResult) => {
    onSelectCostKey(r)
    setIsOpen(false)
  }

  useEffect(() => {
    window.addEventListener('keydown', onHotKeys)
    return () => {
      // remove event listeners on implementing-component cleanup
      window.removeEventListener('keydown', onHotKeys)
    }
  }, [onHotKeys])

  // watch isOpen: when changed to open, clear things out
  useEffect(() => {
    if (isOpen) {
      setSearchText('')
      setCostKeyData(null)
    }
  }, [isOpen])

  useEffect(() => {
    if (searchText.length < 3) {
      setCostKeyData(null)
      return
    }
    findCostKey(searchText, activeGroup?.HrrCode)
      .then((r: findCostKeyResult) => {
        if (r.Data && r.Data.length > 0) {
          setCostKeyData(r.Data)
          return
        }
        setCostKeyData(null)
      })
      .catch((e: any) => {
        console.warn('not found: ', e)
      })
  }, [searchText, activeGroup])

  if (!activeGroup) {
    return null
  }

  return (
    <Dialog
      fullWidth
      id="cost-key-quick-search"
      open={isOpen}
      onClose={onCloseDialog}>
      <DialogContent>
        <div
          className={classNames(
            'quick-search-container',
            costKeyData ? 'ck-found' : 'ck-invalid'
          )}>
          <TextField
            autoFocus
            fullWidth
            onChange={onChange}
            value={searchText}
            variant="standard"
            placeholder="Cost key search..."
            className="quick-search-input-wrap"
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  {costKeyData ? (
                    <CheckCircle className="status-indicator-icon" />
                  ) : (
                    <ErrorIcon className="status-indicator-icon" />
                  )}
                </InputAdornment>
              ),
            }}
          />
          {costKeyData && (
            <div className="quick-search-results">
              {costKeyData.map((r: typed.CostKeyResult) => {
                const natPrice = r.PricingInfo.NationalTypicalPPO
                  ? `$${r.PricingInfo.NationalTypicalPPO}`
                  : 'unavailable'
                const hrrPrice = r.PricingInfo.CurrentHRR
                  ? `$${r.PricingInfo.CurrentHRR}`
                  : 'unavailable'
                return (
                  <div
                    key={r.ID}
                    className="content-display"
                    onClick={onClickCostKey.bind(null, r)}>
                    <span className="cost-key-code">
                      <Chip label={r.Code} />
                    </span>
                    <small className="descr">{r.Descr}</small>
                    <div className="price-info">
                      <strong>Pricing</strong>
                      <div className="price-item">
                        <em>National Typical PPO</em>:{' '}
                        <Chip
                          color={
                            r.PricingInfo.NationalTypicalPPO
                              ? 'primary'
                              : 'secondary'
                          }
                          size="small"
                          label={natPrice}
                        />
                      </div>
                      {activeGroup.HrrCode ? (
                        <div className="price-item">
                          <em>
                            HRR ({activeGroup.HrrCity}, {activeGroup.HrrState})
                          </em>
                          :{' '}
                          <Chip
                            color={
                              r.PricingInfo.CurrentHRR ? 'primary' : 'secondary'
                            }
                            size="small"
                            label={hrrPrice}
                          />
                        </div>
                      ) : (
                        <div className="price-item">
                          <em>HRR</em>:{' '}
                          <Chip
                            color={'secondary'}
                            size="small"
                            label="Unavailable (group lacks HRR)"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
})

function findCostKey(
  search: string,
  hrrCode: number | undefined
): Promise<any> {
  let route = `/savings_review/cost_key_search?search=${search}`
  if (hrrCode) {
    route += `&hrr_code=${hrrCode}`
  }
  return api.get(route) as any
}
