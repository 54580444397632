import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { defaultStyleCreator } from '../../components/DetailView/DetailView'
import PartnerTable from './PartnerTable'
import queryString from 'query-string'

const styles: any = (theme: any) => {
  const defaults = defaultStyleCreator(theme)
  return Object.assign(defaults, {})
}

interface Props {
  history: any
  classes: any
}

interface FormattedParams {
  sortable?: object
  filter?: object
  page?: any
  pageSize?: any
}

interface QSParams {
  sortable?: string
  filter?: string
  page?: string
  pageSize?: string
}

const Partners = ({ classes, history }: Props) => {
  const [initParams, setInitParams] = useState<FormattedParams>({})
  const rawParams: QSParams = queryString.parse(window.location.search)

  useEffect(() => {
    if (rawParams.sortable) {
      initParams.sortable = queryString.parse(rawParams.sortable)
    }
    if (rawParams.filter) {
      initParams.filter = queryString.parse(rawParams.filter)
    }
    initParams.page = rawParams.page
    initParams.pageSize = rawParams.pageSize

    setInitParams(initParams)
  }, [])

  const persistSearch = (params: any) => {
    const stringified = queryString.stringify({
      ...params,
      sortable: queryString.stringify(params.sortable),
      filter: queryString.stringify(params.filter),
    })
    history.push(`?${stringified}`)
  }

  const handleAddNew = () => {
    history.push('/partner')
  }

  return (
    <div>
      <Paper className={classes.mainContent} elevation={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleAddNew}>
              Add New
            </Button>
          </Grid>
          <Grid item xs={12}>
            <PartnerTable
              // @ts-ignore
              persistSearch={persistSearch}
              initParams={initParams}
              autoFocus
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}

export default withStyles(styles)(Partners)
