import React from 'react'
import PropTypes from 'prop-types'
import QSearcher from '../../components/QSearcher'
import { withStyles } from '@material-ui/core/styles'
import {
  Collapse,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemAvatar,
} from '@material-ui/core'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank'
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import _ from 'lodash'

import { ApiActions } from '../../actions'
const { search } = ApiActions

const styles = (theme) => {
  return {
    selected: {
      backgroundColor: 'green',
    },
    current: {
      backgroundColor: 'green',
    },
    active: {},
    inactive: {
      background: 'rgba(255, 204, 0, .3)',
    },
    pendingRemoval: {
      backgroundColor: 'red',
    },
    manageWindow: {
      minHeight: 485,
      minWidth: 600,
    },
    item: {
      cursor: 'pointer',
    },
  }
}

class orgManager extends React.Component {
  state = {
    orgSearchResults: [],
    selectedOrgs: [],
    orgsPendingRemoval: [],
    currentListOpen: true,
  }

  doFindOrgs = ({ q, pageSize, page }) => {
    if (q.length === 0 || q.length > 2) {
      return search('/organization', { filter: { q }, page: 1, pageSize: 5 })
    }

    return new Promise((resolve, reject) => {
      resolve({ Data: [] })
    })
  }

  handleOrgsQuery = (q) => {
    const { searchParams } = this.state
    this.doFindOrgs(Object.assign({}, searchParams, { q }))
      .then((res) => {
        console.debug('Retrieving orgs: ', res)
        this.setState({ orgSearchResults: res.Data })
      })
      .catch((err) => console.error('failed fetching orgs', err))
  }

  toggleSelected = (ID, org) => {
    return () => {
      const { selectedOrgs, orgsPendingRemoval } = this.state

      const selected = _.find(selectedOrgs, { ID })
      if (!selected) {
        selectedOrgs.push(org)
      } else {
        // mutates the existing group
        _.remove(selectedOrgs, { ID })
      }
      this.setState({ selectedOrgs })
      this.props.onUpdate({ selectedOrgs, orgsPendingRemoval })
      console.debug(
        'Selecting a org: ',
        ID,
        org,
        ', currently selected: ',
        selectedOrgs
      )
    }
  }

  toggleCurrent = (ID, org) => {
    return () => {
      const { orgsPendingRemoval, selectedOrgs } = this.state

      const pendingRemoval = _.find(orgsPendingRemoval, { ID })

      if (!pendingRemoval) {
        orgsPendingRemoval.push(org)
      } else {
        _.remove(orgsPendingRemoval, { ID })
      }
      this.setState({ orgsPendingRemoval })
      this.props.onUpdate({ selectedOrgs, orgsPendingRemoval })
      console.debug(
        'Toggling a current org: ',
        ID,
        org,
        ', all pending removal: ',
        orgsPendingRemoval
      )
    }
  }

  toggleShowCurrent = () => {
    const { currentListOpen } = this.state
    this.setState({ currentListOpen: !currentListOpen })
  }

  renderCurrentListItem = (org, avatarClass) => {
    const { classes } = this.props

    return (
      <ListItemAvatar key={org.ID}>
        <Avatar className={avatarClass}>
          <RadioButtonChecked className={classes.selected} />
        </Avatar>
        <ListItemText
          primary={org.Name}
          secondary={
            'This Org is currently active. Manage using "Current Orgs"'
          }
        />
      </ListItemAvatar>
    )
  }

  renderResults = () => {
    const { orgSearchResults, selectedOrgs } = this.state
    const { classes, currentOrgs } = this.props

    return (
      <List>
        {orgSearchResults.map((org) => {
          const selected = _.find(selectedOrgs, { ID: org.ID }) !== undefined
          const current = _.find(currentOrgs, { ID: org.ID }) !== undefined

          const active = org.IsActive ? classes.active : classes.inactive
          const avatarClass = selected
            ? classes.selected
            : current
              ? classes.current
              : classes.notSelected

          if (current) {
            return this.renderCurrentListItem(org, avatarClass)
          }
          return (
            <ListItemAvatar
              key={org.ID}
              button
              onClick={this.toggleSelected(org.ID, org)}
              className={active}>
              <Avatar className={avatarClass}>
                {selected ? (
                  <CheckCircleOutline className={classes.selected} />
                ) : (
                  <CheckBoxOutlineBlank className={classes.notSelected} />
                )}
              </Avatar>
              <ListItemText primary={org.Name} secondary={org.ID} />
            </ListItemAvatar>
          )
        })}
      </List>
    )
  }

  renderSelected = () => {
    const { selectedOrgs, currentListOpen, orgsPendingRemoval } = this.state
    const { classes, currentOrgs } = this.props

    return (
      <div>
        <List>
          <ListItem>
            <ListItemText primary="Orgs to Add" />
          </ListItem>
          {selectedOrgs.map((org) => {
            const active = org.IsActive ? classes.active : classes.inactive

            return (
              <ListItemAvatar
                key={org.ID}
                button
                onClick={this.toggleSelected(org.ID, org)}
                className={active}>
                <Avatar className={classes.selected}>
                  <CheckCircleOutline className={classes.selected} />
                </Avatar>
                <ListItemText primary={org.Name} secondary={org.ID} />
              </ListItemAvatar>
            )
          })}
        </List>
        {currentOrgs && currentOrgs.length > 0 && (
          <List>
            <ListItem button onClick={this.toggleShowCurrent}>
              <ListItemText primary="Current Orgs" />
              {currentListOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={currentListOpen} timeout="auto" unmountOnExit>
              {currentOrgs.map((org) => {
                const pendingRemoval = _.find(orgsPendingRemoval, {
                  ID: org.ID,
                })
                const active = org.IsActive ? classes.active : classes.inactive

                const avatarClass = pendingRemoval
                  ? classes.pendingRemoval
                  : classes.current
                return (
                  <ListItemAvatar
                    key={org.ID}
                    button
                    onClick={this.toggleCurrent(org.ID, org)}
                    className={active}>
                    <Avatar className={avatarClass}>
                      {pendingRemoval ? (
                        <CheckBoxOutlineBlank
                          className={classes.pendingRemoval}
                        />
                      ) : (
                        <CheckCircleOutline className={classes.current} />
                      )}
                    </Avatar>
                    <ListItemText primary={org.Name} secondary={org.ID} />
                  </ListItemAvatar>
                )
              })}
            </Collapse>
          </List>
        )}
      </div>
    )
  }

  render() {
    const { classes } = this.props
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <QSearcher
            search={this.handleOrgsQuery}
            className={classes.searchField}
          />
          <div>{this.renderResults()}</div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>{this.renderSelected()}</div>
        </Grid>
      </Grid>
    )
  }
}

orgManager.propTypes = {
  classes: PropTypes.object.isRequired,
}

export const OrgManager = withStyles(styles)(orgManager)

class orgManagerDialog extends React.Component {
  state = {
    added: [],
    removed: [],
  }

  componentWillUnmount() {
    this.setState({
      added: [],
      remove: [],
    })
  }

  handleCancel = () => {
    this.setState({ added: [], removed: [] })
    this.props.onCancel()
  }

  handleConfirm = () => {
    const { added, removed } = this.state
    this.setState({ added: [], removed: [] })
    this.props.onConfirm({ added, removed })
  }

  trackChanges = ({ selectedOrgs, orgsPendingRemoval }) => {
    const added = selectedOrgs
    const removed = orgsPendingRemoval
    console.debug(
      'Generating diff of orgs under management: ',
      'Added: ',
      added,
      ' Removed: ',
      removed
    )

    this.setState({ added, removed })
  }

  hasChanges = () => {
    const { added, removed } = this.state

    return !(added.length === 0 && removed.length === 0)
  }

  renderActionText = () => {
    const { added, removed } = this.state
    let addedText = ''
    let removedText = ''
    let separator = ''

    if (added.length !== 0) {
      addedText = `Add ${added.length} org${added.length > 1 ? 's' : ''}`
    }
    if (removed.length !== 0) {
      removedText = `Remove ${removed.length} org${removed.length > 1 ? 's' : ''}`
    }
    if (added.length !== 0 && removed.length !== 0) {
      separator = ', '
    }

    return (
      <span>
        {addedText}
        {separator}
        {removedText}
      </span>
    )
  }

  render = () => {
    // open/closed state is managed via the handleCancel/handleConfirm callbacks on the parent component
    const { classes, open, currentOrgs } = this.props
    const hasChanges = this.hasChanges()

    return (
      <div>
        <Dialog
          className={classes.dialog}
          open={open}
          onClose={this.handleCancel}>
          <DialogTitle>Manage Orgs</DialogTitle>
          <DialogContent className={classes.manageWindow}>
            <DialogContentText>
              Please use this lookup table to manage the organizations. No
              changes will be made until you click the Add/Remove button at the
              bottom.
            </DialogContentText>
            <br />
            <OrgManager
              currentOrgs={currentOrgs}
              onUpdate={this.trackChanges}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.handleConfirm}
              disabled={!hasChanges}
              color="primary">
              {hasChanges ? this.renderActionText() : <span>No Changes</span>}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

orgManagerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  currentOrgs: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

orgManagerDialog.defaultProps = {
  currentOrgs: [],
}

export const OrgManagerDialog = withStyles(styles)(orgManagerDialog)
