import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import * as ComponentFeeScheduleTable from '../../FeeSchedules/ComponentFeeScheduleTable'
import { ComponentFeeScheduleActions } from '../../../actions'
import useSnackbar, {
  SnackbarTypeSuccess,
  SnackbarTypeError,
} from '../../../hooks/useSnackbar'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import {
  STATUS_DRAFT as FEE_SCHEDULE_STATUS_DRAFT,
  STATUS_PRODUCTION as FEE_SCHEDULE_STATUS_PRODUCTION,
} from '../../../models/FeeSchedule'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Button,
  Typography,
  Chip,
} from '@material-ui/core'
import dateTime from '../../../utils/dateTime'

interface props {
  organizationID: number | null
}

export default function OrgDetailComponentFeeSchedules({
  organizationID,
}: props): React.ReactElement | null {
  const history = useHistory()
  const [selected, setSelected] = useState<any[]>([])
  const [onlyDraftsSelected, setOnlyDraftsSelected] = useState(false)
  const [onlyProdsSelected, setOnlyProdsSelected] = useState(false)
  const refTable = useRef()

  useEffect(() => {
    if (!(selected || []).length) {
      setOnlyDraftsSelected(false)
      setOnlyProdsSelected(false)
      return
    }
    let drafts = 0,
      prods = 0
    selected.forEach((v: any) => {
      switch (v.Status) {
        case FEE_SCHEDULE_STATUS_DRAFT:
          drafts++
          break
        case FEE_SCHEDULE_STATUS_PRODUCTION:
          prods++
          break
        default:
      }
    })
    setOnlyDraftsSelected(drafts >= 1 && prods === 0)
    setOnlyProdsSelected(prods >= 1 && drafts === 0)
  }, [selected, setOnlyDraftsSelected, setOnlyProdsSelected])

  function refreshTableView() {
    // @ts-ignore
    refTable.current?.refresh()
  }

  if (!organizationID) {
    return null
  }

  return (
    <>
      <ComponentFeeScheduleTable.Table
        ref={refTable}
        passFilters={{ organizationID }}
        onCheckHandler={(vv: any[]) => {
          setSelected(vv || [])
        }}
        LeftHeaderItems={
          <>
            <ComponentFeeScheduleTable.FilterSearch autoFocus />
            &nbsp;&nbsp;
            <ComponentFeeScheduleTable.FilterStatus />
            &nbsp;&nbsp;
            <Chip label={`OrgID: ${organizationID}`} />
          </>
        }
        RightHeaderItems={
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                history.push({
                  pathname: '/component_fee_schedule',
                  state: { org: organizationID },
                })
              }}>
              Add New
            </Button>
          </>
        }
        onRowClick={(row: any, cmdClicked: boolean) => {
          const toPath = `/component_fee_schedule/${row.ID}`
          if (cmdClicked) {
            window.open(toPath, '_blank')
            return
          }
          history.push({
            pathname: toPath,
            state: {
              originPathname: history.location.pathname,
              prevSearch: history.location?.search || null,
            },
          })
        }}
      />

      <Grid
        container
        spacing={2}
        justify="space-between"
        alignItems="center"
        style={{ marginTop: '0.75rem' }}>
        <Grid item xs={12} md="auto">
          {/* 
          @todo: this was a nice-to-have for engineering only prior to refactor done in SD-1234, but it was only
          partially working due to how we were collecting IDs in paginated results. To properly implement this, we'd
          need a new route which collects all the component fee schedule IDs itself, instead of passing them from the
          front-end. So TLDR; if we want a "terminate all active component fee schedules", it needs more work.
          { (!checkedItems.length && canExecEngineering()) && <Button onClick={this.handleRequestTerminateAllFeeSchedules} variant='contained' color='secondary'>Terminate all active Component Fee Schedules</Button> }
        */}

          {onlyDraftsSelected && (
            <BtnManageDeleteDrafts
              selected={selected}
              onDraftsDeleted={refreshTableView}
            />
          )}

          {onlyProdsSelected && (
            <BtnManageTerminations
              selected={selected}
              onComplete={refreshTableView}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}

/*
Dialog to delete draft component fee schedules
*/
function BtnManageDeleteDrafts({
  selected,
  onDraftsDeleted,
}: any): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const { showForDuration: showSnackbarDuration } = useSnackbar()

  function onConfirm() {
    const IDs = selected.map((v: any) => v.ID)
    ComponentFeeScheduleActions.bulkDeleteComponentFeeSchedules({ IDs })
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        showSnackbarDuration(
          'Draft component fee schedules deleted OK',
          SnackbarTypeSuccess
        )
        onDraftsDeleted && onDraftsDeleted()
      })
      .catch((err: any) => {
        if (err.Error?.Message) {
          showSnackbarDuration(err.Error.Message, SnackbarTypeError)
          return
        }
        showSnackbarDuration(
          'Failed deleting draft component fee schedules',
          SnackbarTypeError
        )
      })
      .finally(() => {
        setIsOpen(false)
      })
  }

  return (
    <>
      <Button
        onClick={() => {
          setIsOpen(true)
        }}
        variant="contained"
        color="primary">
        Delete Selected Component Fee Schedules
      </Button>

      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}>
        <DialogTitle>Confirm Removal</DialogTitle>
        <DialogContent>
          Do you really want to delete the selected Fee Schedule(s)? This cannot
          be undone.
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  setIsOpen(false)
                }}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button color="primary" variant="contained" onClick={onConfirm}>
                Confirm
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

/*
Dialog for terminating active component fee schedules
*/
function BtnManageTerminations({
  selected,
  onComplete,
}: any): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [terminateInputs, setTerminateInputs] = useState<any>({})
  const { showForDuration: showSnackbarDuration } = useSnackbar()

  // reset modal state whenever it (re)opens
  useEffect(() => {
    if (isOpen) {
      setTerminateInputs({
        fsDateEnd: null,
        termNotes: null,
      })
    }
  }, [isOpen])

  function onConfirm() {
    const payload = {
      IDs: selected.map((v: any) => v.ID),
      DateEnd: dateTime
        .parse(terminateInputs.fsDateEnd)
        .format(dateTime.formats.ISODate),
      Notes: terminateInputs.termNotes,
    }
    ComponentFeeScheduleActions.bulkTerminateComponentFeeSchedules(payload)
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        showSnackbarDuration(
          'Component fee schedules terminated OK',
          SnackbarTypeSuccess
        )
        onComplete && onComplete()
      })
      .catch((err: any) => {
        if (err.Error?.Message) {
          showSnackbarDuration(err.Error.Message, SnackbarTypeError)
          return
        }
        showSnackbarDuration(
          'Failed terminating component fee schedules',
          SnackbarTypeError
        )
      })
      .finally(() => {
        setIsOpen(false)
      })
  }

  function isValid(): boolean {
    if (!terminateInputs.termNotes) {
      return false
    }
    return dateTime.parse(terminateInputs.fsDateEnd).isValid()
  }

  return (
    <>
      <Button
        onClick={() => {
          setIsOpen(true)
        }}
        variant="contained"
        color="secondary">
        Terminate Selected Component Fee Schedules
      </Button>

      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}>
        <DialogTitle>
          Terminate Selected Production Component Fee Schedules
        </DialogTitle>
        <DialogContent>
          <Typography>
            Please select a date to deactivate and provide some context
          </Typography>
          <hr style={{ marginBottom: 0 }} />
          {/* <DateInput
          label='Deactivation Date'
          onChange={(ev : any) => {
            const v = ev.target?.value
            setTerminateInputs((curr:any) => ({...curr, fsDateEnd: v}))
          }}
          value={terminateInputs.fsDateEnd}
          // margin='none'
          shrink={true}
        /> */}
          <ManagedDateInput
            name="fsDateEnd"
            label="Deactivation Date"
            value={terminateInputs.fsDateEnd}
            setter={({ name, value }) => {
              setTerminateInputs((curr: any) => ({ ...curr, fsDateEnd: value }))
            }}
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Notes"
            placeholder="Enter notes here"
            value={terminateInputs.termNotes || ''}
            InputLabelProps={{ shrink: true }}
            onChange={(ev: any) => {
              const v = ev.target?.value
              setTerminateInputs((curr: any) => ({
                ...curr,
                termNotes: v || null,
              }))
            }}
          />
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  setIsOpen(false)
                }}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                color="primary"
                variant="contained"
                disabled={!isValid()}
                onClick={onConfirm}>
                Confirm
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}
