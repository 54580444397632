import React, { useEffect, useState } from 'react'
import { LogoPicker } from '../../components/LogoPicker/LogoPicker'
import {
  getOrganizationLogos,
  putActiveLogo,
  postLogoFile,
} from '../../actions/OrganizationActions'
import { getFileTypes } from '../../actions/FileActions'
import * as FileUpload from '../../components/FileUpload'

interface OrganizationLogoPickerProps {
  organizationID: number
}

export const OrganizationLogoPicker = ({
  organizationID,
}: OrganizationLogoPickerProps) => {
  const [fileData, setFileData] = useState<FileUpload.fileFields>()

  useEffect(() => {
    const payload = { filter: { Hashes: ['logo'] } }
    getFileTypes(payload).then((res: any) => {
      const newFileData: FileUpload.fileFields = {
        OrganizationID: organizationID,
        FileTypeID: res[0].ID,
      }
      setFileData(newFileData)
    })
  }, [])

  if (!fileData) return <></>
  return (
    <LogoPicker
      id={organizationID}
      getLogos={getOrganizationLogos}
      putActiveLogo={putActiveLogo}
      postLogoFile={postLogoFile}
      LogoFileProps={fileData}
    />
  )
}
