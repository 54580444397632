import React, { useRef, useState } from 'react'
import { IconButton } from '@material-ui/core'
import { ShapeFundingRecord } from '../types'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import ButtonDoProcess from '../Components/ButtonDoProcess'
import ButtonDoSendBackToAdjudication from '../Components/ButtonDoSendBackToAdjudication'
import MiniReports from '../Components/MiniReports'
import * as FundingTable from '../Components/FundingTable'

export default function PageInProcess(): React.ReactElement {
  const [checkedItems, setCheckedItems] = useState<Array<ShapeFundingRecord>>(
    []
  )
  const refInProcessTable = useRef<any>(null)

  const pageColumnLeft = (
    <div className="lefty">
      <div style={{ paddingBottom: '1rem', textAlign: 'right' }}>
        <IconButton
          size="small"
          onClick={() => {
            refInProcessTable.current?.refresh()
          }}>
          <DesignSuite2023.CommonIcons.IconRefresh />
        </IconButton>
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <ButtonDoProcess
          disabled={(checkedItems || []).length === 0}
          zClaimIDs={checkedItems.map((v: ShapeFundingRecord) => v.ZClaimID)}
          onComplete={() => {
            refInProcessTable.current?.refresh()
          }}
        />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <ButtonDoSendBackToAdjudication
          disabled={(checkedItems || []).length === 0}
          zClaimIDs={checkedItems.map((v: ShapeFundingRecord) => v.ZClaimID)}
          onComplete={() => {
            refInProcessTable.current?.refresh()
          }}
        />
      </div>

      <DesignSuite2023.Divider />

      <div style={{ paddingBottom: '1rem' }}>
        <FundingTable.StandardFilterSearch
          autoFocus
          label="Search"
          InputLabelProps={{ shrink: true }}
        />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <FundingTable.FilterEmployer />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <FundingTable.FilterOrganization />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <FundingTable.FilterCollections />
      </div>

      <MiniReports.FundingFlowStats />
    </div>
  )

  return (
    <FundingTable.Table
      ref={refInProcessTable}
      passFilters={{ FundingStatuses: ['in_process'] }}
      dataTableProps={{
        keyProp: 'ZClaimID',
      }}
      className="full-fixed"
      enableURLReflection="zclaims.inprocess.list"
      onCheckHandler={setCheckedItems}
      DataTableProps={{ stickyHeader: true }}
      BeforeTable={pageColumnLeft}
    />
  )
}
