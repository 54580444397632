import React from 'react'
import { EngineeringActions } from '../../actions'
import { withStyles } from '@material-ui/core/styles'
import DetailView, {
  defaultStyleCreator,
} from '../../components/DetailView/DetailView'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import CsvParser from '../../components/CsvParser/CsvParser'
import { Button, Grid } from '@material-ui/core'

const styles = (theme) => {
  const defaults = defaultStyleCreator(theme)
  return Object.assign({}, defaults)
}

const userStructFields = {
  AccessGroupID: null,
  Email: null,
  FirstName: null,
  LastName: null,
  ExtUsername: null,
}

export class EngineeringDetail extends DetailView {
  constructor(props) {
    super(props)
    this.state = this.initialState = {
      step: null,
      processedData: {},
      permissionGroups: [],
      responseErr: null,
    }
  }

  UNSAFE_componentWillMount() {
    const self = this
    EngineeringActions.getPermissionGroups()
      .then((res) => {
        console.log('user groups loaded')
        self.setState({
          permissionGroups: res.Data,
        })
      })
      .catch((e) => {
        console.warn(e)
      })
  }

  restart = () => {
    this.setState({ step: null })
  }

  handleParsed = (processedData) => {
    // when we received the data, add the userGroup property so it always has a
    // default (of null initially)
    processedData.userGroup = null
    this.setState({
      step: 'presend',
      processedData,
    })
  }

  ship = () => {
    const self = this
    EngineeringActions.sendBulkUserImport(this.state.processedData)
      .then((res) => {
        if (res.Error) {
          self.setState({
            responseErr: res.Error,
          })
          return
        }
        self.setState({
          responseErr: null,
        })
        alert('Users created!')
      })
      .catch((e) => {
        console.error('ERRORED: ', e)
        alert(e.message)
      })
  }

  render() {
    const { processedData } = this.state

    switch (this.state.step) {
      case 'presend':
        return (
          <div>
            {this.renderResponseErr()}
            <div>
              <h5>Select a permission group to add users to</h5>
              {this.renderPermissionGroups()}
              <h5>Inspect the payload before you send it:</h5>
              <pre>{JSON.stringify(processedData, null, 4)}</pre>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Button
                  onClick={this.restart}
                  color="secondary"
                  variant="contained">
                  Restart CSV Ingest
                </Button>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <Button onClick={this.ship} color="primary" variant="contained">
                  Send
                </Button>
              </Grid>
            </Grid>
          </div>
        )
      default:
        return (
          <CsvParser
            objectTemplate={userStructFields}
            onComplete={this.handleParsed}
            initialData={processedData}
          />
        )
    }
  }

  renderResponseErr = () => {
    if (!this.state.responseErr) {
      return
    }
    return (
      <div>
        <p>Message: {this.state.responseErr.DetailedMessage}</p>
        <pre style={{ fontSize: '11px' }}>
          {JSON.stringify(this.state.responseErr, null, 4)}
        </pre>
      </div>
    )
  }

  renderPermissionGroups = () => {
    const { permissionGroups } = this.state
    const empty = (
      <option key="_" value="_">
        Select Permission Group
      </option>
    )
    const opts = [empty].concat(
      permissionGroups.map((v) => {
        return (
          <option key={v.id} value={v.id}>
            {v.name}
          </option>
        )
      })
    )
    const _onSetGroup = (e) => {
      let v = e.target.value
      if (v === '_') {
        v = null
      }
      const processed = this.state.processedData
      processed.userGroup = v
      this.setState({
        processedData: processed,
      })
    }
    return <select onChange={_onSetGroup}>{opts}</select>
  }
}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch)
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(EngineeringDetail)
