import React, { useEffect, useState } from 'react'
import { useConfig } from '../../providers/Config'
import { getAddressByID } from '../../actions/AddressActions'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import DesignSuite2023 from '../DesignSuite2023'
import styled from 'styled-components'

const StyledAddress = styled.span`
  display: flex;
  align-items: center;

  .error {
    color: #999;
  }
`

export type ShapeAddress = {
  ID: number
  Address1: string | null
  Address2: string | null
  City: string | null
  State: string | null
  Zip: string | null
  DisplayAddress?: string
  Geocoded?: boolean
  Lat?: number
  Long?: number
}

interface DisplayProps {
  id?: number | null
  addressObj?: ShapeAddress | null
  asLink?: boolean
  mapPinIcon?: boolean
}

export default function DisplayAddress({
  id,
  addressObj: propAddressObj = null,
  asLink = true,
  mapPinIcon = true,
  ...otherProps
}: DisplayProps & Partial<any>): React.ReactElement {
  const [addressObj, setAddressObj] = useState<ShapeAddress | null>(
    propAddressObj
  )
  const { mapsUrl } = useConfig()
  const { catchAPIError } = useErrorHandlers()
  const [errMsg, setErrMsg] = useState<string | null>(null)

  useEffect(() => {
    if (addressObj) return
    if (!id) return
    loadAddressByID(id)
  }, [id, addressObj])

  function loadAddressByID(id: number): void {
    getAddressByID(id)
      .then((res: any) => {
        setAddressObj(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: `Failed loading address ID:${id}`,
          withError(err: any) {
            setErrMsg(err?.Error?.Message || `Failed loading address ID:${id}`)
          },
        })
      )
  }

  function makeMapURL(addr: string) {
    return `${mapsUrl}&q=${encodeURIComponent(addr)}`
  }

  // If insufficient info to render component...
  if (!addressObj) {
    return (
      <StyledAddress>
        <small className="error">{errMsg || 'Address info not found'}</small>
      </StyledAddress>
    )
  }

  const isGeocoded = !!addressObj.Lat && addressObj.Long

  let addrDisplay = <span>(No address available)</span>
  const useDispAddr = addressObj.DisplayAddress || ''
  if (useDispAddr.length >= 1) {
    addrDisplay = <span>{useDispAddr}</span>
    if (asLink) {
      addrDisplay = (
        <a
          href={makeMapURL(useDispAddr)}
          target="_blank"
          rel="noopener noreferrer">
          {addressObj.DisplayAddress}
        </a>
      )
    }
  }

  return (
    <StyledAddress {...otherProps}>
      {mapPinIcon && (
        <>
          <DesignSuite2023.CommonIcons.IconMapPin
            color="inherit"
            fontSize="inherit"
          />
          &nbsp;
        </>
      )}
      {addrDisplay}
      {isGeocoded && (
        <>
          &nbsp;
          <DesignSuite2023.Tooltip title="Address has been geocoded OK">
            <DesignSuite2023.CommonIcons.IconCheck
              color="primary"
              fontSize="inherit"
            />
          </DesignSuite2023.Tooltip>
        </>
      )}
    </StyledAddress>
  )
}
