import React, { useEffect, useMemo, useState } from 'react'
import { Card, Container, Typography } from '@material-ui/core'
import styled from 'styled-components'
import ChipInformationDisplay from './ChipInformationDisplay'
import { renderTextField as RenderTextField } from '../../../components/Inputs/standard'
import DataTable from '../../../components/DataTable/DataTable'
import dateTime from '../../../utils/dateTime'
import VoidPay from './VoidPayment'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { getPayment } from '../../../actions/ZClaimFundingActions'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
export interface paidDetailRecordDetail {
  LineNumber: number
  ZClaimID: number
  ZClaimAmount: string
  IsReversal: boolean
  FundingInfos: string[]
}

export interface paidDetailRecord {
  PaymentID: string
  PaymentType: string
  Date: string | null
  OrganizationID: number
  PartnerID: number
  PayeeName: string
  PayeeType: string
  CheckingAccount: string
  ManualPayType: string
  CheckNumber: number
  ACHInfo: string
  Notes: string
  IsVoid: boolean
  VoidPaymentNotes: string
  ClaimCount: number
  Amount: string

  Details: paidDetailRecordDetail[]
}

interface paidDetailProps {
  paymentID: string
  doClose?(): void
}

const PaymentDetailDiv = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`

const claimColumns = {
  LineNumber: {
    name: 'Line Number',
  },
  ZClaimID: {
    name: 'ZClaimID',
    details: {
      dataFormat(_: any, row: paidDetailRecordDetail): JSX.Element {
        return (
          <span style={{ width: '200px' }}>
            {row.ZClaimID}{' '}
            {row.IsReversal && <span style={{ color: 'red' }}>[Reversal]</span>}
          </span>
        )
      },
    },
  },
  FundingInfos: {
    name: 'Funding Information',
    details: {},
  },
  ZClaimAmount: {
    name: 'Amount',
    details: {
      dataFormat(_: any, row: paidDetailRecordDetail): JSX.Element {
        return (
          <span style={{ color: row.IsReversal ? 'red' : 'inherit' }}>
            {formatAmount(row.ZClaimAmount.toString())}
          </span>
        )
      },
    },
  },
}

export default function PaidDetail({
  paymentID,
  doClose,
}: paidDetailProps): React.ReactElement {
  onclose = doClose ? doClose : null

  const { catchAPIError } = useErrorHandlers()
  const [data, setData] = useState<paidDetailRecord>({
    PaymentID: '',
    PaymentType: '',
    Date: null,
    OrganizationID: 0,
    PartnerID: 0,
    PayeeName: '',
    PayeeType: '',
    CheckingAccount: '',
    ManualPayType: '',
    CheckNumber: 0,
    ACHInfo: '',
    Notes: '',
    IsVoid: false,
    VoidPaymentNotes: '',
    ClaimCount: 0,
    Amount: '',
    Details: [],
  })

  const sorted = useMemo(() => {
    return data.Details.sort((a, b) => a.LineNumber - b.LineNumber)
  }, [data.Details])

  const [filteredRows, setFilteredRows] = useState<paidDetailRecordDetail[]>([])
  const [search, setSearch] = useState('')
  const [paginatedRows, setPaginatedRows] = useState<paidDetailRecordDetail[]>(
    []
  )
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState(10)

  useEffect(() => {
    getData()
  }, [paymentID])

  function getData() {
    return getPayment(paymentID)
      .then((res: any) => {
        setData(res.Data)
        setPaginatedRows(res.Data.Details)
        setFilteredRows(res.Data.Details)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to get payment data',
        })
      )
  }

  useEffect(() => {
    paginateFilteredRows()
  }, [page, pageSize, filteredRows])

  useEffect(() => {
    if (!search) {
      setFilteredRows(sorted)
      return
    }
    setFilteredRows(
      sorted.filter((item) =>
        item.ZClaimID.toString().includes(search.toLowerCase())
      )
    )
  }, [search])

  function paginateFilteredRows() {
    setPaginatedRows(
      filteredRows.slice(page * pageSize - pageSize, page * pageSize)
    )
  }
  function handlePageChange(value: any) {
    setPage(value.page)
  }

  function handlePageSizeChange(value: any) {
    setPageSize(value.pageSize)
  }

  return (
    <Container>
      <Typography component="div" variant="h5" style={{ marginTop: '16px' }}>
        {data.PayeeName}
      </Typography>
      <PaymentDetailDiv>
        <ChipInformationDisplay label="Payment ID" value={data.PaymentID} />
        <ChipInformationDisplay
          label="Date"
          value={dateTime.parse(data.Date).format()}
        />
        <ChipInformationDisplay
          label="Amount"
          value={formatAmount(data.Amount)}
        />
        <ChipInformationDisplay
          label="Checking Account"
          value={data.CheckingAccount}
        />
      </PaymentDetailDiv>
      <PaymentDetailDiv>
        <ChipInformationDisplay label="Payment Type" value={data.PaymentType} />
        {data.ManualPayType && (
          <ChipInformationDisplay
            label="Manual Payment Type"
            value={data.ManualPayType}
          />
        )}
        {data.ACHInfo && (
          <ChipInformationDisplay label="ACH Info" value={data.ACHInfo} />
        )}
        {data.CheckNumber && (
          <ChipInformationDisplay
            label="Check Number"
            value={data.CheckNumber}
          />
        )}
        {data.Notes && (
          <ChipInformationDisplay label="Notes" value={data.Notes} />
        )}
      </PaymentDetailDiv>

      <Card variant="outlined">
        <DesignSuite2023.GridLR
          left={
            <div style={{ paddingLeft: '16px' }}>
              <RenderTextField
                name="ZClaimID"
                label="ZClaimID"
                value={search}
                setter={({ value }) => setSearch(value)}
                opts={{ style: { width: '150px' } }}
                use2023Styles
              />
            </div>
          }
          right={
            <div style={{ paddingRight: '16px' }}>
              {data.IsVoid ? (
                <>
                  <Typography variant="h4" style={{ color: 'red' }}>
                    This payment has been voided
                  </Typography>
                  {data.VoidPaymentNotes && (
                    <Typography variant="h5">
                      {data.VoidPaymentNotes}
                    </Typography>
                  )}
                </>
              ) : (
                <VoidPay
                  data={data}
                  disabled={false}
                  hidden={data.Amount === '0'}
                  onClose={getData}
                />
              )}
            </div>
          }
        />
        <DataTable
          columns={claimColumns}
          data={paginatedRows}
          initPage={1}
          initPageSize={pageSize}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={(v: any) => handlePageSizeChange(v)}
          count={filteredRows.length}
        />
      </Card>
    </Container>
  )
}

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
