import * as api from '../../services/thezerocard/api-helper'
import React, { useState } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import DesignSuite2023 from '../../components/DesignSuite2023'
import ConfirmDialog from '../../components/ConfirmDialog'

interface resp {
  error?: boolean
  // if response is OK
  Data?: any
}

export default () => {
  const [loading, setLoading] = useState<boolean>(false)
  const snackbar = useSnackbar()

  const onClick = () => {
    ConfirmDialog({
      content:
        'This will queue sending out of savings analyses ASAP. Confirm you want to proceed...',
      onConfirm() {
        setLoading(true)
        postNotifySavingsAnalysis()
          .then((v: resp) => {
            if (v.error) {
              throw v
            }
            setLoading(false)
            snackbar.show(
              'Savings analysis notifications issued OK',
              SnackbarTypeSuccess
            )
          })
          .catch((e: resp | Error) => {
            setLoading(false)
            snackbar.show(
              'Issuing savings analysis notifications failed 😩',
              SnackbarTypeError
            )
          })
      },
    })
  }

  return (
    <DesignSuite2023.Tooltip
      placement="right"
      title="Trigger sending savings analysis notifications (dialog)">
      <Button
        variant="contained"
        color="primary"
        onClick={onClick}
        disabled={loading}>
        {loading && (
          <CircularProgress size={20} style={{ marginRight: '0.5rem' }} />
        )}{' '}
        Send Savings Analysis Notifications
      </Button>
    </DesignSuite2023.Tooltip>
  )
}

function postNotifySavingsAnalysis(): Promise<resp> {
  return api.post('/engineering/claims/notify_savings_analysis') as any
}
