/*
Note: if you search this page, notice there is mention at all of LOAs;
this should eventually replace the views/Files/FilesTable - but didn't want
to get into that now. That component had been created prior to the
useStandardTableSetup hook existing, and its way more verbose than it needs
to be. Further - not every 'thing' (eg. Employer/Organization etc) needs to
have their own 'Files' table component - they should just rely on the Files/FilesTable
and it should be wired up such that dependencies can be passed in as necessary (eg.
as this component is designed).
*/
import React, { useState, useContext, forwardRef } from 'react'
import models from '../../models'
import { getFiles } from '../../actions/FileActions'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import FileEditModal, { FileEditModalProps } from '../Files/FileEditModal'
import { File, FileTypeData } from '../Files/types'
import FileTypeFilter, { FileTypeEntities } from '../Files/FileTypeFilter'

// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../hooks/useStandardTableSetup'
export type { File, FileTypeData, FileEditModalProps }
export { FileTypeEntities }

interface props extends stdTableSetup.props {
  FileEditModalProps?: FileEditModalProps
}

export const Table = forwardRef(function FilesTableV2(
  {
    customColumns = models.File.columns,
    apiEndpoint = getFiles,
    onRowClick,
    FileEditModalProps,
    ...passThrough
  }: props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay, refresh } = stdTableSetup.useStandardTableSetup(
    {
      customColumns,
      apiEndpoint,
      onRowClick: handleOnRowClick,
      ...passThrough,
    },
    ref
  )
  const [editFileID, setEditFileID] = useState<number | null>(null)

  /*
    Passing an onRowClick handler prop disables the default action, which
    is to open the Edit modal
  */
  function handleOnRowClick(row: File, cmdClicked: boolean): void {
    if (!!onRowClick) {
      onRowClick(row, cmdClicked)
      return
    }
    setEditFileID(row.ID)
  }

  return (
    <>
      {TableDisplay}

      <FileEditModal
        {...(FileEditModalProps || {})}
        recordID={editFileID}
        handleClose={() => {
          setEditFileID(null)
        }}
        onSuccess={refresh}
        // @todo: entityType?
      />
    </>
  )
})

export function FilterFileType({
  entityTypes,
}: {
  entityTypes?: readonly FileTypeEntities[]
}): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  function onChange(ft: FileTypeData | null) {
    setFilterImmediate({ fileTypeID: ft?.ID || null })
  }

  return (
    <FileTypeFilter
      entityTypes={entityTypes}
      onChange={onChange}
      defaultValue={filter.fileTypeID || null}
    />
  )
}
