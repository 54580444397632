import React from 'react'
import utils from '../utils'
import { Link } from 'react-router-dom'

const costKeyCode = {
  columns: {
    KeyColumn: 'ID',
    Code: { name: 'Cost Key', details: { sortName: 'code' } },
    ID: { name: 'ID', details: {} },
    Category: { name: 'Category', details: { sortName: 'category' } },
    Deprecated: {
      name: 'Deprecated',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
    Descr: { name: 'Description', details: {} },
  },
  editFields: {
    Code: '',
    Descr: '',
    LaymanDescr: '',
    Category: '',
    CostKeyGroupId: 0,
    CostKeyKeywords: [],
    ConfigurableNetworks: [],
    BundleContents: '',
    IsBase: false,
    RequireUnits: false,
    RequireMedicalReview: false,
    GroupByServiceDate: true,
    Deprecated: false,
    TypicalPPOOverride: false,
    ManualTypicalPPOPrice: '',
    PPOOverrideNotes: '',
    AlwaysOverrideTypicalPPO: false,
    DRGCode: '',
    TagSetID: null,
    IsMissingPathology: false,
    IsMissingImplant: false,
    IsMissingXrays: false,
    AliasID: null,
    AllowTravel: false,
    LegacyGroupDescr: '',
  },
  prices: {
    FeeScheduleName: {
      name: 'Fee Schedule Name',
      details: { dataFormat: linkifier },
    },
    Price: { name: 'Price', details: { sortName: 'Price' } },
    FeeScheduleDateStart: { name: 'Fee Schedule Start' },
    FeeScheduleDateEnd: { name: 'Fee Schedule End' },
    DateStart: { name: 'Price Start' },
    DateEnd: { name: 'Price End' },
    IsValidNow: {
      name: 'Valid Now',
      details: { width: '170px', dataFormat: priceIsActiveFormatter },
    },
    Notes: 'Notes',
  },
}

function linkifier(cell, row) {
  const PRICES_TAB = 1
  const href = `/fee_schedule/${row.FeeScheduleID}?active_tab=${PRICES_TAB}`
  return <Link to={href}>{cell}</Link>
}

function priceIsActiveFormatter(cell, row) {
  return row.IsValidNow ? 'Yes' : 'No'
}

export default costKeyCode
