import React from 'react'
import { Button } from '@material-ui/core'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import { unsetPracticeFacilityTermDate } from '../../actions/PracticeFacilityActions'

interface props {
  practiceFacilityID: number
  onComplete(): void
}

export const UnterminateButton = ({
  practiceFacilityID,
  onComplete,
}: props) => {
  const { catchAPIError } = useErrorHandlers()

  function onClick() {
    if (
      window.confirm(
        "This action will unset the facility's termination date to empty. NOTE: clearing the termination date here will NOT remove any termination dates that were previously set on fee schedules."
      )
    ) {
      console.log('mkasdf')
      unsetPracticeFacilityTermDate(practiceFacilityID)
        .then(() => {
          onComplete && onComplete()
        })
        .catch(
          catchAPIError({
            defaultMessage: 'Failed clearing termination date',
          })
        )
    }
  }

  return (
    <>
      <Button color="primary" variant="outlined" onClick={onClick}>
        Clear Termination Date
      </Button>
    </>
  )
}
