import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as api from '../../services/thezerocard/api-helper'

import { CircularProgress } from '@material-ui/core'

// can optimize these to pull from application state first before fetching
export default class OrgNameResolver extends Component {
  state = {
    loading: false,
    name: '',
  }

  componentDidMount() {
    api.get(`/organization/${this.props.orgId}`).then((res) => {
      if (res.Data && res.Data.Name) {
        this.setState({ loading: false, name: res.Data.Name })
        return
      }
      this.setState({ loading: false, error: true })
    })
  }

  generateOrgLink = () => {
    const { orgId } = this.props
    return `${window.location.origin}/organization/${orgId}`
  }

  render() {
    const { loading, name } = this.state
    return (
      <div>
        {loading ? (
          <CircularProgress />
        ) : (
          <a href={this.generateOrgLink()} name="view-link" title={name}>
            {name}
          </a>
        )}
      </div>
    )
  }
}

OrgNameResolver.propTypes = {
  orgId: PropTypes.number.isRequired,
}
