import React, { useEffect, useState } from 'react'
import { findEmployerPlans } from '../../../actions/EmployerActions'
import {
  RenderSelectField2,
  SelectItem,
  SetterArgs,
} from '../../../components/Inputs/standard'
import { ShapeEmployerPlan } from '../types'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import dateTime from '../../../utils/dateTime'
import styled from 'styled-components'

const StyledItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  small {
    color: #aaa;
    margin-left: 1rem;
  }
`

type item = SelectItem & ShapeEmployerPlan

interface props {
  employerID?: number
  selectedID?: number | null
  onChange?: (id: number | null, full?: ShapeEmployerPlan) => void
}

export default React.forwardRef(function PlanList(
  { employerID, selectedID, onChange }: props,
  ref: any
): React.ReactElement | null {
  const [planID, setPlanID] = useState<number | null>(selectedID || null)
  const [employerPlans, setEmployerPlans] = useState<item[]>([])
  const { catchAPIError } = useErrorHandlers()

  React.useImperativeHandle(
    ref,
    () => ({
      refresh,
    }),
    []
  )

  useEffect(() => {
    if (!employerID) return
    load(employerID)
  }, [employerID])

  function refresh() {
    if (!employerID) return
    load(employerID)
  }

  function load(employerID: number) {
    findEmployerPlans({ filter: { EmployerID: employerID } })
      .then(({ Data }: any) => {
        setEmployerPlans(
          Data.map((pl: ShapeEmployerPlan) => ({
            ...pl,
            label: (
              <StyledItem>
                <span>{pl.Label}</span>
                <small>
                  ({dateTime.parse(pl.StartDate).format()} -{' '}
                  {dateTime.parse(pl.EndDate).format(dateTime.formats.Calendar)}
                  )
                </small>
              </StyledItem>
            ),
            value: pl.ID,
          }))
        )
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading employer plans',
        })
      )
  }

  function setter({ name, value }: SetterArgs) {
    setPlanID(value)
    onChange?.(
      value,
      employerPlans.find((p) => p.ID === value) as ShapeEmployerPlan
    )
  }

  if (!employerID) return null

  return (
    <RenderSelectField2
      name="EmployerPlanID"
      label="Employer Plan"
      setter={setter}
      items={employerPlans}
      value={planID}
      FormControlProps={{ fullWidth: true, margin: 'none' }}
    />
  )
})
