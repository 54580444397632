import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { PfManagerDialog } from '../PracticeFacilities/PfManager'
import { ApplyToOrgDialog } from './ApplyToOrgDialog'

import utils from '../../utils'
import {
  FeeScheduleActions,
  PracticeFacilityActions,
  SnackbarActions,
  clearSaveResult,
} from '../../actions'
const { saveFeeScheduleEntities, APPLY_TO_ORGANIZATION } = FeeScheduleActions
const { findPfs } = PracticeFacilityActions

const { setSnackbarMessage } = SnackbarActions

class feeScheduleRelationsManager extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      saving: false,
      loading: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { facilitiesOpen } = this.props
    const { fsId, saveResult } = nextProps

    if (nextProps.facilitiesOpen && facilitiesOpen === false) {
      this.setState({ loading: true })
      this.props
        .findPfs({
          filter: { DisableLimit: true, FeeScheduleID: fsId },
        })
        .then((res) => {
          this.setState({ loading: false })
        })
    }

    if (saveResult) {
      if (saveResult.saved) {
        if (saveResult.type === APPLY_TO_ORGANIZATION) {
          this.props.setSnackbarMessage(
            'Applied to all facilities on the organization!',
            'success'
          )
        } else {
          this.props.setSnackbarMessage('Saved!', 'success')
        }
        this.props.clearSaveResult()
      } else if (saveResult.error) {
        const message = `An error occurred while saving. Request ID: ${saveResult.requestId}`
        this.props.setSnackbarMessage(message, 'error')
        this.props.clearSaveResult()
      }
    }
  }

  onConfirmEntities = (key) => {
    return ({ added, removed }) => {
      this.setState({ saving: true })
      this.props
        .saveFeeScheduleEntities({
          feeScheduleId: this.props.fsId,
          added: {
            [key]: utils.getIds(added),
          },
          removed: {
            [key]: utils.getIds(removed),
          },
        })
        .then((res) => {
          this.setState({ saving: false })
          this.props.onConfirm()
        })
    }
  }

  render() {
    const {
      onCancel,
      onUpdate,
      onConfirm,
      facilitiesOpen,
      facilities,
      fsId,
      orgOpen,
      orgId,
      orgName,
      isProd,
      feeSchedule,
    } = this.props
    const { saving, loading } = this.state

    return (
      <div>
        <ApplyToOrgDialog
          open={orgOpen}
          orgId={orgId}
          orgName={orgName}
          fsId={fsId}
          onConfirm={onConfirm}
          onCancel={onCancel}
          onUpdate={onUpdate}
        />
        <PfManagerDialog
          disableInactive={feeSchedule?.Status === 1}
          open={facilitiesOpen}
          currentFacilities={facilities}
          onConfirm={this.onConfirmEntities('practiceFacilityIDs')}
          onCancel={onCancel}
          onUpdate={onUpdate}
          orgId={orgId}
          saving={saving}
          loading={loading}
          onError={(msg) => {
            this.props.setSnackbarMessage(msg, 'error')
          }}
          disableRemoval={isProd}
        />
      </div>
    )
  }
}

feeScheduleRelationsManager.propTypes = {
  fsId: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  facilitiesOpen: PropTypes.bool.isRequired,
  orgOpen: PropTypes.bool.isRequired,
  orgName: PropTypes.string,
  isProd: PropTypes.bool.isRequired,
}

feeScheduleRelationsManager.defaultProps = {
  onConfirm: () => {
    console.warn(
      'You must set an onConfirm property on the FeeScheduleRelationsManager'
    )
  },
  onCancel: () => {
    console.warn(
      'You must set an onCancel property on the FeeScheduleRelationsManager'
    )
  },
  onUpdate: () => {
    console.warn(
      'You must set an onUpdate property on the FeeScheduleRelationsManager'
    )
  },
  facilitiesOpen: false,
  orgOpen: false,
}

function mapStateToProps(state) {
  return {
    feeSchedule: state.feeSchedules.feeSchedule || {},
    facilities: state.feeSchedules.facilities || [],
    saveResult: state.feeSchedules.saveResult,
    getError: state.feeSchedules.getError,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { findPfs, saveFeeScheduleEntities, setSnackbarMessage, clearSaveResult },
    dispatch
  )
}

export const FeeScheduleRelationsManager = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(feeScheduleRelationsManager)
