import * as api from '../services/thezerocard/api-helper'
export const SEARCH_FOR_ELIGIBILITY = 'SEARCH_FOR_ELIGIBILITY'
export const POST_EXPORT_ELIGIBILITY = 'POST_EXPORT_ELIGIBILITY'
export const POST_INGEST_ELIGIBILITY = 'POST_INGEST_ELIGIBILITY'

export const searchForEligibility = (params) => {
  return {
    type: SEARCH_FOR_ELIGIBILITY,
    payload: api.get('/eligibility_search', params),
  }
}

export const searchEligibility2 = (params) => {
  return api.search('/eligibility', params)
}

export const getEmployerMemberLimitedByID = (empMemID) => {
  return api.get(`/eligibility/employer_member_limited/${empMemID}`)
}

export const findEligSrcRecords = (params) => {
  return api.search('/eligibility_source', params)
}

export function taskExportEligibility(body) {
  return {
    type: POST_EXPORT_ELIGIBILITY,
    payload: api.post('/task_export_eligibility', body),
  }
}

export function taskIngestEligibility(body) {
  return {
    type: POST_INGEST_ELIGIBILITY,
    payload: api.post('/task_ingest_eligibility', body),
  }
}
