import React, { useState, useEffect, useRef } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import * as api from '../../../services/thezerocard/api-helper'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import { bytesToFriendly } from '../../../utils'
import styled from 'styled-components'
import dateTime from '../../../utils/dateTime'

const StyledDialogContent = styled(DialogContent)`
  min-width: 650px;
  max-width: 960px;

  .space-bottom {
    margin-bottom: 0.75rem;
  }

  .MuiTable-root {
    thead {
      background: #f1f1f1;
    }
    th,
    td {
      padding: 0.25rem 0.65rem;
      white-space: nowrap;
    }
    ul {
      margin: 0;
      padding-left: 0.5rem;
    }
    tbody tr {
      border-bottom: 1px solid #ccc;
      &:last-of-type {
        border-bottom: none;
      }
    }
    tr.warn {
      background-color: #fff4e5;
    }
  }
`

interface props {
  componentFeeScheduleID: number
  onSuccess?(): void
}

interface apiResShape {
  Lines: shapeLinePreview[]
  CountLinesWithWarnings: number
  Persisted: boolean
}

interface shapeLinePreview {
  ID: number
  CPTCode: string | null
  ComponentFeeScheduleID: number
  CreatedAt: string | null
  DRGCode: string | null
  EndDate: string | null
  Notes: string | null
  Price: string | null // API "decimal" type sent as a string
  StartDate: string | null
  Units: number | null
  UpdatedAt: string | null
  LineNum: number
  Warnings: string[]
}

const colNames = Object.freeze([
  'LineNum',
  'CPTCode',
  'DRGCode',
  'Price',
  'Units',
  'StartDate',
  'EndDate',
  'Notes',
  'Warnings',
])

export default function ComponentFeeScheduleImport({
  componentFeeScheduleID,
  onSuccess,
}: props): React.ReactElement | null {
  const { catchAPIError } = useErrorHandlers()
  const [isOpen, setIsOpen] = useState(false)
  const [reply, setReply] = useState<apiResShape | null>(null)
  const [lastSelectedFileInfo, setLastSelectedFileInfo] = useState<any>(null)
  const [isLoading, setIsLoading] = useState(false)
  const snackbar = useSnackbar()
  const refFileInput = useRef<any>(null)

  useEffect(() => {
    setReply(null)
    setLastSelectedFileInfo(null)
    setIsLoading(false)
  }, [isOpen])

  useEffect(() => {
    if (!refFileInput.current?.files[0]) return
    const { name, lastModified, size } = refFileInput.current?.files[0]
    setLastSelectedFileInfo({ name, lastModified, size })
  }, [refFileInput.current?.files])

  function doSend(persist = false) {
    if (!refFileInput.current || !refFileInput.current.files.length) return

    setIsLoading(true)
    const fileObj = refFileInput.current?.files[0]
    const fd = new FormData()
    fd.append('file', fileObj, fileObj.name)
    fd.append('args', JSON.stringify({ persist }))

    api
      .postFormData(
        `/component_fee_schedule/${componentFeeScheduleID}/component_price_import`,
        fd
      )
      .then((res: { Data: apiResShape } & Partial<any>) => {
        if (res.error) throw res

        if (res.Data.Persisted) {
          setIsOpen(false)
          onSuccess && onSuccess()
          snackbar.show('Prices imported OK', SnackbarTypeSuccess)
          return
        }

        setReply(res.Data)

        // Attention: this is what enables users being able to re-select the same
        // file: the file input will not fire its' onChange event if the current file
        // on the input is selected by the user's OS again... so, after we get the results
        // from the API, if we know it's not persistable, then we immediately reset the
        // value on the input.
        if (res.Data.CountLinesWithWarnings > 0) {
          if (refFileInput.current) {
            refFileInput.current.value = null
          }
        }
      })
      .catch(
        catchAPIError({
          defaultMessage: `Component fee schedule import ${persist ? 'preview' : 'run'} failed`,
          withError(err: any) {
            setReply(null)
          },
        })
      )
      .finally(() => {
        setIsLoading(false)
      })
  }

  function onSelectFile() {
    if (!refFileInput.current?.files.length) return
    doSend()
  }

  function canSend() {
    if (!reply) return false
    if (reply.CountLinesWithWarnings > 0) return false
    if (!refFileInput.current) return false
    if (!refFileInput.current?.files[0]) return false
    return true
  }

  let importable = false
  if (reply) {
    importable = reply.Lines.length > 0 && reply.CountLinesWithWarnings === 0
  }

  return (
    <>
      <DesignSuite2023.Tooltip title="Open component fee schedule file import dialog">
        <Button
          onClick={() => {
            setIsOpen(true)
          }}
          variant="outlined">
          Import
        </Button>
      </DesignSuite2023.Tooltip>

      {!!isOpen && (
        <Dialog open={isOpen} maxWidth="lg">
          <DialogTitle>Component Fee Schedule File Import</DialogTitle>
          <StyledDialogContent>
            {!!reply === false && (
              <div>
                <p>This must be a CSV file with the following format:</p>
                <ul>
                  <li>
                    <strong>Either</strong> <code>cpt</code> OR <code>drg</code>{' '}
                    are required
                  </li>
                  <li>
                    <code>price</code> is required
                  </li>
                  <li>
                    <code>units</code> and <code>start_date</code> are required
                  </li>
                  <li>
                    <span>
                      <strong>dates</strong> must follow format{' '}
                      <code>YYYY-MM-DD</code>
                    </span>
                    <ul>
                      <li>
                        <code>end_date</code> is optional
                      </li>
                    </ul>
                  </li>
                  <li>
                    <code>notes</code> are optional
                  </li>
                </ul>

                <Typography variant="subtitle2">Sample file</Typography>
                <pre>
                  cpt,drg,price,units,start_date,end_date,notes
                  <br />
                  0001A,,1.99,1,2023-01-01,,
                  <br />
                  ,002,1.99,1,2023-01-01,2023-01-02,this is an optional note
                  <br />
                  0001M,001,1.99,1,2023-01-01,,has both cpt and drg
                </pre>
              </div>
            )}

            {!!lastSelectedFileInfo && !!reply && (
              <>
                <Typography variant="subtitle2">
                  {lastSelectedFileInfo.name}
                </Typography>
                <Typography variant="caption">
                  <strong>File Last Modified:</strong>{' '}
                  {dateTime
                    .parse(lastSelectedFileInfo.lastModified)
                    .local()
                    .fromNow()}
                </Typography>
                &nbsp;&nbsp;
                <Typography variant="caption">
                  <strong>Size:</strong>{' '}
                  {bytesToFriendly(lastSelectedFileInfo.size)}
                </Typography>
                <hr />
              </>
            )}

            {!!reply && (
              <>
                {importable ? (
                  <DesignSuite2023.AlertSuccess className="space-bottom">
                    <strong>{reply?.Lines.length || 0}</strong> lines ready to
                    import
                  </DesignSuite2023.AlertSuccess>
                ) : (
                  <DesignSuite2023.AlertWarning className="space-bottom">
                    <strong>{reply.CountLinesWithWarnings}</strong> lines have
                    warnings (import not allowed)
                  </DesignSuite2023.AlertWarning>
                )}

                <div className="space-bottom">
                  <DesignSuite2023.Table>
                    <thead>
                      <tr>
                        {colNames.map((cn: string) => (
                          <th key={cn}>{cn}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {reply?.Lines.map((l: shapeLinePreview) => {
                        const warns = l.Warnings || []
                        return (
                          <tr
                            key={l.LineNum}
                            className={`${!!warns.length ? 'warn' : ''}`}>
                            {colNames.map((cn: any) => {
                              if (cn === 'Warnings') {
                                return (
                                  <td key={cn}>
                                    <ul>
                                      {warns.map((w: string) => (
                                        <li key={w}>{w}</li>
                                      ))}
                                    </ul>
                                  </td>
                                )
                              }
                              // @ts-ignore
                              return <td key={cn}>{l[cn]}</td>
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </DesignSuite2023.Table>
                </div>

                {!importable && (
                  <Typography variant="caption" className="fix-instructions">
                    <strong>Note:</strong> to import, your file must not contain
                    any warnings. Make your edits then re-upload with the{' '}
                    <strong>Select File</strong> button below.
                  </Typography>
                )}
              </>
            )}
          </StyledDialogContent>
          <DialogActions>
            <Grid container spacing={2} justify="space-between">
              <Grid item xs="auto">
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => {
                    setIsOpen(false)
                  }}>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs="auto">
                {isLoading && (
                  <>
                    <DesignSuite2023.LoadingSpinner />
                    &nbsp;
                  </>
                )}

                {/*
                IMPORTANT: see the conditional style at the end of this: we only ever want
                to hide/show instead of conditionally rendering or not, to ensure that 'refFileInput'
                stays bound to the input, thus making its' .files property accessible to other functions
                of this component.
              */}
                <Button
                  variant="outlined"
                  component="label"
                  color="primary"
                  fullWidth
                  style={{ display: !importable ? 'inline-block' : 'none' }}>
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    ref={refFileInput}
                    onChange={onSelectFile}
                    accept=".csv"
                  />
                  Select File
                </Button>

                {importable && (
                  <Button
                    disabled={!canSend()}
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      doSend(true)
                    }}>
                    Import
                  </Button>
                )}
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}
