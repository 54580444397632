import { useCallback, useState } from 'react'

export default function useRefresh() {
  const [refreshToken, setRefreshToken] = useState<number | null>(null)

  const refresh = useCallback(() => {
    setRefreshToken(Math.round(Math.random() * 10000000000000))
  }, [])

  return { refresh, refreshToken }
}
