const preTransformFuncs = {
  trimWhiteSpace: {
    label: 'Trim Whitespace',
    run(v) {
      return v && v.length >= 1 ? v.trim() : null
    },
  },
}

const postTransformFuncs = {}

export { preTransformFuncs, postTransformFuncs }
