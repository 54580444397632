import { Action } from './Types'
import * as api from '../services/thezerocard/api-helper'
import * as CostKeyActions from './CostKeyActions'
import * as EmployerActions from './EmployerActions'
import * as EligibilityActions from './EligibilityActions'
import * as OpportunityAnalysisActions from './OpportunityAnalysisActions'
import * as ContactActions from './ContactActions'
import * as BillingEntityActions from './BillingEntityActions'
import * as FileActions from './FileActions'
import * as LoaActions from './LoaActions'
import * as AppActions from './AppActions'
import * as OrgActions from './OrganizationActions'
import * as PracticeFacilityActions from './PracticeFacilityActions'
import * as EngineeringActions from './EngineeringActions'
import * as SnackbarActions from './SnackbarActions'
import * as UserActions from './UserActions'
import * as AddressActions from './AddressActions'
import * as MemberActions from './MemberActions'
import * as ReferralActions from './ReferralActions'
import * as ReferrerActions from './ReferrerActions'
import * as StopLossActions from './StopLossActions'
import * as InboundSftpActions from './InboundSftpActions'
import * as GroupActions from './GroupActions'
import * as BundlerActions from './BundlerActions'
import * as FeeScheduleActions from './FeeScheduleActions'
import * as ComponentFeeScheduleActions from './ComponentFeeScheduleActions'
import * as PriceActions from './PriceActions'
import * as ApiActions from './ApiActions'
import * as PartnerActions from './PartnerActions'
import * as TagActions from './TagActions'
import * as BillingPlanActions from './BillingPlanActions'
import * as NetworkActions from './NetworkActions'

export const CLEAR_SAVE_RESULT = 'CLEAR_SAVE_RESULT'
export const CLEAR_AUTO_SUGGEST_SELECTION = 'CLEAR_AUTO_SUGGEST_SELECTION'
export const AUTO_SUGGEST_SELECTED = 'AUTO_SUGGEST_SELECTED'
export const MULTI_SELECT_UPDATED = 'MULTI_SELECT_UPDATED'
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS'
export const SEARCH_DRG_CODE = 'SEARCH_DRG_CODE'

function searchForProvider(params: any): Promise<any> {
  // assign a source of adminapp for provider search origin tracking
  return api.get('/provider_search', { source: 'adminapp' }, params)
}

function clearAutoSuggestMultiSelected(stateKey: any) {
  return {
    type: MULTI_SELECT_UPDATED,
    selections: null,
    stateKey,
  }
}

function multiSelectUpdated(selections: any, stateKey: any) {
  return {
    type: MULTI_SELECT_UPDATED,
    selections,
    stateKey,
  }
}

function autoSuggestSelect(selectedItem: any, stateKey: any) {
  return {
    type: AUTO_SUGGEST_SELECTED,
    selected: selectedItem,
    stateKey,
  }
}

function clearAutoSuggestSelect(stateKey: any) {
  return {
    type: AUTO_SUGGEST_SELECTED,
    selected: null,
    stateKey,
  }
}

function clearAutoSuggestSelection(params: any) {
  const { stateKey } = params

  return {
    type: CLEAR_AUTO_SUGGEST_SELECTION,
    payload: stateKey,
  }
}

function clearSearchResults() {
  return {
    type: CLEAR_SEARCH_RESULTS,
  }
}

function clearSaveResult() {
  return {
    type: CLEAR_SAVE_RESULT,
  }
}

function searchDRGCode(params: any): Action {
  return {
    type: SEARCH_DRG_CODE,
    payload: api.search('/drg_code', params),
  }
}

export {
  ApiActions,
  PriceActions,
  FeeScheduleActions,
  ComponentFeeScheduleActions,
  BillingPlanActions,
  NetworkActions,
  BundlerActions,
  GroupActions,
  ReferralActions,
  ReferrerActions,
  InboundSftpActions,
  UserActions,
  MemberActions,
  AddressActions,
  SnackbarActions,
  EngineeringActions,
  StopLossActions,
  OrgActions,
  PracticeFacilityActions,
  OpportunityAnalysisActions,
  AppActions,
  BillingEntityActions,
  FileActions,
  LoaActions,
  ContactActions,
  EligibilityActions,
  EmployerActions,
  CostKeyActions,
  PartnerActions,
  TagActions,
  clearAutoSuggestSelect,
  autoSuggestSelect,
  clearAutoSuggestMultiSelected,
  multiSelectUpdated,
  clearSaveResult,
  clearAutoSuggestSelection,
  searchForProvider,
  clearSearchResults,
  searchDRGCode,
}
