import React, { useEffect, useState } from 'react'
import DesignSuite2023 from '../../../../components/DesignSuite2023'
import { InputAdornment } from '@material-ui/core'
import { putZClaimDetail } from '../../../../actions/ZClaimActions'
import helpers, { SourceTypes, ClaimTypes } from '../../helpers'
import ManagedDateInput from '../../../../components/Inputs/managedDateInput'
import styled from 'styled-components'
import _get from 'lodash/get'
import useSnackbar, { SnackbarTypeSuccess } from '../../../../hooks/useSnackbar'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'
import StatusDot from '../StatusDot'
import { ShapeZClaimDetail } from '../../types'

const StyledComponent = styled.div`
  .code-list {
    .code-list-item {
      margin-right: 0.5rem;
      padding: 0.45rem 0 0.35rem;
      display: inline-block;
    }
  }
`

type fields = Partial<ShapeZClaimDetail>

interface props {
  detail?: fields
  zClaimID?: number
  claimType: ClaimTypes
  sourceType: SourceTypes
  recordChange?(key: string, value: any): void
  title?: React.ReactElement
  readOnly?: boolean
  markDirty?: (v: boolean) => void
}

const defaultEmptyFields = {
  ClearingHouseClaimNumber: null,
  PriorAuthorizationNumber: null,
  MedicalRecordNumber: null,
  PatientControlNumber: null,
  StatementDateStart: null,
  StatementDateEnd: null,
  AdmissionDate: null,
  DischargeDate: null,
  POSCode: null,
  DRGCode: null,
  AdmissionTypeCode: null,
  AdmissionSourceCode: null,
  PatientStatusCode: null,
}

export default React.forwardRef(function DisplayOtherDetailFields(
  {
    detail = defaultEmptyFields,
    zClaimID,
    recordChange,
    title,
    readOnly = false,
    markDirty,
    claimType,
    sourceType,
  }: props,
  ref: any
): React.ReactElement | null {
  const [changes, setChanges] = useState<fields>({} as fields)
  const [isDirty, setIsDirty] = useState(false)
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  React.useImperativeHandle(
    ref,
    () => ({
      isDirty,
      doSave,
    }),
    [isDirty, changes]
  )

  useEffect(() => {
    markDirty?.(isDirty)
  }, [isDirty])

  useEffect(() => {
    setIsDirty(Object.keys(changes).length > 0)
  }, [changes])

  useEffect(() => {
    setChanges({} as fields)
  }, [detail])

  function doRecordChange(key: string, value: any) {
    const v = value || null // fields are all strings, so '0' would evaluate to truthy
    setChanges((curr: any) => {
      if (_get(detail, key, null) === v) {
        delete curr[key]
      } else {
        curr[key] = v
      }
      return { ...curr }
    })
    recordChange?.(key, v)
  }

  function getVal(key: string): any {
    // @ts-ignore
    if (Object.hasOwn(changes, key)) {
      return changes[key] || ''
    }
    return _get(detail, key, null) || ''
  }

  // eslint-disable-next-line
  async function doSave(): Promise<any> {
    if (!zClaimID) {
      return Promise.reject({
        Error: { Message: 'zClaimID required to save ClaimDetails' },
      })
    }

    const payload = { ...detail } as fields
    Object.keys(changes).forEach((k: string) => {
      payload[k] = changes[k]
    })

    return new Promise((rslv, rej) => {
      return putZClaimDetail(zClaimID, payload)
        .then(() => {
          showSnackbar('Changes saved', SnackbarTypeSuccess)
          rslv(null)
        })
        .catch(
          catchAPIError({
            defaultMessage: 'Failed saving claim details',
            withError: rej,
          })
        )
    })
  }

  const StatementDateStart = (
    <InputField
      name="StatementDateStart"
      key="StatementDateStart"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="Statement Date Start"
      acronym="STMT.FROM"
      kind="date"
      containerWidth="210px"
    />
  )
  const StatementDateEnd = (
    <InputField
      name="StatementDateEnd"
      key="StatementDateEnd"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="Statement Date End"
      acronym="STMT.TO"
      kind="date"
      containerWidth="195px"
    />
  )
  const PatientControlNumber = (
    <InputField
      name="PatientControlNumber"
      key="PatientControlNumber"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="PatientControlNumber"
      acronym="PCN"
    />
  )
  const DRGCode = (
    <InputField
      name="DRGCode"
      key="DRGCode"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="Diagnostic-Related Group"
      acronym="DRG"
      width={72}
    />
  )
  const ClearingHouseClaimNumber = (
    <InputField
      name="ClearingHouseClaimNumber"
      key="ClearingHouseClaimNumber"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="Clearing House Claim Number"
      acronym="CLHN"
    />
  )
  const PriorAuthorizationNumber = (
    <InputField
      name="PriorAuthorizationNumber"
      key="PriorAuthorizationNumber"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="Prior Authorization Number"
      acronym="PAN"
    />
  )
  const MedicalRecordNumber = (
    <InputField
      name="MedicalRecordNumber"
      key="MedicalRecordNumber"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="Medical Record Number"
      acronym="MRN"
      width={180}
    />
  )
  const POSCode = (
    <InputField
      name="POSCode"
      key="POSCode"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="Place of Service Code"
      acronym="POS"
      width={72}
    />
  )
  const AdmissionDate = (
    <InputField
      name="AdmissionDate"
      key="AdmissionDate"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="Admission Date"
      acronym="ADM"
      kind="date"
      containerWidth="170px"
    />
  )
  const DischargeDate = (
    <InputField
      name="DischargeDate"
      key="DischargeDate"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="Discharge Date"
      acronym="DIS"
      kind="date"
      containerWidth="160px"
    />
  )
  const PatientStatusCode = (
    <InputField
      name="PatientStatusCode"
      key="PatientStatusCode"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="Patient Status Code"
      acronym="PSC"
      width={72}
    />
  )
  const AdmissionTypeCode = (
    <InputField
      name="AdmissionTypeCode"
      key="AdmissionTypeCode"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="Admission Type Code"
      acronym="ATC"
      width={72}
    />
  )
  const AdmissionSourceCode = (
    <InputField
      name="AdmissionSourceCode"
      key="AdmissionSourceCode"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="Admission Source Code"
      acronym="ASC"
      width={72}
    />
  )
  const ExtClaimID = (
    <InputField
      name="ExtClaimID"
      key="ExtClaimID"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="External Claim ID"
      acronym="EXT"
    />
  )
  const RXPrescriberID = (
    <InputField
      name="RXPrescriberID"
      key="RXPrescriberID"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="Prescriber ID"
      acronym="RXPID"
    />
  )
  const RXPrescriptionNumber = (
    <InputField
      name="RXPrescriptionNumber"
      key="RXPrescriptionNumber"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="Prescription Number"
      acronym="RXPN"
    />
  )
  const RXDawCode = (
    <InputField
      name="RXDawCode"
      key="RXDawCode"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="Daw Code"
      acronym="DAW"
    />
  )
  const RXProductTypeCode = (
    <InputField
      name="RXProductTypeCode"
      key="RXProductTypeCode"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="Product Type Code"
      acronym="PRDTYP"
    />
  )
  const RXClaimTypeCode = (
    <InputField
      name="RXClaimTypeCode"
      key="RXClaimTypeCode"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="Claim Type Code"
      acronym="CLMTYP"
    />
  )
  const RXRefillNumber = (
    <InputField
      name="RXRefillNumber"
      key="RXRefillNumber"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="Refill Number"
      acronym="REFILL"
    />
  )
  const RXQuantity = (
    <InputField
      name="RXQuantity"
      key="RXQuantity"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="Quantity"
      acronym="QTY"
    />
  )
  const RXDaysSupply = (
    <InputField
      name="RXDaysSupply"
      key="RXDaysSupply"
      disabled={readOnly}
      getVal={getVal}
      doRecordChange={doRecordChange}
      tooltip="Days Supply"
      acronym="DAYS"
    />
  )

  let fields = []
  if (sourceType === SourceTypes.SourceTypeGoZero) {
    fields = [
      StatementDateStart,
      StatementDateEnd,
      PatientControlNumber,
      DRGCode,
    ]
  } else {
    switch (claimType) {
      case ClaimTypes.Professional:
        fields = [
          ClearingHouseClaimNumber,
          PatientControlNumber,
          PriorAuthorizationNumber,
          MedicalRecordNumber,
          StatementDateStart,
          StatementDateEnd,
          POSCode,
        ]
        break

      case ClaimTypes.Institutional:
        fields = [
          ClearingHouseClaimNumber,
          PatientControlNumber,
          PriorAuthorizationNumber,
          MedicalRecordNumber,
          AdmissionDate,
          DischargeDate,
          StatementDateStart,
          StatementDateEnd,
          DRGCode,
          PatientStatusCode,
          AdmissionTypeCode,
          AdmissionSourceCode,
          POSCode,
        ]
        break

      case ClaimTypes.RX:
        fields = [
          ExtClaimID,
          PriorAuthorizationNumber,
          RXPrescriberID,
          RXPrescriptionNumber,
          RXDawCode,
          RXProductTypeCode,
          RXClaimTypeCode,
          RXRefillNumber,
          RXQuantity,
          RXDaysSupply,
        ]
    }
  }

  return (
    <StyledComponent>
      {!!title && (
        <helpers.FlexAlign spaced>
          <helpers.FlexAlign tag="h5">
            {title}
            {isDirty && <StatusDot.Dot color={StatusDot.Colors.YELLOW} />}
          </helpers.FlexAlign>
        </helpers.FlexAlign>
      )}

      {!!fields.length ? (
        <div className="code-list">{fields}</div>
      ) : (
        // There should be no circumstance where this gets displayed if the
        // system is working correctly
        <div>
          <p style={{ color: '#ad4242' }}>
            Unrecognized claim configuration; please contact Engineering.
          </p>
        </div>
      )}

      {/* {JSON.stringify(changes, null, '  ')} */}
      {/* <small>needs save? {isDirty ? 'true' : 'false'}</small> */}
    </StyledComponent>
  )
})

function InputField({
  kind = 'text',
  getVal,
  doRecordChange,
  tooltip,
  name,
  acronym,
  width,
  disabled = false,
  containerWidth = null,
}: any): React.ReactElement {
  if (kind === 'date') {
    return (
      <div className="code-list-item" style={{ width: containerWidth }}>
        <DesignSuite2023.Tooltip title={tooltip}>
          <div>
            <ManagedDateInput
              name={name}
              value={getVal(name)}
              setter={(a: any) => doRecordChange(name, a.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{acronym}</InputAdornment>
                ),
              }}
              UseComponent={helpers.ExtraSmallTextField}
              margin="none"
              disabled={disabled}
            />
          </div>
        </DesignSuite2023.Tooltip>
      </div>
    )
  }

  return (
    <div className="code-list-item" style={{ width: containerWidth }}>
      <DesignSuite2023.Tooltip title={tooltip}>
        <helpers.ExtraSmallTextField
          value={getVal(name)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            doRecordChange(name, e.target.value)
          }}
          InputProps={{
            style: { width: width || null },
            startAdornment: (
              <InputAdornment position="start">{acronym}</InputAdornment>
            ),
          }}
          disabled={disabled}
        />
      </DesignSuite2023.Tooltip>
    </div>
  )
}
