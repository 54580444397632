import React from 'react'
// import get from 'lodash/get'
// import { EngineeringActions } from '../../actions'
// import { withStyles } from '@material-ui/core/styles'
// import { withRouter } from 'react-router-dom'
// import { compose, bindActionCreators } from 'redux'
// import { connect } from 'react-redux'
// import DetailView, { defaultStyleCreator } from '../../components/DetailView/DetailView'

export default function UserDiff() {
  return (
    <div>
      @todo: this needs to be reworked in order to provide any value (considered
      an outdated tool by January 2022)
    </div>
  )
}

// const styles = theme => {
//   const defaults = defaultStyleCreator(theme)
//   const classes = {
//     auditBlock: {
//       background: '#ffffff',
//       padding: '1rem',
//       fontSize: '11px'
//     }
//   }
//   return Object.assign(classes, defaults)
// }

// export class UserDiff extends DetailView {
//   constructor (props) {
//     super(props)
//     this.state = this.initialState = {
//       audited: {}
//     }
//   }

//   UNSAFE_componentWillMount() {
//     const self = this
//     EngineeringActions.getAuth0Diff()
//       .then(res => {
//         self.setState({
//           audited: get(res, 'Data', {})
//         })
//       })
//       .catch(e => { console.warn(e) })
//   }

//   render () {
//     return (
//       <div>
//         {this.renderAuth0Audit()}
//         {this.renderZCAudit()}
//         {this.renderDiffs()}
//       </div>)
//   }

//   renderAuth0Audit = () => {
//     const { classes } = this.props
//     const data = get(this, 'state.audited.auth0Audit', [])
//     return (
//       <div>
//         <h3>Auth0 Database Discrepancies</h3>
//         <p>The following accounts exist in Auth0 but may or may not exist with Zerocard. These accounts should either be purged from Auth0, or ensure that user records are properly created and associated in Zerocard.</p>
//         {data.map((v, index) => {
//           return (<pre key={index} className={classes.auditBlock}>{JSON.stringify(v, null, 4)}</pre>)
//         })}
//       </div>)
//   }

//   renderZCAudit = () => {
//     const { classes } = this.props
//     const data = get(this, 'state.audited.zcAudit', [])
//     return (
//       <div>
//         <h3>Zerocard Database Discrepancies</h3>
//         <p>The following accounts exist in Zerocard but may or may not exist with Auth0. These accounts should either be purged from Zerocard, or ensure that Auth0 accounts exist and are correctly associated.</p>
//         {data.map((v, index) => {
//           return (<pre key={index} className={classes.auditBlock}>{JSON.stringify(v, null, 4)}</pre>)
//         })}
//       </div>)
//   }

//   renderDiffs = () => {
//     const { classes } = this.props
//     const data = get(this, 'state.audited.discrepancies', [])
//     return (
//       <div>
//         <h3>Detected Differences</h3>
//         {data.map((v, index) => {
//           return (
//             <div key={index}>
//               <pre className={classes.auditBlock}>{JSON.stringify(v, null, 4)}</pre>
//             </div>)
//         })}
//       </div>)
//   }
// }

// function mapStateToProps (state) {
//   return {}
// }

// function mapDispatchToProps (dispatch) {
//   return bindActionCreators({}, dispatch)
// }

// export default compose(withStyles(styles), withRouter, connect(mapStateToProps, mapDispatchToProps))(UserDiff)
