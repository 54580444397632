import React from 'react'
import { Grid, List, ListItem } from '@material-ui/core'
import SendgridMarketingExport from '../Marketing/SendgridMarketingExport'

export default function Marketing() {
  return (
    <>
      <Grid container spacing={4}>
        <Grid item>
          <h4>Marketing Flows</h4>
          <List disablePadding>
            <ListItem disableGutters>
              <SendgridMarketingExport />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </>
  )
}
