import React, { useRef } from 'react'
import { IconButton } from '@material-ui/core'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import * as PaidTable from '../Components/PaidTable'

export default function PagePaid(): React.ReactElement {
  const refPaidTable = useRef<any>(null)

  const pageColumnLeft = (
    <div className="lefty">
      {' '}
      <div style={{ paddingBottom: '1rem', textAlign: 'right' }}>
        <IconButton
          size="small"
          onClick={() => {
            refPaidTable.current?.refresh()
          }}>
          <DesignSuite2023.CommonIcons.IconRefresh />
        </IconButton>
      </div>
      <DesignSuite2023.Divider />
      <div style={{ paddingBottom: '1rem' }}>
        <PaidTable.StandardFilterSearch
          autoFocus
          label="Search"
          InputLabelProps={{ shrink: true }}
        />
      </div>
    </div>
  )

  return (
    <PaidTable.Table
      ref={refPaidTable}
      className="full-fixed"
      enableURLReflection="zclaims.paid.list"
      DataTableProps={{ stickyHeader: true }}
      BeforeTable={pageColumnLeft}
    />
  )
}
