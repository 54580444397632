import React from 'react'
import styled from 'styled-components'

const _points =
  '20.0021184 0.00372255107 20.0021184 42.679486 26.516558 42.679486 26.516558 6.48315116 75.0480104 6.48315116 93.48468 24.9034287 93.48468 97.2216474 26.5178786 97.2216474 26.5144396 90.3710586 20 90.3710586 20.003439 103.703704 100 103.703704 100 22.2258196 77.7525659 0'

export default function FileIcon({
  ext = '?',
  color = '#000000',
}: any): React.ReactElement {
  return (
    <StyledFileIconWrapper className="file-extension-icon">
      <svg viewBox="0 0 100 104" preserveAspectRatio="xMidYMid meet">
        <polygon fill={color} fillRule="nonzero" points={_points}></polygon>
        <text
          fontFamily="inherit"
          fontSize="35px"
          fontWeight="bold"
          fill={color}>
          <tspan x="0" y="77">
            {ext}
          </tspan>
        </text>
      </svg>
    </StyledFileIconWrapper>
  )
}

const StyledFileIconWrapper = styled.div`
  display: inline-block;
  width: 50px;

  svg {
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
  }
`
