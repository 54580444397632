import React, { useEffect } from 'react'
import { findContacts } from '../../../actions/ContactActions'
import useQueryParamsGen2, { SCOPES } from '../../../hooks/useQueryParamsGen2'
import { Card, IconButton, Typography } from '@material-ui/core'
import styled from 'styled-components'
import utils from '../../../utils'
import ContentCopyIcon from '@material-ui/icons/FileCopy'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import useSnackbar from '../../../hooks/useSnackbar'
import useErrorHandlers from '../../../hooks/useErrorHandlers'

const LOA_CONTACT_TYPE = 'loa'

export type LoaContactsProps = {
  employerID: number
}

const ContactHolder = styled(Card)({
  padding: '10px',
  width: '100%',
  margin: '5px 0',
})

const NameField = styled(Typography)({
  cursor: 'pointer',
})

const SytledIconButton = styled(IconButton)({
  '&:focus': {
    outline: 'none',
  },
})

export default function LoaContacts({ employerID }: LoaContactsProps) {
  const [loaContacts, setLoaContacts] = React.useState([])
  const { setQueryData } = useQueryParamsGen2({
    scope: SCOPES.EDIT_CONTACT_INFO,
  })
  const { showForDuration } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (!employerID) return

    const payload = {
      filter: {
        employerID: employerID,
        contactHandles: [LOA_CONTACT_TYPE],
      },
    }

    findContacts(payload)
      .payload.then((res: any) => {
        if (res.error) throw res
        setLoaContacts(res.Data)
      })
      .catch(catchAPIError({ defaultMessage: 'Failed to load LOA contacts' }))
  }, [employerID])

  if (!loaContacts || loaContacts.length === 0) return null

  return loaContacts.map((contact: any) => (
    <ContactHolder key={contact.ID}>
      <NameField
        variant={'subtitle1'}
        onClick={() => setQueryData({ editContactID: contact.ID })}>
        {contact.FirstName} {contact.LastName}
      </NameField>

      {contact.Email && (
        <DesignSuite2023.GridLR
          left={<Typography variant={'body2'}>{contact.Email}</Typography>}
          right={
            <SytledIconButton
              onClick={() => {
                utils.updateClipboard(
                  contact.Email,
                  () => {
                    showForDuration(
                      'Email copied to clipboard',
                      'success',
                      1500
                    )
                  },
                  () => {}
                )
              }}>
              <ContentCopyIcon fontSize={'small'} />
            </SytledIconButton>
          }
        />
      )}
    </ContactHolder>
  ))
}
