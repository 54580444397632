import React, { useState, useEffect } from 'react'
import * as api from '../../../services/thezerocard/api-helper'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import dateTime from '../../../utils/dateTime'
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import useErrorHandlers from '../../../hooks/useErrorHandlers'

export default function DialogSendTestFile({
  recipientID,
  isOpen,
  setIsOpen,
}: {
  recipientID: number
  isOpen: boolean
  setIsOpen: any
}) {
  const [fileName, setFileName] = useState<string | null>(null)
  const [fileContent, setFileContent] = useState<string | null>(null)
  const { show: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (isOpen) {
      setFileName(
        `${dateTime.now().format(dateTime.formats.YYYYMMDD_HHmmss)}_from_zero_test_file.txt`
      )
      setFileContent('test file, please ignore')
    }
  }, [isOpen])

  const handleSend = () => {
    api
      .post(`/engineering/sftpaas/util/send-test-file`, {
        recipientID,
        fileName,
        fileContent,
      })
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        setIsOpen(false)
        showSnackbar(
          'File sent without encountering any errors, but should be confirmed with the recipient',
          SnackbarTypeSuccess
        )
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Request failed; please contact engineering',
        })
      )
  }

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Send Test File</DialogTitle>
      <DialogContent>
        <DesignSuite2023.AlertWarning style={{ marginBottom: '0.5rem' }}>
          <span>
            If you made changes to the recipient (like the Default Destination
            Path) you want to test here,{' '}
            <strong>make sure you hit Save Recipient</strong> before using this
            feature.
          </span>
        </DesignSuite2023.AlertWarning>
        <DialogContentText>
          This will immediately attempt to ship the contents of the message
          below to the recipient server, at the specified file name. It uses the
          currently-configured credentials and the default destination path for
          this recipient.
          <br />
          Note: if you have a PGP encryption key assigned,{' '}
          <strong>
            the sent file will be encrypted, and have .enc appended to the file
            name.
          </strong>
        </DialogContentText>
        <TextField
          fullWidth
          size="small"
          label="File Name"
          placeholder="fromZero.txt"
          value={fileName || ''}
          onChange={(ev: any) => {
            setFileName(ev.target.value)
          }}
          helperText={`Name of the file to place on the server (should include extension); will be placed into the default drop destination configured on the recipient`}
          style={{ marginBottom: '1rem' }}
        />
        <TextField
          fullWidth
          multiline
          rows={3}
          size="small"
          label="File Content"
          placeholder="..."
          value={fileContent || ''}
          onChange={(ev: any) => {
            setFileContent(ev.target.value)
          }}
        />
      </DialogContent>
      <DialogActions className="dialog-footerbar">
        <Grid container spacing={2} justify="space-between">
          <Grid item xs="auto">
            <Button
              color="secondary"
              variant="outlined"
              onClick={setIsOpen.bind(null, false)}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button color="primary" variant="contained" onClick={handleSend}>
              Send
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
