import * as api from '../services/thezerocard/api-helper'
export const FIND_COMPONENT_FEE_SCHEDULE = 'FIND_COMPONENT_FEE_SCHEDULES'
export const GET_NEW_COMPONENT_FEE_SCHEDULE = 'GET_NEW_COMPONENT_FEE_SCHEDULE'
export const GET_COMPONENT_FEE_SCHEDULE = 'GET_COMPONENT_FEE_SCHEDULE'
export const SAVE_COMPONENT_FEE_SCHEDULE = 'SAVE_COMPONENT_FEE_SCHEDULE'
export const SAVE_NEW_COMPONENT_FEE_SCHEDULE = 'SAVE_NEW_COMPONENT_FEE_SCHEDULE'
export const RESET_FEE_SCHEDULE_STATE = 'RESET_FEE_SCHEDULE_STATE'
export const MOVE_COMPONENT_TO_PRODUCTION = 'MOVE_COMPONENT_TO_PRODUCTION'
export const CLONE_COMPONENT_FEE_SCHEDULE = 'CLONE_COMPONENT_FEE_SCHEDULE'
export const GET_COMPONENT_FEE_SCHEDULE_TYPES =
  'GET_COMPONENT_FEE_SCHEDULE_TYPES'
export const CLEAR_COMPONENT_FEE_SCHEDULE_SAVE_RESULT =
  'CLEAR_COMPONENT_FEE_SCHEDULE_SAVE_RESULT'

export const findComponentFeeSchedules = (params) => {
  return {
    type: FIND_COMPONENT_FEE_SCHEDULE,
    payload: api.search('/component_fee_schedule', params),
  }
}

export const getNewComponentFeeSchedule = (params) => {
  return {
    type: GET_NEW_COMPONENT_FEE_SCHEDULE,
    payload: {},
  }
}

export const getComponentFeeSchedule = (params) => {
  return {
    type: GET_COMPONENT_FEE_SCHEDULE,
    payload: api.get(`/component_fee_schedule/${params.id}`),
  }
}

export const getComponentFeeScheduleTypes = () => {
  return {
    type: GET_COMPONENT_FEE_SCHEDULE_TYPES,
    payload: api.get('/component_type'),
  }
}

export const saveComponentFeeSchedule = (id, body) => {
  return {
    type: SAVE_COMPONENT_FEE_SCHEDULE,
    payload: api.put(`/component_fee_schedule/${id}`, body),
  }
}

export const saveNewComponentFeeSchedule = (body) => {
  return {
    type: SAVE_NEW_COMPONENT_FEE_SCHEDULE,
    payload: api.post('/component_fee_schedule', body),
  }
}

export const moveComponentToProduction = (id, body) => {
  return {
    type: MOVE_COMPONENT_TO_PRODUCTION,
    payload: api.put(`/component_fee_schedule/${id}/move_to_production`, body),
  }
}

export const cloneComponentFeeSchedule = (params) => {
  const { id } = params
  return {
    type: CLONE_COMPONENT_FEE_SCHEDULE,
    payload: api.post(`/component_fee_schedule/${id}/clone`),
  }
}

export const bulkTerminateComponentFeeSchedules = (params) => {
  return api.put('/component_fee_schedules/bulk_terminate', params)
}

export const bulkDeleteComponentFeeSchedules = (params) => {
  return api.put('/component_fee_schedules/bulk_delete', params)
}

export const clearComponentFeeScheduleSaveResult = () => {
  return { type: CLEAR_COMPONENT_FEE_SCHEDULE_SAVE_RESULT }
}

export const getComponentFeeScheduleFiles = (id) => {
  return api.get(`/component_fee_schedule/${id}/files`)
}
