/*
Instead of dumping raw JSON to the screen (even if its "pretty" to developers),
this is more end-user friendly. Pass in any object (like a srcPatient record) and it'll
show it like a pretty mini-table.
*/
import React, { useMemo } from 'react'
import styled from 'styled-components'

const StyledDisplay = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 0.25rem;
  background: #222;
  color: #fff;
  font-family: monospace;
  font-size: 90%;
  padding: 1rem;
  border-radius: 4px;
  overflow: hidden;
  overflow-x: scroll;

  strong {
    white-space: nowrap;
  }
  span.null {
    opacity: 0.35;
  }
`

interface props {
  data: any
  hideEmptyFields?: boolean
  skipFields?: string[]
  customRender?: { [key: string]: (v: any) => any }
}

export default function DisplayKeyValueData({
  data,
  hideEmptyFields = true,
  skipFields,
  customRender,
  ...otherProps
}: props & Partial<any>): React.ReactElement | null {
  const useData = useMemo(() => {
    if (!data) return null

    let obj = data
    if (hideEmptyFields) {
      obj = removeEmptyObjFields(data)
      for (const k of skipFields || []) {
        delete obj[k]
      }
    }

    const out: any[] = []
    Object.keys(obj).forEach((k: string) => {
      out.push(<strong key={`${k}.label`}>{k}</strong>)
      if (obj?.[k] === null) {
        out.push(
          <span key={`${k}.value`} className="null">
            {customRender?.[k] ? customRender[k](obj[k]) : 'null'}
          </span>
        )
        return
      }
      out.push(
        <span key={`${k}.value`}>
          {customRender?.[k] ? customRender[k](obj[k]) : JSON.stringify(obj[k])}
        </span>
      )
    })

    return out
  }, [data, hideEmptyFields, skipFields])

  if (!useData) return null
  return <StyledDisplay {...otherProps}>{useData}</StyledDisplay>
}

export function removeEmptyObjFields(obj: any): any {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v !== null)
      .map(([k, v]) => [k, v === Object(v) ? removeEmptyObjFields(v) : v])
  )
}
