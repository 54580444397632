import React, { useState, useEffect, useRef } from 'react'
import { useConfig } from '../../providers/Config'
import styled from 'styled-components'
import { getAddressByID } from '../../actions/AddressActions'
import { ShapeAddress } from '../Address'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import { Loader as GoogleMapsLoader } from '@googlemaps/js-api-loader'

/*
Stop typescript from complaining about google.maps. Anywhere google
namespaced types are used below, the proper typescripting use is
commented out. If typescript definitions are fixed someday, great,
but for now we don't have time to argue w/ typescript compiler.
*/
declare var google: any

interface props {
  addressID: number | null
  zoom?: number
  height?: number | string
}

const StyledMap = styled.div`
  width: 100%;
  height: 300px;
`

const defaultMapOptions = Object.freeze({
  panControl: false,
  mapTypeControl: false,
  scrollwheel: false,
  fullscreenControl: false,
  disableDoubleClickZoom: true,
})

export default function MapPreview({
  addressID,
  zoom = 11,
  height = 300,
}: props): React.ReactElement {
  const [addressObj, setAddressObj] = useState<ShapeAddress | null>(null)
  const { mapsKey } = useConfig()
  const [mapsSDK, setMapsSDK] = useState<
    any /*google.maps.MapsLibrary*/ | null
  >(null)
  const { catchAPIError } = useErrorHandlers()
  const mapRef = useRef<HTMLDivElement>(null)
  // @ts-ignore - typescript is getting this wrong; useId does exist
  const uniqueID = React.useId()

  useEffect(() => {
    if (!mapsKey) return
    loadMapsSDK(mapsKey).then(setMapsSDK)
  }, [mapsKey])

  useEffect(() => {
    if (!addressID) return
    loadAddressByID(addressID)
  }, [addressID])

  useEffect(() => {
    if (!addressObj || !mapsSDK || !mapRef?.current) return
    renderMap()
  }, [uniqueID, addressObj, mapsSDK, mapRef])

  function loadAddressByID(id: number): void {
    getAddressByID(id)
      .then((res: any) => {
        setAddressObj(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: `Failed loading address ID:${id} to display on map`,
        })
      )
  }

  function renderMap() {
    if (!addressObj || !mapsSDK || !mapRef?.current) return

    const mapObj = new mapsSDK.Map(mapRef.current, {
      center: { lat: addressObj.Lat, lng: addressObj.Long },
      zoom: zoom,
      mapId: uniqueID,
      ...defaultMapOptions,
    })

    // @ts-ignore
    new mapsSDK.AdvancedMarkerElement({
      map: mapObj,
      position: { lat: addressObj.Lat, lng: addressObj.Long },
    })
  }

  if (!addressObj || !mapsSDK) {
    return (
      <StyledMap>
        <small>Loading map...</small>
      </StyledMap>
    )
  }

  return <StyledMap ref={mapRef} style={{ height }} />
}

/*
Google maps SDK loader singleton
https://developers.google.com/maps/documentation/javascript/load-maps-js-api#typescript
*/
let mapsSDK: any = null
function loadMapsSDK(apiKey: string): Promise<any /*google.maps.MapsLibrary*/> {
  if (mapsSDK) return mapsSDK

  const loader = new GoogleMapsLoader({
    apiKey: apiKey,
    version: 'weekly',
  })

  mapsSDK = new Promise((res, rej) => {
    loader
      .load()
      .then(async () => {
        const { Map } = await google.maps.importLibrary('maps') //as google.maps.MapsLibrary
        const { AdvancedMarkerElement } =
          await google.maps.importLibrary('marker') //as google.maps.MarkerLibrary
        res({ Map, AdvancedMarkerElement })
        // res(google.maps)
      })
      .catch((err: any) => {
        console.error('[MapPreview] Failed to load Google Maps SDK', err)
        rej(err)
      })
  })

  return mapsSDK
}
