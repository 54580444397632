import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { EmployerActions } from '../../../actions'
import { FileImportProps, useImportFile, useRefreshFiles } from './hookCommon'

export default function ({ employerID }: FileImportProps) {
  const {
    refFileInput,
    processing,
    errors,
    result,
    baseReset,
    onSelectFileHandler,
  } = useImportFile()
  const refreshFiles = useRefreshFiles(employerID)

  const [duplicates, setDuplicates] = useState([])
  const [unprocesseds, setUnprocesseds] = useState([])

  const reset = (isProcessing: boolean) => {
    baseReset(isProcessing)
    setDuplicates([])
    setUnprocesseds([])
  }

  const onSelectFile = onSelectFileHandler({
    employerID,
    fnResetAll: reset,
    actioner(params: any) {
      return EmployerActions.importEmployerMemberEmails(params).payload
    },
    withResults(data: any) {
      setDuplicates(data.Duplicates || [])
      setUnprocesseds(data.UnprocessedErrs || [])
      refreshFiles()
    },
  })

  return (
    <div style={{ maxWidth: '830px', margin: '0 auto', padding: '3rem 0' }}>
      <h4>Employer Member Email Import</h4>
      <p>
        This is a tool for matching a list of emails to members of this employer
        (ie. employer members).
      </p>

      <p>Select a CSV file that contains the required headers:</p>
      <ul style={{ marginTop: '-5px' }}>
        <li>
          <code>first</code>
        </li>
        <li>
          <code>last</code>
        </li>
        <li>
          <code>email</code>
        </li>
      </ul>

      <p>Optional headers include:</p>
      <ul style={{ marginTop: '-5px' }}>
        <li>
          <code>dob</code> (ex. <code>1/2/2006</code> or <code>1/2/06</code>)
        </li>
      </ul>

      <p>
        The file will automatically be uploaded and imported, and any issues
        will appear below.
      </p>

      {result && (
        <div>
          <hr />
          <p>
            <strong>File:</strong> {result.FileName} (ID: {result.FileID})<br />
            <strong>Total Lines:</strong> {result.TotalLinesRead}
            <br />
            <strong>Empty Records:</strong> {result.EmptyRecords}
            <br />
            <strong>Skipped (no email present):</strong>{' '}
            {result.SkippedNoEmailPresent}
            <br />
            <strong>Duplicates:</strong> {duplicates.length}
            <br />
            <strong>Processing Errors:</strong> {unprocesseds.length}
            <br />
          </p>

          {duplicates.length > 0 && (
            <div>
              <hr />
              <h5>Duplicates</h5>
              {duplicates.map((d) => {
                return <code key={d}>{JSON.stringify(d)}</code>
              })}
            </div>
          )}

          {unprocesseds.length > 0 && (
            <div>
              <hr />
              <h5>Unprocessed (possibly due to errors)</h5>
              {unprocesseds.map((d) => {
                return <p key={d}>{d}</p>
              })}
            </div>
          )}

          <hr />
          <Button
            variant="outlined"
            color="primary"
            onClick={reset.bind(null, false)}>
            Upload Another File
          </Button>
        </div>
      )}

      {errors && (
        <div>
          <hr />
          <h5>Unable to import file:</h5>
          {Array.isArray(errors)
            ? errors.map((msg) => {
                return <p key={msg}>{msg}</p>
              })
            : JSON.stringify(errors)}
          <small style={{ paddingBottom: '1rem', display: 'block' }}>
            You can reselect another file to try again.
          </small>
        </div>
      )}

      {!result && (
        <Button
          variant="contained"
          component="label"
          color="primary"
          fullWidth
          disabled={processing}>
          {processing ? 'Processing...' : 'Select File'}
          <input
            style={{ display: 'none' }}
            type="file"
            ref={refFileInput}
            onChange={onSelectFile}
            accept=".csv"
          />
        </Button>
      )}

      {processing && (
        <small style={{ padding: '1rem', display: 'block' }}>
          This may take a while. DO NOT REFRESH YOUR BROWSER.
        </small>
      )}
    </div>
  )
}
