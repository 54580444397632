import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import debounce from 'lodash/debounce'

import Models from '../../models'
import { DataTable } from '../../components/DataTable'
import QSearcher from '../../components/QSearcher'
import { defaultStyleCreator } from '../../components/DetailView/DetailView'
import { UserActions } from '../../actions'
import { Chip } from '@material-ui/core'

const { findUsers } = UserActions

const styles = (theme) => {
  const defaults = defaultStyleCreator(theme)
  return Object.assign(defaults, {})
}

class UserTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.initialState = {
      q: '',
      page: 1,
      pageSize: 10,
      loading: false,
    }
  }

  UNSAFE_componentWillMount() {
    const { q, page, pageSize } = this.state
    console.debug('Clearing out old state...')
    console.debug('Fetching users...')
    this.doFindUsers(q, page, pageSize)
  }

  doFindUsers = (q, page, pageSize) => {
    const { groupIds } = this.props
    this.setState({ loading: true })
    if (q.length === 0 || q.length > 2) {
      const filter = { q }
      if (groupIds && groupIds.length > 0) filter.accessGroupIds = groupIds
      this.props.findUsers({ filter, page, pageSize }).then((_) => {
        this.setState({ loading: false })
      })
    }
  }

  handleChangePage = ({ page, pageSize }) => {
    const { q } = this.state
    this.setState({ page, pageSize })
    this.doFindUsers(q, page, pageSize)
  }

  handleQueryChange = debounce((q) => {
    this.setState({ q })
    if (!q || q.length > 2)
      this.doFindUsers(q, this.state.page, this.state.pageSize)
  }, 350)

  handleRowClick = (e, { ID }) => {
    const redirectPath = `/user/${ID}`
    this.props.history.push({
      pathname: redirectPath,
      state: { prevSearch: this.props.location.search },
    })
  }

  renderStaticFilters = () => {
    const { groupIds } = this.props
    return (
      <div>
        {groupIds && (
          <div>
            {groupIds.map((item) => {
              return (
                <Chip
                  label={`Group Id: ${item}`}
                  key={item}
                  className={this.props.classes.chip}
                />
              )
            })}
          </div>
        )}
      </div>
    )
  }

  render() {
    const { users, count, searchError, classes, onRowClick, autoFocus } =
      this.props
    const handleClick = onRowClick ? onRowClick : this.handleRowClick

    return (
      <div>
        <div id="user-table">
          <QSearcher
            search={this.handleQueryChange}
            classNames={classes.searchField}
            autoFocus={autoFocus}
          />
          {this.renderStaticFilters()}
        </div>
        <DataTable
          columns={Models.User.columns}
          keyProp="ID"
          data={users}
          loading={this.state.loading}
          onRowClick={handleClick}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangePage}
          count={count}
          error={searchError}
        />
      </div>
    )
  }
}

UserTable.propTypes = {
  onRowClick: PropTypes.func,
  autoFocus: PropTypes.bool,
}

UserTable.defaultProps = {
  autoFocus: false,
}

function mapStateToProps(state) {
  return {
    users: state.users.users || [],
    count: state.users.count || 0,
    searchError: state.users.searchError || null,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ findUsers }, dispatch)
}

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UserTable)
