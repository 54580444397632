// @ts-ignore
import React, { forwardRef } from 'react'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import { findEligSrcRecords } from '../../actions/EligibilityActions'
import EligModel from '../../models/Eligibility'

// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../hooks/useStandardTableSetup'

export const defaultColumns = Object.freeze({ ...EligModel.srcColumns })

export const Table = forwardRef(function EligibilitySourceTable2(
  {
    customColumns = defaultColumns,
    apiEndpoint = findEligSrcRecords,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      DataTableProps: { pagination: false },
    },
    ref
  )

  return <>{TableDisplay}</>
})
