import React, { useEffect, useState } from 'react'
import DesignSuite2023 from '../../components/DesignSuite2023'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  EditEligibilityFileConfigProps,
  EligibilityFileConfigFields,
} from '../../models/types/EligibilityFileConfig'
import Models from '../../models'
import {
  clearSaveResult,
  EmployerActions,
  SnackbarActions,
} from '../../actions'
import {
  renderNotesField,
  renderNumericField,
  renderSelectField,
  renderSelectFieldWithTooltip,
  renderSwitchField,
  renderTextField,
  renderTextFieldWithTooltip,
  SetterArgs,
} from '../ViewHelpers'

import _ from 'lodash'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'

const {
  getNewEligibilityFileConfig,
  getEligibilityFileConfig,
  postEligibilityFileConfig,
  putEligibilityFileConfig,
} = EmployerActions
const { setSnackbarMessage } = SnackbarActions

const EditEligibilityFileConfigDetails = ({
  configID,
  employerID,
  open,
  ...props
}: EditEligibilityFileConfigProps) => {
  const [fields, setFields]: [EligibilityFileConfigFields, any] = useState(
    Models.EligibilityFileConfig.editFields
  )
  const [changedFields, updateChangedFields]: [any, any] = useState({})
  const [originals, setOriginals]: [EligibilityFileConfigFields, any] =
    useState(Models.EligibilityFileConfig.editFields)
  const [curID, setCurID] = useState(configID)
  // const canUpdate = Models.Member.canEdit()

  useEffect(() => {
    if (curID === 0) {
      props.getNewEligibilityFileConfig(
        Object.assign({}, Models.EligibilityFileConfig.editFields)
      )
    } else {
      props.getEligibilityFileConfig({ ID: curID })
    }

    /* clean it up when we close */
    return () => {
      props.getNewEligibilityFileConfig(
        Object.assign({}, Models.EligibilityFileConfig.editFields)
      )
      setCurID(0)
    }
  }, [curID]) // eslint-disable-line

  useEffect(() => {
    if (props.eligibilityFileConfigDetail) {
      setFields(props.eligibilityFileConfigDetail)
      setOriginals(props.eligibilityFileConfigDetail)
    }
  }, [props.eligibilityFileConfigDetail])

  useEffect(() => {
    if (props.saveResult) {
      console.debug(props.saveResult)
      if (props.saveResult.saved) {
        // in case we are adding a new one, lets make sure we set the ID after save
        setCurID(props.eligibilityFileConfigDetail.ID)
        props.setSnackbarMessage(
          'Eligibility file config details updated successfully!',
          'success'
        )
        props.handleClose(true)
      } else {
        if (props.saveResult && props.saveResult.message) {
          props.setSnackbarMessage(props.saveResult.message, 'error')
        }
      }
    }

    return () => {
      props.clearSaveResult()
    }
  }, [props.saveResult, props.setSnackbarMessage]) // eslint-disable-line

  /*
     This will likely look the same in every detail component, but im not sure if we can centralize
     it with much less effort. (no matter what, we need to share the local state)
  */
  const setter = ({ name, value }: SetterArgs) => {
    const newFields = Object.assign({}, fields, { [name]: value })

    setFields(newFields)
    updateChangedFields(Object.assign({}, changedFields, { [name]: value }))
  }

  /* We can make this nicer, but its a first stab at an edit log */
  // eslint-disable-next-line
  const renderChangedFields = () => {
    if (_.isEmpty(changedFields)) return <div />

    return (
      <List>
        {Object.keys(changedFields).map((field: string, i) => {
          // @ts-ignore-line
          const origValue = originals[field]
          const changedValue = changedFields[field]
          const whatChanged = `${origValue} --> ${changedValue}`
          if (origValue == changedValue) return <div /> // eslint-disable-line

          return (
            <ListItem key={i}>
              <ListItemText primary={field} secondary={whatChanged} />
            </ListItem>
          )
        })}
      </List>
    )
  }

  const handleClose = (edited: boolean) => {
    return () => {
      props.handleClose(edited)
    }
  }

  const canSave = () => {
    return !_.isEmpty(changedFields)
  }

  const handleSave = () => {
    const fieldsToSave = Object.assign({}, fields)
    // do any pre-parsing needed here
    fieldsToSave.EmployerID = employerID
    // @ts-ignore
    if (fieldsToSave.ParserVersion)
      // @ts-ignore
      fieldsToSave.ParserVersion = Number(fields.ParserVersion)
    else fieldsToSave.ParserVersion = null

    if (curID === 0) {
      props.postEligibilityFileConfig({ body: fieldsToSave })
    } else {
      props.putEligibilityFileConfig({ ID: curID, body: fieldsToSave })
    }
  }

  return (
    <div>
      <Dialog
        maxWidth="lg"
        onClose={handleClose(false)}
        open={open}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {props.readOnly
            ? 'Eligibility File Config Details (*Read Only*)'
            : 'Edit Eligibility File Config Details'}
        </DialogTitle>
        <DialogContent style={{ minWidth: 1000 }}>
          <fieldset disabled={props.readOnly}>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                {renderSelectFieldWithTooltip(
                  {
                    name: 'SubsetHandle',
                    label: 'Subset Handle',
                    items: Models.EligibilityFileConfig.subsetHandleOpts,
                    value: fields.SubsetHandle,
                    setter,
                    opts: { disabled: props.readOnly, margin: 'none' },
                  },
                  'Files associated with this config contain the following groups of members.'
                )}
                {renderSelectField({
                  name: 'FileType',
                  label: 'File Type',
                  items: Models.EligibilityFileConfig.fileTypeOpts,
                  value: fields.FileType,
                  setter,
                  opts: { disabled: props.readOnly },
                })}
                {renderTextFieldWithTooltip(
                  {
                    name: 'SourceSystem',
                    label: 'Source System',
                    value: fields.SourceSystem,
                    setter,
                    opts: { disabled: props.readOnly },
                  },
                  'The system from which the member data is pulled'
                )}
                {renderSelectFieldWithTooltip(
                  {
                    name: 'BenefitStartDateSource',
                    label: 'Benefit Start Date Source',
                    items: Models.EligibilityFileConfig.benefitStartEndOpts,
                    value: fields.BenefitStartDateSource,
                    setter,
                    opts: { disabled: props.readOnly },
                  },
                  'Where the start date for each member is generated from'
                )}
                {renderSelectFieldWithTooltip(
                  {
                    name: 'BenefitEndDateSource',
                    label: 'Benefit End Date Source',
                    items: Models.EligibilityFileConfig.benefitStartEndOpts,
                    value: fields.BenefitEndDateSource,
                    setter,
                    opts: { disabled: props.readOnly },
                  },
                  'Where the end date for each member is generated from'
                )}
                {renderSelectFieldWithTooltip(
                  {
                    name: 'BenefitEndDateDefinition',
                    label: 'Benefit End Date Definition',
                    items:
                      Models.EligibilityFileConfig.benefitEndDateDefinitionOpts,
                    value: fields.BenefitEndDateDefinition,
                    setter,
                    opts: { disabled: props.readOnly },
                  },
                  'How ZERO uses the benefit end date to determine when coverage ends'
                )}
                {renderSelectFieldWithTooltip(
                  {
                    name: 'BenefitEndDateCalculationForOmission',
                    label: 'Benefit End Date for Omission',
                    items:
                      Models.EligibilityFileConfig
                        .benefitEndDateCalculationForOmissionOpts,
                    value: fields.BenefitEndDateCalculationForOmission,
                    setter,
                    opts: { disabled: props.readOnly },
                  },
                  'What end date should be used for members omitted from an eligibility file'
                )}
              </Grid>
              <Grid item xs={4}>
                {renderNumericField({
                  name: 'ParserVersion',
                  label: 'Parser Version',
                  value: fields.ParserVersion,
                  setter,
                  opts: { disabled: props.readOnly },
                })}
                {renderSelectFieldWithTooltip(
                  {
                    name: 'BenefitExpirationDefinition',
                    label: 'Benefit Expiration Definition',
                    items:
                      Models.EligibilityFileConfig
                        .benefitExpirationDefinitionOpts,
                    value: fields.BenefitExpirationDefinition,
                    setter,
                    opts: { disabled: props.readOnly },
                  },
                  'Explanation of when the benefits end for members once employment ends'
                )}
                {renderSelectFieldWithTooltip(
                  {
                    name: 'SubmissionFrequency',
                    label: 'Submission Frequency',
                    items: Models.EligibilityFileConfig.submissionFrequencyOpts,
                    value: fields.SubmissionFrequency,
                    setter,
                    opts: { disabled: props.readOnly },
                  },
                  'How often ZERO receives eligibility'
                )}
                {renderTextField({
                  name: 'FrequencyDetail',
                  label: 'Frequency Detail',
                  value: fields.FrequencyDetail,
                  setter,
                  opts: { disabled: props.readOnly },
                })}
                {renderSelectFieldWithTooltip(
                  {
                    name: 'SubmissionType',
                    label: 'Submission Type',
                    items: Models.EligibilityFileConfig.fileSubmissionOpts,
                    value: fields.SubmissionType,
                    setter,
                    opts: { disabled: props.readOnly },
                  },
                  'How ZERO recieves eligibility'
                )}
              </Grid>
              <Grid item xs={4}>
                {renderSwitchField({
                  name: 'IsActive',
                  label: 'Active',
                  value: fields.IsActive,
                  setter,
                  opts: { disabled: props.readOnly },
                })}
                <br />
                {renderSwitchField({
                  name: 'IncludeCobra',
                  label: 'Include Cobra',
                  value: fields.IncludeCobra,
                  setter,
                  opts: { disabled: props.readOnly },
                })}
                <br />
                {renderSwitchField({
                  name: 'IncludePPO',
                  label: 'Include PPO',
                  value: fields.IncludePPO,
                  setter,
                  opts: { disabled: props.readOnly },
                })}
                <br />
                {renderSwitchField({
                  name: 'IncludeHSA',
                  label: 'Include HSA',
                  value: fields.IncludeHSA,
                  setter,
                  opts: { disabled: props.readOnly },
                })}
                <br />
                {renderSwitchField({
                  name: 'IncludeRetirees',
                  label: 'Include Retirees',
                  value: fields.IncludeRetirees,
                  setter,
                  opts: { disabled: props.readOnly },
                })}
                <br />
                {renderSwitchField({
                  name: 'EmailsIncluded',
                  label: 'Emails Included',
                  value: fields.EmailsIncluded,
                  setter,
                  opts: { disabled: props.readOnly },
                })}
                <br />
                {renderSwitchField({
                  name: 'SeparateAccumulatorFile',
                  label: 'Has Separate Accumulator File',
                  value: fields.SeparateAccumulatorFile,
                  setter,
                  opts: { disabled: props.readOnly },
                })}
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {renderTextField({
                  name: 'ExampleFileName',
                  label: 'Example Filename',
                  value: fields.ExampleFileName,
                  setter,
                  opts: { disabled: props.readOnly },
                })}
                {renderTextField({
                  name: 'Regex',
                  label: 'Regex',
                  value: fields.Regex,
                  setter,
                  opts: { disabled: props.readOnly },
                })}
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                {renderNotesField({
                  name: 'OtherNotes',
                  label: 'Other Notes',
                  value: fields.OtherNotes,
                  setter,
                  opts: { disabled: props.readOnly },
                })}
              </Grid>
            </Grid>
          </fieldset>
        </DialogContent>
        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <Button onClick={handleClose(false)} color="secondary">
                Cancel
              </Button>
            }
            right={
              !!props.readOnly ? (
                <DesignSuite2023.Tooltip title="This configuration cannot be edited in Read Only mode. You can visit the employer to edit from there.">
                  <DesignSuite2023.Link
                    to={`/employer/${fields.EmployerID}`}
                    target="_blank">
                    View Employer
                  </DesignSuite2023.Link>
                </DesignSuite2023.Tooltip>
              ) : (
                <Button
                  onClick={handleSave}
                  color="primary"
                  disabled={!canSave()}>
                  Save
                </Button>
              )
            }
          />
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    getError: state.employers.getEligibilityFileConfigError,
    saveResult: state.employers.eligSaveResult,
    eligibilityFileConfigDetail: state.employers.eligibilityFileConfigDetail,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      getNewEligibilityFileConfig,
      getEligibilityFileConfig,
      postEligibilityFileConfig,
      putEligibilityFileConfig,
      setSnackbarMessage,
      clearSaveResult,
    },
    dispatch
  )
}

// @ts-ignore
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEligibilityFileConfigDetails)
