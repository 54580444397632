/*
useHotKeys is a hook for helping register hotkey commands within a component. Search
for its usages for examples.

IMPORTANT DETAIL: this could be simplified by registering event listeners on a one off
basis per invocation of the hook, BUT - that would mean multiple handlers could fire for
a single hot key press. IOW - pretend you have three components open on the page that each
listen for 'CMD+K': if you press those keys, they'd both fire... which in many cases, fine,
no biggie. But in zclaims, not so much... Think about the case where a ZClaim detail view
embeds a table of potential duplicate zclaims, and *those* claims can open using the same
claim detail view. In this sense, the claims detail view is recursive - it embeds other 
components that embed instances of itself:

  ZClaimDetail1
    -> ZClaimDetail2
      -> ZClaimDetail3

Now think about having the stack of claims open to ZClaimDetail3: if you hit CMD+K (which lets
pretend that means "send to processing"), it'll accidentally fire that even on the other
two open zclaim details... bad news.

Anyways - this hook uses a global registration pattern and single bound 'keydown' event
listener, then delegates to the most-recently-registered handler of the hotkey. So whichever
component is last registered steals the event. This is a bit of a hack in how React maestros
might do it, but it works well for our use case. TBD, could also be a great way to cause
memory leaks ¯\_(ツ)_/¯.
*/
import React, { useLayoutEffect } from 'react'

interface shapeBindTo extends Partial<Event> {
  metaKey?: boolean // "command" key on Mac
  shiftKey?: boolean
  key: string
}

const _store: any = {
  handlers: {} as any,
}

window.addEventListener('keydown', (ev) => {
  const rKey = registrationKey(ev)
  const lastInChainID = _store[rKey]?.[_store[rKey]?.length - 1]
  if (!lastInChainID) return
  if (!_store.handlers[lastInChainID]) return
  _store.handlers[lastInChainID]?.(ev)
})

export default function useHotKeys(
  bindTo: shapeBindTo,
  handler: any,
  deps: any[] = []
): any {
  // @ts-ignore
  const id = React.useId()
  const rKey = React.useMemo(() => registrationKey(bindTo), [bindTo])

  useLayoutEffect(() => {
    if (!_store[rKey]) {
      _store[rKey] = []
    }
    _store[rKey].push(id)
    return () => {
      _store[rKey].splice(-1, 1)
    }
  }, [id, rKey])

  useLayoutEffect(() => {
    delete _store.handlers[id]
    _store.handlers[id] = (ev: any) => {
      if (bindTo.metaKey && !ev.metaKey) return
      if (bindTo.shiftKey && !ev.shiftKey) return
      if (ev.key !== bindTo.key) return
      ev.preventDefault()
      handler?.(ev)
    }
    return () => {
      delete _store.handlers[id]
    }
  }, [id, rKey, handler, ...deps])
}

function registrationKey(v: shapeBindTo): string {
  return `${v.metaKey ? 'meta' : 'nometa'}.${v.shiftKey ? 'shift' : 'noshift'}.${v.key}`
}
