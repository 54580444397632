import React, { useState, useEffect } from 'react'
import { EmployerActions } from '../../actions'
import { renderSelectField, SetterArgs } from '../ViewHelpers'
import styled from 'styled-components'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { EmployerSearcher } from '../../components/Searchers'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import dateTime from '../../utils/dateTime'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
import DesignSuite2023 from '../../components/DesignSuite2023'
const { getEmployerDivisions, addNewEmployerMember } = EmployerActions

interface AddEmployerProps {
  memberID: number
  handleClose: (edited: boolean) => void
}

interface EmployerMemberFields {
  ActivationDate: string
  DivisionID: number
}

interface Division {
  Name: string
  ID: number
}

interface Item {
  label: string
  value: number
}

const DialogContainer = styled(DialogContent)`
  min-width: 600px;
`

const AddNewEmployerSearcherContainer = styled.div`
  margin-top: 16px;
`

const defaultFields: EmployerMemberFields = {
  ActivationDate: dateTime.now().format(dateTime.formats.AmericanDate),
  DivisionID: 0,
}

export default function DialogAddEmployer({
  memberID,
  handleClose,
}: AddEmployerProps) {
  const [fields, setFields] = useState<EmployerMemberFields>(defaultFields)
  const [divisions, setDivisions] = useState<Array<Division>>([])
  const [employerID, setEmployerID] = useState<number>(0)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (!employerID) return setDivisions([])
    getEmployerDivisions(employerID)
      .then((res: Array<Division>) => {
        setDivisions(res)
        if (res.length === 1) {
          const defaultDivision = res[0]
          setFields({
            ...fields,
            DivisionID: defaultDivision?.ID,
          })
        }
      })
      .catch(
        catchAPIError({
          defaultMessage: `Failed fetching employer divisions; please contact Engineering`,
        })
      )
  }, [employerID])

  const clearAndClose = (edited: boolean) => {
    handleClose(edited)
    setEmployerID(0)
    setDivisions([])
    setFields(defaultFields)
    setIsOpen(false)
  }

  const setter = ({ name, value }: SetterArgs) => {
    const newFields = Object.assign({}, fields, { [name]: value })
    setFields(newFields)
  }

  const handleSave = () => {
    const newFields = Object.assign({}, fields, {
      ActivationDate: fields.ActivationDate,
    })
    addNewEmployerMember({ employerID, memberID }, newFields)
      .then(() => clearAndClose(true))
      .catch(
        catchAPIError({
          defaultMessage: `Failed fetching employer divisions; please contact Engineering`,
        })
      )
  }

  const isFormInvalid = () => {
    if (
      !employerID ||
      !fields.ActivationDate ||
      !fields.DivisionID ||
      !memberID
    )
      return true
    return false
  }

  const formatDivisions = (divisions: Array<Division>) => {
    const itemsArray: Array<Item> = []
    divisions.forEach((division) =>
      itemsArray.push({ label: division.Name, value: division.ID })
    )
    return itemsArray
  }

  return (
    <>
      <Button
        variant="contained"
        fullWidth
        color="primary"
        onClick={() => setIsOpen(true)}>
        Add New Employer
      </Button>

      <Dialog open={isOpen} onClose={() => clearAndClose(false)}>
        <DialogTitle id="form-dialog-title">
          Add New Employer to Member
        </DialogTitle>
        <DialogContainer>
          <Grid container direction="column">
            <ManagedDateInput
              name="ActivationDate"
              label="Start Date"
              value={fields.ActivationDate}
              setter={setter}
            />
            <AddNewEmployerSearcherContainer>
              <EmployerSearcher
                selectedEmployerID={employerID}
                onChange={(id: number) => setEmployerID(id)}
              />
            </AddNewEmployerSearcherContainer>
            {employerID && divisions
              ? renderSelectField({
                  name: 'DivisionID',
                  label: 'Division',
                  items: formatDivisions(divisions),
                  setter,
                  value: fields.DivisionID,
                })
              : null}
            {!divisions ? (
              <DialogContentText>
                This employer has no divisions! Please add a division before
                linking this employer to a member
              </DialogContentText>
            ) : null}
            {employerID ? (
              <Alert severity="info">
                You will need to run SQL to manually update these fields for the
                new employer member: coverage_level, relationship,
                pepm_employer_indicator, is_subscriber
              </Alert>
            ) : null}
          </Grid>
        </DialogContainer>
        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <Button
                onClick={() => clearAndClose(false)}
                color="secondary"
                variant="outlined">
                Cancel
              </Button>
            }
            right={
              <Button
                color="primary"
                variant="contained"
                onClick={handleSave}
                disabled={isFormInvalid()}>
                Save
              </Button>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
