import React, { useCallback, useEffect, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import * as model from '../../models/Loa'
import * as loaActions from '../../actions/LoaActions'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import useDiffChangedFields from '../../hooks/useDiffChangedFields'
import LOAStatusPicker from './LOAStatusPicker'
import LOAReasonPicker from './LOAReasonPicker'
import LOAReferralPicker from './LOAReferralPicker'
import * as FilesTable from './FilesTable'
import FileUploadModal from '../Files/FileUploadModal'
import ConfirmDialog from '../../components/ConfirmDialog'
import { useConfig } from '../../providers/Config'

import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {
  renderNotesField,
  RenderPriceField,
  renderSwitchField,
  renderTextField,
  SetterArgs,
} from '../ViewHelpers'
import dateTime from '../../utils/dateTime'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
import {
  CostKeySearcher,
  OrganizationSearcherV2,
} from '../../components/Searchers'
import DesignSuite2023 from '../../components/DesignSuite2023'

const defaultLOA: model.LOARecordShape = {
  ID: null,
  CreatedAt: null,
  UpdatedAt: null,
  DeletedAt: null,
  ContractDate: null,
  DateOfService: null,
  Price: null,
  ReferralID: null,
  TypicalPPOPrice: null,
  BundleContents: null,
  EmployerId: null,
  OrganizationId: null,
  EmployerMemberId: null,
  StatusId: null,
  Reason: null,
  CostKeyId: null,
  MainCptCode: null,
  Notes: null,
  IsSplit: null,
  CreatedByUserID: null,
  ModifiedByUserID: null,
}

const loaEntityType = Object.freeze([FilesTable.FileTypeEntities.LOA])

export default function LOADetail({
  match,
  history,
  location,
}: RouteComponentProps<{ id: string }>): React.ReactElement | null {
  const id = match.params.id ? Number(match.params.id) : null
  const [queryParamIntegrationID] = useState<string | null>(
    new URLSearchParams(location.search).get('IntegrationId') || null
  )
  const [queryParamReferralID] = useState<string | null>(
    new URLSearchParams(location.search).get('ReferralId') || null
  )
  const [record, setRecord] = useState<model.LOADecoratedShape>({
    ...defaultLOA,
  })
  const [notFound, setNotFound] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const { catchAPIError } = useErrorHandlers()
  const { show: showSnackbar } = useSnackbar()
  const refLOATable: any = useRef(null)
  const changeTracker = useDiffChangedFields(record, model.diffChangedFields)
  const { referralAppLink } = useConfig()

  useEffect(() => {
    if (!id) return
    loadLOA(id)
  }, [id])

  useEffect(() => {
    if (!queryParamIntegrationID) return
    loaActions
      .getNewEmptyLOAByIntegrationID(queryParamIntegrationID)
      .then((res) => {
        if (res.error) throw res
        setRecord((curr: model.LOADecoratedShape) => ({
          ...curr,
          ...(res.Data || {}),
        }))
      })
      .catch(
        catchAPIError({
          defaultMessage: `Failed generating new empty LOA for integrationID: '${queryParamIntegrationID}'`,
        })
      )
  }, [queryParamIntegrationID])

  useEffect(() => {
    if (!queryParamReferralID) return
    loaActions
      .getNewEmptyLOAByReferralID(queryParamReferralID)
      .then((res) => {
        if (res.error) throw res
        setRecord((curr: model.LOADecoratedShape) => ({
          ...curr,
          ...(res.Data || {}),
        }))
      })
      .catch(
        catchAPIError({
          defaultMessage: `Failed generating new LOA for referralID: '${queryParamReferralID}'`,
        })
      )
  }, [queryParamIntegrationID])

  function setter({ name, value }: SetterArgs) {
    setRecord((curr: model.LOADecoratedShape) => ({ ...curr, [name]: value }))
  }

  function loadLOA(id: number) {
    loaActions
      .getLOA(id)
      .then((res) => {
        if (res.error) throw res
        setRecord(res.Data)
        changeTracker.setInitialForDiff(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading or refreshing LOA',
          withError: (err: any) => {
            if (err?.Error?.StatusCode === 404) {
              setNotFound(true)
            }
          },
        })
      )
  }

  function handleSave() {
    if (record && record.ID) {
      return loaActions
        .putUpdateLOA(record)
        .then((res) => {
          if (res.error) throw res
          setRecord(res.Data)
          changeTracker.setInitialForDiff(res.Data)
          showSnackbar('LOA Saved OK', SnackbarTypeSuccess)
        })
        .catch(catchAPIError({ defaultMessage: 'Failed saving LOA' }))
    }
    return loaActions
      .postCreateLOA(record)
      .then((res) => {
        if (res.error) throw res
        history.push(`/loa/${res.Data.ID}`)
        showSnackbar('New LOA Saved', SnackbarTypeSuccess)
      })
      .catch(catchAPIError({ defaultMessage: 'Failed saving New LOA' }))
  }

  function handleDeleteLOA() {
    if (!record.ID) return
    ConfirmDialog({
      content: `You are about to delete this LOA for ${model.getMemberName(record)}. Continue?`,
      onConfirm() {
        loaActions
          // @ts-ignore
          .deleteLOA(record.ID)
          .then((res) => {
            if (res.error) throw res
            showSnackbar('LOA deleted OK', SnackbarTypeSuccess)
            history.push('/loas')
          })
          .catch(
            catchAPIError({
              defaultMessage:
                'Failed deleting LOA. Please contact engineering.',
            })
          )
      },
    })
  }

  function handleDeleteFiles() {
    if (!record.ID) return
    ConfirmDialog({
      content: 'You are about to delete files attached to this LOA. Continue?',
      onConfirm() {
        if (!record.ID) return
        loaActions
          .deleteLOAFiles(
            record.ID,
            selectedFiles.map((f: any) => f.ID)
          )
          .then((res: any) => {
            if (res && res.error) throw res
            showSnackbar('LOA files deleted OK', SnackbarTypeSuccess)
            refLOATable.current?.refresh()
            // @ts-ignore (note will have been changed by the backend, so refresh the LOA)
            loadLOA(record.ID)
          })
          .catch(
            catchAPIError({
              defaultMessage:
                'Failed deleting LOA files. Please contact engineering.',
            })
          )
      },
    })
  }

  function apiGetLoaFiles(params: any): Promise<any> {
    if (!record.ID)
      return Promise.reject(new Error('Cannot load files for unsaved LOA'))
    return loaActions.getLOAFiles(record.ID, params)
  }

  const postFile = useCallback(
    function (params: any): Promise<any> {
      if (!record.ID)
        return Promise.reject(new Error('Cannot load files for unsaved LOA'))
      return loaActions.postLOAFile(record.ID, params).then((res: any) => {
        return res.Data
      })
    },
    [record]
  )

  if (notFound) {
    return (
      <DesignSuite2023.AlertError>
        LOA could not be found
      </DesignSuite2023.AlertError>
    )
  }

  const calcSavings = () => {
    if (!record.TypicalPPOPrice || !record.Price) return ''
    const savings = Number(record.TypicalPPOPrice) - Number(record.Price)
    return `${savings.toFixed(2).toString()} (${((savings / Number(record.TypicalPPOPrice)) * 100).toFixed(0)}%)`
  }

  return (
    <>
      <DesignSuite2023.LayoutContainer>
        <DesignSuite2023.LayoutPrimary>
          <Grid container justify="space-between">
            <Grid item xs={12} md="auto">
              <Typography variant="h5">
                {!record.ID && 'New'} LOA for{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${window.location.origin}/member/${record.Member?.ID}`}>
                  {model.getMemberName(record)}
                </a>
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                <strong>DOB:</strong>{' '}
                {dateTime.parse(record.Member?.Dob).format()} |{' '}
                <strong>IntegrationID:</strong> {record.IntegrationID}
              </Typography>
            </Grid>
            <Grid item xs={12} md="auto">
              <LOAStatusPicker
                selectedID={record.StatusId}
                onChange={(statusID: number | null) => {
                  setter({ name: 'StatusId', value: statusID })
                }}
              />
            </Grid>
          </Grid>

          <DesignSuite2023.Divider style={{ marginTop: '0.5rem' }} />

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                disabled
                fullWidth
                variant="outlined"
                size="small"
                label="Employer"
                value={record?.Employer?.Name || ''}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DesignSuite2023.CommonIcons.IconLock fontSize="small" />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="View employer details">
                        <IconButton
                          size="small"
                          edge="end"
                          component="a"
                          href={`/employer/${record?.Employer?.ID}`}
                          target="_blank">
                          <DesignSuite2023.CommonIcons.IconOpenInNew fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
              <div
                style={{
                  display: 'flex',
                  fontSize: '90%',
                  paddingTop: '0.35rem',
                }}>
                <div style={{ whiteSpace: 'nowrap', paddingRight: '1rem' }}>
                  <Typography variant="caption" color="textSecondary">
                    LOA Process Type
                  </Typography>
                  <Typography variant="body2">
                    {record?.Employer?.LOAProcessType}
                  </Typography>
                </div>
                <div style={{ flex: 1 }}>
                  <div style={{ width: '100%' }}>
                    <Typography variant="caption" color="textSecondary">
                      PHA Notes
                    </Typography>
                    <pre
                      style={{
                        fontSize: 'inherit',
                        wordWrap: 'normal',
                        wordBreak: 'break-word',
                        whiteSpace: 'pre-line',
                        background: 'transparent',
                        border: 0,
                        padding: 0,
                      }}>
                      {record?.Employer?.PHANotes || '(None)'}
                    </pre>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item container spacing={2} justify="space-between" xs={12}>
              <Grid item xs={9}>
                <OrganizationSearcherV2
                  selectedOrganizationID={record.OrganizationId}
                  onChange={(ID: any, rec: any) => {
                    setter({ name: 'OrganizationId', value: ID })
                    setter({
                      name: 'Organization',
                      value: {
                        ID,
                        Name: rec?.Name || null,
                      },
                    }) // @todo: drop these things?
                  }}
                />
              </Grid>
              <Grid item>
                {renderSwitchField({
                  name: 'IsSplit',
                  // @ts-ignore
                  label: (
                    <div>
                      Split Loa{' '}
                      <Tooltip title="Some LOAs are split between two providers/organizations. If you select this, please add details to the notes.">
                        <DesignSuite2023.CommonIcons.IconInfo
                          style={{ height: '15px', width: '15px' }}
                        />
                      </Tooltip>
                    </div>
                  ),
                  setter,
                  value: !!record.IsSplit,
                })}
              </Grid>
            </Grid>

            {record?.EmployerMemberId ? (
              <Grid item container spacing={2} justify="space-between" xs={12}>
                <Grid item xs={9}>
                  <LOAReferralPicker
                    employerMemberIDs={[record.EmployerMemberId]}
                    selectedID={record.ReferralID}
                    onChange={(referralID: number | null) => {
                      setter({ name: 'ReferralID', value: referralID })
                    }}
                  />
                </Grid>
                {record?.ReferralID ? (
                  <Grid
                    item
                    container
                    alignItems="center"
                    justify="flex-end"
                    xs={3}>
                    <Typography>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={`${referralAppLink}/referral/${record.ReferralID}`}>
                        View Referral
                        <IconButton size="small">
                          <DesignSuite2023.CommonIcons.IconOpenInNew
                            fontSize="small"
                            htmlColor="#337ab7"
                          />
                        </IconButton>
                      </a>
                    </Typography>
                  </Grid>
                ) : null}
              </Grid>
            ) : null}

            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12} md={9}>
                <LOAReasonPicker
                  selectedReason={record.Reason}
                  onChange={(reason: string | null) => {
                    setter({ name: 'Reason', value: reason })
                  }}
                />
              </Grid>
            </Grid>

            <Grid item container spacing={2} xs={12}>
              <Grid item xs={6}>
                {/* { renderDateField({ name: 'ContractDate', label: 'Contract Date', setter, value: record.ContractDate || ''})}  */}
                <ManagedDateInput
                  name="ContractDate"
                  label="Contract Date"
                  value={record.ContractDate}
                  setter={setter}
                />
              </Grid>
              <Grid item xs={6}>
                {/* { renderDateField({ name: 'DateOfService', label: 'Date of Service', setter, value: record.DateOfService || ''})}  */}
                <ManagedDateInput
                  name="DateOfService"
                  label="Date of Service"
                  value={record.DateOfService}
                  setter={setter}
                />
              </Grid>
            </Grid>

            <Grid item container spacing={2} xs={12}>
              <Grid item xs={4}>
                {RenderPriceField({
                  name: 'Price',
                  label: 'Price',
                  setter,
                  value: record.Price || '',
                  opts: {
                    variant: 'outlined',
                    size: 'small',
                    margin: 'none',
                  },
                })}
              </Grid>
              <Grid item xs={4}>
                {RenderPriceField({
                  name: 'TypicalPPOPrice',
                  label: 'Typical PPO Price',
                  setter,
                  value: record.TypicalPPOPrice || '',
                  opts: {
                    variant: 'outlined',
                    size: 'small',
                    margin: 'none',
                  },
                })}
              </Grid>
              <Grid item xs={4}>
                {renderTextField({
                  name: 'Savings',
                  label: 'Savings',
                  setter,
                  value: calcSavings(),
                  disabled: true,
                  opts: {
                    variant: 'outlined',
                    size: 'small',
                    margin: 'none',
                  },
                })}
              </Grid>
            </Grid>

            <Grid item container spacing={2} xs={12}>
              <Grid item xs={12} md={6}>
                <CostKeySearcher
                  selectedCostKeyID={record.CostKeyId}
                  onChange={(ID: any, rec: any) => {
                    setter({ name: 'CostKeyId', value: ID })
                    setter({
                      name: 'CostKey',
                      value: {
                        ID,
                        Code: rec?.Code || null,
                        Descr: rec?.Descr || null,
                      },
                    }) // @todo: drop these things?
                  }}
                  TextFieldProps={{
                    helperText: record.CostKey?.Descr,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {renderTextField({
                  name: 'MainCptCode',
                  label: 'Main CPT Code',
                  setter,
                  value: record.MainCptCode || '',
                  opts: {
                    variant: 'outlined',
                    size: 'small',
                    margin: 'none',
                  },
                })}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {renderNotesField({
                name: 'BundleContents',
                label: 'Bundle Contents',
                setter,
                value: record.BundleContents || '',
                opts: {
                  variant: 'outlined',
                  size: 'small',
                  margin: 'none',
                },
              })}
            </Grid>

            <Grid item xs={12}>
              {renderNotesField({
                name: 'Notes',
                label: 'Notes',
                setter,
                value: record.Notes || '',
                opts: {
                  variant: 'outlined',
                  size: 'small',
                  margin: 'none',
                },
              })}
            </Grid>
          </Grid>
        </DesignSuite2023.LayoutPrimary>

        <DesignSuite2023.LayoutSidebar>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSave}>
            Save
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={handleDeleteLOA}>
            Delete
          </Button>
          <Button
            fullWidth
            size="small"
            onClick={() => {
              history.push('/loas')
            }}>
            Back to LOAs
          </Button>

          {record.ID && (
            <>
              <DesignSuite2023.Divider />
              <DesignSuite2023.DisplayRecordMeta record={record} />
              <changeTracker.DisplayChangedFields>
                <DesignSuite2023.Divider />
              </changeTracker.DisplayChangedFields>
            </>
          )}
        </DesignSuite2023.LayoutSidebar>
      </DesignSuite2023.LayoutContainer>

      {!!record?.ID && (
        <>
          <DesignSuite2023.Section>
            <DesignSuite2023.StyledTabs value={1}>
              <DesignSuite2023.StyledTab value={1} label="Files" />
            </DesignSuite2023.StyledTabs>

            <FilesTable.Table
              ref={refLOATable}
              apiEndpoint={apiGetLoaFiles}
              onCheckHandler={(files: any) => {
                setSelectedFiles(files)
              }}
              FileEditModalProps={{
                entityType: loaEntityType,
              }}
              LeftHeaderItems={
                <>
                  <FilesTable.StandardFilterSearch />
                  &nbsp;&nbsp;
                  <FilesTable.FilterFileType entityTypes={loaEntityType} />
                </>
              }
              RightHeaderItems={
                <>
                  {!!selectedFiles.length && (
                    <>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={handleDeleteFiles}>
                        Delete Files
                      </Button>
                      &nbsp;&nbsp;
                    </>
                  )}
                  <FileUploadModal
                    entityType={loaEntityType}
                    FileUploadProps={{
                      apiPostFile: postFile,
                      multiple: true,
                      onSuccess() {
                        refLOATable.current?.refresh()
                      },
                    }}
                  />
                </>
              }
            />
          </DesignSuite2023.Section>
        </>
      )}

      {/* helpful for debugging */}
      {/* <pre>{JSON.stringify(record, null, '  ')}</pre> */}
    </>
  )
}
