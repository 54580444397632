import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import DetailView from '../../components/DetailView/DetailView'
import get from 'lodash/get'
import uncamelcase from 'lodash/startCase'
import validatorFuncs from './validationFuncs'
import { preTransformFuncs } from './transformFuncs'
import dataTypers from './dataTypers'
import styles from './styles'

const validatorNames = Object.keys(validatorFuncs)
const dataTyperNames = Object.keys(dataTypers)
const preTransformNames = Object.keys(preTransformFuncs)

const colors = {
  true: '#4de47e',
  false: '#f7747e',
  yellow: '#fff550',
}

export class CsvParser extends DetailView {
  constructor(props) {
    super(props)

    this.state = this.initialState = {
      viewParserConfig: false,
      showPreview: false,
      originalFileName: null,
      parsedCSVRows: [],
      parsedCSVHeaders: [],
      mappableProperties: Object.keys(props.objectTemplate),
      mappings: {},
      excludeRows: {},
    }
  }

  UNSAFE_componentWillMount = () => {
    if (this.props.initialData && this.props.initialData.data) {
      this.setState({
        mappings: get(this.props, 'initialData.parserConfig.mappings', {}),
        excludeRows: get(
          this.props,
          'initialData.parserConfig.excludeRows',
          {}
        ),
      })
    }
  }

  doParse = (e) => {
    if (!e.target.files.length) {
      return
    }
    const self = this
    const originalFileName = e.target.files[0].name
    parse(
      e.target.files[0],
      Object.assign(
        {},
        /* this.parserOpts, */ {
          complete(results) {
            const mappings = Object.keys(self.state.mappings).length
              ? self.state.mappings
              : {}
            const excludeRows = Object.keys(self.state.excludeRows).length
              ? self.state.excludeRows
              : {}
            self.setState({
              originalFileName,
              parsedCSVRows: results.data,
              parsedCSVHeaders: results.data[0],
              mappings,
              excludeRows,
            })
          },
        }
      )
    )
  }

  getAssembledPayload = () => {
    const {
      originalFileName,
      excludeRows,
      mappableProperties,
      mappings,
      parsedCSVHeaders,
    } = this.state
    const assembled = this.state.parsedCSVRows
      .filter((_, i) => {
        if (excludeRows[i]) {
          return false
        }
        return true
      })
      .map((row) => {
        const obj = {}
        for (let i = 0; i < mappableProperties.length; i++) {
          let v = null
          // If no mapper exists, the value is left as null
          const mapper = mappings[mappableProperties[i]]
          if (mapper) {
            // A type handler is required, if not set, value WILL REMAIN NULL
            const typeHandler = dataTypers[mapper.typeHandler]
            if (!typeHandler) {
              continue
            }

            let val = row[mapper.csvColIndex]

            const pres = mapper.preTransforms || []
            for (let i = 0; i < pres.length; i++) {
              val = preTransformFuncs[pres[i]].run(val)
            }

            const casted = typeHandler.parser(val)
            if (casted.ok) {
              v = casted.result
            }
            const vldtrs = mapper.validators || []
            if (vldtrs.length) {
              for (let i = 0; i < vldtrs.length; i++) {
                if (validatorFuncs[vldtrs[i]].test(casted) !== true) {
                  v = null
                }
              }
            }
          }
          obj[mappableProperties[i]] = v
        }
        return obj
      })
    return {
      originalFileName,
      parserConfig: {
        mappings,
        excludeRows,
        parsedCSVHeaders,
      },
      data: assembled,
    }
  }

  onComplete = () => {
    this.props.onComplete(this.getAssembledPayload())
  }

  render() {
    const { showPreview } = this.state

    return (
      <div>
        <div
          style={{
            display: 'block',
            width: '100%',
            textAlign: 'center',
            padding: '1rem',
            background: '#e1e1e1',
          }}>
          <input
            ref="fileInput"
            type="file"
            name="csvparser"
            accept="text/csv"
            onChange={this.doParse}
            style={{ display: 'inline-block' }}
          />
          <button onClick={this.togglePreview}>Preview</button>
          <button onClick={this.onComplete}>Done</button>
        </div>

        {showPreview ? (
          <pre>{JSON.stringify(this.getAssembledPayload(), null, 4)}</pre>
        ) : (
          <div>
            <div
              style={{ position: 'fixed', bottom: 0, right: 0, zIndex: 99999 }}>
              <button onClick={this.toggleViewParserConfig}>
                View Parser Config
              </button>
              <pre
                style={{
                  margin: 0,
                  paddingRight: '2rem',
                  display: this.state.viewParserConfig ? 'block' : 'none',
                }}>
                {JSON.stringify(
                  {
                    mappings: this.state.mappings,
                    excludeRows: this.state.excludeRows,
                  },
                  null,
                  4
                )}
              </pre>
            </div>

            <table style={{ width: '100%' }}>
              <thead className={this.props.classes.tableHeading}>
                <tr>{this.renderHeaderColumns()}</tr>
                <tr>{this.renderMappableProperties()}</tr>
                <tr>{this.renderSelectDataType()}</tr>
                <tr>{this.renderPreTransformSettings()}</tr>
                <tr>{this.renderFilterSettings()}</tr>
              </thead>
              <tbody className={this.props.classes.tableBody}>
                {this.renderTableBody()}
              </tbody>
            </table>
          </div>
        )}
      </div>
    )
  }

  togglePreview = () => {
    this.setState({
      showPreview: !this.state.showPreview,
    })
  }

  toggleViewParserConfig = () => {
    this.setState({
      viewParserConfig: !this.state.viewParserConfig,
    })
  }

  renderTableBody = () => {
    const self = this
    if (!Object.keys(self.state.mappings).length) {
      return (
        <tr>
          <td
            colSpan={self.state.mappableProperties.length + 2}
            style={{ padding: '1rem', textAlign: 'center' }}>
            Starting by selecting a file and mapping CSV columns above...
          </td>
        </tr>
      )
    }
    const cellOrder = self.state.mappableProperties.reduce((accu, f, i) => {
      const mapped = self.state.mappings[f]
      accu[i] = mapped ? mapped.csvColIndex : false
      return accu
    }, {})
    const makeRow = (row, rowIndex) => {
      const cellRowNumber = (
        <td key="rowNumberCell">
          <span className={this.props.classes.rowNumber}>{rowIndex}</span>
        </td>
      )
      const cellRowAction = (
        <td key="rowActionCell">
          <span
            onClick={this.setExcludeRow.bind(this, rowIndex)}
            className={this.props.classes.removeValidatorBtn}>
            &minus;
          </span>
        </td>
      )
      return [cellRowNumber, cellRowAction].concat(
        self.state.mappableProperties.map((fieldName, i) => {
          if (cellOrder[i] === false) {
            return <td key={i} />
          }
          return self.processCell(i, fieldName, row[cellOrder[i]])
        })
      )
    }
    return this.state.parsedCSVRows.map((row, i) => {
      return (
        <tr
          key={i}
          className={
            this.state.excludeRows[i]
              ? this.props.classes.styleExcludeRow
              : null
          }>
          {makeRow(row, i)}
        </tr>
      )
    })
  }

  processCell = (colIndex, fieldName, val) => {
    // If we get here, a mapper should always exist
    const mapper = this.state.mappings[fieldName]
    // Type caster (should always be set otherwise don't process)
    const typeHandler = dataTypers[mapper.typeHandler]
    let cellValidationColor = '#f1f1f1'

    if (!typeHandler) {
      return (
        <td key={colIndex} style={{ background: cellValidationColor }}>
          {val}
        </td>
      )
    }

    const pres = mapper.preTransforms || []
    for (let i = 0; i < pres.length; i++) {
      val = preTransformFuncs[pres[i]].run(val)
    }

    const casted = typeHandler.displayable(val)

    // Check validators
    const vldtrs = mapper.validators || []
    cellValidationColor = '#b3f7c9'
    for (let i = 0; i < vldtrs.length; i++) {
      if (validatorFuncs[vldtrs[i]].test(casted) !== true) {
        cellValidationColor = '#f7747e'
        break
      }
    }

    let castFeedbackColor = colors[casted.ok]
    if (casted.result === null) {
      castFeedbackColor = colors.yellow
    }

    return (
      <td key={colIndex} style={{ background: cellValidationColor }}>
        {[
          <span key="typevalue">{casted.show}</span>,
          <span
            key="typename"
            className={this.props.classes.typeCastedIndicator}
            style={{ background: castFeedbackColor }}>
            {mapper.typeHandler}
          </span>,
        ]}
      </td>
    )
  }

  setExcludeRow = (rowIndex) => {
    if (this.state.excludeRows[rowIndex]) {
      const copy = this.state.excludeRows
      delete copy[rowIndex]
      this.setState({ excludeRows: copy })
      return
    }
    this.setState({
      excludeRows: Object.assign(this.state.excludeRows, {
        [rowIndex]: true,
      }),
    })
  }

  renderHeaderColumns = () => {
    return [<th key="spacer" colSpan="2" />].concat(
      this.state.mappableProperties.map((v) => {
        return (
          <th key={v}>
            <span
              style={{ display: 'block', fontWeight: 'bold', fontSize: '85%' }}>
              {v}
            </span>
          </th>
        )
      })
    )
  }

  renderMappableProperties = () => {
    return [<th key="spacer" colSpan="2" />].concat(
      this.state.mappableProperties.map((v) => {
        return <th key={v}>{this.renderCSVColumnOptions(v)}</th>
      })
    )
  }

  renderSelectDataType = () => {
    const self = this
    return [<th key="spacer" colSpan="2" />].concat(
      this.state.mappableProperties.map((mappableProperty) => {
        const opts = [
          <option key={null} value="_">
            Data Type
          </option>,
        ]
        const changeHandler = (e) => {
          if (self.state.mappings[mappableProperty]) {
            const mapper = self.state.mappings[mappableProperty]
            if (e.target.value === '_') {
              delete mapper.typeHandler
            } else {
              mapper.typeHandler = e.target.value
            }
            self.setState({
              mappings: Object.assign(self.state.mappings, {
                [mappableProperty]: mapper,
              }),
            })
          }
        }
        const mapped = self.state.mappings[mappableProperty]
          ? self.state.mappings[mappableProperty].typeHandler
          : null
        dataTyperNames.forEach((casterName) => {
          opts.push(
            <option key={casterName} value={casterName}>
              {uncamelcase(casterName)}
            </option>
          )
        })
        const dataTyperList = (
          <select onChange={changeHandler} defaultValue={mapped}>
            {opts}
          </select>
        )

        return <th key={mappableProperty}>{dataTyperList}</th>
      })
    )
  }

  renderPreTransformSettings = () => {
    return [<th key="spacer" colSpan="2" />].concat(
      this.state.mappableProperties.map((v) => {
        return (
          <th key={v}>
            {this.renderPreTransformPicker(v)}
            {this.renderEnabledPreTransformers(v)}
          </th>
        )
      })
    )
  }

  renderFilterSettings = () => {
    return [<th key="spacer" colSpan="2" />].concat(
      this.state.mappableProperties.map((v) => {
        return (
          <th key={v}>
            {this.renderAddValidatorPicker(v)}
            {this.renderEnabledValidators(v)}
          </th>
        )
      })
    )
  }

  renderCSVColumnOptions = (mappableProperty) => {
    const self = this
    const opts = [<option key={null}>---</option>]
    const changeHandler = (e) => {
      self.setState({
        mappings: Object.assign(self.state.mappings, {
          [mappableProperty]: {
            csvColIndex: parseInt(e.target.value, 10),
          },
        }),
      })
    }
    const mapped = self.state.mappings[mappableProperty]
      ? self.state.mappings[mappableProperty].csvColIndex
      : null
    this.state.parsedCSVHeaders.forEach((v, i) => {
      opts.push(
        <option key={v} value={i}>
          {v}
        </option>
      )
    })
    return (
      <select onChange={changeHandler} defaultValue={mapped}>
        {opts}
      </select>
    )
  }

  renderEnabledPreTransformers = (mappableProperty) => {
    const self = this
    const trfrs = get(
      this.state.mappings[mappableProperty],
      'preTransforms',
      []
    )
    if (!trfrs.length) {
      return
    }
    return trfrs.map((name) => {
      return (
        <span key={name} className={this.props.classes.enabledValidator}>
          <i
            onClick={self.removePreTransformer.bind(
              self,
              mappableProperty,
              name
            )}
            className={this.props.classes.removeValidatorBtn}>
            &times;
          </i>
          {preTransformFuncs[name].label}
        </span>
      )
    })
  }

  removePreTransformer = (mappableProperty, vName) => {
    const mapper = this.state.mappings[mappableProperty]
    mapper.preTransforms.splice(mapper.preTransforms.indexOf(vName), 1)
    const mappings = this.state.mappings
    mappings[mappableProperty] = mapper
    this.setState({ mappings })
  }

  renderPreTransformPicker = (mappableProperty) => {
    const self = this
    const opts = [
      <option key={null} value="_">
        PreTransformers
      </option>,
    ]
    const changeHandler = (e) => {
      if (e.target.value === '_') {
        return
      }
      if (self.state.mappings[mappableProperty]) {
        const mapper = self.state.mappings[mappableProperty]
        mapper.preTransforms = mapper.preTransforms ? mapper.preTransforms : []
        // if the transformer already exists in the list, don't re-add it
        if (mapper.preTransforms.indexOf(e.target.value) !== -1) {
          return
        }
        mapper.preTransforms.push(e.target.value)
        self.setState({
          mappings: Object.assign(self.state.mappings, {
            [mappableProperty]: mapper,
          }),
        })
        e.target.value = '_' // this is a hack; bad idea to mutate DOM nodes in react directly but it works (Jon's fault)
      }
    }
    preTransformNames.forEach((transName) => {
      const mapper = self.state.mappings[mappableProperty]
      // If a validator is already enabled, stop showing it as an option in the list
      if (
        mapper &&
        mapper.preTransforms &&
        mapper.preTransforms.indexOf(transName) !== -1
      ) {
        return
      }
      opts.push(
        <option key={transName} value={transName}>
          {preTransformFuncs[transName].label}
        </option>
      )
    })
    return <select onChange={changeHandler}>{opts}</select>
  }

  renderEnabledValidators = (mappableProperty) => {
    const self = this
    const vldtrs = get(this.state.mappings[mappableProperty], 'validators', [])
    if (!vldtrs.length) {
      return
    }
    return vldtrs.map((name) => {
      return (
        <span key={name} className={this.props.classes.enabledValidator}>
          <i
            onClick={self.removeValidator.bind(self, mappableProperty, name)}
            className={this.props.classes.removeValidatorBtn}>
            &times;
          </i>
          {validatorFuncs[name].label}
        </span>
      )
    })
  }

  removeValidator = (mappableProperty, vName) => {
    const mapper = this.state.mappings[mappableProperty]
    mapper.validators.splice(mapper.validators.indexOf(vName), 1)
    const mappings = this.state.mappings
    mappings[mappableProperty] = mapper
    this.setState({ mappings })
  }

  renderAddValidatorPicker = (mappableProperty) => {
    const self = this
    const opts = [
      <option key={null} value="_">
        Validators
      </option>,
    ]
    const changeHandler = (e) => {
      if (e.target.value === '_') {
        return
      }
      if (self.state.mappings[mappableProperty]) {
        const mapper = self.state.mappings[mappableProperty]
        mapper.validators = mapper.validators ? mapper.validators : []
        // if the validator already exists in the list, don't re-add it
        if (mapper.validators.indexOf(e.target.value) !== -1) {
          return
        }
        mapper.validators.push(e.target.value)
        self.setState({
          mappings: Object.assign(self.state.mappings, {
            [mappableProperty]: mapper,
          }),
        })
        e.target.value = '_' // this is a hack; bad idea to mutate DOM nodes in react directly but it works (Jon's fault)
      }
    }
    validatorNames.forEach((validatorName) => {
      const mapper = self.state.mappings[mappableProperty]
      // If a validator is already enabled, stop showing it as an option in the list
      if (
        mapper &&
        mapper.validators &&
        mapper.validators.indexOf(validatorName) !== -1
      ) {
        return
      }
      opts.push(
        <option key={validatorName} value={validatorName}>
          {validatorFuncs[validatorName].label}
        </option>
      )
    })
    return <select onChange={changeHandler}>{opts}</select>
  }
}

CsvParser.propTypes = {
  objectTemplate: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
  initialData: PropTypes.object,
}

export default withStyles(styles)(CsvParser)

let _parser
function parse(item, opts = {}) {
  if (!_parser) {
    // this require syntax dynamically includes from the require (eg. "just in time")
    _parser = new Promise((res, rej) => {
      require(['papaparse'], res)
    })
  }
  _parser.then((pp) => {
    pp.parse(item, opts)
  })
}
