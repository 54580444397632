import axios from 'axios'
import ConfirmDialog from '../../components/ConfirmDialog'

// we need this for testing
// perhaps we should use redux and wrap everything in the redux provider
const BASE_URL =
  localStorage.getItem('admin_base_url') || 'https://local.zero.health'
const DEFAULT_SORT_PARAMS = []
const DEFAULT_RANGE_PARAMS = { page: 1, pageSize: 50 }
const axiosClient = axios.create({
  baseUrl: BASE_URL,
})

export var url = BASE_URL

// TODO: this needs to be updated
// see: https://axios-http.com/docs/interceptors
axiosClient.interceptors.response.use(
  // Success statuses handler (2XX range)
  function (response) {
    return apiResponse(response)
  },
  // Other statuses handler (any non 2XX range)
  function (error) {
    if (error && error.response && error.response.status === 401) {
      if (window.location.href.includes('redirectUrl')) {
        return Promise.reject({
          error: true,
          Error: {
            DetailedMessage: 'Unauthorized',
            Message: 'Unauthorized',
            StatusCode: 401,
          },
        })
      }

      // window.location.assign(`/?redirectUrl=${window.location.href}`)
      return Promise.reject({
        error: true,
        Error: {
          DetailedMessage: 'Unauthorized',
          Message: 'Unauthorized',
          StatusCode: 401,
        },
      })
    }

    if (!error.response) {
      if (axiosClient.customNetworkDownHandler !== undefined)
        return axiosClient.customNetworkDownHandler(error.config)
      return Promise.reject({
        Error: { DetailedMessage: 'Network Down' },
        Meta: { RequestId: 'Unknown' },
        error: true,
      })
    }

    console.error('Detailed error for debugging: ', error.response.data)

    if (
      error &&
      error.response &&
      error.response.headers &&
      error.response.headers['x-api-format'] === 'v2'
    ) {
      // note: the reason this is mixed up with different casing and seemingly inconsistent formatting is to be backwards
      // compatible with old v1 style route responses; once we have all backend routes converted to V2, we can update this.
      return Promise.reject({
        error: true,
        RequestId: error.response.headers['x-request-id'],
        Error: {
          Message:
            (error.response.data.Error && error.response.data.Error[0]) ||
            'An error occurred',
          StatusCode: error.response.status,
        },
        // Only things hitting v2 route handlers can expect to have the ErrData property, if applicable. This is intentionally named
        // different than just 'err' or 'error' so as to be easy to find usages during refactoring. Scenario: it's perfectly valid for
        // a route to want to return a 400 level response code, but with more verbose information the front-end can use. For example,
        // during two-step error resolution, where the UI presents information about an error that can be acted on by the next step in
        // the UI. See EligibilityDetail for an example.
        ErrData: error.response?.data?.Data || null,
        errList: error.response.data.Error,
      })
    }

    return {
      ...error.response.data,
      error: true,
    }
  }
)

function apiResponse(res) {
  const { data, ...resNoData } = res
  const Data = data.Data || data.data

  return {
    Data,
    Meta: data.Meta || null,
    ...resNoData,
  }
}

function computeRange(page, pageSize) {
  let recordStart = 1
  if (page !== 1) recordStart = (page - 1) * pageSize + 1

  return JSON.stringify([recordStart, page * pageSize])
}

export function get(url, params, encodableParams) {
  let requestUrl = BASE_URL + url

  if (!!encodableParams) {
    const base64d = btoa(JSON.stringify(encodableParams))
    params = Object.assign({}, params || {}, {
      _encoded: base64d,
    })
  }

  if (params) {
    requestUrl += '?' + queryParams(params)
  }

  const issueRequest = (nextURI = null) => {
    return axiosClient.get(nextURI || requestUrl, getBaseConfig())
  }

  return wrapConfirmationRequired(issueRequest)
}

export function search(url, params) {
  let requestUrl = BASE_URL + url
  if (!params) {
    throw `Cannot execute a "search" query with no params`
  }
  const { page, pageSize, ...qsParams } = params

  if (params && params.filter && !params.filter.q) {
    params.filter.q = ''
  }
  if (params && params.filter) qsParams.filter = JSON.stringify(params.filter)
  if (params && params.sort) {
    qsParams.sort = JSON.stringify(params.sort)
  } else {
    qsParams.sort = JSON.stringify(DEFAULT_SORT_PARAMS)
  }
  if (page && pageSize) {
    qsParams.range = computeRange(page, pageSize)
  } else {
    qsParams.range = computeRange(
      DEFAULT_RANGE_PARAMS.page,
      DEFAULT_RANGE_PARAMS.pageSize
    )
  }

  if (params) {
    requestUrl += '?' + queryParams(qsParams)
  }

  const issueRequest = (nextURI = null) => {
    return axiosClient.get(nextURI || requestUrl, getBaseConfig())
  }

  return wrapConfirmationRequired(issueRequest)
}

// @todo: this is used by one thing; convert to PUT and remove this
// note: doesn't include confirmation workflow because we want to get rid of this
export function patch(url, body) {
  const requestUrl = BASE_URL + url
  return axiosClient.patch(requestUrl, body, getBaseConfig())
}

export function post(url, body) {
  const requestUrl = BASE_URL + url
  const issueRequest = (nextURI = null) => {
    return axiosClient.post(nextURI || requestUrl, body, getBaseConfig())
  }
  return wrapConfirmationRequired(issueRequest)
}

export function postFormData(url, fd) {
  const requestUrl = BASE_URL + url
  const issueRequest = (nextURI = null) => {
    return axiosClient({
      method: 'post',
      url: nextURI || requestUrl,
      data: fd,
      headers: getBaseConfig({
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).headers,
    })
  }
  return wrapConfirmationRequired(issueRequest)
}

export function put(url, body) {
  const requestUrl = BASE_URL + url
  const issueRequest = (nextURI = null) => {
    return axiosClient.put(nextURI || requestUrl, body, getBaseConfig())
  }
  return wrapConfirmationRequired(issueRequest)
}

// body is optional
export function del(url, body) {
  const requestUrl = BASE_URL + url
  const issueRequest = (nextURI = null) => {
    const config = getBaseConfig()
    if (body !== undefined) {
      config.data = body
    }
    return axiosClient.delete(nextURI || requestUrl, config)
  }
  return wrapConfirmationRequired(issueRequest)
}

function getBaseConfig(
  merge = {
    // These are the options for the merge object below, but this isn't
    // typescript so getBaseConfig could receive whatever as an argument...
    // this is what *should* be sent though:
    // headers: {},
  }
) {
  const headers = Object.assign(
    {
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('id_token')}`,
    },
    merge?.headers || {}
  )

  return { headers }
}

export function queryParams(params) {
  return Object.keys(params)
    .map((k) => {
      let value = params[k]
      if (value && typeof value === 'string') {
        value = value.trim()
      }
      if (typeof value !== 'object') {
        return encodeURIComponent(k) + '=' + encodeURIComponent(value)
      }

      return null
    })
    .join('&')
}

/*
This is a wrapper for all API interactions that (in effect) supports replaying
a request, if the API replies with a known status code 207 and {Data:{ConfirmationRequired:true}}.
Frequently, we have interactions where a request is made and the backend may have a
condition that definitely isn't an error, but would have effects the user needs to be
aware of (iow, the backend can't make an assumption to do the thing, without the user
being aware).
*/
function wrapConfirmationRequired(issueRequest) {
  return issueRequest().then((res) => {
    // If a *very* specific set of criteria comes through in reply, then we trigger the
    // confirmation workflow...
    if (
      res.status === 207 &&
      res.Data?.ConfirmationRequired &&
      !!res.Data?.NextURI
    ) {
      return new Promise((resolve, reject) => {
        ConfirmDialog({
          title: 'Confirmation Required',
          content: res.Data?.Message,
          onConfirm() {
            issueRequest(res.Data?.NextURI).then(resolve).catch(reject)
          },
          onCancel() {
            const msg = 'User declined confirmation; action not performed.'
            // Reject with a shape that mimics how our axios interceptor transforms an error
            reject({
              error: true,
              RequestId: res.headers['x-request-id'],
              Error: { Message: msg },
              errList: [msg],
            })
          },
        })
      })
    }

    // ... but by default (99% of the time), act as a pass through
    // and just return the response
    return res
  })
}
