import React, { useEffect, useRef, useState } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import {
  postInboundPreStageProcess,
  updateInboundSFTPs,
} from '../../../actions/EngineeringActions'
import styled from 'styled-components'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import * as TableEligInbound from './TableEligInbound'
import { IgnoreDialog } from './IgnoreDialog'
import { Button } from '@material-ui/core'

const StyledRunReport = styled.div`
  padding-top: 0.5rem;

  .MuiAlert-root {
    margin-bottom: 0.5rem;
    font-size: 90%;
    &:last-of-type {
      margin: 0;
    }
    ul {
      margin: 0;
      padding-left: 1rem;
    }
  }
`

export default function ViewInbound({
  setCurrentTab,
}: any): React.ReactElement {
  const [selected, setSelected] = useState([])
  const [selectedInboundIDs, setSelectedInboundIDs] = useState([] as number[])
  const [ignoreDialogOpen, setIgnoreDialogOpen] = useState(false)
  const [inboundsRefreshed, setInboundsRefreshed] = useState(false)
  const [isProcessingInbounds, setIsProcessingInbounds] = useState(false)
  const [runReport, setRunReport] = useState<any>(null)
  const refTable = useRef<any>(null)
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    setSelected([])
    setRunReport(null)
    setInboundsRefreshed(false)
    doRefreshInbounds()
  }, [])

  function doRefreshInbounds() {
    return updateInboundSFTPs()
      .catch(
        catchAPIError({
          defaultMessage: 'Failed refreshing inbound eligibility',
        })
      )
      .finally(() => {
        setInboundsRefreshed(true)
      })
  }
  function doProcess() {
    if (isProcessingInbounds) {
      return
    }

    setIsProcessingInbounds(true)
    postInboundPreStageProcess({
      UseIDs: selected.map((v: any) => v.ID),
      // DryRun: true // @future: backend already supports dry runs which will return the final output CSV
    })
      .then((res: any) => {
        setRunReport(res)
        refTable.current?.refresh()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed processing inbound eligibility',
        })
      )
      .finally(() => {
        setIsProcessingInbounds(false)
      })
  }

  function openIgnoreDialog() {
    const ids = selected.map((sel: any) => sel.ID as number)
    setSelectedInboundIDs(ids)
    setIgnoreDialogOpen(true)
  }

  function onCloseIgnoreDialog() {
    setIgnoreDialogOpen(false)
    setSelectedInboundIDs([])
    setSelected([])
    doRefreshInbounds().then(() => {
      refTable.current?.refresh()
    })
  }

  if (!inboundsRefreshed) {
    return (
      <div style={{ textAlign: 'center' }}>
        <DesignSuite2023.LoadingSpinner />
      </div>
    )
  }

  return (
    <>
      {runReport && (
        <StyledRunReport>
          {runReport.Data?.Results?.map((rr: any) => {
            if (rr.Error) {
              return (
                <DesignSuite2023.AlertError key={rr.Input?.S3Key}>
                  {rr.Input?.S3Key} failed
                  <br />
                  {rr.Error}
                </DesignSuite2023.AlertError>
              )
            }
            if (rr.Warnings) {
              return (
                <DesignSuite2023.AlertWarning key={rr.Input?.S3Key}>
                  {rr.Input?.S3Key} passed through with warnings.
                  <br />
                  <ul>
                    {rr.Warnings.map((w: string, index: number) => (
                      <li key={`${rr.Input?.S3Key}.${index}`}>{w}</li>
                    ))}
                  </ul>
                </DesignSuite2023.AlertWarning>
              )
            }
            return null
            // SD-2025: don't need to see the success notification here (leaving for future reference
            // if helpful)
            // return <DesignSuite2023.AlertSuccess key={rr.Input?.S3Key}>
            //   {rr.Input?.S3Key} passed through OK.<br/>
            // </DesignSuite2023.AlertSuccess>
          })}
        </StyledRunReport>
      )}

      <TableEligInbound.Table
        ref={refTable}
        onCheckHandler={setSelected}
        LeftHeaderItems={
          <TableEligInbound.StandardFilterSearch
            autoFocus
            tooltip="Searches S3 Bucket + Key"
          />
        }
        RightHeaderItems={
          <>
            {!!selected.length && (
              <>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={openIgnoreDialog}>
                  Ignore
                </Button>
                &nbsp;
                <Button color="primary" variant="outlined" onClick={doProcess}>
                  Stage and Match Files{' '}
                  {isProcessingInbounds && (
                    <>
                      &nbsp;
                      <DesignSuite2023.LoadingSpinner size={15} />
                    </>
                  )}
                </Button>
              </>
            )}
            {runReport && (
              <>
                &nbsp;
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {
                    setCurrentTab(2)
                  }}>
                  View Staged
                </Button>
              </>
            )}
          </>
        }
      />
      {ignoreDialogOpen && (
        <IgnoreDialog
          open={ignoreDialogOpen}
          selected={selectedInboundIDs}
          onClose={onCloseIgnoreDialog}
          onCancel={() => setIgnoreDialogOpen(false)}
        />
      )}
    </>
  )
}
