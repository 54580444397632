import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import * as EligibilityTable from '../../../Eligibility/EligibilityTable2'
import DesignSuite2023 from '../../../../components/DesignSuite2023'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeWarning } from '../../../../hooks/useSnackbar'
import {
  getZClaimAssignmentPatient,
  putZClaimPatientOrSubscriber,
} from '../../../../actions/ZClaimActions'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Grid,
} from '@material-ui/core'
import DisplayKeyValueData from '../DisplayKeyValueData'
import styled from 'styled-components'
import { pick } from 'lodash'
import dateTime from '../../../../utils/dateTime'

const StyledTitle = styled(DialogTitle)`
  text-transform: capitalize;
`

const StyledContent = styled(DialogContent)`
  .MuiAlert-root {
    margin-bottom: 1rem;
  }

  .selected-member-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
    white-space: nowrap;

    .small-label {
      display: block;
      font-weight: bold;
    }
  }

  .tbl-dependents {
    .base-table-display {
      margin-top: 0;
    }
  }

  .MuiTable-root {
    min-width: 100% !important;

    .MuiTableCell-root {
      width: auto !important;
      white-space: nowrap;

      &:last-of-type {
        width: 99% !important;
      }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    max-width: 75%;
    margin: 0 auto;
    li {
      margin: 0;
      padding: 0;
    }
  }
`

const eligColumns = Object.freeze({
  ...pick(EligibilityTable.defaultColumns, [
    'Name',
    'DOB',
    'IsCurrentlyActive',
    'IsSubscriber',
    'EmployerName',
  ]),
  _memberPage: {
    name: '',
    details: {
      dataFormat(_: any, row: any): React.ReactElement {
        return (
          <Link
            to={`/member/${row.MemberID}?employerMemberID=${row.EmployerMemberID}`}
            target="_blank"
            onClick={(ev: any) => ev.stopPropagation()}>
            <DesignSuite2023.CommonIcons.IconOpenInNew fontSize="inherit" />{' '}
            Member Page
          </Link>
        )
      },
    },
  },
})

interface props {
  zClaimID: number
  mode: 'patient' | 'subscriber'
  onComplete?(): void
}

export default function AssignPatientOrSubscriber({
  zClaimID,
  mode,
  onComplete,
}: props): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState<any>(null)
  const [selectedEM, setSelectedEM] = useState<any>(null)
  const [selectedIsRelated, setSelectedIsRelated] = useState(false)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  useEffect(() => {
    if (!isOpen) {
      setData(null)
      setSelectedEM(null)
      setSelectedIsRelated(false)
      return
    }
    loadInfo()
  }, [isOpen])

  function loadInfo() {
    if (!zClaimID) return
    getZClaimAssignmentPatient(zClaimID)
      .then((res: any) => {
        setData(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to load required info',
        })
      )
  }

  function getMessages(): any {
    if (!data) return null
    let msgs = []
    if (mode === 'patient') {
      // if (!data?.Current?.SrcPatient?.HasSufficientInfo) {
      //   msgs.push(
      //     <DesignSuite2023.AlertWarning key='patient.2'>
      //       <span>There was no patient info on the claim, so the system automatically assigned the subscriber as the patient upon claim ingestion. This is not uncommon, and is a normal way to send claims.</span>
      //     </DesignSuite2023.AlertWarning>
      //   )
      // }

      if (!data?.Current?.PatientInfo?.EmployerMemberID) {
        msgs.push(
          <DesignSuite2023.AlertWarning key="msg.1">
            <span>There is no patient currently assigned on this claim.</span>
            {data?.Current?.SubscriberInfo?.EmployerMemberID ? (
              <>
                &nbsp;
                <span>
                  The linked subscriber on this claim is{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`/member/${data.Current.SubscriberInfo.MemberID}?employerMemberID=${data.Current.SubscriberInfo.EmployerMemberID}`}>
                    {data.Current.SubscriberInfo.Name} (employer:{' '}
                    {data.Current.SubscriberInfo.EmployerName})
                  </a>
                  .
                </span>
              </>
            ) : (
              <>
                &nbsp;<span>There is no linked subscriber on this claim.</span>
              </>
            )}
          </DesignSuite2023.AlertWarning>
        )
      } else {
        msgs.push(
          <DesignSuite2023.AlertInfo key="msg.2">
            <span>
              Currently assigned patient:{' '}
              <strong>{data?.Current?.PatientInfo?.Name}</strong>
              &nbsp;(employer: {data?.Current?.PatientInfo?.EmployerName}).
            </span>
            {data?.Current?.PatientIsSubscriber && (
              <>
                &nbsp;
                <span style={{ textDecoration: 'underline' }}>
                  Patient &amp; subscriber are the same.
                </span>
              </>
            )}
          </DesignSuite2023.AlertInfo>
        )
      }
    }

    return <>{msgs}</>
  }

  function onClickSelectMember(row: any) {
    // console.log("picked_employer_member", row)
    if (mode === 'patient') {
      if (
        data?.Current?.PatientInfo.EmployerMemberID === row.EmployerMemberID
      ) {
        showSnackbar(
          `Member ${row.FirstName} ${row.LastName} already assigned as patient; nothing to do.`,
          SnackbarTypeWarning
        )
        return
      }
    }
    setSelectedEM(row)
  }

  function doSave() {
    // console.log('do save', selectedEM)
    putZClaimPatientOrSubscriber(zClaimID, {
      EmployerMemberID: selectedEM.EmployerMemberID,
      SetupDefaulters: true,
    })
      .then((res: any) => {
        setIsOpen(false)
        onComplete && onComplete()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Operation failed (please contact engineering)',
        })
      )
  }

  if (!zClaimID) return null

  // const patientIsSubscriber = data?.Current?.PatientIsSubscriber
  // const claimHasPatient = !!data?.Current?.PatientInfo?.EmployerMemberID
  // const claimHasSubscriber = !!data?.Current?.SubscriberInfo?.EmployerMemberID
  const sameEmployerAsSubscriber =
    selectedEM?.EmployerID === data?.Current?.SubscriberInfo?.EmployerID

  return (
    <>
      <DesignSuite2023.Tooltip
        title={`Assign ${mode} (and Subscriber gets set automatically as a result)`}>
        <IconButton
          size="small"
          onClick={() => setIsOpen(true)}
          style={{
            padding: '0.3rem',
            background: 'rgba(0,0,0,0.1)',
            fontSize: '110%',
          }}>
          <DesignSuite2023.CommonIcons.IconEdit fontSize="inherit" />
        </IconButton>
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen} maxWidth={false} onClose={() => setIsOpen(false)}>
        <StyledTitle>Assign Claim {mode}</StyledTitle>
        <StyledContent style={{ width: 1600 }}>
          {getMessages()}

          <Grid container spacing={2}>
            <Grid item xs="auto" style={{ width: 'min-content' }}>
              <DesignSuite2023.Section>
                <h4 style={{ marginTop: 0 }}>Patient Data From Claim</h4>
                {data?.SrcPatient?.Length === 0 ? (
                  <DesignSuite2023.AlertWarning>
                    <span>
                      There was no patient info on the claim. This is not
                      uncommon, and indicates the subscriber is the patient.
                    </span>
                  </DesignSuite2023.AlertWarning>
                ) : (
                  <DisplayKeyValueData
                    data={data?.SrcPatient}
                    style={{ marginBottom: '1rem' }}
                  />
                )}
                {!!data?.SrcPatientDefaulter?.EmployerMemberID ? (
                  data?.SrcPatientDefaulter?.EmployerMemberID ===
                  data?.Current?.PatientInfo?.EmployerMemberID ? (
                    <DesignSuite2023.AlertSuccess>
                      <span>
                        This source record has a default matched member, and it
                        is the same as the patient on this claim.
                      </span>
                    </DesignSuite2023.AlertSuccess>
                  ) : (
                    <DesignSuite2023.AlertWarning>
                      <span>
                        This source record has a default matched member,{' '}
                        <strong>
                          but the patient on the claim is different!
                        </strong>
                      </span>
                    </DesignSuite2023.AlertWarning>
                  )
                ) : (
                  data?.SrcPatient?.HasSufficientInfo && (
                    <DesignSuite2023.AlertWarning>
                      <span>
                        This source record does not have a default mapping to a
                        member.
                      </span>
                    </DesignSuite2023.AlertWarning>
                  )
                )}

                <DesignSuite2023.Divider />

                <h4>Subscriber Data From Claim</h4>
                <DisplayKeyValueData
                  data={data?.SrcSubscriber}
                  style={{ marginBottom: '1rem' }}
                />
                {!!data?.SrcSubscriberDefaulter?.EmployerMemberID ? (
                  data?.SrcSubscriberDefaulter?.EmployerMemberID ===
                  data?.Current?.SubscriberInfo?.EmployerMemberID ? (
                    <DesignSuite2023.AlertSuccess>
                      <span>
                        This source record has a default matched member, and it
                        is the same as the subscriber on this claim.
                      </span>
                    </DesignSuite2023.AlertSuccess>
                  ) : (
                    <DesignSuite2023.AlertWarning>
                      <span>
                        This source record has a default matched member,{' '}
                        <strong>
                          but the subscriber on the claim is different!
                        </strong>
                      </span>
                    </DesignSuite2023.AlertWarning>
                  )
                ) : (
                  <DesignSuite2023.AlertWarning>
                    <span>
                      This source record does not have a default mapping to a
                      member.
                    </span>
                  </DesignSuite2023.AlertWarning>
                )}
              </DesignSuite2023.Section>
            </Grid>

            <Grid
              item
              xs="auto"
              style={{ flex: 1, width: '100%', maxWidth: 1000 }}>
              <div style={{ display: !selectedEM ? 'block' : 'none' }}>
                {!!data?.Current?.SubscriberInfo?.EmployerMemberID && (
                  <div className="tbl-dependents">
                    <h5>
                      Members Associated With Currently-Assigned Subscriber
                      (including self)
                    </h5>
                    <EligibilityTable.Table
                      autoLoadData={true}
                      customColumns={eligColumns}
                      DataTableProps={{
                        density: 'small',
                        rowOptsApplier(row: any): any {
                          return {
                            style: {
                              background:
                                data?.Current?.PatientInfo?.EmployerMemberID ===
                                row.EmployerMemberID
                                  ? '#c9ecde'
                                  : null,
                            },
                          }
                        },
                      }}
                      showPagination={false}
                      passFilters={{
                        havingSubscriberIDs: [
                          data?.Current?.SubscriberInfo?.EmployerMemberID,
                        ],
                        disableLimit: true,
                      }}
                      onRowClick={(row: any) => {
                        setSelectedIsRelated(true)
                        onClickSelectMember(row)
                      }}
                    />
                  </div>
                )}

                <h5 style={{ margin: '1rem 0 -0.5rem' }}>All Member Search</h5>
                <EligibilityTable.Table
                  LeftHeaderItems={
                    <>
                      {/* use of the eligTableContext here is purely to support pressing Enter to search;
                  these component would work fine NOT wrapped in the context and <form> tags */}
                      <EligibilityTable.EligTableContext.Consumer>
                        {({ doSearch }: { doSearch(): void }) => (
                          <form
                            onSubmit={(ev: any) => {
                              ev.preventDefault()
                              ev.stopPropagation()
                              doSearch()
                            }}>
                            <EligibilityTable.FilterFirstName />
                            &nbsp;&nbsp;
                            <EligibilityTable.FilterLastName />
                            &nbsp;&nbsp;
                            <EligibilityTable.FilterIntegrationID />
                            &nbsp;&nbsp;
                            <EligibilityTable.FilterDateOfBirth
                              fullWidth={false}
                              margin="none"
                            />
                            &nbsp;&nbsp;
                            <EligibilityTable.FilterSSNLast4 />
                            &nbsp;&nbsp;
                            <EligibilityTable.FilterButtonDoSearch />
                          </form>
                        )}
                      </EligibilityTable.EligTableContext.Consumer>
                    </>
                  }
                  customColumns={eligColumns}
                  DataTableProps={{ density: 'small' }}
                  onRowClick={(row: any) => {
                    setSelectedIsRelated(false)
                    onClickSelectMember(row)
                  }}
                />
              </div>

              {selectedEM && (
                <>
                  <div
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      marginBottom: '1rem',
                    }}>
                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        margin: '0 auto',
                        textAlign: 'left',
                      }}>
                      <div style={{ paddingRight: '1rem' }}>
                        <Button
                          onClick={() => setSelectedEM(null)}
                          startIcon={
                            <DesignSuite2023.CommonIcons.IconArrowLeft />
                          }>
                          Back
                        </Button>
                      </div>
                      <div>
                        <DesignSuite2023.SubSection
                          title={`${selectedEM.FirstName} ${selectedEM.LastName}`}
                          subtitle={`Employer: ${selectedEM.EmployerName}`}
                          style={{ width: 'min-content', margin: '0 auto' }}>
                          <div className="selected-member-card">
                            <div>
                              <small className="small-label">DOB</small>
                              {selectedEM.DOB}
                            </div>
                            <div>
                              <small className="small-label">Address:</small>
                              {selectedEM.Address?.Display}
                            </div>
                            <div>
                              <small className="small-label">
                                Activation Date:
                              </small>
                              {dateTime
                                .parse(selectedEM.ActivationDate)
                                .format()}
                            </div>
                            <div>
                              <small className="small-label">
                                Deactivation Date:
                              </small>
                              {dateTime
                                .parse(selectedEM.DeactivationDate)
                                .format() || '-'}
                            </div>
                          </div>
                        </DesignSuite2023.SubSection>
                      </div>
                    </div>
                  </div>

                  <ul>
                    {!selectedIsRelated && (
                      <li>
                        <DesignSuite2023.AlertInfo>
                          This will change both the patient{' '}
                          <i>and the subscriber</i> on the claim.
                        </DesignSuite2023.AlertInfo>
                      </li>
                    )}
                    {!sameEmployerAsSubscriber && (
                      <li>
                        <DesignSuite2023.AlertWarning>
                          This change will cause the claim responsibility to be
                          transferred to employer:{' '}
                          <strong>{selectedEM.EmployerName}</strong>.
                        </DesignSuite2023.AlertWarning>
                      </li>
                    )}
                  </ul>
                  {/* <pre>{JSON.stringify(selectedEM, null, ' ')}</pre> */}
                </>
              )}
            </Grid>
          </Grid>
        </StyledContent>
        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                disabled={!selectedEM}
                color="primary"
                variant="contained"
                onClick={doSave}>
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}
