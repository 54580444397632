import React, { useState } from 'react'
import { postProcess } from '../../../actions/ZClaimFundingActions'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import { Button } from '@material-ui/core'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import ConfirmDialog from '../../../components/ConfirmDialog'

interface props {
  zClaimIDs: Array<number>
  onComplete?(): void
  disabled?: boolean
}

export default React.forwardRef(function ButtonDoProcess(
  { zClaimIDs, onComplete, disabled = false }: props,
  ref: any
): React.ReactElement {
  const [isWorking, setIsWorking] = useState(false)
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  function doProcess() {
    ConfirmDialog({
      title: 'Process Claims',
      content:
        'Are you sure you want to process the selected claims? Once this is done, **claims cannot be stopped from going all the way through invoicing; you will have to issue new claims for refunds and adjustments**.',
      onConfirm: () => {
        setIsWorking(true)
        postProcess({ ZClaimIDs: zClaimIDs })
          .then(() => {
            showSnackbar('Process completed OK', SnackbarTypeSuccess)
            onComplete?.()
          })
          .catch(
            catchAPIError({
              defaultMessage: 'Failed Process',
            })
          )
          .finally(() => setIsWorking(false))
      },
    })
  }

  const lenIDs = zClaimIDs?.length >= 2 ? `(${zClaimIDs.length})` : null

  return (
    <DesignSuite2023.Tooltip title="Process selected claims for invoicing">
      <span>
        <Button
          ref={ref}
          disabled={isWorking || disabled}
          fullWidth
          variant="contained"
          color="primary"
          size="small"
          onClick={doProcess}>
          Process {lenIDs}
          {isWorking && (
            <>
              &nbsp;
              <DesignSuite2023.LoadingSpinner size={20} show={isWorking} />
            </>
          )}
        </Button>
      </span>
    </DesignSuite2023.Tooltip>
  )
})
