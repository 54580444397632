import * as api from '../services/thezerocard/api-helper'
export const SAVE_FILE = 'SAVE_FILE'
export const SAVE_NEW_FILE = 'SAVE_NEW_FILE'
export const DELETE_FILE = 'DELETE_FILE'
export const GET_FILE_TYPES = 'GET_FILE_TYPES'
export const GET_COMPONENT_FEE_SCHEDULE_FILE_TYPES =
  'GET_COMPONENT_FEE_SCHEDULE_FILE_TYPES'
export const CLEAR_FILES = 'CLEAR_FILES'
export const CHECK_IF_FILE_EXISTS = 'CHECK_IF_FILE_EXISTS'
export const GET_FILE_CLAIMS = 'GET_FILE_CLAIMS'

export const getAllFileTypes = (params) => {
  return api
    .search('/file_type', params)
    .then((res) => {
      if (res.error)
        throw 'Failed to get File Types, please contact Engineering'
      return res.Data
    })
    .catch((err) => {
      throw err
    })
}

export const updateFileType = (params) => {
  return api
    .put(`/file_type/${params.ID}`, params)
    .then((res) => {
      if (res.error)
        throw 'Failed to update File Type, please contact Engineering'
      return res.Data
    })
    .catch((err) => {
      throw err
    })
}

export const saveNewFileType = (params) => {
  return api
    .post('/file_type', params)
    .then((res) => {
      if (res.error)
        throw 'Failed to save new File Type, please contact Engineering'
      return res.Data
    })
    .catch((err) => {
      throw err
    })
}

export const saveNewFile = (body) => {
  return {
    type: SAVE_NEW_FILE,
    payload: api.post('/file', body),
  }
}

export const postPresignedUploadURL = (params) => {
  return api.post('/presign_file_v2', params)
}

export const getPresignedUrlForDownload = (id) => {
  return api
    .get(`/file/${id}/presigned_download_url`)
    .then((res) => {
      if (res.error)
        throw 'Failed to get presigned url for download, please contact Engineering'
      return res.Data
    })
    .catch((err) => {
      throw err
    })
}

export const getFileClaims = () => {
  return {
    type: GET_FILE_CLAIMS,
    payload: api.get('/opp_analysis/file_claims'),
  }
}

export const clearFiles = () => {
  return {
    type: CLEAR_FILES,
  }
}

export const getFile = (id) => {
  return api.get(`/file/${id}`).then((res) => res.Data)
}

export const getFiles = (payload) => {
  return api.search(`/files-v2`, payload)
}

export const getFileTypes = (params) => {
  return api.search(`/file_type`, params).then((res) => res.Data)
}

export const deleteFiles = (payload) => {
  return api.del(`/file`, {
    FileIDs: payload,
  })
}

export const saveNewComponentFeeScheduleFile = (id, payload) => {
  return api
    .post(`/component_fee_schedule/${id}/file`, payload)
    .then((res) => res.Data)
}

export const saveFile = (body) => {
  const { ID } = body
  return api.put(`/file/${ID}`, body)
}
