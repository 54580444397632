import React from 'react'
import { Button, Paper } from '@material-ui/core'
import * as CostKeyTable2 from './CostKeyTable2'
import styled from 'styled-components'

const PaperWrapper = styled(Paper)`
  padding: 0.25rem 1rem 1rem;
`

export default function CostKeys({ history }) {
  const handleAddNew = () => {
    history.push(`/cost_key`)
  }

  return (
    <PaperWrapper elevation={2}>
      <CostKeyTable2.Table
        enableURLReflection
        LeftHeaderItems={<CostKeyTable2.StandardFilterSearch autoFocus />}
        RightHeaderItems={
          <>
            <Button variant="contained" color="primary" onClick={handleAddNew}>
              Add New
            </Button>
          </>
        }
      />
    </PaperWrapper>
  )
}
