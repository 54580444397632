import React, { useState, useRef } from 'react'
import DialogNewOrEditPlan from './DialogNewOrEditPlan'
import PlanList from './PlanList'
import Limits from './Limits'
import { Grid, IconButton } from '@material-ui/core'
import { ShapeEmployerPlan } from '../types'
import DesignSuite2023 from '../../../components/DesignSuite2023'

interface props {
  employerID: number
}

export default function EmployerPlanDetail({
  employerID,
}: props): React.ReactElement | null {
  const [employerPlan, setEmployerPlan] = useState<ShapeEmployerPlan>(
    {} as ShapeEmployerPlan
  )
  const refPlanList = useRef<any>()

  function onSelectedPlanChange(id: number | null, full?: ShapeEmployerPlan) {
    if (full) {
      setEmployerPlan(full)
      return
    }
    setEmployerPlan({} as ShapeEmployerPlan)
  }

  return (
    <div>
      <DesignSuite2023.AlertError style={{ marginBottom: '1.5rem' }}>
        <strong>Heads up!</strong> If you see this message, DO NOT CONFIGURE ANY
        EMPLOYER PLANS YET. They will likely be deleted/reset.
      </DesignSuite2023.AlertError>

      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <div style={{ minWidth: 280 }}>
            <PlanList
              ref={refPlanList}
              employerID={employerID}
              onChange={onSelectedPlanChange}
            />
          </div>
        </Grid>
        {!!employerPlan?.ID && (
          <Grid item>
            <DialogNewOrEditPlan
              employerID={employerID}
              trigger={(props: any) => (
                <IconButton
                  size="small"
                  onClick={() => props?.openEmployerPlanID(employerPlan?.ID)}>
                  <DesignSuite2023.CommonIcons.IconEdit />
                </IconButton>
              )}
              onSave={() => refPlanList.current?.refresh()}
            />
          </Grid>
        )}
        <Grid item>
          <DialogNewOrEditPlan
            employerID={employerID}
            onSave={() => refPlanList.current?.refresh()}
          />
        </Grid>
      </Grid>

      <Limits employerPlanID={employerPlan.ID} />
    </div>
  )
}
