import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
} from '@material-ui/core'
import { Stepper, Step } from '../../components/Stepper'
import { EligibilityActions } from '../../actions'
import { useDispatch } from 'react-redux'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import DesignSuite2023 from '../../components/DesignSuite2023'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
import dateTime from '../../utils/dateTime'

interface ExportEligibilityPayload
  extends ExportEligibilityTargets,
    ExportEligibilityMethods {}

interface ExportEligibilityMethods extends Record<string, boolean> {
  Test: boolean
  SFTPDefer: boolean
  SFTPSend: boolean
}

interface ExportEligibilityTargets extends Record<string, boolean> {
  VBA: boolean
  VBAUpdateOnly: boolean
  IsInternal: boolean
  Marketing: boolean
  SmartData: boolean
  Point6: boolean
  Quest: boolean
  RX: boolean
  HealthJoy: boolean
}

export const ExportEligibilityModal = () => {
  const [isOpen, setIsOpen] = useState(false)

  const initialTargets = {
    VBA: false,
    VBAUpdateOnly: true,
    IsInternal: true,
    Marketing: false,
    SmartData: true,
    Point6: true,
    Quest: true,
    RX: true,
    HealthJoy: true,
  }
  const initialMethods = {
    Test: false,
    SFTPDefer: false,
    SFTPSend: true,
  }
  const defaultPayload: ExportEligibilityPayload = Object.assign(
    {},
    initialMethods,
    initialTargets
  )

  const [eligibilityPayload, setEligibilityPayload] = useState(defaultPayload)
  const [valid, setValid] = useState(false)
  const dispatch = useDispatch()
  const { show: showSnackbar } = useSnackbar()

  const setPayload = (updates: any) => {
    const newPayload = { ...eligibilityPayload, ...updates }
    setEligibilityPayload(newPayload)
  }

  const submitPayload = (payload: ExportEligibilityPayload) => {
    const { taskExportEligibility } = EligibilityActions
    dispatch(taskExportEligibility(payload))
    showSnackbar('Elig Export Task Queued!', SnackbarTypeSuccess)
    setIsOpen(false)
  }

  const steps = [
    {
      initialLabel: 'Select export targets',
      completedLabel: 'Export targets',
      component: (
        <SelectExportTargetsStep
          initialTargets={initialTargets}
          onUpdate={setPayload}
        />
      ),
      isValid: () => true,
    },
    {
      initialLabel: 'Select export method',
      completedLabel: 'Export methods',
      component: (
        <SelectExportMethodsStep
          initialMethods={initialMethods}
          onUpdate={setPayload}
        />
      ),
      isValid: () => true,
    },
  ] as Step[]

  return (
    <>
      <DesignSuite2023.Tooltip placement="left" title="Launch workflow dialog">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsOpen(true)}>
          Eligibility Export
        </Button>
      </DesignSuite2023.Tooltip>

      <Dialog
        classes={{ paper: 'dialogRoot' }}
        open={isOpen}
        onClose={() => setIsOpen(false)}>
        <DialogTitle>Eligibility Export</DialogTitle>
        <DialogContent>
          <Stepper steps={steps} orientation={'vertical'} setValid={setValid} />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!valid}
            color="primary"
            onClick={() => submitPayload(eligibilityPayload)}>
            Submit
          </Button>
          <Button color="secondary" onClick={() => setIsOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const SelectExportMethodsStep = ({ initialMethods = {}, onUpdate }: any) => {
  const [exportMethods, setMethods] = useState(initialMethods)

  const toggleExportMethod = (key: string) => {
    const newData: ExportEligibilityMethods = { ...exportMethods }
    newData[key] = !exportMethods[key]
    setMethods(newData)
    onUpdate(newData)
  }

  return (
    <div>
      {Object.keys(exportMethods).map((key) => (
        <div key={key} className="checkBox">
          <Checkbox
            checked={exportMethods[key]}
            onChange={() => toggleExportMethod(key)}
          />
          {key}
        </div>
      ))}
    </div>
  )
}

const SelectExportTargetsStep = ({ initialTargets = {}, onUpdate }: any) => {
  const [exportTargets, setTargets] = useState(initialTargets)

  const toggleExportTarget = (key: string) => {
    const newData: ExportEligibilityTargets = { ...exportTargets }
    newData[key] = !exportTargets[key]
    setTargets(newData)
    onUpdate(newData)
  }

  return (
    <div>
      {Object.keys(exportTargets).map((key) => (
        <div key={key} className="checkBox">
          <Checkbox
            checked={exportTargets[key]}
            onChange={() => toggleExportTarget(key)}
          />
          {key === 'Point6' ? <p>98 Point 6</p> : <p>{key}</p>}
        </div>
      ))}
    </div>
  )
}

export const IngestEligibilityModal = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [ingestDate, setIngestDate] = useState(dateTime.now().format())
  const dispatch = useDispatch()
  const { show: showSnackbar } = useSnackbar()

  const submitPayload = () => {
    const { taskIngestEligibility } = EligibilityActions
    const formattedDate = dateTime.parse(ingestDate)
    console.debug('Formatted date: ', formattedDate)
    dispatch(taskIngestEligibility({ Date: formattedDate.format() }))
    showSnackbar('Elig Ingest Task Queued!', SnackbarTypeSuccess)
    setIsOpen(false)
  }

  return (
    <>
      <DesignSuite2023.Tooltip placement="left" title="Launch workflow dialog">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsOpen(true)}>
          Eligibility Ingest
        </Button>
      </DesignSuite2023.Tooltip>

      <Dialog
        classes={{ paper: 'dialogRoot' }}
        open={isOpen}
        onClose={() => setIsOpen(false)}>
        <DialogTitle>Eligibility Ingest</DialogTitle>
        <DialogContent>
          {/* <DateInput
          label='Ingest Date'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setIngestDate(e.target.value) }}
          name='IngestDate'
          value={ingestDate}
        /> */}
          <ManagedDateInput
            name="IngestDate"
            label="Ingest Date"
            value={ingestDate}
            setter={({ name, value }) => {
              setIngestDate(value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => submitPayload()}>
            Submit
          </Button>
          <Button color="primary" onClick={() => setIsOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
