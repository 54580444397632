import React, { forwardRef, useContext } from 'react'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import EmployerModel from '../../models/Employer'
import { getEmployers } from '../../actions/EmployerActions'
import { FormControlLabel, Switch } from '@material-ui/core'
// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../hooks/useStandardTableSetup'

export const defaultColumns = EmployerModel.columns

const defaultSort = { col: 'Employer.Name', dir: 'asc' }

export const Table = forwardRef(function EmployerTableList(
  {
    customColumns = defaultColumns,
    apiEndpoint = getEmployers,
    fnLinkOnRowClick = (row: any) => `/employer/${row.ID}`,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      fnLinkOnRowClick,
      DataTableProps: {
        ...DataTableProps,
        rowOptsApplier(row: any) {
          return !EmployerModel.isActive(row)
            ? { className: 'tr-warning' }
            : null
        },
      },
    },
    ref
  )

  return <>{TableDisplay}</>
})

// Filter by active/inactive status
// note: material ui's select component *sucks* for using any value={}'s that aren't
// a string... YMMV
export function FilterEmployerStatus(): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <FormControlLabel
      label="Show Inactive"
      control={
        <Switch
          color="primary"
          checked={!filter.active}
          onChange={(e: any) => {
            if (e.target.checked) {
              setFilterImmediate({ ...filter, isCustomer: null, active: null })
              return
            }
            setFilterImmediate({ ...filter, isCustomer: true, active: true })
          }}
        />
      }
    />
  )
}
