import React, { Component } from 'react'
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import WarningIcon from '@material-ui/icons/Warning'
import { withStyles } from '@material-ui/core/styles'
import { green, amber } from '@material-ui/core/colors'
import classNames from 'classnames'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { SnackbarActions } from '../../actions'
const { clearSnackbar } = SnackbarActions

const TYPES = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
}

const styles = (theme) => {
  return {
    success: {
      backgroundColor: green[600],
    },
    error: {
      backgroundColor: theme.palette.error.dark,
    },
    info: {
      backgroundColor: theme.palette.primary.dark,
    },
    warning: {
      backgroundColor: amber[700],
    },
    icon: {
      fontSize: 20,
    },
    iconType: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
  }
}

class snackMessage extends Component {
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    this.props.clearSnackbar()
  }

  render() {
    const { type, classes, message, open } = this.props
    const duration = type === TYPES.SUCCESS ? 4000 : 15000

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={duration}
        onClose={this.handleClose}>
        <SnackbarContentWrapper
          classes={classes}
          onClose={this.handleClose}
          type={type}
          message={message}
        />
      </Snackbar>
    )
  }
}

const typeIcons = {
  success: CheckCircleIcon,
  error: ErrorIcon,
  warning: WarningIcon,
}

class SnackbarContentWrapper extends Component {
  render() {
    const { classes, type, message, onClose } = this.props
    const Icon = typeIcons[type]

    if (!type || !message) return <div />
    return (
      <SnackbarContent
        className={classes[type]}
        message={
          <span className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconType)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            color="inherit"
            className={classes.close}
            onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    open: state.snackbar.open,
    type: state.snackbar.type,
    message: state.snackbar.message,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ clearSnackbar }, dispatch)
}
export const SnackMessage = compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(snackMessage)
