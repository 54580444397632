import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Grid,
} from '@material-ui/core'
import {
  InsertDriveFile as InsertDriveFileIcon,
  Description as IconDescription,
} from '@material-ui/icons'
import { SchmipitBody, SchmipitSubmission } from './types'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import * as FileUpload from '../../components/FileUpload'
import styled from 'styled-components'
import { postSchmipit } from '../../actions/SchmipitActions'
import { getFileTypes } from '../../actions/FileActions'
import DesignSuite2023 from '../../components/DesignSuite2023'

interface FileTypeData {
  FileTypeID: number
}

const StyledTextField = styled(TextField)`
  width: 100%;
  margin-bottom: 20px;
`

const LinkContainer = styled(Grid)`
  width: 100%;
  display: flex;
`

const StyledDialogContent = styled(DialogContent)`
  width: 600px;
`

export default function DialogCreateSnippet(): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [text, setText] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [UUID, setUUID] = useState<string>('')
  const [ID, setID] = useState<number>(0)
  const [schmipit, setSchmipit] = useState({} as SchmipitBody)
  const [fileData, setFileData] = useState<FileTypeData | null>(null)
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (isOpen) return
    setText('')
    setName('')
    setUUID('')
    setID(0)
    setSchmipit({} as SchmipitBody)
    setFileData(null)
  }, [isOpen])

  useEffect(() => {
    if (!isOpen || fileData) return
    const payload = { filter: { Hashes: ['snipit'] } }
    getFileTypes(payload).then((res: any) => {
      setFileData({ FileTypeID: res[0].ID })
    })
  }, [isOpen, fileData])

  const handleSubmit = () => {
    saveNewSchmipit({ Notes: text, File: { ID, UUID, OriginalName: name } })
  }

  const saveNewSchmipit = (body: SchmipitSubmission) => {
    postSchmipit(body)
      .then((res: any) => setSchmipit(res))
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to save new Schmipit; please contact Engineering',
        })
      )
  }

  const capturePresignedSchmipitData = (
    res: FileUpload.presignResponse,
    file: FileUpload.decoratedFile
  ) => {
    setName(file.domFileObj.name)
    setUUID(res.UUID || '')
  }

  const renderUploadFields = (): React.ReactElement | null => {
    if (!fileData) return null
    return (
      <StyledDialogContent>
        <DialogContentText>Notes:</DialogContentText>
        <StyledTextField
          value={text}
          onChange={(e: any) => setText(e.target.value)}
          multiline={true}
        />
        <FileUpload.Uploader
          defaultFileData={fileData}
          autoSign
          useUUIDs
          capturePresignResponse={(
            res: FileUpload.presignResponse,
            file: FileUpload.decoratedFile
          ) => {
            capturePresignedSchmipitData(res, file)
          }}
          CustomFileDisplay={(props: any) => {
            return (
              <FileUpload.DefaultSingleFileInput
                {...props}
                showInputs={false}
              />
            )
          }}
          onSuccess={(res: FileUpload.postFileResponse[]) => {
            setID(res[0].ID)
          }}
        />
      </StyledDialogContent>
    )
  }

  const renderSchmipitConfirmation = () => {
    const newClip = `https://${window.location.host}/schmipit/${schmipit.PublicID}`
    return (
      <StyledDialogContent>
        <DialogContentText>
          You can find it and share it with this link:
        </DialogContentText>
        <LinkContainer>
          <a
            target="_blank"
            rel="noreferrer"
            href={`/schmipit/${schmipit.PublicID}`}>
            {newClip}
          </a>
          <IconButton
            style={{ marginRight: 30 }}
            onClick={() => navigator.clipboard.writeText(newClip)}>
            <InsertDriveFileIcon />
          </IconButton>
        </LinkContainer>
      </StyledDialogContent>
    )
  }

  return (
    <>
      <DesignSuite2023.Tooltip title="New snippet">
        <IconButton color="inherit" onClick={() => setIsOpen(true)}>
          <IconDescription fontSize="inherit" />
        </IconButton>
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen} onClick={(e: any) => e.stopPropagation()}>
        <DialogTitle>
          {schmipit.PublicID
            ? 'Your Schmipit has been created!'
            : 'Create Schmipit'}
        </DialogTitle>
        {schmipit.PublicID
          ? renderSchmipitConfirmation()
          : renderUploadFields()}
        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <Button
                color="primary"
                variant="outlined"
                onClick={() => setIsOpen(false)}>
                Close
              </Button>
            }
            right={
              !schmipit.PublicID ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}>
                  Create Schmipit
                </Button>
              ) : null
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
