import React from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import styled from 'styled-components'
import * as shapes from '../types'
import dateTime from '../../../utils/dateTime'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { Typography, Button, Chip } from '@material-ui/core'

const StyledRelationships = styled(DesignSuite2023.Section)`
  padding: 0;

  .heading {
    margin: 0;
    padding: 0.5rem 1rem;
    background: #f1f1f1;
  }
  .btn-pad {
    padding: 1rem;
  }
  .block {
    display: block;
  }
  .red-text {
    color: firebrick;
  }
  a.related {
    display: block;
    padding: 1rem;
    border-left: 4px solid #ccc;

    &.active {
      border-left-color: rgb(30 233 182);
    }
    &.inactive {
      border-left-color: firebrick;
    }
  }
`

interface props {
  member: shapes.shapeMember | null
  employerMember: shapes.shapeEmployerMember | null
}

export default function DisplaySubscriberDependentRelationships({
  member,
  employerMember,
}: props): React.ReactElement | null {
  const deps = employerMember?.Dependents
  const isDep = employerMember?.Subscriber && employerMember?.Subscriber?.ID > 0
  const history = useHistory()

  function onClickAddDependent() {
    if (!member || !employerMember)
      return alert(
        'Operation failed; this is probably a bug. Please contact engineering.'
      )
    history.push({
      pathname: `/eligibility`,
      state: {
        subscriber: {
          EmployerID: employerMember.EmployerId,
          EmployerMemberID: employerMember.ID, // this is the EMPLOYER MEMBER ID
          FirstName: member.FirstName, //loGet(this.state, 'fields.FirstName'),
          LastName: member.LastName, //loGet(this.state, 'fields.LastName'),
          AddressID: member.AddressID, //loGet(this.state, 'fields.AddressID'),
          EmployerName: employerMember.EmployerName,
          CarrierName: employerMember.CarrierName,
          PlanName: employerMember.PlanName,
        },
      },
    })
  }

  if (isDep) {
    // if the employer member is itself a DEPENDENT, show subscriber's info
    return (
      <StyledRelationships>
        <Typography variant="subtitle1" className="heading">
          Subscriber
        </Typography>
        {employerMember?.Subscriber && (
          <DisplayRecord recordWithPersonInfo={employerMember.Subscriber} />
        )}
      </StyledRelationships>
    )
  }

  return (
    <StyledRelationships>
      <Typography variant="subtitle1" className="heading">
        Dependents
      </Typography>
      {!deps?.length ? (
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ margin: '1rem 1rem 0' }}>
          None
        </Typography>
      ) : (
        deps?.map((dep: shapes.shapeSubscriberOrDependentInfo) => (
          <DisplayRecord key={dep.ID} recordWithPersonInfo={dep} />
        ))
      )}
      <div className="btn-pad">
        <Button
          fullWidth
          variant="outlined"
          onClick={onClickAddDependent}
          startIcon={<DesignSuite2023.CommonIcons.IconAdd />}>
          Add Dependent
        </Button>
      </div>
    </StyledRelationships>
  )
}

function DisplayRecord({
  recordWithPersonInfo,
  ...spread
}: {
  recordWithPersonInfo: shapes.shapeSubscriberOrDependentInfo
} & Partial<any>): React.ReactElement {
  const dtDob = dateTime.parse(recordWithPersonInfo.Dob)
  const dtAct = dateTime.parse(recordWithPersonInfo.ActivationDate)
  const dtDeact = dateTime.parse(recordWithPersonInfo.DeactivationDate)
  const isActive = dateTime.validationFns.isActive(
    recordWithPersonInfo.ActivationDate,
    recordWithPersonInfo.DeactivationDate
  )
  const ageYears = dateTime.now().diff(dtDob, 'years')

  return (
    <Link
      {...spread}
      className={`related ${isActive ? 'active' : 'inactive'}`}
      target="_blank"
      to={`/member/${recordWithPersonInfo.ID}?employerMemberID=${recordWithPersonInfo.EmployerMemberID}`}>
      <Typography variant="body1">
        {recordWithPersonInfo?.FirstName} {recordWithPersonInfo?.LastName}
        &nbsp;
        <Chip
          component="span"
          size="small"
          label={recordWithPersonInfo.RelationshipDescription}
          style={{ marginLeft: '0.5rem' }}
        />
      </Typography>

      <Typography variant="body2" color="textSecondary">
        <span className="block">
          <strong>DOB:</strong> {dtDob.format()} (Age:{' '}
          {ageYears > 0 ? ageYears : 'Infant'})
        </span>
        <span className="block">
          <strong>Activation:</strong> {dtAct.format()}
        </span>
        {!dtDeact.isEmpty() && (
          <span className={`block ${!isActive && 'red-text'}`}>
            <strong>Deactivation:</strong> {dtDeact.format()}
          </span>
        )}
        <span className="block">
          <strong>Subset:</strong> {recordWithPersonInfo?.SubsetHandle}
        </span>
        {recordWithPersonInfo.Problematic && (
          <Chip
            component="span"
            size="small"
            label="Problematic"
            color="secondary"
          />
        )}
        {/* {JSON.stringify(recordWithPersonInfo, null, '  ')} */}
      </Typography>
    </Link>
  )
}
