import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Avatar,
  Typography,
  CircularProgress,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  IconButton,
} from '@material-ui/core'
import CompanyIcon from '@material-ui/icons/Business'
import * as FileUpload from '../FileUpload'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import { deleteFiles } from '../../actions/FileActions'
import styled from 'styled-components'
import DeleteIcon from '@material-ui/icons/Delete'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

interface LogoProps {
  FileID: number
  FileName: string
  FileCreatedByEmail: string
  URL: string
  IsActiveLogoFile: boolean
}

interface LogoPickerProps {
  id: number
  LogoFileProps: FileUpload.fileFields
  getLogos(id: ID): Promise<LogoProps[]>
  postLogoFile(arg0: ID, arg1: PostLogoProps): Promise<any>
  putActiveLogo(arg0: PutLogoProps): Promise<any>
}

interface PutLogoProps {
  id: number
  fileID: string
}

interface PostLogoProps {
  FileID: number
  MakeActive: boolean
}

interface ID {
  id: number
}

export const LogoPicker: React.FC<LogoPickerProps> = ({
  id,
  LogoFileProps,
  getLogos,
  postLogoFile,
  putActiveLogo,
}) => {
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [err, setError] = useState<string | undefined>()
  const [logos, setLogos] = useState<LogoProps[]>([])
  const [activeLogo, setActiveLogo] = useState<LogoProps | null>(null)
  const [updateCounter, setUpdateCounter] = useState<number>(0)
  const { show: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  const [stableAcceptFileTypePresets] = useState({
    'image/png': ['.png'],
    'image/svg+xml': ['.svg'],
    'image/gif': ['.gif'],
    'image/jpeg': ['.jpg'],
  })

  const handleClose = () => {
    setOpen(false)
  }

  const refreshLogos = () => setUpdateCounter(updateCounter + 1)

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    if (value) {
      putActiveLogo({ id, fileID: value })
        .then(() => {
          refreshLogos()
        })
        .catch((err: string) => {
          setError(err)
        })
    }
  }

  const handleUploadComplete = (fileID: number) => {
    postLogoFile({ id }, { FileID: fileID, MakeActive: true })
      .then(() => {
        handleClose()
        refreshLogos()
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'There was a problem setting your file as the current logo, please try again. You may need to rename your file if it has been previously uploaded.',
        })
      )
  }

  const downloadLogo = (logo: LogoProps) => window.open(logo?.URL)

  const deleteLogo = (logo: LogoProps) => {
    deleteFiles([logo.FileID])
      .then(() => {
        showSnackbar('Successfully deleted logo', SnackbarTypeSuccess)
        refreshLogos()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Unable to Delete Logo; please contact Engineering',
        })
      )
  }

  useEffect(() => {
    if (!id) return
    setLoading(true)
    getLogos({ id })
      .then((logos: LogoProps[]) => {
        setLogos(logos)
        setActiveLogo(null)
        logos.forEach((logo) => {
          if (logo.IsActiveLogoFile) {
            setActiveLogo(logo)
          }
        })
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to Fetch Logos; please contact Engineering',
        })
      )
      .finally(() => setLoading(false))
  }, [updateCounter, id])

  const Placeholder = () => {
    return (
      <LogoPlaceholder variant="circle">
        <CompanyIcon fontSize="large" />
      </LogoPlaceholder>
    )
  }

  const renderLogoList = () => (
    <FormControl>
      <RadioGroup value={activeLogo?.FileID} onChange={handleChange}>
        {logos.map((logo: LogoProps) => {
          return (
            <Grid key={logo.FileID} container spacing={2} alignItems="center">
              <FormControlLabel
                value={logo.FileID}
                control={<Radio />}
                label={<LogoListItem src={logo.URL} />}
              />
              {logo.IsActiveLogoFile ? null : (
                <>
                  <IconButton onClick={() => deleteLogo(logo)}>
                    <DeleteIcon color="secondary" />
                  </IconButton>
                  <IconButton onClick={() => downloadLogo(logo)}>
                    <OpenInNewIcon color="primary" />
                  </IconButton>
                </>
              )}
            </Grid>
          )
        })}
        <FormControlLabel
          value={0}
          control={<Radio />}
          label="No active logo"
        />
      </RadioGroup>
    </FormControl>
  )

  return (
    <LogoPlaceholderContainer>
      {!activeLogo ? <Placeholder /> : <Logo src={activeLogo?.URL} />}
      <Button onClick={() => setOpen(true)}>
        {!activeLogo ? 'Upload a logo' : 'Update logo'}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Logo Picker</DialogTitle>
        <StyledDialogContent>
          <Grid container>
            <FileUpload.Uploader
              defaultFileData={LogoFileProps}
              autoSign
              autoUniqueName
              acceptPresets={stableAcceptFileTypePresets}
              CustomFileDisplay={(props: any) => {
                return (
                  <FileUpload.DefaultSingleFileInput
                    {...props}
                    showInputs={false}
                  />
                )
              }}
              onSuccess={(res: FileUpload.postFileResponse[]) => {
                handleUploadComplete(res[0].ID)
              }}
            />
          </Grid>
          {err ? (
            <div>Unable to load logos</div>
          ) : (
            <LogoListContainer>
              {loading ? <CircularProgress /> : null}
              {logos.length > 0 ? (
                renderLogoList()
              ) : (
                <Typography variant="h6">No currently active logos.</Typography>
              )}
            </LogoListContainer>
          )}
        </StyledDialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </LogoPlaceholderContainer>
  )
}

const StyledDialogContent = styled(DialogContent)`
  min-width: 600px;
  display: flex;
  flex-direction: column
}`

const LogoPlaceholder = styled(Avatar)`
  margin-top: 15px;
  width: 150px;
  height: 150px;
`
const LogoPlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Logo = styled.img`
  max-width: 330px;
  padding: 15px;
`

const LogoListItem = styled.img`
  width: 150px;
  padding: 10px;
`

const LogoListContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`
