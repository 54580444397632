import React, { useRef } from 'react'
import { IconButton } from '@material-ui/core'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import * as ZelisTable from '../Components/ZelisTable'

export default function PageZelis(): React.ReactElement {
  const refZelisTable = useRef<any>(null)

  const pageColumnLeft = (
    <div className="lefty">
      {' '}
      <div style={{ paddingBottom: '1rem', textAlign: 'right' }}>
        <IconButton
          size="small"
          onClick={() => {
            refZelisTable.current?.refresh()
          }}>
          <DesignSuite2023.CommonIcons.IconRefresh />
        </IconButton>
      </div>
      <DesignSuite2023.Divider />
      <ZelisTable.FilterBatchDate />
    </div>
  )

  return (
    <ZelisTable.Table
      ref={refZelisTable}
      className="full-fixed"
      enableURLReflection="zclaims.zelis.list"
      DataTableProps={{ stickyHeader: true }}
      BeforeTable={pageColumnLeft}
    />
  )
}
