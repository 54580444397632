import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { canExecEngineering } from '../../utils/perms'
import utils from '../../utils'
import compose from 'recompose/compose'

import Models from '../../models'
import { DataTable } from '../../components/DataTable'
import QSearcher from '../../components/QSearcher'
import { PriceActions, SnackbarActions } from '../../actions'
import { defaultStyleCreator } from '../../components/DetailView/DetailView'
import _ from 'lodash'
import {
  SAVE_NEW_COMPONENT_PRICE,
  UPDATE_COMPONENT_PRICE,
} from '../../actions/PriceActions'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
import dateTime from '../../utils/dateTime'
import DesignSuite2023 from '../../components/DesignSuite2023'

const { setSnackbarMessage } = SnackbarActions
const {
  findPrices,
  savePrice,
  resetPriceState,
  clearSaveResult,
  deletePrices,
  deactivatePrices,
  backdatePrices,
  findComponentPrices,
  updateComponentPrice,
  putResetPriceCache,
  DELETE_PRICES,
  SAVE_NEW_PRICE,
  SAVE_PRICE,
  DEACTIVATE_PRICES,
  BACKDATE_PRICES,
} = PriceActions

const styles = (theme) => {
  const defaults = defaultStyleCreator(theme)

  return Object.assign(defaults, {
    searchField: {
      display: 'inline-flex',
      marginTop: '0px',
    },
    field: {
      width: 200,
    },
    deactivateBtn: {
      marginTop: 15,
      marginBottom: 15,
      float: 'left',
    },
    backdateBtn: {
      marginTop: 15,
      marginBottom: 15,
      marginLeft: 15,
      float: 'left',
    },
  })
}

export class PriceTable extends React.Component {
  initialState = {
    loading: false,
    page: 1,
    pageSize: 10,
    sortable: { col: 'Price', dir: 'asc' },
    checkedItems: [],
    confirmDeleteOpen: false,
    confirmDeactivateOpen: false,
    confirmBackdateOpen: false,
    billingPlanOpen: false,
    dateEnd: '',
    backdateStartVal: '',
    backdateNote: '',
    filter: {
      q: '',
      onlyActive: true,
    },
    engineeringOnlyPerm: canExecEngineering(),
  }

  refDataTable = React.createRef()
  state = this.initialState

  componentWillUnmount() {
    this.props.resetPriceState()
  }

  UNSAFE_componentWillMount() {
    const { filter, page, pageSize, sortable, initialized } = this.state
    const { initParams } = this.props

    if (!initialized) {
      if (!_.isEmpty(initParams)) {
        this.doFindPrices({ ...initParams })
        this.setState({ ...initParams })
      } else {
        this.doFindPrices({ filter, page, pageSize, sortable })
      }
    }

    this.setState({ initialized: true })
  }

  componentDidUpdate(prevProps) {
    const { updateCounter } = this.props
    const { filter, sortable, page, pageSize } = this.state
    // Do not ever reuse this concept
    if (updateCounter && updateCounter !== prevProps.updateCounter) {
      this.doFindPrices({ filter, page, pageSize, sortable })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { saveResult } = nextProps
    const { filter, page, pageSize, sortable } = this.state
    if (saveResult) {
      if (saveResult.error) {
        const msg = `An error occurred while saving: ${saveResult.message}`
        this.props.setSnackbarMessage(msg, 'error')
      } else if (
        saveResult.type === SAVE_PRICE ||
        saveResult.type === UPDATE_COMPONENT_PRICE
      ) {
        this.props.setSnackbarMessage('Price saved!', 'success')
        this.doFindPrices({ filter, sortable, page, pageSize })
      } else if (saveResult.type === DELETE_PRICES) {
        this.props.setSnackbarMessage('Prices deleted!', 'success')
        this.doFindPrices({ filter, sortable, page, pageSize })
        this.setState({ checkedItems: [], confirmDeleteOpen: false })
      } else if (saveResult.type === DEACTIVATE_PRICES) {
        this.props.setSnackbarMessage('Prices deactivated!', 'success')
        this.doFindPrices({ filter, sortable, page, pageSize })
        this.setState({
          checkedItems: [],
          confirmDeactivateOpen: false,
          notes: '',
          dateEnd: '',
        })
      } else if (saveResult.type === BACKDATE_PRICES) {
        this.props.setSnackbarMessage('Backdated prices OK', 'success')
        this.doFindPrices({ filter, sortable, page, pageSize })
        this.setState({ checkedItems: [], confirmBackdateOpen: false })
      } else if (
        saveResult.type === SAVE_NEW_PRICE ||
        saveResult.type === SAVE_NEW_COMPONENT_PRICE
      ) {
        return
      }

      this.setState({ saving: false, saveResult })
      this.props.clearSaveResult()
    }
  }

  doFindPrices = ({ filter, page, pageSize, sortable }) => {
    const { fsId, costKeyId, isComponent } = this.props

    this.setState({ loading: true })

    if (filter.q.length === 0 || filter.q.length > 2) {
      if (fsId) filter.feeScheduleId = fsId
      if (costKeyId) filter.costKeyId = costKeyId

      this.props.persistSearch({ filter, page, pageSize, sortable })

      const payload = { filter, page, pageSize }
      if (sortable.col && sortable.dir) {
        payload.sort = [sortable.col, sortable.dir]
      }
      if (isComponent) {
        payload.sort = [sortable.col.toLowerCase(), sortable.dir]
        return this.props.findComponentPrices(payload).then((res) => {
          this.setState({ loading: false })
        })
      }
      this.props.findPrices(payload).then((res) => {
        this.setState({ loading: false })
      })
    }
  }

  resetToPageOne = (pageSize) => {
    this.setState({ page: 1, pageSize })
    this.refDataTable?.current?.forceSetPagination({ page: 1, pageSize })
  }

  handleShowInactive = (showInactive) => {
    const { filter, sortable, pageSize } = this.state
    showInactive ? (filter.onlyActive = false) : (filter.onlyActive = true)
    this.setState({ filter })
    this.resetToPageOne(pageSize)
    this.doFindPrices({ filter, page: 1, pageSize: pageSize, sortable })
  }

  handleQueryChange = (q) => {
    const { filter, sortable, pageSize } = this.state
    filter.q = q
    this.setState({ filter })
    this.resetToPageOne(pageSize)
    this.doFindPrices({ filter, page: 1, pageSize: pageSize, sortable })
  }

  handleChangePage = ({ page, pageSize }) => {
    const { filter, sortable } = this.state

    // reset to page one if user changes page size
    if (pageSize !== this.state.pageSize) {
      this.resetToPageOne(pageSize)
      this.doFindPrices({ filter, page: 1, pageSize: pageSize, sortable })
      return
    }

    this.setState({ page })
    this.doFindPrices({ filter, page, pageSize, sortable })
  }

  handleSaveRow = (row) => {
    const { fsId, isComponent, updateComponentPrice } = this.props
    let rowToSave = Object.assign({}, row, { Units: parseInt(row.Units) })
    if (isComponent) {
      return updateComponentPrice(row.ID, rowToSave)
    }
    rowToSave = Object.assign({}, row, {
      DateStart: row.DateStart
        ? dateTime.parse(row.DateStart).format(dateTime.formats.ISODate)
        : '',
      DateEnd: row.DateEnd
        ? dateTime.parse(row.DateEnd).format(dateTime.formats.ISODate)
        : '',
    })
    return this.props.savePrice({ fsId, priceId: row.ID, body: rowToSave })
  }

  sortHandler = ({ col, dir }) => {
    const { filter, pageSize } = this.state
    const sortable = { col, dir }
    this.setState({ sortable })
    this.resetToPageOne(pageSize)
    this.doFindPrices({ filter, page: 1, pageSize, sortable })
  }

  handleEdit = (row, rowKey) => {}

  handleDeletePrices = () => {
    const { checkedItems } = this.state
    const { fsId } = this.props

    this.props.deletePrices({ priceIds: utils.getIds(checkedItems), fsId })
  }

  handleDeactivatePrices = () => {
    const { checkedItems, dateEnd, notes } = this.state
    const parsed = dateTime.parse(dateEnd).format(dateTime.formats.ISODate)

    this.props.deactivatePrices({
      dateEnd: parsed,
      priceIds: utils.getIds(checkedItems),
      notes,
    })
  }

  handleCheckChanged = (checkedRows) => {
    this.setState({ checkedItems: checkedRows })
  }

  rowOptsApplier = (row) => {
    if (row && row.IsActive === false) {
      return { className: 'tr-warning' }
    }
    return null
  }

  onCancelDialog = () => {
    this.setState({
      confirmDeactivateOpen: false,
      confirmDeleteOpen: false,
      confirmBackdateOpen: false,
      notes: '',
      dateEnd: '',
    })
  }

  renderConfirmDeactivate = () => {
    const { confirmDeactivateOpen, checkedItems } = this.state
    return (
      <div>
        <Dialog open={confirmDeactivateOpen} onClose={this.onCancelDialog}>
          <DialogTitle>Deactivate Price(s)</DialogTitle>
          <DialogContent>
            <div>
              <ManagedDateInput
                name="DateEnd"
                label="Deactivation Date"
                value={this.state.dateEnd}
                setter={({ name, value }) => {
                  this.setState({ dateEnd: value })
                }}
              />
              {/* <DateInput
              className={classes.field}
              style={{ 'margin-bottom': 15 }}
              label='Deactivation Date'
              onChange={(e) => { this.setState({ dateEnd: e.target.value }) }}
              name='DateEnd'
              value={this.state.dateEnd}
            /> */}
            </div>
            <div>
              <TextField
                label="Notes"
                multiline
                placeholder="Enter notes here"
                rows="4"
                rowsMax="8"
                value={this.state.notes}
                onChange={(e) => {
                  this.setState({ notes: e.target.value })
                }}
                name="notes"
                margin="normal"
              />
            </div>
            <div>
              <Typography>
                Please select a date to deactivate the following prices on:
              </Typography>
            </div>
            <List>
              {checkedItems.map((price) => {
                return (
                  <ListItem>
                    {price.CostKey.Code} - {price.CostKey.LaymanDescr}
                  </ListItem>
                )
              })}
            </List>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleDeactivatePrices}
              disabled={!this.state.dateEnd && !this.state.notes}
              color="primary">
              Confirm
            </Button>
            <Button onClick={this.onCancelDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  renderConfirmDelete = () => {
    const { confirmDeleteOpen, checkedItems } = this.state
    return (
      <div>
        <Dialog open={confirmDeleteOpen} onClose={this.onCancelDialog}>
          <DialogTitle>Delete Price(s)</DialogTitle>
          <DialogContent>
            <Typography>
              Please confirm you would like to delete the following prices:
            </Typography>
            <div>
              <List>
                {checkedItems.map((price) => {
                  return (
                    <ListItem>
                      {price.CostKey.Code} - {price.CostKey.LaymanDescr}
                    </ListItem>
                  )
                })}
              </List>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeletePrices} color="primary">
              Confirm
            </Button>
            <Button onClick={this.onCancelDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  renderConfirmBackdate = () => {
    const { confirmBackdateOpen, checkedItems } = this.state
    return (
      <div>
        <Dialog open={confirmBackdateOpen} onClose={this.onCancelDialog}>
          <DialogTitle>Backdate Price</DialogTitle>
          <DialogContent>
            <Typography gutterBottom={true}>
              <strong>Engineering</strong> members are permitted to skirt the
              normal fee schedule business logic and set the Start Date on a
              price to a time in the past. You should only be doing this if you
              know what you're doing.
            </Typography>
            {checkedItems.map((price) => {
              return (
                <blockquote>
                  <Typography>
                    <strong>{price.CostKey.Code}</strong> (Current Start Date:{' '}
                    {price.DateStart})
                  </Typography>
                  <Typography>{price.CostKey.LaymanDescr}</Typography>
                </blockquote>
              )
            })}
            <div>
              {/* <DateInput
              className={classes.field}
              style={{ 'margin-bottom': 15 }}
              label='Start Date'
              onChange={(e) => { this.setState({ backdateStartVal: e.target.value }) }}
              name='BackdateStart'
              value={this.state.backdateStartVal}
            /> */}
              <ManagedDateInput
                name="BackdateStart"
                label="Start Date"
                value={this.state.backdateStartVal}
                setter={({ name, value }) => {
                  this.setState({ backdateStartVal: value })
                }}
              />
            </div>
            <div>
              <TextField
                label="Notes"
                multiline
                placeholder="Enter notes here"
                rows="4"
                rowsMax="8"
                value={this.state.backdateNote}
                onChange={(e) => {
                  this.setState({ backdateNote: e.target.value })
                }}
                name="backdateNote"
                margin="normal"
                fullWidth
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleBackdatePrices}
              disabled={
                !this.state.backdateStartVal && !this.state.backdateNote
              }
              color="primary">
              Confirm
            </Button>
            <Button onClick={this.onCancelDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  handleBackdatePrices = () => {
    const { checkedItems, backdateStartVal, backdateNote } = this.state
    const parsed = dateTime
      .parse(backdateStartVal)
      .format(dateTime.formats.ISODate)
    this.props.backdatePrices({
      fsId: this.props.fsId,
      dateStart: parsed,
      priceIds: utils.getIds(checkedItems),
      notes: backdateNote,
    })
  }

  renderStaticFilters = () => {
    const { fsId } = this.props
    return (
      <div>
        {fsId && (
          <div>
            <Chip label={`fsId: ${fsId}`} className={this.props.classes.chip} />
          </div>
        )}
      </div>
    )
  }

  renderTable = () => {
    const {
      getError,
      prices,
      count,
      production,
      columnOverride,
      allowEditing,
      isComponent,
    } = this.props
    const { loading, sortable, page, pageSize } = this.state
    const columns = production
      ? Models.Price.productionColumns
      : Models.Price.columns
    let cols = columnOverride ? columnOverride : columns
    if (isComponent)
      cols = production
        ? Models.ComponentPrice.productionColumns
        : Models.ComponentPrice.columns

    return (
      <DataTable
        columns={cols}
        allowEditing={allowEditing}
        checkHandler={this.handleCheckChanged}
        count={count}
        data={prices}
        error={getError}
        initPage={page}
        initPageSize={pageSize}
        keyProp="ID"
        loading={loading}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangePage}
        onEditRow={this.handleEdit}
        onSaveRow={this.handleSaveRow}
        ref={this.refDataTable}
        rowOptsApplier={this.rowOptsApplier}
        sortHandler={this.sortHandler}
        sortable={sortable}
      />
    )
  }

  render() {
    const { classes, initParams, production, canDeactivate } = this.props
    const { checkedItems, showInactive } = this.state
    const { filter } = initParams
    const q = filter ? filter.q : ''

    return (
      <div>
        <div className={classes.searchBar}>
          <QSearcher
            search={this.handleQueryChange}
            className={classes.searchField}
            default={q}
          />
        </div>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item>{this.renderStaticFilters()}</Grid>
          <Grid item>
            <Typography>Show Inactive</Typography>
          </Grid>
          <Grid item>
            <Switch
              color="primary"
              onChange={(e) => this.handleShowInactive(e.target.checked)}
              checked={showInactive}
            />
          </Grid>
        </Grid>
        {this.renderTable()}
        {this.renderConfirmDelete()}
        {this.renderConfirmBackdate()}
        {this.renderConfirmDeactivate()}

        {checkedItems.length > 0 && !production && (
          <Button
            onClick={() => this.setState({ confirmDeleteOpen: true })}
            className={classes.deactivateBtn}
            variant="contained"
            color="secondary">
            Delete Prices
          </Button>
        )}
        {checkedItems.length > 0 && production && (
          <Button
            onClick={() => this.setState({ confirmDeactivateOpen: true })}
            className={classes.deactivateBtn}
            variant="contained"
            disabled={!canDeactivate}
            color="secondary">
            Deactivate Prices
          </Button>
        )}
        {checkedItems.length > 0 && production && this.state.engineeringPerm ? (
          <Tooltip title="You can only see this / perform this action with **Engineering** permission">
            <Button
              className={classes.backdateBtn}
              color="primary"
              variant="contained"
              onClick={() => {
                this.setState({ confirmBackdateOpen: true })
              }}>
              Backdate Price
            </Button>
          </Tooltip>
        ) : null}
        {checkedItems.length > 0 && production && (
          <DesignSuite2023.Tooltip title="Reset price cache">
            <Button
              onClick={() => {
                putResetPriceCache(this.props.fsId, {
                  FeeSchedulePriceIDs: utils.getIds(checkedItems),
                })
                  .then((res) => {
                    if (res.error) throw res
                    alert('Price cache reset OK')
                  })
                  .catch(() => {
                    alert(
                      'Error resetting price cache. Please contact engineering.'
                    )
                  })
              }}
              color="primary"
              variant="outlined"
              style={{ float: 'left', margin: '15px 0 0 15px' }}>
              Reset Cache
            </Button>
          </DesignSuite2023.Tooltip>
        )}
      </div>
    )
  }
}

PriceTable.propTypes = {
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  fsId: PropTypes.number,
  persistSearch: PropTypes.func,
  initParams: PropTypes.object,
  canDeactivate: PropTypes.bool,
  allowEditing: PropTypes.bool,
}

PriceTable.defaultProps = {
  page: 1,
  pageSize: 10,
  persistSearch: () => {},
  initParams: {},
  canDeactivate: false,
  allowEditing: true,
}

function mapStateToProps(state) {
  return {
    prices: state.prices.prices || [],
    saveResult: state.prices.saveResult,
    count: state.prices.pricesCount || 0,
    getError: state.prices.getPricesError || null,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      findPrices,
      savePrice,
      resetPriceState,
      deletePrices,
      deactivatePrices,
      backdatePrices,
      clearSaveResult,
      setSnackbarMessage,
      findComponentPrices,
      updateComponentPrice,
    },
    dispatch
  )
}

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(PriceTable)
