import * as api from '../services/thezerocard/api-helper'
export const FIND_PRICES = 'FIND_PRICES'
export const SAVE_PRICE = 'SAVE_PRICE'
export const SAVE_NEW_PRICE = 'SAVE_NEW_PRICE'
export const CLEAR_SAVE_RESULT = 'CLEAR_SAVE_RESULT'
export const RESET_PRICE_STATE = 'RESET_PRICE_STATE'
export const DEACTIVATE_PRICES = 'DEACTIVATE_PRICES'
export const BACKDATE_PRICES = 'BACKDATE_PRICES'
export const DELETE_PRICES = 'DELETE_PRICES'
export const FIND_COMPONENT_PRICES = 'FIND_COMPONENT_PRICES'
export const SAVE_NEW_COMPONENT_PRICE = 'SAVE_NEW_COMPONENT_PRICE'
export const UPDATE_COMPONENT_PRICE = 'UPDATE_COMPONENT_PRICE'

export const findPrices = (params) => {
  return {
    type: FIND_PRICES,
    payload: api.search('/prices', params),
  }
}

export const savePrice = ({ fsId, priceId, body }) => {
  return {
    type: SAVE_PRICE,
    payload: api.put(`/fee_schedule/${fsId}/price/${priceId}`, body),
  }
}

export const saveNewPrice = ({ fsId, body }) => {
  return {
    type: SAVE_NEW_PRICE,
    payload: api.post(`/fee_schedule/${fsId}/price`, body),
  }
}

export const deactivatePrices = ({ priceIds, dateEnd, notes }) => {
  return {
    type: DEACTIVATE_PRICES,
    payload: api.put('/bulk_deactivate_price', {
      priceIds,
      dateEnd,
      notes,
    }),
  }
}

export const backdatePrices = ({ fsId, priceIds, dateStart, notes }) => {
  return {
    type: BACKDATE_PRICES,
    payload: api.put(`/fee_schedule/${fsId}/backdate_prices`, {
      priceIds,
      dateStart,
      notes,
    }),
  }
}

export const deletePrices = ({ priceIds, fsId }) => {
  return {
    type: DELETE_PRICES,
    payload: api.put(`/fee_schedule/${fsId}/bulk_delete_price`, {
      priceIds,
    }),
  }
}

export const resetPriceState = () => {
  return { type: RESET_PRICE_STATE }
}

export const clearSaveResult = () => {
  return { type: CLEAR_SAVE_RESULT }
}

export const findComponentPrices = (params) => {
  return {
    type: FIND_COMPONENT_PRICES,
    payload: api.search(
      `/component_fee_schedule/${params.filter.feeScheduleId}/prices`,
      params
    ),
  }
}

export const saveNewComponentPrice = (body) => {
  return {
    type: SAVE_NEW_COMPONENT_PRICE,
    payload: api.post('/component_price', body),
  }
}

export const updateComponentPrice = (id, body) => {
  return {
    type: UPDATE_COMPONENT_PRICE,
    payload: api.put(`/component_price/${id}`, body),
  }
}

export const putResetPriceCache = (feeScheduleID, body) => {
  return api.put(`/fee_schedule/${feeScheduleID}/reset_price_cache`, body)
}
