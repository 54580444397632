import React, { useEffect, useState } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import { MemberActions } from '../../actions'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import DesignSuite2023 from '../../components/DesignSuite2023'
import dateTime from '../../utils/dateTime'
import utils from '../../utils'
import Models from '../../models'
import { Button, Chip, Grid, IconButton, Typography } from '@material-ui/core'
import DisplayMemberNotes from './subcomponents/DisplayMemberNotes'
import IDCard from '../../components/IdCard'
import * as shapes from './types'
import DisplayAddress from '../../components/Address'
import CheckEmailBlacklisted from '../../components/CheckEmailBlacklisted'

// sub-components only used by this
import DisplaySubscriberDependentRelationships from './subcomponents/DisplaySubscriberDependentRelationships'
import DisplayEmployerMemberships from './subcomponents/DisplayEmployerMemberships'
import DisplaySidebarActionButtons from './subcomponents/DisplaySidebarActionButtons'
import TabbedItems from './subcomponents/DisplayMemberTabs'

// dialogs
import DialogSetActivationDate from './DialogSetActivationDate'
import DialogSetDeactivationDate from './DialogSetDeactivationDate'
import DialogSetSkipUpdate from './DialogSetSkipUpdate'
import DialogSetSubsetHandle from './DialogSetSubsetHandle'
import DialogSetDivision from './DialogSetDivision'
import DialogSetCarrierName from './DialogSetCarrierName'
import DialogSetPlanName from './DialogSetPlanName'
import DialogSetExcludeVBAExport from './DialogSetExcludeVBAExport'
import DialogManageProblematic from './DialogManageProblematic'
import DialogEditMemberTrigger from './DialogEditMemberDetails'
import DialogAddEmployer from './DialogAddEmployer'
import DialogChangeMyZEROEmail from './DialogChangeMyZEROEmail'

// styled components
import {
  StyledBtnEdit,
  StyledCustomGrid,
  StyledLoader,
  StyledSpanBlock,
  StyledTextLabel,
  StyledTextValue,
} from './subcomponents/styled'

interface props {
  memberID?: number
}

export default function MemberDetail2({
  match,
  history,
  location,
  memberID: passedMemberID,
}: props & RouteComponentProps<{ id: string }>): React.ReactElement | null {
  const initURLParams = React.useMemo(() => {
    const p = new URLSearchParams(location.search)
    return {
      employerMemberID: parseInt(p.get('employerMemberID') || '') || null,
    }
  }, [])
  // note: this is super freaking annoying, but React freaks out using the || operator with parseInt and
  // will throw a bunch of "useState being called conditionally" errors if you do this:
  // ... = useState(passedMemberID || parseInt(match?.params?.id) || null)
  // Hence, for the initial memberID value (where order of precedence matters; if memberID is
  // passed to the component as a prop, thats used, otherwise use the route's value, otherwise null),
  // we have to do it in a function
  const [memberID, setMemberID] = useState<number | null>(_getInitialMemberID())
  const [memberData, setMemberData] = useState<shapes.shapeMember | null>(null)
  const [useEmployerMemberID, setUseEmployerMemberID] = useState<number | null>(
    initURLParams.employerMemberID
  )
  const [currEmpMem, setCurrEmpMem] =
    useState<shapes.shapeEmployerMember | null>(null)
  const [dataMemberProf, setDataMemberProf] =
    useState<shapes.shapeMemberProfile | null>(null)
  const [dataUserProf, setDataUserProf] =
    useState<shapes.shapeUserProfile | null>(null)
  const [dataMyZeroInspection, setDataMyZeroInspection] =
    useState<shapes.shapeMyZEROInspection | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [minimizeExtraContent, setMinimizeExtraContent] = useState(false)
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (!memberID) {
      setMemberData(null)
      setUseEmployerMemberID(null)
      setCurrEmpMem(null)
      setIsLoading(false)
      return
    }
    refreshMemberData()
  }, [memberID])

  // watch for changes to passedMemberID prop, or the route's id
  useEffect(() => {
    // if memberID is passed as a prop, it always takes precedence
    if (passedMemberID) {
      setMemberID(passedMemberID)
      return
    }
    if (match?.params?.id) {
      setMemberID(parseInt(match?.params?.id))
    }
  }, [passedMemberID, match?.params?.id])

  // reflect internal state back to the URL
  useEffect(() => {
    if (!useEmployerMemberID) return
    history.push({ search: `?employerMemberID=${useEmployerMemberID}` })
  }, [useEmployerMemberID])

  // only fires when URL location.search changes (if user presses back after switching
  // employer members, for example)
  useEffect(() => {
    if (!location.search) return
    const p = new URLSearchParams(location.search)
    const emID = parseInt(p.get('employerMemberID') || '') || null
    if (!emID || emID === useEmployerMemberID) return
    setUseEmployerMemberID(emID)
  }, [location.search])

  // watch changes to memberData AND useEmployerMemberID; if employerMemberID is already
  // set but it doesn't come back in the set of matched members, then auto-select the
  // first employer member
  useEffect(() => {
    if (!memberData) return
    const em =
      memberData?.EmployerMemberships?.find(
        (em: any) => em.ID === useEmployerMemberID
      ) || null

    if (!em) {
      const urlEmpMemID = initURLParams.employerMemberID || (0 as number)
      if (urlEmpMemID >= 1 && useEmployerMemberID === urlEmpMemID) {
        alert(
          'Invalid employerMemberID passed in URL (*member* has no employer member records with that ID). Auto-selecting the first employer member.'
        )
      }

      const useEMID = memberData?.EmployerMemberships?.[0]?.ID || null
      if (!useEMID) return

      setUseEmployerMemberID(useEMID)
      return
    }

    setCurrEmpMem(em as shapes.shapeEmployerMember)
  }, [memberData, useEmployerMemberID])

  /*
    See comment above where memberID state is declared
  */
  function _getInitialMemberID(): number | null {
    if (passedMemberID) return passedMemberID
    if (match?.params?.id) {
      const id = parseInt(match?.params?.id)
      if (isFinite(id)) return id
    }
    return null
  }

  function refreshMemberData() {
    if (!memberID) return

    setIsLoading(true)
    MemberActions.getMemberByID(memberID)
      .then((memberRes: any) => {
        if (memberRes.error) throw memberRes
        setMemberData(memberRes.Data)
        showSnackbar('Member data refreshed OK', SnackbarTypeSuccess)
        // NOTICE: this is RETURNING A PROMISE from the refreshAccountInfos call;
        // so it act's like a promise chain
        return refreshAccountInfos()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed refreshing member data',
        })
      )
      .finally(() => setIsLoading(false))
  }

  function refreshAccountInfos() {
    if (!memberID) return

    return Promise.all([
      MemberActions.getMemberProfile(memberID),
      MemberActions.getUserProfileFromMemberID(memberID),
    ])
      .then(([resMemProf, resUserProf]) => {
        if (resMemProf.error) throw resMemProf
        if (resUserProf.error) throw resUserProf
        !!resMemProf?.Data && setDataMemberProf(resMemProf.Data)
        !!resUserProf?.Data && setDataUserProf(resUserProf.Data)

        // conditionally hit the "inspect my zero info" endpoint, but only if
        // the user profile has a MemberID. *IMPORTANT* make sure to return the
        // promise so that the catch/finally blocks of the parent will chain!
        if (resUserProf?.Data?.MemberID) {
          return MemberActions.getInspectMemberMyZERO(
            resUserProf.Data.MemberID
          ).then((res: any) => {
            if (res.error) throw res
            setDataMyZeroInspection(res.Data)
          })
        }
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed fetching MyZERO related info',
        })
      )
  }

  function displayMemberName(): string {
    if (!memberData) return ''
    const { FirstName, MiddleName, LastName, Suffix } = memberData
    return [FirstName, MiddleName, LastName, Suffix]
      .filter((v) => !!v)
      .join(' ')
  }

  if (!memberData) {
    return (
      <StyledLoader>
        <div className="loader">
          <DesignSuite2023.LoadingSpinner />
          &nbsp;&nbsp;<strong>Loading member data...</strong>
        </div>
      </StyledLoader>
    )
  }

  // All dialogs related to editing employer member info and eligibility have the exact
  // same set of arguments, so we store them together here and just spread onto the
  // component where needed (upside of dialogs being fully self-contained :))
  const commonMemberDialogProps = {
    Trigger: StyledBtnEdit,
    empMemRec: currEmpMem,
    onSaved: refreshMemberData,
  }

  return (
    <>
      <DesignSuite2023.LayoutContainer>
        <DesignSuite2023.LayoutPrimary
          large
          style={{ width: '100%', padding: 0 }}>
          {isLoading && (
            <StyledLoader>
              <div className="loader">
                <DesignSuite2023.LoadingSpinner />
                &nbsp;&nbsp;<strong>Loading member data...</strong>
              </div>
            </StyledLoader>
          )}
          <DesignSuite2023.Section>
            <DesignSuite2023.GridLR
              style={{
                background: '#f1f1f1',
                borderRadius: 5,
                marginBottom: '1rem',
              }}
              left={
                <Typography
                  variant="h4"
                  style={{
                    padding: '0.5rem',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <span>{displayMemberName()}</span>
                  &nbsp;
                  <Chip
                    size="small"
                    color={(memberData?.Retired && 'secondary') || 'default'}
                    label={
                      <span>
                        <strong>Retired:</strong>{' '}
                        {memberData?.Retired ? 'Yes' : 'No'}
                      </span>
                    }
                  />
                </Typography>
              }
              right={
                <>
                  <DialogEditMemberTrigger
                    memberID={memberData?.ID}
                    onSave={refreshMemberData}
                  />
                </>
              }
            />

            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={6}>
                <StyledCustomGrid templateColumns="repeat(5, auto)">
                  <StyledTextLabel>DOB</StyledTextLabel>
                  <StyledTextLabel>Gender</StyledTextLabel>
                  <StyledTextLabel>SSN/4</StyledTextLabel>
                  <StyledTextLabel>Phone</StyledTextLabel>
                  <StyledTextLabel>Email</StyledTextLabel>
                  <StyledTextValue>
                    {dateTime.parse(memberData?.Dob).format()}
                  </StyledTextValue>
                  <StyledTextValue>{memberData?.Gender}</StyledTextValue>
                  <StyledTextValue>{memberData?.SsnLast4}</StyledTextValue>
                  <StyledTextValue>
                    {memberData?.Phone
                      ? utils.phoneFormatter(memberData?.Phone)
                      : '-'}
                  </StyledTextValue>
                  <StyledTextValue>
                    <CheckEmailBlacklisted email={memberData?.Email} link />
                  </StyledTextValue>

                  <DesignSuite2023.Divider
                    className="full-width"
                    style={{ margin: '0.5rem 0' }}
                  />

                  <StyledTextLabel className="full-width">
                    Address
                  </StyledTextLabel>
                  <Typography className="full-width" variant="body2">
                    <DisplayAddress addressObj={memberData?.Address} />
                  </Typography>
                </StyledCustomGrid>
              </Grid>
              <Grid item xs={6}>
                <div
                  style={{ borderLeft: '1px solid #ddd', paddingLeft: '1rem' }}>
                  <DisplayMemberNotes memberID={memberData?.ID} />
                </div>
              </Grid>
            </Grid>
          </DesignSuite2023.Section>

          <Grid container>
            <Grid item style={{ borderRight: '1px solid #ddd' }}>
              <DisplayEmployerMemberships
                memberData={memberData}
                useEmployerMemberID={useEmployerMemberID}
                onClick={(employerMemberID: number) =>
                  setUseEmployerMemberID(employerMemberID)
                }
              />
              <div style={{ padding: '1rem' }}>
                {!!memberData && (
                  <DialogAddEmployer
                    memberID={memberData?.ID}
                    handleClose={refreshMemberData}
                  />
                )}
              </div>
            </Grid>
            <Grid item style={{ flex: 1 }}>
              <StyledCustomGrid
                style={{ padding: '1.5rem' }}
                templateColumns="repeat(6, auto)">
                <Typography
                  variant="h6"
                  component="div"
                  className="full-width"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingBottom: '0.25rem',
                  }}>
                  <span>Employer Membership Details</span>
                  &nbsp;
                  <Chip
                    size="small"
                    label={
                      <span>
                        <strong>Employer Member ID:</strong> {currEmpMem?.ID}
                      </span>
                    }
                  />
                </Typography>

                <StyledTextLabel>Integration ID</StyledTextLabel>
                <StyledTextLabel>
                  Activation Date
                  <DialogSetActivationDate {...commonMemberDialogProps} />
                </StyledTextLabel>
                <StyledTextLabel>
                  Deactivation Date
                  <DialogSetDeactivationDate {...commonMemberDialogProps} />
                </StyledTextLabel>
                <StyledTextLabel>Created</StyledTextLabel>
                <StyledTextLabel>Last Changed</StyledTextLabel>
                <StyledTextLabel>Last Run</StyledTextLabel>
                <StyledTextValue>{currEmpMem?.IntegrationId}</StyledTextValue>
                <StyledTextValue>
                  {dateTime.parse(currEmpMem?.ActivationDate).format()}
                </StyledTextValue>
                <StyledTextValue>
                  {dateTime.parse(currEmpMem?.DeactivationDate).format()}
                </StyledTextValue>
                <StyledTextValue>
                  {dateTime.parse(currEmpMem?.CreatedAt).format()}
                </StyledTextValue>
                <StyledTextValue>
                  {dateTime.parse(currEmpMem?.ChangedAt).format()}
                </StyledTextValue>
                <StyledTextValue>
                  {dateTime.parse(currEmpMem?.UpdatedAt).format()}
                </StyledTextValue>

                <hr className="row-spacer" />

                <StyledTextLabel>Employer</StyledTextLabel>
                <StyledTextLabel>Employer Launch</StyledTextLabel>
                <StyledTextLabel style={{ gridColumn: '3/-1' }}>
                  Employer Term
                </StyledTextLabel>
                <StyledTextValue>
                  <Link
                    target="_blank"
                    to={`/employer/${currEmpMem?.EmployerId}`}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingBottom: '0.25rem',
                    }}>
                    {currEmpMem?.EmployerName}
                    &nbsp;
                    <DesignSuite2023.CommonIcons.IconOpenInNew fontSize="inherit" />
                  </Link>
                </StyledTextValue>

                <StyledTextValue>
                  {dateTime.parse(currEmpMem?.EmployerLaunchDate).format()}
                </StyledTextValue>
                <StyledTextValue style={{ gridColumn: '3/-1' }}>
                  {dateTime.parse(currEmpMem?.EmployerTerminationDate).format()}
                </StyledTextValue>

                <hr className="row-spacer" />

                <StyledTextLabel className="full-width">
                  Notes For PHAs
                </StyledTextLabel>
                <StyledTextValue className="full-width">
                  {currEmpMem?.EmployerPHANotes || '-'}
                </StyledTextValue>

                <DesignSuite2023.Divider className="full-width" />

                <Typography
                  variant="h6"
                  component="div"
                  className="full-width"
                  style={{ display: 'flex', alignItems: 'center' }}>
                  <span>Eligibility Details</span>
                  &nbsp;
                  <Chip
                    size="small"
                    variant="outlined"
                    label={`Has MD5? ${!!currEmpMem?.LastProcessedMD5 ? 'Yes' : 'No'}`}
                  />
                </Typography>
                <StyledTextLabel>
                  Subset Handle
                  <DialogSetSubsetHandle {...commonMemberDialogProps} />
                </StyledTextLabel>
                <StyledTextLabel>
                  Carrier Name
                  <DialogSetCarrierName {...commonMemberDialogProps} />
                </StyledTextLabel>
                <StyledTextLabel>
                  Plan Name
                  <DialogSetPlanName {...commonMemberDialogProps} />
                </StyledTextLabel>
                <StyledTextLabel>
                  Skip Update Until
                  <DialogSetSkipUpdate {...commonMemberDialogProps} />
                </StyledTextLabel>
                <StyledTextLabel style={{ gridColumn: '5/-1' }}>
                  Exclude VBA Export
                  <DialogSetExcludeVBAExport {...commonMemberDialogProps} />
                </StyledTextLabel>
                <StyledTextValue>
                  {Models.Eligibility.subsetHandleFormatter(
                    currEmpMem?.SubsetHandle
                  )}
                </StyledTextValue>
                <StyledTextValue>{currEmpMem?.CarrierName}</StyledTextValue>
                <StyledTextValue>{currEmpMem?.PlanName || '-'}</StyledTextValue>
                <StyledTextValue>
                  {currEmpMem?.SkipUpdateUntil || '-'}
                </StyledTextValue>
                <StyledTextValue style={{ gridColumn: '5/-1' }}>
                  {Models.Eligibility.excludeFromVBAExportReasonsFormatter(
                    currEmpMem?.ExcludeFromVBAExportReason
                  )}
                </StyledTextValue>

                <hr className="row-spacer" />
                <StyledTextLabel>
                  Division
                  <DialogSetDivision {...commonMemberDialogProps} />
                </StyledTextLabel>
                <StyledTextLabel style={{ gridColumn: '2/-1' }}>
                  Is Problematic
                  <DialogManageProblematic {...commonMemberDialogProps} />
                </StyledTextLabel>

                <StyledTextValue>
                  {!currEmpMem?.DivisionID
                    ? ''
                    : currEmpMem?.DivisionName?.trim() !== ''
                      ? currEmpMem?.DivisionName
                      : `Default division[${currEmpMem?.DivisionID}]`}
                </StyledTextValue>

                <StyledTextValue>
                  {currEmpMem?.Problematic ? (
                    <StyledSpanBlock colorAlert>
                      Member <strong>IS</strong> problematic
                    </StyledSpanBlock>
                  ) : (
                    <StyledSpanBlock>
                      Member is <strong>not</strong> marked problematic
                    </StyledSpanBlock>
                  )}
                </StyledTextValue>

                <DesignSuite2023.Divider className="full-width" />
                <div
                  className="full-width"
                  style={{ textAlign: 'center', margin: '-1rem auto 0' }}>
                  <DesignSuite2023.Tooltip
                    title={
                      minimizeExtraContent
                        ? 'Expand content'
                        : 'Hide the following (keep bottom tabs in view easier)'
                    }>
                    <IconButton
                      size="small"
                      onClick={() =>
                        setMinimizeExtraContent((c: boolean) => !c)
                      }>
                      {minimizeExtraContent ? (
                        <DesignSuite2023.CommonIcons.IconArrowDown fontSize="inherit" />
                      ) : (
                        <DesignSuite2023.CommonIcons.IconArrowUp fontSize="inherit" />
                      )}
                    </IconButton>
                  </DesignSuite2023.Tooltip>
                </div>

                <Grid
                  className="full-width"
                  container
                  spacing={2}
                  justify="space-between"
                  alignItems="flex-start"
                  style={{ display: minimizeExtraContent ? 'none' : 'flex' }}>
                  <Grid item>
                    <DisplayMyZEROInfo
                      dataMemberProf={dataMemberProf}
                      dataUserProf={dataUserProf}
                      dataMyZeroInspection={dataMyZeroInspection}
                      doRefresh={refreshMemberData}
                    />

                    <DesignSuite2023.Divider />

                    <Typography variant="h6" component="div">
                      <span>ID Card</span>
                    </Typography>
                    <div>
                      {!!(currEmpMem && !!memberData) && (
                        <IDCard
                          FirstName={memberData?.FirstName}
                          MiddleName={memberData?.MiddleName}
                          LastName={memberData?.LastName}
                          Dob={memberData?.Dob}
                          QuestID={currEmpMem?.QuestID}
                          HasQuest={currEmpMem?.HasQuest}
                          IntegrationId={currEmpMem?.IntegrationId}
                          IsCurrentlyActive={currEmpMem?.IsCurrentlyActive}
                          NotYetActive={
                            !currEmpMem?.IsCurrentlyActive &&
                            dateTime
                              .parse(currEmpMem?.DeactivationDate)
                              .isEmpty()
                          }
                        />
                      )}
                    </div>
                  </Grid>
                  <Grid item style={{ flex: 1 }}>
                    {!!memberData && (
                      <DisplaySubscriberDependentRelationships
                        member={memberData}
                        employerMember={currEmpMem}
                      />
                    )}
                  </Grid>
                </Grid>
              </StyledCustomGrid>
            </Grid>
          </Grid>
        </DesignSuite2023.LayoutPrimary>

        <DesignSuite2023.LayoutSidebar>
          <DisplaySidebarActionButtons
            empMemRec={currEmpMem}
            memberData={memberData}
          />
        </DesignSuite2023.LayoutSidebar>
      </DesignSuite2023.LayoutContainer>

      <TabbedItems
        useEmployerMemberID={useEmployerMemberID}
        emailForNotificationsLookup={dataUserProf?.Email}
      />

      {/* <pre>{JSON.stringify(memberData, null, '  ')}</pre> */}
    </>
  )
}

/*
Subcomponent to render MyZERO related things.
*/
function DisplayMyZEROInfo({
  dataMemberProf,
  dataUserProf,
  dataMyZeroInspection,
  doRefresh = () => {}, // default to noop
}: any): React.ReactElement | null {
  const [expandAuth0Resp, setExpandAuth0Resp] = useState(false)

  function displayAuth0Response() {
    if (!dataMyZeroInspection)
      return <span>Auth0 data for member user account not found</span>
    if (!expandAuth0Resp)
      return (
        <Button
          variant="text"
          size="small"
          component="span"
          onClick={() => setExpandAuth0Resp(true)}>
          Show Auth0 Data
        </Button>
      )
    return (
      <>
        <pre style={{ maxWidth: 480 }}>
          {JSON.stringify(dataMyZeroInspection, null, '  ')}
        </pre>
        <Button
          variant="text"
          size="small"
          component="span"
          onClick={() => setExpandAuth0Resp(false)}>
          Hide
        </Button>
      </>
    )
  }

  if (!dataMemberProf && !dataUserProf)
    return (
      <div style={{ maxWidth: 500 }}>
        <Typography variant="h6" component="div" className="full-width">
          <span>MyZERO</span>
        </Typography>
        <p>
          No user &amp; member profile information available. This is a strong
          indication that the member has not yet signed up for MyZERO.
        </p>
      </div>
    )

  return (
    <>
      <StyledCustomGrid
        templateColumns="max-content 1fr"
        colGap="1rem"
        rowGap="0.25rem">
        <Typography variant="h6" component="div" className="full-width">
          <span>Member Profile</span>
          <Typography
            className="small-caption"
            variant="caption"
            display="block"
            color="textSecondary">
            Member profile info; editable by member in MyZero.{' '}
            <i>If this is populated, it implies member has accessed MyZERO</i>.
          </Typography>
        </Typography>
        <StyledTextLabel>Name</StyledTextLabel>
        <StyledTextValue>
          {dataMemberProf?.FirstName} {dataMemberProf?.LastName}
        </StyledTextValue>
        <StyledTextLabel>Contact Phone</StyledTextLabel>
        <StyledTextValue>{dataMemberProf?.ContactPhone || '-'}</StyledTextValue>
        <StyledTextLabel>Contact Email</StyledTextLabel>
        <StyledTextValue>{dataMemberProf?.ContactEmail || '-'}</StyledTextValue>
        <StyledTextLabel>DOB</StyledTextLabel>
        <StyledTextValue>{dataMemberProf?.DateOfBirth || '-'}</StyledTextValue>
        <StyledTextLabel>Has MyZERO</StyledTextLabel>
        <StyledTextValue>
          {(dataMemberProf?.Confirmed && 'Yes') || 'No'}
        </StyledTextValue>
        <StyledTextLabel>Address</StyledTextLabel>
        <StyledTextValue>
          {dataMemberProf?.Address1} {dataMemberProf?.Address2}{' '}
          {dataMemberProf?.City} {dataMemberProf?.State}, {dataMemberProf?.Zip}
        </StyledTextValue>

        <DesignSuite2023.Divider className="full-width" />

        <Typography variant="h6" component="div" className="full-width">
          <span>MyZERO User Info</span>
          <Typography
            className="small-caption"
            variant="caption"
            display="block"
            color="textSecondary">
            User information in our system, not editable by member.
          </Typography>
        </Typography>
        <StyledTextLabel>Name</StyledTextLabel>
        <StyledTextValue>
          {dataUserProf?.FirstName || '-'} {dataUserProf?.LastName}
        </StyledTextValue>
        <StyledTextLabel>UserID</StyledTextLabel>
        <StyledTextValue>{dataUserProf?.ID || '-'}</StyledTextValue>
        <StyledTextLabel>MemberID</StyledTextLabel>
        <StyledTextValue>{dataUserProf?.MemberID || '-'}</StyledTextValue>
        <StyledTextLabel>GoZERO</StyledTextLabel>
        <StyledTextValue>
          {dataUserProf?.IsGoZero ? 'Yes' : 'No'}
        </StyledTextValue>
        <StyledTextLabel>Active</StyledTextLabel>
        <StyledTextValue>
          {dataUserProf?.IsActive ? 'Yes' : 'No'}
        </StyledTextValue>
        <StyledTextLabel style={{ alignSelf: 'center' }}>Email</StyledTextLabel>
        <StyledTextValue
          style={{ display: 'inline-flex', alignItems: 'center' }}>
          {!!dataUserProf?.Email ? (
            <CheckEmailBlacklisted email={dataUserProf?.Email} />
          ) : (
            '-'
          )}
          &nbsp;&nbsp;
          {!!dataUserProf?.ID && (
            <DialogChangeMyZEROEmail
              userID={dataUserProf?.ID}
              currentEmail={dataUserProf?.Email}
              onSaved={doRefresh}
            />
          )}
        </StyledTextValue>
        <StyledTextLabel style={{ alignSelf: 'center' }}>
          Auth0 User Info
        </StyledTextLabel>
        <div>{displayAuth0Response()}</div>
      </StyledCustomGrid>
    </>
  )
}
