import React from 'react'
import DesignSuite2023 from '../../components/DesignSuite2023'
import { Button } from '@material-ui/core'
import { ReviewState, GetReviewStates } from './typed'

interface props {
  onClickStatusButton(status: number): void
  disabled: boolean
}

interface handler {
  (ev: React.MouseEvent<HTMLButtonElement>): void
}

export default function ShortcutButtons({
  onClickStatusButton,
  disabled,
}: props): any {
  const onClickFn = (status: number): handler => {
    return () => {
      if (disabled) {
        return
      }
      onClickStatusButton(status)
    }
  }
  const reviewStates = GetReviewStates()

  return (
    <div className="shortcut-buttons">
      {reviewStates.map((rs: ReviewState) => {
        return (
          <React.Fragment key={rs.value}>
            <DesignSuite2023.Tooltip
              title={`State: ${rs.label}`}
              placement="right">
              <Button
                disabled={disabled}
                onClick={onClickFn(rs.value)}
                variant="contained"
                color="primary"
                disableElevation
                size="medium"
                fullWidth>
                {rs.value}
              </Button>
            </DesignSuite2023.Tooltip>
          </React.Fragment>
        )
      })}
    </div>
  )
}
