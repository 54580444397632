import React, { useEffect, useState } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import ClaimLinesDisplay from './ClaimDetail/ClaimLineTable'
import DisplayCodesList from './ClaimDetail/DisplayCodesList'
import DisplayOtherDetailFields from './ClaimDetail/ClaimDetailOtherFields'
import { postNewManualZClaim } from '../../../actions/ZClaimActions'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import dateTime from '../../../utils/dateTime'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
// import {
//   SetterArgs,
// } from '../../ViewHelpers'
import { ShapeZClaimDetail, ShapeZClaimLine } from '../types'
import styled from 'styled-components'
import * as helpers from '../helpers'
import { SourceTypes, ClaimTypes } from '../helpers'
import DialogMemberSearch from './DialogMemberSearch'
import DialogBillingEntitySearch from './DialogBillingEntitySearch'
import DialogFeeScheduleSearch from './DialogFeeScheduleSearch'
import DialogPracticeFacilitySearch from './DialogPracticeFacilitySearch'

// import _set from 'lodash/set'

interface props {
  onClaimCreated?(): void
}

interface shapeClaimData {
  PatientEmployerMemberID: number | null
  BillingEntityID: number | null
  FeeScheduleID: number | null
  Claim: {
    SourceType: helpers.SourceTypes | null
    ClaimType: ClaimTypes | null
    Detail: ShapeZClaimDetail
    DiagnosisCodes: string[]
    // ProcedureCodes: string[]
  }
  Lines: ShapeZClaimLine[]
  [key: string]: any
}

const emptyClaimData = (): shapeClaimData => ({
  PatientEmployerMemberID: null,
  BillingEntityID: null,
  // OrganizationID: null,
  PracticeFacilityID: null,
  FeeScheduleID: null,
  Claim: {
    SourceType: helpers.SourceTypes.SourceTypeManual,
    ClaimType: ClaimTypes.Professional,
    Detail: {} as ShapeZClaimDetail,
    DiagnosisCodes: [],
    // ProcedureCodes: [],
  },
  Lines: [],
})

const StyledContent = styled.div`
  h5 {
    margin: 0;
    font-size: 110%;
  }
  .autocomplete-root {
    width: auto;
    .MuiFormControl-fullWidth {
      width: auto;
      min-width: 0;
    }
  }
`

export default function AddManualClaim({
  onClaimCreated,
}: props): React.ReactElement | null {
  const { catchAPIError } = useErrorHandlers()
  const [isOpen, setIsOpen] = useState(false)
  const [claimData, setClaimData] = useState<shapeClaimData>(emptyClaimData())
  const [patientData, setPatientData] = useState<any>(null)
  const [billingEntityData, setBillingEntityData] = useState<any>(null)
  const [practiceFacilityData, setPracticeFacilityData] = useState<any>(null)
  const [feeScheduleData, setFeeScheduleData] = useState<any>(null)
  const [canSave, setCanSave] = useState(false)
  const [showDebug, setShowDebug] = useState(false)
  const { showForDuration: showSnackbar } = useSnackbar()
  const refDlgMemberSearch = React.useRef<any>(null)
  const refDlgBillingEntitySearch = React.useRef<any>(null)
  const refDlgFeeScheduleSearch = React.useRef<any>(null)
  const refDlgPracticeFacilitySearch = React.useRef<any>(null)

  useEffect(() => {
    setClaimData(emptyClaimData())
    setPatientData(null)
    setBillingEntityData(null)
    setPracticeFacilityData(null)
    setFeeScheduleData(null)
    setCanSave(false)
    setShowDebug(false)
  }, [isOpen])

  useEffect(() => {
    setCanSave(checkCanSave())
  }, [claimData])

  useEffect(() => {
    if (!patientData?.EmployerMemberID) return
    setClaimData((curr: shapeClaimData) => {
      curr.PatientEmployerMemberID = patientData.EmployerMemberID
      return { ...curr }
    })
  }, [patientData])

  useEffect(() => {
    if (!billingEntityData?.ID) return
    setClaimData((curr: shapeClaimData) => {
      curr.BillingEntityID = billingEntityData.ID
      return { ...curr }
    })
  }, [billingEntityData])

  useEffect(() => {
    if (!practiceFacilityData?.ID) return
    setClaimData((curr: shapeClaimData) => {
      curr.PracticeFacilityID = practiceFacilityData.ID
      return { ...curr }
    })
  }, [practiceFacilityData])

  useEffect(() => {
    if (!feeScheduleData?.ID) return
    setClaimData((curr: shapeClaimData) => {
      curr.FeeScheduleID = feeScheduleData.ID
      return { ...curr }
    })
  }, [feeScheduleData])

  function checkCanSave(): boolean {
    if (!claimData?.Claim?.ClaimType) return false
    if (!claimData?.BillingEntityID) return false
    if (!claimData.Lines?.length) return false
    return true
  }

  function doCreateClaim() {
    postNewManualZClaim(claimData)
      .then(() => {
        showSnackbar(`New manual claim added OK`, SnackbarTypeSuccess)
        setIsOpen(false)
        onClaimCreated && onClaimCreated()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed creating claim',
        })
      )
  }

  return (
    <>
      <DesignSuite2023.Tooltip title="Manually add a new claim">
        <Button
          variant="text"
          size="small"
          onClick={() => {
            setIsOpen(true)
          }}>
          New Manual Claim
        </Button>
      </DesignSuite2023.Tooltip>

      <Dialog
        open={isOpen}
        maxWidth="xl"
        onClose={() => setIsOpen(false)}
        disableBackdropClick>
        <DialogTitle>Manual Claim Entry</DialogTitle>
        <DialogContent style={{ width: '80vw', maxWidth: 1400 }}>
          <StyledContent>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4, 1fr)',
                gridColumnGap: '1rem',
                marginBottom: '1rem',
              }}>
              <div>
                <DialogMemberSearch
                  ref={refDlgMemberSearch}
                  onPickedMember={setPatientData}
                  Trigger={(props: any) => (
                    <Button {...props} variant="outlined" fullWidth>
                      Assign Patient (Member)
                    </Button>
                  )}
                />
                {!!patientData && (
                  <div>
                    <Typography variant="body1">
                      {patientData.FirstName} {patientData.LastName}
                    </Typography>
                    <Typography variant="body2">
                      Employer: {patientData?.EmployerName}
                    </Typography>
                    <Typography variant="body2">
                      Employer Member ID: {patientData?.EmployerMemberID}
                    </Typography>
                    <Typography variant="body2">
                      Is Currently Active:{' '}
                      {patientData.IsCurrentlyActive ? 'Yes' : 'No'}
                    </Typography>
                    <Typography variant="body2">
                      Activation Date:{' '}
                      {dateTime.parse(patientData.ActivationDate).format()}
                    </Typography>
                    <Typography variant="body2">
                      Deactivation Date:{' '}
                      {dateTime.parse(patientData.DeactivationDate).format()}
                    </Typography>
                  </div>
                )}
              </div>
              <div>
                <DialogBillingEntitySearch
                  ref={refDlgBillingEntitySearch}
                  onPickedBillingEntity={setBillingEntityData}
                  Trigger={(props: any) => (
                    <Button {...props} variant="outlined" fullWidth>
                      Assign Billing Entity
                    </Button>
                  )}
                />
                {!!billingEntityData && (
                  <div>
                    <Typography variant="body1">
                      {billingEntityData.Name}
                    </Typography>
                    <Typography variant="body2">
                      TIN: {billingEntityData.TIN}
                    </Typography>
                    <Typography variant="body2">
                      Remittance Address:{' '}
                      {billingEntityData.RemittanceAddress?.DisplayAddress}
                    </Typography>
                    <Typography variant="body2">
                      Organization: {billingEntityData.OrganizationName}
                    </Typography>
                  </div>
                )}
              </div>
              <div>
                <DialogFeeScheduleSearch
                  ref={refDlgFeeScheduleSearch}
                  onPickedFeeSchedule={setFeeScheduleData}
                  TableProps={{
                    passFilters: {
                      organizationID: billingEntityData?.OrganizationID,
                      byStatus: 'production',
                    },
                    DataTableProps: {
                      LeftFooterItems: (
                        <Typography variant="caption">
                          Fee schedules are filtered by production only
                        </Typography>
                      ),
                    },
                  }}
                  Trigger={(props: any) => (
                    <Button
                      {...props}
                      variant="outlined"
                      fullWidth
                      disabled={!billingEntityData?.OrganizationID}>
                      Assign Fee Schedule
                    </Button>
                  )}
                />
                {!!feeScheduleData && (
                  <div>
                    <Typography variant="body1">
                      {feeScheduleData.Name}
                    </Typography>
                    <Typography variant="body2">
                      Start Date:{' '}
                      {dateTime.parse(feeScheduleData.DateStart).format()}
                    </Typography>
                    <Typography variant="body2">
                      End Date:{' '}
                      {dateTime.parse(feeScheduleData.DateEnd).format()}
                    </Typography>
                    <Typography variant="body2">
                      Notes: {feeScheduleData.Notes}
                    </Typography>
                  </div>
                )}
              </div>
              <div>
                <DialogPracticeFacilitySearch
                  ref={refDlgPracticeFacilitySearch}
                  onPickedPracticeFacility={setPracticeFacilityData}
                  TableProps={{
                    passFilters: {
                      organizationID: billingEntityData?.OrganizationID,
                    },
                  }}
                  Trigger={(props: any) => (
                    <Button
                      {...props}
                      variant="outlined"
                      fullWidth
                      disabled={!billingEntityData?.OrganizationID}>
                      Assign Practice Facility
                    </Button>
                  )}
                />
                {!!practiceFacilityData && (
                  <div>
                    <Typography variant="body1">
                      {practiceFacilityData.Name}
                    </Typography>
                    <Typography variant="body2">
                      Launch Date:{' '}
                      {dateTime.parse(practiceFacilityData.LaunchDate).format()}
                    </Typography>
                    <Typography variant="body2">
                      Term Date:{' '}
                      {dateTime
                        .parse(practiceFacilityData.TerminationDate)
                        .format()}
                    </Typography>
                    <Typography variant="body2">
                      Address: {practiceFacilityData.Address?.DisplayAddress}
                    </Typography>
                    <Typography variant="body2">
                      Notes: {practiceFacilityData.Notes}
                    </Typography>
                  </div>
                )}
              </div>
            </div>

            <DesignSuite2023.Divider style={{ marginTop: 0 }} />

            <Grid container spacing={2}>
              <Grid item xs={5}>
                <FormControl component="div" size="small">
                  <h5 style={{ margin: 0, fontSize: '110%' }}>Claim Type</h5>
                  <RadioGroup
                    row
                    name="ClaimType"
                    value={claimData?.Claim.ClaimType}
                    onChange={(ev: any) => {
                      setClaimData((curr: shapeClaimData) => ({
                        ...curr,
                        Claim: { ...curr.Claim, ClaimType: ev.target.value },
                      }))
                    }}>
                    <FormControlLabel
                      value={ClaimTypes.Professional}
                      control={<Radio size="small" />}
                      label="Professional"
                    />
                    <FormControlLabel
                      value={ClaimTypes.Institutional}
                      control={<Radio size="small" />}
                      label="Institutional"
                    />
                  </RadioGroup>
                </FormControl>

                <helpers.FlexAlign spaced>
                  <helpers.FlexAlign tag="h5">
                    <span>Diagnosis Codes</span>&nbsp;
                    <small>
                      (A) is the{' '}
                      <i>
                        <strong>principal</strong> diagnosis code
                      </i>
                    </small>
                  </helpers.FlexAlign>
                </helpers.FlexAlign>
                <DisplayCodesList
                  onCodeChange={(codes: string[]) => {
                    setClaimData((curr: shapeClaimData) => {
                      curr.Claim.DiagnosisCodes = codes
                      return { ...curr }
                    })
                  }}
                />
              </Grid>
              <Grid item xs={7}>
                {!!claimData?.Claim?.ClaimType && (
                  <DisplayOtherDetailFields
                    claimType={claimData?.Claim.ClaimType}
                    sourceType={SourceTypes.SourceTypeManual}
                    recordChange={(key: string, value: any) => {
                      setClaimData((curr: shapeClaimData) => {
                        curr.Claim.Detail[key] = value
                        return { ...curr }
                      })
                    }}
                  />
                )}
              </Grid>
            </Grid>

            <DesignSuite2023.Divider />

            {!!claimData?.Claim?.ClaimType && (
              <ClaimLinesDisplay
                claimType={claimData?.Claim?.ClaimType}
                sourceType={SourceTypes.SourceTypeManual}
                diagnosisCodes={claimData.Claim.DiagnosisCodes}
                prePopulateSingleEmptyLine
                onLinesUpdate={(ll: ShapeZClaimLine[]) => {
                  setClaimData((curr: shapeClaimData) => {
                    curr.Lines = ll
                    return { ...curr }
                  })
                }}
              />
            )}

            <div style={{ textAlign: 'right', color: '#aaa' }}>
              <small
                style={{ display: 'inline-block', cursor: 'pointer' }}
                onClick={() => setShowDebug((curr: boolean) => !curr)}>
                Debug
              </small>
            </div>
            {showDebug && (
              <DesignSuite2023.StyledPre>
                {JSON.stringify(
                  {
                    canSave,
                    claimData,
                    patientData,
                    billingEntityData,
                    practiceFacilityData,
                    feeScheduleData,
                  },
                  null,
                  '  '
                )}
              </DesignSuite2023.StyledPre>
            )}
          </StyledContent>
        </DialogContent>
        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  setIsOpen(false)
                }}>
                Cancel
              </Button>
            }
            right={
              <>
                <Button
                  disabled={!canSave}
                  color="primary"
                  variant="contained"
                  onClick={doCreateClaim}>
                  Create Claim
                </Button>
              </>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
