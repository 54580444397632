import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import * as types from './types'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import {
  getPGPKey,
  postCreatePGPKey,
  putUpdatePGPKey,
  postReadPGPKeyInfo,
  pgpKeyAPIResponse,
} from '../../../actions/EngineeringActions'
import {
  Grid,
  Button,
  Dialog as MuiDialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Switch,
  FormControlLabel,
} from '@material-ui/core'
import styled from 'styled-components'

const StyledDialogContent = styled(DialogContent)`
  width: 700px;

  .labelish {
    display: block;
  }
  .key-field-input {
    textarea {
      font-family: monospace;
      font-size: 12px;
    }
  }
  .info-fields {
    white-space: nowrap;
  }
`

const PublicKeysOnlyWarning = styled.div`
  background: aquamarine;
  border: 5px dashed rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 0 10px #1c791c;
  margin-bottom: 1rem;

  span {
    font-family: 'Comic Sans MS';
    font-weight: bold;
    display: block;
    text-align: center;
    font-size: 3.2rem;
    padding: 1rem 2rem;
    background-image: linear-gradient(
      to left,
      violet,
      indigo,
      blue,
      green,
      yellow,
      orange,
      red
    );
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-stroke: 3px #000;
    text-decoration: underline;
    white-space: nowrap;
    animation: blinker 1.75s linear infinite;
    transition: all 0.25s ease-in-out;
  }

  @keyframes blinker {
    25% {
      opacity: 0.75;
      transform: rotate3d(1, 90, 1, 25deg);
      background-image: linear-gradient(
        to left,
        green,
        yellow,
        orange,
        red,
        violet,
        indigo,
        blue
      );
    }
    75% {
      opacity: 0.75;
      transform: rotate3d(1, 90, 1, -25deg);
      background-image: linear-gradient(
        to left,
        green,
        yellow,
        orange,
        red,
        blue,
        indigo,
        violet
      );
    }
  }
`

export interface props {
  onSave?(saved: types.PGPKey): void
  sftpaasRecipientID?: number | null
}

export interface DialogAPI {
  open(): void
  editPGPKeyPublicID(pgpKeyPublicID: number): void
}

export const Dialog = forwardRef(function Dialog(
  { onSave, sftpaasRecipientID }: props,
  ref: React.Ref<DialogAPI>
): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [formData, setFormData] = useState<types.PGPKey>({} as types.PGPKey)
  const [keyFieldProps, setKeyFieldProps] = useState<any>({})
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  /*
    watcher on isOpen; anytime dialog opens, reset the formData
  */
  useEffect(() => {
    if (!isOpen) return
    // ensure always totally cleared when re-opening
    setFormData({
      ID: 0,
      SFTPaasRecipientID: sftpaasRecipientID,
    } as types.PGPKey)
    setKeyFieldProps({})
  }, [isOpen])

  /*
    watcher on publickey and allowignoreexpiration; if those change, we want to
    re-read the key info
  */
  useEffect(() => {
    if (!formData.PublicKey) return
    doCheckKeyInfo()
  }, [formData.PublicKey, formData.AllowIgnoreExpiration])

  /*
    public API to work with the dialog, while **keeping all state management of the dialog
    internal**.
  */
  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setIsOpen(true)
      },
      editPGPKeyPublicID: (pgpKeyPublicID: number) => {
        setIsOpen(true)
        loadKeyByID(pgpKeyPublicID)
      },
    }),
    [setIsOpen, setFormData]
  )

  function loadKeyByID(id: number) {
    getPGPKey(id)
      .then((res: any) => {
        if (res.error) throw res
        setFormData(res.Data)
        setKeyFieldProps({
          helperText:
            'Existing keys are locked; if you need to change it, create a new key instead',
        })
      })
      .catch(catchAPIError({ defaultMessage: 'Failed loading pgp key' }))
  }

  function doSave() {
    let route: pgpKeyAPIResponse
    switch (formData.ID >= 1) {
      case true:
        route = putUpdatePGPKey(formData)
        break
      case false:
        route = postCreatePGPKey(formData)
        break
    }

    route
      .then(({ Data }) => {
        setFormData(Data)
        showSnackbar('PGP Key saved OK', SnackbarTypeSuccess)
        onSave && onSave(Data)
        setIsOpen(false)
      })
      .catch(catchAPIError({ defaultMessage: 'Failed creating new PGP key' }))
  }

  function isDisabled() {
    if (!formData.Name) return true
    return false
  }

  function setVal(merge = {}) {
    setFormData((curr: types.PGPKey) => ({ ...curr, ...merge }))
  }

  function doCheckKeyInfo() {
    if (!formData.PublicKey) return
    if (!(formData.PublicKey || '').length) {
      setVal({
        Expiration: null,
        PublicKeyFingerprint: null,
        PublicKeyAlgo: null,
      })
      setKeyFieldProps({})
      return
    }
    postReadPGPKeyInfo(formData)
      .then(({ Data }) => {
        setVal({
          Expiration: Data.Expiration,
          PublicKeyFingerprint: Data.PublicKeyFingerprint,
          PublicKeyAlgo: Data.PublicKeyAlgo,
        })
        setKeyFieldProps({})
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed validating PGP key',
          withError: (err: any) => {
            setKeyFieldProps({ error: true, helperText: 'Key is invalid' })
            setVal({
              Expiration: null,
              PublicKeyFingerprint: null,
              PublicKeyAlgo: null,
            })
          },
        })
      )
  }

  return (
    <MuiDialog open={isOpen} maxWidth="lg">
      <DialogTitle>
        Manage PGP Key <small>Note: these are always *ONLY* public keys.</small>
      </DialogTitle>

      <StyledDialogContent>
        <p>
          <strong>Important</strong>: This should be the <i>public</i> key{' '}
          <u>provided by the recipient</u>. This is not ZERO's public key. If
          your communication with the recipient involves them requesting you to
          send anything, you're doing it wrong. They generate a key and send to
          us, end of story.
        </p>

        <PublicKeysOnlyWarning>
          <span>!pUbLic KeYs OnLy!❤️</span>
        </PublicKeysOnlyWarning>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Name"
              placeholder="..."
              variant="outlined"
              value={formData.Name || ''}
              onChange={(ev: any) => {
                setVal({ Name: ev.target.value })
              }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            {!!formData.ID && (
              <DesignSuite2023.AlertWarning style={{ marginBottom: '0.75rem' }}>
                <small>
                  Public keys cannot be edited after creation. If you need to
                  change the public key value, create a new one.
                </small>
              </DesignSuite2023.AlertWarning>
            )}
            <TextField
              className="key-field-input"
              disabled={!!formData.ID}
              fullWidth
              multiline
              rows={10}
              size="small"
              label="Public Key"
              placeholder="Paste public key here"
              variant="outlined"
              value={formData.PublicKey || ''}
              onChange={(ev: any) => {
                setVal({ PublicKey: ev.target.value })
              }}
              {...keyFieldProps}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          className="info-fields"
          justify="space-between">
          <Grid item xs="auto">
            <strong className="labelish">Expiration</strong>
            {formData.Expiration || 'N/A'}
          </Grid>
          <Grid item xs="auto">
            <strong className="labelish">KeyAlgo</strong>
            {formData.PublicKeyAlgo || 'N/A'}
          </Grid>
          <Grid item xs="auto">
            <strong className="labelish">Fingerprint</strong>
            {formData.PublicKeyFingerprint || 'N/A'}
          </Grid>
          <Grid item xs={12}>
            <DesignSuite2023.Tooltip
              title={
                <span>
                  If the key is expired (or lacks an expiration date),
                  <br />
                  you may use "Allow Ignoring Expiration" to get around
                  <br />
                  treating this as an error. It is not recommended, and
                  <br />
                  you should (probably) work with the sender of this
                  <br />
                  key to source a new one with a valid future expiration.
                </span>
              }>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={!!formData.AllowIgnoreExpiration}
                    onChange={(_: any, v: any) => {
                      setVal({ AllowIgnoreExpiration: v })
                    }}
                  />
                }
                label="Allow Ignoring Expiration"
              />
            </DesignSuite2023.Tooltip>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={5}
              size="small"
              label="Notes"
              placeholder="Notes"
              variant="outlined"
              value={formData.Notes || ''}
              onChange={(ev: any) => {
                setVal({ Notes: ev.target.value })
              }}
            />
          </Grid>
        </Grid>
      </StyledDialogContent>

      <DialogActions>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs="auto">
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                setIsOpen(false)
              }}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button
              disabled={isDisabled()}
              color="primary"
              variant="contained"
              onClick={doSave}>
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </MuiDialog>
  )
})
