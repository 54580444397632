import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FocusTrap from 'focus-trap-react'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import DetailView, {
  defaultStyleCreator,
} from '../../components/DetailView/DetailView'
import Models from '../../models'
import { PriceActions, CostKeyActions, SnackbarActions } from '../../actions'
import dateTime from '../../utils/dateTime'
import ManagedDateInput from '../../components/Inputs/managedDateInput'

import {
  FormControlLabel,
  CircularProgress,
  Divider,
  Typography,
  Grid,
  Button,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'

const { saveNewPrice, clearSaveResult, SAVE_NEW_PRICE } = PriceActions
const { setSnackbarMessage } = SnackbarActions

const styles = (theme) => {
  const defaults = defaultStyleCreator(theme)

  return Object.assign(
    {
      costKeyField: {
        marginBottom: '25px',
      },
      validationText: {
        marginTop: '25px',
      },
    },
    defaults
  )
}

export class AddPriceDialog extends DetailView {
  constructor(props) {
    super(props)

    this.initialState = {
      saving: false,
      validatingCostKey: false,
      validCostKey: false,
      costKeyValidationMessage: '',
      open: true,
      fields: Object.assign({}, Models.Price.editFields),
    }
    this.state = this.initialState

    this.renderContent = this.renderContent.bind(this)
  }

  handleSave = (e) => {
    this.setState({ saving: true })
    const { fields } = this.state
    const { fsId } = this.props
    const rowToSave = Object.assign({}, fields, {
      DateStart: dateTime
        .parse(fields.DateStart)
        .format(dateTime.formats.ISODate),
      DateEnd: dateTime.parse(fields.DateEnd).format(dateTime.formats.ISODate),
    })

    this.props.saveNewPrice({ fsId, body: rowToSave })
  }

  componentWillUnmount() {
    this.props.clearSaveResult()
    this.setState(this.initialState)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { saveResult } = nextProps

    if (saveResult) {
      if (saveResult.error) {
        const msg = `An error occurred while saving: ${saveResult.message}`
        this.props.setSnackbarMessage(msg, 'error')
      } else if (saveResult.type === SAVE_NEW_PRICE) {
        this.props.setSnackbarMessage('Price added!', 'success')
        this.props.onSave()
      }

      this.setState({ saving: false, saveResult })
      this.props.clearSaveResult()
    }
  }

  validateCostKey = (costKey) => {
    return CostKeyActions.findCostKeyByCode({ costKey })
  }

  handleCostKeyChange = (e) => {
    const val = e.target.value

    if (val && val.length >= 5) {
      this.setState({ validatingCostKey: true })
      this.validateCostKey(val).then((res) => {
        let message, valid, costKeyId
        if (res.error) {
          message = 'Invalid Cost Key'
          valid = false
        } else {
          message = res.Data.LaymanDescr
          costKeyId = res.Data.ID
          valid = true
        }
        this.setState({
          validatingCostKey: false,
          validCostKey: valid,
          costKeyValidationMessage: message,
          fields: {
            ...this.state.fields,
            CostKeyCodeID: costKeyId,
          },
        })
      })
    } else {
      this.setState({ costKeyValidationMessage: '', validCostKey: false })
    }

    this.handleChange(e)
  }

  renderDialog() {
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.onCancel}>
          <FocusTrap>
            <DialogTitle>Add New Price</DialogTitle>
            <DialogContent>{this.renderContent()}</DialogContent>
            <DialogActions>
              <Button
                onClick={this.handleSave}
                disabled={!this.state.validCostKey}
                color="primary">
                Add
              </Button>
              <Button onClick={this.props.onCancel} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </FocusTrap>
        </Dialog>
      </div>
    )
  }

  renderContent() {
    const { classes, isProd } = this.props
    if (this.state.loading)
      return <CircularProgress className={classes.progress} />

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {this.renderTextField('CostKeyPH', 'Cost Key', {
              onChange: this.handleCostKeyChange,
              className: classes.costKeyField,
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            <div>
              {this.state.validatingCostKey ? (
                <CircularProgress className={classes.progress} />
              ) : (
                <Typography className={classes.validationText} type="title">
                  {this.state.costKeyValidationMessage}
                </Typography>
              )}
            </div>
          </Grid>
        </Grid>
        <Divider className={classes.sectionDivider} />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            {this.renderPriceField('Price', 'Price')}
            {isProd ? (
              <div>
                {/* {this.renderDateField('DateStart', 'Start Date')} */}
                <ManagedDateInput
                  name="DateStart"
                  label="Start Date"
                  value={this.state.fields.DateStart}
                  setter={({ name, value }) =>
                    this.handleFieldUpdate(name, value)
                  }
                />
                {/* {this.renderDateField('DateEnd', 'End Date')} */}
                <ManagedDateInput
                  name="DateEnd"
                  label="End Date"
                  value={this.state.fields.DateEnd}
                  setter={({ name, value }) =>
                    this.handleFieldUpdate(name, value)
                  }
                />
              </div>
            ) : null}
            <div>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={this.state.fields.ExcludeFromOppAnalysis}
                    onChange={this.handleSwitchChange('ExcludeFromOpp')}
                  />
                }
                label="Exclude from Opp Analysis"
              />
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={this.state.fields.ExcludeFromPublicSearch}
                    onChange={this.handleSwitchChange('ExcludeFromSearch')}
                  />
                }
                label="Exclude from Public Search"
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            {this.renderNotesField()}
          </Grid>
          <Grid item xs={12} />
          <Grid item sm={12} lg={9}>
            {this.state.saveResult && this.state.saveResult.error && (
              <Typography type="title" className={classes.errorMsg}>
                {this.state.saveResult.message}
              </Typography>
            )}
          </Grid>
        </Grid>
      </div>
    )
  }

  render() {
    return <div>{this.renderDialog()}</div>
  }
}

AddPriceDialog.propTypes = {
  onSave: PropTypes.func,
  fsId: PropTypes.object.isRequired,
  isProd: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    saveResult: state.prices.saveResult,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { saveNewPrice, setSnackbarMessage, clearSaveResult },
    dispatch
  )
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(AddPriceDialog)
