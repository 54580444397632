import React, { forwardRef, useContext, useState } from 'react'
import * as stdTableSetup from '../../../../../hooks/useStandardTableSetup'
import { findZClaimSourceFiles } from '../../../../../actions/ZClaimActions'
import DesignSuite2023 from '../../../../../components/DesignSuite2023'
import {
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from '@material-ui/core'
import LogViewer from '../../../Components/LogViewer'
import EDISourceViewer, {
  EDISourceViewerActionLoadSourceFile,
} from '../../../Components/EDISourceViewer'
import utils from '../../../../../utils'
import dateTime from '../../../../../utils/dateTime'
import styled from 'styled-components'

// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../../../../hooks/useStandardTableSetup'

const StyledTableWrap = styled.div`
  .MuiIconButton-sizeSmall {
    padding: 7px;
    font-size: 1.3rem;
  }
`

export const defaultColumns = Object.freeze({
  ID: { name: 'ID', details: { sortName: `default` } },
  CreatedAt: {
    name: 'Created',
    details: {
      dataFormat: dateTime.cellFormatter(dateTime.formats.PrettyVerbose),
    },
  },
  Ingested: {
    name: 'Ingested',
    details: { dataFormat: utils.boolYesNoFormatter },
  },
  FileKey: { name: 'File Key', details: { width: '99%' } },
  _icons: {
    name: '',
    details: {
      dataFormat(_: any, row: any) {
        return (
          <>
            {!!row.LastProcessError && (
              <DesignSuite2023.Tooltip
                interactive
                title={
                  <DesignSuite2023.StyledPre style={{ maxWidth: '35vw' }}>
                    {row.LastProcessError}
                  </DesignSuite2023.StyledPre>
                }>
                <IconButton>
                  <DesignSuite2023.CommonIcons.IconWarning />
                </IconButton>
              </DesignSuite2023.Tooltip>
            )}
            <LogViewer uuid={row.UUID} btnSize="small" />
            &nbsp;
            <EDISourceViewer
              uuid={row.UUID}
              action={EDISourceViewerActionLoadSourceFile}
              btnSize="small"
            />
          </>
        )
      },
    },
  },
})

const defaultSort = { col: 'default', dir: 'desc' }

export const Table = forwardRef(function SourceFileTable(
  {
    customColumns = defaultColumns,
    apiEndpoint = findZClaimSourceFiles,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      initPageSize: 25,
      DataTableProps: {
        ...DataTableProps,
        density: 'small',
        rowsPerPage: [10, 25, 50, 100, 250, 500],
        rowOptsApplier(row: any) {
          if (row.LastProcessError) {
            return { className: 'tr-error' }
          }
          if (!row.Ingested) {
            return { className: 'tr-warning' }
          }
        },
      },
    },
    ref
  )

  return <StyledTableWrap>{TableDisplay}</StyledTableWrap>
})

// Filter source files by certain states
export function FilterSourceFileState(): React.ReactElement {
  const [internalID] = useState(`${Math.round(Math.random() * 10000000000000)}`)
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState<Array<any>>([
    { value: 'all', label: 'All' },
    { value: 'uningested', label: 'Not Ingested' },
    { value: 'ingested', label: 'Ingested Not Processed' },
    { value: 'extracted', label: 'Processed (to zclaims)' },
    { value: 'has_error', label: 'Errored' },
  ])

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ status: null })
    }
    setFilterImmediate({ status: sel.props.value })
  }

  const v = filter.status || 'all'

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      style={{ minWidth: 140 }}>
      <InputLabel shrink id={internalID}>
        Source File State
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Source File State"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export function FilterSourceType(): React.ReactElement {
  const [internalID] = useState(`${Math.round(Math.random() * 10000000000000)}`)
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState<Array<any>>([
    { value: 'all', label: 'All' },
    { value: '837p', label: '837P' },
    { value: '837i', label: '837I' },
    { value: 'rxngo', label: 'RxNgo' },
    { value: 'all_vba', label: 'VBA (Claims and Lines)' },
  ])

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ sourceType: null })
    }
    setFilterImmediate({ sourceType: sel.props.value })
  }

  const v = filter.sourceType || 'all'

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      style={{ minWidth: 120 }}>
      <InputLabel shrink id={internalID}>
        SourceType
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="SourceType"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export function FilterIsHidden(): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <FormControlLabel
      label="Include Hidden"
      control={
        <Switch
          color="primary"
          checked={!!filter.IncludeIsHidden}
          onChange={(e: any) => {
            setFilterImmediate({
              ...filter,
              IncludeIsHidden: e.target.checked ? true : null,
            })
          }}
        />
      }
    />
  )
}
