import React, { useState } from 'react'
import DesignSuite2023, {
  DrawerProps,
} from '../../../components/DesignSuite2023'
import EmployerReceiptDetail from './EmployerReceiptDetail'
import { PayerType, receiptTableRecord } from './ReceiptsTable'
import ProviderReceiptDetail from './ProviderReceiptDetail'

type props = DrawerProps & {
  data: receiptTableRecord
  onClose?(): void
}

export default React.forwardRef<props, any>(function (
  { data, disableEscapeKeyDown = true, onClose: propOnClose, ...spread }: props,
  ref: any
): React.ReactElement | null {
  const [component, setComponent] = useState<React.ReactElement | null>(null)

  function onOpen() {
    if (data.PayerType === PayerType.Employer) {
      setComponent(
        <EmployerReceiptDetail
          receiptID={data.ReceiptID}
          doClose={() => ref?.current?.close()}
        />
      )
    } else if (data.PayerType === PayerType.BillingEntity) {
      setComponent(
        <ProviderReceiptDetail
          receiptID={data.ReceiptID}
          doClose={() => ref?.current?.close()}
        />
      )
    }
  }

  function onClose() {
    setComponent(null)
    propOnClose?.()
  }

  return (
    <DesignSuite2023.Drawer
      {...spread}
      ref={ref}
      width="80vw"
      disableEscapeKeyDown={disableEscapeKeyDown}
      onOpen={onOpen}
      onClose={onClose}>
      {component}
    </DesignSuite2023.Drawer>
  )
})
