import React, { useState, useEffect } from 'react'
import { editEmployerMemberExcludeFromVBAExportReason } from '../../actions/MemberActions'
import DesignSuite2023 from '../../components/DesignSuite2023'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import {
  renderNotesField as RenderNotesField,
  RenderSelectField2,
  SetterArgs,
} from '../ViewHelpers'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'
import * as shapes from './types'
import Models from '../../models'

interface props {
  Trigger: any // React.ReactComponent
  empMemRec: shapes.shapeEmployerMember | null
  onSaved?(): void
}

export default function DialogSetExcludeVBAExport({
  Trigger,
  empMemRec,
  onSaved,
}: props): React.ReactElement | null {
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const [isOpen, setIsOpen] = useState(false)
  const [payload, setPayload] = useState<any>({
    ExcludeFromVBAExportReason: empMemRec?.ExcludeFromVBAExportReason || null,
    ExcludeFromVBAExportNotes: empMemRec?.ExcludeFromVBAExportNotes || null,
  })

  useEffect(() => {
    if (!isOpen) return
    setPayload({
      ExcludeFromVBAExportReason: empMemRec?.ExcludeFromVBAExportReason || null,
      ExcludeFromVBAExportNotes: empMemRec?.ExcludeFromVBAExportNotes || null,
    })
  }, [isOpen])

  function setter({ name, value }: SetterArgs) {
    setPayload((curr: any) => ({ ...curr, [name]: value }))
  }

  function doSave() {
    editEmployerMemberExcludeFromVBAExportReason({
      ...payload,
      EmployerMemberID: empMemRec?.ID,
    })
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar('Updated OK!', SnackbarTypeSuccess)
        onSaved && onSaved()
      })
      .catch(catchAPIError({ defaultMessage: 'Failed updating' }))
      .finally(() => setIsOpen(false))
  }

  if (!empMemRec) return null

  return (
    <>
      <DesignSuite2023.Tooltip title="Manage settings for excluding this member from VBA exports">
        <Trigger onClick={() => setIsOpen(true)} />
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>
          Change Member Exclude From VBA Export
          <small style={{ display: 'block' }}>
            Note: this is an eligibility related concept; if you do not know
            what this is, leave.
          </small>
        </DialogTitle>
        <DialogContent>
          <div style={{ padding: '0 0 0.5rem' }}>
            <RenderSelectField2
              name="ExcludeFromVBAExportReason"
              label="Exclude From VBA Export Reason"
              value={payload.ExcludeFromVBAExportReason}
              setter={setter}
              items={Models.Eligibility.excludeFromVBAExportReasons}
              FormControlProps={{
                margin: 'none',
                size: 'small',
                fullWidth: true,
              }}
            />
          </div>

          <RenderNotesField
            setter={setter}
            name="ExcludeFromVBAExportNotes"
            label="Notes"
            value={payload.ExcludeFromVBAExportNotes}
            opts={{
              margin: 'normal',
              variant: 'outlined',
              size: 'small',
              helperText:
                "Warning: don't ever put sensitive information in notes!",
            }}
          />

          {/* <pre>{JSON.stringify(payload, null, '  ')}</pre> */}
        </DialogContent>
        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            }
            right={
              <Button color="primary" variant="contained" onClick={doSave}>
                Save
              </Button>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
