import React, { useState } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import * as FeeScheduleTable from '../../FeeSchedules/FeeScheduleTable2'
import { putZClaimFeeSchedule } from '../../../actions/ZClaimActions'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import { IconButton, Dialog, DialogTitle } from '@material-ui/core'
import styled from 'styled-components'

const StyledDialog = styled(Dialog)`
  .std-table .header-items {
    margin-top: 0;
    padding: 0.5rem 1rem;
  }
  .MuiDialogTitle-root {
    padding: 0;
    line-height: 1;
    .MuiTypography-root {
      line-height: 1;
    }
  }
`

interface props {
  zClaimID: number
  organizationID?: number
  practiceFacilityID?: number | null
  onSelected?(): void
}

export default function FindAndLinkFeeSchedule({
  zClaimID,
  organizationID,
  practiceFacilityID = null,
  onSelected,
}: props): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  function onSelect(row: any) {
    putZClaimFeeSchedule(zClaimID, row.ID)
      .then((res: any) => {
        setIsOpen(false)
        showSnackbar('Fee schedule assigned to claim OK', SnackbarTypeSuccess)
        onSelected?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to set fee schedule',
        })
      )
  }

  if (!zClaimID || !organizationID) return null

  return (
    <>
      <DesignSuite2023.Tooltip title="Assign Fee Schedule">
        <IconButton
          size="small"
          onClick={() => setIsOpen(true)}
          style={{
            padding: '0.3rem',
            background: 'rgba(0,0,0,0.1)',
            fontSize: '110%',
          }}>
          <DesignSuite2023.CommonIcons.IconEdit fontSize="inherit" />
        </IconButton>
      </DesignSuite2023.Tooltip>

      <StyledDialog
        open={isOpen}
        maxWidth="lg"
        fullWidth
        onClose={() => setIsOpen(false)}>
        <FeeScheduleTable.Table
          passFilters={{
            organizationID,
            practiceFacilityID,
            byStatus: 'production',
          }}
          onRowClick={onSelect}
          DataTableProps={{
            density: 'small',
            LeftFooterItems: (
              <>
                {!!practiceFacilityID && (
                  <small style={{ fontWeight: 'bold' }}>
                    Auto-filtered by Practice Facility assigned on claim
                  </small>
                )}
              </>
            ),
          }}
          LeftHeaderItems={
            <DialogTitle style={{ lineHeight: 1 }}>
              <div>Link A Fee Schedule</div>
              <small>
                Fee schedules are pre-filtered by the provider (organization).
                Once you choose a fee schedule, the claim will automatically be
                readjudicated and attempt to set prices.
              </small>
            </DialogTitle>
          }
          RightHeaderItems={<FeeScheduleTable.StandardFilterSearch />}
        />
      </StyledDialog>
    </>
  )
}
