import React, { forwardRef, useContext, useEffect, useState } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import { findZClaims, getMembersInfo } from '../../../actions/ZClaimActions'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import {
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core'
import { AspectRatio as IconAspectRatio } from '@material-ui/icons'
import SourceFilePicker from './Pickers/SourceFilePicker'
// import * as CollectionPicker from './Collections/Picker'
import * as CollectionPicker from './Collections/Picker'
import LogViewer from './LogViewer'
import DisplayStatus from '../Components/Status'
import {
  EmployerSearcher,
  OrganizationSearcherV2,
  PracticeFacilitySearcher,
} from '../../../components/Searchers'
import dateTime from '../../../utils/dateTime'
import ViewClaimDetailOverlay from './ViewClaimDetailOverlay'
import DialogMemberSearch from './DialogMemberSearch'
//@ts-ignore
import styled from 'styled-components'
import utils from '../../../utils'
import helpers, { SourceTypes } from '../helpers'
import DisplayClaimID from './DisplayClaimID'

// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../../hooks/useStandardTableSetup'

const StyledTableWrap = styled.div`
  .color-soft-error {
    color: #ad4242;
  }

  ////////////////////////////////////////////////////////////////////////
  // data-table fixed height related styling!
  ////////////////////////////////////////////////////////////////////////
  &.full-fixed {
    &.zclaims-table-wrap,
    .std-table,
    .base-table-display {
      height: 100%;
    }

    .base-table-display {
      overflow: clip;
      overflow-clip-margin: 1rem;
    }

    .datatable-common-wrapper {
      height: calc(100% - 3rem);
      .MuiTableContainer-root {
        max-height: none;
        height: 100%;
      }
    }

    .std-table {
      &.has-before {
        display: flex;
        .lefty {
          position: sticky;
          top: 20px;
          align-self: start;
          padding-right: 1rem;
          width: 100%;
          max-width: 280px;
          height: 100%;
          overflow-x: hidden;
          overflow-y: scroll;
        }
        // .base-table-display {
        //   margin-top: 0;
        // }
      }
      .base-table-display {
        margin-top: 0;
      }
    }
  }
  ////////////////////////////////////////////////////////////////////////
  // end data-table fixed height related styling!
  ////////////////////////////////////////////////////////////////////////

  // hacks to tune up pagination controls when not supporting "of <N> records" (counts)
  .MuiTablePagination-root {
    .MuiToolbar-root {
      .MuiTablePagination-selectRoot {
        margin-right: 0;
      }
      .MuiTablePagination-caption:nth-of-type(2) {
        display: none;
      }
      .MuiButtonBase-root:nth-of-type(4) {
        display: none;
      }
    }
  }

  .MuiIconButton-sizeSmall {
    padding: 7px;
    font-size: 1.3rem;
  }
`

export const defaultColumns = Object.freeze({
  // CreatedAt: {name: 'Created', details: {}},
  _ViewClaim: {
    name: '',
    details: {
      dataFormat(_: any, row: any): any {
        return <InlineViewClaim zClaimID={row.ID} />
      },
    },
  },
  ID: {
    name: 'ClaimID',
    details: {
      dataFormat(_: any, row: any): any {
        return <DisplayClaimID claimObj={row} />
      },
    },
  },
  ClaimType: {
    name: 'ClaimType',
    details: {
      dataFormat(_: any, row: any): any {
        return (
          <helpers.ClaimTypeDisplay
            claimType={row.ClaimType}
            valueOnEmpty="???"
          />
        )
      },
    },
  },
  SourceType: {
    name: 'SourceType',
    details: {
      dataFormat(_: any, row: any): any {
        switch (row.SourceType) {
          case SourceTypes.SourceTypeEDI:
            return 'EDI'
          case SourceTypes.SourceTypeRxngo:
            return 'RX'
          case SourceTypes.SourceTypeGoZero:
            return 'GoZero'
          case SourceTypes.SourceTypeVBA:
            return 'VBA'
          case SourceTypes.SourceTypeManual:
            return 'Manual'
          case SourceTypes.SourceTypeClone:
            return 'Clone'
        }
        return `???`
      },
    },
  },
  ZClaimStatus: {
    name: 'Status',
    details: {
      dataFormat(_: any, row: any): any {
        return (
          <DisplayStatus
            adjStatus={row.ZClaimStatus}
            fundStatus={row.ZClaimFundingStatus}
          />
        )
      },
    },
  },
  // PatientName: {name:'PatientName', details:{dataFormat(_:any, row:any) : any {
  //   return row.PatientInfo?.Name
  // }}},
  _Human: {
    name: 'Person',
    details: {
      dataFormat(_: any, row: any): any {
        return (
          <div style={{ minWidth: 180 }}>
            {/* <div>
              {row.PatientInfo?.Name || (
                <span className="color-soft-error">(patient undetermined)</span>
              )}
            </div> */}
            {!!row.PatientInfo?.Name ? (
              <div>{row.PatientInfo.Name}</div>
            ) : (
              <div className="color-soft-error">Patient undetermined</div>
            )}
            {!!row.SubscriberInfo?.Name ? (
              <small>Subscriber: {row.SubscriberInfo.Name}</small>
            ) : (
              <small className="color-soft-error">
                Subscriber: undetermined
              </small>
            )}
          </div>
        )
      },
    },
  },
  _Provider: {
    name: 'Service Provider',
    details: {
      dataFormat(_: any, row: any): any {
        let provDisplay: any = null
        let facDisplay: any = null

        if (row.OrganizationInfo?.ID) {
          provDisplay = row.OrganizationInfo.Name
        }

        if (row.PracticeFacilityInfo?.ID) {
          facDisplay = (
            <>
              Facility:{' '}
              {row.PracticeFacilityInfo.Name ||
                `ID ${row.PracticeFacilityInfo.ID}`}
            </>
          )
        }

        if (row.BillingEntityInfo?.ID && !row.BillingEntityInfo?.IsContracted) {
          provDisplay = (
            <span style={{ color: '#777' }}>{row.BillingEntityInfo?.Name}</span>
          )
          facDisplay = (
            <span style={{ color: '#777' }}>(uncontracted provider)</span>
          )
        }

        if (!provDisplay) {
          provDisplay = (
            <span className="color-soft-error">Provider undetermined</span>
          )
        }

        if (!facDisplay) {
          facDisplay = (
            <span className="color-soft-error">Facility undetermined</span>
          )
        }

        return (
          <div style={{ minWidth: 220 }}>
            <div>{provDisplay}</div>
            <small>{facDisplay}</small>
          </div>
        )
      },
    },
  },
  _Employer: {
    name: 'Employer',
    details: {
      dataFormat(_: any, row: any): any {
        if (!row.EmployerInfo?.ID) return null
        return (
          <div style={{ minWidth: 240 }}>
            <div>{row.EmployerInfo?.Name}</div>
            <small>
              Launch: {row.EmployerInfo?.LaunchDate} | Term:{' '}
              {row.EmployerInfo?.TerminationDate}
            </small>
          </div>
        )
      },
    },
  },
  _Billed: {
    name: 'Billed($)',
    details: {
      sortName: 'zclaim.sum_billed',
      dataFormat(_: any, row: any): any {
        const sumB = row.ComputedSummary?.SumBilledAmount
        if (sumB === null || sumB === undefined) return ''
        if (!sumB.length) return ''

        // never display $-0, only negative if billed is non-zero amount
        const amt = parseFloat(row.ComputedSummary?.SumBilledAmount)
        let signNeg = ''
        let style = {}
        if (row?.Detail?.IsReversal && amt > 0) {
          signNeg = '-'
          style = { color: 'red' }
        }

        return (
          <span style={style}>
            ${signNeg}
            {utils.numberWithCommas(row.ComputedSummary?.SumBilledAmount) || ''}
          </span>
        )
      },
    },
  },
  _Price: {
    name: 'Payable($)',
    details: {
      sortName: 'zclaim.sum_payable',
      dataFormat(_: any, row: any): any {
        const sumP = row.ComputedSummary?.SumPayable
        if (sumP === null || sumP === undefined) return ''
        if (!sumP.length) return ''

        // never display $-0, only negative if payable is non-zero amount
        const amt = parseFloat(row.ComputedSummary?.SumPayable)
        let signNeg = ''
        let style = {}
        if (row?.Detail?.IsReversal && amt > 0) {
          signNeg = '-'
          style = { color: 'red' }
        }

        return (
          <span style={style}>
            ${signNeg}
            {utils.numberWithCommas(row.ComputedSummary?.SumPayable) || ''}
          </span>
        )
      },
    },
  },
  _ServiceDate: {
    name: 'Service Date',
    details: {
      sortName: 'zclaim.first_service_date',
      dataFormat(_: any, row: any): any {
        return (
          <div style={{ minWidth: 100 }}>
            {dateTime.parse(row?.ComputedSummary?.FirstServiceDate).format()}
          </div>
        )
      },
    },
  },
  // _Linked: {name:'Linked', details:{dataFormat(_:any, row:any) : any {
  //   return <div style={{display:'grid',gridTemplateColumns:'repeat(5, auto)',gridColumnGap:'0.2rem',textAlign:'center',fontSize:'80%'}}>
  //     <span>Patient</span>
  //     <span>Subscr</span>
  //     <span>Org</span>
  //     <span>BillEnt</span>
  //     <span>Facility</span>
  //     <span>{!!row.PatientInfo?.EmployerMemberID ? <DesignSuite2023.CommonIcons.IconCheck /> : <DesignSuite2023.CommonIcons.IconDeny style={{color:'red'}} />}</span>
  //     <span>{!!row.SubscriberInfo?.EmployerMemberID ? <DesignSuite2023.CommonIcons.IconCheck /> : <DesignSuite2023.CommonIcons.IconDeny style={{color:'red'}} />}</span>
  //     <span>{!!row.OrganizationInfo?.ID ? <DesignSuite2023.CommonIcons.IconCheck /> : <DesignSuite2023.CommonIcons.IconDeny style={{color:'red'}} />}</span>
  //     <span>{!!row.BillingEntityInfo?.ID ? <DesignSuite2023.CommonIcons.IconCheck /> : <DesignSuite2023.CommonIcons.IconDeny style={{color:'red'}} />}</span>
  //     <span>{!!row.PracticeFacilityInfo?.ID ? <DesignSuite2023.CommonIcons.IconCheck /> : <DesignSuite2023.CommonIcons.IconDeny style={{color:'gray'}} />}</span>
  //   </div>
  // }}},
  LineCount: {
    name: '#Lines',
    details: {
      dataFormat(_: any, row: any): any {
        return row?.ComputedSummary?.LineCount || ''
      },
    },
  },
  _icons: {
    name: '',
    details: {
      dataFormat(_: any, row: any) {
        return (
          <>
            <LogViewer uuid={row.ZClaimUUID} btnSize="small" />
            {row?.IsInTrash && (
              <DesignSuite2023.Tooltip title="This claim has been marked as trash; it should not be trusted.">
                <DesignSuite2023.CommonIcons.IconWarning color="secondary" />
              </DesignSuite2023.Tooltip>
            )}
          </>
        )
      },
    },
  },
  // @todo: service date)
})

const defaultSort = { col: 'zclaim.id', dir: 'desc' }

export const Table = forwardRef(function ClaimTableList(
  {
    customColumns = defaultColumns,
    apiEndpoint = findZClaims,
    // fnLinkOnRowClick = (row:any) => `/organization/${row.ID}`,
    initPageSize: propInitPageSize = 25,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      // fnLinkOnRowClick,
      initPageSize: propInitPageSize,
      DataTableProps: {
        ...(passThrough?.DataTableProps || {}),
        density: 'small',
        rowsPerPage: [10, 25, 50, 100, 250, 500],
        // rowOptsApplier(row:any) { return (row && row.IsActive) ? null : {className:'tr-warning'} },
      },
    },
    ref
  )

  return (
    <StyledTableWrap
      className={`zclaims-table-wrap ${passThrough?.className || ''}`}>
      {TableDisplay}
    </StyledTableWrap>
  )
})

// Filter by source files (ie. files where the claims originated from)
export function FilterSourceFiles({
  multiple = false,
  passFilters = null,
}: {
  multiple?: boolean
  passFilters?: any
}): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  function handleChange(sourceFileIDs: number[]) {
    setFilterImmediate({ sourceFileIDs })
  }

  return (
    <SourceFilePicker
      multiple={multiple}
      passFilters={passFilters}
      currentSourceFileIDs={filter.sourceFileIDs || []}
      handleChange={handleChange}
    />
  )
}

// Filter by collections (ie. files where the claims originated from)
export const FilterCollections = React.forwardRef(function FilterCollections(
  {
    multiple = false,
    passFilters = null,
    ...remaining
  }: Partial<CollectionPicker.Props>,
  ref: any
): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  function handleChange(collectionIDs: number[]) {
    setFilterImmediate({ collectionIDs })
  }

  return (
    <CollectionPicker.Picker
      {...remaining}
      ref={ref}
      multiple={multiple}
      passFilters={passFilters}
      currentCollectionIDs={filter.collectionIDs || []}
      handleChange={handleChange}
    />
  )
})

// Filter by claims that were marked "caged" during the transition from staged
// -> real claim. @todo: come up w/ better UX / wording here.
// note: material ui's select component *sucks* for using any value={}'s that aren't
// a string... YMMV
export function FilterWhereCaged(): React.ReactElement {
  const [internalID] = useState(`${Math.round(Math.random() * 10000000000000)}`)
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState<Array<any>>([
    { value: 'all', label: 'All' },
    { value: 'import_caged', label: 'Problematic' },
    { value: 'import_ok', label: 'Non-problematic' },
  ])

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ ingestState: null })
    }
    setFilterImmediate({ ingestState: sel.props.value })
  }

  const v = filter.ingestState || 'all'

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      fullWidth
      style={{ minWidth: 120 }}>
      <InputLabel shrink id={internalID}>
        Caged
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Caged"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export function FilterSrcHash({
  tooltip,
  InputProps,
}: any): React.ReactElement {
  const { filter, setFilter, setFilterImmediate } = useContext(
    stdTableSetup.baseContext
  )

  function clear() {
    setFilterImmediate({ linkedToSrcHash: '' })
  }

  return (
    <DesignSuite2023.Tooltip title={tooltip} disableFocusListener>
      <TextField
        placeholder="Src hash (advanced)"
        size="small"
        variant="outlined"
        value={filter.linkedToSrcHash}
        onChange={(ev: any) => {
          const linkedToSrcHash = ev.target?.value
          setFilter({ linkedToSrcHash })
        }}
        label="Src Hash"
        InputProps={
          InputProps
            ? InputProps
            : {
                startAdornment: (
                  <InputAdornment position="start">
                    <DesignSuite2023.CommonIcons.IconSearch />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!filter.linkedToSrcHash?.length}
                      size="small"
                      edge="end"
                      onClick={clear}>
                      <DesignSuite2023.CommonIcons.IconCancel fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }
        }
      />
    </DesignSuite2023.Tooltip>
  )
}

export function FilterExternalClaimID({
  tooltip,
  InputProps,
}: any): React.ReactElement {
  const { filter, setFilter, setFilterImmediate } = useContext(
    stdTableSetup.baseContext
  )

  function clear() {
    setFilterImmediate({ extClaimID: '' })
  }

  return (
    <DesignSuite2023.Tooltip title={tooltip} disableFocusListener>
      <TextField
        placeholder="External Claim #"
        size="small"
        variant="outlined"
        value={filter.extClaimID}
        onChange={(ev: any) => {
          const extClaimID = ev.target?.value
          setFilter({ extClaimID })
        }}
        label="External Claim #"
        InputProps={
          InputProps
            ? InputProps
            : {
                startAdornment: (
                  <InputAdornment position="start">
                    <DesignSuite2023.CommonIcons.IconSearch />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!filter.extClaimID?.length}
                      size="small"
                      edge="end"
                      onClick={clear}>
                      <DesignSuite2023.CommonIcons.IconCancel fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }
        }
      />
    </DesignSuite2023.Tooltip>
  )
}

export function FilterClearingHouseClaimNumber({
  tooltip,
  InputProps,
}: any): React.ReactElement {
  const { filter, setFilter, setFilterImmediate } = useContext(
    stdTableSetup.baseContext
  )

  function clear() {
    setFilterImmediate({ clearingHouseClaimNumber: '' })
  }

  return (
    <DesignSuite2023.Tooltip title={tooltip} disableFocusListener>
      <TextField
        placeholder="Clearing House Claim #"
        size="small"
        variant="outlined"
        value={filter.clearingHouseClaimNumber}
        onChange={(ev: any) => {
          const clearingHouseClaimNumber = ev.target?.value
          setFilter({ clearingHouseClaimNumber })
        }}
        label="Clearing House Claim #"
        InputProps={
          InputProps
            ? InputProps
            : {
                startAdornment: (
                  <InputAdornment position="start">
                    <DesignSuite2023.CommonIcons.IconSearch />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!filter.clearingHouseClaimNumber?.length}
                      size="small"
                      edge="end"
                      onClick={clear}>
                      <DesignSuite2023.CommonIcons.IconCancel fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }
        }
      />
    </DesignSuite2023.Tooltip>
  )
}

export function FilterZClaimIDs({
  tooltip,
  InputProps,
}: any): React.ReactElement {
  const { filter, setFilter, setFilterImmediate } = useContext(
    stdTableSetup.baseContext
  )

  function clear() {
    setFilterImmediate({ textualZClaimIDs: '' })
  }

  return (
    <DesignSuite2023.Tooltip title={tooltip} disableFocusListener>
      <TextField
        placeholder="ZClaimIDs"
        size="small"
        variant="outlined"
        value={filter.textualZClaimIDs}
        onChange={(ev: any) => {
          const textualZClaimIDs = ev.target?.value
          setFilter({ textualZClaimIDs })
        }}
        label="ZClaimIDs"
        InputProps={
          InputProps
            ? InputProps
            : {
                startAdornment: (
                  <InputAdornment position="start">
                    <DesignSuite2023.CommonIcons.IconSearch />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!filter.textualZClaimIDs?.length}
                      size="small"
                      edge="end"
                      onClick={clear}>
                      <DesignSuite2023.CommonIcons.IconCancel fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }
        }
      />
    </DesignSuite2023.Tooltip>
  )
}

/*
FilterHasLinkedPatient provides filtering of records where a patient is
linked or not.
*/
export function FilterHasLinkedPatient(): React.ReactElement {
  const [internalID] = useState(`${Math.round(Math.random() * 10000000000000)}`)
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState<Array<any>>([
    { value: 'all', label: 'Any' },
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ])

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ hasLinkedPatient: null })
    }
    setFilterImmediate({ hasLinkedPatient: sel.props.value === 'yes' })
  }

  let v = 'all'
  if (filter.hasLinkedPatient === true) {
    v = 'yes'
  }
  if (filter.hasLinkedPatient === false) {
    v = 'no'
  }

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      style={{ minWidth: 100 }}>
      <InputLabel shrink id={internalID}>
        Has Patient
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Has Patient"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

/*
FilterHasLinkedSubscriber provides filtering of records where a subscriber is
linked or not.
*/
export function FilterHasLinkedSubscriber(): React.ReactElement {
  const [internalID] = useState(`${Math.round(Math.random() * 10000000000000)}`)
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState<Array<any>>([
    { value: 'all', label: 'Any' },
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ])

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ hasLinkedSubscriber: null })
    }
    setFilterImmediate({ hasLinkedSubscriber: sel.props.value === 'yes' })
  }

  let v = 'all'
  if (filter.hasLinkedSubscriber === true) {
    v = 'yes'
  }
  if (filter.hasLinkedSubscriber === false) {
    v = 'no'
  }

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      style={{ minWidth: 100 }}>
      <InputLabel shrink id={internalID}>
        Has Subscriber
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Has Subscriber"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

/*
FilterHasLinkedOrganization provides filtering of records where an organization is
linked or not.
*/
export function FilterHasLinkedOrganization(): React.ReactElement {
  const [internalID] = useState(`${Math.round(Math.random() * 10000000000000)}`)
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState<Array<any>>([
    { value: 'all', label: 'Any' },
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ])

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ hasLinkedOrganization: null })
    }
    setFilterImmediate({ hasLinkedOrganization: sel.props.value === 'yes' })
  }

  let v = 'all'
  if (filter.hasLinkedOrganization === true) {
    v = 'yes'
  }
  if (filter.hasLinkedOrganization === false) {
    v = 'no'
  }

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      style={{ minWidth: 100 }}>
      <InputLabel shrink id={internalID}>
        Has Org
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Has Organization"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

/*
FilterHasLinkedPracticeFacility provides filtering of records where a practice facility is
linked or not.
*/
export function FilterHasLinkedPracticeFacility(): React.ReactElement {
  const [internalID] = useState(`${Math.round(Math.random() * 10000000000000)}`)
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState<Array<any>>([
    { value: 'all', label: 'Any' },
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ])

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ hasLinkedPracticeFacility: null })
    }
    setFilterImmediate({ hasLinkedPracticeFacility: sel.props.value === 'yes' })
  }

  let v = 'all'
  if (filter.hasLinkedPracticeFacility === true) {
    v = 'yes'
  }
  if (filter.hasLinkedPracticeFacility === false) {
    v = 'no'
  }

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      style={{ minWidth: 100 }}>
      <InputLabel shrink id={internalID}>
        Has Facility
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Has Facility"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

/*
FilterHasLinkedFeeSchedule provides filtering of records where a practice facility is
linked or not.
*/
export function FilterHasLinkedFeeSchedule(): React.ReactElement {
  const [internalID] = useState(`${Math.round(Math.random() * 10000000000000)}`)
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState<Array<any>>([
    { value: 'all', label: 'Any' },
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ])

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ hasLinkedFeeSchedule: null })
    }
    setFilterImmediate({ hasLinkedFeeSchedule: sel.props.value === 'yes' })
  }

  let v = 'all'
  if (filter.hasLinkedFeeSchedule === true) {
    v = 'yes'
  }
  if (filter.hasLinkedFeeSchedule === false) {
    v = 'no'
  }

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      style={{ minWidth: 100 }}>
      <InputLabel shrink id={internalID}>
        Has FeeSched
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Has FeeSchedule"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

/*
 */
export function FilterAdjudicationStatus(): React.ReactElement {
  const [internalID] = useState(`${Math.round(Math.random() * 10000000000000)}`)
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState<Array<any>>([
    { value: 'all', label: 'Any' },
    { value: 'pended', label: 'Pended' },
    { value: 'error', label: 'Error' },
    { value: 'denied', label: 'Denied' },
    { value: 'partially_denied', label: 'Partially Denied' },
    { value: 'payable', label: 'Payable' },
  ])

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ adjudicationStatus: null })
    }
    setFilterImmediate({ adjudicationStatus: sel.props.value })
  }

  const v = filter.adjudicationStatus || 'all'

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      fullWidth
      style={{ minWidth: 170 }}>
      <InputLabel shrink id={internalID}>
        Adjudication Status
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Adjudication Status"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export function FilterSourceType(): React.ReactElement {
  const [internalID] = useState(`${Math.round(Math.random() * 10000000000000)}`)
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState<Array<any>>([
    { value: 'all', label: 'Any' },
    { value: 'edi', label: 'EDI' },
    { value: 'rxngo', label: 'RX' },
    { value: 'gozero', label: 'GoZero' },
    { value: 'vba', label: 'VBA (legacy)' },
    { value: 'manual', label: 'Manual' },
    { value: 'clone', label: 'Clone' },
  ])

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ sourceType: null })
    }
    setFilterImmediate({ sourceType: sel.props.value })
  }

  const v = filter.sourceType || 'all'

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      fullWidth
      style={{ minWidth: 170 }}>
      <InputLabel shrink id={internalID}>
        SourceType
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="SourceType"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export function FilterClaimType(): React.ReactElement {
  const [internalID] = useState(`${Math.round(Math.random() * 10000000000000)}`)
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState<Array<any>>([
    { value: 'all', label: 'Any' },
    { value: 'pr', label: 'Professional' },
    { value: 'in', label: 'Institutional' },
    { value: 'rx', label: 'RX' },
  ])

  const onChange = (e: any, sel: any) => {
    if (sel.props?.value === 'all') {
      return setFilterImmediate({ claimType: null })
    }
    setFilterImmediate({ claimType: sel.props.value })
  }

  const v = filter.claimType || 'all'

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      fullWidth
      style={{ minWidth: 170 }}>
      <InputLabel shrink id={internalID}>
        ClaimType
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="ClaimType"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export function FilterOrganization(props: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <OrganizationSearcherV2
      {...props}
      preloadAll
      selectedOrganizationID={filter.organizationIDs?.[0]}
      onChange={(id: number | null) => {
        if (!id) {
          setFilterImmediate({ organizationIDs: [] })
          return
        }
        setFilterImmediate({ organizationIDs: [id] })
      }}
      TextFieldProps={{
        variant: 'outlined',
        size: 'small',
        InputLabelProps: { shrink: true },
        style: { minWidth: 250, maxWidth: 280 },
      }}
      AutoCompleteProps={{
        style: { display: 'inline-block', maxWidth: 280 },
      }}
    />
  )
}

export function FilterPracticeFacility(props: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <PracticeFacilitySearcher
      {...props}
      selectedPracticeFacilityID={filter.practiceFacilityIDs?.[0]}
      onChange={(id: number | null) => {
        if (!id) {
          setFilterImmediate({ practiceFacilityIDs: [] })
          return
        }
        setFilterImmediate({ practiceFacilityIDs: [id] })
      }}
      TextFieldProps={{
        variant: 'outlined',
        size: 'small',
        InputLabelProps: { shrink: true },
        style: { minWidth: 250, maxWidth: 280 },
      }}
      AutoCompleteProps={{
        style: { display: 'inline-block', maxWidth: 280 },
      }}
    />
  )
}

export function FilterEmployer(props: any): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <EmployerSearcher
      {...props}
      selectedEmployerID={filter.employerIDs?.[0]}
      onChange={(empID: number | null) => {
        if (!empID) {
          setFilterImmediate({ employerIDs: [] })
          return
        }
        setFilterImmediate({ employerIDs: [empID] })
      }}
      TextFieldProps={{
        variant: 'outlined',
        size: 'small',
        InputLabelProps: { shrink: true },
      }}
      AutoCompleteProps={{
        style: { display: 'inline-block', maxWidth: 280 },
      }}
    />
  )
}

/*
 */
export function FilterClaimsCreatedByMe(): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <FormControlLabel
      label="Claims Created By Me"
      control={
        <Switch
          color="primary"
          checked={!!filter.ClaimsCreatedByMe}
          onChange={(e: any) => {
            setFilterImmediate({
              ...filter,
              ClaimsCreatedByMe: e.target.checked,
            })
          }}
        />
      }
    />
  )
}

export function FilterMemberPicker(): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [members, setMembers] = useState<any[]>([])
  const [isInitd, setIsInitd] = useState(false)
  const refDlgMemberSearch = React.useRef<any>(null)

  useEffect(() => {
    if (!isInitd) return

    const memIDs = members?.map((m) => m.MemberID)
    if (JSON.stringify(memIDs) === JSON.stringify(filter.patientMemberIDs)) {
      return
    }

    if (!members?.length) {
      setFilterImmediate({ patientMemberIDs: [] })
      return
    }
    setFilterImmediate({ patientMemberIDs: members.map((m) => m.MemberID) })
  }, [members, filter, isInitd])

  React.useMemo(() => {
    if (!filter.patientMemberIDs?.length) {
      setIsInitd(true)
      return
    }
    getMembersInfo({
      patientMemberIDs: filter.patientMemberIDs,
    })
      .then((res: any) => {
        if (res?.error) throw res
        if (!res?.Data?.length) return
        setMembers(res.Data)
      })
      .finally(() => {
        setIsInitd(true)
      })
  }, [])

  function onLaunchSearch() {
    refDlgMemberSearch.current?.open?.()
  }

  function handlePickedMember(member: any) {
    // console.log('set member called', member)
    setMembers((curr: any[]) => {
      return [...curr, member]
    })
  }

  return (
    <fieldset
      style={{
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 4,
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: '1.1876em',
        letterSpacing: '0.00938em',
        padding: '0 12px 6px',
      }}>
      <legend
        style={{
          display: 'inline-block',
          fontSize: '0.74em',
          color: 'rgba(0, 0, 0, 0.54)',
          border: 'none',
          width: 'auto',
          margin: '0 0 0 -4px',
          padding: '0 4px',
        }}>
        Member
      </legend>

      <div style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          {members?.map((member: any) => {
            if (!member?.MemberID) return null
            return (
              <DesignSuite2023.Tooltip
                key={member.MemberID}
                title={`DOB: ${dateTime.parse(member.DOB).format()}`}>
                <Chip
                  key={member.MemberID}
                  size="small"
                  label={`${member.FirstName} ${member.LastName}`}
                  style={{ margin: '2px 2px 2px 0' }}
                  onDelete={() => {
                    setMembers((curr: any[]) => {
                      return curr.filter((m) => m.MemberID !== member.MemberID)
                    })
                  }}
                />
              </DesignSuite2023.Tooltip>
            )
          })}
        </div>

        <div>
          <div
            onClick={onLaunchSearch}
            style={{
              padding: '3px 4px 4px 4px',
              lineHeight: 1,
              background: 'rgba(0,0,0,0.075)',
              clipPath: 'circle(50%)',
              position: 'relative',
              top: -1,
              cursor: 'pointer',
            }}>
            <DesignSuite2023.CommonIcons.IconSearch fontSize="inherit" />
          </div>
        </div>
      </div>

      <DialogMemberSearch
        ref={refDlgMemberSearch}
        onPickedMember={handlePickedMember}
      />
    </fieldset>
  )
}

// To show the "claims" page (claims that are in adjudication status, or - any claims
// that do NOT have a funding status), we apply the filter HasFundingStatus: false by
// default. This component will nullify that value so the backend doesn't apply the
// filter.
export function FilterShowAllClaims(): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <FormControlLabel
      label="Show All Claims"
      control={
        <Switch
          color="primary"
          checked={filter.HasFundingStatus === null}
          onChange={(e: any) => {
            if (e.target.checked) {
              setFilterImmediate({
                HasFundingStatus: null,
              })
              return
            }
            setFilterImmediate({
              HasFundingStatus: false,
            })
          }}
        />
      }
    />
  )
}

function InlineViewClaim({ zClaimID }: any): React.ReactElement {
  const { refresh } = useContext(stdTableSetup.baseContext)
  const ref = React.useRef<any>(null)

  return (
    <>
      <DesignSuite2023.Tooltip title="View Claim">
        <IconButton size="small" onClick={() => ref?.current?.open()}>
          <IconAspectRatio fontSize="inherit" />
        </IconButton>
      </DesignSuite2023.Tooltip>
      <ViewClaimDetailOverlay
        ref={ref}
        zClaimID={zClaimID}
        onClose={() => refresh()}
      />
    </>
  )
}
