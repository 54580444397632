import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
  Checkbox,
} from '@material-ui/core'
import { TextField } from '@material-ui/core'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import styled from 'styled-components'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import ManagedDateInput from '../Inputs/managedDateInput'
import dateTime from '../../utils/dateTime'

interface TermData {
  Deletable: boolean
  FeeScheduleNames: Array<string>
  PracticeFacilityNames?: Array<string>
}

interface termPayload {
  Delete: boolean
  TerminationDate: string | null
  TerminationNotes: string | null
}

interface termProps {
  name: string | null
  entityType: string
  getTermData(): Promise<any>
  terminateEntity(payload: termPayload): Promise<any>
  onTerminated?(result: onTerminatedResult): void
}

interface onTerminatedResult {
  Destroyed: boolean
}

const Entity = styled.p`
  margin-bottom: 0px;
`

const DestroyText = styled.p`
  margin: 0px 5px 0px 0px;
`

const defaultPayload: termPayload = {
  Delete: false,
  TerminationDate: dateTime.now().format(),
  TerminationNotes: null,
}

export const TermModal = ({
  name,
  entityType,
  onTerminated,
  getTermData,
  terminateEntity,
}: termProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [termData, setTermData] = useState<TermData>()
  const [termPayload, setTermPayload] = useState<termPayload>(defaultPayload)
  const [dateError, setDateError] = useState(false)
  const { catchAPIError } = useErrorHandlers()
  const snackbar = useSnackbar()

  useEffect(() => {
    if (!isOpen) return
    setTermPayload(defaultPayload)
    getTermData()
      .then((res: TermData) => setTermData(res))
      .catch(
        catchAPIError({
          defaultMessage: `Failed loading ${entityType} data; please contact Engineering`,
          withError(err: any) {
            setIsOpen(false)
          },
        })
      )
  }, [isOpen])

  function handleTerm() {
    const payload = { ...termPayload }
    if (!payload.Delete) {
      const td = dateTime.parse(payload.TerminationDate)
      if (!td.isValid() || td.isEmpty()) {
        setDateError(true)
        return
      }
      payload.TerminationDate = td.format(dateTime.formats.ISODate)
    }

    terminateEntity(payload)
      .then((res: onTerminatedResult) => {
        if (res.Destroyed) {
          snackbar.show(`${entityType} destroyed OK`, SnackbarTypeSuccess)
        } else {
          snackbar.show(`${entityType} terminated OK`, SnackbarTypeSuccess)
        }
        onTerminated && onTerminated(res)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Termination failed; please contact Engineering',
        })
      )
      .finally(() => {
        setIsOpen(false)
      })
  }

  function onClickDestroyCheckbox(
    ev: React.ChangeEvent<HTMLInputElement>
  ): void {
    if (ev.target.checked) {
      setTermPayload({
        Delete: true,
        TerminationDate: '',
        TerminationNotes: null,
      })
      return
    }
    setTermPayload((curr: any) => ({ ...curr, Delete: false }))
  }

  function setterDateChange({ name, value }: any) {
    const v = dateTime.parse(value)
    if (v.isEmpty()) {
      setTermPayload((curr: any) => ({ ...curr, TerminationDate: null }))
      setDateError(false)
      return
    }

    if (!v.isValid()) {
      setTermPayload((curr: any) => ({ ...curr, TerminationDate: null }))
      setDateError(true)
      return
    }

    setTermPayload((curr: any) => ({
      ...curr,
      TerminationDate: v.format(dateTime.formats.ISODate),
    }))
    setDateError(false)
  }

  const renderAccordion = (entityName: string, entities: Array<string>) => (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Affected {entityName}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          {entities.map((entity) => (
            <Entity key={entity}>{entity}</Entity>
          ))}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )

  return (
    <>
      <Button
        color="secondary"
        variant="contained"
        onClick={() => {
          setIsOpen(true)
        }}>
        Terminate
      </Button>

      <Dialog open={isOpen}>
        <DialogTitle>Confirm Termination</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to terminate {entityType}{' '}
            <strong>
              <i>{name}</i>
            </strong>{' '}
            on the selected date. Would you like to proceed?
          </DialogContentText>
          {termData?.FeeScheduleNames.length ||
          termData?.PracticeFacilityNames?.length ? (
            <DialogContentText>
              Terminating this {entityType} will cascade to the following
              entities shown below
            </DialogContentText>
          ) : null}
          {termData?.FeeScheduleNames.length
            ? renderAccordion('Fee Schedules', termData.FeeScheduleNames)
            : null}
          {termData?.PracticeFacilityNames?.length
            ? renderAccordion(
                'Practice Facilities',
                termData.PracticeFacilityNames
              )
            : null}
          {!termPayload.Delete && (
            <>
              <ManagedDateInput
                name="TerminationDate"
                label="Termination Date"
                value={termPayload.TerminationDate}
                setter={setterDateChange}
                error={dateError}
              />
              <TextField
                fullWidth
                multiline
                rows={4}
                rowsMax={5}
                size="small"
                label="Notes"
                placeholder="..."
                margin="normal"
                value={termPayload.TerminationNotes || ''}
                onChange={(ev: any) => {
                  setTermPayload({
                    ...termPayload,
                    TerminationNotes: ev.target.value,
                  })
                }}
              />
            </>
          )}
          {termData?.Deletable ? (
            <Grid container direction="row" alignItems="center">
              <Checkbox
                checked={termPayload.Delete}
                onChange={onClickDestroyCheckbox}
              />
              <DestroyText>Destroy?</DestroyText>
              <p>
                By choosing <i>destroy</i>, the {entityType} will be removed
                entirely (and will not be recoverable). With this option, the
                termination date and notes are irrelevant.
              </p>
            </Grid>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" variant="contained" onClick={handleTerm}>
            {termPayload.Delete ? 'Destroy' : 'Terminate'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setIsOpen(false)
            }}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
