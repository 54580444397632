import React, { useState, useEffect } from 'react'
import {
  Button,
  IconButton,
  Typography,
  InputAdornment,
  Chip,
} from '@material-ui/core'
import {
  getEmployerPlanLimits,
  putEmployerPlanLimits,
} from '../../../actions/EmployerActions'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import CostKeyCategorySearcher from '../../../components/Searchers/CostKeyCategorySearcher'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import styled from 'styled-components'
import {
  renderTextField as RenderTextField,
  SetterArgs,
} from '../../ViewHelpers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'

const StyledLimits = styled.div`
  padding: 2rem 0;
  max-width: 1200px;

  .list-wrap {
    margin: 1rem 0 1.5rem;
    border-bottom: 1px solid #eee;
  }
`

const StyledLimitRow = styled.div`
  padding: 1.5rem 0;
  border-top: 1px solid #eee;

  .limit-conf {
    display: flex;
    grid-column-gap: 1rem;
    align-items: center;
  }

  .text-caption {
    display: block;
    padding: 0.5rem 0 0 1rem;
    line-height: 1;
  }

  .adornments {
    font-size: 70%;
    padding: 0 !important;
    line-height: 1;
    position: relative;
    top: 2px;
    cursor: pointer;
    height: auto;

    &.space-left {
      margin-left: 0.25rem;
    }

    &.green {
      background-color: #4caf50;
      color: white;
    }

    .MuiChip-label {
      padding: 0.3rem 0.5rem 0.35rem !important;
    }
  }
`

interface props {
  employerPlanID?: number | null
}

interface limitDef {
  Limits: Array<{
    EmployerPlanLimitID?: number | null
    Quantity: number
    CostKeyCategoryIDs: Array<number | null>
  }>
}

export default function Limits({
  employerPlanID,
}: props): React.ReactElement | null {
  const [data, setData] = useState<limitDef>({
    Limits: [],
  })
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (!employerPlanID) return
    load(employerPlanID)
  }, [employerPlanID])

  function load(employerPlanID: number) {
    getEmployerPlanLimits(employerPlanID)
      .then(({ Data }: any) => {
        setData((prev: any): any => {
          prev.Limits = Data
          return { ...prev }
        })
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading employer plan limits',
        })
      )
  }

  function doSave() {
    putEmployerPlanLimits(employerPlanID!, data)
      .then(() => {
        showSnackbar('Employer plan saved OK', SnackbarTypeSuccess)
        return load(employerPlanID!)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed saving employer plan limits',
        })
      )
  }

  function doAddLimit() {
    setData((prev) => ({
      Limits: [
        ...prev.Limits,
        {
          Quantity: 1,
          CostKeyCategoryIDs: [null],
        },
      ],
    }))
  }

  function doAddLimitCategory(limitIdx: number) {
    setData((prev: any): any => {
      if (!prev.Limits[limitIdx].CostKeyCategoryIDs) {
        prev.Limits[limitIdx].CostKeyCategoryIDs = []
      }
      prev.Limits[limitIdx].CostKeyCategoryIDs.push(null)
      return { ...prev }
    })
  }

  function doRemoveLimit(limitIdx: number) {
    setData((prev: any): any => {
      prev.Limits?.splice(limitIdx, 1)
      return { ...prev }
    })
  }

  function doSetLimitCategory(
    limitIdx: number,
    categoryIdx: number,
    id: number
  ) {
    setData((prev: any): any => {
      prev.Limits[limitIdx].CostKeyCategoryIDs[categoryIdx] = id
      return { ...prev }
    })
  }

  function doSetLimitQuantity(limitIdx: number, value: number | null) {
    setData((prev: any): any => {
      prev.Limits[limitIdx].Quantity = value
      return { ...prev }
    })
  }

  function removeLimitCategory(limitIdx: number, catIdx: number) {
    setData((prev: any): any => {
      prev.Limits[limitIdx].CostKeyCategoryIDs.splice(catIdx, 1)
      return { ...prev }
    })
  }

  if (!employerPlanID) return null

  return (
    <StyledLimits>
      <Typography variant="h6">Limits</Typography>
      <Typography variant="body2" color="textSecondary">
        Limits are caps on how many visits a member has available for a given
        procedure category, within a plan year. (For example: member can only
        have 20 Physical Therapy visits per plan year.)
      </Typography>

      <div className="list-wrap">
        {data.Limits.map((limit, limitIdx) => {
          return (
            <StyledLimitRow key={limitIdx}>
              <div className="limit-conf">
                <div>
                  {limit.EmployerPlanLimitID && (
                    <Chip label={`ID: ${limit.EmployerPlanLimitID}`} />
                  )}
                  {!limit.EmployerPlanLimitID && <Chip label="New" />}
                </div>
                {limit.CostKeyCategoryIDs?.map((id, catIdx) => {
                  return (
                    <CostKeyCategorySearcher
                      preloadAll
                      disableClear
                      selectedCostKeyCategoryID={id}
                      onChange={(id) => {
                        doSetLimitCategory(limitIdx, catIdx, id!)
                      }}
                      TextFieldProps={{
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <Chip
                                size="small"
                                className="adornments"
                                label="Remove"
                                onClick={() =>
                                  removeLimitCategory(limitIdx, catIdx)
                                }
                              />
                              {(!id || !limit.EmployerPlanLimitID) && (
                                <Chip
                                  size="small"
                                  className="adornments space-left green"
                                  label="New"
                                />
                              )}
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  )
                })}
                <div>
                  <DesignSuite2023.Tooltip title="Add a procedure category">
                    <IconButton
                      size="small"
                      onClick={() => {
                        doAddLimitCategory(limitIdx)
                      }}>
                      <DesignSuite2023.CommonIcons.IconAdd fontSize="inherit" />
                    </IconButton>
                  </DesignSuite2023.Tooltip>
                </div>
                <div>
                  <RenderTextField
                    use2023Styles
                    name="Quantity"
                    label="Quantity"
                    value={limit.Quantity}
                    setter={({ value }: SetterArgs) => {
                      doSetLimitQuantity(limitIdx, +value)
                    }}
                    opts={{
                      margin: 'none',
                      type: 'number',
                      style: { width: 75 },
                    }}
                  />
                </div>
                <div>
                  <DesignSuite2023.Tooltip title="Remove this limit configuration">
                    <IconButton
                      size="small"
                      color="secondary"
                      onClick={() => {
                        doRemoveLimit(limitIdx)
                      }}>
                      <DesignSuite2023.CommonIcons.IconCancel fontSize="inherit" />
                    </IconButton>
                  </DesignSuite2023.Tooltip>
                </div>
              </div>

              {limit.CostKeyCategoryIDs?.length >= 2 && (
                <Typography
                  className="text-caption"
                  variant="caption"
                  color="textSecondary">
                  This is a <strong>compound limit</strong>, meaning services
                  rendered that fall into any of these categories will count
                  against the same limit. Between all categories, only{' '}
                  {limit.Quantity} occurrences are allowed total.
                </Typography>
              )}
            </StyledLimitRow>
          )
        })}
      </div>

      <DesignSuite2023.GridLR
        left={
          <Button variant="outlined" onClick={doAddLimit}>
            Add Limit Configuration
          </Button>
        }
        right={
          <Button variant="contained" color="primary" onClick={doSave}>
            Save
          </Button>
        }
      />

      {/* <pre>{JSON.stringify(data, null, '  ')}</pre> */}
    </StyledLimits>
  )
}
