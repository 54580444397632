import React from 'react'
import Clear from '@material-ui/icons/Clear'
import Done from '@material-ui/icons/Done'
import utils from '../utils'
import { Chip } from '@material-ui/core'
import CopyIcon from '../components/CopyIcon'
import styled from 'styled-components'
import * as DesignSuite2023 from '../components/DesignSuite2023'
import dateTime from '../utils/dateTime'

const StyledPrimaryIndicator = styled.span`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #0cc351;
  box-shadow: 0 0 10px 1px #0cc351;
  border: 1px solid #42b16c;
  cursor: default;
`

const READ_PERMISSION = 'r:contact'

const contact = {
  columns: {
    Active: {
      name: 'Active',
      details: {
        dataFormat: (cell, row) => {
          return (
            <>
              {isActiveFormatter(cell, row)}
              {!!row.IsPrimary && (
                <DesignSuite2023.Tooltip title="Marked as Primary Contact">
                  <StyledPrimaryIndicator />
                </DesignSuite2023.Tooltip>
              )}
            </>
          )
        },
      },
    },
    Name: {
      name: (
        <DesignSuite2023.Tooltip title="Sort by name, starting with Last name. (Contacts marked Is Primary are bubbled up, regardless of sorted order.)">
          <span>Name</span>
        </DesignSuite2023.Tooltip>
      ),
      details: {
        sortName: 'Contact.LastNameBubblePrimary',
        dataFormat: (cell, row) => `${row.FirstName} ${row.LastName}`,
      },
    },
    Phone: {
      name: 'Phone',
      details: {
        dataFormat(cell, row) {
          let ph = utils.phoneFormatter(cell, row)
          if (row.PhoneExt) {
            ph = `${ph} Ext.${row.PhoneExt}`
          }
          return ph
        },
        style: { whiteSpace: 'nowrap' },
      },
    },
    Email: { name: 'Email', details: { dataFormat: utils.emailLinkFormatter } },
    CopyEmail: {
      name: 'Copy Email',
      details: { dataFormat: copyEmailFormatter },
    },
    Title: { name: 'Title', details: {} },
    ContactTypes: { name: 'Type', details: { dataFormat: typeFormatter } },
    Notes: { name: 'Notes', details: {} },
    Dates: { name: 'Dates', details: { dataFormat: datesFormatter } },
  },
  editFields: {
    Honorific: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    Phone: '',
    PhoneExt: '',
    Mobile: '',
    Email: '',
    Suffix: '',
    Fax: '',
    Title: '',
    Salutation: '',
    PreferredMethod: 'phone',
    ContactTypeIDs: [],
    IsPrimary: false,
    Active: true,
    Notes: '',
    Address: {
      Address1: '',
      Address2: '',
      City: '',
      State: '',
      Zip: '',
    },
    Company: '',
  },
  validators: {
    FirstName: {
      isValid: (val) => {
        return val && val.length > 1
      },
      msg: 'First Name is required',
    },
    LastName: {
      isValid: (val) => {
        return val && val.length > 1
      },
      msg: 'Last Name is required',
    },
    Phone: {
      isValid: (val) => {
        return !val || val.length === 10
      },
      msg: 'Must be valid or empty',
    },
    PhoneExt: {
      isValid: (val) => {
        return !val || +val > 0
      },
      msg: 'Must be valid or empty',
    },
    ContactTypeIDs: {
      isValid: (val) => {
        return val && val.length > 0
      },
      msg: 'Must pick a contact type',
    },
  },
  salutations: [
    { value: 'Ms', label: 'Ms' },
    { value: 'Miss', label: 'Miss' },
    { value: 'Mrs', label: 'Mrs' },
    { value: 'Mr', label: 'Mr' },
    { value: 'Dr', label: 'Dr' },
  ],
  suffixes: [
    { value: 'jr', label: 'Jr' },
    { value: 'sr', label: 'Sr' },
    { value: 'i', label: 'I' },
    { value: 'ii', label: 'II' },
    { value: 'iii', label: 'III' },
    { value: 'iv', label: 'IV' },
  ],
  legendItems: {
    Inactive: {
      color: 'yellow',
      label: 'Inactive',
    },
  },
  hasAccess: () => {
    return utils.checkPerms(READ_PERMISSION)
  },
}

function typeFormatter(cell, row) {
  if (cell && cell.length > 0) {
    return (
      <div>
        {cell.map((type, i) => {
          return (
            <Chip
              size="small"
              color="primary"
              key={type.ID}
              label={type.Description}
              style={{ margin: 1, height: 24 }}
            />
          )
        })}
        {row.IsPrimary && (
          <Chip
            size="small"
            color="secondary"
            label={'Primary'}
            style={{ margin: 1, height: 24 }}
          />
        )}
      </div>
    )
  }
  return ''
}

function isActiveFormatter(cell, row) {
  const { Active } = row

  return Active ? <Done /> : <Clear />
}

function copyEmailFormatter(_cell, row) {
  return <CopyIcon value={row['Email']} />
}

function datesFormatter(_cell, row) {
  let createdAt
  createdAt = dateTime.parse(row['CreatedAt']).format()

  let terminatedAt
  terminatedAt = dateTime.parse(row['TerminatedAt']).format()

  if (terminatedAt) {
    return (
      <div>
        <p>
          <b>Created:</b> {createdAt}
        </p>
        <p>
          <b>Deactivated:</b> {terminatedAt}
        </p>
      </div>
    )
  }

  return (
    <div>
      <p>
        <b>Created:</b> {createdAt}
      </p>
    </div>
  )
}

export default contact
