import React, { useEffect, useState } from 'react'
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import styled from 'styled-components'
import { Employer } from '../../models/types/Employer'
import { getEmployers } from '../../actions/EmployerActions'
import { Autocomplete } from '@material-ui/lab'
import useErrorHandlers from '../../hooks/useErrorHandlers'

interface Props {
  passFilters?: any
  currentEmployers: Employer[]
  saveEmployers: (arg: number[]) => void
  getOptionLabel?: (option: Employer) => string
  getOptionDisabled?: (option: Employer) => boolean
}

const EmployersCompleteContainer = styled(DialogContent)`
  width: 600px;
`

const EmployerManagerV2 = ({
  passFilters,
  currentEmployers,
  saveEmployers,
  getOptionLabel = (option: any) => option.Name,
  getOptionDisabled = () => false,
}: Props) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedEmployers, setSelectedEmployers] = useState<Employer[]>([])
  const [allEmployers, setAllEmployers] = useState<Employer[]>([])
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    getEmployers({
      sort: ['Employer.Name', 'asc'],
      filter: { ...passFilters, NoLimit: true },
    })
      .then((res: any) => {
        setAllEmployers(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to fetch Employers; please contact Engineering',
        })
      )
  }, [passFilters])

  useEffect(() => {
    setSelectedEmployers(currentEmployers)
  }, [currentEmployers])

  const handleSave = () => {
    setShowModal(false)
    const employerIDs = selectedEmployers.map((emp: any) => emp.ID)
    saveEmployers(employerIDs)
  }

  const handleClose = () => {
    setShowModal(false)
    setSelectedEmployers(currentEmployers)
  }

  const handleSelected = (e: any, value: Array<Employer>) => {
    setSelectedEmployers(value)
  }

  const checkIsOptionSelected = (value: any) => {
    let result = false
    selectedEmployers.forEach((emp: any) => {
      if (emp.ID === value.ID) result = true
    })
    return result
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setShowModal(true)}>
        Manage Employers
      </Button>
      <Dialog open={showModal}>
        <DialogTitle>Manage Employers</DialogTitle>
        <EmployersCompleteContainer>
          <Autocomplete
            multiple
            getOptionLabel={getOptionLabel}
            getOptionDisabled={getOptionDisabled}
            getOptionSelected={checkIsOptionSelected}
            options={allEmployers}
            value={selectedEmployers}
            onChange={handleSelected}
            renderInput={(params: any) => (
              <TextField
                {...params}
                variant="outlined"
                label="Employers"
                placeholder="Search..."
                InputLabelProps={{ shrink: true }}
              />
            )}
            renderTags={(value: any, getProps: any) =>
              value.map((option: any, index: number) => (
                <Chip
                  variant="outlined"
                  label={option.Name}
                  {...getProps({ index })}
                />
              ))
            }
          />
        </EmployersCompleteContainer>
        <DialogActions>
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
          <Button onClick={handleClose} variant="contained" color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EmployerManagerV2
