import React, { useRef, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import * as StopLossTable from './StopLossTable'
import styled from 'styled-components'
import StopLossModel from '../../models/StopLoss'
import { deleteStopLossContracts } from '../../actions/StopLossActions'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import useErrorHandlers from '../../hooks/useErrorHandlers'

const PaperWrapper = styled(Paper)`
  padding: 0.25rem 1rem 1rem;
`

export default function StopLoss({ history }: any) {
  const refTable = useRef()
  const snackbar = useSnackbar()
  const { catchAPIError } = useErrorHandlers()
  const [checkedItems, setCheckedItems] =
    useState<Array<typeof StopLossModel>>()
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  const handleAddNew = () => {
    history.push(`/stop_loss_contract/`)
  }

  const onItemsChecked = (selected: Array<typeof StopLossModel>) => {
    setCheckedItems(selected)
  }

  const toggleDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog)
  }

  const actionButtons = () => {
    if (checkedItems && checkedItems.length) {
      return (
        <Button
          onClick={toggleDeleteDialog}
          variant="contained"
          color="primary">
          Delete Stop Loss Contracts
        </Button>
      )
    }
  }

  const onConfirmDelete = () => {
    if (!checkedItems || !checkedItems.length) return
    deleteStopLossContracts(checkedItems.map((v: any) => v.ID))
      .then(() => {
        snackbar.show('Fee Schedules deleted!', SnackbarTypeSuccess)
        // @ts-ignore
        refTable.current?.refresh()
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to Delete Draft Fee Schedules; please contact Engineering',
        })
      )
      .finally(() => toggleDeleteDialog())
  }

  return (
    <>
      <PaperWrapper elevation={2}>
        <StopLossTable.Table
          enableURLReflection={true}
          onCheckHandler={onItemsChecked}
          passFilters={{ IncludeInactive: false }}
          ref={refTable}
          RightHeaderItems={
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddNew}>
                Add New
              </Button>
            </>
          }
          LeftHeaderItems={
            <>
              <StopLossTable.StandardFilterSearch autoFocus />
              &nbsp;&nbsp;
              <StopLossTable.FilterStatus />
            </>
          }
          DataTableProps={{ LeftFooterItems: actionButtons() }}
        />
      </PaperWrapper>
      <Dialog open={showDeleteDialog} onClose={toggleDeleteDialog}>
        <DialogTitle>Confirm Removal</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want to delete the selected Stop Loss Contract(s)?
            This cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onConfirmDelete} color="secondary">
            Delete
          </Button>
          <Button onClick={toggleDeleteDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
