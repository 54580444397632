import util from './utils'
import { ContactActions } from '../actions'
const { FIND_CONTACTS } = ContactActions

export default function (state = null, action) {
  switch (action.type) {
    case FIND_CONTACTS:
      if (action.payload.error) {
        return {
          ...state,
          contacts: null,
          count: 0,
          getContactsError: util.errorResponse(action),
        }
      }
      return {
        ...state,
        contacts: action.payload.Data,
        count: action.payload.Meta.Total,
      }
    default:
      return {
        ...state,
      }
  }
}
