import React, { useState } from 'react'
import styled from 'styled-components'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'

const StyledTextField = styled(TextField)`
  width: 350px;
  padding: 30px;
`

interface SaveParams {
  email: string
}

interface DialogProps {
  open: boolean
  currentEmail: string
  handleClose: () => void
  handleSave: (params: SaveParams) => void
}

/*
 */
const Component = (props: DialogProps) => {
  const [email, setEmail] = useState(props.currentEmail)

  const handleClose = () => {
    props.handleClose()
  }

  const handleSave = () => {
    props.handleSave({
      email,
    })
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={props.open}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Change Email</DialogTitle>
        <DialogContent>
          <StyledTextField
            label="Email Address"
            value={email}
            onChange={(e: any) => {
              setEmail(e.target.value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} color="primary">
            Change
          </Button>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export const DialogChangeEmail = (props: DialogProps) => {
  return props.open ? <Component {...props} /> : null
}
