import React, { useState, useEffect } from 'react'
import InvoiceStatusFilter from './statusFilter'
import * as api from '../../services/thezerocard/api-helper'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
import Models from '../../models'
import {
  CircularProgress,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
require('./dialogBulkStatusTransition.scss')

const { STATUS_PAID_FULL, STATUS_PAID_FARLEY, STATUS_SENT, STATUS_APPROVED } =
  Models.Invoice
const statsRequiringDate = [
  STATUS_PAID_FULL,
  STATUS_PAID_FARLEY,
  STATUS_SENT,
  STATUS_APPROVED,
]

interface props {
  isOpen: boolean
  invoices: any[]
  onDialogClose?(): void
  doAfterSave?(invoiceIDs: number[]): void
}

export default function DialogBulkStatusTransition({
  isOpen,
  invoices = [],
  onDialogClose,
  doAfterSave,
}: props): React.ReactElement | null {
  const [isWorking, setIsWorking] = useState(false)
  const [selectedStatusID, setSelectedStatusID] = useState(0)
  const [foundInvoiceStatuses, setFoundInvoiceStatuses] = useState<any>([])
  const [allInvoicesHaveStatus, setAllInvoicesHaveStatus] = useState<any>(null)
  const [dateVal, setDateVal] = useState<any>(null)
  const { showForDuration: showSnackbar } = useSnackbar()
  const [dateLabel, setDateLabel] = useState('Invalid')

  useEffect(() => {
    !isOpen && handleClose()
  }, [isOpen])

  useEffect(() => {
    switch (selectedStatusID) {
      case STATUS_SENT:
        setDateLabel('Sent On')
        break
      case STATUS_APPROVED:
        setDateLabel('Approved On')
        break
      case STATUS_PAID_FULL:
        setDateLabel('Funded On')
        break
      case STATUS_PAID_FARLEY:
        setDateLabel('Farley Paid On')
        break
    }
    // and always clear the date
    setDateVal(null)
  }, [selectedStatusID])

  // ensure dialog state resets when toggled open
  useEffect(() => {
    setIsWorking(false)
    setSelectedStatusID(0)
    setFoundInvoiceStatuses([])
    setAllInvoicesHaveStatus(null)
    setDateVal(null)
  }, [isOpen])

  // This does a quick check on all the passed in invoices to see all the current
  // StatusId's are the same. If more than one current status is found, we show a message that
  // this bulk operation is disallowed (eg. you can only bulk change the status on 1:N invoices
  // if the invoices are currently in the same status).
  useEffect(() => {
    if (!isOpen) {
      return
    }
    const foundStatuses = invoices.reduce((coll: any, curr: any) => {
      if (!!coll.find((v: any) => v.ID === curr.StatusId)) {
        return coll
      }
      coll.push(curr.Status)
      return coll
    }, [])
    setFoundInvoiceStatuses(foundStatuses)
    switch (foundStatuses.length) {
      case 0:
        setAllInvoicesHaveStatus(null)
        break
      case 1:
        setAllInvoicesHaveStatus(foundStatuses[0])
        break
      default:
        setAllInvoicesHaveStatus(null)
    }
  }, [isOpen, invoices, setFoundInvoiceStatuses, setAllInvoicesHaveStatus])

  function handleClose() {
    onDialogClose && onDialogClose()
  }

  function handleSave(): void {
    if (foundInvoiceStatuses.length > 1) {
      return
    }
    setIsWorking(true)
    api
      .put('/invoice_bulk_status_change', {
        StatusID: selectedStatusID,
        DateForStatus: dateVal,
        InvoiceIDs: invoices.map((inv: any) => inv.ID),
      })
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        showSnackbar('Status updated for invoices OK!', SnackbarTypeSuccess)
        handleClose()
        doAfterSave && doAfterSave(invoices.map((inv: any) => inv.ID))
      })
      .catch((e: any) => {
        showSnackbar(e.Error?.Message, SnackbarTypeError)
      })
      .finally(() => {
        setIsWorking(false)
      })
  }

  function isSaveDisabled(): boolean {
    if (isWorking) {
      return true
    }
    if (!allInvoicesHaveStatus) {
      return true
    }
    if (!selectedStatusID) {
      return true
    }
    if (selectedStatusID === allInvoicesHaveStatus.ID) {
      return true
    }
    return false
  }

  function shouldShowDateInput(): boolean {
    if (selectedStatusID === allInvoicesHaveStatus.ID) {
      return false
    }
    return !!statsRequiringDate.find((x) => x === selectedStatusID)
  }

  if (!isOpen || invoices.length === 0) {
    return null
  }

  return (
    <>
      <Dialog
        className="dialog-bulk-status-transition"
        open={isOpen}
        onClose={handleClose}
        maxWidth={false}>
        <DialogTitle>Bulk Edit: Invoice Status</DialogTitle>
        <DialogContent>
          {/* Refuse to proceed... */}
          {foundInvoiceStatuses.length > 1 && (
            <>
              <p>
                Detected Statuses: &nbsp;
                {foundInvoiceStatuses.map((v: any) => (
                  <Chip
                    component="span"
                    size="small"
                    key={v.ID}
                    label={v.Descr}
                  />
                ))}
              </p>
              <p>
                <strong>The selected invoices have different statuses.</strong>{' '}
                Bulk updates will only be applied if all of the selected
                invoices are currently in the same status. Close this dialog and
                retry your selection.
              </p>
            </>
          )}

          {!!allInvoicesHaveStatus && (
            <>
              {isWorking && (
                <div className="loading-wrapper">
                  <CircularProgress />
                </div>
              )}

              {!isWorking && (
                <>
                  <p className="selection-msg">
                    <strong>{invoices.length} invoices selected</strong> (with
                    current status:{' '}
                    <strong>{allInvoicesHaveStatus.Descr}</strong>)
                  </p>
                  <InvoiceStatusFilter
                    fullWidth
                    disableClearable
                    selectedID={selectedStatusID}
                    onChange={(s: any) => {
                      setSelectedStatusID(s.ID)
                    }}
                    TextFieldProps={{ label: 'Move To Status' }}
                  />

                  {selectedStatusID === allInvoicesHaveStatus.ID && (
                    <Alert severity="warning">
                      All invoices are already in status{' '}
                      <strong>{allInvoicesHaveStatus.Descr}</strong>. Nothing to
                      do...
                    </Alert>
                  )}

                  {shouldShowDateInput() && (
                    <>
                      <div className="date-input-wrapper">
                        <ManagedDateInput
                          name="_"
                          label={dateLabel}
                          value={dateVal}
                          setter={({ name, value }) => setDateVal(value)}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions className="dialog-footerbar">
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button
                color="secondary"
                variant="outlined"
                onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                disabled={isSaveDisabled()}
                color="primary"
                variant="contained"
                onClick={handleSave}>
                Save
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}
