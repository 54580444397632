import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import DetailView, {
  defaultStyleCreator,
} from '../../components/DetailView/DetailView'
import {
  TextField,
  Button,
  FormControlLabel,
  Switch,
  Chip,
  Tooltip,
  Grid,
} from '@material-ui/core'
import { Close as CloseIcon, Done as DoneIcon } from '@material-ui/icons'
import { CostKeyActions } from '../../actions'
import DRGCodeSearcher from '../../components/Searchers/DRGCodeSearcher'
import './ruleSetBuilder.css'

const styles = (theme) => {
  return Object.assign({}, defaultStyleCreator(theme))
}

export class RuleSetBuilder extends DetailView {
  constructor(props) {
    super(props)
    this.state = this.initialState = {
      ruleSet: [],
      error: null,
      showSavedOK: false,
    }
  }

  UNSAFE_componentWillMount = () => {
    const { costKeyID } = this.props
    CostKeyActions.getCostKeyCodeRuleSet({ costKeyID }).then((res) => {
      if (res.Error) {
        // If status code is NotFound, thats OK - we don't need to display an error
        if (res.Error.StatusCode === 404) {
          return
        }
        // ... but if the status code is anything else, then show an error message
        this.setState({ error: res.Error.Message })
        return
      }
      this.setState({ ruleSet: res.Data })
    })
  }

  doSaveRuleSet = () => {
    const { costKeyID } = this.props
    CostKeyActions.saveCostKeyCodeRuleSet(
      { costKeyID },
      this.state.ruleSet
    ).then((res) => {
      if (res.Error) {
        this.setState({ error: res.Error.Message })
        return
      }
      this.setState({ showSavedOK: true })
      this.saveResultTimer = setTimeout(() => {
        this.setState({ showSavedOK: false })
      }, 3000)
    })
  }

  addNewRule = () => {
    this.setState({
      ruleSet: [
        ...this.state.ruleSet,
        {
          DRG: null,
          Notes: null,
          Segments: [],
        },
      ],
    })
  }

  addNewSegment = (ruleSetIndex) => {
    return () => {
      const { ruleSet } = this.state
      ruleSet[ruleSetIndex].Segments.push({
        CPTs: [],
        Search: true,
      })
      this.setState({ ruleSet })
    }
  }

  // allow csv cpt format (52345, 23425, 34224) for convenience
  parseMultipleCPTS = (cpts, ruleSetIndex, segmentIndex) => {
    const { ruleSet } = this.state
    let parts = []

    if (cpts && cpts.indexOf(',') === 5) {
      parts = cpts.split(',')
      parts.forEach((raw) => {
        const cpt = raw.trim()
        if (!cpt || cpt.length !== 5) {
          alert(`One or more CPT codes are invalid: ${cpt}`)
          return null
        }
        ruleSet[ruleSetIndex].Segments[segmentIndex].CPTs.push(cpt)
      })
    }

    this.setState({ ruleSet })
  }

  validateAndAddCPT = (cpt, ruleSetIndex, segmentIndex) => {
    if (!cpt || cpt.length !== 5) {
      alert(
        'Invalid CPT code: must be 5 characters in length, or a comma separated list of 5 digit cpts.'
      )
      return null
    }
    const { ruleSet } = this.state

    ruleSet[ruleSetIndex].Segments[segmentIndex].CPTs.push(cpt)

    return ruleSet
  }

  addCPT = (ruleSetIndex, segmentIndex) => {
    return (ev) => {
      ev.preventDefault()
      const input = ev.target.querySelector('input')
      const val = input.value

      if (val && val.indexOf(',') === 5) {
        this.parseMultipleCPTS(val, ruleSetIndex, segmentIndex)
        input.value = null
        return
      }

      const updatedRuleSet = this.validateAndAddCPT(
        val,
        ruleSetIndex,
        segmentIndex
      )
      this.setState({ updatedRuleSet })
      input.value = null
    }
  }

  deleteCPT = (ruleSetIndex, segmentIndex, cptIndex) => {
    return () => {
      const { ruleSet } = this.state
      ruleSet[ruleSetIndex].Segments[segmentIndex].CPTs.splice(cptIndex, 1)
      this.setState({ ruleSet })
    }
  }

  deleteSegment = (ruleSetIndex, segmentIndex) => {
    return () => {
      const { ruleSet } = this.state
      ruleSet[ruleSetIndex].Segments.splice(segmentIndex, 1)
      this.setState({ ruleSet })
    }
  }

  deleteRuleSet = (ruleSetIndex) => {
    return () => {
      const { ruleSet } = this.state
      ruleSet.splice(ruleSetIndex, 1)
      this.setState({ ruleSet })
    }
  }

  toggleSearch = (ruleSetIndex, segmentIndex) => {
    return () => {
      const { ruleSet } = this.state
      ruleSet[ruleSetIndex].Segments[segmentIndex].Search =
        !ruleSet[ruleSetIndex].Segments[segmentIndex].Search
      this.setState({ ruleSet })
    }
  }

  onNotesChange = (rsIndex) => {
    return (ev) => {
      const { ruleSet } = this.state
      ruleSet[rsIndex].Notes = ev.target.value
      this.setState({ ruleSet })
    }
  }

  onDRGChange = (rsIndex, value) => {
    const { ruleSet } = this.state
    ruleSet[rsIndex].DRG = value
    this.setState({ ruleSet })
    // return (ev) => {
    //   const { ruleSet } = this.state
    //   ruleSet[rsIndex].DRG = ev.target.value
    //   this.setState({ ruleSet })
    // }
  }

  hideError = () => {
    this.setState({ error: null })
  }

  render() {
    const { classes } = this.props

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="rule-set-manager">
            <h4>Manage Associated CPTs</h4>

            {this.state.error ? (
              <div className="error-feedback">
                <p>{this.state.error}</p>
                <CloseIcon
                  className="icon-remove"
                  style={{ height: '15px', width: '15px' }}
                  onClick={this.hideError}
                />
              </div>
            ) : null}

            {this.state.ruleSet.map((rs, rsIndex) => {
              return (
                <div className="cell-rule-set" key={`crs-${rsIndex}`}>
                  <span className="id-rule-label">
                    Identification Rule {rsIndex + 1}
                    <Tooltip title="Remove this identification rule">
                      <CloseIcon
                        className="icon-remove"
                        style={{ height: '15px', width: '15px' }}
                        onClick={this.deleteRuleSet(rsIndex)}
                      />
                    </Tooltip>
                  </span>

                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <DRGCodeSearcher
                        selectedDRGCode={rs.DRG}
                        onChange={(x) => {
                          this.onDRGChange(rsIndex, x)
                        }}
                        TextFieldProps={{
                          InputLabelProps: {
                            shrink: true,
                          },
                          label: 'DRG Code',
                        }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        label="Notes"
                        multiline
                        placeholder="Enter notes here"
                        rows="1"
                        rowsMax="8"
                        value={rs.Notes ? rs.Notes : ''}
                        onChange={this.onNotesChange(rsIndex)}
                        margin="none"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>

                  {rs.Segments ? (
                    <div className="cell-segments">
                      {rs.Segments.map((seg, segIndex) => {
                        return (
                          <div
                            className="cell-segment"
                            key={`crs-${rsIndex}-seg-${segIndex}`}>
                            <span className="segment-label">
                              Segment {segIndex + 1}
                              <Tooltip title="Remove this segment">
                                <CloseIcon
                                  className="icon-remove"
                                  style={{ height: '15px', width: '15px' }}
                                  onClick={this.deleteSegment(
                                    rsIndex,
                                    segIndex
                                  )}
                                />
                              </Tooltip>
                            </span>

                            <Grid
                              container
                              direction="row"
                              component="form"
                              justify="flex-start"
                              alignItems="center"
                              spacing={1}
                              onSubmit={this.addCPT(rsIndex, segIndex)}>
                              <Grid item xs={3}>
                                <TextField
                                  fullWidth
                                  label="CPT"
                                  margin="none"
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={9}>
                                <Button
                                  type="submit"
                                  variant="outlined"
                                  size="small">
                                  Add CPT
                                </Button>
                              </Grid>
                            </Grid>

                            <div className="segment-cpts">
                              {seg.CPTs.length ? (
                                seg.CPTs.map((cpt, cptIndex) => {
                                  return (
                                    <Chip
                                      label={cpt}
                                      onDelete={this.deleteCPT(
                                        rsIndex,
                                        segIndex,
                                        cptIndex
                                      )}
                                      className="chip"
                                      key={Math.random()}
                                      color="primary"
                                    />
                                  )
                                })
                              ) : (
                                <div>(No CPTs in this segment)</div>
                              )}
                            </div>

                            <div className="search-control">
                              <FormControlLabel
                                control={
                                  <Switch
                                    color="primary"
                                    checked={
                                      this.state.ruleSet[rsIndex].Segments[
                                        segIndex
                                      ].Search
                                    }
                                    onChange={this.toggleSearch(
                                      rsIndex,
                                      segIndex
                                    )}
                                  />
                                }
                                label="Searchable?"
                              />
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  ) : null}
                  <Button
                    type="button"
                    variant="outlined"
                    size="small"
                    onClick={this.addNewSegment(rsIndex)}>
                    Add New Segment
                  </Button>
                </div>
              )
            })}
            <Button
              type="button"
              variant="outlined"
              size="small"
              onClick={this.addNewRule}>
              Add New Rule
            </Button>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div style={{ float: 'right' }}>
            {this.state.showSavedOK ? (
              <div className={classes.statusIconContainer}>
                <DoneIcon className={classes.statusIcon} />
              </div>
            ) : null}
            <Button
              color="primary"
              className={classes.button}
              variant="contained"
              onClick={this.doSaveRuleSet}>
              Save
            </Button>
          </div>
        </Grid>
      </Grid>
    )
  }
}

RuleSetBuilder.propTypes = {
  costKeyID: PropTypes.number.isRequired,
}

export default compose(withStyles(styles), withRouter)(RuleSetBuilder)
