import React, { useState } from 'react'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import { postSendBackToAdjudication } from '../../../actions/ZClaimFundingActions'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { Button } from '@material-ui/core'

interface props {
  zClaimIDs: Array<number>
  onComplete?(): void
  before?(): Promise<any>
  disabled?: boolean
}

export default React.forwardRef(function ButtonDoSendBackToAdjudication(
  { zClaimIDs, onComplete, disabled = false }: props,
  ref: any
): React.ReactElement {
  const [isWorking, setIsWorking] = useState(false)
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  function doSendBackToAdjudication() {
    setIsWorking(true)

    return postSendBackToAdjudication({ ZClaimIDs: zClaimIDs })
      .then(() => {
        showSnackbar('SendBackToAdjudication completed OK', SnackbarTypeSuccess)
        onComplete?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed SendBackToAdjudication',
        })
      )
      .finally(() => setIsWorking(false))
  }

  const lenIDs = zClaimIDs?.length >= 2 ? `(${zClaimIDs.length})` : null

  return (
    <DesignSuite2023.Tooltip title="Send selected claims back to adjudication">
      <span>
        <Button
          ref={ref}
          disabled={isWorking || disabled}
          fullWidth
          variant="outlined"
          color="secondary"
          size="small"
          onClick={doSendBackToAdjudication}>
          Revert To Adjudication {lenIDs}
          {isWorking && (
            <>
              &nbsp;
              <DesignSuite2023.LoadingSpinner size={20} show={isWorking} />
            </>
          )}
        </Button>
      </span>
    </DesignSuite2023.Tooltip>
  )
})
