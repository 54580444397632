import React, { useState, useEffect } from 'react'
import { BillingPlan } from '../../models/BillingPlan'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import { BillingPlanActions } from '../../actions'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Button,
} from '@material-ui/core'
import styled from 'styled-components'
const { getBillingPlan, deleteBillingPlan } = BillingPlanActions

interface DeleteBillingPlanDialogProps {
  open: boolean
  billingPlanID: number | null
  onCancel(): void
  onDelete(): void
}

export const DeleteBillingPlanDialog = ({
  billingPlanID,
  open,
  onCancel,
  onDelete,
}: DeleteBillingPlanDialogProps) => {
  const [billingPlan, setBillingPlan] = useState<BillingPlan>()
  const { show: showSnackbar } = useSnackbar()

  useEffect(() => {
    if (!billingPlanID) return
    getBillingPlan({ billingPlanID })
      .then((data: BillingPlan) => {
        setBillingPlan(data)
      })
      .catch((msg: string) => {
        showSnackbar(msg, SnackbarTypeError)
      })
  }, [billingPlanID])

  const handleClose = () => {
    onCancel()
  }

  const handleDelete = async () => {
    await deleteBillingPlan({ billingPlanID: billingPlanID as number }).then(
      () => {
        onDelete()
        handleClose()
      }
    )
  }

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Confirm Delete - Billing Plan</DialogTitle>
      <StyledDialogContent>
        <DialogContentText>
          Are you sure you want to delete this billing plan?
          <br />
          <br />
          <div>Billing Plan: {billingPlan?.Name}</div>
          <div>Notes: {billingPlan?.Notes}</div>
        </DialogContentText>
      </StyledDialogContent>
      <DialogActions>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs="auto">
            <Button color="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button color="primary" onClick={handleDelete}>
              Confirm
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

const StyledDialogContent = styled(DialogContent)`
  min-width: 500px;
}`
