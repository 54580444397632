import { GridSortModel } from '@mui/x-data-grid'

export interface GroupRecord {
  ID: number
  id: number // same as above, set via code; required to exist for DataGrid component
  ClaimNr: string
  ClaimType: string
  CostKey: string
  CostKeyDescr: string
  EmployerId: number
  EmployerName: string
  GroupNr: number
  HrrCity: string
  HrrCode: number
  HrrState: string
  IgnoreReason: string
  IsAdjustmentClaim: boolean
  IsIgnored: boolean
  IsVoidClaim: boolean
  Notes: string
  PaidAmount: number // float
  PatientDob: string // timestamp
  PatientId: string
  PaymentDate: string // timestamp
  PosCode: string
  PosDescr: string
  Providers: string
  ReceivedDate: string // timestamp
  RequestDate: string // timestamp
  ReviewDate: string // timestamp
  ReviewState: number
  ServiceDate: string // timestamp
  TypicalHrrPpoPrice: number // float
  TypicalPPOPrice: number // float
}

export interface CostKeyResult {
  ID: number
  Code: string
  Descr: string
  Deprecated: string // actually a bool; API is returning incorrectly
  CostKeyTier: string
  Category: string
  PricingInfo: {
    CurrentHRR: string | null
    NationalTypicalPPO: string | null
  }
}

export interface GroupFilterFields {
  PatientID: string
  EmployerID: number | null
  ProviderName: string | null
  ReviewState: number | null
  ClaimNr: string
  GroupNr: string
  SortBy: GridSortModel
  Limit: number
}

export interface EmployerRecord {
  ID: number
  Name: string
}

export interface ProviderNameRecord {
  Name: string
}

export interface TrackedLines {
  lines: Array<any>
  selectedIDs: Array<number>
  initiallySelectedIDs: Array<number>
  changed: boolean
}

export interface ReviewState {
  value: any
  label: string
}

export const GetReviewStates = () => {
  return [
    { label: 'Not worked', value: 0 },
    { label: 'Accepted when claim is paid correctly', value: 1 },
    { label: 'Question Queue', value: 3 },
    { label: 'Waiting for Bundle Claim', value: 4 },
    { label: 'Refund Letter Sent', value: 5 },
    { label: 'Sent to Farley for Reprocessing', value: 6 },
    { label: 'Travel', value: 15 },
    { label: 'Regenexx', value: 18 },
    { label: 'Wilson Electric Configurable Network', value: 19 },
    { label: 'Life Church Configurable Network', value: 20 },
    { label: 'LOA', value: 21 },
  ]
}
