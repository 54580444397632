import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'

// can optimize these to pull from application state first before fetching
export default class StatusDescriptor extends Component {
  state = {
    loading: true,
    descr: '',
  }

  checkUpdateStatus() {
    const { id, statuses } = this.props

    const curStatus = statuses.find((status) => status.ID === id)
    if (curStatus) {
      this.setState({ loading: false, descr: curStatus.Descr })
    }
  }

  componentDidMount() {
    this.checkUpdateStatus()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.checkUpdateStatus()
  }

  render() {
    const { loading, descr } = this.state
    return <div>{loading ? <CircularProgress /> : <span>{descr}</span>}</div>
  }
}

StatusDescriptor.propTypes = {
  id: PropTypes.number.isRequired,
  statuses: PropTypes.array.isRequired,
}
