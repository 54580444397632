import React, { useEffect, useState, useCallback } from 'react'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import debounce from 'lodash/debounce'
import {
  getPracticeFacility,
  findPFsNoRedux,
} from '../../actions/PracticeFacilityActions'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import './styles.scss'

interface DataItem {
  ID: number
  Name: string
}

interface Props {
  selectedPracticeFacilityID: number | null
  onChange(pfID: number | null, pf: any): void
  AutoCompleteProps?: object
  TextFieldProps?: object
  disabled?: boolean
  disableClear?: boolean
}

export default function PracticeFacilitySearcher2({
  selectedPracticeFacilityID,
  onChange,
  AutoCompleteProps = {},
  TextFieldProps = {},
  disableClear = false,
  ...props
}: Props): React.ReactElement {
  const [query, setQuery] = useState<string>('')
  const [data, setData] = useState<Array<DataItem>>(Array<DataItem>())
  const [active, setActive] = useState<DataItem | null>(null)
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (!selectedPracticeFacilityID) {
      return
    }
    if (active && active.ID === selectedPracticeFacilityID) {
      return
    }
    getPracticeFacility(selectedPracticeFacilityID)
      .then((res: any) => {
        setActive({ ID: res.ID, Name: res.Name })
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Search failed; please contact Engineering',
        })
      )
  }, [selectedPracticeFacilityID, active])

  const load = useCallback(
    debounce((q: string) => {
      findPFsNoRedux({ filter: { q } })
        .then((res: any) => {
          setData(
            res.Data.map((p: any) => {
              return { ID: p.ID, Name: p.Name, ...p }
            })
          )
        })
        .catch(
          catchAPIError({
            defaultMessage: 'Search failed; please contact Engineering',
          })
        )
    }),
    [setData, catchAPIError]
  )

  useEffect(() => {
    if (!query || query.length <= 2) return
    load(query)
  }, [query])

  const onInputChange = (ev: any, q: string, r: string) => {
    setQuery(q)
  }

  const onSelectionChange = (_: any, b: DataItem | null, reason: string) => {
    setActive(b)
    onChange(b ? b.ID : null, b)
  }

  return (
    <Autocomplete
      classes={{ root: 'autocomplete-root' }}
      disabled={props.disabled}
      options={data}
      getOptionLabel={(opt: DataItem) => opt.Name}
      getOptionSelected={(opt: DataItem) => {
        return active ? opt.ID === active.ID : false
      }}
      defaultValue={null}
      value={active}
      onChange={onSelectionChange}
      onInputChange={onInputChange}
      disableClearable={disableClear}
      renderInput={(params: any) => (
        <TextField
          {...params}
          label="Practice Facility"
          placeholder="Start typing to search facilities..."
          {...TextFieldProps}
        />
      )}
      {...AutoCompleteProps}
    />
  )
}
