import React, { useState, useEffect } from 'react'
import * as api from '../../../services/thezerocard/api-helper'
// import 'charts.css'
import styled from 'styled-components'

// skips the charts.css framework since "bar" charts are stupid simple
const StyledFundingFlowStats = styled.div`
  .datum {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    padding: 0.5rem;
    font-size: 90%;
    font-weight: 600;

    .bar-fill {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.2);
    }

    &.partially_denied .bar-fill {
      background: rgba(182, 172, 26, 0.45);
    }
    &.suspended .bar-fill {
      background: rgba(66, 26, 182, 0.45);
    }
    &.payable .bar-fill {
      background: rgba(26, 182, 71, 0.45);
    }
    &.denied .bar-fill {
      background: rgba(182, 26, 111, 0.45);
    }
  }
`

export function FundingFlowStats(): React.ReactElement {
  const [data, setData] = useState<any>(null)
  const [totalPayable, setTotalPayable] = useState<number>(0)
  const [totalClaimCount, setTotalClaimCount] = useState<number>(0)

  useEffect(() => {
    api.get(`/zclaims/reporting/funding_flow_stats`).then((res: any) => {
      if (res.error) throw res
      setData(res.Data)
      setTotalPayable(
        res.Data?.reduce(
          (acc: number, row: any) => acc + Number.parseFloat(row.SumPayable),
          0
        )
      )
      setTotalClaimCount(
        res.Data?.reduce((acc: number, row: any) => acc + row.ClaimCount, 0)
      )
    })
  }, [])

  function percentOfTotalClaimCount(input: number): number {
    return Math.round((input / totalClaimCount) * 100)
  }

  if (!data?.length || !totalPayable || !totalClaimCount)
    return <div>Loading...</div>

  return (
    <StyledFundingFlowStats className="report-funding-flow-stats">
      {data.map((row: any) => (
        <div className={`datum ${row.StatusLabel}`} key={row.StatusLabel}>
          <span
            className="bar-fill"
            style={{
              width: `${percentOfTotalClaimCount(row.ClaimCount)}%`,
            }}></span>
          <span>
            {row.StatusLabel} ({row.ClaimCount})
          </span>
          <span>${Number.parseFloat(row.SumPayable).toLocaleString()}</span>
        </div>
      ))}
    </StyledFundingFlowStats>
  )
}

export default {
  FundingFlowStats,
}
