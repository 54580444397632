import BillingEntity from './BillingEntity'
import BundlePrices from './ProviderSearch/BundlePrices'
import Bundler from './Bundler'
import ComponentFeeSchedule from './ComponentFeeSchedule'
import ComponentPrice from './ComponentPrice'
import ConfigurableNetwork from './ConfigurableNetwork'
import Contact from './Contact'
import CostKeyCode from './CostKey'
import Eligibility from './Eligibility'
import EligibilityFileConfig from './EligibilityFileConfig'
import Employer from './Employer'
import FeeSchedule from './FeeSchedule'
import File from './File'
import FileType from './FileType'
import GoZeroUsers from './GoZeroUsers'
import Group from './Group'
import InboundSftpConfig from './InboundSftpConfig'
import Invoice from './Invoice'
import Loa from './Loa'
import Member from './Member'
import Organization from './Organization'
import Partner from './Partner'
import Price from './Price'
import ProviderSearch from './ProviderSearch/ProviderSearch'
import Referral from './Referral'
import Referrer from './Referrer'
import SesNotification from './SesNotification'
import StopLoss from './StopLoss'
import TeamZeroUsers from './TeamZeroUsers'
import User from './User'
import { OpportunityAnalysis, ProspectClaimLines } from './OpportunityAnalysis'

const models = {
  BillingEntity,
  BundlePrices,
  Bundler,
  ComponentFeeSchedule,
  ComponentPrice,
  ConfigurableNetwork,
  Contact,
  CostKeyCode,
  Eligibility,
  EligibilityFileConfig,
  Employer,
  FeeSchedule,
  File,
  FileType,
  GoZeroUsers,
  Group,
  InboundSftpConfig,
  Invoice,
  Loa,
  Member,
  OpportunityAnalysis,
  Organization,
  Partner,
  Price,
  ProspectClaimLines,
  ProviderSearch,
  Referral,
  Referrer,
  SesNotification,
  StopLoss,
  TeamZeroUsers,
  User,
} as any

Object.freeze(models) // make immutable

export default models
