import * as api from '../services/thezerocard/api-helper'
import { SearchParams, Config } from '../views/Engineering/types'

export const getSFTPConfigs = (params: SearchParams) => {
  return api
    .search('/sftp_inbound_config', params)
    .then((res: any) => {
      if (res.error)
        throw 'Failed to get list of sftp configs, please contact Engineering'
      return res.Data
    })
    .catch((err: any) => {
      throw err
    })
}

export const postSFTPConfig = (body: Config) => {
  return (
    api
      .post(`/sftp_inbound_config`, body)
      //@ts-ignore
      .then((res) => res.Data)
      .catch((err: any) => {
        if (err.Error?.Message) throw err.Error.Message
        throw 'Failed to generate new sftp config, please contact Engineering'
      })
  )
}

export const updateSFTPConfig = (id: number | undefined, body: Config) => {
  return (
    api
      .put(`/sftp_inbound_config/${id}`, body)
      //@ts-ignore
      .then((res) => res.Data)
      .catch((err: any) => {
        if (err.Error?.Message) throw err.Error.Message
        throw 'Failed to update sftp config, please contact Engineering'
      })
  )
}

export const terminateSFTPConfig = (id: number | undefined, body: Config) => {
  return (
    api
      .put(`/sftp_inbound_config_terminate/${id}`, body)
      //@ts-ignore
      .then((res) => res.Data)
      .catch((err: any) => {
        if (err.Error?.Message) throw err.Error.Message
        throw 'Failed to update sftp config, please contact Engineering'
      })
  )
}
