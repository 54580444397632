import React from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { default as loGet } from 'lodash/get'
import * as api from '../../services/thezerocard/api-helper'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Button,
  Paper,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
} from '@material-ui/core'
import { ArrowBack as IconArrowBack } from '@material-ui/icons'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { Autocomplete } from '@material-ui/lab'
import DetailView, {
  defaultStyleCreator,
} from '../../components/DetailView/DetailView'
import Models from '../../models'
import { SnackbarActions, EmployerActions } from '../../actions'
import dateTime from '../../utils/dateTime'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
import './style.scss'
import { RenderSelectField2 } from '../../components/Inputs/standard'
import { getEmployerDivisions } from '../../actions/EmployerActions'

const { setSnackbarMessage } = SnackbarActions
const { getAllEmployers } = EmployerActions
const initialState = {
  fields: Object.assign({}, Models.Eligibility.addFields),
  fetchingEmployers: true,
  employers: [],
  selectedEmployer: null,
  duplicates: [],
  hardDependencyErrorMessage: null,
  isWorking: false,
  divisions: [],
}

export class EligibilityDetail extends DetailView {
  constructor(props) {
    super(props)
    this.initialState = { ...initialState }
    this.state = this.initialState
  }

  validators = Object.freeze(Models.Eligibility.validators)

  componentWillUnmount() {
    this.setState({ ...initialState })
  }

  componentDidMount() {
    const subscriberInfo = loGet(this.props, 'location.state.subscriber', null)
    const carrierName = subscriberInfo ? subscriberInfo.CarrierName : ''
    const planName = subscriberInfo ? subscriberInfo.PlanName : ''
    const augmentedState = Object.assign({}, initialState, {
      fields: {
        ...initialState.fields,
        CarrierName: carrierName,
        PlanName: planName,
      },
    })
    this.setState(augmentedState)

    getAllEmployers()
      .payload.then((res) => {
        if (res.error) {
          throw new Error(
            'Unable to load employer list at this time, which eligibility creation depends on. Please check back later, and notify engineering immediately.'
          )
        }
        this.setState({ employers: res.Data || [] })
      })
      .catch((e) => {
        this.setState({
          hardDependencyErrorMessage: e.message,
        })
      })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.fields.EmployerID !== prevState.fields.EmployerID) {
      getEmployerDivisions(this.state.fields.EmployerID)
        .then((res) => {
          this.setState({ divisions: res })
        })
        .catch((e) => {
          this.props.setSnackbarMessage(
            'Failed loading divisions for selected employer. Please try again later or contact engineering.'
          )
        })
    }
  }

  employerAutoSelectValidationProps = () => {
    if (!this.state.showValidations) {
      return null
    }
    const selected = this.state.selectedEmployer
    if (selected && loGet(selected, 'ID', 0) > 0) {
      return null
    }
    return {
      error: true,
      FormHelperTextProps: { error: true },
      helperText: 'Employer is required',
    }
  }

  executeSave = (p = {}) => {
    if (!this.validToSave()) {
      return
    }

    const subscriberInfo = loGet(this.props, 'location.state.subscriber', null)
    const employerID = loGet(
      subscriberInfo,
      'EmployerID',
      loGet(this.state, 'selectedEmployer.ID', null)
    )
    if (!employerID) {
      return
    }

    const { fields } = this.state
    const { DateOfBirth, BenefitStart, BenefitEnd } = fields
    this.setState({ isWorking: true, showValidations: false })

    const payload = Object.assign(p, Models.Eligibility.addFields, fields, {
      IsSubscriber: true,
      EmployerID: employerID,
      DateOfBirth: dateTime.parse(DateOfBirth).format(dateTime.formats.ISODate),
      BenefitStart: dateTime
        .parse(BenefitStart)
        .format(dateTime.formats.ISODate),
      BenefitEnd: dateTime.parse(BenefitEnd).format(dateTime.formats.ISODate),
    })
    if (subscriberInfo) {
      Object.assign(payload, {
        IsSubscriber: false,
        SubscriberEmployerMemberID: subscriberInfo.EmployerMemberID,
      })
    }

    saveEligibility(payload)
      /*
        res can take multiple shapes here, given the complex set of outcomes the backend can take.
        First scenario being that we encounter known conditions at the route handler level, in which
        case it responds with 'ErrData', that the UI can use to display either an error message only, or
        to launch a modal to provide further feedback.
        Second scenario is that the route handler did NOT detect issues, and that we actually try to
        create an eligibility record, but errors come back from there, in which case an 'errList' will
        be detected in the response.
        @todo: we really need to transition over to v2 handlers on the backend and cleanup api response
               normalization...
      */
      .then((res) => {
        const memberID = loGet(res.Data, 'MemberID', null)
        if (!memberID) {
          this.props.setSnackbarMessage(
            'Ambiguous outcome: it seems like this request went through, but came back with missing information. Please contact engineering if you see this.',
            'error'
          )
          return
        }

        this.props.setSnackbarMessage('Eligibility saved OK', 'success')
        this.props.history.push(`/member/${memberID}`)
      })
      .catch(({ error /* <- a boolean */, ErrData, errList, Data }) => {
        this.setState({ isWorking: false })

        if (ErrData) {
          switch (true) {
            case ErrData.AlreadyExists:
              const memberPageURL = `/member/${loGet(ErrData, 'DuplicateInfo.MemberID')}`
              this.props.setSnackbarMessage(
                // snackbar messages can themselves be components - don't have to be strings
                <span>
                  {ErrData.Message}.&nbsp;
                  <a
                    href={memberPageURL}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'underline', color: 'inherit' }}>
                    View this member.
                  </a>
                </span>,
                'error'
              )
              break
            case ErrData.HasDuplicates:
              this.setState({ duplicates: ErrData.Duplicates })
              break
            default:
              this.props.setSnackbarMessage(
                'An unknown error occurred; please contact engineering',
                'error'
              )
          }
          return
        }

        if (Array.isArray(errList) && errList.length > 0) {
          this.props.setSnackbarMessage(
            <div>
              One or more errors occurred:
              {errList.map((m) => {
                return <span style={{ display: 'block' }}>{m}</span>
              })}
            </div>,
            'error'
          )
          return
        }

        if (error) {
          this.props.setSnackbarMessage(
            'An error occurred adding this eligibility record, with unkown details. Please contact engineering if you see this.',
            'error'
          )
          return
        }
      })
  }

  onClickSave = () => {
    this.executeSave({})
  }

  saveIgnoringDuplicates = () => {
    this.executeSave({ IgnoreDuplicates: true })
  }

  getEmployerLink = (empID) => {
    if (empID > 0) {
      return `${window.location.origin}/employer/${empID}`
    }
    return ``
  }

  getMemberPageLink = (memberID, employerMemberID) => {
    const url = `${window.location.origin}/member/${memberID}`
    if (employerMemberID) {
      return `${url}?employerMemberID=${employerMemberID}`
    }
    return url
  }

  useSubscriberAddress = (ev) => {
    if (ev.target.checked) {
      const subscriberInfo = loGet(this.props, 'location.state.subscriber', {})
      getEligibilityAddress(subscriberInfo.AddressID).then(
        ({ Data, error, errList }) => {
          if (error) {
            if (errList) {
              this.props.setSnackbarMessage(errList.join('; '), 'error')
              return
            }
            this.props.setSnackbarMessage(
              'Failed loading address for existing subscriber. Please try again later or contact engineering.',
              'error'
            )
            return
          }
          this.setState({
            fields: {
              ...this.state.fields,
              UseAddressID: Data.ID,
              Address1: Data.Address1,
              Address2: Data.Address2,
              City: Data.City,
              State: Data.State,
              Zip: Data.Zip,
            },
          })
        }
      )
      return
    }
    this.setState({
      fields: {
        ...this.state.fields,
        UseAddressID: null,
        Address1: '',
        Address2: '',
        City: '',
        State: '',
        Zip: '',
      },
    })
  }

  renderDivisionSelect(name, label) {
    let divisions = this.state.divisions ? this.state.divisions : []

    let items = divisions.map((d) => ({
      label: d.Name,
      value: d.ID,
    }))

    let value = ''
    if (this.state.fields && this.state.fields[name]) {
      value = this.state.fields[name]
    }

    const setter = (e) => {
      this.handleFieldUpdate(e.name, e.value)
    }

    return (
      <>
        <RenderSelectField2
          name={name}
          label={label}
          value={value}
          setter={setter}
          items={items}
          FormControlProps={{
            margin: 'none',
            size: 'small',
            fullWidth: true,
          }}
        />
      </>
    )
  }

  renderSubsetSelect(name, label) {
    let value = ''
    if (this.state.fields && this.state.fields[name]) {
      value = this.state.fields[name]
    }

    const setter = (e) => {
      this.handleFieldUpdate(e.name, e.value)
    }

    return (
      <>
        <RenderSelectField2
          name={name}
          label={label}
          value={value}
          setter={setter}
          items={Models.Eligibility.subsetHandles}
          FormControlProps={{
            margin: 'none',
            size: 'small',
            fullWidth: true,
          }}
        />
      </>
    )
  }

  renderGenderSelect(name, label) {
    let genders = [
      { label: 'Male', value: 'M' },
      { label: 'Female', value: 'F' },
      { label: 'Other', value: 'O' },
    ]

    let value = ''
    if (this.state.fields && this.state.fields[name]) {
      value = this.state.fields[name]
    }

    const setter = (e) => {
      this.handleFieldUpdate(e.name, e.value)
    }

    return (
      <>
        <RenderSelectField2
          name={name}
          label={label}
          value={value}
          setter={setter}
          items={genders}
          FormControlProps={{
            margin: 'none',
            size: 'small',
            fullWidth: true,
          }}
        />
      </>
    )
  }

  render() {
    const { classes } = this.props
    const subscriberInfo = loGet(this.props, 'location.state.subscriber', {})
    const isAddingDepMode = subscriberInfo.EmployerMemberID > 0
    const canUseSubscriberAddress =
      isAddingDepMode && subscriberInfo.AddressID > 0
    const usingSubscriberAddress = !!this.state.fields.UseAddressID
    const pickedEmployerURL = this.getEmployerLink(
      loGet(this.state, 'selectedEmployer.ID', 0)
    )

    if (this.state.hardDependencyErrorMessage) {
      return (
        <div>
          <p>{this.state.hardDependencyErrorMessage}</p>
        </div>
      )
    }

    return (
      <>
        <Paper className={classes.mainContent} elevation={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              {isAddingDepMode ? (
                <blockquote>
                  You are adding a new <strong>dependent</strong> of existing
                  subscriber{' '}
                  <strong>
                    {subscriberInfo.FirstName} {subscriberInfo.LastName}
                  </strong>{' '}
                  (employed by <strong>{subscriberInfo.EmployerName}</strong> |
                  EmployerID: {subscriberInfo.EmployerID})
                </blockquote>
              ) : (
                <blockquote>
                  You are adding a new <strong>subscriber</strong>. To add a
                  dependent, you must first{' '}
                  <i>
                    locate the subscriber using eligibility search, then use the
                    'Add Dependent' button from there.
                  </i>
                </blockquote>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} className={classes.cardColumn}>
              <Card>
                <CardHeader
                  title="Employment Details"
                  subheader="Details pertaining to the member's employment"
                />
                <CardContent>
                  {!isAddingDepMode && (
                    <Autocomplete
                      options={this.state.employers}
                      getOptionLabel={(e) => {
                        return e.Name || ''
                      }}
                      getOptionSelected={(e) =>
                        +e.ID === this.state.selectedEmployer.ID
                      }
                      value={this.state.selectedEmployer}
                      defaultValue={null}
                      onChange={(_, selectedEmployer) => {
                        this.setState({
                          selectedEmployer,
                          fields: {
                            ...this.state.fields,
                            EmployerID: selectedEmployer
                              ? selectedEmployer.ID
                              : null,
                          },
                        })
                      }}
                      loading={this.state.fetchingEmployers}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Select Employer"
                          placeholder="Select Employer"
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          {...this.employerAutoSelectValidationProps()}
                        />
                      )}
                    />
                  )}
                  {!isAddingDepMode && !!pickedEmployerURL && (
                    <Typography variant="caption">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={pickedEmployerURL}>
                        View this employer
                      </a>
                    </Typography>
                  )}
                  {isAddingDepMode &&
                    this.renderSelect(
                      'RelationToSubscriber',
                      'Relation to Subscriber',
                      Models.Eligibility.relationships
                    )}
                  <ManagedDateInput
                    name="BenefitStart"
                    label="Benefit Start"
                    value={this.state.fields.BenefitStart}
                    setter={({ name, value }) =>
                      this.handleFieldUpdate(name, value)
                    }
                  />
                  <ManagedDateInput
                    name="BenefitEnd"
                    label="Benefit End"
                    value={this.state.fields.BenefitEnd}
                    setter={({ name, value }) =>
                      this.handleFieldUpdate(name, value)
                    }
                  />
                  <Box mt={1}>
                    {this.renderDivisionSelect('DivisionID', 'Division')}
                  </Box>
                  <Box mt={1}>
                    {this.renderSubsetSelect('Subset', 'Subset')}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3} className={classes.cardColumn}>
              <Card>
                <CardHeader
                  title="Member Details"
                  subheader="Personal information about the member"
                />
                <CardContent>
                  {this.renderTextField('FirstName', 'First Name')}
                  {this.renderTextField('MiddleName', 'Middle Name')}
                  {this.renderTextField('LastName', 'Last Name')}
                  {this.renderTextField('Suffix', 'Suffix')}
                  <ManagedDateInput
                    name="DateOfBirth"
                    label="DOB"
                    value={this.state.fields.DateOfBirth}
                    setter={({ name, value }) =>
                      this.handleFieldUpdate(name, value)
                    }
                  />
                  {this.renderSSNField('SSN', 'SSN')}
                  <Box mt={2}>
                    {this.renderGenderSelect('Gender', 'Gender')}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3} className={classes.cardColumn}>
              <Card>
                <CardHeader title="Address" subheader="The member's address" />
                <CardContent>
                  {canUseSubscriberAddress && (
                    <label>
                      <input
                        type="checkbox"
                        onClick={this.useSubscriberAddress}
                      />{' '}
                      Use same address as subscriber
                    </label>
                  )}
                  {this.renderTextField('Address1', 'Address 1', {
                    disabled: usingSubscriberAddress,
                  })}
                  {this.renderTextField('Address2', 'Address 2', {
                    disabled: usingSubscriberAddress,
                  })}
                  {this.renderTextField('City', 'City', {
                    disabled: usingSubscriberAddress,
                  })}
                  {this.renderTextField('State', 'State', {
                    disabled: usingSubscriberAddress,
                  })}
                  {this.renderTextField('Zip', 'Zip', {
                    disabled: usingSubscriberAddress,
                  })}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3} className={classes.cardColumn}>
              <Card>
                <CardHeader
                  title="Contact Info"
                  subheader="The member's contact information"
                />
                <CardContent>
                  {this.renderTextField('Email', 'Email')}
                  {this.renderPhoneField('HomePhone', 'Home Phone')}
                  {this.renderPhoneField('WorkPhone', 'Work Phone')}
                </CardContent>
              </Card>
              <Card>
                <CardHeader
                  title="Insurance Info"
                  subheader="The member's insurance information"
                />
                <CardContent>
                  {this.renderTextField('CarrierName', 'Carrier Name')}
                  {this.renderTextField('PlanName', 'Plan Name')}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3} className={classes.cardColumn}>
              <Card>
                <CardHeader
                  title="Notes"
                  subheader="Notes about this eligibility record"
                />
                <CardContent>{this.renderNotesField()}</CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: '1.5rem' }}>
            <Grid item xs={12} sm={6}>
              <Button
                onClick={() => {
                  this.props.history.push('/eligibility_console')
                }}>
                <IconArrowBack /> Back to Eligibility
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.saveButtonContainer}>
                <Button
                  color="primary"
                  className={classes.button}
                  variant="contained"
                  onClick={this.onClickSave}
                  disabled={this.state.isWorking}>
                  {this.state.isWorking ? (
                    <CircularProgress className={classes.progress} />
                  ) : (
                    'Save'
                  )}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Paper>

        {!!this.state.duplicates.length && (
          <Dialog
            className="duplicates-dialog"
            open={!!this.state.duplicates.length}>
            <DialogTitle>Duplicate(s) Detected</DialogTitle>
            <DialogContent>
              {/* generating this link below, we know all duplicates have the same MemberID, so we just take the first one */}
              <p>
                This member (ID:{' '}
                <a
                  href={this.getMemberPageLink(
                    this.state.duplicates[0].MemberID
                  )}
                  target="_blank"
                  rel="noopener noreferrer">
                  {this.state.duplicates[0].MemberID}
                </a>
                ) already exists in our system, associated with one or more
                employers:
              </p>
              <div className="duplicate-list">
                <div className="duplicate-list-label">Employer:</div>
                <div className="duplicate-list-label">Employer Member ID:</div>
                <div className="duplicate-list-label">Integration ID:</div>
                {this.state.duplicates.map((v) => (
                  <React.Fragment key={v.EmployerMemberID}>
                    <div>
                      <Tooltip placement="top" title="Opens in new window">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={this.getEmployerLink(v.EmployerID)}>
                          {v.EmployerName}
                        </a>
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip placement="top" title="Opens in new window">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={this.getMemberPageLink(
                            v.MemberID,
                            v.EmployerMemberID
                          )}>
                          {v.EmployerMemberID}
                        </a>
                      </Tooltip>
                    </div>
                    <div>{v.IntegrationID}</div>
                  </React.Fragment>
                ))}
              </div>
              <blockquote>
                <strong>
                  You can still proceed and link this member to{' '}
                  {this.state.selectedEmployer
                    ? this.state.selectedEmployer.Name
                    : subscriberInfo.EmployerName}
                </strong>
                . If you choose Proceed below, you should be aware of any
                potential side effects.{' '}
                <sub>
                  Attn: Anna &amp; McKenna{' '}
                  <span role="img" aria-label="eyes">
                    👀
                  </span>
                </sub>
              </blockquote>
            </DialogContent>
            <DialogActions>
              <Grid container spacing={2} justify="space-between">
                <Grid item xs="auto">
                  <Button
                    onClick={() => {
                      this.setState({ duplicates: [] })
                    }}
                    color="secondary">
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs="auto">
                  <Button
                    onClick={this.saveIgnoringDuplicates}
                    color="primary"
                    disabled={this.state.isWorking}>
                    {this.state.isWorking ? (
                      <CircularProgress
                        className={this.props.classes.progress}
                      />
                    ) : (
                      'Proceed'
                    )}
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        )}
      </>
    )
  }
}

export default compose(
  withStyles((theme) => {
    return defaultStyleCreator(theme)
  }),
  withRouter,
  connect(
    () => ({}),
    (dispatch) => {
      return bindActionCreators({ setSnackbarMessage }, dispatch)
    }
  )
)(EligibilityDetail)

function saveEligibility(payload) {
  return api.post('/eligibility', payload)
}

function getEligibilityAddress(addressID) {
  return api.get(`/eligibility/address/${addressID}`)
}
