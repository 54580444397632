import React, { useState, useEffect, useRef } from 'react'
import {
  getZClaimSrcRecord,
  putZClaimSrcRecordDefaulterEmployerMember,
} from '../../../../actions/ZClaimActions'
import DesignSuite2023 from '../../../../components/DesignSuite2023'
import styled from 'styled-components'
import DisplayKeyValueData from '../../Components/DisplayKeyValueData'
import * as EligibilityTable from '../../../Eligibility/EligibilityTable2'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import * as ClaimTable from '../../Components/ClaimTable'
import dateTime from '.././../../../utils/dateTime'
import { Typography } from '@material-ui/core'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'
import useSnackbar from '../../../../hooks/useSnackbar'

const StyledWrap = styled.div`
  padding: 1.5rem;

  .std-table .header-items {
    margin-top: 0;
  }

  h4 {
    margin: 0;
  }

  .flexd {
    display: flex;
    align-items: start;
    width: 100%;

    .src-info {
      padding-right: 1rem;
    }

    .right {
      flex: 1;
      overflow: clip;
      overflow-clip-margin: 1rem;
    }
  }
`

interface props {
  kind: string
  hash: string
}

export default function ReconcileSrcPatientOrSubscriber({
  kind,
  hash,
}: props): React.ReactElement {
  const [srcRec, setSrcRec] = useState<any>(null)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const refClaimsTable = useRef<any>(null)

  useEffect(() => {
    getZClaimSrcRecord({ kind, hash }).then(({ Data }: any) => {
      setSrcRec(Data)
    })
  }, [kind, hash])

  function onSelect(row: any) {
    ConfirmDialog({
      content: (
        <div>
          <Typography variant="h5">
            {row.FirstName} {row.LastName} <small>({row.EmployerName})</small>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <strong>DOB: </strong>{' '}
            <span>{dateTime.parse(row.DOB).format()}</span>;&nbsp;
            <strong>Activation: </strong>{' '}
            <span>{dateTime.parse(row.ActivationDate).format()}</span>;&nbsp;
            <strong>Deactivation: </strong>{' '}
            <span>
              {dateTime.parse(row.DeactivationDate).format() || '(no deac)'}
            </span>
          </Typography>
          <DesignSuite2023.Divider />
          <Typography variant="body1">
            You selected the above (employer) Member as the default for this
            source record. All future claims with the same patient or subscriber
            data will automatically link to this person, and any current records
            **that have not manually assigned the member** will be auto-set.
          </Typography>
        </div>
      ),
      onConfirm() {
        putZClaimSrcRecordDefaulterEmployerMember({
          Hash: srcRec.Hash,
          EmployerMemberID: row.EmployerMemberID,
        })
          .then(() => {
            showSnackbar('Assigned defaulter for source record OK', 'success')
            refClaimsTable.current?.refresh()
          })
          .catch(
            catchAPIError({
              defaultMessage: 'Failed assigning defaulter',
            })
          )
      },
    })
  }

  return (
    <StyledWrap>
      <div className="flexd">
        <div className="src-info">
          <h4>
            {kind === 'src_patient' ? 'Patient' : 'Subscriber'} Source Record
          </h4>
          <DisplayKeyValueData
            hideEmptyFields={false}
            data={srcRec}
            style={{ marginTop: '0.5rem' }}
          />
        </div>
        <div className="right">
          <div style={{ width: '100%' }}>
            <EligibilityTable.Table
              onRowClick={onSelect}
              DataTableProps={{ density: 'small' }}
              noWrapWhitespace
              LeftHeaderItems={
                <>
                  {/* use of the eligTableContext here is purely to support pressing Enter to search;
                these component would work fine NOT wrapped in the context and <form> tags */}
                  <EligibilityTable.EligTableContext.Consumer>
                    {({ doSearch }: { doSearch(): void }) => (
                      <form
                        onSubmit={(ev: any) => {
                          ev.preventDefault()
                          ev.stopPropagation()
                          doSearch()
                        }}
                        style={{ width: '100%' }}>
                        <EligibilityTable.FilterFirstName />
                        &nbsp;&nbsp;
                        <EligibilityTable.FilterLastName />
                        &nbsp;&nbsp;
                        <EligibilityTable.FilterDateOfBirth />
                        &nbsp;&nbsp;
                        <EligibilityTable.FilterButtonDoSearch />
                        &nbsp;&nbsp;
                        <EligibilityTable.FilterButtonClear />
                      </form>
                    )}
                  </EligibilityTable.EligTableContext.Consumer>
                </>
              }
            />
          </div>
        </div>
      </div>

      <DesignSuite2023.Divider />
      {srcRec?.Hash && (
        <ClaimTable.Table
          ref={refClaimsTable}
          passFilters={{ linkedToSrcHash: srcRec?.Hash }}
          LeftHeaderItems={
            <>
              <h4>Claims where this record appears</h4>
            </>
          }
        />
      )}
    </StyledWrap>
  )
}
