import React, { useState } from 'react'
import { Button, Link } from '@material-ui/core'
import { ManageFileTypesModal } from './FileTypesModal'
import DataDictionary from '../DataDictionary/DataDictionary'
require('./style.scss')

export default function EngineeringOnly({ history }) {
  const [isManageFileTypesModalOpen, setIsManageFileTypesModalOpen] =
    useState(false)
  const singlePageNavTo = (v) => {
    return () => {
      history.push(v)
    }
  }

  return (
    <div className="engineering-only">
      <h3>Tools For Engineering Team Only</h3>
      <ul>
        <li>
          <Button
            variant="contained"
            color="primary"
            onClick={singlePageNavTo('/engineering/user_import')}>
            Import Users
          </Button>{' '}
          Bulk import users from a CSV file
        </li>
        <li>
          <Button
            variant="contained"
            color="primary"
            onClick={singlePageNavTo('/engineering/user_diff')}>
            Zerocard/Auth0 Diff
          </Button>{' '}
          Compare users in Zerocard system vs Auth0
        </li>
        <li>
          <DataDictionary /> Regular maintenance: update missing data dictionary
          definitions
        </li>
        <li>
          <Button
            variant="contained"
            color="primary"
            onClick={singlePageNavTo('/engineering/sftpaas')}>
            SFTPaas
          </Button>{' '}
          Drop files to recipients via SFTP. You can also{' '}
          <Link
            href="#"
            underline="always"
            onClick={() => {
              history.push('/engineering/pgp_keys_public')
            }}>
            view PGP keys here.
          </Link>
        </li>
        <li>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsManageFileTypesModalOpen(true)}>
            Manage FileTypes
          </Button>
        </li>
        <li>
          <Button
            variant="contained"
            color="primary"
            onClick={singlePageNavTo('/engineering/fax_ocr_sandbox')}>
            Fax/OCR Sandbox
          </Button>{' '}
          AWS' Textract sandbox
        </li>
      </ul>
      <ManageFileTypesModal
        open={isManageFileTypesModalOpen}
        closeModal={() => setIsManageFileTypesModalOpen(false)}
      />
    </div>
  )
}
