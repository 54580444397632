import React, { useState, useEffect } from 'react'
import DesignSuite2023 from '../../components/DesignSuite2023'
import { OpportunityAnalysisActions } from '../../actions'
import useErrorHandlers from '../../hooks/useErrorHandlers'

interface props {
  costKey: string | null
}

interface rule {
  ID: number
  CreatedAt: string
  CostKeyId: number
  Priority: number
  Rule: string
}

export default function ShowV2Rules({ costKey }: props): React.ReactElement {
  const [rules, setRules] = useState<rule[]>([])
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (!costKey) return
    OpportunityAnalysisActions.getBundleV2RulesByCostKey(costKey)
      .then((res: any) => {
        if (res.error) throw res
        setRules(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading associated bundle rules (v2)',
        })
      )
  }, [costKey])

  if (!costKey) {
    return <span>(No Bundle Rules(v2) To Display)</span>
  }

  return (
    <>
      {rules.map((r: rule, index: number) => (
        <div>
          <DesignSuite2023.Tooltip
            interactive
            title={
              <DesignSuite2023.StyledPre style={{ maxWidth: 500 }}>
                {r.Rule}
              </DesignSuite2023.StyledPre>
            }>
            <span>
              <DesignSuite2023.CommonIcons.IconPeek /> Rule {index + 1}
            </span>
          </DesignSuite2023.Tooltip>
        </div>
      ))}
    </>
  )
}
