import React, { Component } from 'react'
import { TextField, InputAdornment } from '@material-ui/core'
import PropTypes from 'prop-types'
import Search from '@material-ui/icons/Search'
import { withStyles } from '@material-ui/core/styles'
import { defaultStyleCreator } from '../../components/DetailView/DetailView'

const styles = (theme) => {
  const defaults = defaultStyleCreator(theme)

  return Object.assign(defaults, {
    searchBar: {
      display: 'inline-flex',
      flexWrap: 'wrap',
      marginRight: '16px',
    },
    field: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 250,
    },
  })
}

class QSearcher extends Component {
  constructor(props) {
    super(props)
    this.state = {
      q: props.default,
    }
  }

  onChange = (e) => {
    const term = e.target.value
    this.setState({ q: term })
    this.props.search(term)
  }

  render() {
    const { classes, disabled, placeholder, autoFocus, label } = this.props
    return (
      <div className={classes.searchBar}>
        <TextField
          autoFocus={autoFocus}
          label={label || null}
          disabled={disabled}
          className={classes.field}
          onChange={this.onChange}
          placeholder={placeholder}
          value={this.state.q}
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </div>
    )
  }
}

QSearcher.propTypes = {
  search: PropTypes.func.isRequired,
  classes: PropTypes.object,
  default: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
}

QSearcher.defaultProps = {
  q: '',
  disabled: false,
  placeholder: 'Start typing to search...',
  autoFocus: false,
}

export default withStyles(styles)(QSearcher)
