import React, { useState, useEffect, useRef } from 'react'
import * as RecipientTable from './recipientTableList'
import DialogRecipient from './dialogRecipient'
import SendLogTableList, { defaultColumns } from './sendLogTableList'
import * as api from '../../../services/thezerocard/api-helper'
import { Button } from '@material-ui/core'
import _pick from 'lodash/pick'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import * as types from './types'

const readyToSendCols = Object.freeze(
  _pick(
    defaultColumns,
    'ID',
    'CreatedAt',
    'UpdatedAt',
    'RecipientName',
    'FileID',
    'DropDestination',
    'PGPEncr'
  )
)

const sendLogFilters = Object.freeze({
  excludeStatuses: ['deferred'],
})

const readyToSendFilters = Object.freeze({
  status: 'deferred',
})

export default function SFTPaas(props: any): any {
  const [selectedReadyToSends, setSelectedReadyToSends] = useState<any>([])
  const refRecipsTable = useRef<any>(null)
  const refSendLogTable = useRef<any>(null)
  const refReadyToSendTable = useRef<any>(null)
  const refRecipientDialog = useRef<any>(null)
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    setSelectedReadyToSends([])
  }, [])

  const onRowClick = (r: types.recipient) => {
    refRecipientDialog.current?.open(r.ID)
  }

  const onDialogClose = () => {
    refRecipsTable?.current?.refresh()
  }

  const checkHandlerReadyToSend = (selected: any[]) => {
    setSelectedReadyToSends(selected || [])
  }

  const onDeleteReadyToSend = () => {
    if (window.confirm('Really want to delete these? Last chance...')) {
      api
        .del('/engineering/sftpaas/send-log/delete-deferreds', {
          IDs: selectedReadyToSends.map((v: any) => {
            return v.ID
          }),
        })
        .then((res: any) => {
          if (res.error) {
            throw res
          }
          setSelectedReadyToSends([])
          // @ts-ignore
          refReadyToSendTable.current?.refresh()
        })
        .catch(
          catchAPIError({
            defaultMessage: 'Operation failed; please contact engineering',
          })
        )
    }
  }

  const onQueueReadyToSend = () => {
    api
      .put('/engineering/sftpaas/send-log/enqueue-deferreds', {
        IDs: selectedReadyToSends.map((v: any) => {
          return v.ID
        }),
      })
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        setSelectedReadyToSends([])
        // @ts-ignore
        refSendLogTable.current?.refresh()
        // @ts-ignore
        refReadyToSendTable.current?.refresh()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Operation failed; please contact engineering',
        })
      )
  }

  return (
    <>
      <RecipientTable.Table
        ref={refRecipsTable}
        onRowClick={onRowClick}
        LeftHeaderItems={
          <>
            <h4>Recipients</h4>
            &nbsp;&nbsp;
            <div>
              <RecipientTable.StandardFilterSearch />
            </div>
          </>
        }
        RightHeaderItems={
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={() => refRecipientDialog.current?.open()}>
              Add New Recipient
            </Button>
          </>
        }
      />

      <div style={{ marginTop: '2rem' }}>
        <h4>Send Log</h4>
        <SendLogTableList ref={refSendLogTable} passFilters={sendLogFilters} />
      </div>

      <div style={{ marginTop: '2rem' }}>
        <h4>Ready To Send</h4>
        <SendLogTableList
          disableFilterStatus
          ref={refReadyToSendTable}
          passFilters={readyToSendFilters}
          customColumns={readyToSendCols}
          onCheckHandler={checkHandlerReadyToSend}
          RightHeaderItems={
            <>
              {!!selectedReadyToSends.length && (
                <>
                  <Button
                    onClick={onDeleteReadyToSend}
                    color="secondary"
                    style={{ marginRight: '1rem' }}>
                    Delete
                  </Button>
                  <Button
                    onClick={onQueueReadyToSend}
                    color="primary"
                    variant="outlined">
                    Enqueue Send
                  </Button>
                </>
              )}
            </>
          }
        />
      </div>

      <DialogRecipient ref={refRecipientDialog} onDialogClose={onDialogClose} />
    </>
  )
}
