import React, { useState, useEffect } from 'react'
import { editEmployerMemberSubsetHandle } from '../../actions/MemberActions'
import DesignSuite2023, { AlertWarning } from '../../components/DesignSuite2023'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import {
  renderSwitchField as RenderSwitchField,
  renderNotesField as RenderNotesField,
  RenderSelectField2,
  SetterArgs,
} from '../ViewHelpers'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'
import * as shapes from './types'
import Models from '../../models'

interface props {
  Trigger: any // React.ReactComponent
  empMemRec: shapes.shapeEmployerMember | null
  onSaved?(): void
}

export default function DialogSetSubsetHandle({
  Trigger,
  empMemRec,
  onSaved,
}: props): React.ReactElement | null {
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const [isOpen, setIsOpen] = useState(false)
  const [payload, setPayload] = useState<any>({
    SubsetHandle: empMemRec?.SubsetHandle || null,
    ApplyToDependents: false,
    Notes: null,
  })

  useEffect(() => {
    if (!isOpen) return
    setPayload({
      SubsetHandle: empMemRec?.SubsetHandle || null,
      ApplyToDependents: false,
      Notes: null,
    })
  }, [isOpen])

  function setter({ name, value }: SetterArgs) {
    setPayload((curr: any) => ({ ...curr, [name]: value }))
  }

  function doSave() {
    editEmployerMemberSubsetHandle({
      ...payload,
      EmployerMemberID: empMemRec?.ID,
    })
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar('Subset handle updated OK!', SnackbarTypeSuccess)
        onSaved && onSaved()
      })
      .catch(catchAPIError({ defaultMessage: 'Failed updating subset handle' }))
      .finally(() => setIsOpen(false))
  }

  if (!empMemRec) return null

  return (
    <>
      <DesignSuite2023.Tooltip title="Manually set subset handle">
        <Trigger onClick={() => setIsOpen(true)} />
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>
          Change Member Subset Handle
          <small style={{ display: 'block' }}>
            Note: this is an eligibility related concept; if you do not know
            what this is, leave.
          </small>
        </DialogTitle>
        <DialogContent>
          <RenderSelectField2
            name="SubsetHandle"
            label="Subset Handle"
            value={payload.SubsetHandle}
            setter={setter}
            items={Models.Eligibility.subsetHandles}
            FormControlProps={{
              margin: 'none',
              size: 'small',
              fullWidth: true,
            }}
          />

          {empMemRec?.IsSubscriber && (
            <>
              <div style={{ padding: '1rem 0 0.5rem' }}>
                <RenderSwitchField
                  setter={setter}
                  name="ApplyToDependents"
                  label={
                    <>
                      <span>Apply to Dependents</span>
                      <small style={{ display: 'block' }}>
                        Subset handle will propagate to dependents if this
                        member is a subscriber.
                      </small>
                    </>
                  }
                  value={payload.ApplyToDependents}
                />
              </div>

              {payload.ApplyToDependents && (
                <>
                  <AlertWarning>
                    <strong>Warning</strong>: this will set the subset handle
                    for the following dependents (all that are not currently
                    marked retired):
                    <ul>
                      {empMemRec?.Dependents?.map((d: any) => (
                        <li key={d.EmployerMemberID}>
                          (EmployerMemberID: {d.EmployerMemberID}) {d.FirstName}{' '}
                          {d.LastName}
                        </li>
                      ))}
                    </ul>
                  </AlertWarning>
                </>
              )}
            </>
          )}

          <RenderNotesField
            setter={setter}
            name="Notes"
            label="Notes"
            value={payload.Notes}
            opts={{
              margin: 'normal',
              variant: 'outlined',
              size: 'small',
              helperText:
                "Warning: don't ever put sensitive information in notes!",
            }}
          />

          {/* <pre>{JSON.stringify(payload, null, '  ')}</pre> */}
          {/* <pre>{JSON.stringify(empMemRec, null, '  ')}</pre> */}
        </DialogContent>
        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            }
            right={
              <Button color="primary" variant="contained" onClick={doSave}>
                Save
              </Button>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
