import React, { useRef, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
} from '@material-ui/core'
import { renderNotesField, SetterArgs } from '../ViewHelpers'
import * as FeeScheduleTable from './FeeScheduleTable2'
import { FeeScheduleActions } from '../../actions'
import { STATUS_DRAFT, STATUS_PRODUCTION } from '../../models/FeeSchedule'
import { useHistory } from 'react-router-dom'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import { FeeSchedule } from './types'
import dateTime from '../../utils/dateTime'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
//@ts-ignore
import styled from 'styled-components'

const {
  deleteFeeSchedules,
  bulkTerminateFeeSchedules,
  bulkEditFeeScheduleStarts,
} = FeeScheduleActions

const PaperContainer = styled(Paper)`
  padding: 20px;
  padding-top: 0px;
`

interface FeeScheduleFields {
  bulkTerminateDate: string
  bulkTerminateNotes: string
  bulkEditDateStart: string
}

const defaultFields = {
  bulkTerminateDate: '',
  bulkTerminateNotes: '',
  bulkEditDateStart: '',
}

const FeeSchedules = () => {
  const [checkedItems, setCheckedItems] = useState<Array<FeeSchedule>>()
  const [countDraftSelected, setCountDraftSelected] = useState<number>(0)
  const [countProductionSelected, setCountProductionSelected] =
    useState<number>(0)
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false)
  const [showTerminate, setShowTerminate] = useState<boolean>(false)
  const [showEditStartDate, setShowEditStartDate] = useState<boolean>(false)
  const [fields, setFields] = useState<FeeScheduleFields>(defaultFields)
  const history = useHistory()
  const snackbar = useSnackbar()
  const { catchAPIError } = useErrorHandlers()
  const refFSTable = useRef()

  const setter = ({ name, value }: SetterArgs) => {
    const newFields = Object.assign({}, fields, { [name]: value })
    setFields(newFields)
  }

  const handleAddNew = () => {
    history.push('/fee_schedule')
  }

  const onItemsChecked = (selected: Array<FeeSchedule>) => {
    let numDraft = 0
    let numProd = 0

    selected.forEach((item: FeeSchedule) => {
      if (item.Status === STATUS_DRAFT) numDraft += 1
      if (item.Status === STATUS_PRODUCTION) numProd += 1
    })

    setCountDraftSelected(numDraft)
    setCountProductionSelected(numProd)
    setCheckedItems(selected)
  }

  const toggleDeleteDialog = () => {
    setShowConfirmDelete(!showConfirmDelete)
  }

  const onConfirmDelete = () => {
    if (!checkedItems || !checkedItems.length) return
    deleteFeeSchedules({ ids: checkedItems.map((v: FeeSchedule) => v.ID) })
      .then(() => {
        snackbar.show('Fee Schedules deleted!', SnackbarTypeSuccess)
        // @ts-ignore
        refFSTable.current?.refresh()
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to Delete Draft Fee Schedules; please contact Engineering',
        })
      )
      .finally(() => toggleDeleteDialog())
  }

  const toggleTerminateDialog = () => {
    setShowTerminate(!showTerminate)
    setFields({ ...fields, bulkTerminateDate: '', bulkTerminateNotes: '' })
  }

  const toggleEditStartDateDialog = () => {
    setShowEditStartDate(!showEditStartDate)
    setFields({ ...fields, bulkEditDateStart: '' })
  }

  const onConfirmTerminate = () => {
    if (!checkedItems || !checkedItems.length) {
      snackbar.show(
        'Missing Selected Fee Schedules; no actions were performed',
        SnackbarTypeError
      )
      return toggleTerminateDialog()
    }

    if (
      !fields.bulkTerminateDate ||
      !dateTime.parse(fields.bulkTerminateDate).isValid()
    ) {
      snackbar.show(
        'Invalid termination date; no actions were performed',
        SnackbarTypeError
      )
      return toggleTerminateDialog()
    }

    const payload = {
      ids: checkedItems.map((v: FeeSchedule) => {
        return v.ID
      }),
      dateEnd: fields.bulkTerminateDate,
      notes: fields.bulkTerminateNotes,
    }
    bulkTerminateFeeSchedules(payload)
      .then(() => {
        snackbar.show('Fee Schedules updated!', SnackbarTypeSuccess)
        // @ts-ignore
        refFSTable.current?.refresh()
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to Terminate Production Fee Schedules; please contact Engineering',
        })
      )
      .finally(() => toggleTerminateDialog())
  }

  const onConfirmEditStartDate = () => {
    if (!checkedItems || !checkedItems.length) {
      snackbar.show(
        'Missing Selected Fee Schedules; no actions were performed',
        SnackbarTypeError
      )
      return toggleTerminateDialog()
    }

    if (
      !fields.bulkEditDateStart ||
      !dateTime.parse(fields.bulkEditDateStart).isValid()
    ) {
      snackbar.show(
        'Invalid start date; no actions were performed',
        SnackbarTypeError
      )
      return toggleEditStartDateDialog()
    }

    const payload = {
      ids: checkedItems.map((v: FeeSchedule) => {
        return v.ID
      }),
      dateStart: fields.bulkEditDateStart,
    }
    bulkEditFeeScheduleStarts(payload)
      .then((res: any) => {
        snackbar.show('Fee Schedules updated!', SnackbarTypeSuccess)
        // @ts-ignore
        refFSTable.current?.refresh()
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to Edit Fee Schedule Start Dates; please contact Engineering',
        })
      )
      .finally(() => toggleEditStartDateDialog())
  }

  const renderActionButtons = () => {
    if (countDraftSelected && !countProductionSelected) {
      return (
        <>
          <Button
            onClick={toggleDeleteDialog}
            variant="contained"
            color="primary">
            Delete Fee Schedules
          </Button>
          &nbsp;&nbsp;
          <Button onClick={toggleEditStartDateDialog} variant="contained">
            Edit Start Date
          </Button>
        </>
      )
    }
    if (countProductionSelected)
      return (
        <Button onClick={toggleTerminateDialog} variant="contained">
          Terminate Fee Schedules
        </Button>
      )
    return null
  }

  return (
    <>
      <PaperContainer elevation={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FeeScheduleTable.Table
              enableURLReflection
              onCheckHandler={onItemsChecked}
              ref={refFSTable}
              LeftHeaderItems={
                <>
                  <FeeScheduleTable.StandardFilterSearch autoFocus />
                  &nbsp;&nbsp;
                  <FeeScheduleTable.FilterOrganization />
                  &nbsp;&nbsp;
                  <FeeScheduleTable.FilterStatus />
                </>
              }
              RightHeaderItems={
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddNew}>
                  Add New
                </Button>
              }
              DataTableProps={{ LeftFooterItems: renderActionButtons() }}
            />
          </Grid>
        </Grid>
      </PaperContainer>

      <Dialog open={showConfirmDelete} onClose={toggleDeleteDialog}>
        <DialogTitle>Confirm Removal</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you really want to delete the selected Fee Schedule(s)? This
            cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onConfirmDelete} color="secondary">
            Delete
          </Button>
          <Button onClick={toggleDeleteDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        PaperProps={{ style: { overflow: 'visible' } }}
        open={showEditStartDate}
        onClose={toggleEditStartDateDialog}>
        <DialogTitle>Change Start Date</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Change the start date for selected fee schedules...
          </DialogContentText>
          {/* {renderDateField({name: 'bulkEditDateStart', label: 'Start Date', value: fields.bulkEditDateStart, setter})} */}
          <ManagedDateInput
            name="bulkEditDateStart"
            label="Start Date"
            value={fields.bulkEditDateStart}
            setter={setter}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onConfirmEditStartDate}
            disabled={!dateTime.parse(fields.bulkEditDateStart).isValid()}
            color="secondary">
            Confirm
          </Button>
          <Button onClick={toggleEditStartDateDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        PaperProps={{ style: { overflow: 'visible' } }}
        open={showTerminate}
        onClose={toggleTerminateDialog}>
        <DialogTitle>Terminate Fee Schedules</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Set the termination date for selected fee schedules...
          </DialogContentText>
          {/* {renderDateField({name: 'bulkTerminateDate', label: 'Termination Date', value: fields.bulkTerminateDate, setter})} */}
          <ManagedDateInput
            name="bulkTerminateDate"
            label="Termination Date"
            value={fields.bulkTerminateDate}
            setter={setter}
          />
          {renderNotesField({
            name: 'bulkTerminateNotes',
            label: 'Terminate Notes',
            value: fields.bulkTerminateNotes,
            setter,
            opts: { placeholder: 'Enter terminate notes here' },
          })}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onConfirmTerminate}
            disabled={!dateTime.parse(fields.bulkTerminateDate).isValid()}
            color="secondary">
            Confirm
          </Button>
          <Button onClick={toggleTerminateDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FeeSchedules
