import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import { useSetFrameHeaderMid } from '../../../components/AppFrame'
import useQueryParamsGen2 from '../../../hooks/useQueryParamsGen2'
import PagePayableView from './viewPayable'
import PagePaidView from './viewPaid'
import PageZelisView from './viewZelis'

const SUB_VIEW_PAYABLE = 'payable'
const SUB_VIEW_PAID = 'paid'
const SUB_VIEW_ZELIS = 'zelis'

export default function PageReceipts(): React.ReactElement {
  const { queryData, setQueryData } = useQueryParamsGen2({
    scope: 'zclaims.payables.subview',
  })
  const [subView, setSubView] = useState(
    queryData['subView'] || SUB_VIEW_PAYABLE
  )

  useEffect(() => {
    setQueryData({ subView })
  }, [subView])

  useSetFrameHeaderMid(() => {
    return (
      <>
        <Button
          size="small"
          variant={subView === SUB_VIEW_PAYABLE ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_PAYABLE)}>
          Payable
        </Button>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <Button
          size="small"
          variant={subView === SUB_VIEW_PAID ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_PAID)}>
          Paid
        </Button>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <Button
          size="small"
          variant={subView === SUB_VIEW_ZELIS ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_ZELIS)}>
          Zelis
        </Button>
      </>
    )
  }, [subView])

  switch (subView) {
    case SUB_VIEW_PAYABLE:
      return <PagePayableView />
    case SUB_VIEW_PAID:
      return <PagePaidView />
    case SUB_VIEW_ZELIS:
      return <PageZelisView />
  }

  return <span>Invalid state: try clicking one of the tabs above...</span>
}
