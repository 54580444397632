import React from 'react'
import { Paper, Grid, Button, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import DetailView, {
  defaultStyleCreator,
} from '../../components/DetailView/DetailView'
import UserTable from './UserTable'
import * as GroupTable2 from '../Groups/GroupTable2'

const styles = (theme) => {
  const defaults = defaultStyleCreator(theme)
  return Object.assign(defaults, {
    header: {
      padding: 8,
      paddingTop: 16,
    },
  })
}

export class User extends DetailView {
  handleUserRowClick = (e, { ID }) => {
    this.props.history.push(`/user/${ID}`)
  }
  handleGroupRowClick = (e, { ID }) => {
    if (e.target.name === 'view-link') {
      return
    }

    this.props.history.push(`/group/${ID}`)
  }

  handleAddNewUser = () => {
    this.props.history.push('/user')
  }
  handleAddNewGroup = () => {
    this.props.history.push('/group')
  }

  render() {
    const { classes } = this.props
    return (
      <div>
        <Paper className={classes.mainContent} elevation={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.header}>
                <Typography variant="h5">Users</Typography>
              </div>
              <UserTable onRowClick={this.handleUserRowClick} autoFocus />
              <div style={{ textAlign: 'right', padding: '1rem 0' }}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.handleAddNewUser}>
                  New User
                </Button>
              </div>
            </Grid>
          </Grid>
          <Grid id="groups" container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.header}>
                <Typography variant="h5">Access Groups</Typography>
              </div>
              {/* <GroupTable onRowClick={this.handleGroupRowClick} /> */}
              <GroupTable2.Table
                LeftHeaderItems={
                  <>
                    <GroupTable2.StandardFilterSearch />
                    &nbsp;&nbsp;
                    <GroupTable2.FilterOrganization />
                    &nbsp;&nbsp;
                    <GroupTable2.FilterPracticeFacility />
                  </>
                }
                RightHeaderItems={
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={this.handleAddNewGroup}>
                      New Access Group
                    </Button>
                  </>
                }
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
    )
  }
}

export default withStyles(styles)(User)
