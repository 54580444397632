import * as api from '../services/thezerocard/api-helper'

import * as typed from '../views/SavingsReview/typed'

export function updateClaimSummary(group: any): Promise<any> {
  return api.put(`/savings_review/claim_summary/${group.ID}`, group)
}

export function updateClaimLines(data: any): Promise<any> {
  return api.put(`/savings_review/claim_line`, data)
}

export function getReviewableClaims(
  params: typed.GroupFilterFields
): Promise<any> {
  return api.get('/savings_review/claim_summary', {
    q: JSON.stringify(params),
  })
}
