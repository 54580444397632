import React from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import styled from 'styled-components'
import * as shapes from '../types'
import dateTime from '../../../utils/dateTime'
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core'
import {
  CheckCircleOutline as IconCircleCheck,
  HighlightOff as IconInactive,
} from '@material-ui/icons'

const StyledEmployerMemberships = styled(List)`
  padding: 0;
  margin: 0;

  .block {
    display: block;
    &.overflow-control {
      width: 210px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .titled {
    font-size: 125%;
    line-height: 1;
    padding-bottom: 0.15rem;
  }
`

/*
Show the list of employer memberships for this member
*/
export default function DisplayEmployerMemberships({
  memberData,
  useEmployerMemberID,
  onClick = () => {},
}: {
  memberData: shapes.shapeMember
  useEmployerMemberID: number | null
  onClick?(employerMemberID: number): void
}): React.ReactElement | null {
  if (!memberData?.EmployerMemberships?.length) {
    return <>MEMBER NOT ASSOCIATED WITH ANY EMPLOYER (@todo here)</>
  }

  const empMems = (memberData?.EmployerMemberships || []).sort(
    (a: shapes.shapeEmployerMember, b: shapes.shapeEmployerMember) =>
      a.IsCurrentlyActive ? -1 : 1
  )

  return (
    <StyledEmployerMemberships>
      {empMems.map((em: shapes.shapeEmployerMember) => {
        const isSelected = em.ID === useEmployerMemberID
        const dtEmpMemActivationDate = dateTime.parse(em.ActivationDate)
        const dtEmpMemDeactivationDate = dateTime.parse(em.DeactivationDate)

        return (
          <DesignSuite2023.Tooltip
            key={em.ID}
            title={
              <div>
                <div>{em.EmployerName}</div>
                <div>
                  (dates are for the *EMPLOYER MEMBER*, not the employer)
                </div>
              </div>
            }>
            <ListItem
              key={em.ID}
              button
              component="li"
              onClick={() => onClick(em.ID)}
              selected={isSelected}>
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundColor: em.IsCurrentlyActive ? 'green' : 'gray',
                  }}>
                  {em.IsCurrentlyActive ? (
                    <IconCircleCheck />
                  ) : (
                    <IconInactive />
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <span className="block overflow-control titled">
                    {em.EmployerName}
                  </span>
                }
                secondary={
                  <>
                    <small className="block">Employer Member:</small>
                    <span className="block">
                      <strong>
                        {dtEmpMemActivationDate.isBefore(dateTime.now())
                          ? 'Activated'
                          : 'Activates'}
                        :
                      </strong>{' '}
                      {dtEmpMemActivationDate.format()}
                    </span>
                    {!dtEmpMemDeactivationDate.isEmpty() && (
                      <span className="block">
                        <strong>
                          {dtEmpMemDeactivationDate.isBefore(dateTime.now())
                            ? 'Deactivated'
                            : 'Deactivates'}
                          :
                        </strong>{' '}
                        {dtEmpMemDeactivationDate.format()}
                      </span>
                    )}
                  </>
                }
              />
            </ListItem>
          </DesignSuite2023.Tooltip>
        )
      })}
    </StyledEmployerMemberships>
  )
}
