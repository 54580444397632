import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { GroupManagerDialog } from '../Groups/GroupManager'
import { GroupActions, SnackbarActions, clearSaveResult } from '../../actions'

const { findGroups, saveUserGroups } = GroupActions
const { setSnackbarMessage } = SnackbarActions

class userGroupManager extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { open } = this.props
    const { userId, saveResult } = nextProps

    if (nextProps.open && open === false) {
      this.props.findGroups({ filter: { userIds: [userId] } })
    }

    if (saveResult) {
      if (saveResult.saved) {
        this.props.setSnackbarMessage('Saved!', 'success')
        this.props.clearSaveResult()
      } else if (saveResult.error) {
        const message = `An error occurred while saving. Request ID: ${saveResult.requestId}`
        this.props.setSnackbarMessage(message, 'error')
        this.props.clearSaveResult()
      }
    }
  }

  // users are different than other entities
  onConfirmUsers = ({ added, removed }) => {
    this.props.saveUserGroups({ userId: this.props.userId, added, removed })
    this.props.onConfirm()
  }

  render() {
    const { onCancel, onUpdate, open, groups } = this.props
    return (
      <div>
        <GroupManagerDialog
          open={open}
          currentGroups={groups}
          onConfirm={this.onConfirmUsers}
          onCancel={onCancel}
          onUpdate={onUpdate}
        />
      </div>
    )
  }
}

userGroupManager.propTypes = {
  userId: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

userGroupManager.defaultProps = {
  onConfirm: () => {
    console.warn('You must set an onConfirm property on the UserGroupManager')
  },
  onCancel: () => {
    console.warn('You must set an onCancel property on the UserGroupManager')
  },
  onUpdate: () => {
    console.warn('You must set an onUpdate property on the UserGroupManager')
  },
  open: false,
}

function mapStateToProps(state) {
  return {
    groups: state.groups.groups || {},
    saveResult: state.groups.saveResult,
    getError: state.groups.getError,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { findGroups, saveUserGroups, setSnackbarMessage, clearSaveResult },
    dispatch
  )
}

export const UserGroupManager = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(userGroupManager)
