import * as api from '../services/thezerocard/api-helper'
import * as pgpKeyTypes from '../views/Engineering/PGPKeys/types'

export function sendBulkUserImport(payload: any): Promise<any> {
  return api.post('/engineering/users/bulk-import', payload)
}

export function getAuth0User({ auth0UserID }: any): Promise<any> {
  const encoded = encodeURIComponent(auth0UserID)
  return api.get(`/engineering/user/${encoded}`)
}

export function getAuth0Diff(): Promise<any> {
  return api.get('/engineering/users/auth0-diff')
}

export function getPermissionGroups(): Promise<any> {
  // todo: consider changing this on the api
  return api.get('/user-groups')
}

export function markEmployerMembersReprocessable(params: any): Promise<any> {
  const { employerId } = params
  return api.put(
    `/engineering/eligibility/employer/${employerId}/mark_all_members_reprocessable`
  )
}

export function retrySendSFTP(id: any): Promise<any> {
  return api.post(`/engineering/sftpaas/retry/${id}`)
}

export function getProviderClaimInvoiceOptions(): Promise<any> {
  return api.get('/provider_claim_invoice/options')
}

export function postSendProviderClaimFileID(payload: any): Promise<any> {
  return api.post('/provider_claim_invoice/process_file', payload)
}

export function getQuikTripProviderReportsLastSent(): Promise<any> {
  return api.get('/quiktrip_provider_reports_last_sent')
}

export function postQuikTripProviderReportsSend(): Promise<any> {
  return api.post(`/quiktrip_provider_reports_send`)
}

export function getSearchPGPKeys(params: any): Promise<any> {
  return api.search(`/engineering/pgp_key_public`, params)
}

export type pgpKeyAPIResponse = Promise<
  { Data: pgpKeyTypes.PGPKey } & Partial<any>
>

export function getPGPKey(id: number): pgpKeyAPIResponse {
  return api.get(`/engineering/pgp_key_public/${id}`) as any
}

export function postCreatePGPKey(payload: any): pgpKeyAPIResponse {
  return api.post('/engineering/pgp_key_public', payload) as any
}

export function putUpdatePGPKey(payload: any): pgpKeyAPIResponse {
  return api.put(`/engineering/pgp_key_public/${payload.ID}`, payload) as any
}

export function postReadPGPKeyInfo(payload: any): pgpKeyAPIResponse {
  return api.post(`/engineering/pgp_key_public/read_key_info`, payload) as any
}

export function deletePGPKeys(ids: number[]): Promise<any> {
  return api.del(`/engineering/pgp_key_public`, { IDs: ids })
}

export function updateInboundSFTPs() {
  return api.post('/eligibility/sync_elig_inbound')
}

export function getInboundPreStagePlan(params: any) {
  params.filter.NoLimit = params?.filter?.NoLimit === false ? false : true
  return api.search(`/eligibility/elig_inbound_pre_stage_plan`, params)
}

export function postInboundPreStageProcess(params: any) {
  return api.post(`/eligibility/elig_inbound_pre_stage_process`, params)
}

export function getEligInboundStaged(params: any): Promise<any> {
  params.filter.NoLimit = params?.filter?.NoLimit === false ? false : true
  return api.search(`/eligibility/elig_inbound_staged`, params)
}

export function putIgnoreElig(params: {
  EligInboundIDs: number[]
  Notes: string
}): Promise<any> {
  return api.put('/eligibility/elig_inbound_ignore', params)
}

export function putResetElig(params: {
  EligInboundIDs: number[]
  Notes: string
}): Promise<any> {
  return api.put('/eligibility/elig_inbound_reset', params)
}

export function putInboundStagedMatchFileConfigs(): Promise<any> {
  return api.put('/eligibility/elig_inbound_staged/match_elig_file_configs')
}

export function putInboundStagedProcessableFiles(params: any): Promise<any> {
  return api.put('/eligibility/elig_inbound_staged/processable_files', params)
}

export function putInboundStagedUnsetProcessableFiles(
  params: any
): Promise<any> {
  return api.put(
    '/eligibility/elig_inbound_staged/unset_processable_files',
    params
  )
}
