import _ from 'lodash'
import { excludeFromOppAnalysisFormatter } from './ReactFormatters'
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { pink, teal } from '@material-ui/core/colors'
import {
  Check,
  Close,
  InsertDriveFile as InsertDriveFileIcon,
} from '@material-ui/icons'
import { Checkbox, Grid, IconButton } from '@material-ui/core'
import styled from 'styled-components'

const IPText = styled.p`
  margin-bottom: 0px;
`

const utils = {
  updateClipboard,
  checkPerms,
  isInteger,
  isFloat,
  numberWithCommas,
  priceFormatter,
  moneyFormatter,
  distanceFormatter,
  phoneFormatter,
  boolYesNoFormatter,
  boolCheckboxFormatter,
  copyDataFormatter,
  shareLinkFormatter,
  arrayIPsFormatter,
  activeFormatter,
  getExcludeFromOppAnalysisEditDetails, // we might want sep util files in the future
  getDisplayAddress,
  getSelectOptions,
  arrayToSelectOptions,
  isValidTIN,
  convertMoneyFromTZC,
  convertMoneyToTZC,
  scrubAndParseFloat,
  convertToRate,
  convertFromRate,
  stripNonNumeric,
  emailLinkFormatter,
  orgLinkFormatter,
  getIds,
  nameFormatter,
  zeroOneCheckFormatter,
  makeRandomString,
  mergeParams,
}

function convertMoneyFromTZC(val) {
  return val / 100
}

function convertMoneyToTZC(val) {
  // this function takes a fp or string representation of a fp/decimal
  // and multiplies it by 100 to turn it into an integer that our backend understands (ie no decimal)
  // unfortunately this can trigger bad fp behavior with js, which is why we Round the result here.
  // see tests for examples
  return Math.round(val * 100)
}

function convertToRate(val) {
  const converted = val / 100
  return parseFloat(converted)
}

function convertFromRate(val) {
  return val * 100
}

function checkPerms(perm) {
  // this is not ideal, but will work for the foreseeable future
  if (!localStorage) return false
  const perms = localStorage.getItem('perms')

  return _.includes(perms, perm)
}

function stripNonNumeric(val) {
  return val.replace(/\D/g, '')
}

function getDisplayAddress(cell, row) {
  return row.Address.DisplayAddress
}

function distanceFormatter(cell, row) {
  const value = _.toNumber(cell).toFixed(1)
  return `${value} mi`
}

function scrubAndParseFloat(value) {
  const scrubbedVal = value.toString().replace(/[^0-9.]/g, '')

  return parseFloat(scrubbedVal)
}

function isInteger(value) {
  const parsed = _.toNumber(value)
  return parsed === _.toInteger(value)
}

function isFloat(value) {
  const parsed = parseFloat(value)
  return parsed === _.toNumber(value)
}

function numberWithCommas(value) {
  if (!value) return ''
  if (isNaN(value)) {
    return value
  }
  let parts = value.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

function emailLinkFormatter(cell, row) {
  const mailto = `mailto:${cell}`
  return (
    <a href={mailto} name="view-link">
      {cell}
    </a>
  )
}

function orgLinkFormatter(cell, row) {
  return (
    <a
      rel="noreferrer"
      target="_blank"
      href={`/organization/${row.OrganizationID}`}>
      {cell || 'Organization'}
    </a>
  )
}

function phoneFormatter(cell, row) {
  const value = _.toString(cell)
  const areaCode = value.substr(0, 3)
  const firstPart = value.substr(3, 3)
  const secondPart = value.substr(6, 4)

  if (!areaCode || !firstPart || !secondPart) return ''

  return `(${areaCode})-${firstPart}-${secondPart}`
}

function priceFormatter(cell, row) {
  const value = _.toNumber(cell).toFixed(2)

  return `$${value}`
}

function moneyFormatter(cell, row) {
  let value = _.toNumber(cell)
  if (value !== 0) {
    value = value / 100
  }

  return priceFormatter(value, row)
}

function boolYesNoFormatter(cell, row) {
  const val = cell

  return val === true || val === '1' ? 'Yes' : 'No'
}

function boolCheckboxFormatter(cell) {
  const val = cell === true || cell === '1'
  return <Checkbox checked={val} disabled={true} />
}

function arrayIPsFormatter(cell) {
  return (
    <Grid container direction="column">
      {cell.map((ip) => (
        <IPText>{ip}</IPText>
      ))}
    </Grid>
  )
}

function copyDataFormatter(cell) {
  if (!cell) return <></>
  return (
    <IconButton onClick={() => navigator.clipboard.writeText(cell)}>
      <InsertDriveFileIcon />
    </IconButton>
  )
}

function shareLinkFormatter(cell, link) {
  if (!cell) return <></>
  const fullLink = `${link}${cell}`
  return (
    <IconButton onClick={() => navigator.clipboard.writeText(fullLink)}>
      <InsertDriveFileIcon />
    </IconButton>
  )
}

function getExcludeFromOppAnalysisEditDetails() {
  return {
    dataFormat: excludeFromOppAnalysisFormatter,
  }
}

function getSelectOptions(items, keys) {
  const { valueKey, labelKey, sort, allowEmpty } = keys

  let ret = items.map((item) => {
    return {
      key: item[valueKey],
      value: item[valueKey],
      label: item[labelKey],
    }
  })
  if (sort) ret = ret.sort((a, b) => a.label.localeCompare(b.label))
  if (allowEmpty) ret.unshift({ key: 0, value: 0, label: '' })

  return ret
}

function arrayToSelectOptions(items) {
  return items.map((item) => {
    return {
      key: item,
      value: item,
      label: item,
    }
  })
}

function isValidTIN(tin) {
  return tin && tin.length === 9 && isInteger(tin)
}

function getIds(collection) {
  if (!collection || collection.length === 0) return []
  return collection.map((item) => {
    return item.ID
  })
}

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function bytesToFriendly(bytes, decimals = 2) {
  if (!bytes) return '0 Bytes (or unknown)'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const IconCheck = withStyles({
  colorPrimary: {
    color: teal[500],
  },
})(Check)

export const IconX = withStyles({
  colorPrimary: {
    color: pink[500],
  },
})(Close)

function activeFormatter(field) {
  return (_, row) => {
    if (row && row[field] === true) {
      return <IconCheck color="primary" />
    }
    return <IconX color="primary" />
  }
}

function zeroOneCheckFormatter(field) {
  return (_, row) => {
    if (row && row[field] === 1) {
      return <IconCheck color="primary" />
    }
    return <IconX color="primary" />
  }
}

function nameFormatter(firstName, middleName, lastName) {
  const mid = middleName ? `${middleName} ` : ''
  return `${firstName} ${mid}${lastName}`
}

function updateClipboard(newClip, cb, err) {
  navigator.clipboard.writeText(newClip).then(cb, err)
}

export function makeRandomString(len = 8) {
  // Declare all characters
  let chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  // Pick characers randomly
  let str = ''
  for (let i = 0; i < len; i++) {
    str += chars.charAt(Math.floor(Math.random() * chars.length))
  }

  return str
}

function mergeParams(zParams, qsParams) {
  const unbase64d = atob(zParams)
  const parsedParams = JSON.parse(unbase64d)
  return { ...parsedParams, ...qsParams }
}

export default utils
