import React from 'react'

export default function () {
  return (
    <div>
      <h3>Instructions</h3>
      <p>
        Files must be in CSV format, exported with the following column headers
        (in this order): <code>Cost_Key</code>, <code>Price</code>,{' '}
        <code>Exclude_From_Opp_Analysis</code>,{' '}
        <code>Exclude_From_Public_Search</code>, <code>Note</code>,{' '}
        <code>Practice_ID</code>, <code>Ext_Practice_ID</code>
      </p>
      <hr />
      <h5>If you want one fee schedule for all facilities</h5>
      <p>
        Omit values in both the <code>Practice_ID</code> or{' '}
        <code>Ext_Practice_ID</code> columns.
      </p>
      <div style={{ paddingLeft: '1rem' }}>
        <pre>
          Cost_Key,Price,Exclude_From_Opp_Analysis,Exclude_From_Public_Search,Note,Practice_ID,Ext_Practice_ID
          <br />
          B7034,500,0,0,,, <br />
          B7033,550,0,0,,, <br />
          B7035,625,0,0,,, <br />
        </pre>
      </div>
      <hr />
      <h5>If you want individual fee schedules for each facility</h5>
      <p>
        Fill in <strong>either</strong> (one or the other, not both){' '}
        <code>Practice_ID</code> or <code>Ext_Practice_ID</code> columns. Some
        organizations provide us with the ID of a facility
        <i>as represented in their own system</i>, which we'll track in our
        database. If your price sheet needs to use the external ID, see the
        second example below.
      </p>
      <div style={{ paddingLeft: '1rem' }}>
        <strong style={{ fontSize: '90%' }}>
          Using Practice Facility ID (eg. as represented in ZeroCard's database)
        </strong>
        <pre>
          Cost_Key,Price,Exclude_From_Opp_Analysis,Exclude_From_Public_Search,Note,Practice_ID,Ext_Practice_ID
          <br />
          B7034,500,0,0,,6328, <br />
          B7035,625,0,0,,6328, <br />
          B7033,550,0,0,,6328, <br />
          B7035,625,0,0,,6329, <br />
        </pre>
      </div>
      <div style={{ paddingLeft: '1rem' }}>
        <strong style={{ fontSize: '90%' }}>
          Using External Practice Facility ID (eg. ID of the facility as
          represnted by an organization's own systems)
        </strong>
        <pre>
          Cost_Key,Price,Exclude_From_Opp_Analysis,Exclude_From_Public_Search,Note,Practice_ID,Ext_Practice_ID
          <br />
          B7034,500,0,0,,,99A1BZ38X <br />
          B7035,625,0,0,,,99A1BZ38X <br />
          B7033,550,0,0,,,99A1BZ38Y <br />
          B7035,625,0,0,,,99A1BZ38Y <br />
        </pre>
      </div>
      <hr />
      <p>Notes:</p>
      <ul>
        <li>
          If the price sheet is going to include the{' '}
          <code>Ext_Practice_ID</code>,{' '}
          <strong>
            every single row must have an <code>Ext_Practice_ID</code>
            value with the normal <code>Practice_ID</code> column *empty*
          </strong>{' '}
          (ie. we won't mix-and-match some rows having only our internal
          <code>Practice_ID</code> vs. some having only{' '}
          <code>Ext_Practice_ID</code>)
        </li>
        <li>
          The values for <code>Exclude_From_Opp_Analysis</code> and{' '}
          <code>Exclude_From_Public_Search</code>
          should be either a <code>1</code> (for true) or <code>0</code> for
          false.
        </li>
        <li>
          <strong>Practice Facility Activation/Deactivation Dates:</strong> the
          following rules apply to your fee schedule import:
          <ul>
            <li>
              Facitilies terminated by the start date of the fee schedule will{' '}
              <strong>not be added</strong>
            </li>
            <li>
              Facitilies with no launch date configured will{' '}
              <strong>not be added</strong>
            </li>
            <li>
              All facilities active on the fee schedule start date will be
              associated, as well as facilities with future launch dates
            </li>
          </ul>
        </li>
      </ul>
    </div>
  )
}
