import React, { useState, useEffect } from 'react'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { FeeScheduleActions } from '../../actions'
import DesignSuite2023 from '../../components/DesignSuite2023'
const { deleteFeeSchedulesFromPracticeFacility } = FeeScheduleActions

interface props {
  practiceFacilityID: number
  feeSchedules: any[]
  onChange?(): void
}

export default function DailogRemoveFromFeeSchedules({
  practiceFacilityID,
  feeSchedules = [],
  onChange,
}: props): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [draftFeeSchedules, setDraftFeeSchedules] = useState<any[]>([])
  const [prodFeeSchedules, setProdFeeSchedules] = useState<any[]>([])
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    setDraftFeeSchedules(feeSchedules.filter((fs: any) => fs.Status === 0))
    setProdFeeSchedules(feeSchedules.filter((fs: any) => fs.Status === 1))
  }, [feeSchedules])

  function doRemove(): void {
    deleteFeeSchedulesFromPracticeFacility(
      practiceFacilityID,
      feeSchedules.map((v: any) => v.ID)
    )
      .then(() => {
        onChange && onChange()
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed removing facility from selected fee schedules; please contact Engineering',
        })
      )
      .finally(() => {
        setIsOpen(false)
      })
  }

  return (
    <>
      <DesignSuite2023.Tooltip title="Opens workflow dialog for removing this facility from the selected fee schedule(s)">
        <span>
          <Button
            disabled={!feeSchedules.length}
            variant="contained"
            color="secondary"
            onClick={setIsOpen.bind(null, true)}>
            Remove Facility From Fee Schedule(s)
          </Button>
        </span>
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen}>
        <DialogTitle>Remove Facility From Fee Schedule(s)</DialogTitle>
        <DialogContent>
          <p>
            <strong>How This Works:</strong> this manages the{' '}
            <i>relationship</i> between the practice facility and one or more
            fee schedules. You are not managing the fee schedules themselves -
            you're updating the association data between this facility and one
            or more fee schedules.
            <br />
          </p>
          <p>
            For <strong>production fee schedules</strong> associated with this
            facility, the "removed" date will be set to today. In other words,
            this facility will be treated as inactive within the fee schedule
            (but you'll still know this facility{' '}
            <i>had been part of the fee schedule, historically</i>).
          </p>
          <p style={{ marginBottom: '1rem' }}>
            For <strong>draft fee schedules</strong>, the association to this
            facility will be <i>deleted entirely</i>. You may re-add it later,
            prior to moving the fee schedule into production.
          </p>

          {!!prodFeeSchedules.length && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  <strong>
                    Production Fee Schedules ({prodFeeSchedules.length})
                  </strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ListFeeSchedules items={prodFeeSchedules} />
              </AccordionDetails>
            </Accordion>
          )}

          {!!draftFeeSchedules.length && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  <strong>
                    Draft Fee Schedules ({draftFeeSchedules.length})
                  </strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ListFeeSchedules items={draftFeeSchedules} />
              </AccordionDetails>
            </Accordion>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button
                color="secondary"
                variant="outlined"
                onClick={setIsOpen.bind(null, false)}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button color="primary" variant="contained" onClick={doRemove}>
                Proceed
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

function ListFeeSchedules({
  items = [],
}: {
  items: any[]
}): React.ReactElement {
  const results = items.map((fs: any) => {
    return (
      <TableRow>
        <TableCell>{fs.ID}</TableCell>
        <TableCell>{fs.Name}</TableCell>
      </TableRow>
    )
  })
  if (!results.length) {
    return <span>No fee schedules</span>
  }
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell style={{ width: '99%' }}>Name</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{results}</TableBody>
    </Table>
  )
}
