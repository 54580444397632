import React from 'react'
import PropTypes from 'prop-types'
import QSearcher from '../../components/QSearcher'
import { withStyles } from '@material-ui/core/styles'
import {
  Avatar,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank'
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Models from '../../models'
import _ from 'lodash'
import { ApiActions } from '../../actions'

const { search } = ApiActions

const styles = (theme) => {
  return {
    selected: {
      backgroundColor: 'green',
    },
    current: {
      backgroundColor: 'green',
    },
    active: {},
    inactive: {
      background: 'rgba(255, 204, 0, .3)',
    },
    pendingRemoval: {
      backgroundColor: 'red',
    },
    manageWindow: {
      minHeight: 485,
      minWidth: 600,
    },
    item: {
      cursor: 'pointer',
    },
  }
}

class empManager extends React.Component {
  state = {
    empSearchResults: [],
    selectedEmps: [],
    empsPendingRemoval: [],
    currentListOpen: true,
  }

  doFindEmployers = ({ q }) => {
    if (q.length === 0 || q.length > 2) {
      return search('/employer', { filter: { q }, page: 1, pageSize: 5 })
    }
    return new Promise((resolve, _) => {
      resolve({ Data: [] })
    })
  }

  handleEmployersQuery = (q) => {
    const { searchParams } = this.state
    this.doFindEmployers(Object.assign({}, searchParams, { q }))
      .then((res) => {
        this.setState({ empSearchResults: res.Data })
      })
      .catch((err) => {
        console.log('failed fetching employers', err)
      })
  }

  toggleSelected = (ID, emp) => {
    return () => {
      const { selectedEmps, empsPendingRemoval } = this.state

      const selected = _.find(selectedEmps, { ID })
      if (!selected) {
        selectedEmps.push(emp)
      } else {
        // mutates the existing group
        _.remove(selectedEmps, { ID })
      }
      this.setState({ selectedEmps })
      this.props.onUpdate({ selectedEmps, empsPendingRemoval })
      console.debug(
        'Selecting a emp: ',
        ID,
        emp,
        ', currently selected: ',
        selectedEmps
      )
    }
  }

  toggleCurrent = (ID, emp) => {
    return () => {
      const { empsPendingRemoval, selectedEmps } = this.state

      const pendingRemoval = _.find(empsPendingRemoval, { ID })

      if (!pendingRemoval) {
        empsPendingRemoval.push(emp)
      } else {
        _.remove(empsPendingRemoval, { ID })
      }
      this.setState({ empsPendingRemoval })
      this.props.onUpdate({ selectedEmps, empsPendingRemoval })
      console.debug(
        'Toggling a current emp: ',
        ID,
        emp,
        ', all pending removal: ',
        empsPendingRemoval
      )
    }
  }

  toggleShowCurrent = () => {
    const { currentListOpen } = this.state
    this.setState({ currentListOpen: !currentListOpen })
  }

  renderCurrentListItem = (emp, avatarClass) => {
    const { classes } = this.props

    return (
      <ListItemAvatar key={emp.ID}>
        <Avatar className={avatarClass}>
          <RadioButtonChecked className={classes.selected} />
        </Avatar>
        <ListItemText
          primary={emp.Name}
          secondary={
            'This Employer is currently active. Manage using "Current Employers"'
          }
        />
      </ListItemAvatar>
    )
  }

  renderResults = () => {
    const { empSearchResults, selectedEmps } = this.state
    const { classes, currentEmps } = this.props

    return (
      <List>
        {empSearchResults.map((emp) => {
          const selected = _.find(selectedEmps, { ID: emp.ID }) !== undefined
          const current = _.find(currentEmps, { ID: emp.ID }) !== undefined

          const active = Models.Employer.isActive(emp)
            ? classes.active
            : classes.inactive
          const avatarClass = selected
            ? classes.selected
            : current
              ? classes.current
              : classes.notSelected

          if (current) {
            return this.renderCurrentListItem(emp, avatarClass)
          }
          return (
            <ListItemAvatar
              key={emp.ID}
              button
              onClick={this.toggleSelected(emp.ID, emp)}
              className={active}>
              <Avatar className={avatarClass}>
                {selected ? (
                  <CheckCircleOutline className={classes.selected} />
                ) : (
                  <CheckBoxOutlineBlank className={classes.notSelected} />
                )}
              </Avatar>
              <ListItemText primary={emp.Name} secondary={emp.ID} />
            </ListItemAvatar>
          )
        })}
      </List>
    )
  }

  renderSelected = () => {
    const { selectedEmps, currentListOpen, empsPendingRemoval } = this.state
    const { classes, currentEmps } = this.props

    return (
      <div>
        <List>
          <ListItem>
            <ListItemText primary="Employers to Add" />
          </ListItem>
          {selectedEmps.map((emp) => {
            const active = Models.Employer.isActive(emp)
              ? classes.active
              : classes.inactive

            return (
              <ListItemAvatar
                key={emp.ID}
                button
                onClick={this.toggleSelected(emp.ID, emp)}
                className={active}>
                <Avatar className={classes.selected}>
                  <CheckCircleOutline className={classes.selected} />
                </Avatar>
                <ListItemText primary={emp.Name} secondary={emp.ID} />
              </ListItemAvatar>
            )
          })}
        </List>
        {currentEmps && currentEmps.length > 0 && (
          <List>
            <ListItem button onClick={this.toggleShowCurrent}>
              <ListItemText primary="Current Employers" />
              {currentListOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={currentListOpen} timeout="auto" unmountOnExit>
              {currentEmps.map((emp) => {
                const pendingRemoval = _.find(empsPendingRemoval, {
                  ID: emp.ID,
                })
                const active = emp.ActiveProvider
                  ? classes.active
                  : classes.inactive

                const avatarClass = pendingRemoval
                  ? classes.pendingRemoval
                  : classes.current
                return (
                  <ListItemAvatar
                    key={emp.ID}
                    button
                    onClick={this.toggleCurrent(emp.ID, emp)}
                    className={active}>
                    <Avatar className={avatarClass}>
                      {pendingRemoval ? (
                        <CheckBoxOutlineBlank
                          className={classes.pendingRemoval}
                        />
                      ) : (
                        <CheckCircleOutline className={classes.current} />
                      )}
                    </Avatar>
                    <ListItemText primary={emp.Name} secondary={emp.ID} />
                  </ListItemAvatar>
                )
              })}
            </Collapse>
          </List>
        )}
      </div>
    )
  }

  render() {
    const { classes } = this.props
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <QSearcher
            search={this.handleEmployersQuery}
            className={classes.searchField}
          />
          <div>{this.renderResults()}</div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>{this.renderSelected()}</div>
        </Grid>
      </Grid>
    )
  }
}

empManager.propTypes = {
  classes: PropTypes.object.isRequired,
}

export const EmpManager = withStyles(styles)(empManager)

class empManagerDialog extends React.Component {
  state = {
    added: [],
    removed: [],
  }

  componentWillUnmount() {
    this.setState({
      added: [],
      remove: [],
    })
  }

  handleCancel = () => {
    this.setState({ added: [], removed: [] })
    this.props.onCancel()
  }

  handleConfirm = () => {
    const { added, removed } = this.state
    this.setState({ added: [], removed: [] })
    this.props.onConfirm({ added, removed })
  }

  trackChanges = ({ selectedEmps, empsPendingRemoval }) => {
    const added = selectedEmps
    const removed = empsPendingRemoval
    console.debug(
      'Generating diff of employers under management: ',
      'Added: ',
      added,
      ' Removed: ',
      removed
    )

    this.setState({ added, removed })
  }

  hasChanges = () => {
    const { added, removed } = this.state

    return !(added.length === 0 && removed.length === 0)
  }

  renderActionText = () => {
    const { added, removed } = this.state
    let addedText = ''
    let removedText = ''
    let separator = ''

    if (added.length !== 0) {
      addedText = `Add ${added.length} emp${added.length > 1 ? 's' : ''}`
    }
    if (removed.length !== 0) {
      removedText = `Remove ${removed.length} emp${removed.length > 1 ? 's' : ''}`
    }
    if (added.length !== 0 && removed.length !== 0) {
      separator = ', '
    }

    return (
      <span>
        {addedText}
        {separator}
        {removedText}
      </span>
    )
  }

  render = () => {
    // open/closed state is managed via the handleCancel/handleConfirm callbacks on the parent component
    const { classes, open, currentEmps } = this.props
    const hasChanges = this.hasChanges()

    return (
      <div>
        <Dialog
          className={classes.dialog}
          open={open}
          onClose={this.handleCancel}>
          <DialogTitle>Manage Employers</DialogTitle>
          <DialogContent className={classes.manageWindow}>
            <DialogContentText>
              Please use this lookup table to manage the employers. No changes
              will be made until you click the Add/Remove button at the bottom.
            </DialogContentText>
            <br />
            <EmpManager
              currentEmps={currentEmps}
              onUpdate={this.trackChanges}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.handleConfirm}
              disabled={!hasChanges}
              color="primary">
              {hasChanges ? this.renderActionText() : <span>No Changes</span>}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

empManagerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  currentEmps: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

empManagerDialog.defaultProps = {
  currentEmps: [],
}

export const EmployerManagerDialog = withStyles(styles)(empManagerDialog)
