import React, { useState, useEffect } from 'react'
import { BillingPlan } from '../../models/BillingPlan'
import { BillingPlanActions } from '../../actions'
import { BillingPlanDialog } from './BillingPlanDialog'
import { DeleteBillingPlanDialog } from './DeleteBillingPlanDialog'
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Button,
  List,
  ListItem,
  ListItemText,
  Grid,
  Grow,
} from '@material-ui/core'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import dateTime from '../../utils/dateTime'
import styled from 'styled-components'
const { getBillingPlan, removeComponentLine } = BillingPlanActions

interface BillingPlanDetailProps {
  id?: number
  onUpdate: () => void
  onDelete: () => void
  priceToMatch?: number
  updateCounter?: number
  defaultCode?: string
  fsID: number
}

export const BillingPlanDetails: React.FC<BillingPlanDetailProps> = ({
  id,
  onUpdate,
  priceToMatch,
  updateCounter,
  onDelete,
  defaultCode,
  fsID,
}) => {
  const [billingPlan, setBillingPlan] = useState<BillingPlan>()
  const [initialized, setInitialized] = useState<boolean>(false)
  const [idPendingDelete, setIDPendingDelete] = useState<number | null>(null)
  const [idEditing, setIDEditing] = useState<number | undefined>()
  const [linePendingRemoval, setLinePendingRemoval] = useState<any>(null)
  const { show: showSnackbar } = useSnackbar()

  useEffect(() => {
    if (!id || id === fsID) {
      setBillingPlan(undefined)
      return
    }
    getBillingPlan({ billingPlanID: id })
      .then((bp: any) => {
        setBillingPlan(bp)
        setInitialized(true)
      })
      .catch((err: string) => {
        showSnackbar(err, SnackbarTypeError)
      })
  }, [id, updateCounter])

  const handleLineClick = (line: any, cpID: number) => {
    return () => {
      // hacky, might want to just add this to the response
      if (line) line.ComponentID = cpID
      setLinePendingRemoval(
        line.ComponentLineID === linePendingRemoval?.ComponentLineID
          ? null
          : line
      )
    }
  }

  const confirmRemoval = (line: any) => {
    return () => {
      removeComponentLine({
        lineID: line.ComponentLineID,
        componentID: line.ComponentID,
      })
        .then((bp: any) => {
          showSnackbar(`Line removed successfully`, SnackbarTypeSuccess)
          setLinePendingRemoval(null)
          onUpdate()
        })
        .catch((err: string) => {
          showSnackbar(err, SnackbarTypeError)
        })
    }
  }

  const renderComponentList = () => {
    const hasComponents = billingPlan?.Components
      ? billingPlan.Components.length > 0
      : false
    if (!hasComponents)
      return (
        <div>
          Use the price picker on the left to add your first component price
        </div>
      )
    return billingPlan?.Components?.map((cp) => {
      const curTotal = calcComponentTotal(cp.Lines)
      return (
        <ComponentCard>
          <CardHeader
            title={
              <CardHeaderTitle>
                <span>{cp.Name}</span>
                {curTotal === 0 ? null : (
                  <span>Total: ${curTotal.toFixed(2)}</span>
                )}
              </CardHeaderTitle>
            }
            subheader={
              <span>
                Updated on {dateTime.parse(cp.UpdatedAt).local().format()} by{' '}
                {cp.ComponentModifiedByUser.Email}
              </span>
            }
          />
          <BillingPlanContent>
            <ComponentList disablePadding dense>
              {cp.Lines.map((line) => {
                const selected =
                  linePendingRemoval?.ComponentLineID === line.ComponentLineID
                return (
                  <ComponentListItem
                    key={line.CPTCode}
                    button
                    onClick={handleLineClick(line, cp.ID)}
                    selected={selected}>
                    <ListItemText
                      primary={
                        <div>
                          CPT: {line.CPTCode}
                          {line.DRGCode ? <div>DRG: {line.DRGCode}</div> : null}
                        </div>
                      }
                      secondary={
                        <div>
                          <span>${line.Price}</span>
                          {line.Units > 0 ? (
                            <span> @ {line.Units} unit(s)</span>
                          ) : null}
                          {line.Notes ? <div>{line.Notes}</div> : null}
                        </div>
                      }
                    />
                    {selected ? (
                      <Grow in={selected}>
                        <div>
                          <Button
                            color="secondary"
                            onClick={confirmRemoval(line)}>
                            Remove
                          </Button>
                        </div>
                      </Grow>
                    ) : null}
                  </ComponentListItem>
                )
              })}
            </ComponentList>
          </BillingPlanContent>
        </ComponentCard>
      )
    })
  }

  const calcTotal = (bp: any) => {
    if (!bp) return 0
    let total = 0
    bp.Components.forEach((bpc: any) => {
      total += calcComponentTotal(bpc.Lines)
    })
    return total
  }
  const calcComponentTotal = (lines: any) => {
    if (!lines) return 0
    let total = 0
    lines.forEach((l: any) => {
      total += Number(l.Price)
    })
    return total
  }

  if (!id || !billingPlan) {
    return (
      <div>
        <Card>
          <CardHeader title="Add a new billing plan, or select an existing one to see details" />
          <CardContent></CardContent>
        </Card>
      </div>
    )
  }

  return (
    <div>
      <Card>
        <CardHeader
          title={
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <span>{billingPlan.Name}</span>
                <Typography variant="body1" color="textSecondary">
                  Updated by {billingPlan.BillingPlanModifiedByUser?.Email} on{' '}
                  {dateTime.parse(billingPlan.UpdatedAt).local().format()}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {billingPlan.Notes}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {/* @todo make this edit the bp */}
                <div>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => setIDEditing(billingPlan.ID as number)}>
                    Edit
                  </Button>
                </div>
                <Button
                  color="secondary"
                  onClick={() => setIDPendingDelete(billingPlan.ID as number)}>
                  Delete
                </Button>
              </Grid>
              <Grid item xs={3}>
                <TotalDiffer
                  target={priceToMatch as number}
                  current={calcTotal(billingPlan)}
                />
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {initialized ? renderComponentList() : <div />}
        </CardContent>
      </Card>
      <BillingPlanDialog
        open={!!idEditing}
        billingPlanID={idEditing}
        onCancel={() => setIDEditing(undefined)}
        defaultCode={defaultCode}
        feeSchedulePriceID={billingPlan.FeeSchedulePriceID as number}
        onUpdate={onUpdate}
      />
      <DeleteBillingPlanDialog
        billingPlanID={idPendingDelete}
        open={idPendingDelete !== null}
        onCancel={() => setIDPendingDelete(null)}
        onDelete={onDelete}
      />
    </div>
  )
}

const ComponentCard = styled(Card)`
  margin-top: 5px;
`

const ComponentList = styled(List)`
  display: flex;
`

const ComponentListItem = styled(ListItem)`
  width: auto;
`

const BillingPlanContent = styled(CardContent)`
  padding: 0px;
`

const CardHeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
`

const FloatRightContainer = styled.div`
  float: right;
`

const FloatLeftContainer = styled.div`
  margin-left: -20px;
`

const TotalContainer = styled.div`
  width: fit-content;
  float: right;
`

const TotalDiffer = ({
  target,
  current,
}: {
  target: number
  current: number
}) => {
  const getDiff = () => {
    const diff = target - current
    const isEqual = diff === 0
    let descriptor = ''

    if (isEqual) descriptor = 'Matched!'
    if (diff > 0) descriptor = 'Under by'
    if (diff < 0) descriptor = 'Over by'

    return { diff, descriptor }
  }

  const diff = getDiff()
  return (
    <TotalContainer>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="body1">
            <FloatLeftContainer>Target</FloatLeftContainer>
            <FloatLeftContainer>Current</FloatLeftContainer>
            <FloatLeftContainer>{diff.descriptor}</FloatLeftContainer>
          </Typography>
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={3}>
          <Typography variant="body1">
            <FloatRightContainer>${target}</FloatRightContainer>
            <FloatRightContainer>${current.toFixed(2)}</FloatRightContainer>
            <FloatRightContainer>${diff.diff.toFixed(2)}</FloatRightContainer>
          </Typography>
        </Grid>
      </Grid>
    </TotalContainer>
  )
}
