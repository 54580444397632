import React, { useRef } from 'react'
import * as ReceiptsTable from '../Components/ReceiptsTable'
import ReceiveEmployerFunds from '../Components/ReceiveEmployerFunds'
import { Divider, IconButton } from '@material-ui/core'
import ReceiveProviderFunds from '../Components/ReceiveProviderFunds'
import DesignSuite2023 from '../../../components/DesignSuite2023'

export default function PageApply(): React.ReactElement {
  const refReceiptsTable = useRef<any>(null)

  const refreshReceiptsTable = () => {
    refReceiptsTable.current.refresh()
  }

  const pageColumnLeft = (
    <div className="lefty">
      <div style={{ paddingBottom: '1rem', textAlign: 'right' }}>
        <IconButton
          size="small"
          onClick={() => {
            refreshReceiptsTable()
          }}>
          <DesignSuite2023.CommonIcons.IconRefresh />
        </IconButton>
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <ReceiveEmployerFunds onCancel={refreshReceiptsTable} />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <ReceiveProviderFunds onCancel={refreshReceiptsTable} />
      </div>
      <Divider />
      <div style={{ paddingBottom: '1rem' }}>
        <ReceiptsTable.StandardFilterSearch
          autoFocus
          label="Search"
          InputLabelProps={{ shrink: true }}
        />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <ReceiptsTable.FilterEmployer />
      </div>
      <div style={{ paddingBottom: '1rem' }}>
        <ReceiptsTable.FilterIncludeZeroBalance />
      </div>
    </div>
  )

  return (
    <ReceiptsTable.Table
      ref={refReceiptsTable}
      className="full-fixed"
      enableURLReflection="zclaims.receipts.list"
      DataTableProps={{ stickyHeader: true }}
      BeforeTable={pageColumnLeft}
    />
  )
}
