import util from './utils'
import { FileActions } from '../actions'
const {
  GET_FILE_CLAIMS,
  GET_COMPONENT_FEE_SCHEDULE_FILE_TYPES,
  GET_FILE_TYPES,
  SAVE_FILE,
  SAVE_NEW_FILE,
  DELETE_FILE,
  CLEAR_FILES,
} = FileActions

export default function (state = null, action) {
  switch (action.type) {
    case GET_COMPONENT_FEE_SCHEDULE_FILE_TYPES:
      if (action.payload.error) {
        return {
          ...state,
          getError: util.errorResponse(action),
        }
      }
      return {
        ...state,
        fileTypes: filterExplicit(action.payload.Data),
        fileTypeFilters: formatFileTypes(action.payload.Data),
      }
    case GET_FILE_TYPES:
      if (action.payload.error) {
        return {
          ...state,
          getError: util.errorResponse(action),
        }
      }
      return {
        ...state,
        fileTypes: filterExplicit(action.payload.Data),
        fileTypeFilters: action.payload.Data,
      }
    case GET_FILE_CLAIMS:
      if (action.payload.error) {
        return {
          ...state,
          getError: util.errorResponse(action),
        }
      }
      return {
        ...state,
        fileClaims: getFileClaimsOptions(action.payload.Data),
      }
    case CLEAR_FILES:
      return {
        ...state,
        files: [],
      }
    case SAVE_FILE:
    case SAVE_NEW_FILE:
      if (action.payload.error) {
        return {
          ...state,
          saveResult: util.errorResponse(action),
        }
      }
      return {
        ...state,
        saveResult: action.payload.Data,
      }
    case DELETE_FILE:
      if (action.payload.error) {
        return {
          ...state,
          saveResult: util.errorResponse(action),
        }
      }
      return {
        ...state,
        files: [], // rows are now managed in state and not refetched
        saveResult: action.payload.Data,
      }
    default:
      return {
        ...state,
      }
  }
}

function getFileClaimsOptions(fileClaims) {
  return [{ id: -1, label: 'All Employers' }].concat(
    fileClaims.map((f) => {
      return {
        id: f.ID,
        label: `(FileClaimID:${f.ID}) ${f.EmployerName}: ${f.Descr}`,
      }
    })
  )
}

const filterExplicit = (fileTypes) => {
  if (!fileTypes || !fileTypes.length || fileTypes.length === 0) {
    return []
  }

  console.debug('Filtering out explicit file types')
  return fileTypes.filter((ft) => {
    return !ft.IsExplicitType
  })
}

const formatFileTypes = (fileTypes) => {
  return [
    {
      key: fileTypes[0].ID,
      value: fileTypes[0].ID,
      label: fileTypes[0].Description,
    },
  ]
}
