import React, { forwardRef, useContext } from 'react'
import GroupModel from '../../models/Group'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import { GroupActions } from '../../actions'
import {
  OrganizationSearcherV2,
  PracticeFacilitySearcher,
} from '../../components/Searchers'
// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../hooks/useStandardTableSetup'

export const defaultColumns = Object.freeze(GroupModel.columns)

const defaultSort = { col: 'created_at', dir: 'asc' }

export const Table = forwardRef(function CostKeyTableList(
  {
    customColumns = defaultColumns,
    apiEndpoint = defaultAPIEndpoint,
    fnLinkOnRowClick = (row: any) => `/group/${row.ID}`,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      fnLinkOnRowClick,
    },
    ref
  )

  return <>{TableDisplay}</>
})

function defaultAPIEndpoint(payload: any): Promise<any> {
  return GroupActions.findGroups(payload).payload
}

export function FilterOrganization(): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <OrganizationSearcherV2
      selectedOrganizationID={filter.organizationIDs?.[0]}
      onChange={(id: number | null) => {
        if (!id) {
          return setFilterImmediate({ organizationIDs: [] })
        }
        setFilterImmediate({ organizationIDs: [id] })
      }}
      TextFieldProps={{
        variant: 'outlined',
        size: 'small',
        InputLabelProps: { shrink: true },
        style: { minWidth: 250, maxWidth: 350 },
      }}
      AutoCompleteProps={{
        style: { display: 'inline-block', maxWidth: 350 },
      }}
    />
  )
}

export function FilterPracticeFacility(): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <PracticeFacilitySearcher
      selectedPracticeFacilityID={filter.practiceFacilityIDs?.[0]}
      onChange={(id: number | null) => {
        if (!id) {
          return setFilterImmediate({ practiceFacilityIDs: [] })
        }
        setFilterImmediate({ practiceFacilityIDs: [id] })
      }}
      TextFieldProps={{
        variant: 'outlined',
        size: 'small',
        InputLabelProps: { shrink: true },
        style: { minWidth: 250, maxWidth: 350 },
      }}
      AutoCompleteProps={{
        style: { display: 'inline-block', maxWidth: 350 },
      }}
    />
  )
}
