import React, { useState, useEffect } from 'react'
import { editEmployerMemberSkipUpdateDate } from '../../actions/MemberActions'
import DesignSuite2023, { AlertWarning } from '../../components/DesignSuite2023'
import dateTime from '../../utils/dateTime'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import {
  renderSwitchField as RenderSwitchField,
  renderNotesField as RenderNotesField,
  SetterArgs,
} from '../ViewHelpers'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'
import * as shapes from './types'

interface props {
  Trigger: any // React.ReactComponent
  empMemRec: shapes.shapeEmployerMember | null
  onSaved?(): void
}

export default function DialogSetSkipUpdate({
  Trigger,
  empMemRec,
  onSaved,
}: props): React.ReactElement | null {
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const [isOpen, setIsOpen] = useState(false)
  const [payload, setPayload] = useState<any>({
    SkipUpdateDate: empMemRec?.SkipUpdateUntil || null,
    NoSkipUpdateDate: false,
    ApplyToDependents: false,
    Notes: null,
  })

  useEffect(() => {
    if (!isOpen) return
    setPayload({
      SkipUpdateDate: empMemRec?.SkipUpdateUntil || null,
      NoSkipUpdateDate: false,
      ApplyToDependents: false,
      Notes: null,
    })
  }, [isOpen])

  useEffect(() => {
    if (payload.NoSkipUpdateDate) {
      setter({ name: 'SkipUpdateDate', value: null })
      return
    }
  }, [payload.NoSkipUpdateDate])

  function setter({ name, value }: SetterArgs) {
    setPayload((curr: any) => ({ ...curr, [name]: value }))
  }

  function doSave() {
    if (!payload.NoSkipUpdateDate) {
      const d = dateTime.parse(payload.SkipUpdateDate)
      if (!d.isValid() || d.isBefore(dateTime.presets.Y2015)) {
        alert('Invalid date')
        return
      }
    }

    editEmployerMemberSkipUpdateDate({
      ...payload,
      EmployerMemberID: empMemRec?.ID,
    })
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar('Skip update date saved OK!', SnackbarTypeSuccess)
        onSaved && onSaved()
      })
      .catch(
        catchAPIError({ defaultMessage: 'Failed updating skip update date' })
      )
      .finally(() => setIsOpen(false))
  }

  if (!empMemRec) return null

  return (
    <>
      <DesignSuite2023.Tooltip title="Manually set skip update until date">
        <Trigger onClick={() => setIsOpen(true)} />
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>
          Change "Skip Update Until" Date
          <small style={{ display: 'block' }}>
            Note: this is an eligibility related concept; if you do not know
            what this is, leave.
          </small>
        </DialogTitle>
        <DialogContent>
          {!payload.NoSkipUpdateDate && (
            <ManagedDateInput
              name="SkipUpdateDate"
              label="SkipUpdateDate Date"
              value={payload.SkipUpdateDate}
              setter={setter}
            />
          )}

          <div style={{ padding: '1rem 0' }}>
            <RenderSwitchField
              setter={setter}
              name="NoSkipUpdateDate"
              label={
                <>
                  <span>Disable "skip update until" date</span>
                  <small style={{ display: 'block' }}>
                    Sets "skip update until" date to never.
                  </small>
                </>
              }
              value={payload.NoSkipUpdateDate}
            />
          </div>

          {empMemRec?.IsSubscriber && (
            <>
              <RenderSwitchField
                setter={setter}
                name="ApplyToDependents"
                label={
                  <>
                    <span>Apply to Dependents</span>
                    <small style={{ display: 'block' }}>
                      "Skip update until" date will propagate to dependents if
                      this member is a subscriber.
                    </small>
                  </>
                }
                value={payload.ApplyToDependents}
              />

              {payload.ApplyToDependents && (
                <>
                  <AlertWarning>
                    <strong>Warning</strong>: this will set the skip update
                    until date for the following dependents (all that are not
                    currently marked retired):
                    <ul>
                      {empMemRec?.Dependents?.map((d: any) => (
                        <li key={d.EmployerMemberID}>
                          (EmployerMemberID: {d.EmployerMemberID}) {d.FirstName}{' '}
                          {d.LastName}
                        </li>
                      ))}
                    </ul>
                  </AlertWarning>
                </>
              )}
            </>
          )}

          <RenderNotesField
            setter={setter}
            name="Notes"
            label="Notes"
            value={payload.Notes}
            opts={{
              margin: 'normal',
              variant: 'outlined',
              size: 'small',
              helperText:
                "Warning: don't ever put sensitive information in notes!",
            }}
          />

          {/* <pre>{JSON.stringify(payload, null, '  ')}</pre> */}
        </DialogContent>
        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            }
            right={
              <Button color="primary" variant="contained" onClick={doSave}>
                Save
              </Button>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
