import * as api from '../services/thezerocard/api-helper'

export const getBillingPlan = ({
  billingPlanID,
}: {
  billingPlanID: number | string
}) => {
  return api
    .get(`/billing_plan/${billingPlanID}`)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res.Data
    })
    .catch((err: any) => {
      if (err.Error?.Message) {
        throw err.Error.Message
      }
      throw `Failed loading billing plan; please contact Engineering`
    })
}

export const deleteBillingPlan = ({
  billingPlanID,
}: {
  billingPlanID: number | string
}) => {
  return api
    .del(`/billing_plan/${billingPlanID}`)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
    })
    .catch((err: any) => {
      if (err.Error?.Message) {
        throw err.Error.Message
      }
      throw `Failed deleting billing plan; please contact Engineering`
    })
}

export const getFeeSchedulePrice = ({
  feeSchedulePriceID,
}: {
  feeSchedulePriceID: number | string
}) => {
  return api
    .get(`/fee_schedule_price/${feeSchedulePriceID}`)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res.Data
    })
    .catch((err: any) => {
      if (err.Error?.Message) {
        throw err.Error.Message
      }
      throw `Failed loading fee schedule price; please contact Engineering`
    })
}

export const getFSPriceBillingPlans = ({
  feeSchedulePriceID,
}: {
  feeSchedulePriceID: number | string
}) => {
  return api
    .get(`/fee_schedule_price/${feeSchedulePriceID}/billing_plan`)
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res.Data
    })
    .catch((err: any) => {
      if (err.Error?.Message) {
        throw err.Error.Message
      }
      throw `Failed loading billing plans; please contact Engineering`
    })
}

export const findComponentPrices = (query: string, fsID: number) => {
  return api
    .search(`/component_fee_schedule/${fsID}/prices`, { filter: { q: query } })
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res.Data
    })
    .catch((err: any) => {
      if (err.Error?.Message) {
        throw err.Error.Message
      }
      throw `There was a problem searching for component prices. Please try again`
    })
}

export const findComponentFS = (query: string) => {
  return api
    .search('/component_fee_schedule', { filter: { q: query } })
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res.Data
    })
    .catch((err: any) => {
      if (err.Error?.Message) {
        throw err.Error.Message
      }
      throw `There was a problem searching for component prices. Please try again`
    })
}

export const addPriceToBillingPlan = ({
  bpID,
  fspID,
}: {
  bpID: number
  fspID: number
}) => {
  return api
    .post(`/billing_plan/${bpID}/price/${fspID}`, {})
    .then((res: any) => {
      if (res.error) {
        throw res
      }
      return res.Data
    })
    .catch((err: any) => {
      if (err.Error?.Message) {
        throw err.Error.Message
      }
      throw `There was a problem searching for component prices. Please try again`
    })
}

export const removeComponentLine = ({
  lineID,
  componentID,
}: {
  lineID: number
  componentID: number
}) => {
  return api
    .del(`/component/${componentID}/component_line/${lineID}`, {})
    .then((res: any) => {
      if (res.error) {
        throw res
      }
    })
    .catch((err: any) => {
      if (err.Error?.Message) {
        throw err.Error.Message
      }
      throw `There was a problem removing the component line. Please try again.`
    })
}
