import * as api from '../../services/thezerocard/api-helper'
import React, { useState } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import DesignSuite2023 from '../../components/DesignSuite2023'
import ConfirmDialog from '../../components/ConfirmDialog'

interface resp {
  error?: boolean
  // if response is OK
  Data?: any
}

export default () => {
  const [loading, setLoading] = useState<boolean>(false)
  const snackbar = useSnackbar()

  const onClick = () => {
    ConfirmDialog({
      content:
        'This will queue the duplicate member detection process ASAP. Confirm you want to proceed...',
      onConfirm() {
        setLoading(true)
        postFindDuplicateMembers()
          .then((v: resp) => {
            if (v.error) {
              throw v
            }
            setLoading(false)
            snackbar.show(
              'Find duplicate members task queued OK',
              SnackbarTypeSuccess
            )
          })
          .catch((e: resp | Error) => {
            setLoading(false)
            snackbar.show(
              'Find duplicate members task queue failed 😩',
              SnackbarTypeError
            )
          })
      },
    })
  }

  return (
    <DesignSuite2023.Tooltip
      placement="left"
      title="Trigger find duplicate members task (dialog)">
      <Button
        variant="contained"
        color="primary"
        onClick={onClick}
        disabled={loading}>
        {loading && (
          <CircularProgress size={20} style={{ marginRight: '0.5rem' }} />
        )}{' '}
        Find Duplicate Members
      </Button>
    </DesignSuite2023.Tooltip>
  )
}

function postFindDuplicateMembers() {
  return api.post('/task_find_duplicate_members')
}
