import React, { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import * as practiceFacilityActions from '../../actions/PracticeFacilityActions'

interface SpecAutoCompleteProps {
  handleChange: (specs: Spec[]) => void
  currentSpecs: Spec[]
}

export interface Spec {
  Label: string
  ID: number
}

export const SpecializationsAutocomplete = ({
  handleChange,
  currentSpecs,
}: SpecAutoCompleteProps) => {
  const { catchAPIError } = useErrorHandlers()
  const [allSpecs, setAllSpecs] = useState<Array<Spec>>([])
  const [selectedSpecs, setSelectedSpecs] = useState<Array<Spec>>(currentSpecs)

  useEffect(() => {
    practiceFacilityActions
      .getAllPracticeFacilitySpecializationsV2()
      .then((res: Array<Spec>) => {
        const formattedSpecs = res?.map((spec) => {
          return { Label: spec.Label, ID: spec.ID }
        })
        setAllSpecs(formattedSpecs)
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to fetch Specializations; please contact Engineering',
        })
      )
  }, [])

  const handleSelected = (e: any, value: Array<Spec>) => {
    setSelectedSpecs(value)
    handleChange(value)
  }

  return (
    <Autocomplete
      multiple
      options={allSpecs}
      getOptionLabel={(option: Spec) => option.Label}
      value={selectedSpecs}
      onChange={handleSelected}
      renderInput={(params: any) => (
        <TextField
          {...params}
          variant="outlined"
          label="Specializations"
          placeholder="Select one or more specializations"
        />
      )}
    />
  )
}
