import React, { useEffect, useRef, useState } from 'react'
import useErrorHandlers from '../../../../../hooks/useErrorHandlers'
import useSnackbar, {
  SnackbarTypeSuccess,
} from '../../../../../hooks/useSnackbar'
import DesignSuite2023 from '../../../../../components/DesignSuite2023'
import { Button } from '@material-ui/core'
import styled from 'styled-components'
import * as GoZeroTable from './GoZeroSourceTable'
import { postZClaimProcessGoZero } from '../../../../../actions/ZClaimActions'

const StyledWorkflow = styled.div`
  .std-table {
    .header-items {
      margin-top: -0.5rem;
    }
  }
`

export default function WorkflowGoZero(): React.ReactElement | null {
  const refGoZeroTable = useRef<any>(null)
  const [selectedGoZeroSrcs, setSelectedGoZeroSrcs] = useState([])
  const [canProcess, setCanProcess] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const { catchAPIError } = useErrorHandlers()
  const { showDurationShort: showSnackbar } = useSnackbar()

  useEffect(() => {
    setCanProcess(!!selectedGoZeroSrcs.length)
  }, [selectedGoZeroSrcs])

  function onClickProcessSources() {
    if (isProcessing) return
    setIsProcessing(true)
    const srcGoZeroIDs = selectedGoZeroSrcs.map((b: any) => b.ID)
    postZClaimProcessGoZero({ SrcGoZeroIDs: srcGoZeroIDs })
      .then(() => {
        showSnackbar('GoZero Referral Invoices processed', SnackbarTypeSuccess)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed processing GoZero Referral Invoices',
        })
      )
      .finally(() => {
        setIsProcessing(false)
        refGoZeroTable.current?.refresh()
      })
  }

  return (
    <StyledWorkflow>
      <GoZeroTable.Table
        ref={refGoZeroTable}
        onCheckHandler={setSelectedGoZeroSrcs}
        enableURLReflection="zclaims.workflows.sourceGoZero"
        LeftHeaderItems={
          <>
            <h4>Referral Invoices</h4>
          </>
        }
        RightHeaderItems={
          <>
            {!!selectedGoZeroSrcs.length && (
              <>
                {canProcess && (
                  <span>
                    <DesignSuite2023.Tooltip title="Once a GoZero Referral Invoice is processed, it converts the Referral Invoice to a ZClaim">
                      <Button
                        disabled={isProcessing}
                        variant="outlined"
                        size="small"
                        onClick={onClickProcessSources}>
                        Process GoZero Invoices
                        {isProcessing && (
                          <>
                            &nbsp;&nbsp;
                            <DesignSuite2023.LoadingSpinner size={15} />
                          </>
                        )}
                      </Button>
                    </DesignSuite2023.Tooltip>
                  </span>
                )}
                &nbsp;&nbsp;
              </>
            )}
          </>
        }
        DataTableProps={{
          LeftFooterItems: !!selectedGoZeroSrcs.length && (
            <>
              <small>
                <strong>DevTools:</strong>
              </small>
            </>
          ),
        }}
      />
    </StyledWorkflow>
  )
}
