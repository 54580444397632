import React, { useState, useEffect } from 'react'
import * as api from '../../services/thezerocard/api-helper'
import * as typed from './typed'
import { isEqual } from 'lodash'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid'
import dateTime from '../../utils/dateTime'

const valueFormatterDate = {
  valueFormatter: ({ value }: any) => {
    return dateTime.parse(value).format()
  },
}

// https://mui.com/components/data-grid/columns/#column-types
const tableCols = [
  { field: 'ID', headerName: 'ID', sortable: false },
  { field: 'ClaimNr', headerName: 'ClaimNr', width: 110 },
  { field: 'GroupNr', headerName: 'GroupNr', width: 110 },
  {
    field: 'ServiceStart',
    headerName: 'ServiceStart',
    width: 140,
    ...valueFormatterDate,
  },
  {
    field: 'PaymentDate',
    headerName: 'PaymentDate',
    width: 150,
    ...valueFormatterDate,
  },
  { field: 'CptCode', headerName: 'CPTCode', width: 115 },
  {
    field: 'BilledAmount',
    headerName: 'BilledAmount',
    width: 120,
    sortable: false,
  },
  {
    field: 'PaidAmount',
    headerName: 'PaidAmount',
    width: 120,
    sortable: false,
  },
  {
    field: 'TypicalPpoPrice',
    headerName: 'TypicalPpoPrice',
    width: 150,
    sortable: false,
  },
  {
    field: 'TypicalHrrPpoPrice',
    headerName: 'TypicalHrrPpoPrice',
    width: 160,
    sortable: false,
  },
  {
    field: 'IsAdjustmentClaim',
    headerName: 'IsAdjustmentClaim',
    sortable: false,
  },
  { field: 'IsVoidClaim', headerName: 'IsVoidClaim', sortable: false },
  {
    field: 'PerformingProviderName',
    headerName: 'PerformingProvider',
    width: 200,
  },
  { field: 'Units', headerName: 'Units', width: 80, sortable: false },
  { field: 'PosCode', headerName: 'PosCode', sortable: false },
  { field: 'UbCode', headerName: 'UbCode', sortable: false },
  { field: 'CptModifier', headerName: 'CptModifier', sortable: false },
  { field: 'Diagnosis1', headerName: 'Diagnosis1', sortable: false },
]

interface props {
  activeGroup: typed.GroupRecord | undefined
  trackedLines: typed.TrackedLines
  setTrackedLines: Function
}

export default React.memo(function LinesDisplayTable({
  activeGroup,
  trackedLines,
  setTrackedLines,
}: props) {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: 'ServiceStart', sort: 'desc' },
  ])
  const [trackedPage, setTrackedPage] = useState(0)
  const { show: showSnackbar } = useSnackbar()

  useEffect(() => {
    if (!activeGroup) {
      return
    }
    // if group has a patientId, load lines...
    if (activeGroup.PatientId !== '') {
      loadLinesByPatientID(activeGroup.PatientId)
        .then((data: any) => {
          const selectedLineIDs = data
            .filter((v: any) => {
              return v.GroupNr === activeGroup.GroupNr
            })
            .map((v: any) => {
              return v.ID
            })
            .sort()
          setTrackedLines({
            lines: data,
            selectedIDs: selectedLineIDs,
            initiallySelectedIDs: selectedLineIDs,
            changed: false,
          })
        })
        .catch((e: any) => {
          showSnackbar(e.message, SnackbarTypeError)
          setTrackedLines({
            lines: [],
            selectedIDs: [],
            initiallySelectedIDs: [],
            changed: false,
          })
        })
    }
  }, [activeGroup, setTrackedLines, showSnackbar])

  const getRowClassName = (params: any): string => {
    if (!activeGroup) {
      return ''
    }
    if (trackedLines.selectedIDs.includes(params.row.ID)) {
      if (params.row.GroupNr === activeGroup.GroupNr) {
        return 'active-line-in-active-group'
      }
      return 'active-line-outside-active-group'
    }
    return ''
  }

  if (!activeGroup) {
    return null
  }

  return (
    <div className="line-review-container">
      <DataGrid
        autoHeight
        checkboxSelection
        density="compact"
        page={trackedPage}
        onPageChange={setTrackedPage}
        rows={trackedLines.lines.map((v: any) => {
          v.id = v.ID
          return v /* data grid requires 'id' property */
        })}
        columns={tableCols}
        components={{
          Toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarColumnsButton />
              <GridToolbarFilterButton />
            </GridToolbarContainer>
          ),
        }}
        getRowClassName={getRowClassName}
        className="lines-table"
        onSelectionModelChange={(model: GridSelectionModel): void => {
          const ids = ((model as Array<number>) || []).sort()
          setTrackedLines((prev: typed.TrackedLines) => ({
            ...prev,
            selectedIDs: ids,
            changed: !isEqual(prev.initiallySelectedIDs, ids),
          }))
        }}
        selectionModel={trackedLines.selectedIDs}
        disableColumnMenu={true} // https://mui.com/components/data-grid/columns/#column-menu
        sortModel={sortModel}
        onSortModelChange={(sm: GridSortModel) => {
          setTrackedPage(0)
          setSortModel(sm)
        }}
      />
    </div>
  )
})

async function loadLinesByPatientID(patientID: string): Promise<any> {
  const req = (await api.get('/savings_review/claim_line', {
    patient_id: patientID,
  })) as any
  if (req.error) {
    if (req.errList) {
      throw new Error(req.errList[0])
    }
    throw new Error(
      'Unable to load reviewable claims; please contact engineering'
    )
  }
  return req.Data
}
