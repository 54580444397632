import React, { useCallback, useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { EmployerActions, SnackbarActions } from '../../actions'
import debounce from 'lodash/debounce'
import './styles.scss'

const { getEmployers, getEmployerById } = EmployerActions
const { setSnackbarMessage } = SnackbarActions

interface DataItem {
  ID: number
  Name: string
}

interface Props {
  selectedEmployerID: number | null
  onChange(employerID: number | null, employer?: any): void
  setSnackbarMessage(message: string, level: string): void
  AutoCompleteProps?: object
  TextFieldProps?: object
  disabled?: boolean
  disableClear?: boolean
  onlyCustomers?: boolean
}

const EmployerSearcher = ({
  selectedEmployerID,
  onChange,
  setSnackbarMessage,
  AutoCompleteProps = {},
  TextFieldProps = {},
  disabled = false,
  disableClear = false,
  onlyCustomers = false,
}: Props) => {
  const [query, setQuery] = useState<string>('')
  const [data, setData] = useState<Array<DataItem>>(Array<DataItem>())
  const [active, setActive] = useState<DataItem | null>(null)

  const showGenericErr = useCallback(() => {
    setSnackbarMessage(
      'Uh oh, something went awry searching for employers. Try again later.',
      'error'
    )
  }, [setSnackbarMessage])

  useEffect(() => {
    if (!selectedEmployerID) {
      return
    }
    if (active && active.ID === selectedEmployerID) {
      return
    }
    const req = getEmployerById({ employerId: selectedEmployerID })
    if (req && req.payload) {
      req.payload
        .then((r: any) => {
          if (r.error) {
            return showGenericErr()
          }
          setActive({ ID: r.Data.ID, Name: r.Data.Name })
        })
        .catch(showGenericErr)
    }
  }, [selectedEmployerID, active, showGenericErr])

  // if selectedEmployerID prop passed in and its falsy, setActive to null
  useEffect(() => {
    !selectedEmployerID && setActive(null)
  }, [selectedEmployerID])

  useEffect(() => {
    if (query.length < 3) {
      return
    }
    const filter = { q: query } as any
    if (onlyCustomers) {
      filter.isCustomer = true
    }
    getEmployers({
      filter,
      sort: ['Employer.Name', 'asc'],
    })
      .then((r: any) => {
        if (r.error) {
          return showGenericErr()
        }
        setData(
          r.Data.map((p: any) => {
            return { ID: p.ID, Name: p.Name, ...p }
          })
        )
      })
      .catch(showGenericErr)
  }, [query, showGenericErr])

  const onInputChange = debounce((ev: any, q: string, r: string) => {
    setQuery(q)
  }, 350)

  const onSelectionChange = (_: any, b: DataItem | null, reason: string) => {
    setActive(b)
    onChange(b ? b.ID : null, b)
  }

  return (
    <Autocomplete
      disabled={disabled}
      classes={{ root: 'autocomplete-root' }}
      options={data}
      getOptionLabel={(opt: DataItem) => opt.Name}
      getOptionSelected={(opt: DataItem) => {
        return active ? opt.ID === active.ID : false
      }}
      defaultValue={null}
      value={active}
      onChange={onSelectionChange}
      onInputChange={onInputChange}
      disableClearable={disableClear}
      renderInput={(params: any) => (
        <TextField
          {...params}
          variant="outlined"
          size="small"
          label="Employer"
          placeholder="Start typing to search..."
          style={{ minWidth: 250 }}
          {...TextFieldProps}
        />
      )}
      {...AutoCompleteProps}
    />
  )
}

export default connect(
  (state: any) => {
    return {}
  },
  (dispatch: any) => {
    return bindActionCreators({ setSnackbarMessage }, dispatch)
  }
)(EmployerSearcher)
