import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  CircularProgress,
} from '@material-ui/core'

import { FeeScheduleActions } from '../../actions'
const { applyToOrganization } = FeeScheduleActions

const styles = (theme) => {
  return {}
}

export class applyToOrgDialog extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      saving: false,
    }
  }

  handleCancel = () => {
    this.props.onCancel()
  }

  handleConfirm = () => {
    const { orgId, fsId } = this.props
    if (!orgId) {
      window.alert(
        'This functionality is currently not working. Please contact engineering.'
      )
      return
    }

    this.setState({ saving: true })
    this.props.applyToOrganization({ id: fsId, orgId }).then((res) => {
      this.props.onConfirm()
      this.setState({ saving: false })
    })
  }

  render() {
    const { classes, open, orgId } = this.props
    const { saving } = this.state
    return (
      <div>
        <Dialog
          className={classes.dialog}
          open={open}
          onClose={this.handleCancel}>
          <DialogTitle>Manage Facilities</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {orgId ? (
                <Typography>
                  This will add every <i>currently active practice facility</i>{' '}
                  from the organization to this fee schedule. If you're sure
                  this is what you'd like to do, hit <strong>Apply</strong>{' '}
                  below.
                </Typography>
              ) : (
                <Typography>
                  You need to select an organization before utilizing this
                  option
                </Typography>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.handleConfirm} color="primary">
              {saving ? <CircularProgress /> : <span>Apply</span>}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ applyToOrganization }, dispatch)
}

export const ApplyToOrgDialog = compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(applyToOrgDialog)

ApplyToOrgDialog.propTypes = {
  open: PropTypes.boolean,
  onCancel: PropTypes.func,
  orgId: PropTypes.number.isRequired,
}

ApplyToOrgDialog.defaultProps = {
  open: false,
  onCancel: () => {
    console.debug(
      'You must set an onCancel property on the FeeScheduleRelationsManager'
    )
  },
}
