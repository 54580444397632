import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'

interface props {
  value: any
  onChange?(nextVal: any): void
  restrictInput?: RegExp
  numeric?: boolean
  textPrefix?: string
}

const StyledDiv = styled.div`
  line-height: 1;
  padding: 0.25rem 0.35rem;
  font-size: inherit;

  &[disabled] {
    background: #f5f5f5;
    color: #888;
    cursor: not-allowed;
  }

  &[data-text-prefix]:before {
    content: attr(data-text-prefix);
    display: inline;
    padding-right: 0.15rem;
    opacity: 0.75;
  }
`

export const rgxIntegerOnly = /^[0-9]*$/
export const rgxFloatOnly = /^[0-9.]*$/
export const rgxAlphaOnly = /^[a-zA-Z]*$/

/*
The claim line table makes use of a custom input, that is just a <div> with contenteditable
attribute on it. Normal <input /> types are not friendly in a table layout like this where
users will prefer to use tabs and arrow keys navigating around, and it helps a lot with
content overflows and width etc (iow - extra work, but the experience is much better).
*/
export const Input = React.forwardRef(function Input(
  {
    value,
    onChange,
    restrictInput,
    numeric = false,
    textPrefix = '',
    ...otherProps
  }: props & Partial<any>,
  ref: any
): React.ReactElement {
  const [trackVal, setTrackVal] = useState<any>(value)
  const inputRef = useRef<any>(null)

  useEffect(() => {
    if (!inputRef?.current) return
    inputRef.current.textContent = value === null ? '' : value
    setTrackVal(value)
  }, [value])

  function onInput(ev: React.ChangeEvent<HTMLDivElement>) {
    // .replace() removes null characters (no IDEA why they appear, but they do and break things). This
    // is a particularly pernicious bug and hard to observe reliably: but in the context of creating a
    // new claim manually, when you're adding claim lines and you set the DiagnosisCode[1|2|3|4] fields
    // WITHOUT the .replace regex below, it errors and crashes the whole interface.
    // eslint-disable-next-line
    const x = ev.target?.textContent?.replace(/\x00/g, '')

    if (x === '' || x === undefined || x === 'undefined') {
      setTrackVal(null)
      return
    }
    if (numeric && x?.length > 0) {
      setTrackVal(Number.parseFloat(x))
      return
    }
    setTrackVal(x)
  }

  function onFocus() {
    if (!inputRef?.current) return
    // move cursor to end
    const sel = window.getSelection()
    sel?.selectAllChildren(inputRef.current)
    // sel?.collapseToEnd()
  }

  function onBlur() {
    if (trackVal === value) return
    onChange && onChange(trackVal)
  }

  function onClick() {
    if (!inputRef?.current) return
    inputRef.current.focus()
  }

  function onKeyPress(ev: React.KeyboardEvent<HTMLDivElement>) {
    if (!restrictInput) return
    if (!restrictInput.test(ev.key)) ev.preventDefault()
    return
  }

  if (!!textPrefix) {
    otherProps['data-text-prefix'] = textPrefix
  }

  return (
    <StyledDiv
      {...otherProps}
      contentEditable={!otherProps?.disabled}
      ref={inputRef}
      onInput={onInput}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
      onKeyPress={onKeyPress}
    />
  )
})
