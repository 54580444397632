import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import StatusDescriptor from './StatusDescriptor'
import { ReferralActions } from '../../actions'
const { getReferralStatuses } = ReferralActions

// can optimize these to pull from application state first before fetching
class ReferralStatusResolver extends Component {
  componentDidMount() {
    const { statuses } = this.props

    if (statuses.length === 0) {
      this.props.getReferralStatuses()
    }
  }

  render() {
    const { id, statuses } = this.props
    return (
      <div>
        <StatusDescriptor statuses={statuses} id={id} />
      </div>
    )
  }
}

ReferralStatusResolver.propTypes = {
  id: PropTypes.number.isRequired,
}

function mapStateToProps(state, props) {
  return {
    statuses: state.referrals.statuses || [],
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getReferralStatuses }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferralStatusResolver)
