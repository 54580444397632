import React, { useEffect, useState } from 'react'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import * as PracticeFacilityTable from '../PracticeFacilities/PracticeFacilityTable2'
import { putAddNetworkEntities } from '../../actions/NetworkActions'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Tooltip,
} from '@material-ui/core'
import _pick from 'lodash/pick'
import styled from 'styled-components'

const StyledDialogContent = styled(DialogContent)`
  .header-items {
    margin-top: 0 !important;
    h3 {
      margin: 0;
      padding: 0;
    }
  }
`

const customColsPFTable = Object.assign(
  {},
  _pick(PracticeFacilityTable.defaultColumns, [
    'ID',
    'Name',
    'LaunchDate',
    'TerminationDate',
  ]),
  {
    ActiveFS: {
      name: 'Active Fee Schedule',
      details: {
        dataFormat: (_: any, row: any): any => {
          if (row.HasActiveFeeSchedule) {
            return (
              <span>
                <strong>YES</strong> ({row.ActiveFeeScheduleName})
              </span>
            )
          }
          return `No`
        },
      },
    },
    CityState: {
      name: 'City/State',
      details: {
        dataFormat: (_: any, row: any): string | null => {
          if (!row.Address?.City || !row.Address?.State) {
            return null
          }
          return `${row.Address?.City}, ${row.Address?.State}`
        },
      },
    },
  }
)

export interface props {
  networkID: number
  onClose?(): void
}

export default function DialogAddPracticeFacility({
  networkID,
  onClose,
}: props): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState<Array<any>>([])
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  useEffect(() => {
    setSelected([])
  }, [isOpen])

  function doClose() {
    setIsOpen(false)
    onClose && onClose()
  }

  function doAdd() {
    putAddNetworkEntities(networkID, {
      PracticeFacilityIDs: selected.map((e: any) => e.ID),
    })
      .then((_: any) => {
        showSnackbar('Added to network OK!', SnackbarTypeSuccess)
      })
      .catch(
        catchAPIError({ defaultMessage: 'Failed adding record to network' })
      )
      .finally(doClose)
  }

  function isDisabled() {
    return (selected || []).length === 0
  }

  if (!networkID) return null
  return (
    <>
      <Tooltip title="(EngineeringOnly): launch bulk-add workflow">
        <Button variant="outlined" onClick={setIsOpen.bind(null, true)}>
          Add Practice Facility(s)
        </Button>
      </Tooltip>

      <Dialog open={isOpen} maxWidth="lg">
        <StyledDialogContent>
          <PracticeFacilityTable.Table
            onRowClick={() => {}}
            passFilters={{ ActiveStatus: true }}
            customColumns={customColsPFTable}
            onCheckHandler={setSelected}
            LeftHeaderItems={
              <>
                <h3>Add Practice Facility To Network</h3>
              </>
            }
            RightHeaderItems={
              <>
                <PracticeFacilityTable.StandardFilterSearch autoFocus />
                &nbsp;&nbsp;
                <PracticeFacilityTable.FilterOrganization />
              </>
            }
            DataTableProps={{
              LeftFooterItems: (
                <>
                  <small>
                    <strong>
                      Inactive practice facilities are not displayed.
                    </strong>
                  </small>
                </>
              ),
            }}
          />
        </StyledDialogContent>
        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button color="secondary" variant="outlined" onClick={doClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                disabled={isDisabled()}
                color="primary"
                variant="contained"
                onClick={doAdd}>
                Proceed
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}
