import React from 'react'

type AnyObject = { [key: string]: any }
type Validator = {
  [key: string]: { isValid: (val: any, data: any) => boolean; msg: string }
}

export function useForm<T extends AnyObject>(
  initialData: T,
  validator?: Validator
) {
  const [data, setData] = React.useState<T>(initialData)

  const setField = (field: string) => (val: any) => {
    setData({ ...data, [field]: val })
  }

  const fields = Object.keys(initialData)

  let setters: AnyObject = {}
  let errors: AnyObject = {}

  fields.forEach((field) => {
    const val = initialData[field]
    const valType = typeof val

    // does not support nested objects
    if (!!val && !Array.isArray(val) && valType === 'object') {
      console.debug(`useForm hook does not support value for field: ${field}`)
      return
    }

    setters[field] = (val: any) => {
      setData({ ...data, [field]: val })
    }
  })

  fields.forEach((field) => {
    if (!validator || !validator[field]) return
    const val = data[field]
    const validation = validator[field]
    const err = validation.isValid(val, data) ? null : validation.msg
    errors[field] = err
  })

  const reset = () => setData(initialData)

  const isValid = () => {
    if (!validator) return true
    const validity = Object.keys(validator).map((key) =>
      validator[key].isValid(data[key], data)
    )
    return validity.includes(false) ? false : true
  }

  return {
    data,
    setData,
    setField,
    setters,
    errors,
    reset,
    isValid,
  }
}
