import React, { useImperativeHandle } from 'react'
import * as FeeScheduleTable from '../../FeeSchedules/FeeScheduleTable2'
import { Button, Dialog, Typography } from '@material-ui/core'
import styled from 'styled-components'

const StyledDialog = styled(Dialog)`
  .std-table {
    .header-items {
      margin-top: 0;
      margin: 0;
      width: auto;
      padding: 0.5rem 0.5rem 0;
      position: sticky;
      top: 0;
      z-index: 99;
      background: #fff;
      align-items: center;
    }
    .base-table-display {
      margin: 0;
    }
  }
`

interface props {
  onPickedFeeSchedule: Function
  Trigger?: React.FC<any> | null
  TableProps?: any
}

export default React.forwardRef(function DialogFeeScheduleSearch(
  { onPickedFeeSchedule, TableProps = {}, Trigger }: props,
  ref: any
): React.ReactElement | null {
  const [isOpen, setIsOpen] = React.useState(false)
  const {
    DataTableProps: passDataTableProps,
    passFilters: passPassFilters,
    ...spreadTableProps
  } = TableProps

  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
    }),
    [setIsOpen]
  )

  function doClose() {
    setIsOpen(false)
  }

  function onSelectFeeSchedule(fs: any) {
    onPickedFeeSchedule?.(fs)
    doClose()
  }

  let btnTrigger = null
  if (Trigger) {
    btnTrigger = (
      <Trigger
        onClick={(ev: any) => {
          ev?.stopPropagation()
          setIsOpen(true)
        }}
      />
    )
  }

  return (
    <>
      {btnTrigger}

      <StyledDialog open={isOpen} onClose={doClose} maxWidth="xl">
        <FeeScheduleTable.Table
          {...spreadTableProps}
          DataTableProps={{ density: 'small', ...passDataTableProps }}
          passFilters={passPassFilters}
          onRowClick={onSelectFeeSchedule}
          RightHeaderItems={
            <>
              <Typography variant="h6">Fee Schedule Search</Typography>
              &nbsp;&nbsp;
              <Button onClick={doClose} variant="outlined" size="small">
                Close
              </Button>
            </>
          }
          LeftHeaderItems={
            <>
              <FeeScheduleTable.StandardFilterSearch />
              &nbsp;&nbsp;
              <FeeScheduleTable.FilterOrganization />
            </>
          }
        />
      </StyledDialog>
    </>
  )
})
