import React, { useState, useEffect } from 'react'
import { getZClaimLimitsInfo } from '../../../../actions/ZClaimActions'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'
import DesignSuite2023 from '../../../../components/DesignSuite2023'

type apiRecord = {
  ConfiguredLimitInfo: {
    EmployerPlanID: number
    EmployerPlanLimitID: number
    QuantityAllowed: number
    Categories: string
    IsCompound: boolean
  }
  HitsByThisClaim: number
  Existing: number
}

interface props {
  zClaimID: number
}

export default function LimitsInfo({
  zClaimID,
}: props): React.ReactElement | null {
  const [limitsInfo, setLimitsInfo] = useState<apiRecord[]>([])
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    // Fetch limits data
    getZClaimLimitsInfo(zClaimID)
      .then((res: any) => {
        if (res.error) throw res
        if (!res.Data?.length) return
        setLimitsInfo(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading zclaim limit info',
        })
      )
  }, [])

  if (!limitsInfo?.length) return null

  return (
    <DesignSuite2023.AlertInfo style={{ marginTop: '1rem' }}>
      <div>
        <strong>
          This claim has one or more attached bundles that are limited by the
          Employer's plan design:
        </strong>
      </div>
      <ul style={{ margin: 0, padding: '0 0 0 1rem' }}>
        {limitsInfo.map((limit, idx) => (
          <li style={{ padding: '0.1rem 0' }}>
            Limit on <code>{limit.ConfiguredLimitInfo.Categories}</code>: member
            has had&nbsp;
            <span style={{ textDecoration: 'underline' }}>
              {limit.Existing}
            </span>{' '}
            of&nbsp;
            <strong>{limit.ConfiguredLimitInfo.QuantityAllowed}</strong>
            &nbsp;allowed encounters, and this claim will add{' '}
            <strong>{limit.HitsByThisClaim}</strong> to the total.
            {limit.HitsByThisClaim + limit.Existing >
              limit.ConfiguredLimitInfo.QuantityAllowed && (
              <>
                &nbsp;
                <span
                  style={{
                    fontWeight: 'bold',
                    color: 'red',
                    textDecoration: 'underline',
                  }}>
                  THIS CLAIM WILL EXCEED THE ALLOWED LIMIT FOR THE PLAN YEAR!
                </span>
              </>
            )}
          </li>
        ))}
      </ul>
    </DesignSuite2023.AlertInfo>
  )
}
