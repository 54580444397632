import * as api from '../services/thezerocard/api-helper'

interface StopLossPayload {
  filter: {
    q: string
    EmployerIDs?: Array<number>
    IncludeInactive?: boolean
  }
  sort?: Array<string>
  range?: Array<number>
}

export const getStopLossRecords = (payload: StopLossPayload) => {
  return api.search(`/stoploss`, payload).then((res: any) => res)
}
export const deleteStopLossContracts = (ids: number[]) => {
  return api.put(`/stoploss_bulk_delete`, { ids })
}
