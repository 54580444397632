import * as api from '../services/thezerocard/api-helper'
import { SchmipitSubmission } from '../views/Schmipit/types'

export const postSchmipit = (body: SchmipitSubmission) => {
  return (
    api
      .post(`/snipit`, body)
      //@ts-ignore
      .then((res) => res.Data)
      .catch((err: any) => {
        if (err.Error?.Message) throw err.Error.Message
        throw 'Failed to generate new schmipit, please contact Engineering'
      })
  )
}

export const fetchSchmipit = (id: string) => {
  return (
    api
      .get(`/snipit/${id}`)
      //@ts-ignore
      .then((res) => res.Data)
      .catch((err: any) => {
        if (err.Error?.Message) throw err.Error.Message
        throw 'Failed to fetch schmipit, please contact Engineering'
      })
  )
}
