import dateTime from '../utils/dateTime'

const STATUS_DRAFT = 0
const STATUS_PRODUCTION = 1

const FeeSchedule = {
  editFields: {
    ID: 0,
    Name: '',
    Notes: '',
    DateStart: '',
    DateEnd: '',
    Status: STATUS_DRAFT,
    StatusModifiedByUserID: null,
  },
  validators: {
    Name: {
      isValid: (val) => {
        return val && val.length > 1
      },
      msg: 'Name is required',
    },
    DateStart: {
      isValid: (val) => dateTime.parse(val).isValid(),
      msg: 'Start Date is required',
    },
  },
}

export default FeeSchedule

export { STATUS_DRAFT, STATUS_PRODUCTION }
