import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { UserManagerDialog } from '../Users/UserManager'
import { OrgManagerDialog } from '../Organizations/OrgManager'
import { PfManagerDialog } from '../PracticeFacilities/PfManager'
import { BundlerManagerDialog } from '../Bundlers/BundlerManager'
import { EmployerManagerDialog } from '../Employers/EmployerManager'

import utils from '../../utils'
import { GroupActions, SnackbarActions, clearSaveResult } from '../../actions'

const {
  findGroupOrganizations,
  findGroupUsers,
  findGroupFacilities,
  findGroupEmployers,
  findGroupBundlers,
  saveGroupUsers,
  saveGroupEntities,
} = GroupActions

const { setSnackbarMessage } = SnackbarActions

/*
 * Why? This component acts as a combined container for all the management dialogs that are present on the GroupDetail.js view. It abstracts some of the management logic away from the GroupDetail page and serves as a clearer example on how to add more managers to new detail pages.
 */
class groupRelationsManager extends React.Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { usersOpen, orgsOpen, facilitiesOpen, bundlersOpen, employersOpen } =
      this.props
    const { groupId, saveResult } = nextProps

    if (nextProps.usersOpen && usersOpen === false) {
      this.props.findGroupUsers({ filter: { accessGroupIDs: [groupId] } })
    }
    if (nextProps.orgsOpen && orgsOpen === false) {
      this.props.findGroupOrganizations({
        filter: { accessGroupIDs: [groupId] },
      })
    }
    if (nextProps.facilitiesOpen && facilitiesOpen === false) {
      this.props.findGroupFacilities({ filter: { accessGroupIDs: [groupId] } })
    }
    if (nextProps.employersOpen && employersOpen === false) {
      this.props.findGroupEmployers({ filter: { accessGroupIDs: [groupId] } })
    }
    if (nextProps.bundlersOpen && bundlersOpen === false) {
      this.props.findGroupBundlers({ groupId })
      // TODO: when bundlers are hooked up, use this
      // this.props.findGroupBundlers({ filter: { accessGroupIDs: [groupId] } })
    }

    if (saveResult) {
      if (saveResult.saved) {
        this.props.setSnackbarMessage('Saved!', 'success')
        this.props.clearSaveResult()
      } else if (saveResult.error) {
        const message = `An error occurred while saving. Request ID: ${saveResult.requestId}`
        this.props.setSnackbarMessage(message, 'error')
        this.props.clearSaveResult()
      }
    }
  }

  // users are different than other entities
  onConfirmUsers = ({ added, removed }) => {
    this.props.saveGroupUsers({ groupId: this.props.groupId, added, removed })
    this.props.onConfirm()
  }

  //   OrganizationIDs        []db_types.NullUint
  //   PracticeFacilityIDs    []db_types.NullUint
  //   BundlerIDs             []db_types.NullUint
  // entities are anything attached to a group that you can provide access to
  onConfirmEntities = (key) => {
    return ({ added, removed }) => {
      this.props.saveGroupEntities({
        groupId: this.props.groupId,
        added: {
          [key]: utils.getIds(added),
        },
        removed: {
          [key]: utils.getIds(removed),
        },
      })
      this.props.onConfirm()
    }
  }

  render() {
    const {
      onCancel,
      onUpdate,
      usersOpen,
      orgsOpen,
      facilitiesOpen,
      employersOpen,
      bundlersOpen,
      users,
      orgs,
      facilities,
      employers,
      bundlers,
      resourceName,
    } = this.props
    return (
      <div>
        <UserManagerDialog
          open={usersOpen}
          currentUsers={users}
          onConfirm={this.onConfirmUsers}
          onCancel={onCancel}
          onUpdate={onUpdate}
          resourceName={resourceName}
        />
        <OrgManagerDialog
          open={orgsOpen}
          currentOrgs={orgs}
          onConfirm={this.onConfirmEntities('organizationIDs')}
          onCancel={onCancel}
          onUpdate={onUpdate}
        />
        <PfManagerDialog
          open={facilitiesOpen}
          currentFacilities={facilities}
          onConfirm={this.onConfirmEntities('practiceFacilityIDs')}
          onCancel={onCancel}
          onUpdate={onUpdate}
        />
        <EmployerManagerDialog
          open={employersOpen}
          currentEmps={employers}
          onConfirm={this.onConfirmEntities('employerIDs')}
          onCancel={onCancel}
          onUpdate={onUpdate}
        />
        <BundlerManagerDialog
          open={bundlersOpen}
          currentBundlers={bundlers}
          onConfirm={this.onConfirmEntities('bundlerIDs')}
          onCancel={onCancel}
          onUpdate={onUpdate}
        />
      </div>
    )
  }
}

groupRelationsManager.propTypes = {
  groupId: PropTypes.number.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  usersOpen: PropTypes.bool.isRequired,
  orgsOpens: PropTypes.bool.isRequired,
  facilitiesOpen: PropTypes.bool.isRequired,
  bundlersOpen: PropTypes.bool.isRequired,
  employersOpen: PropTypes.bool.isRequired,
  resourceName: PropTypes.string,
}

groupRelationsManager.defaultProps = {
  onConfirm: () => {
    console.warn(
      'You must set an onConfirm property on the GroupRelationsManager'
    )
  },
  onCancel: () => {
    console.warn(
      'You must set an onCancel property on the GroupRelationsManager'
    )
  },
  onUpdate: () => {
    console.warn(
      'You must set an onUpdate property on the GroupRelationsManager'
    )
  },
  usersOpen: false,
  orgsOpens: false,
  facilitiesOpen: false,
  bundlersOpen: false,
  employersOpen: false,
  resourceName: '',
}

function mapStateToProps(state) {
  return {
    group: state.groups.group || {},
    users: state.groups.users || [],
    orgs: state.groups.organizations || [],
    facilities: state.groups.facilities || [],
    bundlers: state.groups.bundlers || [],
    employers: state.groups.employers || [],
    saveResult: state.groups.saveResult,
    getError: state.groups.getError,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      findGroupOrganizations,
      findGroupUsers,
      findGroupFacilities,
      findGroupEmployers,
      findGroupBundlers,
      saveGroupUsers,
      saveGroupEntities,
      setSnackbarMessage,
      clearSaveResult,
    },
    dispatch
  )
}

export const GroupRelationsManager = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(groupRelationsManager)
