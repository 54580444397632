import React, { useState, useRef, useEffect } from 'react'
import { Button, Grid } from '@material-ui/core'
import {
  Table,
  FileTableFilter,
  FileTableSearcher,
} from '../../Files/FilesTable'
import { FileTypeEntities } from '../../Files/FileTypeFilter'
import FileUploadModal from '../../Files/FileUploadModal'
import FileEditModal from '../../Files/FileEditModal'
import { deleteFiles } from '../../../actions/FileActions'
import { File } from '../../Files/types'
import useQueryParamsGen2 from '../../../hooks/useQueryParamsGen2'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import styled from 'styled-components'

const DeleteButton = styled(Button)`
  margin-right: 10px;
`

interface props {
  EmployerMemberID: number
}

export default function DisplayMemberFiles({
  EmployerMemberID,
}: props): React.ReactElement | null {
  const MEMBER_FILE_DATA = { EmployerMemberID }
  const [MEMBER_ENTITY_TYPE] = useState([FileTypeEntities.EmployerMember])
  const [selectedFiles, setSelectedFiles] = useState<Array<number>>([])
  const defaultFilters = { EmployerMemberID }
  const { catchAPIError } = useErrorHandlers()
  const refFileTable = useRef()
  // @todo: this was implemented wrong (doesn't cause problems; just doesn't work)
  const { queryData, setQueryData } = useQueryParamsGen2()
  const [editID, setEditID] = useState<number | null>(queryData.editID || null)

  useEffect(() => {
    let newQueryData = Object.assign({}, queryData)
    if (!editID) delete newQueryData.editID
    if (editID && !queryData.editID) newQueryData = { ...queryData, editID }
    setQueryData(newQueryData)
  }, [editID])

  function deleteSelectedFile() {
    deleteFiles(selectedFiles)
      .then(() => {
        //@ts-ignore
        refFileTable.current?.refresh()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to delete file; please contact Engineering',
        })
      )
  }

  function handleChecked(selected: Array<File>) {
    if (selected.length === 0) setSelectedFiles([])
    setSelectedFiles(selected.map((file) => file.ID))
  }

  function refreshTableList() {
    //@ts-ignore
    refFileTable.current?.refresh()
  }

  if (!EmployerMemberID) return null

  return (
    <Table
      ref={refFileTable}
      passFilters={defaultFilters}
      onCheckHandler={handleChecked}
      entityType={MEMBER_ENTITY_TYPE}
      onRowClick={(row: File) => {
        setEditID(row.ID)
      }}
      LeftHeaderItems={
        <>
          <FileTableSearcher />
          &nbsp;
          <FileTableFilter entityType={MEMBER_ENTITY_TYPE} />
        </>
      }
      RightHeaderItems={
        <Grid>
          {!!selectedFiles?.length && (
            <DeleteButton
              variant="contained"
              color="secondary"
              onClick={deleteSelectedFile}>
              Delete file
            </DeleteButton>
          )}
          <FileUploadModal
            FileUploadProps={{
              onSuccess: refreshTableList,
              defaultFileData: MEMBER_FILE_DATA,
            }}
            entityType={MEMBER_ENTITY_TYPE}
          />
          <FileEditModal
            recordID={editID}
            handleClose={() => setEditID(null)}
            onSuccess={refreshTableList}
            entityType={MEMBER_ENTITY_TYPE}
          />
        </Grid>
      }
    />
  )
}
