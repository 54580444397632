import React, { useState } from 'react'
import utils from '../../utils'
import { LoadingButton } from '../DesignSuite2023'
import { getPresignedUrlForDownload } from '../../actions/FileActions'
import styled from 'styled-components'

const DownloadButton = styled(LoadingButton)`
  overflow: 'hidden'
  text-overflow: 'ellipsis'
  text-align: 'left'
  overflow-wrap: 'anywhere'
  white-space: 'normal'
`

const VIEW_PHI_PERMISSION = 'r:phi'
const VIEW_LOA_PERMISSION = 'r:loa'

interface Props {
  filename: string
  variant?: 'text' | 'outlined' | 'contained'
  handleGetDownloadURLFail: () => void
  fileId: number
  phi?: boolean
  loa?: boolean
}

const FileLinkBtn: React.FC<Props> = ({
  filename,
  variant = 'text',
  handleGetDownloadURLFail,
  fileId,
  phi,
  loa,
}) => {
  const checkDownloadPermission = (): boolean => {
    if (phi) {
      return utils.checkPerms(VIEW_PHI_PERMISSION)
    }
    if (loa) {
      return utils.checkPerms(VIEW_LOA_PERMISSION)
    }
    return true
  }

  const download = (url: string, name: string): void => {
    // create an anchor element and "click" it
    // note that this is subject to normal browser limitations on user interactions
    // ie it must be triggered by a user action within an acceptable timeframe
    const downloader = document.createElement('a')

    downloader.href = url
    downloader.download = name
    document.body.appendChild(downloader)
    downloader.click()
    document.body.removeChild(downloader)
  }

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.stopPropagation()

    getPresignedUrlForDownload(fileId)
      .then((data: any) => {
        if (data.url) {
          // instead of downloading the file directly, we fetch it and create a blob in order to use the original filename.
          // some filenames may contain PHI, so we cannot use content disposition, as it whould expose the PHI in url query string to AWS.
          setDownloading(true)
          return fetch(new Request(data.url))
            .then((res: any) => {
              if (res.error) {
                throw res
              }
              return res.blob()
            })
            .then((blob) => {
              const url = URL.createObjectURL(blob)
              download(url, data.filename)
              setDownloading(false)
              return url
            })
            .catch((err) => {
              console.warn('DownloadFileError: ', err)
              alert(
                'There was an issue downloading the file. Please try again or contact engineering.'
              )
              setDownloading(false)
              throw err
            })
        }
        handleGetDownloadURLFail()
        setDownloading(false)
      })
      .catch((e: any) => {
        setDownloading(false)
        console.error(e)
      })
  }

  const [canView] = useState(checkDownloadPermission())
  const [downloading, setDownloading] = useState(false)
  const clickHandler = canView ? handleClick : () => {}

  return (
    <DownloadButton
      fullWidth={false}
      disabled={!canView}
      title={filename}
      size="medium"
      color="primary"
      variant={variant}
      onClick={clickHandler}
      loading={downloading}>
      {filename}
    </DownloadButton>
  )
}

export default FileLinkBtn
