import React, { useEffect } from 'react'
import { BillingPlan } from '../../models/BillingPlan'
import { FormTextField } from '../../components/Inputs'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import { useBillingPlanForm } from './hooks'
import { BillingPlanActions } from '../../actions'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Button,
} from '@material-ui/core'
import styled from 'styled-components'
const { getBillingPlan } = BillingPlanActions

interface BillingPlanDialogProps {
  open: boolean
  feeSchedulePriceID: number
  billingPlanID?: number
  onCancel(): void
  onUpdate(): void
  defaultCode?: string
}

export const BillingPlanDialog = ({
  feeSchedulePriceID,
  billingPlanID,
  open,
  onCancel,
  onUpdate,
  defaultCode,
}: BillingPlanDialogProps) => {
  const { form, save, errors } = useBillingPlanForm({
    billingPlanID,
    feeSchedulePriceID,
  })
  const { show: showSnackbar } = useSnackbar()

  useEffect(() => {
    if (!defaultCode || billingPlanID) return
    form.setData({ ...form.data, Name: defaultCode })
  }, [defaultCode])

  useEffect(() => {
    if (!billingPlanID) return
    getBillingPlan({ billingPlanID })
      .then((data: BillingPlan) => {
        form.setData(data)
      })
      .catch((msg: string) => {
        showSnackbar(msg, SnackbarTypeError)
      })
  }, [billingPlanID, feeSchedulePriceID])

  const handleClose = () => {
    if (defaultCode) form.setData({ Name: defaultCode, Notes: '' })
    if (!defaultCode) form.reset()
    onCancel()
  }

  const handleSave = async () => {
    await save().then((success) => {
      if (success) {
        form.reset()
        onUpdate()
        handleClose()
      }
    })
  }

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>Add/Edit Billing Plan</DialogTitle>
      <StyledDialogContent>
        <DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormTextField
                form={form}
                errors={errors}
                fieldName="Name"
                label="Name"
                defaultValue={defaultCode}
              />
              <FormTextField
                form={form}
                errors={errors}
                fieldName="Notes"
                label="Notes"
                placeholder="Notes"
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </StyledDialogContent>
      <DialogActions>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs="auto">
            <Button color="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button color="primary" onClick={handleSave}>
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

const StyledDialogContent = styled(DialogContent)`
  min-width: 500px;
}`
