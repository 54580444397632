import React from 'react'
import { Link } from 'react-router-dom'
import utils from '../utils'
const READ_GROUP_PERMISSION = 'r:user'

const Group = {
  editFields: {
    Name: '',
    Notes: '',
  },
  columns: {
    ID: { name: 'ID', details: {} },
    Name: { name: 'Name', details: {} },
    Notes: { name: 'Notes', details: {} },
    Members: {
      name: '# of Members',
      details: { dataFormat: numMembersFormatter },
    },
    Organizations: {
      name: 'Organizations',
      details: { dataFormat: organizationsFormatter },
    },
    PracticeFacilities: {
      name: 'Facilities',
      details: { dataFormat: facilitiesFormatter },
    },
    Employers: {
      name: 'Employers',
      details: { dataFormat: employersFormatter },
    },
    Bundlers: { name: 'Bundlers', details: { dataFormat: bundlersFormatter } },
    AllOrganizations: { name: 'AllOrganizations', details: {} },
    AllEmployers: { name: 'AllEmployers', details: {} },
  },
  hasAccess: () => {
    return utils.checkPerms(READ_GROUP_PERMISSION)
  },
}

function numMembersFormatter(cell, row) {
  return row.NumberOfUsers
}

function organizationsFormatter(cell, row) {
  if (!row.Organizations) return null
  return (
    <div>
      {row.Organizations.map((org, i) => {
        const lnk = (
          <Link to={`/organization/${org.ID}`} target="_blank" name="view-link">
            {org.Name}
          </Link>
        )
        return (
          <div key={org.ID}>
            {lnk}
            {i < row.Organizations.length - 1 ? ', ' : ''}
          </div>
        )
      })}
    </div>
  )
}

function facilitiesFormatter(cell, row) {
  if (!row.PracticeFacilities) return null
  return (
    <div>
      {row.PracticeFacilities.map((pf, i) => {
        const lnk = (
          <Link
            to={`/organization/${pf.OrganizationID}/practice_facility/${pf.ID}`}
            target="_blank"
            name="view-link">
            {pf.Name}
          </Link>
        )
        return (
          <div key={pf.ID}>
            {lnk}
            {i < row.PracticeFacilities.length - 1 ? ', ' : ''}
          </div>
        )
      })}
    </div>
  )
}

function bundlersFormatter(cell, row) {
  if (!row.Bundlers) return null
  return (
    <div>
      {row.Bundlers.map((bund) => {
        return <span key={bund.ID}>{bund.Name}</span>
      })}
    </div>
  )
}

function employersFormatter(cell, row) {
  if (!row.Employers) return null
  return (
    <div>
      {row.Employers.map((emp, i) => {
        const lnk = (
          <Link to={`/employer/${emp.ID}`} target="_blank" name="view-link">
            {emp.Name}
          </Link>
        )
        return (
          <div key={emp.ID}>
            {lnk}
            {i < row.Employers.length - 1 ? ', ' : ''}
          </div>
        )
      })}
    </div>
  )
}

export default Group
