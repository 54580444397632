import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Grid,
  Typography,
  Tooltip,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { InfoOutlined } from '@material-ui/icons'
import { PracticeFacilityActions } from '../../actions'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import styled from 'styled-components'

const {
  getAllPracticeFacilitySpecializationsV2,
  updatePracticeFacilitySpecializations,
} = PracticeFacilityActions

const SpecChipContainer = styled(Grid)`
  padding-bottom: 1rem;
`

const BoldText = styled(Typography)`
  font-weight: bold;
  display: inline;
`

const RedText = styled(Typography)`
  color: red;
`

const ManageTooltip = styled(Tooltip)`
  margin-left: 5px;
  margin-bottom: 5px;
  display: inline;
`

interface DialogProps {
  orgID: number | null
  handleCancelDialog(): void
  handleSave(): void
  open: boolean
}

interface Spec {
  Label: string
  ID: number
}

const ManageSpecializations = ({
  orgID,
  handleCancelDialog,
  handleSave,
  open,
}: DialogProps) => {
  const [totalSpecs, setTotalSpecs] = useState<Array<Spec>>([])
  const [selectedSpecs, setSelectedSpecs] = useState<Array<Spec>>([])
  const { catchAPIError } = useErrorHandlers()
  const snackbar = useSnackbar()

  useEffect(() => {
    getAllPracticeFacilitySpecializationsV2()
      .then((res: Array<Spec>) => setTotalSpecs(res))
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to fetch Specializations; please contact Engineering',
        })
      )
  }, [])
  if (orgID === null) return <></>

  const onSave = () => {
    if (!selectedSpecs.length) return
    updatePracticeFacilitySpecializations(orgID, {
      specializationIDs: selectedSpecs.map((v) => v.ID),
    })
      .then(() => {
        snackbar.show(
          'Specializations successfully updated across all Practice Facilities',
          SnackbarTypeSuccess
        )
        handleSave()
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to save Specializations; please contact Engineering',
        })
      )
  }

  const handleSelected = (e: any, value: Array<Spec>) => setSelectedSpecs(value)
  const toolText =
    'This will destroy any previously set specializations for individual facilities, and assign the new ones selected below. Facilities added later will not automatically receieve the specializations, but must be setup on an individual basis, or by using this tool.'

  return (
    <Dialog open={open} onClose={handleCancelDialog}>
      <DialogTitle>Manage Specializations</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This allows you to select specializations and{' '}
          <BoldText>
            bulk-assign them to every facility in this organization
          </BoldText>
          .
          <ManageTooltip title={toolText}>
            <InfoOutlined />
          </ManageTooltip>
        </DialogContentText>
        <SpecChipContainer>
          <Autocomplete
            multiple
            options={totalSpecs}
            getOptionLabel={(option) => option.Label}
            value={selectedSpecs}
            onChange={handleSelected}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Specializations"
                placeholder="Select one or more specializations"
              />
            )}
          />
        </SpecChipContainer>
        {!selectedSpecs.length && (
          <RedText>At least one specialization must be selected</RedText>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!selectedSpecs.length}
          onClick={onSave}
          color="primary"
          variant="outlined">
          Set on All Facilities
        </Button>
        <Button onClick={handleCancelDialog} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ManageSpecializations
