import React, { useState, useEffect } from 'react'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { FeeScheduleActions } from '../../actions'
import { STATUS_DRAFT, STATUS_PRODUCTION } from '../../models/FeeSchedule'
const { deletePracticeFacilitiesFromSchedule } = FeeScheduleActions

interface props {
  feeSchedule: any
  practiceFacilities: any[]
  onChange?(): void
}

export default function DailogRemovePracticeFacilities({
  feeSchedule = {},
  practiceFacilities = [],
  onChange,
}: props): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [removablePFs, setRemovablePFs] = useState<any[]>([])
  const [alreadyTermd, setAlreadyTermd] = useState<any[]>([])
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    setRemovablePFs(
      practiceFacilities.filter(
        (fs: any) => !!fs.FeeScheduleRemovedOn === false
      )
    )
    setAlreadyTermd(
      practiceFacilities.filter((fs: any) => !!fs.FeeScheduleRemovedOn === true)
    )
  }, [practiceFacilities])

  function doRemove(): void {
    deletePracticeFacilitiesFromSchedule(
      feeSchedule.ID,
      removablePFs.map((pf: any) => pf.ID)
    )
      .then(() => {
        onChange && onChange()
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed removing facility from selected fee schedules; please contact Engineering',
        })
      )
      .finally(() => {
        setIsOpen(false)
      })
  }

  if (!feeSchedule.ID) {
    return null
  }

  return (
    <>
      <Tooltip title="Opens workflow dialog for removing selected facilities from the current fee schedule">
        <Button
          disabled={!removablePFs.length}
          variant="contained"
          color="secondary"
          onClick={setIsOpen.bind(null, true)}>
          Remove Facilities
        </Button>
      </Tooltip>

      <Dialog open={isOpen}>
        <DialogTitle>Remove Facilities From Fee Schedule</DialogTitle>
        <DialogContent>
          <p>
            <strong>How This Works:</strong> this manages the{' '}
            <i>relationship</i> between the fee schedule and one or more
            practice facilities. You are not managing the practice facilities
            themselves - you're updating the association data between this fee
            schedule and one or more attached facilities.
          </p>
          <p style={{ marginBottom: '1rem' }}>
            {feeSchedule.Status === STATUS_DRAFT && (
              <>
                Since this fee schedule is in <strong>DRAFT</strong> mode, the
                selected facilities will be removed entirely. You can always
                re-add them back later before moving to production.
              </>
            )}
            {feeSchedule.Status === STATUS_PRODUCTION && (
              <>
                Since this fee schedule is in <strong>PRODUCTION</strong> mode,
                the selected facilities will be removed from the fee schedule,
                with today's date logged as "removed on". You will not be able
                to re-add these faciltiies to this fee schedule once this is
                done.
              </>
            )}
          </p>

          {!!removablePFs.length && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  <strong>Facilities ({removablePFs.length})</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ListFacilities items={removablePFs} />
              </AccordionDetails>
            </Accordion>
          )}

          {!!alreadyTermd.length && (
            <small style={{ display: 'block', paddingTop: '0.5rem' }}>
              You selected ({alreadyTermd.length}) facilities that already had
              removal dates set; no action will occur with these - only the ones
              listed above.
            </small>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button
                color="secondary"
                variant="outlined"
                onClick={setIsOpen.bind(null, false)}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                disabled={!removablePFs.length}
                color="primary"
                variant="contained"
                onClick={doRemove}>
                Proceed
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

function ListFacilities({ items = [] }: { items: any[] }): React.ReactElement {
  const results = items.map((pf: any) => {
    return (
      <TableRow>
        <TableCell>{pf.ID}</TableCell>
        <TableCell>{pf.Name}</TableCell>
      </TableRow>
    )
  })
  if (!results.length) {
    return <span>No facilities</span>
  }
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell style={{ width: '99%' }}>Name</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{results}</TableBody>
    </Table>
  )
}
