import React, { useState, useEffect, useRef } from 'react'
import { IconButton, Typography } from '@material-ui/core'
import * as TableSrcRecords from '../srcRecords/tableSrcRecords'
import useQueryParamsGen2 from '../../../../hooks/useQueryParamsGen2'
import DesignSuite2023 from '../../../../components/DesignSuite2023'

export default function WorkflowReconcileSourceData(): React.ReactElement {
  const { queryData, setQueryData } = useQueryParamsGen2({
    scope: 'zclaims.workflows.srcDataTabs',
  })
  const [tab, setTab] = useState<string>(queryData['tab'] || 'pts')
  const refTable = useRef<any>(null)

  useEffect(() => {
    setQueryData({ tab })
  }, [tab])

  return (
    <div>
      <DesignSuite2023.AlertWarning style={{ marginBottom: '0.5rem' }}>
        <span>
          This page deals with{' '}
          <strong>only source records that can be assigned a defaulter</strong>.
          Not all claims are received with enough information to reliably choose
          a defaulter for a given component of the claim (patient, subscriber,
          billing entity, service facility).
        </span>
      </DesignSuite2023.AlertWarning>

      <div className="sublayout">
        <div className="left">
          <DesignSuite2023.StyledTabs
            value={tab}
            orientation="vertical"
            indicatorColor="secondary"
            textColor="inherit"
            style={{ margin: 0, borderRadius: '8px' }}>
            <DesignSuite2023.StyledTab
              value={'pts'}
              label="Patients"
              onClick={() => setTab('pts')}
            />
            <DesignSuite2023.StyledTab
              value={'sub'}
              label="Subscribers"
              onClick={() => setTab('sub')}
            />
            <DesignSuite2023.StyledTab
              value={'bil'}
              label="Billing Entities (+Org)"
              onClick={() => setTab('bil')}
            />
            <DesignSuite2023.StyledTab
              value={'fac'}
              label="Service Facilities"
              onClick={() => setTab('fac')}
            />
          </DesignSuite2023.StyledTabs>
        </div>

        <div className="right">
          {tab === 'pts' && (
            <TableSrcRecords.Table
              ref={refTable}
              // noWrapWhitespace
              kind="src_patient"
              enableURLReflection="zclaims.workflows.srcData.patients"
              passFilters={{ HasNoDefaulter: true }}
              LeftHeaderItems={
                <>
                  <Typography>Patient Source Data</Typography>
                  &nbsp;
                  <IconButton
                    size="small"
                    onClick={() => refTable.current?.refresh()}>
                    <DesignSuite2023.CommonIcons.IconRefresh fontSize="inherit" />
                  </IconButton>
                  &nbsp;
                  <TableSrcRecords.StandardFilterSearch />
                </>
              }
              RightHeaderItems={
                <>
                  <TableSrcRecords.FilterWhereHasNoDefaulter />
                </>
              }
            />
          )}
          {tab === 'sub' && (
            <TableSrcRecords.Table
              ref={refTable}
              // noWrapWhitespace
              kind="src_subscriber"
              enableURLReflection="zclaims.workflows.srcData.subscribers"
              passFilters={{ HasNoDefaulter: true }}
              LeftHeaderItems={
                <>
                  <Typography>Subscriber Source Data</Typography>
                  &nbsp;
                  <IconButton
                    size="small"
                    onClick={() => refTable.current?.refresh()}>
                    <DesignSuite2023.CommonIcons.IconRefresh fontSize="inherit" />
                  </IconButton>
                  &nbsp;
                  <TableSrcRecords.StandardFilterSearch />
                </>
              }
              RightHeaderItems={
                <>
                  <TableSrcRecords.FilterWhereHasNoDefaulter />
                </>
              }
            />
          )}
          {tab === 'bil' && (
            <TableSrcRecords.Table
              ref={refTable}
              // noWrapWhitespace
              kind="src_billing_provider"
              enableURLReflection="zclaims.workflows.srcData.billing_provider"
              passFilters={{ HasNoDefaulter: true }}
              LeftHeaderItems={
                <>
                  <Typography>Billing Provider Source Data</Typography>
                  &nbsp;
                  <IconButton
                    size="small"
                    onClick={() => refTable.current?.refresh()}>
                    <DesignSuite2023.CommonIcons.IconRefresh fontSize="inherit" />
                  </IconButton>
                  &nbsp;
                  <TableSrcRecords.StandardFilterSearch />
                </>
              }
              RightHeaderItems={
                <>
                  <TableSrcRecords.FilterWhereHasNoDefaulter />
                </>
              }
            />
          )}
          {tab === 'fac' && (
            <TableSrcRecords.Table
              ref={refTable}
              // noWrapWhitespace
              kind="src_service_facility"
              enableURLReflection="zclaims.workflows.srcData.service_facility"
              passFilters={{ HasNoDefaulter: true }}
              LeftHeaderItems={
                <>
                  <Typography>Service Facility Source Data</Typography>
                  &nbsp;
                  <IconButton
                    size="small"
                    onClick={() => refTable.current?.refresh()}>
                    <DesignSuite2023.CommonIcons.IconRefresh fontSize="inherit" />
                  </IconButton>
                  &nbsp;
                  <TableSrcRecords.StandardFilterSearch />
                </>
              }
              RightHeaderItems={
                <>
                  <TableSrcRecords.FilterWhereHasNoDefaulter />
                </>
              }
            />
          )}
        </div>
      </div>
    </div>
  )
}
