import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Checkbox,
} from '@material-ui/core'
import { FileActions } from '../../actions'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import { DataTable } from '../../components/DataTable'
import Models from '../../models'
import { renderTextField, SetterArgs, renderSelectField } from '../ViewHelpers'
import styled from 'styled-components'

interface FileType {
  CreatedAt: string
  CreatedByUserID: number
  Description: string
  Hash: string
  ID: number
  IsEmployerMemberType: boolean
  IsEmployerType: boolean
  IsLoaType: boolean
  IsOrganizationType: boolean
  IsPHIType: boolean
  IsPartnerType: boolean
  IsReferrerType: boolean
  ManagedInternally: boolean
  ModifiedByUserID: number
  UpdatedAt: string
}

interface NewFileType {
  Description: string
  Hash: string
  IsEmployerMemberType: boolean
  IsEmployerType: boolean
  IsLoaType: boolean
  IsOrganizationType: boolean
  IsPHIType: boolean
  IsPartnerType: boolean
  IsReferrerType: boolean
  ManagedInternally: boolean
}

interface NewEditables {
  IsEmployerMemberType: boolean
  IsEmployerType: boolean
  IsLoaType: boolean
  IsOrganizationType: boolean
  IsPHIType: boolean
  IsPartnerType: boolean
  IsReferrerType: boolean
  ManagedInternally: boolean
}

interface FileTypeOption {
  label: string
  value: string
}

interface SelectItem {
  name: string
  value: string
}

interface ModalProps {
  open: boolean
  closeModal: () => void
}

interface AddNewModalProps {
  open: boolean
  closeModal: () => void
  setShowAddNewModal: Dispatch<SetStateAction<boolean>>
}

const TypeRow = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  border-bottom: 1px solid black;
  align-items: center;
  justify-content: space-between;
`

const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`

export const ManageFileTypesModal = ({ open, closeModal }: ModalProps) => {
  const [fileTypes, setFileTypes] = useState<Array<FileType>>(Array<FileType>())
  const [saveRow, setSaveRow] = useState<FileType>()
  const [selectedFileAttribute, setSelectedFileAttribute] = useState<string>()
  const [showAddNewModal, setShowAddNewModal] = useState<boolean>(false)
  const { getAllFileTypes, updateFileType } = FileActions
  const snackbar = useSnackbar()

  useEffect(() => {
    if (!open) return

    const params =
      selectedFileAttribute && selectedFileAttribute !== 'All'
        ? {
            sort: ['file_type.description', 'asc'],
            page: 1,
            pageSize: 100,
            filter: { [selectedFileAttribute]: true },
          }
        : { sort: ['file_type.description', 'asc'], page: 1, pageSize: 100 }

    getAllFileTypes(params)
      .then((res: Array<FileType>) => {
        setFileTypes(res)
      })
      .catch((err: any) => {
        if (err.Error && err.Error.Message)
          return snackbar.show(err.Error.Message, SnackbarTypeError)
        snackbar.show(
          'Failed to get File Types, you are engineering so go contact yourself',
          SnackbarTypeError
        )
      })
  }, [open, selectedFileAttribute, saveRow])

  const handleCloseModal = () => {
    closeModal()
  }

  const handleEdit = () => null

  const handleSaveRow = (row: FileType) => {
    return updateFileType(row)
      .then((row: FileType) => setSaveRow(row))
      .catch((err: any) => {
        if (err.Error && err.Error.Message)
          return snackbar.show(err.Error.Message, SnackbarTypeError)
        snackbar.show(
          'Failed to get File Types, you are engineering so go contact yourself',
          SnackbarTypeError
        )
      })
  }

  const setter = (select: SelectItem) => {
    setSelectedFileAttribute(select.value)
  }

  const checkRow = (row: FileType) => !row.ManagedInternally

  const editablesArray: Array<FileTypeOption> = [
    { label: 'All', value: 'All' },
    { label: 'IsEmployerMemberType', value: 'IsEmployerMemberType' },
    { label: 'IsEmployerType', value: 'IsEmployerType' },
    { label: 'IsLoaType', value: 'IsLOAType' },
    { label: 'IsOrganizationType', value: 'IsOrganizationType' },
    { label: 'IsPHIType', value: 'IsPHIType' },
    { label: 'IsPartnerType', value: 'IsPartnerType' },
    { label: 'IsReferrerType', value: 'IsReferrerType' },
  ]

  const columns = Models.FileType.columns
  const renderManageContent = () => (
    <Dialog maxWidth="lg" open={open} onClick={(e) => e.stopPropagation()}>
      <DialogTitle>FileTypes Management Modal</DialogTitle>
      <DialogContent>
        <SearchContainer>
          {renderSelectField({
            name: 'FileType',
            label: 'File Type Stakeholder',
            setter,
            items: editablesArray,
            value: selectedFileAttribute,
          })}
        </SearchContainer>
        <DataTable
          stickyHeader={true}
          columns={columns}
          keyProp="ID"
          allowEditing={true}
          isRowEditable={checkRow}
          data={fileTypes}
          count={fileTypes.length}
          pagination={false}
          onSaveRow={handleSaveRow}
          onEditRow={handleEdit}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => setShowAddNewModal(true)}>
          Add New FileType
        </Button>
        <Button color="primary" onClick={handleCloseModal}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )

  return (
    <>
      {showAddNewModal ? (
        <AddFileTypeModal
          open={open}
          closeModal={handleCloseModal}
          setShowAddNewModal={setShowAddNewModal}
        />
      ) : (
        renderManageContent()
      )}
    </>
  )
}

const AddFileTypeModal = ({
  open,
  closeModal,
  setShowAddNewModal,
}: AddNewModalProps) => {
  const defaultFileType = {
    Description: '',
    Hash: '',
    IsEmployerMemberType: false,
    IsEmployerType: false,
    IsLoaType: false,
    IsOrganizationType: false,
    IsPHIType: false,
    IsPartnerType: false,
    IsReferrerType: false,
    ManagedInternally: false,
  }
  const [fileType, setFileType] = useState<NewFileType>(defaultFileType)
  const { saveNewFileType } = FileActions
  const snackbar = useSnackbar()

  const setter = ({ name, value }: SetterArgs) => {
    const newFields = Object.assign({}, fileType, { [name]: value })
    setFileType(newFields)
  }

  const handleSubmit = () => {
    saveNewFileType(fileType)
      .then(() => {
        handleClose()
      })
      .catch((err: any) => {
        if (err.Error && err.Error.Message)
          return snackbar.show(err.Error.Message, SnackbarTypeError)
        snackbar.show(
          'Failed to make new File Type, you are engineering so go contact yourself',
          SnackbarTypeError
        )
      })
  }

  const handleClose = () => {
    setFileType(defaultFileType)
    setShowAddNewModal(false)
    closeModal()
  }

  const handleBack = () => {
    setFileType(defaultFileType)
    setShowAddNewModal(false)
  }

  const editablesArray: Array<string> = [
    'IsEmployerMemberType',
    'IsEmployerType',
    'IsLoaType',
    'IsOrganizationType',
    'IsPHIType',
    'IsPartnerType',
    'IsReferrerType',
  ]

  return (
    <Dialog
      classes={{ paper: 'dialogRoot' }}
      open={open}
      onClick={(e) => e.stopPropagation()}>
      <DialogTitle>Add New File Type Modal</DialogTitle>
      <DialogContent>
        {renderTextField({
          name: 'Description',
          label: 'Description',
          value: fileType.Description,
          setter,
        })}
        {renderTextField({
          name: 'Hash',
          label: 'Hash',
          value: fileType.Hash,
          setter,
        })}
        {editablesArray.map((field: string) => (
          <TypeRow key={field}>
            <DialogContentText>{field}</DialogContentText>
            <Checkbox
              checked={fileType[field as keyof NewEditables]}
              onChange={() =>
                setter({
                  name: field,
                  value: !fileType[field as keyof NewEditables],
                })
              }></Checkbox>
          </TypeRow>
        ))}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleBack}>
          Back to List
        </Button>
        <Button color="secondary" onClick={handleSubmit}>
          Submit
        </Button>
        <Button color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
