import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'

import { useConfig } from '../../providers/Config'
import utils from '../../utils'
import Models from '../../models'
import { DataTable, SearchFilter } from '../../components/DataTable'
import QSearcher from '../../components/QSearcher'
import { defaultStyleCreator } from '../../components/DetailView/DetailView'
import { ReferralActions } from '../../actions'
import { Chip } from '@material-ui/core'

const { findReferrals, getReferralStatuses } = ReferralActions

const styles = (theme) => {
  const defaults = defaultStyleCreator(theme)

  return Object.assign(defaults, {
    searchField: {
      display: 'inline-flex',
      marginRight: 16,
      marginTop: '16px',
    },
    flexBar: {
      display: 'inline-flex',
      alignItems: 'center',
    },
    field: {
      width: 200,
    },
  })
}

class ReferralTable extends React.Component {
  state = {
    q: '',
    page: 1,
    pageSize: 10,
    statusID: null,
  }

  handleStatusIdChange = (val) => {
    const statusID = val
    const { q, page, pageSize } = this.state
    this.setState({ statusID })
    this.findReferrals({ q, page, pageSize, statusID })
  }

  componentDidMount() {
    const { statuses } = this.props
    const { q, page, pageSize, statusID } = this.state
    this.findReferrals({ q, page, pageSize, statusID })
    if (statuses.length === 0) {
      this.props.getReferralStatuses()
    }
  }

  findReferrals = ({ q, page, pageSize, statusID }) => {
    const { employerMemberIds } = this.props
    this.setState({ loading: true })
    if (q.length === 0 || q.length > 2) {
      const filter = { q }
      if (statusID && statusID !== 0) filter.statusID = [statusID]
      if (employerMemberIds && employerMemberIds.length > 0)
        filter.employerMemberIDs = employerMemberIds
      this.props.findReferrals({ filter, page, pageSize }).then((res) => {
        this.setState({ loading: false })
      })
    }
  }

  renderStaticFilters = () => {
    const { employerMemberIds } = this.props
    return (
      <div>
        {employerMemberIds && (
          <div>
            {this.props.employerMemberIds.map((item) => {
              return (
                <Chip
                  label={`EM-ID: ${item}`}
                  key={item}
                  className={this.props.classes.chip}
                />
              )
            })}
          </div>
        )}
      </div>
    )
  }

  handleQueryChange = (q) => {
    const { page, pageSize, statusID } = this.state
    this.setState({ q })
    this.findReferrals({ q, page, pageSize, statusID })
  }

  handleChangePage = ({ page, pageSize }) => {
    const { q, statusID } = this.state
    this.setState({ page, pageSize })
    this.findReferrals({ q, page, pageSize, statusID })
  }

  render() {
    const { searchError, referrals, loading, classes, sortable } = this.props

    return (
      <div>
        <div className={classes.flexBar}>
          <QSearcher
            search={this.handleQueryChange}
            classNames={classes.searchField}
          />
          <SearchFilter
            name="StatusID"
            label="Status"
            items={this.props.statuses}
            defaultValue={0}
            onChange={this.handleStatusIdChange}
          />
          {this.renderStaticFilters()}
        </div>
        <Wrapper
          sortable={sortable}
          data={referrals}
          loading={loading}
          handleChangePage={this.handleChangePage}
          count={this.props.count}
          error={searchError}
        />
      </div>
    )
  }
}

ReferralTable.defaultProps = {
  sortable: { col: null, dir: null },
}

function mapStateToProps(state) {
  const statuses = state.referrals.statuses || []
  const statusOpts =
    statuses.length > 0
      ? utils.getSelectOptions(statuses, {
          valueKey: 'ID',
          labelKey: 'Descr',
          allowEmpty: true,
        })
      : []

  return {
    referrals: state.referrals.referrals || [],
    statuses: statusOpts,
    count: state.referrals.count || 0,
    searchError: state.referrals.searchError || null,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ findReferrals, getReferralStatuses }, dispatch)
}

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ReferralTable)

const Wrapper = ({
  sortable,
  data,
  loading,
  handleChangePage,
  count,
  error,
}) => {
  const { referralAppLink } = useConfig()
  const handleReferralClick = (e, { ID }) => {
    if (e.nativeEvent && e.nativeEvent.target.name === 'view-link') {
      return
    }
    const redirectPath = `${referralAppLink}/referral/${ID}`
    window.open(redirectPath)
  }

  return (
    <DataTable
      columns={Models.Referral.columns}
      keyProp="ID"
      sortable={sortable}
      data={data}
      loading={loading}
      onRowClick={handleReferralClick}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangePage}
      count={count}
      error={error}
    />
  )
}
