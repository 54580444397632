import React from 'react'
import ConfirmDialog from '../../../components/ConfirmDialog'
import { Button } from '@material-ui/core'
import { putZClaimAssignUncontractedProvider } from '../../../actions/ZClaimActions'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar from '../../../hooks/useSnackbar'
import DesignSuite2023 from '../../../components/DesignSuite2023'

interface props {
  srcRecID: number | null
  autoFixAttachedClaims?: boolean
  onConfirm?(): void
}

export default function BtnCreateUncontractedBillingEntity({
  srcRecID,
  autoFixAttachedClaims = false,
  onConfirm,
}: props): React.ReactElement | null {
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  function markAsUncontractedProvider() {
    ConfirmDialog({
      content:
        'This will record the information from the source record and categorize all associated claims as attached to an "uncontracted provider". This means claims will NOT be associated with an organization.',
      onConfirm() {
        putZClaimAssignUncontractedProvider({
          ZClaimObservedBillingProviderID: srcRecID,
          autoFixAttachedClaims,
        })
          .then(() => {
            showSnackbar('Uncontracted Provider assigned OK', 'success')
            onConfirm?.()
          })
          .catch(
            catchAPIError({
              defaultMessage: 'Failed assigning uncontracted provider',
            })
          )
      },
    })
  }

  if (!srcRecID) return null

  return (
    <DesignSuite2023.Tooltip
      title={`ZERO occasionaly receives claims from providers by accident (claims intended for a different payer); in other words: claims we should not receive. We still track the claim sender's info as 'Uncontracted' Billing Entities for audit and outreach purposes.`}>
      <Button
        variant="outlined"
        size="small"
        onClick={markAsUncontractedProvider}>
        Record Uncontracted Provider
      </Button>
    </DesignSuite2023.Tooltip>
  )
}
