import React from 'react'
import { IconButton } from '@material-ui/core'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'
import utils from '../../utils'

interface Props {
  value: string
  onCopySuccess?(): void
  onCopyError?(): void
}

const CopyIcon: React.FC<Props> = ({
  value,
  onCopySuccess = () => {},
  onCopyError = () => {},
}: Props) => (
  <IconButton
    name="view-link"
    onClick={(ev: any) => {
      utils.updateClipboard(value, onCopySuccess, onCopyError)
      ev.stopPropagation()
    }}>
    <InsertDriveFileIcon />
  </IconButton>
)

export default CopyIcon
