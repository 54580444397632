import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import queryString from 'query-string'
import * as api from '../../services/thezerocard/api-helper'
import Done from '@material-ui/icons/Done'
import Info from '@material-ui/icons/Info'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import NetworksAutocomplete from '../Networks/NetworksAutocomplete'
import PriceTable from '../Prices/PriceTable'
import RuleSetBuilder from './RuleSetBuilder'
import BillingTranslationBuilder from './BillingTranslation'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { createFilterOptions } from '@material-ui/lab/Autocomplete'
import utils from '../../utils'
import DetailView, {
  defaultStyleCreator,
} from '../../components/DetailView/DetailView'
import Models from '../../models'
import { CostKeyActions, clearSaveResult, SnackbarActions } from '../../actions'
import {
  CircularProgress,
  Grid,
  Button,
  Tabs,
  Tab,
  Paper,
  Divider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
  Chip,
  InputAdornment,
} from '@material-ui/core'
import { Link as IconLink } from '@material-ui/icons'
import { TagAutocomplete } from '../../components/Tags'
import DRGCodeSearcher from '../../components/Searchers/DRGCodeSearcher'
import CostKeyCategorySearcher from '../../components/Searchers/CostKeyCategorySearcher'

const {
  saveCostKeyCode,
  saveNewCostKeyCode,
  deleteCostKeyCode,
  getCostKeyCode,
  getAllCostKeyKeywords,
  getNewCostKeyCode,
  getCostKeyCategories,
  DELETE_COST_KEY_CODE,
  SAVE_NEW_COST_KEY_CODE,
} = CostKeyActions
const { setSnackbarMessage } = SnackbarActions

const styles = (theme) => {
  const defaults = defaultStyleCreator(theme)
  const classes = {
    dialog: {
      '& > div': {
        overflowY: 'visible',
      },
    },
    keywordSuggester: {
      marginTop: '-16px', // offset the margin on the textbox input when this is the first element in a column
    },
  }

  return Object.assign({}, defaults, classes)
}

const priceTableCols = Object.freeze(
  Object.assign({}, Models.CostKeyCode.prices, {
    Networks: {
      name: 'Networks',
      details: {
        dataFormat(_, row) {
          return (row.Networks || []).map((n) => (
            <Chip size="small" label={n} />
          ))
        },
      },
    },
  })
)

export class CostKeyCodeDetail extends DetailView {
  constructor(props) {
    super(props)
    const { match } = props

    this.initialState = {
      id: match.params.id,
      fields: Object.assign({}, Models.CostKeyCode.editFields),
      confirmDelete: false,
      confirmClone: false,
      cloneNewCode: '',
      confirmTermination: false,
      confirmActivation: false,
      childTabValue: 0,
      mainTabValue: 0,
      saving: false,
      loading: true,
      availableKeywords: [],
      aliasSearchResults: [],
      aliasedData: null,
    }

    this.state = this.initialState

    this.mainTabs = {
      details: 0,
      billingTranslation: 1,
      ruleSets: 2,
    }

    this.childTabs = {
      prices: 0,
    }
    this.props.getCostKeyCategories()
  }

  componentWillUnmount() {
    this.props.getNewCostKeyCode()
    this.props.clearSaveResult()
  }

  componentDidMount() {
    const { match } = this.props
    const { history } = this.props
    const { location } = history
    const qsParams = queryString.parse(location.search)

    getAllCostKeyKeywords()
      .payload.then(({ Data }) => {
        this.setState({
          // Note: this is probably confusing AF: keywords are not unique in the database; every cost key just saves
          // a list of keywords applicable to itself. When we load keywords, they come back WITH ALL IDs SET TO 0, because
          // they're deduplicated... thus there is no canonical record to point to. The Autocomplete cares about
          // IDs for keeping track of render lists though, so that's why we're using the index value from the map function
          // here to set the ID. IOW - if you see IDs assigned to keywords, THAT IS A LIE.
          availableKeywords: Data.map((v, idx) => {
            return { ID: idx, Keyword: v.Keyword }
          }),
        })
      })
      .catch((e) => {
        this.props.setSnackbarMessage(
          'Failed loading keywords; this feature may not work correctly. Please contact engineering immediately.',
          'error'
        )
      })

    this.props.clearSaveResult()

    if (match.params.id) {
      this.setState({
        fields: Object.assign({}, Models.CostKeyCode.editFields, {
          ID: match.params.id,
        }),
      })
      this.props.getCostKeyCode({ ID: match.params.id }).then((res) => {
        if (res.payload && res.payload.Data) {
          if (res.payload.Data.AliasID) {
            this.loadAliased(res.payload.Data.AliasID)
          }
        }
        this.setState({
          loading: false,
          mainTabValue: +qsParams.active_main_tab || 0,
          childTabValue: +qsParams.active_child_tab || 0,
        })
      })
    } else {
      this.setState({
        loading: false,
        fields: Object.assign({}, Models.CostKeyCode.editFields),
      })
      this.props.getNewCostKeyCode()

      // This is a new cost key, make sure the keywords are clear
      this.setState((state) => {
        return {
          fields: {
            ...state.fields,
            CostKeyKeywords: [],
          },
        }
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { costKey, saveResult } = nextProps
    const { history } = this.props
    const { location } = history
    const qsParams = queryString.parse(location.search)

    if (
      costKey.ID !== this.props.costKey.ID ||
      costKey.UpdatedAt !== this.props.costKey.UpdatedAt
    ) {
      const newCostKeyCode = Object.assign({}, this.state.fields, costKey)
      if (!newCostKeyCode.Contacts) newCostKeyCode.Contacts = []
      newCostKeyCode.ManualTypicalPPOPrice = utils.convertMoneyFromTZC(
        newCostKeyCode.ManualTypicalPPOPrice
      )

      this.setState({
        fields: newCostKeyCode,
        originalFields: newCostKeyCode,
        changedFields: {},
      }) // be mindful of autoGenFields
    }

    this.setState({
      mainTabValue: +qsParams.active_main_tab || 0,
      childTabValue: +qsParams.active_child_tab || 0,
    })

    if (saveResult) {
      if (saveResult.type === DELETE_COST_KEY_CODE) {
        this.setState({ confirmDelete: false })
        this.props.clearSaveResult()
        this.backToCostKeyCodes()
      } else if (saveResult.type === SAVE_NEW_COST_KEY_CODE) {
        this.props.clearSaveResult()
        this.props.history.push(`cost_key/${costKey.ID}`)
      } else {
        this.setState({ saving: false })
      }
    }
  }
  loadAliased = (costKeyCodeID) => {
    if (!costKeyCodeID) {
      return
    }
    api.get(`/cost_key/${costKeyCodeID}`).then(({ Data }) => {
      this.setState({
        aliasSearchResults: [Data],
        aliasedData: Data,
      })
    })
  }

  backToCostKeyCodes = (e) => {
    this.props.history.goBack()
  }

  handleDelete = () => {
    this.props.deleteCostKeyCode({
      ID: this.state.fields.ID,
    })
  }

  showConfirmDelete = () => {
    this.setState({ confirmDelete: true })
  }

  handleCancelDelete = () => {
    this.setState({ confirmDelete: false })
  }

  showConfirmClone = () => {
    this.setState({ confirmClone: true })
  }

  handleCancelClone = () => {
    this.setState({ confirmClone: false })
  }

  handleClone = () => {
    api
      .post('/cost_key_clone', {
        ExistingID: +this.state.id,
        NewCode: this.state.cloneNewCode,
      })
      .then((res) => {
        if (res.error) {
          if (res.errList && res.errList[0]) {
            return alert(res.errList[0])
          }
          return alert(res.Error.Message)
        }
        this.props.history.push(`/cost_key/${res.Data.ID}`)
        // issues w/ state management beyond what is reasonable to fix for the scope of
        // this task (SD-941: cloning a cost key). When we clone the cost key, we want to
        // go to its' page - ie. this same component - upon saving (which ...history.push()
        // above will do), but this component will NOT refresh its own props/state properly.
        // Most failsafe solution is set the URL then just reload the page, until we can address
        // this at a later date.
        window.location.reload()
      })
  }

  handleSave = (e) => {
    if (!this.isValidToSave()) {
      this.setState({ showValidations: true })
      return
    }
    this.executeSave()
  }

  isValidToSave = () => {
    return (
      this.isLaymanDescrValid() &&
      this.isDescrValid() &&
      this.areBundleContentsValid() &&
      this.isCategoryValid() &&
      this.isCostKeyValid()
    )
  }

  isCostKeyValid = () => {
    const { fields } = this.state
    return fields.Code && fields.Code !== '' && fields.Code.length >= 5
  }

  isDescrValid = () => {
    const { fields } = this.state
    return fields.Descr && fields.Descr.length >= 3
  }

  isLaymanDescrValid = () => {
    const { fields } = this.state
    return fields.LaymanDescr && fields.LaymanDescr.length <= 120
  }

  isCategoryValid = () => {
    const { fields } = this.state
    return fields.Category && fields.Category !== ''
  }

  areBundleContentsValid = () => {
    const { fields } = this.state
    return fields.BundleContents && fields.BundleContents.length >= 3 // a word should be in there
  }

  executeSave = async () => {
    this.setState({ saving: true })

    const costKeyToSave = {
      ...this.props.costKey,
      ...this.state.fields,
      ManualTypicalPPOPrice: utils.convertMoneyToTZC(
        utils.scrubAndParseFloat(this.state.fields.ManualTypicalPPOPrice)
      ),
    }

    if (costKeyToSave.ID) {
      this.props.saveCostKeyCode({ ID: costKeyToSave.ID }, costKeyToSave)
    } else {
      this.props.saveNewCostKeyCode(costKeyToSave)
    }
  }

  renderLocalStatusIcon = () => {
    let result
    const { classes } = this.props
    if (this.props.saveResult && this.props.saveResult.saved) {
      result = <Done className={classes.statusIcon} />
      this.saveResultTimer = setTimeout(() => {
        this.props.clearSaveResult()
      }, 3000)
    }

    return (
      <div className={this.props.classes.statusIconContainer}>{result}</div>
    )
  }

  renderChildTabs = () => {
    if (!this.state.fields.ID) return <div />
    return (
      <div>
        <Tabs
          value={this.state.childTabValue}
          onChange={this.handleChildTabChange}
          indicatorColor="primary"
          textColor="inherit">
          {Models.BundlePrices.hasAccess() && (
            <Tab value={this.childTabs.prices} label="Prices" />
          )}
        </Tabs>
        <div className={this.props.classes.childContainer}>
          {this.renderChildTabContent()}
        </div>
      </div>
    )
  }

  rowOptsApplier = (row) => {
    if (!row.IsValidNow) {
      return { className: 'tr-warning' }
    }

    return null
  }

  renderChildTabContent = () => {
    const { ID } = this.state.fields

    if (this.state.childTabValue === this.childTabs.prices) {
      return (
        <PriceTable
          allowEditing={false}
          columnOverride={priceTableCols}
          production
          canDeactivate
          costKeyId={ID * 1}
        />
      )
    }

    return <div />
  }

  _onCheckedChange = (checkedRows) => {
    this.setState({ checkedItems: checkedRows })
  }

  recordTabChanged = (key, value) => {
    const { history } = this.props
    if (history) {
      const existingParams = queryString.parse(history.location.search)
      const stringified = queryString.stringify({
        ...existingParams,
        [key]: value,
      })
      history.push(`?${stringified}`)
    }
  }

  handleMainTabChange = (e, value) => {
    this.recordTabChanged('active_main_tab', value)
    this.setState({ mainTabValue: value })
  }

  handleChildTabChange = (e, value) => {
    this.recordTabChanged('active_child_tab', value)
    this.setState({ childTabValue: value })
  }

  searchExisting = (text) => {
    api
      .search('/cost_keys', { filter: { Q: text }, range: [1, 50], sort: [] })
      .then(({ Data }) => {
        this.setState({
          aliasSearchResults: Data || [],
        })
      })
  }

  renderConfirmDeleteDialog = () => {
    const { classes } = this.props

    return (
      <div>
        <Dialog
          className={classes.dialog}
          open={this.state.confirmDelete}
          onClose={this.handleCancelDelete}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You are about to delete {this.state.fields.Descr} cost key. Are
              you sure you want to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancelDelete} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleDelete} color="primary">
              Delete it!
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  renderConfirmCloneDialog = () => {
    return (
      <Dialog
        className={this.props.classes.dialog}
        open={this.state.confirmClone}
        onClose={this.handleCancelClone}>
        <DialogTitle>Clone CostKey</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to clone this cost key, and must choose the next code
            to use. The cost key will be cloned with all the same properties{' '}
            <i>except</i> for the new code.
            <div
              style={{
                textAlign: 'center',
                maxWidth: '50%',
                margin: '0 auto',
              }}>
              <TextField
                fullWidth
                type="text"
                value={this.state.cloneNewCode}
                variant="standard"
                placeholder="New cost key"
                onChange={(ev) => {
                  this.setState({ cloneNewCode: ev.target.value })
                }}
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button
                onClick={this.handleCancelClone}
                color="secondary"
                variant="outlined">
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                onClick={this.handleClone}
                color="primary"
                variant="contained">
                Clone it!
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    )
  }

  renderKeywordAutocomplete = () => (
    <Autocomplete
      multiple
      freeSolo
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      autoHighlight
      options={this.state.availableKeywords.map((v) => ({
        Keyword: v.Keyword,
      }))}
      getOptionLabel={(opt) => {
        return opt
          ? opt.inputValue
            ? `Add ${opt.inputValue}`
            : opt.Keyword || ''
          : ''
      }}
      value={this.state.fields.CostKeyKeywords || []}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Keywords"
          placeholder="Search..."
          InputLabelProps={{ shrink: true }}
        />
      )}
      renderTags={(value, getProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option.Keyword}
            {...getProps({ index })}
          />
        ))
      }
      filterOptions={(options, params) => {
        const f = createFilterOptions()
        const filtered = f(options, params)
        const { inputValue } = params
        const exists = options.some((option) => {
          return inputValue === option.Keyword
        })
        if (!exists && inputValue !== '') {
          filtered.push({ inputValue, Keyword: inputValue })
        }
        return filtered
      }}
      onChange={(event, newValue) => {
        let words = []

        if (Array.isArray(newValue)) {
          for (let i = 0; i < newValue.length; i++) {
            const nv = newValue[i]
            if (typeof nv === 'string') {
              words.push(nv)
              continue
            }
            if (nv.inputValue) {
              words.push(nv.inputValue)
              continue
            }
            if (nv.Keyword) {
              words.push(nv.Keyword)
            }
          }
        }

        const values = words.map((w) => ({ Keyword: w }))

        this.state.availableKeywords.forEach((r) => {
          let i = words.indexOf(r.Keyword)
          if (i >= 0) {
            words.splice(i, 1)
          }
        })

        this.setState({
          availableKeywords: this.state.availableKeywords.concat(
            words.map((w) => {
              return { Keyword: w }
            })
          ),
          fields: {
            ...this.state.fields,
            CostKeyKeywords: values,
          },
        })
      }}
    />
  )

  renderAliasAutocomplete = () => (
    <Autocomplete
      options={this.state.aliasSearchResults}
      getOptionLabel={(opt) => opt.Code || ''}
      getOptionSelected={(opt) => opt.ID === this.state.fields.AliasID}
      onInputChange={(event, v) => {
        this.searchExisting(v)
      }}
      value={this.state.aliasedData}
      defaultValue={null}
      onChange={(event, newValue) => {
        if (!newValue) {
          this.setState({
            aliasSearchResults: [],
            aliasedData: null,
            fields: {
              ...this.state.fields,
              AliasID: null,
            },
          })
          return
        }
        if (newValue.ID === this.props.costKey.ID) {
          this.props.setSnackbarMessage(
            'Cost key cannot alias itself.',
            'error'
          )
          return
        }
        this.setState({
          aliasedData: newValue,
          fields: {
            ...this.state.fields,
            AliasID: newValue.ID,
          },
        })
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            <span>
              Alias existing CPT{' '}
              {this.state.fields.AliasID && (
                <a
                  href={`/cost_key/${this.state.fields.AliasID}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ display: 'inline-block', marginLeft: '0.25rem' }}>
                  <IconLink /> View In New Window
                </a>
              )}
            </span>
          }
          fullWidth
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <Tooltip title="The alias allows the network evaluation to treat the current costkey similar to the aliased costkey.">
                  <Info style={{ height: '15px', width: '15px' }} />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      )}
      style={{ marginTop: 20 }}
    />
  )

  renderMainTabContent = () => {
    switch (this.state.mainTabValue) {
      case this.mainTabs.details:
        return this.renderDetailContent()
      case this.mainTabs.billingTranslation:
        return this.renderBillingTranslationContent()
      case this.mainTabs.ruleSets:
        return this.renderRuleSetsContent()
      default:
    }
    return <div />
  }

  handleUpdateNetworks = (networks) => {
    const netowrkIDs = networks.map((network) => network.ID)
    this.setState({
      fields: { ...this.state.fields, ConfigurableNetworks: netowrkIDs },
      changedFields: {
        ...this.state.changedFields,
        ConfigurableNetworks: netowrkIDs,
      },
    })
  }

  renderDetailContent = () => {
    const { classes } = this.props
    const { TypicalPPOOverride } = this.state.fields
    const requireUnitsLabel = (
      <div>
        Require Units{' '}
        <Tooltip title="Claims Processor will honor units when this bundle comes in">
          <Info style={{ height: '15px', width: '15px' }} />
        </Tooltip>
      </div>
    )
    const requiresApprovalLabel = (
      <div>
        Requires Medical Approval{' '}
        <Tooltip title="This bundle needs prior approval from CMO or provider success as the bundle may be allowed in certain cases but not in others">
          <Info style={{ height: '15px', width: '15px' }} />
        </Tooltip>
      </div>
    )
    const groupByLabel = (
      <div>
        Group by Service Date{' '}
        <Tooltip title="Claims Processor will only pay the highest line in the claim with the amount for the entire bundle">
          <Info style={{ height: '15px', width: '15px' }} />
        </Tooltip>
      </div>
    )
    const deprecatedLabel = (
      <div>
        Deprecated{' '}
        <Tooltip title="This cost key should no longer be used for new prices.">
          <Info style={{ height: '15px', width: '15px' }} />
        </Tooltip>
      </div>
    )
    const alwaysOverrideLabel = (
      <div>
        Always Override Typical PPO{' '}
        <Tooltip title="Always override the typical ppo, or only override when there are less than 10 entries">
          <Info style={{ height: '15px', width: '15px' }} />
        </Tooltip>
      </div>
    )
    const drgLabel = (
      <div>
        DRG Code{' '}
        <Tooltip title="DRG code is used primarily for provider search.">
          <Info style={{ height: '15px', width: '15px' }} />
        </Tooltip>
      </div>
    )
    let laymanDescrValidationOpts,
      descrValidationOpts,
      bundleContentsValidationOpts,
      categoryValidationOpts,
      costKeyValidationOpts
    const backToCostKeys = '< Back to Cost Keys'
    if (this.state.loading)
      return <CircularProgress className={classes.progress} />
    if (this.state.showValidations) {
      if (!this.isLaymanDescrValid())
        laymanDescrValidationOpts = {
          error: true,
          FormHelperTextProps: { error: true },
          helperText: 'Cannot be empty but must be 120 characters or less',
        }
      if (!this.isDescrValid())
        descrValidationOpts = {
          error: true,
          FormHelperTextProps: { error: true },
          helperText:
            'Descr cannot be empty, and must be at least 3 characters long',
        }
      if (!this.areBundleContentsValid())
        bundleContentsValidationOpts = {
          error: true,
          FormHelperTextProps: { error: true },
          helperText: 'Cannot be empty and must be at least 3 characters long',
        }
      if (!this.isCategoryValid())
        categoryValidationOpts = {
          error: true,
          FormHelperTextProps: { error: true },
          helperText: 'Must select a category',
        }
      if (!this.isCostKeyValid())
        costKeyValidationOpts = {
          error: true,
          FormHelperTextProps: { error: true },
          helperText:
            'Cost key cannot be empty and must be >= 5 characters long.',
        }
    }

    return (
      <Grid container spacing={4}>
        <Grid item container spacing={2} xs={12}>
          <Grid item xs={6}>
            {this.renderTextField('Code', 'Cost Key', {
              style: { width: '100%' },
              ...costKeyValidationOpts,
            })}
          </Grid>
          <Grid item xs={6}>
            {this.props.costKey && this.renderAliasAutocomplete()}
          </Grid>
        </Grid>
        <Grid
          container
          spacing={4}
          item
          xs={12}
          md={7}
          style={{
            alignContent: 'flex-start',
            maxWidth: 'unset',
            flexGrow: 1,
          }}>
          <Grid item xs={4}>
            <div style={{ marginBottom: 8 }}>
              <DRGCodeSearcher
                selectedDRGCode={this.state.fields.DRGCode}
                onChange={(x) => {
                  this.handleFieldUpdate('DRGCode', x)
                }}
                TextFieldProps={{
                  InputLabelProps: {
                    shrink: true,
                  },
                  label: drgLabel,
                }}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <div style={{ marginTop: -16 }}>
              {this.renderSelect(
                'Category',
                'Category',
                this.props.categories,
                { fullWidth: true, ...categoryValidationOpts }
              )}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div>
              <CostKeyCategorySearcher
                preloadAll
                selectedCostKeyCategoryID={this.state.fields.CostKeyCategoryID}
                onChange={(v) => {
                  this.handleFieldUpdate('CostKeyCategoryID', v)
                }}
                AutoCompleteProps={{ disabled: true }}
                TextFieldProps={{
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
              />
            </div>
          </Grid>
          <Grid item xs={12} style={{ marginTop: -16, paddingTop: 0 }}>
            {this.renderTextField('Descr', 'Description', {
              multiline: true,
              rows: '4',
              fullWidth: true,
              className: '',
              ...descrValidationOpts,
            })}
          </Grid>
          <Grid item xs={12} style={{ marginTop: -16, paddingTop: 0 }}>
            {this.renderTextField('LaymanDescr', 'Layman Description', {
              multiline: true,
              rows: '3',
              fullWidth: true,
              className: '',
              ...laymanDescrValidationOpts,
            })}
          </Grid>
          <Grid item xs={12} style={{ marginTop: -16, paddingTop: 0 }}>
            <TextField
              {...bundleContentsValidationOpts}
              rows="8"
              multiline
              label="Bundle Contents"
              className={classes.bundleContents}
              value={this.state.fields.BundleContents || ''}
              onChange={this.handleChange}
              name="BundleContents"
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} style={{ marginTop: -16, paddingTop: 0 }}>
            <TextField
              multiline
              label="Notes"
              className={classes.notes}
              value={this.state.fields.Notes || ''}
              onChange={this.handleChange}
              name="Notes"
              margin="normal"
              InputLabelProps={{ shrink: true }}
              rows="5"
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid
          container
          item
          spacing={2}
          xs={12}
          md={5}
          style={{
            alignContent: 'flex-start',
            maxWidth: 'unset',
            flexGrow: 1,
          }}>
          <Grid item xs={12}>
            <TextField
              label="Grouping Term (Legacy)"
              margin="none"
              fullWidth
              style={{ margin: '0 0 1rem 0' }}
              name="LegacyGroupDescr"
              value={this.state.fields.LegacyGroupDescr || ''}
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title="This is a legacy concept ZERO had used to group costkeys together with a common tag-like term. Prefer using Keywords (below) and a proper Category (left) instead. This field is here primarily for information purposes, if the costkey had been previously assigned to a group. We recommend not changing this if its set.">
                      <Info style={{ height: '15px', width: '15px' }} />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            {this.props.costKey && Models.ConfigurableNetwork.canUpdate() && (
              <div style={{ paddingBottom: '0.5rem' }}>
                <NetworksAutocomplete
                  currentNetworks={this.state.fields.ConfigurableNetworks}
                  handleChange={this.handleUpdateNetworks}
                  getOptionLabel={(option) => {
                    if (!option.UseSpecificCostKeys) {
                      return `${option.Name} (specific cost keys disabled)`
                    }
                    return option.Name
                  }}
                  getOptionDisabled={(option) => !option.UseSpecificCostKeys}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            {this.props.costKey && this.renderKeywordAutocomplete()}
          </Grid>
          <Grid item xs={12}>
            {this.props.costKey && (
              <TagAutocomplete
                tagFamily="costkeycode"
                tagSetID={this.state.fields.TagSetID}
                onChangeTagSet={(newTagsetID) => {
                  this.setState({
                    fields: { ...this.state.fields, TagSetID: newTagsetID },
                  })
                }}
              />
            )}
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={12}>
              {this.renderSwitchField('AllowTravel', 'Allow Travel')}
            </Grid>
            <Grid item xs={12}>
              <div
                style={
                  this.state.fields.Deprecated
                    ? {
                        background: '#f1b4b4',
                        padding: '0.5rem',
                        borderRadius: 5,
                      }
                    : { marginLeft: -11 }
                }>
                {this.renderSwitchField('Deprecated', deprecatedLabel, {
                  LabelProps: { style: { margin: 0 } },
                })}
              </div>
            </Grid>
            <Grid item xs={12}>
              {this.renderSwitchField('RequireUnits', requireUnitsLabel)}
            </Grid>
            <Grid item xs={12}>
              {this.renderSwitchField('GroupByServiceDate', groupByLabel)}
            </Grid>
            <Grid item xs={12}>
              {this.renderSwitchField(
                'RequireMedicalReview',
                requiresApprovalLabel
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12}>
                {this.renderSwitchField(
                  'TypicalPPOOverride',
                  'Override Typical PPO Price'
                )}
              </Grid>
              <Grid item xs={12} style={{ paddingLeft: '2rem' }}>
                {TypicalPPOOverride &&
                  this.renderSwitchField(
                    'AlwaysOverrideTypicalPPO',
                    alwaysOverrideLabel
                  )}
              </Grid>
              <Grid item xs={12} style={{ paddingLeft: '2rem' }}>
                {TypicalPPOOverride &&
                  this.renderPriceField(
                    'ManualTypicalPPOPrice',
                    'Manual Typical PPO Price',
                    {
                      fullWidth: true,
                      style: { marginTop: 0, width: '100%' },
                      InputLabelProps: { shrink: true },
                    }
                  )}
              </Grid>
              <Grid item xs={12} style={{ paddingLeft: '2rem' }}>
                {TypicalPPOOverride &&
                  this.renderTextField(
                    'PPOOverrideNotes',
                    'Notes on Override',
                    {
                      multiline: true,
                      rows: '4',
                      rowsMax: '8',
                      fullWidth: true,
                      style: { marginTop: 0, width: '100%' },
                      InputLabelProps: { shrink: true },
                    }
                  )}
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            <strong>
              Missing Bundle Components &nbsp;
              <Tooltip title="An Incomplete bundle is a bundle that is missing certain core parts of a medical procedure that will be billed seperately (e.g. implant, pathology, X-rays). Select any one of these components that are missing form this bundle.">
                <Info style={{ height: '15px', width: '15px' }} />
              </Tooltip>
            </strong>
            <Grid item xs={12}>
              {this.renderSwitchField(
                'IsMissingPathology',
                'Missing Pathology'
              )}
            </Grid>
            <Grid item xs={12}>
              {this.renderSwitchField('IsMissingImplant', 'Missing Implant')}
            </Grid>
            <Grid item xs={12}>
              {this.renderSwitchField('IsMissingXrays', 'Missing Xrays')}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Button style={{ float: 'right' }} onClick={this.backToCostKeyCodes}>
            {backToCostKeys}
          </Button>
        </Grid>
        <Grid item sm={12} lg={7}>
          {this.props.saveResult && this.props.saveResult.error && (
            <Typography type="title" className={classes.errorMsg}>
              {this.props.saveResult.message}
            </Typography>
          )}
        </Grid>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs="auto">
            {this.state.id && (
              <Button
                className={classes.button}
                color="primary"
                variant="outlined"
                onClick={this.showConfirmClone}>
                Clone
              </Button>
            )}
          </Grid>
          <Grid item xs="auto">
            <div className={classes.saveButtonContainer}>
              {this.renderLocalStatusIcon()}
              <Button
                color="primary"
                className={classes.button}
                variant="contained"
                onClick={this.handleSave}>
                Save
              </Button>
              {this.state.fields.ID !== 0 && (
                <Button
                  className={classes.button}
                  color="secondary"
                  variant="contained"
                  onClick={this.showConfirmDelete}>
                  Delete
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {this.props.costKey && this.props.costKey.ID && this.renderChanges()}
        </Grid>
      </Grid>
    )
  }

  renderRuleSetsContent = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {this.props.costKey.ID ? (
            <RuleSetBuilder costKeyID={this.props.costKey.ID} />
          ) : (
            <p style={{ paddingTop: '1rem' }}>
              Rule sets can only be defined once the cost key is saved.
            </p>
          )}
        </Grid>
      </Grid>
    )
  }

  renderBillingTranslationContent = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {this.props.costKey.ID ? (
            <BillingTranslationBuilder costKeyID={this.props.costKey.ID} />
          ) : (
            <p style={{ paddingTop: '1rem' }}>
              Billing translation can only be defined once the cost key is
              saved.
            </p>
          )}
        </Grid>
      </Grid>
    )
  }

  renderContent = () => {
    const { classes } = this.props

    return (
      <div>
        <Paper className={classes.mainContent} elevation={2}>
          <Tabs
            value={this.state.mainTabValue}
            onChange={this.handleMainTabChange}
            indicatorColor="primary"
            textColor="inherit">
            <Tab value={this.mainTabs.details} label="Details" />
            <Tab
              value={this.mainTabs.billingTranslation}
              label="Billing Translation"
            />
            <Tab value={this.mainTabs.ruleSets} label="Rule Sets" />
          </Tabs>
          {this.renderMainTabContent()}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Divider className={classes.sectionDivider} />
            </Grid>
            <Grid item xs={12}>
              {this.renderChildTabs()}
            </Grid>
          </Grid>
          {this.renderConfirmDeleteDialog()}
          {this.renderConfirmCloneDialog()}
        </Paper>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.props.getError
          ? this.renderError(this.props.getError.message)
          : this.renderContent()}
      </div>
    )
  }
}

CostKeyCodeDetail.propTypes = {
  costKey: PropTypes.object.isRequired,
  saveResult: PropTypes.object,
  getError: PropTypes.object,
}

function mapStateToProps(state) {
  const categories = state.costKeyCodes.costKeyCategories || []
  return {
    costKey: state.costKeyCodes.costKey || {},
    categories: utils.arrayToSelectOptions(categories),
    prices: state.costKeyCodes.prices || [],
    saveResult: state.costKeyCodes.saveResult,
    getError: state.costKeyCodes.getCodeError,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      saveCostKeyCode,
      saveNewCostKeyCode,
      getCostKeyCode,
      getNewCostKeyCode,
      deleteCostKeyCode,
      getCostKeyCategories,
      clearSaveResult,
      setSnackbarMessage,
    },
    dispatch
  )
}

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CostKeyCodeDetail)
