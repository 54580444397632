import React, { useState, useEffect } from 'react'
import { TextField, Chip } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'
import { findZClaimSourceFiles } from '../../../../actions/ZClaimActions'
import { ShapeSourceFile } from '../../types'
import styled from 'styled-components'

const StyledOption = styled.span`
  font-size: 85%;
`

export interface Props {
  currentSourceFileIDs: Array<number>
  handleChange: (sourceFiles: Array<number>) => void
  passFilters?: any
  getOptionLabel?: (option: ShapeSourceFile) => string
  getOptionDisabled?: (option: ShapeSourceFile) => boolean
  multiple?: boolean
}

export type { ShapeSourceFile }

/*
  Switching the autocomplete between supporting 'multiple' or singular is a major
  PITA; to make it a little less painful, its easier to make the props exposed
  to this component act as if always multiple (so even if its a single-select, then
  it would still pass `currentSourceFileIDs=[<one value>]`)
*/
export default function SourceFilePicker({
  currentSourceFileIDs = [],
  handleChange,
  passFilters = {},
  getOptionLabel = (option: ShapeSourceFile & Partial<any>) =>
    option.customLabel, // see promise handler where it decorates customLabel below
  getOptionDisabled = (option: ShapeSourceFile) => false,
  multiple = false,
}: Props) {
  const [allSourceFiles, setAllSourceFiles] = useState<Array<ShapeSourceFile>>(
    []
  )
  const [selectedSourceFiles, setSelectedSourceFiles] = useState<
    Array<ShapeSourceFile>
  >([])
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    findZClaimSourceFiles({
      filter: { ...passFilters, disableLimit: true },
      page: 1,
      pageSize: 10,
    })
      .then((res: { Data: Array<ShapeSourceFile> }) => {
        setAllSourceFiles(
          res.Data.map((d) => ({
            ...d,
            // @ts-ignore
            customLabel: d.FileKey.split('/').findLast(() => true) || '',
          }))
        )
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to fetch SourceFiles; please contact Engineering',
        })
      )
  }, [])

  useEffect(() => {
    const sourceFiles = currentSourceFileIDs
      .map((bID: number) => {
        return allSourceFiles.find((b: any) => b.ID === bID)
      })
      .filter((v) => !!v) as ShapeSourceFile[]
    setSelectedSourceFiles(sourceFiles)
  }, [currentSourceFileIDs, allSourceFiles])

  function onChange(e: any, value: any) {
    if (value === null) {
      setSelectedSourceFiles([])
      handleChange([])
      return
    }
    if (Array.isArray(value)) {
      setSelectedSourceFiles(value)
      handleChange(value.map((b) => b.ID))
      return
    }
    setSelectedSourceFiles([value])
    handleChange([value.ID])
  }

  return (
    <Autocomplete
      multiple={multiple}
      options={allSourceFiles}
      getOptionLabel={getOptionLabel}
      renderOption={(opt: any): any => {
        return <StyledOption>{opt.customLabel}</StyledOption>
      }}
      getOptionDisabled={getOptionDisabled}
      getOptionSelected={(opt: any, value: any) => {
        return opt.ID === value.ID
      }}
      value={multiple ? selectedSourceFiles : selectedSourceFiles[0] || null}
      onChange={onChange}
      renderInput={(params: any) => (
        <TextField
          {...params}
          size="small"
          variant="outlined"
          label="SourceFiles"
          placeholder="Search..."
          InputLabelProps={{ shrink: true }}
          style={{ minWidth: 250 }}
        />
      )}
      renderTags={(value: any, getProps: any) =>
        value.map((option: any, index: number) => (
          <Chip
            variant="outlined"
            label={option.customLabel}
            {...getProps({ index })}
          />
        ))
      }
    />
  )
}
