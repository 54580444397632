import * as api from '../services/thezerocard/api-helper'
import * as model from '../models/PracticeFacility'

export const GET_ALL_PRACTICE_FACILITY_SPECIALIZATIONS =
  'GET_ALL_PRACTICE_FACILITY_SPECIALIZATIONS'
export const GET_PRACTICE_FACILITY = 'GET_PRACTICE_FACILITY'
export const GET_NEW_PRACTICE_FACILITY = 'GET_NEW_PRACTICE_FACILITY'
export const SAVE_PRACTICE_FACILITY = 'SAVE_PRACTICE_FACILITY'
export const SAVE_NEW_PRACTICE_FACILITY = 'SAVE_NEW_PRACTICE_FACILITY'
export const GET_PRACTICE_FACILITY_BUNDLE_PRICES =
  'GET_PRACTICE_FACILITY_BUNDLE_PRICES'
export const SAVE_NEW_PRACTICE_FACILITY_BUNDLE_PRICE =
  'SAVE_NEW_PRACTICE_FACILITY_BUNDLE_PRICE'
export const SAVE_PRACTICE_FACILITY_BUNDLE_PRICE =
  'SAVE_PRACTICE_FACILITY_BUNDLE_PRICE'
export const DELETE_PRACTICE_FACILITY_BUNDLE_PRICE =
  'DELETE_PRACTICE_FACILITY_BUNDLE_PRICE'
export const FIND_PRACTICE_FACILITIES = 'FIND_PRACTICE_FACILITIES'
export const CLEAR_FACILITIES = 'CLEAR_FACILITIES'

export type APIResponsePracticeFacility = Promise<
  { Data: model.PracticeFacilityRecordShape } & Partial<any>
>

export const findPfs = (params: any) => {
  return {
    type: FIND_PRACTICE_FACILITIES,
    payload: api.search('/practice_facility', params),
  }
}

export const findPFsNoRedux = (params: any) => {
  return api.search('/practice_facility', params)
}

export const getAllPracticeFacilitySpecializations = () => {
  return {
    type: GET_ALL_PRACTICE_FACILITY_SPECIALIZATIONS,
    payload: api.get('/practice_facility_specialization'),
  }
}

export const getPracticeFacilityBundlePrices = (params: any) => {
  const { practiceFacilityId, orgId } = params

  return {
    type: GET_PRACTICE_FACILITY_BUNDLE_PRICES,
    payload: api.get(
      `/organization/${orgId}/practice_facility/${practiceFacilityId}/bundle_price`
    ),
  }
}

export const saveNewPracticeFacilityBundlePrice = (params: any, body: any) => {
  const { practiceFacilityId, orgId } = params

  return {
    type: SAVE_NEW_PRACTICE_FACILITY_BUNDLE_PRICE,
    payload: api.post(
      `/organization/${orgId}/practice_facility/${practiceFacilityId}/bundle_price`,
      body
    ),
  }
}

export const savePracticeFacilityBundlePrice = (params: any, body: any) => {
  const { id, practiceFacilityId, orgId } = params

  return {
    type: SAVE_PRACTICE_FACILITY_BUNDLE_PRICE,
    payload: api.put(
      `/organization/${orgId}/practice_facility/${practiceFacilityId}/bundle_price/${id}`,
      body
    ),
  }
}

export const deletePracticeFacilityBundlePrice = (params: any) => {
  const { id, practiceFacilityId, orgId } = params

  return {
    type: DELETE_PRACTICE_FACILITY_BUNDLE_PRICE,
    payload: {
      response: api.del(
        `/organization/${orgId}/practice_facility/${practiceFacilityId}/bundle_price/${id}`
      ),
      id,
    },
  }
}

export const clearPracticeFacilities = () => {
  return {
    type: CLEAR_FACILITIES,
    payload: [],
  }
}

export const postPracticeFacility = ({ orgID }: any, body: any) => {
  return api.post(
    `/organization/${orgID}/practice_facility`,
    body
  ) as APIResponsePracticeFacility
}

export const putPracticeFacility = (id: any, body: any) => {
  return api.put(
    `/practice_facility/${id}`,
    body
  ) as APIResponsePracticeFacility
}

export const getPracticeFacility = (id: any) => {
  return api.get(`/practice_facility/${id}`) as APIResponsePracticeFacility
}

export const findPracticeFacilityFeeSchedules = (id: any, params: any) => {
  return api.search(`/practice_facility/${id}/fee_schedules`, params)
}

export const getPracticeFacilityTermData = (id: any) => {
  return api
    .get(`/practice_facility_termination/${id}`)
    .then((res: any) => res.Data)
}

export const terminatePracticeFacility = (id: any, body: any) => {
  return api
    .put(`/practice_facility_termination/${id}`, body)
    .then((res: any) => res.Data)
}

export const unsetPracticeFacilityTermDate = (id: any) => {
  return api
    .put(`/practice_facility_unterminate/${id}`)
    .then((res: any) => res.Data)
}

export const updatePracticeFacilitySpecializations = (id: any, body: any) => {
  return api
    .put(`/organization/${id}/practice_facility_specializations`, body)
    .then((res: any) => res.Data)
}

export const getAllPracticeFacilitySpecializationsV2 = () => {
  return api
    .get('/practice_facility_specialization')
    .then((res: any) => res.Data)
}
