import React from 'react'
import styled from 'styled-components'
import { SourceTypes } from '../helpers'

const StyledID = styled.span`
  white-space: nowrap;
  strong {
    user-select: none;
    opacity: 0.5;
    display: inline-block;
    padding-right: 0.05rem;
  }

  &.sub {
    font-size: 80%;
    padding-left: 0.15rem;
  }
`

export default function DisplayClaimID({ claimObj }: any): any {
  if (!claimObj) return null
  // if (claimObj.SourceType === SourceTypes.SourceTypeVBA) {
  //   return (
  //     <StyledID>
  //       <strong>vba#</strong>
  //       {claimObj.Detail?.ExtClaimID || '?'}
  //     </StyledID>
  //   )
  // }
  return (
    <StyledID>
      <strong>Z</strong>
      {claimObj.ID}

      {claimObj.SourceType === SourceTypes.SourceTypeVBA && (
        <StyledID className="sub">
          <strong>vba#</strong>
          {claimObj.Detail?.ExtClaimID || '?'}
        </StyledID>
      )}
    </StyledID>
  )
}
