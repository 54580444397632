import React, { useState, useEffect, useRef } from 'react'
import { Button } from '@material-ui/core'
import AddManualClaim from '../Components/AddManualClaim'
import { useSetFrameHeaderMid } from '../../../components/AppFrame'
import useQueryParamsGen2 from '../../../hooks/useQueryParamsGen2'
import PageClaimsListView from './viewClaimsList'
import PageWorkflowsView from './viewWorkflows'

const SUB_VIEW_CLAIMS = 'claims'
const SUB_VIEW_WORKFLOWS = 'workflows'

export default function PageClaims(): React.ReactElement {
  const { queryData, setQueryData } = useQueryParamsGen2({
    scope: 'zclaims.claims.subview',
  })
  const [subView, setSubView] = useState(
    queryData['subView'] || SUB_VIEW_CLAIMS
  )
  const refClaimTable = useRef<any>(null)

  useEffect(() => {
    setQueryData({ subView })
  }, [subView])

  useSetFrameHeaderMid(() => {
    return (
      <>
        <Button
          size="small"
          variant={subView === SUB_VIEW_CLAIMS ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_CLAIMS)}>
          Claims View
        </Button>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <Button
          size="small"
          variant={subView === SUB_VIEW_WORKFLOWS ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_WORKFLOWS)}>
          Workflows
        </Button>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <AddManualClaim
          onClaimCreated={() => {
            refClaimTable.current?.setFilterImmediate({
              ClaimsCreatedByMe: true,
            })
          }}
        />
      </>
    )
  }, [refClaimTable, subView])

  switch (subView) {
    case SUB_VIEW_CLAIMS:
      return <PageClaimsListView ref={refClaimTable} />

    case SUB_VIEW_WORKFLOWS:
      return <PageWorkflowsView />
  }

  return (
    <span>Invalid state: try clicking Claims View or Workflows above...</span>
  )
}
