import React, { useState, useEffect, useRef } from 'react'
import * as api from '../../../services/thezerocard/api-helper'
import {
  Table,
  FileTableFilter,
  FileTableSearcher,
} from '../../Files/FilesTable'
import * as types from './types'
import { Grid, Button, TextField, Tooltip } from '@material-ui/core'
import styled from 'styled-components'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
require('./adhocFileSend.scss')

const Spacer = styled(Grid)`
  width: 10px;
`

export default function AdhocFileSend({
  recipient,
  setTabViewLog,
}: {
  recipient: types.recipient
  setTabViewLog(): void
}): any {
  const [markDone, setMarkDone] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState<any>([])
  const [queuedFiles, setQueuedFiles] = useState<any>([])
  const refFileTable = useRef()
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    setSelectedFiles([])
    setQueuedFiles([])
  }, [recipient])

  const onCheckSelectFiles = (selected: any[]) => {
    setSelectedFiles(selected || [])
  }

  const onClickQueueForSend = () => {
    setQueuedFiles(
      selectedFiles.map((f: any) => {
        const s3k = (f && f.S3Key) || ''
        const s3Path = s3k.split('/')
        return {
          ID: f.ID,
          DestPath: recipient.DefaultDestPath || '',
          Name: s3Path[s3Path.length - 1],
          DestFileName: s3Path[s3Path.length - 1],
        }
      })
    )
  }

  const doQueueForSend = () => {
    api
      .post(
        `/engineering/sftpaas/recipient/${recipient.ID}/queue-for-delivery`,
        queuedFiles
      )
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        setMarkDone && setMarkDone(true)
        setQueuedFiles([])
        setSelectedFiles([])
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Request failed; please contact engineering',
        })
      )
  }

  if (markDone) {
    return (
      <div style={{ textAlign: 'center', padding: '1rem' }}>
        Files queued for delivery! Follow the status in{' '}
        <Button
          color="primary"
          variant="outlined"
          size="small"
          onClick={setTabViewLog}>
          Send Log
        </Button>
      </div>
    )
  }

  if (queuedFiles.length > 0) {
    return (
      <>
        <p>
          The following files are selected to send to recipient{' '}
          <strong>{recipient.Name}</strong>. If you need to change the name of
          the files <i>as they'll be delivered on the recipient's server</i>,
          you can do so here. The name should include, and not change, the file
          extension.
        </p>
        <div className="adhoc-queued-files">
          <div className="headers">ID</div>
          <div className="headers">Destination Path</div>
          <div className="headers">Destination File Name</div>
          {!markDone &&
            queuedFiles.map((f: any, idx: number) => (
              <React.Fragment key={f.ID}>
                <div className="cell">{f.ID}</div>
                <div className="cell">
                  <TextField
                    fullWidth
                    // variant='outlined'
                    size="small"
                    placeholder={f.DestPath}
                    value={f.DestPath || ''}
                    helperText="Drop path on the recipient server"
                    onChange={(ev: any) => {
                      const arrCopy = [...queuedFiles]
                      const objCopy = { ...f }
                      objCopy.DestPath = ev.target.value
                      arrCopy[idx] = objCopy
                      setQueuedFiles(arrCopy)
                    }}
                  />
                </div>
                <div className="cell">
                  <TextField
                    fullWidth
                    // variant='outlined'
                    size="small"
                    placeholder={f.DestFileName}
                    value={f.DestFileName || ''}
                    helperText={`Original file name: ${f.Name}`}
                    onChange={(ev: any) => {
                      const arrCopy = [...queuedFiles]
                      const objCopy = { ...f }
                      objCopy.DestFileName = ev.target.value
                      arrCopy[idx] = objCopy
                      setQueuedFiles(arrCopy)
                    }}
                  />
                </div>
              </React.Fragment>
            ))}
        </div>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs="auto">
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                setQueuedFiles([])
              }}>
              Back
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button
              color="primary"
              variant="contained"
              onClick={doQueueForSend}>
              Queue For Delivery ({queuedFiles.length} files)
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <Table
      ref={refFileTable}
      onCheckHandler={onCheckSelectFiles}
      LeftHeaderItems={
        <>
          <FileTableSearcher />
          <Spacer />
          <FileTableFilter />
        </>
      }
      RightHeaderItems={
        <>
          {!!selectedFiles.length && (
            <Tooltip title="Does not send yet; there is another step">
              <Button
                variant="contained"
                color="primary"
                onClick={onClickQueueForSend}>
                Prepare Send ({selectedFiles.length} files)
              </Button>
            </Tooltip>
          )}
        </>
      }
    />
  )
}
