import * as api from '../services/thezerocard/api-helper'
import * as model from '../models/Loa'

export type APIResponseLOA = Promise<
  { Data: model.LOADecoratedShape } & Partial<any>
>
export type APIResponseLOAs = Promise<
  { Data: Array<model.LOADecoratedShape> } & Partial<any>
>

export function findLOAs(params: any): APIResponseLOAs {
  return api.search(`/loa`, params) as any
}

export function getNewEmptyLOAByIntegrationID(
  integrationID: string
): Promise<any> {
  return api.get('/new_loa', { integrationID })
}

export function getNewEmptyLOAByReferralID(referralID: string): Promise<any> {
  return api.get('/new_loa', { referralID })
}

export function getLOA(id: number): APIResponseLOA {
  return api.get(`/loa/${id}`) as any
}

export function putUpdateLOA(payload: model.LOARecordShape): APIResponseLOA {
  return api.put(`/loa/${payload.ID}`, payload) as any
}

export function postCreateLOA(payload: model.LOARecordShape): APIResponseLOA {
  return api.post('/loa', payload) as any
}

export function deleteLOA(id: number): Promise<any> {
  return api.del(`/loa/${id}`) as any
}

export function getLOAStatuses(): Promise<any> {
  return api.get(`/loa_statuses`)
}

export function getLOAFiles(id: number, params: any): Promise<any> {
  return api.search(`/loa/${id}/files`, params)
}

export function postLOAFile(id: number, payload: any): Promise<any> {
  return api.post(`/loa/${id}/file`, payload)
}

export function deleteLOAFiles(id: number, FileIDs: number[]) {
  return api.del(`/loa/${id}/files`, { FileIDs })
}
