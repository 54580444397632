import React from 'react'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import * as EmployerTable from './EmployerTable'
import styled from 'styled-components'

const PaperWrapper = styled(Paper)`
  padding: 0.25rem 1rem 1rem;
`

const CORE_TAB = 1

export default function Employers({ history }: any) {
  const handleAddNew = () => {
    history.push(`/employer/?active_main_tab=${CORE_TAB}`)
  }

  return (
    <PaperWrapper elevation={2}>
      <EmployerTable.Table
        enableURLReflection
        passFilters={{ isCustomer: true, active: true }}
        RightHeaderItems={
          <>
            <Button variant="contained" color="primary" onClick={handleAddNew}>
              Add New
            </Button>
          </>
        }
        LeftHeaderItems={
          <>
            <EmployerTable.StandardFilterSearch autoFocus />
            &nbsp;&nbsp;
            <EmployerTable.FilterEmployerStatus />
          </>
        }
      />
    </PaperWrapper>
  )
}
