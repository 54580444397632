import React, { useState, useEffect } from 'react'
import { editEmployerMemberActivationDate } from '../../actions/MemberActions'
import DesignSuite2023 from '../../components/DesignSuite2023'
import dateTime from '../../utils/dateTime'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import {
  renderNotesField as RenderNotesField,
  SetterArgs,
} from '../ViewHelpers'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'
import * as shapes from './types'

interface props {
  Trigger: any // React.ReactComponent
  empMemRec: shapes.shapeEmployerMember | null
  onSaved?(): void
}

export default function DialogSetActivationDate({
  Trigger,
  empMemRec,
  onSaved,
}: props): React.ReactElement | null {
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const [isOpen, setIsOpen] = useState(false)
  const [payload, setPayload] = useState<any>({
    ActivationDate: empMemRec?.ActivationDate || null,
    Notes: null,
  })

  useEffect(() => {
    if (!isOpen) return
    setPayload({
      ActivationDate: empMemRec?.ActivationDate || null,
      Notes: null,
    })
  }, [isOpen])

  function setter({ name, value }: SetterArgs) {
    setPayload((curr: any) => ({ ...curr, [name]: value }))
  }

  function doSave() {
    const d = dateTime.parse(payload.ActivationDate)
    if (!d.isValid() || d.isBefore(dateTime.presets.Y2015)) {
      alert(
        'Invalid date: currently we do not allow setting the activation date to zero (ie. never) or any year before 2015'
      )
      return
    }

    editEmployerMemberActivationDate({
      ...payload,
      EmployerMemberID: empMemRec?.ID,
    })
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar('Activation date updated OK!', SnackbarTypeSuccess)
        onSaved && onSaved()
      })
      .catch(
        catchAPIError({ defaultMessage: 'Failed updating activation date' })
      )
      .finally(() => setIsOpen(false))
  }

  if (!empMemRec) return null

  return (
    <>
      <DesignSuite2023.Tooltip title="Manually set activation date">
        <Trigger onClick={() => setIsOpen(true)} />
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Change Employer Member Activation Date</DialogTitle>
        <DialogContent>
          <DesignSuite2023.AlertInfo style={{ marginBottom: '1rem' }}>
            <strong>Notice</strong>: during the next eligibility run for the
            given employer, if the record for this person appears again, this
            could be overridden.
          </DesignSuite2023.AlertInfo>
          <ManagedDateInput
            name="ActivationDate"
            label="Activation Date"
            value={payload.ActivationDate}
            setter={setter}
          />
          <RenderNotesField
            setter={setter}
            name="Notes"
            label="Notes"
            value={payload.Notes}
            opts={{
              margin: 'normal',
              variant: 'outlined',
              size: 'small',
            }}
          />

          {/* <pre>{JSON.stringify(payload, null, '  ')}</pre> */}
        </DialogContent>
        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            }
            right={
              <Button color="primary" variant="contained" onClick={doSave}>
                Save
              </Button>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
