import {
  deleteProviderReceiptFile,
  getProviderReceiptDetail,
  getProviderReceiptFiles,
  PostApplyProviderReceiptRecord,
  postApplyProviderReceipts,
  PostApplyProviderReceiptsParams,
  receiptType,
} from '../../../actions/ZClaimFundingActions'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { RenderPriceField, SetterArgs } from '../../ViewHelpers'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../../hooks/useSnackbar'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import FileLinkBtn from '../../../components/FileLinkBtn/FileLinkBtn'
import styled from 'styled-components'
import DeleteIcon from '@material-ui/icons/Delete'
import ChipInformationDisplay from './ChipInformationDisplay'
import dateTime from '../../../utils/dateTime'

interface appliedProviderReceipt {
  ReceiptZClaimID: string
  ReceiptID: number
  ReceiptDate: string | null
  ReceiptType: receiptType
  CheckNumber: string
  ACHInfo: string
  ReceiptAmount: string
  AppliedAmount: string
  Notes: string
}

interface relatedZClaimInfo {
  ZClaimID: number
  Date: string | null
  IsReversal: boolean
  PayableAmount: string
  AppliedAmount: string
  OpenBalance: string
  AppliedReceipts: appliedProviderReceipt[]
}

interface providerReceiptInfo {
  ReceiptID: number
  ReceiptDate: string | null
  BillingEntityID: number
  BillingEntityName: string
  OrganizationName: string
  ReceiptType: receiptType
  CheckNumber: string
  Notes: string
  ReceiptAmount: string
  AppliedAmount: string
  UnappliedAmount: string
  IsReversed: boolean
  ReversalDate: string | null
  RelatedZClaimInfo: relatedZClaimInfo[]
  ReceiptFiles: receiptFile[]
}

interface receiptFile {
  FileID: number
  FileName: string
  S3Key: string
}

const initialProviderReceiptInfo = (): providerReceiptInfo => ({
  ReceiptID: 0,
  ReceiptDate: dateTime.now().format(dateTime.formats.ISODate),
  BillingEntityID: 0,
  BillingEntityName: '',
  OrganizationName: '',
  ReceiptType: receiptType.CHECK,
  CheckNumber: '',
  Notes: '',
  ReceiptAmount: '',
  AppliedAmount: '',
  UnappliedAmount: '',
  IsReversed: false,
  ReversalDate: null,
  RelatedZClaimInfo: [],
  ReceiptFiles: [],
})

interface receiptDetailProps {
  receiptID: number
  doClose?(): void
}

const DetailDiv = styled.div`
  margin-top: 8px;
  margin-bottom: 16px;
`

export default function ProviderReceiptDetail({
  receiptID,
  doClose,
}: receiptDetailProps): React.ReactElement {
  onclose = doClose ? doClose : null
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const [data, setData] = useState<providerReceiptInfo>(
    initialProviderReceiptInfo()
  )
  const [receiptFiles, setReceiptFiles] = useState<receiptFile[]>([])

  useEffect(() => {
    getData()
    getFiles()
  }, [receiptID])

  function getData() {
    if (receiptID === 0) {
      setData(initialProviderReceiptInfo())
      return
    }
    return getProviderReceiptDetail(receiptID)
      .then((res: any) => {
        setData(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to get zClaim receipt data',
        })
      )
  }

  function getFiles() {
    if (receiptID === 0) {
      return
    }
    return getProviderReceiptFiles(receiptID)
      .then((res: any) => {
        setReceiptFiles(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to get zClaim receipt data',
        })
      )
  }

  // map by zClaimID
  const [postApplyProviderReceiptRecords, setPostApplyProviderReceiptRecords] =
    useState<Map<number, PostApplyProviderReceiptRecord>>(new Map())

  function doPostApplyReceipt(zClaimID: number) {
    // Get the record for the given zClaimID
    const record = postApplyProviderReceiptRecords.get(zClaimID)

    // If there's no record for the given zClaimID, we can't proceed
    if (!record) {
      console.error(`No record found for zClaimID: ${zClaimID}`)
      return
    }

    // Create an array with the single record
    const params: PostApplyProviderReceiptsParams = [record]

    postApplyProviderReceipts(params)
      .then(() => {
        setAmountByZClaimID({ value: '', zClaimID })
        getData()
        showSnackbar(`Receipt posted OK`, SnackbarTypeSuccess)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed posting receipt',
        })
      )
  }

  function deleteReceiptFile(receiptID: number, fileID: number) {
    deleteProviderReceiptFile(receiptID, fileID)
      .then(() => {
        showSnackbar(`File Successfully Deleted`, SnackbarTypeSuccess)
        getFiles()
      })
      .catch(catchAPIError({ defaultMessage: 'Failed to delete receipt file' }))
  }

  function getReceiptInfo() {
    if (data.ReceiptType === receiptType.CHECK) {
      return `Check Number: ${data.CheckNumber}`
    } else if (data.ReceiptType === receiptType.ACH) {
      return 'ACH'
    }
  }

  interface setByZClaimIDArgs {
    value: string
    zClaimID: number
  }

  const setAmountByZClaimID = ({ value, zClaimID }: setByZClaimIDArgs) => {
    setPostApplyProviderReceiptRecords((prevRecords) => {
      let record = prevRecords.get(zClaimID)
      if (record) {
        // Update the existing record
        record = {
          ...record,
          amount: value,
        }
      } else {
        // Create a new record
        record = {
          receiptID: data.ReceiptID,
          zClaimID: zClaimID,
          date: dateTime.now().format(dateTime.formats.ISODate),
          amount: value,
        }
      }
      prevRecords.set(zClaimID, record)

      // Create a new Map for the updated records
      const updatedRecords = new Map(prevRecords)
      updatedRecords.set(zClaimID, record)

      return updatedRecords
    })
  }

  return (
    <>
      <Container>
        <DetailDiv>
          <Typography component="div" variant={'h5'}>
            {data.BillingEntityName}
          </Typography>
          <Typography component="div" variant={'subtitle1'}>
            {`Organization: ${data.OrganizationName}`}
          </Typography>
        </DetailDiv>
        <DetailDiv>
          <ChipInformationDisplay label="Receipt ID" value={data.ReceiptID} />
          <ChipInformationDisplay label="Method" value={getReceiptInfo()} />
          <ChipInformationDisplay
            label="Receipt Amount"
            value={formatAmount(data.ReceiptAmount)}
          />
          {!data.IsReversed && (
            <>
              <ChipInformationDisplay
                label="Applied Amount"
                value={formatAmount(data.AppliedAmount)}
              />
              <ChipInformationDisplay
                label="Unapplied Balance"
                value={formatAmount(data.UnappliedAmount)}
              />
            </>
          )}
        </DetailDiv>

        {!!receiptFiles.length && (
          <Grid container spacing={2}>
            {receiptFiles.map((file) => (
              <Grid item xs={3}>
                <FileLinkBtn
                  filename={file.FileName}
                  handleGetDownloadURLFail={() =>
                    showSnackbar(`Unable to download file`, SnackbarTypeError)
                  }
                  fileId={file.FileID}
                />
                <IconButton
                  onClick={() => {
                    deleteReceiptFile(receiptID, file.FileID)
                  }}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            ))}
          </Grid>
        )}
        <Divider />
        {data.IsReversed && (
          <Typography variant="body1" style={{ color: 'red' }}>
            {`reversed on ${data.ReversalDate ? dateTime.parse(data.ReversalDate).format() : ''}`}
          </Typography>
        )}
        {!data.IsReversed && (
          <>
            <Typography variant={'body1'}>
              Open and previously applied zClaims:
            </Typography>
            {data.RelatedZClaimInfo.length === 0 && (
              <Typography
                variant="h6"
                color={'textSecondary'}
                style={{ marginTop: '100px', marginBottom: '100px' }}>
                There are no open zClaims for {data.BillingEntityName}
              </Typography>
            )}

            {data.RelatedZClaimInfo.map((zClaim) => {
              const setter = ({ value }: SetterArgs) => {
                setAmountByZClaimID({ value, zClaimID: zClaim.ZClaimID })
              }

              const getAmountValue = () => {
                const record = postApplyProviderReceiptRecords.get(
                  zClaim.ZClaimID
                )
                return record ? record.amount : ''
              }

              return (
                <>
                  <Card variant={'outlined'} style={{ marginTop: '20px' }}>
                    <CardHeader
                      title={
                        <>
                          {`ZClaim ID: ${zClaim.ZClaimID}`}
                          {zClaim.IsReversal && (
                            <span style={{ color: 'red' }}> [Reversal]</span>
                          )}
                        </>
                      }
                      subheader={
                        <>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Typography variant="body1">
                                ZClaim Date:{' '}
                                {dateTime.parse(zClaim.Date).format()}
                              </Typography>
                              <Typography
                                variant="body1"
                                style={{
                                  color: zClaim.IsReversal ? 'red' : 'inherit',
                                }}>
                                ZClaim Total:{' '}
                                {formatAmount(zClaim.PayableAmount)}
                              </Typography>
                              <Typography variant="body1">
                                Applied Amount:{' '}
                                {formatAmount(zClaim.AppliedAmount)}
                              </Typography>
                              <Typography
                                variant="body1"
                                style={{
                                  color: zClaim.IsReversal ? 'red' : 'inherit',
                                }}>
                                Open Balance: {formatAmount(zClaim.OpenBalance)}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              {zClaim.OpenBalance !== '0' &&
                                data.UnappliedAmount !== '0' && (
                                  <>
                                    <RenderPriceField
                                      name="amount"
                                      label="Amount"
                                      opts={{
                                        placeholder:
                                          'Enter receipt amount to apply to this zClaim',
                                      }}
                                      value={getAmountValue()}
                                      setter={setter}
                                    />
                                    <Button
                                      color="primary"
                                      variant="outlined"
                                      onClick={() =>
                                        doPostApplyReceipt(zClaim.ZClaimID)
                                      }>
                                      Apply Receipt
                                    </Button>
                                  </>
                                )}
                            </Grid>
                          </Grid>
                        </>
                      }></CardHeader>
                    <CardContent>
                      {zClaim.AppliedReceipts &&
                        zClaim.AppliedReceipts.length > 0 && (
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>ID</TableCell>
                                  <TableCell>Receipt ID</TableCell>
                                  <TableCell>Receipt Date</TableCell>
                                  <TableCell>Receipt Type</TableCell>
                                  <TableCell>Notes</TableCell>
                                  <TableCell>Receipt Amount</TableCell>
                                  <TableCell>Applied Amount</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {zClaim.AppliedReceipts.map((receipt) => (
                                  <TableRow
                                    key={receipt.ReceiptZClaimID}
                                    style={
                                      receipt.ReceiptID === receiptID
                                        ? { backgroundColor: 'yellow' }
                                        : {}
                                    }>
                                    <TableCell>
                                      {receipt.ReceiptZClaimID}
                                    </TableCell>
                                    <TableCell>{receipt.ReceiptID}</TableCell>
                                    <TableCell>
                                      {dateTime
                                        .parse(receipt.ReceiptDate)
                                        .format()}
                                    </TableCell>
                                    <TableCell>
                                      {receipt.ReceiptType === receiptType.CHECK
                                        ? `Check: ${receipt.CheckNumber}`
                                        : receipt.ReceiptType ===
                                            receiptType.ACH
                                          ? `ACH: ${receipt.ACHInfo}`
                                          : receipt.ReceiptType}
                                    </TableCell>
                                    <TableCell>{receipt.Notes}</TableCell>
                                    <TableCell>
                                      {formatAmount(receipt.ReceiptAmount)}
                                    </TableCell>
                                    <TableCell>
                                      {formatAmount(receipt.AppliedAmount)}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      <Divider />
                    </CardContent>
                  </Card>
                </>
              )
            })}
          </>
        )}
      </Container>
    </>
  )
}

function formatAmount(amount: string | number): string {
  if (!amount) {
    return `$0.00`
  }

  if (typeof amount === 'number') {
    amount = amount.toString()
  }

  if (amount === '0') {
    return `$0.00`
  }

  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
