import React, { useImperativeHandle } from 'react'
import * as EligibilityTable from '../../Eligibility/EligibilityTable2'
import { Button, Dialog, Typography } from '@material-ui/core'
import styled from 'styled-components'

const StyledDialog = styled(Dialog)`
  .std-table {
    .header-items {
      margin-top: 0;
      margin: 0;
      width: auto;
      padding: 0.5rem 0.5rem 0;
      position: sticky;
      top: 0;
      z-index: 99;
      background: #fff;
      align-items: center;
    }
    .base-table-display {
      margin: 0;
    }
  }

  .autocomplete-root {
    width: auto;
    .MuiFormControl-fullWidth {
      width: auto;
      min-width: 0;
    }
  }
`

interface props {
  onPickedMember: Function
  Trigger?: React.FC<any> | null
}

export default React.forwardRef(function DialogMemberSearch(
  { onPickedMember, Trigger }: props,
  ref: any
): React.ReactElement | null {
  const [isOpen, setIsOpen] = React.useState(false)

  useImperativeHandle(
    ref,
    () => ({
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
    }),
    [setIsOpen]
  )

  function doClose() {
    setIsOpen(false)
  }

  function onSelectMember(member: any) {
    onPickedMember?.(member)
    doClose()
  }

  let btnTrigger = null
  if (Trigger) {
    btnTrigger = (
      <Trigger
        onClick={(ev: any) => {
          ev?.stopPropagation()
          setIsOpen(true)
        }}
      />
    )
  }

  return (
    <>
      {btnTrigger}

      <StyledDialog open={isOpen} onClose={doClose} maxWidth="xl">
        <EligibilityTable.Table
          onRowClick={onSelectMember}
          DataTableProps={{
            density: 'small',
          }}
          RightHeaderItems={
            <>
              <Typography variant="h6">Member Search</Typography>
              &nbsp;&nbsp;
              <Button onClick={doClose} variant="outlined" size="small">
                Close
              </Button>
            </>
          }
          LeftHeaderItems={
            <>
              <EligibilityTable.EligTableContext.Consumer>
                {({ doSearch }: { doSearch(): void }) => (
                  <form
                    onSubmit={(ev: any) => {
                      ev.preventDefault()
                      ev.stopPropagation()
                      doSearch()
                    }}
                    style={{
                      width: '100%',
                      display: 'inline-flex',
                      alignItems: 'center',
                    }}>
                    <EligibilityTable.FilterFirstName />
                    &nbsp;&nbsp;
                    <EligibilityTable.FilterLastName />
                    &nbsp;&nbsp;
                    <EligibilityTable.FilterDateOfBirth />
                    &nbsp;&nbsp;
                    <EligibilityTable.FilterIntegrationID />
                    &nbsp;&nbsp;
                    <EligibilityTable.FilterEmployer
                      EmployerSearcherProps={{
                        AutoCompleteProps: {
                          style: { display: 'inline-flex' },
                        },
                      }}
                    />
                    &nbsp;&nbsp;
                    <EligibilityTable.FilterButtonDoSearch />
                  </form>
                )}
              </EligibilityTable.EligTableContext.Consumer>
            </>
          }
        />

        {/* <div className="custom-dialog-title">
          <Typography variant="h6">Member Search</Typography>
          <Button onClick={doClose} variant="outlined" size="small">
            Close
          </Button>
        </div>
        <DialogContent style={{ width: '1080px' }}>
          <EligibilityTable.Table
            onRowClick={onSelectMember}
            DataTableProps={{
              density: 'small',
            }}
            LeftHeaderItems={
              <>
                <EligibilityTable.EligTableContext.Consumer>
                  {({ doSearch }: { doSearch(): void }) => (
                    <form
                      onSubmit={(ev: any) => {
                        ev.preventDefault()
                        ev.stopPropagation()
                        doSearch()
                      }}
                      style={{ width: '100%' }}>
                      <EligibilityTable.FilterFirstName />
                      &nbsp;&nbsp;
                      <EligibilityTable.FilterLastName />
                      &nbsp;&nbsp;
                      <EligibilityTable.FilterDateOfBirth />
                      &nbsp;&nbsp;
                      <EligibilityTable.FilterIntegrationID />
                      &nbsp;&nbsp;
                      <EligibilityTable.FilterEmployer
                        EmployerSearcherProps={{
                          AutoCompleteProps: {
                            style: { display: 'inline-flex' },
                          },
                        }}
                      />
                      &nbsp;&nbsp;
                      <EligibilityTable.FilterButtonDoSearch />
                    </form>
                  )}
                </EligibilityTable.EligTableContext.Consumer>
              </>
            }
          />
        </DialogContent> */}
      </StyledDialog>
    </>
  )
})
