import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import { DataTable } from '../../components/DataTable'
import models from '../../models'
import useQueryParamsGen2 from '../../hooks/useQueryParamsGen2'
import { debounce } from 'lodash'
import * as api from '../../services/thezerocard/api-helper'
import { Grid, TextField, InputAdornment } from '@material-ui/core'
import { Search as IconSearch } from '@material-ui/icons'

const defaultSortCol = models.Referrer.columns.Name.details.sortName

export default function ReferrersTableList({ RightHeaderItems }: any) {
  const { queryData, setQueryData } = useQueryParamsGen2()
  const [results, setResults] = useState([])
  const [resultCount, setResultCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState({ q: '', ...queryData.filter })
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    ...queryData.pagination,
  })
  const [sortable, setSortable] = useState({
    col: defaultSortCol,
    dir: 'asc',
    ...queryData.sortable,
  })
  const history = useHistory()
  const { showDurationShort: showSnackbarDuration } = useSnackbar()

  const loadReferrers = useCallback(
    debounce((payload: any) => {
      // @ts-ignore issue query and handle results
      api
        .search(`/referrer`, payload)
        .then((res: any) => {
          if (res.error) {
            throw res
          }
          setResults(res.Data)
          setResultCount(res.Meta?.Total || 0)
        })
        .catch((err: any) => {
          if (err.Error?.Message) {
            showSnackbarDuration(err.Error.Message, SnackbarTypeError)
            return
          }
          showSnackbarDuration(
            'Failed loading referrers; please contact engineering',
            SnackbarTypeError
          )
        })
        .finally(() => {
          setLoading(false)
        })
    }, 400),
    [setLoading, setResults, setResultCount, showSnackbarDuration]
  )

  useEffect(() => {
    setQueryData({ filter, pagination, sortable })
    setLoading(true)
    // compose payload
    const payload = {
      filter: { ...filter },
      sort: [sortable.col, sortable.dir],
      page: pagination.page,
      pageSize: pagination.pageSize,
    }
    if (payload.filter.q && payload.filter.q.length < 3) {
      payload.filter.q = null
    }
    loadReferrers(payload)
  }, [filter, pagination, sortable, setQueryData, setLoading, loadReferrers])

  return (
    <>
      <Grid container spacing={2} justify="space-between">
        <Grid item xs={12} md="auto">
          <TextField
            autoFocus
            placeholder="Start typing to search"
            size="small"
            value={filter.q}
            onChange={(ev: any) => {
              const q = ev.target?.value
              setFilter((c: any) => {
                return { ...c, q }
              })
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconSearch />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md="auto">
          {RightHeaderItems}
        </Grid>
      </Grid>

      <DataTable
        loading={loading}
        keyProp="ID"
        data={results}
        columns={models.Referrer.columns}
        initPage={pagination.page * 1}
        initPageSize={pagination.pageSize * 1}
        count={resultCount * 1}
        onRowClick={(_: any, { ID }: any, cmdClicked: boolean) => {
          const redirectPath = `/referrer/${ID}`
          if (cmdClicked) {
            window.open(redirectPath, `_blank`)
            return
          }
          history.push(redirectPath)
        }}
        onChangePage={setPagination}
        onChangeRowsPerPage={setPagination}
        sortHandler={setSortable}
        sortable={sortable}
      />
    </>
  )
}
