import React, { useState, useEffect } from 'react'
import DesignSuite2023 from '../../../../components/DesignSuite2023'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'
import EDISourceViewer, {
  EDISourceViewerActionLoadClaim,
} from '../EDISourceViewer'
import { omit as _omit } from 'lodash'
import {
  FormControlLabel,
  Grid,
  Link,
  Switch,
  IconButton,
} from '@material-ui/core'
import styled from 'styled-components'
import { SourceTypes } from '../../helpers'
import DisplayKeyValueData, {
  removeEmptyObjFields,
} from '../DisplayKeyValueData'
import { getViewableSource } from '../../../../actions/ZClaimActions'

const StyledViewSource = styled.div`
  .boxd {
    margin-bottom: 1.5rem;
  }
`

interface payload {
  SourceType: SourceTypes
  SourceFile: any
  ZClaimSrcUUID: string | null
  Observed: {
    Patient: any
    Subscriber: any
    ReferringProvider: any
    RenderingProvider: any
    BillingProvider: any
    ServiceFacility: any
  }
  // outlet to allow sending back customized additions
  [key: string]: any
}

export default function ViewSource({
  zClaimID,
  Trigger,
}: {
  zClaimID: number | null
  Trigger: React.FC<any> | null
}): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [data, setData] = useState<payload | null>(null)
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (!isOpen) {
      setData(null)
      return
    }
    if (!zClaimID) {
      return
    }
    load(zClaimID)
  }, [isOpen, zClaimID])

  function load(zClaimID: number) {
    getViewableSource(zClaimID)
      .then((res: any) => {
        if (res.error) throw res
        setData(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to load source data',
        })
      )
  }

  let displayComp = null

  switch (data?.SourceType) {
    case SourceTypes.SourceTypeEDI:
      displayComp = <ViewSourceEDI data={data} />
      break
    case SourceTypes.SourceTypeGoZero:
      displayComp = <ViewSourceGoZero data={data} />
      break
    case SourceTypes.SourceTypeRxngo:
      displayComp = <ViewSourceRxngo data={data} />
      break
    case SourceTypes.SourceTypeVBA:
      displayComp = <ViewSourceVBA data={data} />
      break
    case SourceTypes.SourceTypeManual:
      displayComp = <div>This was a manually created claim (@todo)</div>
      break
    case SourceTypes.SourceTypeClone:
      displayComp = <div>This was a cloned claim (@todo)</div>
      break
    default:
      displayComp = (
        <div style={{ textAlign: 'center', padding: '3rem 0' }}>
          <div>
            <DesignSuite2023.LoadingSpinner />
          </div>
          <small>Loading Source Data</small>
        </div>
      )
  }

  let btnTrigger = null
  if (Trigger) {
    btnTrigger = (
      <Trigger
        onClick={(ev: any) => {
          ev?.stopPropagation()
          setIsOpen(true)
        }}
      />
    )
  }
  if (!btnTrigger) {
    btnTrigger = (
      <IconButton
        size="small"
        onClick={(ev: any) => {
          ev.stopPropagation()
          setIsOpen(true)
        }}>
        <DesignSuite2023.CommonIcons.IconInfo fontSize="inherit" />
      </IconButton>
    )
  }

  return (
    <>
      <DesignSuite2023.Tooltip title="View claim source data">
        {/* wrapped in a div so tooltip can pass ref */}
        <div>{btnTrigger}</div>
      </DesignSuite2023.Tooltip>

      <React.Fragment>
        <DesignSuite2023.Drawer
          open={isOpen}
          onClose={() => setIsOpen(false)}
          width="70vw">
          <div style={{ padding: '1rem' }}>{displayComp}</div>
        </DesignSuite2023.Drawer>
      </React.Fragment>
    </>
  )

  // return null
}

function ViewSourceEDI({ data }: { data: payload }): React.ReactElement | null {
  const [hideEmptyFields, setHideEmptyFields] = useState(true)

  function _reduceObject(v: any): any {
    if (!hideEmptyFields) {
      return v
    }
    return removeEmptyObjFields(v)
  }

  const nonEmptyFieldsControl = (
    <FormControlLabel
      label={<small>Hide empty fields</small>}
      control={
        <Switch
          color="primary"
          size="small"
          checked={hideEmptyFields}
          key="hideEmptyFields"
          onChange={() => {
            setHideEmptyFields(!hideEmptyFields)
          }}
        />
      }
    />
  )

  if (!data) {
    return null
  }

  return (
    <StyledViewSource>
      <DesignSuite2023.GridLR
        left={<>{nonEmptyFieldsControl}</>}
        right={
          !!data.SourceFile?.ID && !!data.ZClaimSrcUUID ? (
            <>
              <span>
                File: {data.SourceFile?.FileKey?.split('/').slice(-1)}
              </span>
              <EDISourceViewer
                uuid={data.ZClaimSrcUUID}
                action={EDISourceViewerActionLoadClaim}
              />
            </>
          ) : null
        }
      />

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <DesignSuite2023.Section className="boxd">
            <h4>Subscriber Info</h4>
            <DisplayKeyValueData
              data={data.Observed.Subscriber}
              hideEmptyFields={hideEmptyFields}
            />
          </DesignSuite2023.Section>

          <DesignSuite2023.Section className="boxd">
            <h4>Service Facility</h4>
            <DisplayKeyValueData
              data={data.Observed.ServiceFacility}
              hideEmptyFields={hideEmptyFields}
            />
          </DesignSuite2023.Section>

          <DesignSuite2023.Section className="boxd">
            <h4>Referring Provider</h4>
            <DisplayKeyValueData
              data={data.Observed.ReferringProvider}
              hideEmptyFields={hideEmptyFields}
            />
          </DesignSuite2023.Section>
        </Grid>

        <Grid item xs={6}>
          <DesignSuite2023.Section className="boxd">
            <h4>Patient Info</h4>
            <DisplayKeyValueData
              data={data.Observed.Patient}
              hideEmptyFields={hideEmptyFields}
            />
          </DesignSuite2023.Section>

          <DesignSuite2023.Section className="boxd">
            <h4>Billing Provider</h4>
            <DisplayKeyValueData
              data={data.Observed.BillingProvider}
              hideEmptyFields={hideEmptyFields}
            />
          </DesignSuite2023.Section>

          <DesignSuite2023.Section className="boxd">
            <h4>Rendering Provider</h4>
            <DisplayKeyValueData
              data={data.Observed.RenderingProvider}
              hideEmptyFields={hideEmptyFields}
            />
          </DesignSuite2023.Section>
        </Grid>
      </Grid>

      <Grid container spacing={4} justify="space-between">
        <Grid item xs={6}>
          <h4>Core</h4>
          <DesignSuite2023.StyledPre>
            {JSON.stringify(_reduceObject(data.JSONSourceData?.Core), null, 2)}
          </DesignSuite2023.StyledPre>
          <h4>Claim</h4>
          <DesignSuite2023.StyledPre>
            {JSON.stringify(
              _reduceObject(_omit(data.JSONSourceData?.Claim, 'Lines')),
              null,
              2
            )}
          </DesignSuite2023.StyledPre>
        </Grid>

        <Grid item xs={6}>
          <h4>
            Lines (Count: {(data.JSONSourceData?.Claim?.Lines || []).length})
          </h4>
          {data.JSONSourceData?.Claim?.Lines?.map((l: any) => (
            <DesignSuite2023.StyledPre key={`${Math.random() * 10000}`}>
              {JSON.stringify(_reduceObject(l), null, 2)}
            </DesignSuite2023.StyledPre>
          ))}
        </Grid>
      </Grid>
    </StyledViewSource>
  )
}

function ViewSourceGoZero({
  data,
}: {
  data: payload
}): React.ReactElement | null {
  const [hideEmptyFields, setHideEmptyFields] = useState(true)

  const nonEmptyFieldsControl = (
    <FormControlLabel
      label={<small>Hide empty fields</small>}
      control={
        <Switch
          color="primary"
          size="small"
          checked={hideEmptyFields}
          key="hideEmptyFields"
          onChange={() => {
            setHideEmptyFields(!hideEmptyFields)
          }}
        />
      }
    />
  )

  if (!data) {
    return null
  }

  return (
    <StyledViewSource>
      <DesignSuite2023.GridLR
        left={<>{nonEmptyFieldsControl}</>}
        right={
          <>
            <Link
              target="_blank"
              rel="noreferrer"
              href={`http://go.zero.health/referral_invoice/${data.GoZeroSourceData.ReferralInvoiceID}`}>
              View Invoice
            </Link>
          </>
        }
      />

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <DesignSuite2023.Section className="boxd">
            <h4>Source Data</h4>
            <DisplayKeyValueData
              data={data.GoZeroSourceData}
              hideEmptyFields={hideEmptyFields}
            />
          </DesignSuite2023.Section>
        </Grid>
      </Grid>
    </StyledViewSource>
  )
}

function ViewSourceRxngo({
  data,
}: {
  data: payload
}): React.ReactElement | null {
  const [hideEmptyFields, setHideEmptyFields] = useState(true)

  const nonEmptyFieldsControl = (
    <FormControlLabel
      label={<small>Hide empty fields</small>}
      control={
        <Switch
          color="primary"
          size="small"
          checked={hideEmptyFields}
          key="hideEmptyFields"
          onChange={() => {
            setHideEmptyFields(!hideEmptyFields)
          }}
        />
      }
    />
  )

  if (!data) {
    return null
  }

  return (
    <StyledViewSource>
      <DesignSuite2023.GridLR
        left={<>{nonEmptyFieldsControl}</>}
        right={null}
      />

      <Grid container spacing={4}>
        <Grid item xs={6}>
          <DesignSuite2023.Section className="boxd">
            <h4>Rxngo Source Data</h4>
            <DisplayKeyValueData
              data={data.RxngoSourceData}
              hideEmptyFields={hideEmptyFields}
            />
          </DesignSuite2023.Section>
        </Grid>
      </Grid>
    </StyledViewSource>
  )
}

function ViewSourceVBA({ data }: { data: payload }): React.ReactElement | null {
  const [hideEmptyFields, setHideEmptyFields] = useState(true)

  const nonEmptyFieldsControl = (
    <FormControlLabel
      label={<small>Hide empty fields</small>}
      control={
        <Switch
          color="primary"
          size="small"
          checked={hideEmptyFields}
          key="hideEmptyFields"
          onChange={() => {
            setHideEmptyFields(!hideEmptyFields)
          }}
        />
      }
    />
  )

  if (!data) {
    return null
  }

  return (
    <StyledViewSource>
      <DesignSuite2023.GridLR
        left={<>{nonEmptyFieldsControl}</>}
        right={null}
      />

      <h4>Claim Data</h4>
      <DisplayKeyValueData
        data={data.SourceDataVBAClaim}
        hideEmptyFields={hideEmptyFields}
      />

      <h4>Claim *Lines* Info</h4>
      {(data.SourceDataVBALines || []).map((line: any) => (
        <div style={{ marginBottom: '1rem' }}>
          <DisplayKeyValueData data={line} hideEmptyFields={hideEmptyFields} />
        </div>
      ))}
    </StyledViewSource>
  )
}
