import React, { forwardRef } from 'react'
import * as api from '../../../services/thezerocard/api-helper'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import dateTime from '../../../utils/dateTime'

// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../../hooks/useStandardTableSetup'

const defaultColumns = Object.freeze({
  Name: { name: 'Name', details: { sortName: 'name' } },
  Handle: {
    name: 'Handle',
    details: {
      dataFormat(_: any, row: any): any {
        return <code>{row.Handle}</code>
      },
    },
  },
  Notes: { name: 'Notes', details: {} },
  PrivateKeyAutoGend: {
    name: 'PrivateKeySource',
    details: {
      dataFormat(_: any, row: any): string {
        return row.PrivateKeyAutoGend ? 'Auto-generated' : 'Custom'
      },
    },
  },
  CreatedAt: {
    name: 'Created',
    details: {
      width: 220,
      dataFormat: dateTime.cellFormatter(dateTime.formats.PrettyVerbose),
    },
  },
})

const defaultSort = { col: 'name', dir: 'asc' }

export const Table = forwardRef(function RecipientTableList(
  {
    customColumns = defaultColumns,
    apiEndpoint = apiEndpointFindRecipients,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
    },
    ref
  )

  return <>{TableDisplay}</>
})

function apiEndpointFindRecipients(payload: any) {
  return api.search('/engineering/sftpaas/recipient', payload)
}
