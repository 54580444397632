import * as api from '../services/thezerocard/api-helper'

export const getSesNotifications = ({ email }: any) => {
  return api.post('/notifications/ses', { Email: email })
}

export const getMemberProfile = (memberID: number) => {
  return api.get(`/member/${memberID}/profile`)
}

export const getUserProfileFromMemberID = (memberID: number) => {
  return api.get(`/get_user_by_member_id/${memberID}`)
}

export const getInspectMemberMyZERO = (memberID: number) => {
  return api.get(`/member/${memberID}/inspect_myzero`)
}

// @todo: unwind this function from redux; it's used in two places (MemberDetail.js
// and the refactored EditMemberDetails.tsx dialog), the latter of which skips redux
// and uses .payload promise directly, and MemberDetail.js has zero need for shared/centralized
// state of the member info
export const getMemberByID = (memberID: number) => {
  return api.get(`/member/${memberID}`)
}

export const postMemberEdit = (payload: any) => {
  return api.post('/member_edit', payload)
}

export const editEmployerMemberActivationDate = (payload: any) => {
  return api.put('/eligibility/activation_date', payload)
}

export const editEmployerMemberDeactivationDate = (payload: any) => {
  return api.put('/eligibility/deactivation_date', payload)
}

export const editEmployerMemberDivision = (payload: any) => {
  return api.put('/eligibility/division', payload)
}

export const editEmployerMemberSkipUpdateDate = (payload: any) => {
  return api.put('/eligibility/skip_update_until', payload)
}

export const editEmployerMemberSubsetHandle = (payload: any) => {
  return api.put('/eligibility/subset_handle', payload)
}

export const editEmployerMemberExcludeFromVBAExportReason = (payload: any) => {
  return api.put('/eligibility/exclude_from_vba_export', payload)
}

export const editEmployerMemberCarrierName = (payload: any) => {
  return api.put('/eligibility/carrier_name', payload)
}

export const editEmployerMemberPlanName = (payload: any) => {
  return api.put('/eligibility/plan_name', payload)
}

export const editEmployerMemberProblematic = (payload: any) => {
  return api.put('/eligibility/problematic_flag', payload)
}
