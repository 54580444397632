import React, { useState, useEffect, forwardRef } from 'react'
import DesignSuite2023 from '../../components/DesignSuite2023'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, {
  SnackbarTypeSuccess,
  SnackbarTypeWarning,
  SnackbarTypeError,
} from '../../hooks/useSnackbar'
import {
  getBillingEntityByID,
  createBillingEntity,
  saveBillingEntity,
} from '../../actions/BillingEntityActions'
import {
  renderTextField as RenderTextField,
  renderNotesField as RenderNotesField,
  renderSwitchField as RenderSwitchField,
  SetterArgs,
} from '../ViewHelpers'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
import { OrganizationSearcherV2 } from '../../components/Searchers'
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
} from '@material-ui/core'
import { ShapeBillingEntity, BillingEntityCreationSource } from './types'

export interface props {
  organizationID?: number | null
  creationSource?: BillingEntityCreationSource
  onSave?(be: ShapeBillingEntity): void
  readOnly?: boolean
  showToggleIsContracted?: boolean
}

export type PartialBillingEntity = Omit<
  ShapeBillingEntity,
  | 'CreatedAt'
  | 'UUID'
  // | 'IsRetired'
  // | 'IsContracted'
  // | 'CreationSource'
  | 'IsLatest'
  | 'ModifiedByUserID'
>

const emptyDefaults = (merge?: any): PartialBillingEntity => ({
  ID: 0,
  Name: '',
  TIN: '',
  NPI: '',
  OrganizationID: null,
  Notes: '',
  DateStart: null,
  DateEnd: null,
  IsContracted: true, // defaults to true
  CreationSource: null, // defaults to 'manual'
  OriginatingSrcHash: null,
  RemittanceAddressID: null,
  RemittanceAddress: {
    ID: 0,
    Address1: '',
    Address2: '',
    City: '',
    State: '',
    Zip: '',
  },
  ...(merge || {}),
})

export default forwardRef(function DisplayBillingEntity(
  {
    organizationID,
    onSave,
    readOnly,
    creationSource = BillingEntityCreationSource.Manual,
    showToggleIsContracted = false,
  }: props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [record, setRecord] = useState<
    PartialBillingEntity | ShapeBillingEntity
  >(
    emptyDefaults({
      OrganizationID: organizationID,
      CreationSource: creationSource,
    })
  )
  const [validations, setValidations] = useState<any>({})
  const [isLoading, setIsLoading] = useState(false)
  const [showDebug, setShowDebug] = useState(false)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const refOrgPicker = React.useRef<any>(null)
  const canSave = Object.keys(validations).length === 0
  const isReadOnly = readOnly || record?.IsRetired === true

  React.useImperativeHandle(
    ref,
    () => ({
      openEmpty,
      viewBillingEntityID,
    }),
    []
  )

  useEffect(() => {
    if (!isOpen) {
      setRecord(emptyDefaults())
      setValidations({})
      setIsLoading(false)
      setShowDebug(false)
      return
    }
  }, [isOpen])

  useEffect(() => {
    const sets = {} as any
    if (!!organizationID && record.OrganizationID !== organizationID) {
      sets.OrganizationID = organizationID
    }
    if (!!creationSource && record.CreationSource !== creationSource) {
      sets.CreationSource = creationSource
    }
    if (Object.keys(sets).length > 0) {
      setRecord((curr: any) => ({ ...curr, ...sets }) as PartialBillingEntity)
    }
  }, [record, organizationID, creationSource])

  useEffect(() => {
    const vldtns = {} as any
    if (!record.Name) {
      vldtns.Name = {
        error: true,
        helperText: 'Name is required',
        FormHelperTextProps: { error: true },
      }
    }
    if (!!record.NPI && (record.NPI || '').length !== 10) {
      vldtns.NPI = {
        error: true,
        helperText: 'Must be 10 digits',
        FormHelperTextProps: { error: true },
      }
    }
    if ((record.TIN || '').length !== 9) {
      vldtns.TIN = {
        error: true,
        helperText: 'Must be 9 digits',
        FormHelperTextProps: { error: true },
      }
    }
    setValidations(vldtns)
  }, [record.Name, record.NPI, record.TIN])

  function setter({ name, value }: SetterArgs) {
    setRecord((curr: PartialBillingEntity) => ({ ...curr, [name]: value }))
  }

  function setterRemittanceAddress({ name, value }: SetterArgs) {
    setRecord((curr: PartialBillingEntity) => {
      if (!curr.RemittanceAddress) {
        curr.RemittanceAddress = {} as any
      }
      curr.RemittanceAddress![name] = value
      return { ...curr }
    })
  }

  function openEmpty(populate?: PartialBillingEntity) {
    setRecord({ ...emptyDefaults({ ...populate }) })
    setIsOpen(true)
  }

  function viewBillingEntityID(id: number) {
    setIsLoading(true)
    setIsOpen(true)

    getBillingEntityByID(id)
      .then((res: any) => {
        if (res.error) throw res
        setRecord(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading Billing Entity',
        })
      )
      .finally(() => setIsLoading(false))
  }

  function doSave() {
    if (!canSave) {
      // insurance really; Save button should be disabled if not valid
      showSnackbar(
        'Validation errors must be fixed prior to saving',
        SnackbarTypeWarning
      )
      return
    }

    const payload = { ...record }
    const isNew = !record.ID
    if (isNew) {
      if (payload.IsContracted && !payload.OrganizationID) {
        showSnackbar(
          'Cannot create new Billing Entity without an Organization ID.',
          SnackbarTypeError
        )
        return
      }
      if (!payload.IsContracted && !!payload.OrganizationID) {
        showSnackbar(
          'Cannot create new Billing Entity with an Organization ID when IsContracted=false.',
          SnackbarTypeError
        )
        return
      }

      createBillingEntity(payload)
        .then((res: any) => {
          if (res.error) throw res
          showSnackbar('New Billing Entity created OK', SnackbarTypeSuccess)
          setIsOpen(false)
          onSave?.(res.Data as ShapeBillingEntity)
        })
        .catch(
          catchAPIError({
            defaultMessage: 'Failed creating new Billing Entity',
          })
        )

      return
    }

    saveBillingEntity(payload)
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar('Billing Entity saved OK', SnackbarTypeSuccess)
        setIsOpen(false)
        onSave?.(res.Data as ShapeBillingEntity)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed saving Billing Entity',
        })
      )
  }

  const spreadInputProps = { use2023Styles: true, opts: { margin: 'dense' } }

  return (
    <>
      <Dialog open={isOpen} maxWidth="md" onClose={() => setIsOpen(false)}>
        <DialogTitle
          disableTypography
          style={{
            background: '#f1f1f1',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          <Typography variant="h6">
            {isReadOnly ? 'View' : !!record.ID ? 'Edit' : 'Add'} Billing Entity
          </Typography>
          <div>
            <IconButton
              size="medium"
              onClick={() => setIsOpen(false)}
              style={{ padding: 5 }}>
              <DesignSuite2023.CommonIcons.IconCancel fontSize="inherit" />
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent style={{ width: 650 }}>
          {isLoading ? (
            <div style={{ textAlign: 'center' }}>
              <DesignSuite2023.LoadingSpinner />
            </div>
          ) : (
            <>
              {record.IsContracted === false && (
                <DesignSuite2023.AlertWarning style={{ marginBottom: '1rem' }}>
                  This is an uncontracted Billing Entity; indicating ZERO
                  received a claim from a provider we do not have a contractual
                  relationship with.
                </DesignSuite2023.AlertWarning>
              )}
              {isReadOnly && (
                <DesignSuite2023.AlertWarning style={{ marginBottom: '1rem' }}>
                  This Billing Entity is read-only (new versions cannot be
                  created).
                </DesignSuite2023.AlertWarning>
              )}

              <fieldset disabled={isReadOnly}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <DesignSuite2023.Tooltip title="Name is visible to providers in GoZERO.">
                      <div>
                        <RenderTextField
                          name="Name"
                          label="Name"
                          value={record.Name}
                          setter={setter}
                          use2023Styles
                          opts={{
                            margin: 'dense',
                            ...validations.Name,
                          }}
                        />
                      </div>
                    </DesignSuite2023.Tooltip>
                  </Grid>
                  <Grid item xs={3}>
                    <DesignSuite2023.Tooltip
                      title={
                        <span style={{ maxWidth: 380, display: 'block' }}>
                          Tax Identification Number is fixed after creation and
                          cannot be updated. To setup a new TIN, create a new
                          Billing Entity.
                        </span>
                      }>
                      <div>
                        <RenderTextField
                          name="TIN"
                          label="TIN"
                          value={record.TIN}
                          setter={setter}
                          disabled={!!record.ID}
                          use2023Styles
                          opts={{
                            margin: 'dense',
                            ...validations.TIN,
                          }}
                        />
                      </div>
                    </DesignSuite2023.Tooltip>
                  </Grid>
                  <Grid item xs={3}>
                    <RenderTextField
                      name="NPI"
                      label="NPI"
                      value={record.NPI}
                      setter={setter}
                      use2023Styles
                      opts={{
                        margin: 'dense',
                        ...validations.NPI,
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <ManagedDateInput
                      name="DateStart"
                      label="Valid From"
                      value={record.DateStart}
                      setter={setter}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ManagedDateInput
                      name="DateEnd"
                      label="Valid Thru"
                      value={record.DateEnd}
                      setter={setter}
                    />
                  </Grid>
                </Grid>

                {/* If organizationID is not passed as a prop (ie. organization association is not
                forced), then display the picker. */}
                {!organizationID && (
                  <>
                    <DesignSuite2023.Divider />
                    <OrganizationSearcherV2
                      ref={refOrgPicker}
                      selectedOrganizationID={record.OrganizationID}
                      onChange={(id: number | null) => {
                        setter({ name: 'OrganizationID', value: id })
                      }}
                      TextFieldProps={{
                        variant: 'outlined',
                        size: 'small',
                        fullWidth: true,
                        InputLabelProps: { shrink: true },
                        ...validations.OrganizationID,
                        // style: { minWidth: 250, maxWidth: 280 },
                      }}
                      AutoCompleteProps={{
                        style: { display: 'inline-block' /*, maxWidth: 280 */ },
                      }}
                      disabled={
                        isReadOnly ||
                        record.ID > 0 ||
                        record.IsContracted === false
                      }
                    />
                    {record.ID > 0 && (
                      <Typography variant="caption" color="textSecondary">
                        Organization cannot be edited
                      </Typography>
                    )}
                    {!record.ID && !!showToggleIsContracted && (
                      <div
                        style={{ textAlign: 'center', paddingTop: '0.5rem' }}>
                        <RenderSwitchField
                          name="IsContracted"
                          label="Is Contracted"
                          value={record.IsContracted}
                          setter={({ name, value }: SetterArgs) => {
                            setter({ name, value })
                            if (!value) {
                              refOrgPicker.current?.doClear()
                            }
                          }}
                          opts={{
                            size: 'small',
                          }}
                        />
                      </div>
                    )}
                  </>
                )}

                <DesignSuite2023.Divider />

                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <RenderTextField
                      name="Address1"
                      label="Address1"
                      value={record.RemittanceAddress.Address1}
                      setter={setterRemittanceAddress}
                      {...spreadInputProps}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <RenderTextField
                      name="Address2"
                      label="Address2"
                      value={record.RemittanceAddress.Address2}
                      setter={setterRemittanceAddress}
                      {...spreadInputProps}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <RenderTextField
                      name="City"
                      label="City"
                      value={record.RemittanceAddress.City}
                      setter={setterRemittanceAddress}
                      {...spreadInputProps}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <RenderTextField
                      name="State"
                      label="State"
                      value={record.RemittanceAddress.State}
                      setter={setterRemittanceAddress}
                      {...spreadInputProps}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <RenderTextField
                      name="Zip"
                      label="Zip"
                      value={record.RemittanceAddress.Zip}
                      setter={setterRemittanceAddress}
                      {...spreadInputProps}
                    />
                  </Grid>
                </Grid>

                <DesignSuite2023.Divider />

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <RenderNotesField
                      name="Notes"
                      label="Notes"
                      value={record.Notes}
                      setter={setter}
                      {...spreadInputProps}
                    />
                  </Grid>
                </Grid>
              </fieldset>

              <div style={{ textAlign: 'right', opacity: '0.75' }}>
                <small onClick={() => setShowDebug(!showDebug)}>Debug</small>
              </div>
              {showDebug && (
                <DesignSuite2023.StyledPre>
                  {JSON.stringify(record, null, '  ')}
                </DesignSuite2023.StyledPre>
              )}
            </>
          )}
        </DialogContent>

        {!isReadOnly && (
          <DialogActions>
            <DesignSuite2023.GridLR
              left={
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={() => {
                    setIsOpen(false)
                  }}>
                  Cancel
                </Button>
              }
              right={
                <>
                  <Button
                    disabled={!canSave}
                    color="primary"
                    variant="outlined"
                    onClick={doSave}>
                    Save
                  </Button>
                </>
              }
            />
          </DialogActions>
        )}
      </Dialog>
    </>
  )
})
