import React, { useEffect, useState, useCallback } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { PartnerActions, SnackbarActions } from '../../actions'
import debounce from 'lodash/debounce'
import './styles.scss'
const { getPartners, getPartnerById } = PartnerActions
const { setSnackbarMessage } = SnackbarActions

interface DataItem {
  ID: number
  Name: string
}

interface Props {
  selectedPartnerID: number | null
  onChange(partnerID: number | null): void
  setSnackbarMessage(message: string, level: string): void
  AutoCompleteProps?: object
  TextFieldProps?: object
  disableClear?: boolean
}

const PartnerSearcher = ({
  selectedPartnerID,
  onChange,
  setSnackbarMessage,
  AutoCompleteProps = {},
  TextFieldProps = {},
  disableClear = false,
  ...props
}: Props) => {
  const [query, setQuery] = useState<string>('')
  const [data, setData] = useState<Array<DataItem>>(Array<DataItem>())
  const [active, setActive] = useState<DataItem | null>(null)

  const showGenericErr = useCallback(() => {
    setSnackbarMessage(
      'Uh oh, something went awry searching for partners. Try again later.',
      'error'
    )
  }, [setSnackbarMessage])

  useEffect(() => {
    if (!selectedPartnerID) {
      return
    }
    if (active && active.ID === selectedPartnerID) {
      return
    }
    const req = getPartnerById(selectedPartnerID)
    if (req && req.payload) {
      req.payload
        .then((r: any) => {
          if (r.error) {
            return showGenericErr()
          }
          setActive({ ID: r.Data.ID, Name: r.Data.Name })
        })
        .catch(showGenericErr)
    }
  }, [selectedPartnerID, active, showGenericErr])

  useEffect(() => {
    if (query.length < 3) {
      return
    }
    const req = getPartners({ filter: { q: query } })
    if (req && req.payload) {
      req.payload
        .then((r: any) => {
          if (r.error) {
            return showGenericErr()
          }
          setData(
            r.Data.map((p: any) => {
              return { ID: p.ID, Name: p.Name }
            })
          )
        })
        .catch(showGenericErr)
    }
  }, [query, showGenericErr])

  const onInputChange = debounce((ev: any, q: string, r: string) => {
    setQuery(q)
  }, 350)

  const onSelectionChange = (_: any, b: DataItem | null, reason: string) => {
    setActive(b)
    onChange(b ? b.ID : null)
  }

  return (
    <Autocomplete
      classes={{ root: 'autocomplete-root' }}
      options={data}
      getOptionLabel={(opt: DataItem) => opt.Name}
      getOptionSelected={(opt: DataItem) => {
        return active ? opt.ID === active.ID : false
      }}
      defaultValue={null}
      value={active}
      disableClearable={disableClear}
      onChange={onSelectionChange}
      onInputChange={onInputChange}
      renderInput={(params: any) => (
        <TextField
          {...params}
          label="Partner"
          placeholder="Start typing to search partners..."
          {...TextFieldProps}
        />
      )}
      {...AutoCompleteProps}
    />
  )
}

export default connect(
  (state: any) => {
    return {}
  },
  (dispatch: any) => {
    return bindActionCreators({ setSnackbarMessage }, dispatch)
  }
)(PartnerSearcher)
