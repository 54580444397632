import * as api from '../services/thezerocard/api-helper'
export const FIND_GROUPS = 'FIND_GROUPS'
export const GET_GROUP_BY_ID = 'GET_GROUP_BY_ID'
export const SAVE_GROUP = 'SAVE_GROUP'
export const SAVE_NEW_GROUP = 'SAVE_NEW_GROUP'
export const RESET_GROUP_STATE = 'RESET_GROUP_STATE'
export const GET_NEW_GROUP = 'GET_NEW_GROUP'
export const FIND_GROUP_ORGANIZATIONS = 'FIND_GROUP_ORGANIZATIONS'
export const FIND_GROUP_FACILITIES = 'FIND_GROUP_FACILITIES'
export const FIND_GROUP_USERS = 'FIND_GROUP_USERS'
export const FIND_GROUP_BUNDLERS = 'FIND_GROUP_BUNDLERS'
export const FIND_GROUP_EMPLOYERS = 'FIND_GROUP_EMPLOYERS'
export const SAVE_USER_GROUPS = 'SAVE_USER_GROUPS'
export const SAVE_GROUP_USERS = 'SAVE_GROUP_USERS'
export const SAVE_GROUP_ENTITIES = 'SAVE_GROUP_ENTITIES'
export const CLEAR_BUNDLERS = 'CLEAR_BUNDLERS'

export const findGroups = (params) => {
  return {
    type: FIND_GROUPS,
    payload: api.search('/access_group', params),
  }
}

export const getNewGroup = (params) => {
  return {
    type: GET_NEW_GROUP,
    payload: {},
  }
}

export const getGroup = (params) => {
  return {
    type: GET_GROUP_BY_ID,
    payload: api.get(`/access_group/${params.groupId}`),
  }
}

export const saveGroup = (groupId, body) => {
  return {
    type: SAVE_GROUP,
    payload: api.put(`/access_group/${groupId}`, body),
  }
}

export const saveNewGroup = (body) => {
  return {
    type: SAVE_NEW_GROUP,
    payload: api.post('/access_group', body),
  }
}

export const resetGroupState = () => {
  return { type: RESET_GROUP_STATE }
}

export const clearBundlers = () => {
  return { type: CLEAR_BUNDLERS }
}

export const findGroupUsers = (params) => {
  return {
    type: FIND_GROUP_USERS,
    payload: api.search('/users', params),
  }
}

export const findGroupOrganizations = (params) => {
  return {
    type: FIND_GROUP_ORGANIZATIONS,
    payload: api.search('/organization', params),
  }
}

// todo: might need to scope this on org, dbl check
export const findGroupFacilities = (params) => {
  return {
    type: FIND_GROUP_FACILITIES,
    payload: api.search('/practice_facility', params),
  }
}

export const findGroupEmployers = (params) => {
  return {
    type: FIND_GROUP_EMPLOYERS,
    payload: api.search('/employer', params),
  }
}

export const findGroupBundlers = (params) => {
  return {
    type: FIND_GROUP_BUNDLERS,
    /* TODO: remove when we hookup bundlers */
    payload: api.get(`/access_group/${params.groupId}/bundlers`),
    // api.search('/bundler', params)
  }
}

export const saveGroupUsers = (params) => {
  return {
    type: SAVE_GROUP_USERS,
    payload: api.put(`/access_group/${params.groupId}/users`, {
      addedUserIDs: getIds(params.added),
      removedUserIDs: getIds(params.removed),
    }),
  }
}

export const saveUserGroups = (params) => {
  return {
    type: SAVE_USER_GROUPS,
    payload: api.put(`/user/${params.userId}/access_groups`, {
      addedGroupIDs: getIds(params.added),
      removedGroupIDs: getIds(params.removed),
    }),
  }
}

export const saveGroupEntities = (params) => {
  const { groupId, added, removed } = params
  return {
    type: SAVE_GROUP_ENTITIES,
    payload: api.put(`/access_group/${groupId}/entities`, {
      added,
      removed,
    }),
  }
}

function getIds(collection) {
  if (!collection || collection.length === 0) return []
  return collection.map((item) => {
    return item.ID
  })
}

export const findGroupEmployersV2 = (params) => {
  return api.search('/employer', params)
}
export const getGroupByID = (id) => {
  return api.get(`/access_group/${id}`)
}
