import React from 'react'
import * as api from '../../services/thezerocard/api-helper'
import GoZeroUserList from '../../components/GoZeroUsers/List'

export default function ReferrerGoZeroUsers({ referrerID }: any): any {
  function fnSearchUsersToAdd(params: any): Promise<any> {
    return api.get(`/referrer/${referrerID}/gozero/user_search`, null, params)
  }

  function fnGetUsers(): Promise<any> {
    return api.get(`/referrer/${referrerID}/gozero/users`)
  }

  function fnFnGetAllGoZeroUserIDs(): Promise<any> {
    return api.get(`/referrer/${referrerID}/gozero/all_user_ids`)
  }

  function fnPutToggleAdmin(userID: number, to: boolean): Promise<any> {
    return api.put(`/referrer/${referrerID}/gozero/admin_user/${userID}`, {
      isAdmin: to,
    })
  }

  function fnPostSendPasswordResetEmail(userID: number): Promise<any> {
    return api.post(`/referrer/${referrerID}/gozero/change_password_email`, {
      UserID: userID,
    })
  }

  function fnPutGoZeroUsers(payload: any): Promise<any> {
    return api.put(`/referrer/${referrerID}/gozero/users`, payload)
  }

  function fnCreateGoZeroUser(body: any): Promise<any> {
    return api.post(`/referrer/${referrerID}/gozero/user`, body)
  }

  function fnDeleteGoZeroUsers(payload: any): Promise<any> {
    return api.del(`/referrer/${referrerID}/gozero/users`, payload)
  }

  return (
    <GoZeroUserList
      fnSearchUsersToAdd={fnSearchUsersToAdd}
      fnGetUsers={fnGetUsers}
      fnGetAllGoZeroUserIDs={fnFnGetAllGoZeroUserIDs}
      fnPutToggleAdmin={fnPutToggleAdmin}
      fnPostSendPasswordResetEmail={fnPostSendPasswordResetEmail}
      fnPutGoZeroUsers={fnPutGoZeroUsers}
      fnCreateGoZeroUser={fnCreateGoZeroUser}
      fnDeleteGoZeroUsers={fnDeleteGoZeroUsers}
    />
  )
}
