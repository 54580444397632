import * as api from '../services/thezerocard/api-helper'

export const SEARCH_FOR_GROUPS = 'SEARCH_FOR_GROUPS'
export const GET_GROUPS_TO_REVIEW = 'GET_GROUPS_TO_REVIEW'
export const GET_LINES_FOR_PATIENT = 'GET_LINES_FOR_PATIENT'
export const GET_GROUPS_FOR_PATIENT = 'GET_GROUPS_FOR_PATIENT'
export const SAVE_GROUP_SUMMARY = 'SAVE_GROUP_SUMMARY'
export const SAVE_GROUP_LINES = 'SAVE_GROUP_LINES'
export const GET_SHOW_GROUP_OPTIONS = 'GET_SHOW_GROUP_OPTIONS'
export const GET_LIMIT_OPTIONS = 'GET_LIMIT_OPTIONS'
export const GET_SUMMARY = 'GET_SUMMARY'
export const GET_SUMMARY_CLAIM_LINES = 'GET_SUMMARY_CLAIM_LINES'
export const GET_EMPLOYER_FOR_FILE_CLAIMS = 'GET_EMPLOYER_FOR_FILE_CLAIMS'
export const CLEAR_SAVE_RESULT = 'CLEAR_SAVE_RESULT'
export const CLEAR_SELECTED_SUMMARY = 'CLEAR_SELECTED_SUMMARY'

export function getShowGroupOptions() {
  return {
    type: GET_SHOW_GROUP_OPTIONS,
  }
}

export function getLimitOptions() {
  return {
    type: GET_LIMIT_OPTIONS,
  }
}

export function getSummary(summaryId) {
  return {
    type: GET_SUMMARY,
    payload: api.get(`/prospect_summary/${summaryId}`),
  }
}

export function getEmployerForFileClaims(fileClaimsId) {
  return {
    type: GET_EMPLOYER_FOR_FILE_CLAIMS,
    payload: api.get(`/opp_analysis/file_claims/${fileClaimsId}/employer`),
  }
}

export function getGroupsToReview(params) {
  const { showGroup, fileClaimsId, costKey, groupNr, sort, limit } = params
  return {
    type: GET_GROUPS_TO_REVIEW,
    payload: api.get('/prospect_summary', {
      show_group: showGroup || null,
      file_claims_id: fileClaimsId || null,
      cost_key: costKey || null,
      group_nr: groupNr || null,
      sort: sort || null,
      limit: limit || null,
    }),
  }
}

export function getSummaryClaimLines(params) {
  return {
    type: GET_SUMMARY_CLAIM_LINES,
    payload: api.get('/prospect_line', params),
  }
}

export function saveGroupSummary(group) {
  return {
    type: SAVE_GROUP_SUMMARY,
    payload: api.put(`/prospect_summary/${group.ID}`, group),
  }
}

export function saveGroupLines(lines) {
  return {
    type: SAVE_GROUP_LINES,
    payload: api.patch('/prospect_line', lines),
  }
}

export function clearSelectedSummary() {
  return {
    type: CLEAR_SELECTED_SUMMARY,
  }
}

export function clearSaveResult() {
  return {
    type: CLEAR_SAVE_RESULT,
  }
}

export function getBundleV2RulesByCostKey(CostKey /* string */) {
  return api.get('/opp_analysis/bundle_v2_rules_by_costkey', null, {
    CostKey,
  })
}

export function queueOpportunityAnalysisTask(payload) {
  return api.post('/task_opp_analysis', payload).then((res) => {
    if (res.error) {
      throw res
    }
    return res
  })
}

export function deleteFileClaim(fileClaimID) {
  return api.del(`/opp_analysis/file_claims/${fileClaimID}`).then((res) => {
    if (res.error) {
      throw res
    }
    return res
  })
}
