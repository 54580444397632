import React from 'react'
import { Search as IconSearch } from '@material-ui/icons'
import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@material-ui/core'
import NumberFormat from 'react-number-format'
import * as masks from './masks'

export function CustomControlledInput({
  name,
  label,
  value,
  onChange,
  maskInput,
  error,
  helperText,
  showHelper,
  className,
  margin,
  FormControlProps,
  variant,
  size,
  ...other
}: any) {
  const { disabled, shrink, ...remaining } = other
  let InputComponent = variant === 'outlined' ? OutlinedInput : Input
  return (
    <FormControl
      {...FormControlProps}
      className={className}
      error={error}
      margin={margin || 'normal'}
      variant={variant}
      size={size}>
      <InputLabel disabled={disabled} shrink={disabled || shrink}>
        {label}
      </InputLabel>
      <InputComponent
        {...remaining}
        name={name}
        value={value}
        onChange={onChange}
        inputComponent={maskInput || 'input'} // https://v4.mui.com/api/input/#input-api
        disabled={disabled}
        label={label}
      />
      {(error || showHelper) && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

export const PhoneInput = (props: any) => (
  <CustomControlledInput maskInput={masks.PhoneNumberMask} {...props} />
)
export const PhoneExtInput = (props: any) => (
  <CustomControlledInput maskInput={masks.PhoneExtMask} {...props} />
)
export const SSNInput = (props: any) => (
  <CustomControlledInput maskInput={masks.SSNMask} {...props} />
)
export const MonthDayInput = (props: any) => (
  <CustomControlledInput
    maskInput={masks.MonthDayMask}
    {...props}
    style={{ width: 250 }}
  />
)

export const PercentageInput = (props: any) => (
  <CustomControlledInput
    maskInput={masks.PercentageMask}
    endAdornment={<InputAdornment position="end">%</InputAdornment>}
    {...props}
  />
)

export const PriceInput = ({ name, inputRef, onChange, ...spread }: any) => (
  <NumberFormat
    {...spread}
    getInputRef={inputRef}
    decimalScale={2}
    thousandSeparator
    prefix="$"
    onValueChange={(values: any) => {
      onChange({
        target: {
          value: values.value,
          name: name,
        },
      })
    }}
  />
)

export const FormTextField = ({ fieldName, errors, form, ...spread }: any) => {
  const opts = { ...spread }
  if (errors && errors[fieldName]) {
    opts.error = true
    opts.helperText = errors[fieldName]
  }
  return (
    <TextField
      fullWidth
      margin="normal"
      size="small"
      value={form.data[fieldName]}
      onChange={(ev: any) => {
        form.setters[fieldName](ev.target.value)
      }}
      {...opts}
    />
  )
}

// @todo: used by two places only; consider this deprecated
export const Searchbox = ({ value, ...spread }: any) => (
  <TextField
    autoFocus
    placeholder="Start typing to search"
    size="small"
    variant="outlined"
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <IconSearch />
        </InputAdornment>
      ),
    }}
    {...spread}
  />
)
