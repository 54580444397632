import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  CircularProgress,
} from '@material-ui/core'
import {
  getQuikTripProviderReportsLastSent,
  postQuikTripProviderReportsSend,
} from '../../actions/EngineeringActions'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import styled from 'styled-components'
import DesignSuite2023 from '../../components/DesignSuite2023'

interface option {
  OrganizationID: number
  Label: string
  DateFormat: string
}

interface data {
  FileTypeID: number
  Options: Array<option>
}

interface getLastSentData {
  error?: boolean
  Error?: Array<string>
  Data?: data
}

export default function SendQuikTripReports(): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsWorking] = useState(true)
  const { catchAPIError } = useErrorHandlers()
  const { show: showSnackbar } = useSnackbar()
  const [data, setData] = useState<data | null>(null)

  useEffect(() => {
    if (!isOpen) {
      return
    }
    setData(null)
    setIsWorking(true)
    getConfig()
  }, [isOpen])

  function getConfig() {
    setIsWorking(true)
    getQuikTripProviderReportsLastSent()
      .then((res: getLastSentData & any) => {
        if (res.error) {
          throw res
        }
        setData(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed loading QuikTrip provider reports last sent info; please contact engineering',
        })
      )
      .finally(() => {
        setIsWorking(false)
      })
  }

  function doSend() {
    setIsWorking(true)
    postQuikTripProviderReportsSend()
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        showSnackbar(
          'QuikTrip provider reports processing OK!',
          SnackbarTypeSuccess
        )
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed QuikTrip provider reports processing',
        })
      )
      .finally(() => {
        setIsWorking(false)
        setIsOpen(false)
      })
  }

  return (
    <>
      <DesignSuite2023.Tooltip
        placement="right"
        title="Opens a dialog (does not immediately perform an action)">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsOpen(true)
          }}>
          Send QuikTrip Reports
        </Button>
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen}>
        {/* set the width here to a minimum so that the fileUpload display doesn't cause bouncing around */}
        <StyledDialogContent>
          {/*display getLastSentData */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h3>Send QuikTrip Provider Reports</h3>
              <h4>
                Note: due to all the background/network stuff, this API request
                could take a while. Please do not refresh while it's running.
              </h4>
              <div style={{ textAlign: 'center' }}>
                {isLoading && <CircularProgress />}
              </div>
              <h5>Last Sent:</h5>
              <pre>{JSON.stringify(data, null, 2)}</pre>
            </Grid>
          </Grid>
        </StyledDialogContent>

        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  setIsOpen(false)
                }}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button color="primary" variant="contained" onClick={doSend}>
                Send QuikTrip Reports
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

const StyledDialogContent = styled(DialogContent)`
  min-width: 560px;
`
