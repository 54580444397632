import React from 'react'
import { Button } from '@material-ui/core'
import { EmployerActions } from '../../../actions'
import { FileImportProps, useImportFile, useRefreshFiles } from './hookCommon'

export default function ({ employerID }: FileImportProps) {
  const {
    refFileInput,
    processing,
    errors,
    result,
    baseReset,
    onSelectFileHandler,
  } = useImportFile()
  const refreshFiles = useRefreshFiles(employerID)

  const onSelectFile = onSelectFileHandler({
    employerID,
    fnResetAll: baseReset,
    actioner(params: any) {
      return EmployerActions.importEmployerZipCodes(params).payload
    },
    withResults() {
      refreshFiles()
    },
  })

  return (
    <div style={{ maxWidth: '830px', margin: '0 auto', padding: '3rem 0' }}>
      <h4>Employer Zip Codes Import</h4>
      <p>
        Import a CSV containing a list of zip codes. <strong>Note:</strong> this
        import does not attempt to match members, or use any other information
        in the file, besides zip codes. It is used entirely for analysis, in
        attempting to determine the areas where many of the Employer's members
        may live. In other words: fit analysis (does this employer have members
        in areas we serve?).
      </p>

      <p>Select a CSV file that contains the required headers:</p>
      <ul style={{ marginTop: '-5px' }}>
        <li>
          <code>zip_code</code>
        </li>
      </ul>

      <p>Optional headers include:</p>
      <ul style={{ marginTop: '-5px' }}>
        <li>
          <code>grouper</code>: to allow filtering by a criteria of your
          choosing (e.g. <code>division</code>, <code>healthplan</code>,{' '}
          <code>retired</code>, <code>PPO/HSA/HDHP</code>, etc)
        </li>
        <li>
          <code>members_per_zip_code</code>: count of members per zip; if header
          present, row must contain a number; if header not present, default
          value of 1 is used
        </li>
      </ul>

      <p>Additional headers will be ignored.</p>

      <p>
        The file will automatically be uploaded and imported, and any issues
        will appear below.
      </p>

      {result && (
        <div>
          <hr />
          <p>
            <strong>File:</strong> {result.FileName} (ID: {result.FileID})<br />
            <strong>Total Lines:</strong> {result.TotalLinesRead}
            <br />
            <strong>Skipped (no zip code):</strong> {result.SkippedEmpty}
            <br />
            <strong>Skipped (invalid zip code):</strong> {result.Invalids}
            <br />
          </p>

          <hr />
          <Button
            variant="outlined"
            color="primary"
            onClick={baseReset.bind(null, false)}>
            Upload Another File
          </Button>
        </div>
      )}

      {errors && (
        <div>
          <hr />
          <h5>Unable to import file:</h5>
          {Array.isArray(errors)
            ? errors.map((msg) => {
                return <p key={msg}>{msg}</p>
              })
            : JSON.stringify(errors)}
          <small style={{ paddingBottom: '1rem', display: 'block' }}>
            You can reselect another file to try again.
          </small>
        </div>
      )}

      {!result && (
        <Button
          variant="contained"
          component="label"
          color="primary"
          fullWidth
          disabled={processing}>
          {processing ? 'Processing...' : 'Select File'}
          <input
            style={{ display: 'none' }}
            type="file"
            ref={refFileInput}
            onChange={onSelectFile}
            accept=".csv"
          />
        </Button>
      )}

      {processing && (
        <small style={{ padding: '1rem', display: 'block' }}>
          This may take a while. DO NOT REFRESH YOUR BROWSER.
        </small>
      )}
    </div>
  )
}
