import util from './utils'
import { UserActions, CLEAR_SAVE_RESULT } from '../actions'
const {
  FIND_USERS,
  GET_USER_BY_ID,
  SAVE_USER,
  SAVE_NEW_USER,
  RESET_USER_STATE,
  SEND_VERIFICATION_EMAIL,
  CLEAR_USER,
} = UserActions

export default function (state = null, action) {
  switch (action.type) {
    case FIND_USERS:
      if (action.payload.error) {
        return { ...state, searchError: util.errorResponse(action) }
      }
      return {
        ...state,
        users: action.payload.Data,
        count: action.payload.Meta.Total,
        searchError: null,
      }
    case GET_USER_BY_ID:
      if (action.payload.error) {
        return { ...state, getUserErr: util.errorResponse(action) }
      }
      return {
        ...state,
        user: action.payload.Data,
        getUserErr: null,
      }
    case SAVE_USER:
    case SAVE_NEW_USER:
      if (action.payload.error) {
        return {
          ...state,
          saveResult: util.errorResponse(action),
        }
      }
      return {
        ...state,
        user: action.payload.Data,
        saveResult: {
          saved: true,
          type: action.type,
        },
      }
    case CLEAR_SAVE_RESULT:
      return {
        ...state,
        saveResult: null,
      }
    case CLEAR_USER:
      return {
        ...state,
        user: {},
        saveResult: null,
        getUserErr: null,
      }
    case RESET_USER_STATE:
      return {
        ...state,
        users: [],
        saveResult: null,
        getUserErr: null,
      }
    case SEND_VERIFICATION_EMAIL:
      if (action.payload.error) {
        return {
          ...state,
          sentEmailError: util.errorResponse(action),
          sentEmail: false,
        }
      }

      return {
        ...state,
        sentEmail: true,
        sentEmailError: null,
      }
    default:
      return { ...state }
  }
}
