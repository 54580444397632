import React, { useState, useEffect } from 'react'
import * as api from '../../services/thezerocard/api-helper'
import DesignSuite2023 from '../../components/DesignSuite2023'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import ConfirmDialog from '../ConfirmDialog'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import { Button, IconButton, Typography } from '@material-ui/core'
import styled from 'styled-components'
import utils from '../../utils'

const StyledDisplay = styled.div`
  display: inline-flex;
  align-items: center;
`

const StyledTip = styled.div`
  text-align: center;

  button {
    margin: 0.5rem 0;
  }
`

interface props {
  email: string | null | undefined
  link?: boolean
}

export default function CheckEmailBlacklisted({
  email,
  link = false,
}: props): React.ReactElement | null {
  const [isBlacklisted, setIsBlacklisted] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (!email) return
    checkBlacklist(email)
  }, [email])

  function checkBlacklist(email: string) {
    setIsLoading(true)
    api
      .get(`/notifications/is_email_flagged`, null, { email })
      .then((res: any) => {
        if (res.error) throw res
        if (res?.Data?.IsFlagged) {
          setIsBlacklisted(true)
          return
        }
        setIsBlacklisted(false)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed looking up email in blacklist',
        })
      )
      .finally(() => setIsLoading(false))
  }

  function doClear() {
    ConfirmDialog({
      content: (
        <>
          <Typography
            variant="body1"
            color="textPrimary"
            style={{ marginBottom: '1rem' }}>
            Clearing this email ({email}) from the blacklist simply means ZERO
            won't view this email suspiciously anymore. But emails are finnicky
            and ZERO's system is only a part of the delivery process - this in
            no way ensures the user will receive future emails sent by, or
            routed through, ZERO (but it will likely help).
          </Typography>
          <Typography variant="caption" color="textSecondary">
            NOTE: this email <strong>may end up blacklisted again</strong> if
            the sending system detects issues.
          </Typography>
        </>
      ),
      onConfirm() {
        api
          .del(`/notifications/flagged_email`, { email })
          .then((res: any) => {
            if (res.error) throw res
            showSnackbar('Email cleared from blacklist', SnackbarTypeSuccess)
            // if successfully cleared, clear flagged recs
            setIsBlacklisted(false)
          })
          .catch(
            catchAPIError({
              defaultMessage: 'Failed clearing email from blacklist',
            })
          )
      },
    })
  }

  if (!email) return null

  return (
    <StyledDisplay>
      {isLoading && <DesignSuite2023.LoadingSpinner size={14} />}

      {isBlacklisted ? (
        <DesignSuite2023.Tooltip
          interactive
          title={
            <StyledTip>
              <strong>Email blacklisted!</strong> (our system will not send
              emails here).
              <br />
              <Button size="small" variant="contained" onClick={doClear}>
                Clear from blacklist
              </Button>
            </StyledTip>
          }>
          <IconButton size="small">
            <DesignSuite2023.CommonIcons.IconWarning
              fontSize="inherit"
              style={{ color: 'firebrick' }}
            />
          </IconButton>
        </DesignSuite2023.Tooltip>
      ) : (
        <DesignSuite2023.Tooltip
          title={
            <StyledTip>
              Email does not appear in ZERO's blacklist.
              <br />
              (does not guarantee recipient will receive emails)
            </StyledTip>
          }>
          <IconButton size="small">
            <DesignSuite2023.CommonIcons.IconCheck
              fontSize="inherit"
              style={{ color: 'gray' }}
            />
          </IconButton>
        </DesignSuite2023.Tooltip>
      )}

      {!!link ? utils.emailLinkFormatter(email) : <span>{email}</span>}
    </StyledDisplay>
  )
}
