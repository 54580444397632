/*
See comment at header of peer file (legacy.js). That component is too old to implement
the useSetFrameHeaderLeft hook, so we have to wrap that component and pass it in
*/
import React, { useState } from 'react'
import { useSetFrameHeaderLeft } from '../../../components/AppFrame'
import EmployerDetailView from './legacy'

export default function EmployerDetail(props: any): React.ReactElement {
  const [headerTitle, setHeaderTitle] = useState<any>('Employer Detail')

  useSetFrameHeaderLeft(
    () => <h1 className="page-title smaller">{headerTitle}</h1>,
    [headerTitle]
  )

  return <EmployerDetailView {...props} setHeaderTitle={setHeaderTitle} />
}
