import React, { forwardRef } from 'react'
import * as FeeScheduleTable from '../FeeSchedules/FeeScheduleTable2'
import { findPracticeFacilityFeeSchedules } from '../../actions/PracticeFacilityActions'

const FilterOrganization = FeeScheduleTable.FilterOrganization
const FilterSearch = FeeScheduleTable.StandardFilterSearch
const FilterStatus = FeeScheduleTable.FilterStatus

const columns = Object.freeze({
  ...FeeScheduleTable.defaultColumns,
  FeeScheduleRemovedOn: { name: 'Facility Removed On Date', details: {} },
})

interface props extends FeeScheduleTable.props {
  practiceFacilityID: number
}

const Table = forwardRef(function PracticeFacilityFeeSchedules(
  { practiceFacilityID, ...otherProps }: props & Partial<any>,
  ref: any
): React.ReactElement {
  function apiEndpoint(payload: any): Promise<any> {
    return findPracticeFacilityFeeSchedules(practiceFacilityID, payload)
  }

  return (
    <FeeScheduleTable.Table
      {...otherProps}
      ref={ref}
      customColumns={columns}
      apiEndpoint={apiEndpoint}
    />
  )
})

export default Table
export { Table, FilterOrganization, FilterSearch, FilterStatus }
