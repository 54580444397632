import React from 'react'
import PropTypes from 'prop-types'
import QSearcher from '../../components/QSearcher'
import { OrganizationSearcherV2 } from '../../components/Searchers'
import { withStyles } from '@material-ui/core/styles'
import {
  CircularProgress,
  Divider,
  Typography,
  Avatar,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemAvatar,
  InputAdornment,
} from '@material-ui/core'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank'
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked'
import { Search as IconSearch } from '@material-ui/icons'
import _ from 'lodash'

import { ApiActions } from '../../actions'
const { search } = ApiActions
const RENDER_LIMIT = 50

const styles = (theme) => {
  return {
    selected: {
      backgroundColor: 'green',
    },
    current: {
      backgroundColor: 'green',
    },
    pendingRemoval: {
      backgroundColor: 'red',
    },
    manageWindow: {
      minHeight: 485,
    },
    manageDialog: {},
    item: {
      cursor: 'pointer',
    },
  }
}

class pfManager extends React.Component {
  state = {
    orgSearchResults: [],
    facilitySearchResults: [],
    totalFacilities: 0,
    selectedFacilities: [],
    facilitiesPendingRemoval: [],
    currentFacilities: [],
    filter: '',
    orgId: null,
    orgName: null,
    orgActive: true,
  }

  UNSAFE_componentWillMount() {
    const { orgId } = this.props
    if (orgId) {
      this.setState({ orgId: this.props.orgId }, () => {
        this.doFindFacilities({ q: '' }).then((res) => {
          this.setState({
            facilitySearchResults: res.Data,
            totalFacilities: res.Meta.Total,
          })
        })
      })
    }
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps && nextProps.currentFacilities) {
      this.setState({ currentFacilities: nextProps.currentFacilities })
    }
  }

  doFindFacilities = ({ q }) => {
    const { orgId } = this.state
    if ((q.length === 0 || q.length > 2) && orgId !== null) {
      return search('/practice_facility', {
        filter: { q, organizationID: orgId },
        page: 1,
        pageSize: 50,
      })
    }

    return new Promise((resolve, reject) => {
      resolve({ Data: [] })
    })
  }

  handleOrgSelected = (orgID, org) => {
    const nextState = { orgId: null, orgName: null, orgActive: false }
    if (org) {
      nextState.orgId = orgID
      nextState.orgName = org.Name
      nextState.orgActive = org.IsActive
    }
    this.setState(nextState, () => {
      this.handleFacilitiesQuery('') // clear out the facilitySearchResults
    })
  }

  handleFacilitiesQuery = (q) => {
    this.doFindFacilities(Object.assign({ q }))
      .then((res) => {
        this.setState({ facilitySearchResults: res.Data })
      })
      .catch((err) => {
        this.props.onError('Unable to fetch facilities')
      })
  }

  handleCurrentFacilitiesQuery = (q) => {
    this.setState({
      currentFacilities: _.filter(this.props.currentFacilities, (pf) => {
        return pf.Name.toLowerCase().includes(q.toLowerCase())
      }),
      filter: q,
    })
  }

  toggleSelected = (ID, facility) => {
    return () => {
      const { selectedFacilities, facilitiesPendingRemoval } = this.state
      const { disableInactive, onUpdate } = this.props
      const selected = _.find(selectedFacilities, { ID })

      if (!selected) {
        /*
        @todo: SD-1336: this is a bare-minimum way of addressing launch/term dates
        w/ respect to facilities: if the facility is not currently active, it cannot
        be added to a fee schedule. HOWEVER - this is incomplete treatment of how we
        should be handling this: we *should* be checking fee schedule launch/term dates
        (when available) against the launch/term dates of the facility, and ensuring that
        a facility w/ an out-of-bounds launch/term date cannot be added.
        */
        if (!facility.IsActive && disableInactive) {
          window.alert(
            'This facility is currently inactive and cannot be added (check the launch/term dates of this facility).'
          )
          return
        }
        selectedFacilities.push(facility)
      } else {
        // mutates the existing group
        _.remove(selectedFacilities, { ID })
      }
      this.setState({ selectedFacilities })
      onUpdate({ selectedFacilities, facilitiesPendingRemoval })
      console.debug(
        'Selecting a facility: ',
        ID,
        facility,
        ', currently selected: ',
        selectedFacilities
      )
    }
  }

  toggleCurrent = (ID, facility) => {
    return () => {
      const { disableRemoval } = this.props
      if (disableRemoval) return null

      const { facilitiesPendingRemoval, selectedFacilities } = this.state

      const pendingRemoval = _.find(facilitiesPendingRemoval, { ID })

      if (!pendingRemoval) {
        facilitiesPendingRemoval.push(facility)
      } else {
        _.remove(facilitiesPendingRemoval, { ID })
      }
      this.setState({ facilitiesPendingRemoval })
      this.props.onUpdate({ selectedFacilities, facilitiesPendingRemoval })
      console.debug(
        'Toggling a current facility: ',
        ID,
        facility,
        ', all pending removal: ',
        facilitiesPendingRemoval
      )
    }
  }

  renderCurrentListItem = (pf, avatarClass) => {
    const { classes } = this.props

    return (
      <ListItemAvatar key={pf.ID}>
        <Avatar className={avatarClass}>
          <RadioButtonChecked className={classes.selected} />
        </Avatar>
        <ListItemText
          primary={pf.Name}
          secondary={
            'This Facility is currently active. Manage using "Current Facilities"'
          }
        />
      </ListItemAvatar>
    )
  }

  renderTooManyFacilities = (numFacilities) => {
    return (
      <div>
        <ListItem>
          <ListItemText
            primary={`${numFacilities} facilities currently selected`}
            secondary={
              'There are too many facilities to show here. Please use the search box to filter down to < 50'
            }
          />
        </ListItem>
      </div>
    )
  }

  renderResults = () => {
    const {
      facilitySearchResults,
      selectedFacilities,
      currentFacilities,
      totalFacilities,
    } = this.state
    const { classes } = this.props

    return (
      <List>
        {totalFacilities > 0 && (
          <ListSubheader
            style={{ 'background-color': 'white' }}
            component="div"
            id="nested-list-subheader">
            {facilitySearchResults.length} of {totalFacilities} facilities.
          </ListSubheader>
        )}
        {facilitySearchResults.map((facility) => {
          const selected =
            _.find(selectedFacilities, { ID: facility.ID }) !== undefined
          const current =
            _.find(currentFacilities, { ID: facility.ID }) !== undefined

          const avatarClass = selected
            ? classes.selected
            : current
              ? classes.current
              : classes.notSelected

          if (current) {
            return this.renderCurrentListItem(facility, avatarClass)
          }
          return (
            <ListItemAvatar
              key={facility.ID}
              button
              onClick={this.toggleSelected(facility.ID, facility)}>
              <Avatar className={avatarClass}>
                {selected ? (
                  <CheckCircleOutline className={classes.selected} />
                ) : (
                  <CheckBoxOutlineBlank className={classes.notSelected} />
                )}
              </Avatar>
              <ListItemText
                primary={facility.Name}
                secondary={facility.Address.DisplayAddress}
              />
            </ListItemAvatar>
          )
        })}
      </List>
    )
  }

  renderSelected = () => {
    const { selectedFacilities } = this.state
    const { classes } = this.props

    return (
      <div>
        <List>
          <ListItem>
            <ListItemText primary="Facilities to Add" />
          </ListItem>
          {selectedFacilities.map((facility) => {
            return (
              <ListItemAvatar
                key={facility.ID}
                button
                onClick={this.toggleSelected(facility.ID, facility)}>
                <Avatar className={classes.selected}>
                  <CheckCircleOutline className={classes.selected} />
                </Avatar>
                <ListItemText
                  primary={facility.Name}
                  secondary={facility.Address.DisplayAddress}
                />
              </ListItemAvatar>
            )
          })}
        </List>
      </div>
    )
  }

  renderCurrent = () => {
    const { facilitiesPendingRemoval, currentFacilities } = this.state
    const { classes, loading, disableRemoval } = this.props

    return (
      <div>
        {disableRemoval ? (
          <Typography>Removal of facilities is currently disabled.</Typography>
        ) : null}
        {loading ? <CircularProgress /> : null}
        {currentFacilities && currentFacilities.length > 0 && (
          <List>
            {currentFacilities.length >= RENDER_LIMIT
              ? this.renderTooManyFacilities(currentFacilities.length)
              : currentFacilities.map((facility) => {
                  const pendingRemoval = _.find(facilitiesPendingRemoval, {
                    ID: facility.ID,
                  })

                  const avatarClass = pendingRemoval
                    ? classes.pendingRemoval
                    : classes.current
                  return (
                    <ListItemAvatar
                      key={facility.ID}
                      button
                      onClick={this.toggleCurrent(facility.ID, facility)}>
                      <Avatar className={avatarClass}>
                        {pendingRemoval ? (
                          <CheckBoxOutlineBlank
                            className={classes.pendingRemoval}
                          />
                        ) : (
                          <CheckCircleOutline className={classes.current} />
                        )}
                      </Avatar>
                      <ListItemText
                        primary={facility.Name}
                        secondary={facility.Address.DisplayAddress}
                      />
                    </ListItemAvatar>
                  )
                })}
          </List>
        )}
      </div>
    )
  }

  render() {
    const { classes } = this.props
    const { orgId, orgName, orgActive } = this.state
    const pfDisabled = orgId === null

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          {!!orgName && (
            <>
              <small>Organization: </small>
              <h4>{orgName}</h4>
            </>
          )}
          {!orgId && (
            <OrganizationSearcherV2
              onChange={this.handleOrgSelected}
              TextFieldProps={{
                label: null,
                margin: 'normal',
                placeholder: 'Organizations',
                style: { minWidth: 200 },
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconSearch />
                    </InputAdornment>
                  ),
                },
              }}
            />
          )}
        </Grid>
        <Divider />
        {!orgActive && (
          <Grid item xs={12} sm={12}>
            <Typography>
              *** This organization is currently inactive ***
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <QSearcher
            search={this.handleFacilitiesQuery}
            className={classes.searchField}
            disabled={pfDisabled}
            placeholder="Facility"
          />
          <div>{this.renderResults()}</div>
        </Grid>
        <Grid item xs={6} sm={3}>
          <div>{this.renderSelected()}</div>
        </Grid>
        <Grid item xs={6} sm={3}>
          <QSearcher
            search={this.handleCurrentFacilitiesQuery}
            className={classes.searchField}
            disabled={pfDisabled}
            placeholder="Current Facilities"
          />
          <div>{this.renderCurrent()}</div>
        </Grid>
      </Grid>
    )
  }
}

pfManager.propTypes = {
  classes: PropTypes.object.isRequired,
  disableRemoval: PropTypes.bool,
}

pfManager.defaultProps = {
  disableRemoval: false,
}

export const PfManager = withStyles(styles)(pfManager)

class pfManagerDialog extends React.Component {
  state = {
    added: [],
    removed: [],
  }

  componentWillUnmount() {
    this.setState({
      added: [],
      remove: [],
    })
  }

  handleCancel = () => {
    this.setState({ added: [], removed: [] })
    this.props.onCancel()
  }

  handleConfirm = () => {
    const { added, removed } = this.state
    this.setState({ added: [], removed: [] })
    this.props.onConfirm({ added, removed })
  }

  trackChanges = ({ selectedFacilities, facilitiesPendingRemoval }) => {
    const added = selectedFacilities
    const removed = facilitiesPendingRemoval

    this.setState({ added, removed })
  }

  hasChanges = () => {
    const { added, removed } = this.state

    return !(added.length === 0 && removed.length === 0)
  }

  renderActionText = () => {
    const { added, removed } = this.state
    let addedText = ''
    let removedText = ''
    let separator = ''

    if (added.length !== 0) {
      addedText = `Add ${added.length} ${added.length > 1 ? 'facilities' : 'facility'}`
    }
    if (removed.length !== 0) {
      removedText = `Remove ${removed.length} ${removed.length > 1 ? 'facilities' : 'facility'}`
    }
    if (added.length !== 0 && removed.length !== 0) {
      separator = ', '
    }

    return (
      <span>
        {addedText}
        {separator}
        {removedText}
      </span>
    )
  }

  render = () => {
    // open/closed state is managed via the handleCancel/handleConfirm callbacks on the parent component
    const {
      classes,
      open,
      orgId,
      saving,
      loading,
      currentFacilities,
      count,
      disableRemoval,
      disableInactive,
    } = this.props
    const hasChanges = this.hasChanges()

    return (
      <div>
        <Dialog
          className={classes.manageDialog}
          open={open}
          onClose={this.handleCancel}
          fullWidth
          maxWidth="lg">
          <DialogTitle>Manage Facilities</DialogTitle>
          <DialogContent className={classes.manageWindow}>
            <DialogContentText>
              Please pick an organization and then corresponding facility
            </DialogContentText>
            <br />
            <PfManager
              disableInactive={disableInactive}
              disableRemoval={disableRemoval}
              currentFacilities={currentFacilities}
              total={count}
              orgId={orgId}
              onUpdate={this.trackChanges}
              loading={loading}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              Cancel
            </Button>
            {saving ? (
              <CircularProgress />
            ) : (
              <Button
                onClick={this.handleConfirm}
                disabled={!hasChanges}
                color="primary">
                {hasChanges ? this.renderActionText() : <span>No Changes</span>}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

pfManagerDialog.propTypes = {
  currentFacilities: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  disableRemoval: PropTypes.bool,
  count: PropTypes.number,
  disableInactive: PropTypes.bool,
}

pfManagerDialog.defaultProps = {
  currentFacilities: [],
  disableRemoval: false,
}

export const PfManagerDialog = withStyles(styles)(pfManagerDialog)
