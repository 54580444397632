import React, { useState, useEffect } from 'react'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import {
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  OutlinedInput,
} from '@material-ui/core'
import { findReferralsV2 } from '../../actions/ReferralActions'

interface props {
  selectedID: number | null
  employerMemberIDs: number[]
  onChange(statusID: number | null): void
}

// relevant: https://stackoverflow.com/questions/56472438/select-outlineinput-label-with-shrink-property-not-same-as-textfield-when-empty
export default function LOAReferralPicker({
  selectedID,
  employerMemberIDs,
  onChange,
}: props): React.ReactElement {
  const [referrals, setReferrals] = useState<Array<any>>([])
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (!employerMemberIDs) return
    findReferralsV2({
      filter: { EmployerMemberIDs: employerMemberIDs, ReferralsOnly: true },
    })
      .then((res: any) => {
        if (res.error) throw res
        setReferrals(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading list of LOA referrals',
        })
      )
  }, [])

  const handleChange = (e: any, sel: any) => {
    if (!sel.props.value || sel.props.value === 'none') return onChange(null)
    onChange(sel.props.value)
  }

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      style={{ width: '100%' }}>
      <InputLabel shrink>Linked Referral</InputLabel>
      <Select
        autoWidth
        value={selectedID || 'none'}
        onChange={handleChange}
        input={<OutlinedInput notched label="Linked Referral" />}>
        <MenuItem key="none" value="none">
          None
        </MenuItem>
        {referrals.map((s: any) => (
          <MenuItem key={s.ID} value={s.ID}>{`${s.ID} - ${s.Descr}`}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
