import React, { useState } from 'react'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { postZClaimClone } from '../../../actions/ZClaimActions'
import { SourceTypes } from '../helpers'
import { AdjStatuses, FundStatuses } from './Status'
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from '@material-ui/core'

interface props {
  // zClaimID: number
  clm: any
  onComplete?: () => void
}

const adjStatusThatCanBeRefunded: string[] = [
  AdjStatuses.partiallyDenied,
  AdjStatuses.payable,
]

enum apiRouteActions {
  refund_only = 'refund_only',
  refund_and_adjust = 'refund_and_adjust',
  adjust_only = 'adjust_only',
}

export default function ClaimReversalDialog({
  // zClaimID,
  clm,
  onComplete,
}: props): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  const adjStatus = clm?.Claim?.ZClaimStatus as string
  const fundStatus = clm?.Claim?.ZClaimFundingStatus as string
  const isClone = clm?.Claim?.SourceType === SourceTypes.SourceTypeClone
  const sumPayable = Number.parseFloat(clm?.Claim?.ComputedSummary?.SumPayable)

  const canAdjustAtAll =
    !isClone && !!fundStatus && fundStatus !== FundStatuses.in_process
  const canRefund =
    canAdjustAtAll &&
    adjStatusThatCanBeRefunded.includes(adjStatus) &&
    Number.isFinite(sumPayable) &&
    sumPayable > 0

  function makeHandler(action: apiRouteActions): (ev: any) => void {
    return (ev: any) => {
      if (!clm?.Claim?.ID) return
      postZClaimClone(clm.Claim.ID, { action })
        .then(() => {
          let msg = 'If you see this, please contact Engineering.'
          switch (action) {
            case apiRouteActions.refund_only:
              msg = '1 refund claim created and associated to existing claim'
              break
            case apiRouteActions.refund_and_adjust:
              msg =
                '1 refund claim and 1 adjustment claim created and associated to existing claim'
              break
            case apiRouteActions.adjust_only:
              msg =
                '1 adjustment claim created and associated to existing claim'
              break
          }
          showSnackbar(msg, SnackbarTypeSuccess)
          onComplete?.()
        })
        .catch(
          catchAPIError({
            defaultMessage:
              'Failed creating adjustment claims; please contact Engineering if you see this.',
          })
        )
        .finally(() => setIsOpen(false))
    }
  }

  if (!canAdjustAtAll) return null

  return (
    <>
      <DesignSuite2023.Tooltip title="*No immediate action*: opens dialog to determine how to adjust this claim">
        <Button
          className="no-margin"
          size="small"
          variant="outlined"
          color="secondary"
          fullWidth
          onClick={() => setIsOpen(true)}>
          <span>Refund / Adjust</span>
        </Button>
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen} maxWidth="lg" onClose={() => setIsOpen(false)}>
        <DialogTitle disableTypography style={{ background: '#f1f1f1' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Typography variant="h6">Issue Claim Adjustment</Typography>
            <div>
              <IconButton
                size="medium"
                onClick={() => setIsOpen(false)}
                style={{ padding: 5 }}>
                <DesignSuite2023.CommonIcons.IconCancel fontSize="inherit" />
              </IconButton>
            </div>
          </div>
        </DialogTitle>

        <DialogContent>
          <div
            style={{
              display: 'grid',
              gridColumnGap: '1rem',
              gridRowGap: '1rem',
              gridTemplateColumns: '1fr auto',
              alignItems: 'center',
              padding: '1rem 0',
            }}>
            {canRefund ? (
              <>
                <Button
                  variant="outlined"
                  onClick={makeHandler(apiRouteActions.refund_only)}>
                  Refund Claim Only
                </Button>
                <Typography variant="body2">
                  Copy the existing claim and reverse the full amount.
                </Typography>
                <Button
                  variant="outlined"
                  onClick={makeHandler(apiRouteActions.refund_and_adjust)}>
                  Adjust Claim
                </Button>
                <Typography variant="body2">
                  Copy the existing claim and reverse the full amount, as well
                  as create <i>another</i> copy on which the claim amounts can
                  be changed.
                </Typography>
              </>
            ) : (
              <>
                {/* button is displayed here for UI consistency, but has no action */}
                <Button disabled variant="outlined">
                  Refund Claim Only
                </Button>
                <Typography variant="body2">
                  This claim cannot be refunded - only adjusted. (It is either
                  already a refund claim itself, or was previously paid at $0).
                  <small style={{ display: 'block' }}>
                    If you believe this to be an error, please contact
                    engineering immediately.
                  </small>
                </Typography>
                <Button
                  variant="outlined"
                  onClick={makeHandler(apiRouteActions.adjust_only)}>
                  Adjust Claim
                </Button>
                <Typography variant="body2">
                  Makes a copy the existing claim, on which the claim amounts
                  can be changed.
                </Typography>
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}
