import React from 'react'
import PropTypes from 'prop-types'
import QSearcher from '../../components/QSearcher'
import { withStyles } from '@material-ui/core/styles'
import {
  Collapse,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemAvatar,
  Divider,
  Typography,
} from '@material-ui/core'

import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank'
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import _ from 'lodash'

import { ApiActions } from '../../actions'
const { search } = ApiActions

const styles = (theme) => {
  return {
    selected: {
      backgroundColor: 'green',
    },
    current: {
      backgroundColor: 'green',
    },
    pendingRemoval: {
      backgroundColor: 'red',
    },
    manageWindow: {
      minHeight: 485,
    },
    item: {
      cursor: 'pointer',
    },
    userManagementDialog: {
      '& > div > div': {
        minWidth: 1200,
      },
    },
  }
}

class userManager extends React.Component {
  state = {
    userSearchResults: [],
    selectedUsers: [],
    usersPendingRemoval: [],
    currentListOpen: true,
  }

  // not perfect, but want to minimize impact to existing functionality here
  componentDidMount = () => {
    this.handleUsersQuery('')
  }

  doFindUsers = ({ q, pageSize, page }) => {
    if (q.length === 0 || q.length > 2) {
      const { searchPath } = this.props
      return search(searchPath, { filter: { q }, page: 1, pageSize: 5 })
    }

    return new Promise((resolve, reject) => {
      resolve({ Data: [] })
    })
  }

  handleUsersQuery = (q) => {
    const { searchParams } = this.state

    this.doFindUsers(Object.assign({}, searchParams, { q }))
      .then((res) => {
        this.setState({ userSearchResults: res.Data })
      })
      .catch((err) => console.error('failed fetching users', err))
  }

  toggleSelected = (ID, user) => {
    return () => {
      const { selectedUsers, usersPendingRemoval } = this.state

      const selected = _.find(selectedUsers, { ID })
      if (!selected) {
        selectedUsers.push(user)
      } else {
        // mutates the existing group
        _.remove(selectedUsers, { ID })
      }
      this.setState({ selectedUsers })
      this.props.onUpdate({ selectedUsers, usersPendingRemoval })
      console.debug(
        'Selecting a user: ',
        ID,
        user,
        ', currently selected: ',
        selectedUsers
      )
    }
  }

  toggleCurrent = (ID, user) => {
    return () => {
      const { usersPendingRemoval, selectedUsers } = this.state

      const pendingRemoval = _.find(usersPendingRemoval, { ID })

      if (!pendingRemoval) {
        usersPendingRemoval.push(user)
      } else {
        _.remove(usersPendingRemoval, { ID })
      }
      this.setState({ usersPendingRemoval })
      this.props.onUpdate({ selectedUsers, usersPendingRemoval })
      console.debug(
        'Toggling a current user: ',
        ID,
        user,
        ', all pending removal: ',
        usersPendingRemoval
      )
    }
  }

  toggleShowCurrent = () => {
    const { currentListOpen } = this.state
    this.setState({ currentListOpen: !currentListOpen })
  }

  renderCurrentListItem = (user, avatarClass) => {
    const { classes } = this.props
    const name = `${user.FirstName} ${user.LastName}`
    return (
      <ListItem className={classes.item} disableGutters>
        <ListItemAvatar key={user.ID}>
          <Avatar className={avatarClass}>
            <RadioButtonChecked className={classes.selected} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={name}
          secondary={
            'This User is currently active. Manage using "Current Users"'
          }
        />
      </ListItem>
    )
  }

  renderResults = () => {
    const { userSearchResults, selectedUsers } = this.state
    const { classes, currentUsers } = this.props

    return (
      <List>
        {userSearchResults.map((user) => {
          const name = `${user.FirstName} ${user.LastName}`
          const selected = _.find(selectedUsers, { ID: user.ID }) !== undefined
          const current = _.find(currentUsers, { ID: user.ID }) !== undefined

          const avatarClass = selected
            ? classes.selected
            : current
              ? classes.current
              : classes.notSelected

          if (current) {
            return this.renderCurrentListItem(user, avatarClass)
          }
          return (
            <ListItem
              className={classes.item}
              disableGutters
              onClick={this.toggleSelected(user.ID, user)}>
              <ListItemAvatar key={user.ID} button>
                <Avatar className={avatarClass}>
                  {selected ? (
                    <CheckCircleOutline className={classes.selected} />
                  ) : (
                    <CheckBoxOutlineBlank className={classes.notSelected} />
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={name} secondary={user.Email} />
            </ListItem>
          )
        })}
      </List>
    )
  }

  renderSelected = () => {
    const { selectedUsers } = this.state
    const { classes } = this.props
    return (
      <List>
        <ListItem disableGutters>
          <ListItemText primary="Users to Add" />
        </ListItem>
        {selectedUsers?.length === 0 ? (
          <Typography variant="caption">
            Select one or more users to add from the left.
          </Typography>
        ) : null}
        {selectedUsers.map((user) => {
          const name = `${user.FirstName} ${user.LastName}`

          return (
            <ListItem
              className={classes.item}
              disableGutters
              onClick={this.toggleSelected(user.ID, user)}>
              <ListItemAvatar key={user.ID} button>
                <Avatar className={classes.selected}>
                  <CheckCircleOutline className={classes.selected} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={name} secondary={user.Email} />
            </ListItem>
          )
        })}
      </List>
    )
  }

  renderCurrent = () => {
    const { currentListOpen, usersPendingRemoval } = this.state
    const { classes, currentUsers } = this.props

    return (
      <div>
        {currentUsers && currentUsers.length > 0 && (
          <List>
            <ListItem
              className={classes.item}
              disableGutters
              button
              onClick={this.toggleShowCurrent}>
              <ListItemText primary="Current Users" />
              {currentListOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={currentListOpen} timeout="auto" unmountOnExit>
              {currentUsers.map((user) => {
                const name = `${user.FirstName} ${user.LastName}`
                const pendingRemoval = _.find(usersPendingRemoval, {
                  ID: user.ID,
                })

                const avatarClass = pendingRemoval
                  ? classes.pendingRemoval
                  : classes.current

                return (
                  <ListItem
                    className={classes.item}
                    disableGutters
                    onClick={this.toggleCurrent(user.ID, user)}>
                    <ListItemAvatar key={user.ID} button>
                      <Avatar className={avatarClass}>
                        {pendingRemoval ? (
                          <CheckBoxOutlineBlank
                            className={classes.pendingRemoval}
                          />
                        ) : (
                          <CheckCircleOutline className={classes.current} />
                        )}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={name} secondary={user.Email} />
                  </ListItem>
                )
              })}
            </Collapse>
          </List>
        )}
      </div>
    )
  }

  render() {
    const { classes } = this.props
    return (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <QSearcher
            search={this.handleUsersQuery}
            className={classes.searchField}
          />
          <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
            {this.renderResults()}
          </div>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={4}>
          <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
            {this.renderSelected()}
          </div>
        </Grid>
        <Grid item xs={4}>
          <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
            {this.renderCurrent()}
          </div>
        </Grid>
      </Grid>
    )
  }
}

userManager.propTypes = {
  classes: PropTypes.object.isRequired,
}

export const UserManager = withStyles(styles)(userManager)

class userManagerDialog extends React.Component {
  state = {
    added: [],
    removed: [],
  }

  componentWillUnmount() {
    this.setState({
      added: [],
      remove: [],
    })
  }

  handleCancel = () => {
    this.setState({ added: [], removed: [] })
    this.props.onCancel()
  }

  handleConfirm = () => {
    const { added, removed } = this.state
    this.setState({ added: [], removed: [] })
    this.props.onConfirm({
      added,
      removed,
      permissionHandle: this.props.resourceName,
    })
  }

  trackChanges = ({ selectedUsers, usersPendingRemoval }) => {
    const added = selectedUsers
    const removed = usersPendingRemoval

    this.setState({ added, removed })
  }

  hasChanges = () => {
    const { added, removed } = this.state

    return !(added.length === 0 && removed.length === 0)
  }

  renderActionText = () => {
    const { added, removed } = this.state
    let addedText = ''
    let removedText = ''
    let separator = ''

    if (added.length !== 0) {
      addedText = `Add ${added.length} user${added.length > 1 ? 's' : ''}`
    }
    if (removed.length !== 0) {
      removedText = `Remove ${removed.length} user${removed.length > 1 ? 's' : ''}`
    }
    if (added.length !== 0 && removed.length !== 0) {
      separator = ', '
    }

    return (
      <span>
        {addedText}
        {separator}
        {removedText}
      </span>
    )
  }

  render = () => {
    // open/closed state is managed via the handleCancel/handleConfirm callbacks on the parent component
    const { classes, currentUsers, open, searchPath } = this.props
    const hasChanges = this.hasChanges()
    const { resourceName } = this.props
    return (
      <div>
        <Dialog
          className={classes.userManagementDialog}
          open={open}
          onClose={this.handleCancel}>
          <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
            <DialogTitle style={{ padding: 16 }}>
              Manage Users:{` ${resourceName}`}
            </DialogTitle>
          </div>
          <DialogContent className={classes.manageWindow}>
            <div style={{ paddingLeft: '10%', paddingRight: '10%' }}>
              <DialogContentText>
                Search below to find and add a user to {resourceName}. You can
                select multiple users.
              </DialogContentText>
            </div>
            <br />
            <UserManager
              currentUsers={currentUsers}
              onUpdate={this.trackChanges}
              searchPath={searchPath}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.handleConfirm}
              disabled={!hasChanges}
              color="primary">
              {hasChanges ? this.renderActionText() : <span>No Changes</span>}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

userManagerDialog.propTypes = {
  currentUsers: PropTypes.array.isRequired,
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onUpdate: PropTypes.func,
  resourceName: PropTypes.string,
}

userManagerDialog.defaultProps = {
  searchPath: `/users`,
  currentUsers: [],
}

export const UserManagerDialog = withStyles(styles)(userManagerDialog)
