export enum statuses {
  IN_FLIGHT = 'inFlight',
  DONE = 'done',
  START = 'start',
  SIGNED = 'signed',
  UPLOADED = 'uploaded',
  ERRORED = 'errored',
}

export interface customDisplayProps {
  file: decoratedFile
  setter(data: fileFields & any): void
}

export interface fileFields {
  Notes?: string
  FileTypeID?: number
  EmployerID?: number | null | undefined
  ReferrerID?: number | null | undefined
  OrganizationID?: number | null | undefined
  EmployerMemberID?: number | null | undefined
  PartnerID?: number
  EffectiveDate?: string
}

export interface postFileArgs extends fileFields {
  S3Key: string
  Size: number | null
}

export interface postFileResponse extends postFileArgs {
  ID: number
}

export interface decoratedFile {
  key: string
  domFileObj: File
  presignResponse: presignResponse | null
  progress: number
  err: (Error & any) | null
  fileRec: postFileResponse | null
  postFileData?: fileFields
  objectURL: string
}

export interface presignResponse {
  S3Key: string
  UUID?: string | null
  Url: string
}

export interface managedFileCollection {
  [index: string]: decoratedFile
}

export interface coordinator {
  status: statuses | null
  // Note: managedFiles is an OBJECT, *not* an array (although it looks similar). Instead,
  // the keys of the object are the same as their **index in the array of selectedFiles**
  // that we keep track of w/ useState. Doing this with an makes state management easier,
  // when updating deeply-targeted fields.
  managedFiles: managedFileCollection | null
}
