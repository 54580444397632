import React, { forwardRef, ReactElement, useCallback } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import { findZClaimInvoiced } from '../../../actions/ZClaimFundingActions'
import FileLinkBtn from '../../../components/FileLinkBtn/FileLinkBtn'
import { useConfig } from '../../../providers/Config'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../../hooks/useSnackbar'
import CopyIcon from '../../../components/CopyIcon'
import dateTime from '../../../utils/dateTime'

interface copyLinkProps {
  row: invoicedRecord
}

export interface invoiceFile {
  FileID: number
  S3Key: string
  FileTypeHash: string
  FileTypeDescription: string
}

export interface invoicedRecord {
  InvoiceID: number
  InvoiceUUID: string
  InvoiceNumber: string
  InvoiceDate: string | null
  EmployerID: number
  EmployerName: string
  ClaimCount: number
  InvoiceTotal: string
  Applied: string
  InvoiceBalance: string
  BillingMethod: string
  IsRefund: boolean
  LastNotificationDate: string | null
  LastNotificationType: string
  Files: invoiceFile[]
}

export const defaultColumns = Object.freeze({
  FileID: { name: 'ID', details: { hidden: true } },
  InvoiceNumber: {
    name: 'Invoice No.',
    details: {
      sortName: 'InvoiceNumber',
      dataFormat(_: any, row: invoicedRecord): ReactElement {
        return (
          <div>
            {row.InvoiceNumber}
            {row.IsRefund && (
              <span style={{ color: 'red', marginLeft: '5px' }}>[refund]</span>
            )}
          </div>
        )
      },
    },
  },
  CopyTeamLink: {
    name: 'Copy Link',
    details: {
      dataFormat: (cell: any, row: invoicedRecord) => (
        <CopyTeamLink row={row} />
      ),
    },
  },
  InvoiceDate: {
    name: 'Issued',
    details: { sortName: 'InvoiceDate', dataFormat: dateTime.cellFormatter() },
  },
  Files: {
    name: 'Files',
    details: {
      dataFormat: (_: any, row: invoicedRecord): ReactElement => {
        return (
          <div>
            {row.Files &&
              row.Files.length > 0 &&
              row.Files.sort((a, b) =>
                a.FileTypeDescription.localeCompare(b.FileTypeDescription)
              ).map((file) => (
                <div>
                  <FileLinkBtn
                    filename={file.FileTypeDescription}
                    handleGetDownloadURLFail={() => {}}
                    fileId={file.FileID}
                  />
                </div>
              ))}
          </div>
        )
      },
    },
  },
  BillingMethod: {
    name: 'Method',
  },
  InvoiceTotal: {
    name: 'Invoiced',
    details: {
      dataFormat(_: any, row: invoicedRecord): ReactElement {
        return (
          <span style={{ color: row.IsRefund ? 'red' : 'inherit' }}>
            {formatAmount(row.InvoiceTotal)}
          </span>
        )
      },
      sortName: 'InvoiceAmount',
    },
  },
  InvoiceBalance: {
    name: 'Balance',
    details: {
      dataFormat: (_: any, row: invoicedRecord): string => {
        return formatAmount(row.InvoiceBalance)
      },
    },
  },
})

const defaultSort = { col: 'InvoiceNumber', dir: 'desc' }

function formatAmount(amount: string): string {
  if (!amount || amount === '0') return '$0.00'
  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}

export const Table = forwardRef(function Table(
  {
    customColumns = defaultColumns,
    apiEndpoint = findZClaimInvoiced,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      passFilters: { employerIDs: [passThrough.EmployerID], withFiles: true },
      initPageSize: 10,
      DataTableProps: {
        keyProp: 'InvoiceID',
        ...DataTableProps,
        rowsPerPage: [10, 25, 50, 100, 250, 500],
        density: 'small',
      },
    },
    ref
  )

  return <>{TableDisplay}</>
})

function CopyTeamLink({ row }: copyLinkProps): React.ReactElement {
  const { teamzeroUrl } = useConfig()
  const { showForDuration: showSnackbar } = useSnackbar()
  const { InvoiceNumber, InvoiceUUID, EmployerID } = row
  const link = `${teamzeroUrl}/employer/${EmployerID}/invoices?viewingUUID=${InvoiceUUID}&viewing=true`
  const onCopied = useCallback(() => {
    showSnackbar(
      `TeamZERO link copied for Invoice Number: ${InvoiceNumber}`,
      SnackbarTypeSuccess
    )
  }, [showSnackbar])
  const onCopyErr = useCallback(() => {
    showSnackbar(
      'Failed copying link to clipboard: careful what you paste next, it may be incorrect.',
      SnackbarTypeError
    )
  }, [])
  return (
    <CopyIcon value={link} onCopySuccess={onCopied} onCopyError={onCopyErr} />
  )
}
