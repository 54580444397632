import React, { useState, useEffect } from 'react'
import { editEmployerMemberProblematic } from '../../actions/MemberActions'
import DesignSuite2023 from '../../components/DesignSuite2023'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import {
  renderNotesField as RenderNotesField,
  renderSwitchField as RenderSwitchField,
  SetterArgs,
} from '../ViewHelpers'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'
import * as shapes from './types'

interface props {
  Trigger: any // React.ReactComponent
  empMemRec: shapes.shapeEmployerMember | null
  onSaved?(): void
}

export default function DialogManageProblematic({
  Trigger,
  empMemRec,
  onSaved,
}: props): React.ReactElement | null {
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const [isOpen, setIsOpen] = useState(false)
  const [payload, setPayload] = useState<any>({
    Problematic: empMemRec?.Problematic || null,
    Notes: null,
  })

  useEffect(() => {
    if (!isOpen) return
    setPayload({
      Problematic: empMemRec?.Problematic || null,
      Notes: null,
    })
  }, [isOpen])

  function setter({ name, value }: SetterArgs) {
    setPayload((curr: any) => ({ ...curr, [name]: value }))
  }

  function doSave() {
    editEmployerMemberProblematic({
      ...payload,
      EmployerMemberID: empMemRec?.ID,
    })
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar('Updated OK!', SnackbarTypeSuccess)
        onSaved && onSaved()
      })
      .catch(catchAPIError({ defaultMessage: 'Failed updating' }))
      .finally(() => setIsOpen(false))
  }

  if (!empMemRec) return null

  return (
    <>
      <DesignSuite2023.Tooltip title="Manage settings for excluding this member from VBA exports">
        <Trigger onClick={() => setIsOpen(true)} />
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>
          Manage Problematic Settings
          <small style={{ display: 'block' }}>
            Note: this is an eligibility related concept; if you do not know
            what this is, leave.
          </small>
        </DialogTitle>
        <DialogContent>
          <DesignSuite2023.AlertInfo>
            <p>
              <strong>What does this mean?</strong> It indicates there may be a
              problem with this members' data. For example, member added by
              accident / should never have been added, or a mistake in SSN or
              Date of Birth.
            </p>
            <div style={{ fontSize: '90%' }}>
              <p>
                Flagging as problematic indicates potential data problems, and
                ...
              </p>
              <ul>
                <li>causes member to be *excluded* from exports</li>
                <li>highlights user red in eligibility search</li>
              </ul>
            </div>
          </DesignSuite2023.AlertInfo>

          <div style={{ padding: '1rem 0 0.5rem' }}>
            <RenderSwitchField
              setter={setter}
              name="Problematic"
              label={
                <>
                  <span>Is Problematic</span>
                  <small style={{ display: 'block' }}>
                    Toggle whether the employer member record is flagged
                    problematic.
                  </small>
                </>
              }
              value={payload.Problematic}
            />
          </div>

          <RenderNotesField
            setter={setter}
            name="Notes"
            label="Notes"
            value={payload.Notes}
            opts={{
              margin: 'normal',
              variant: 'outlined',
              size: 'small',
              helperText:
                "Warning: don't ever put sensitive information in notes!",
            }}
          />

          {/* <pre>{JSON.stringify(payload, null, '  ')}</pre> */}
        </DialogContent>
        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            }
            right={
              <Button color="primary" variant="contained" onClick={doSave}>
                Save
              </Button>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
