import React from 'react'
import utils from '../../utils'
import { Link } from 'react-router-dom'

const READ_PERMISSION = 'r:provider_prices'

const bundlePrices = {
  columns: {
    KeyColumn: 'CostKey',
    Description: 'Description',
    CostKey: {
      name: 'Cost Key',
      details: {
        width: '150px',
        style: 'word-break:"break-all"',
        dataFormat: costKeyLinkFormatter,
      },
    },
    Price: {
      name: 'Cost',
      details: { width: '100px', dataFormat: utils.priceFormatter },
    },
    IsValidNow: {
      name: 'Is Active',
      details: { width: '75px', dataFormat: utils.boolYesNoFormatter },
    },
    AllowTravel: {
      name: 'Travel Allowed',
      details: { width: '100px', dataFormat: utils.boolYesNoFormatter },
    },
    RequireMedicalReview: {
      name: 'Requires Medical Approval',
      details: { width: '150px', dataFormat: utils.boolYesNoFormatter },
    },
    Notes: {
      name: 'Notes',
      details: {
        dataFormat(_, row) {
          return row.Notes || ''
        },
      },
    },
  },
  hasAccess: () => {
    return utils.checkPerms(READ_PERMISSION)
  },
  legendItems: {
    Inactive: {
      color: 'yellow',
      label: 'Inactive',
    },
  },
}

function costKeyLinkFormatter(cell, row) {
  if (row && row.CostKey && row.CostKeyID) {
    return (
      <Link
        to={`/cost_key/${row.CostKeyID}`}
        style={{ wordBreak: 'break-all' }}
        target="_blank"
        name="view-link">
        {row.CostKey}
      </Link>
    )
  }
  return <div style={{ wordBreak: 'break-all' }}>{row.CostKey}</div>
}

export default bundlePrices
