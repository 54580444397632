import React, { useState, useEffect, useRef } from 'react'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { putInboundStagedProcessableFiles } from '../../../actions/EngineeringActions'
import * as TableEligInboundStaged from './TableEligInboundStaged'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import { IgnoreDialog } from './IgnoreDialog'
import { ResetDialog } from './ResetDialog'
import dateTime from '../../../utils/dateTime'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'

import { ShapeEligInboundStaged } from './types'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Button,
  Typography,
} from '@material-ui/core'
import {
  renderSwitchField,
  renderNotesField,
  SetterArgs,
} from '../../ViewHelpers'

const defaultData = {
  ProcessDate: null,
  EffectiveDate: null,
  OnHold: false,
  Notes: null,
}

export default function ViewFileList(): React.ReactElement {
  const [selected, setSelected] = useState([])
  const [selectedInboundIDs, setSelectedInboundIDs] = useState([] as number[])
  const [ignoreDialogOpen, setIgnoreDialogOpen] = useState(false)
  const [resetDialogOpen, setResetDialogOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [data, setData] = useState<any>({ ...defaultData })
  const snackbar = useSnackbar()
  const { catchAPIError } = useErrorHandlers()
  const refTbl = useRef<any>(null)

  useEffect(() => {
    setSelected([])
  }, [])

  useEffect(() => {
    if (!dialogOpen) return
    setData({
      ...defaultData,
      ProcessDate: dateTime.now().format(),
      EffectiveDate: dateTime.now().format(),
    })
  }, [dialogOpen])

  function setter({ name, value }: SetterArgs) {
    setData((curr: any) => ({ ...curr, [name]: value }))
  }

  function prepare() {
    setDialogOpen(true)
  }

  function applyToSelected() {
    putInboundStagedProcessableFiles(
      selected.map((f: ShapeEligInboundStaged) => ({
        EligInboundStagedID: f.ID,
        ...data,
      }))
    )
      .then(() => {
        setDialogOpen(false)
        snackbar.showForDuration(
          'Configurations applied OK. Files are ready for processing.',
          SnackbarTypeSuccess,
          10000
        )
        refTbl.current?.refresh()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed running processable files stuff',
        })
      )
  }

  function openIgnoreDialog() {
    const ids = selected.map((sel: any) => sel.OriginalEligInboundID as number)
    setSelectedInboundIDs(ids)
    setIgnoreDialogOpen(true)
  }

  function onCloseIgnoreDialog() {
    setIgnoreDialogOpen(false)
    setSelectedInboundIDs([])
    setSelected([])
    refTbl.current?.refresh()
  }

  function openResetDialog() {
    const ids = selected.map((sel: any) => sel.OriginalEligInboundID as number)
    setSelectedInboundIDs(ids)
    setResetDialogOpen(true)
  }

  function onCloseResetDialog() {
    setResetDialogOpen(false)
    setSelectedInboundIDs([])
    setSelected([])
    refTbl.current?.refresh()
  }

  return (
    <>
      <TableEligInboundStaged.Table
        ref={refTbl}
        onCheckHandler={setSelected}
        passFilters={{ HasEligFileConfig: true, HasProcessableFile: false }}
        customColumns={TableEligInboundStaged.columnsWithMatchInfo}
        LeftHeaderItems={
          <TableEligInboundStaged.StandardFilterSearch autoFocus />
        }
        RightHeaderItems={
          <>
            {!selected.length ? (
              <span>Select one or more files to view actions</span>
            ) : (
              <>
                <Button
                  color="secondary"
                  variant="outlined"
                  onClick={openResetDialog}>
                  Reset
                </Button>
                &nbsp;&nbsp;
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={openIgnoreDialog}>
                  Ignore
                </Button>
                &nbsp;&nbsp;
                <Button color="primary" variant="outlined" onClick={prepare}>
                  Prepare for processing or Place Hold
                </Button>
              </>
            )}
          </>
        }
      />
      {ignoreDialogOpen && (
        <IgnoreDialog
          open={ignoreDialogOpen}
          selected={selectedInboundIDs}
          onClose={onCloseIgnoreDialog}
          onCancel={() => setIgnoreDialogOpen(false)}
        />
      )}
      {resetDialogOpen && (
        <ResetDialog
          open={resetDialogOpen}
          selected={selectedInboundIDs}
          onClose={onCloseResetDialog}
          onCancel={() => setResetDialogOpen(false)}
        />
      )}

      <Dialog open={dialogOpen}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2" color="textSecondary">
                These fields will be applied to all of the selected files. Upon
                hitting 'Continue' below, the files will be updated and ready
                for eligibility processing.
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {/* { renderDateField({ name: 'ProcessDate', label: 'Process Date', setter, value: data.ProcessDate || ''})}  */}
              <ManagedDateInput
                name="ProcessDate"
                label="Process Date"
                value={data.ProcessDate}
                setter={setter}
              />
            </Grid>
            <Grid item xs={6}>
              {/* { renderDateField({ name: 'EffectiveDate', label: 'Effective Date', setter: setter, value: data.EffectiveDate || ''})}  */}
              <ManagedDateInput
                name="EffectiveDate"
                label="Effective Date"
                value={data.EffectiveDate}
                setter={setter}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              {renderNotesField({
                name: 'Notes',
                label: 'Notes',
                setter,
                value: data.Notes || '',
                opts: {
                  variant: 'outlined',
                  size: 'small',
                  margin: 'none',
                },
              })}
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              {renderSwitchField({
                name: 'OnHold',
                // @ts-ignore
                label: (
                  <div>
                    On Hold{' '}
                    <DesignSuite2023.Tooltip title="Prevent the file from moving into eligibility processing flow, temporarily.">
                      <DesignSuite2023.CommonIcons.IconInfo
                        style={{ height: '15px', width: '15px' }}
                      />
                    </DesignSuite2023.Tooltip>
                  </div>
                ),
                setter,
                value: !!data.OnHold,
              })}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  setDialogOpen(false)
                }}>
                Close
              </Button>
            }
            right={
              <Button
                color="primary"
                variant="contained"
                onClick={applyToSelected}>
                Continue
              </Button>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
