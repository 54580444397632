import React, { useEffect, useState } from 'react'
import EmployerEligibilityFileConfigsTable, {
  ConfigInitParams,
  ViewConfigProps,
} from './EmployerEligibilityFileConfigsTable'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import queryString from 'query-string'
import { EmployerActions, SnackbarActions } from '../../actions'
import { toBoolean } from 'underscore.string'
import { withRouter } from 'react-router-dom'

const { setSnackbarMessage } = SnackbarActions

interface ContainerProps {
  employerID?: number
  getEligibilityFileConfigs?: any
  eligibilityFileConfigs?: any
  setSnackbarMessage?: any
  searchError?: any
  getError?: any
  location: any
  history: any
  count: number
}

interface QSParams {
  editing?: boolean
  configID?: number
}

const Container = ({
  employerID,
  searchError,
  getError,
  getEligibilityFileConfigs,
  setSnackbarMessage,
  ...props
}: ContainerProps) => {
  const [initParams, updateInitParams] = useState({
    editing: false,
    configID: 0,
  })
  const [initialized, setInitialized] = useState(false)

  const onViewConfig = (vcProps: ViewConfigProps) => {
    if (vcProps && vcProps.configID) {
      const { history } = props
      const curParams = parseQs() as QSParams

      curParams.editing = true
      curParams.configID = vcProps.configID

      history.push(`?${queryString.stringify(curParams)}`)
    }
  }

  const onCloseConfig = () => {
    const { history } = props
    const curParams = parseQs()

    delete curParams.editing
    delete curParams.configID

    history.push(`?${queryString.stringify(curParams)}`)
    getEligibilityFileConfigs({ employerID })
  }

  const parseQs = (): ConfigInitParams => {
    const { location } = props
    const { search } = location
    const defaultParams = { editing: false, configID: 0 }

    const qsParams = queryString.parse(search)

    if (
      qsParams.editing !== undefined &&
      qsParams.configID !== undefined &&
      qsParams.editing !== null &&
      qsParams.configID !== null
    ) {
      const formatted = Object.assign({}, defaultParams, qsParams)
      // @ts-ignore
      formatted.editing = toBoolean(qsParams.editing)
      // @ts-ignore
      formatted.configID = qsParams.configID * 1

      return formatted
    }

    return Object.assign({}, defaultParams, qsParams)
  }

  useEffect(() => {
    const formatted = parseQs()
    updateInitParams(formatted)
    setInitialized(true)
  }, []) // eslint-disable-line

  useEffect(() => {
    getEligibilityFileConfigs({ employerID })
  }, [employerID, getEligibilityFileConfigs])

  useEffect(() => {
    if (getError) {
      setSnackbarMessage(getError.message, 'error')
    }
    if (searchError) {
      setSnackbarMessage(searchError.message, 'error')
    }
  }, [getError, searchError, setSnackbarMessage])

  if (!initialized) return <div />

  return (
    <EmployerEligibilityFileConfigsTable
      initParams={initParams}
      employerID={employerID}
      eligibilityFileConfigs={props.eligibilityFileConfigs}
      onViewConfig={onViewConfig}
      onCloseConfig={onCloseConfig}
      {...props}
    />
  )
}

function mapStateToProps(state: any) {
  return {
    eligibilityFileConfigs: state.employers.eligibilityFileConfigs || [],
    count: state.employers.eligibilityFileConfigs
      ? state.employers.eligibilityFileConfigs.length
      : 0,
    searchError: state.employers.getEligibilityFileConfigsError || null,
    getError: state.employers.getEligibilityFileConfigError || null,
  }
}

const { getEligibilityFileConfigs } = EmployerActions
// @ts-ignore
function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    { getEligibilityFileConfigs, setSnackbarMessage },
    dispatch
  )
}
// @ts-ignore
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
  // @ts-ignore
)(Container)
