import React, { useState } from 'react'
import {
  FormControl,
  Input,
  MenuItem,
  InputLabel,
  Select,
} from '@material-ui/core'
import styled from 'styled-components'

const InlineFormControl = styled(FormControl)`
  display: inline-flex;
  margin-top: 0px;
`

interface Option {
  label: string
  value: any
}

interface SearchFilterProps {
  name: string
  label: string
  items: Array<Option>
  defaultValue: string
  onChange(val: any): void
  opts?: any
}

const SearchFilter = ({
  name,
  label,
  items,
  defaultValue,
  onChange,
  opts,
}: SearchFilterProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [val, setVal] = useState<string>(defaultValue)

  const handleSelectOpen = () => {
    setOpen(true)
  }

  const handleSelectClose = () => {
    setOpen(false)
  }

  const handleSelectChange = (e: any) => {
    setVal(e.target.value)
    onChange(e.target.value)
  }

  return (
    <InlineFormControl margin="normal">
      {label ? <InputLabel htmlFor={name}>{label}</InputLabel> : null}
      <Select
        {...opts}
        value={val}
        open={open}
        MenuProps={{ open }}
        onOpen={handleSelectOpen}
        onClose={handleSelectClose}
        onChange={handleSelectChange}
        input={<Input name={name} id={name} />}>
        {items.map((item: Option) => (
          <MenuItem key={item.value} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </InlineFormControl>
  )
}

export default SearchFilter
