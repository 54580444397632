import React, { useState } from 'react'
import { postTriggerCacheRefresh } from '../../actions/NetworkActions'
import { Button, Tooltip, Paper } from '@material-ui/core'
import * as NetworkTable from './NetworkTable'
import Models from '../../models'
import styled from 'styled-components'
import { canExecEngineering } from '../../utils/perms'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'

const PaperWrapper = styled(Paper)`
  padding: 0.25rem 1rem 1rem;
`

interface Props {
  history: any
}

export default function Networks({ history }: Props) {
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const [permEngineering] = useState<boolean>(canExecEngineering())

  const handleAddNew = () => {
    history.push(`/network`)
  }

  function doTriggerCacheRebuild() {
    if (!permEngineering) return
    postTriggerCacheRefresh()
      .then(() => {
        showSnackbar('Refresh triggered!', SnackbarTypeSuccess)
      })
      .catch(
        catchAPIError({ defaultMessage: 'Failed triggering cache refresh' })
      )
  }

  return (
    <PaperWrapper elevation={2}>
      <NetworkTable.Table
        enableURLReflection
        LeftHeaderItems={<NetworkTable.StandardFilterSearch autoFocus />}
        RightHeaderItems={
          <>
            {permEngineering && (
              <>
                <Tooltip title="(EngineeringOnly): trigger cache rebuild">
                  <Button variant="outlined" onClick={doTriggerCacheRebuild}>
                    Rebuild Cache
                  </Button>
                </Tooltip>
                &nbsp;&nbsp;
              </>
            )}
            {Models.ConfigurableNetwork.canCreate() ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddNew}>
                Add New
              </Button>
            ) : null}
          </>
        }
      />
    </PaperWrapper>
  )
}
