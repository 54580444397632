import React, { useState, useEffect, useRef } from 'react'
import Models from '../../models'
import { MemberActions } from '../../actions'
import {
  renderTextField as RenderTextField,
  renderPhoneField as RenderPhoneField,
  renderSSNField as RenderSSNField,
  renderSwitchField as RenderSwitchField,
  renderNotesField as RenderNotesField,
  SetterArgs,
  RenderSelectField2,
} from '../ViewHelpers'
import styled from 'styled-components'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useDiffChangedFields, {
  ShapeChangedField,
} from '../../hooks/useDiffChangedFields'
import DesignSuite2023 from '../../components/DesignSuite2023'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core'

const WarningText = styled.p`
  font-size: 11px;
  margin: 0;
`

interface props {
  memberID: number | null | undefined
  onSave?(): void
}

interface ShapeMemberEdit {
  ID: number // "= member.id"
  FirstName: string
  MiddleName: string
  LastName: string
  Suffix: string
  Honorific: string
  FirstNameAka: string
  LastNameAka: string
  Phone: string
  Email: string
  Ssn: string
  Dob: any
  Gender: string
  Retired: boolean
  Notes: string
  // these fields are nested under an Address{} object from the API, but get
  // transformed to top-level fields by the component
  Address1: string
  Address2: string
  City: string
  State: string
  Zip: string
}

const emptyDefaults = (): ShapeMemberEdit => ({
  ID: 0,
  Retired: false,
  FirstName: '',
  FirstNameAka: '',
  MiddleName: '',
  LastName: '',
  LastNameAka: '',
  Email: '',
  Honorific: '',
  Suffix: '',
  Gender: '',
  Notes: '',
  Dob: '',
  Ssn: '',
  Phone: '',
  Address1: '',
  Address2: '',
  City: '',
  State: '',
  Zip: '',
})

const diffableFields = Object.freeze(
  Object.keys(emptyDefaults()).map((label: string) => ({ label, name: label }))
)

/*
 */
export default function DialogEditMemberTrigger({
  buttonProps,
  ...spread
}: props & any): React.ReactElement {
  const refDialog = useRef<any>()

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        onClick={() => refDialog?.current?.setIsOpen(true)}
        startIcon={<DesignSuite2023.CommonIcons.IconEdit />}>
        Edit Member Details
      </Button>
      <EditMemberDetails {...spread} ref={refDialog} />
    </>
  )
}

/*
 */
export const EditMemberDetails = React.forwardRef(function EditMemberDetails(
  { memberID, onSave }: props & Partial<any>,
  ref: any
) {
  const [isOpen, setIsOpen] = useState(false)
  const [record, setRecord] = useState<ShapeMemberEdit>(emptyDefaults())
  const [canSave, setCanSave] = useState(false)
  const { catchAPIError } = useErrorHandlers()
  const snackbar = useSnackbar()
  const changeTracker = useDiffChangedFields(record, diffableFields)

  // expose API on this component
  React.useImperativeHandle(ref, () => ({ setIsOpen }), [])

  useEffect(() => {
    setRecord(emptyDefaults())
    setCanSave(false)
    if (!isOpen || !memberID) return
    load(memberID)
  }, [isOpen, memberID])

  useEffect(() => {
    setCanSave(changeTracker.changedFields?.length >= 1)
  }, [changeTracker.changedFields])

  const setter = ({ name, value }: SetterArgs) =>
    setRecord((curr: ShapeMemberEdit) => ({ ...curr, [name]: value }))

  function load(mID: number) {
    MemberActions.getMemberByID(mID) //.payload
      .then((res: { Data: ShapeMemberEdit & any } & Partial<any>) => {
        if (res.error) throw res
        const transformed = Object.assign({}, res.Data, {
          Address1: res.Data.Address?.Address1,
          Address2: res.Data.Address?.Address2,
          City: res.Data.Address?.City,
          State: res.Data.Address?.State,
          Zip: res.Data.Address?.Zip,
        })
        setRecord({ ...transformed })
        changeTracker.setInitialForDiff({ ...transformed })
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading member info',
        })
      )
  }

  function doSave() {
    // payload must send back 'ID' as 'MemberID' for the backend
    MemberActions.postMemberEdit({ ...record, MemberID: record.ID })
      .then(() => {
        snackbar.showForDuration('Member updated OK', SnackbarTypeSuccess)
        setIsOpen(false)
        onSave && onSave()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed saving member info',
        })
      )
  }

  const showEditNameWarning =
    !!changeTracker.changedFields.find(
      (x: ShapeChangedField) => x.name === 'FirstName'
    ) ||
    !!changeTracker.changedFields.find(
      (x: ShapeChangedField) => x.name === 'LastName'
    )

  const spreadInputProps = {
    setter,
    opts: {
      margin: 'dense',
      variant: 'outlined',
      size: 'small',
      disabled: !Models.Member.canEdit(),
    },
  }

  const renderGenderSelect = () => {
    let genders = [
      { label: 'Male', value: 'M' },
      { label: 'Female', value: 'F' },
      { label: 'Other', value: 'O' },
    ]

    let value = ''
    if (record && record.Gender) {
      value = record.Gender
    }

    const setter = (e: any) => {
      setRecord((curr: ShapeMemberEdit) => ({
        ...curr,
        Gender: e.value,
      }))
    }

    return (
      <>
        <RenderSelectField2
          name="Gender"
          label="Gender"
          value={value}
          setter={setter}
          items={genders}
          FormControlProps={{
            margin: 'none',
            size: 'small',
            fullWidth: true,
            style: { padding: 'none', margin: '7px' },
          }}
        />
      </>
    )
  }

  return (
    <>
      <Dialog
        onClose={() => setIsOpen(false)}
        open={isOpen}
        aria-labelledby="form-dialog-title"
        maxWidth="xl">
        <DesignSuite2023.GridLR
          left={
            <DialogTitle id="form-dialog-title">
              Edit Member Details
            </DialogTitle>
          }
          right={<changeTracker.DisplayChangedFields inline />}
        />

        <DialogContent style={{ maxWidth: 900 }}>
          <DialogContentText>Name &amp; Designations</DialogContentText>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={9}>
              <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={4}>
                  <RenderTextField
                    name="FirstName"
                    label="First Name"
                    value={record.FirstName}
                    {...spreadInputProps}
                  />
                  <RenderTextField
                    name="FirstNameAka"
                    label="First Name Aka"
                    value={record.FirstNameAka}
                    {...spreadInputProps}
                  />
                </Grid>
                <Grid item xs={4}>
                  <RenderTextField
                    name="MiddleName"
                    label="Middle Name"
                    value={record.MiddleName}
                    {...spreadInputProps}
                  />
                </Grid>
                <Grid item xs={4}>
                  <RenderTextField
                    name="LastName"
                    label="Last Name"
                    value={record.LastName}
                    {...spreadInputProps}
                  />
                  <RenderTextField
                    name="LastNameAka"
                    label="Last Name Aka"
                    value={record.LastNameAka}
                    {...spreadInputProps}
                  />
                </Grid>
                {!!showEditNameWarning && (
                  <Grid item xs={12}>
                    <WarningText>
                      * If you are updating names, make sure to move old names
                      down into the AKA fields
                    </WarningText>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={6}>
                  <RenderTextField
                    name="Honorific"
                    label="Honorific"
                    value={record.Honorific}
                    {...spreadInputProps}
                  />
                </Grid>
                <Grid item xs={6}>
                  <RenderTextField
                    name="Suffix"
                    label="Suffix"
                    value={record.Suffix}
                    {...spreadInputProps}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <hr />

          <DialogContentText>Identifying, Contact &amp; Misc</DialogContentText>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={7}>
              <Grid container spacing={2} alignItems="flex-start">
                <Grid item xs={5}>
                  <ManagedDateInput
                    name="Dob"
                    Label="Dob"
                    value={record.Dob}
                    {...spreadInputProps}
                  />
                </Grid>
                <Grid item xs={4}>
                  <RenderSSNField
                    name="Ssn"
                    label="Ssn"
                    value={record.Ssn}
                    {...spreadInputProps}
                  />
                </Grid>
                <Grid item xs={3}>
                  {renderGenderSelect()}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <RenderTextField
                name="Email"
                label="Email"
                value={record.Email}
                {...spreadInputProps}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={7}>
              <RenderNotesField
                name="Notes"
                label="Notes"
                value={record.Notes}
                {...spreadInputProps}
              />
            </Grid>
            <Grid item xs={5}>
              <RenderPhoneField
                name="Phone"
                label="Phone"
                value={record.Phone}
                {...spreadInputProps}
              />
              <RenderSwitchField
                name="Retired"
                label="Retired"
                value={record.Retired}
                setter={setter}
                opts={{ disabled: !Models.Member.canEdit() }}
              />
            </Grid>
          </Grid>

          <hr />

          <DialogContentText>Address Information</DialogContentText>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={3}>
              <RenderTextField
                name="Address1"
                label="Address1"
                value={record.Address1}
                {...spreadInputProps}
              />
            </Grid>
            <Grid item xs={3}>
              <RenderTextField
                name="Address2"
                label="Address2"
                value={record.Address2}
                {...spreadInputProps}
              />
            </Grid>
            <Grid item container xs={6} spacing={2} alignItems="flex-start">
              <Grid item xs={5}>
                <RenderTextField
                  name="City"
                  label="City"
                  value={record.City}
                  {...spreadInputProps}
                />
              </Grid>
              <Grid item xs={4}>
                <RenderTextField
                  name="State"
                  label="State"
                  value={record.State}
                  {...spreadInputProps}
                />
              </Grid>
              <Grid item xs={3}>
                <RenderTextField
                  name="Zip"
                  label="Zip"
                  value={record.Zip}
                  {...spreadInputProps}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <Button onClick={() => setIsOpen(false)} color="secondary">
                Cancel
              </Button>
            }
            right={
              <Button onClick={doSave} color="primary" disabled={!canSave}>
                Save
              </Button>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
})
