import React, { useState, useEffect } from 'react'
import { Chip, Typography } from '@material-ui/core'
import styled from 'styled-components'

const ContainerChangedFields = styled.div`
  font-size: 90%;
  .MuiTypography-subtitle2 {
    margin-bottom: 0.25rem;
    font-size: inherit;
  }
  .MuiChip-root {
    margin: 0 0.35rem 0.25rem 0;
    font-size: inherit;
  }

  &.inlined {
    display: flex;
    align-items: center;
    .MuiTypography-subtitle2 {
      margin: 0 0.35rem 0 0;
    }
    .MuiChip-root {
      margin-bottom: 0;
    }
  }
`

type fieldKey = string
type fieldDescription = string
export interface ShapeChangedField {
  name: fieldKey
  label: fieldDescription
}

export default function useDiffChangedFields(
  current: any,
  fields: readonly ShapeChangedField[]
) {
  const [initial, setInitialForDiff] = useState<any>(null)
  const [changedFields, setChangedFields] = useState<ShapeChangedField[]>(
    [] as ShapeChangedField[]
  )

  useEffect(() => {
    if (!current || !initial) return
    let changed = fields.filter((field: ShapeChangedField) => {
      const key = field.name
      return (current[key] || null) !== (initial[key] || null)
    })
    // can only change one field at a time, so this is a minor perf bump
    if (changed.length !== changedFields.length) {
      setChangedFields(changed)
    }
  }, [current, initial])

  function DisplayChangedFields({
    children,
    inline,
  }: any): React.ReactElement | null {
    return (
      (!!changedFields.length && (
        <ContainerChangedFields className={inline ? 'inlined' : ''}>
          {children}
          <Typography variant="subtitle2" color="textSecondary">
            Unsaved Changes
          </Typography>
          {changedFields.map((field: ShapeChangedField) => (
            <Chip key={field.name} size="small" label={field.label} />
          ))}
        </ContainerChangedFields>
      )) ||
      null
    )
  }

  return {
    changedFields, // available, but you should use the exported component
    setInitialForDiff,
    DisplayChangedFields,
  }
}
