import React, { useEffect, useState } from 'react'
import * as api from '../../services/thezerocard/api-helper'
import * as typed from './typed'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import { Cancel as IconCancel } from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Grid, TextField, InputAdornment } from '@material-ui/core'
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridCellParams,
  GridSortModel,
} from '@mui/x-data-grid'
import { ReviewStateAutoSuggest } from './inputs'
import dateTime from '../../utils/dateTime'

const valueFormatterDate = {
  valueFormatter: ({ value }: any) => {
    return dateTime.parse(value).format()
  },
}

const ROWS_PER_PAGE = 5

const tableCols = [
  { sortable: false, minWidth: 70, field: 'ID', headerName: 'ID' }, // note lowercase 'id'; set in memory
  {
    sortable: false,
    minWidth: 180,
    field: 'EmployerName',
    headerName: 'EmployerName',
  },
  {
    sortable: false,
    minWidth: 120,
    field: 'PatientId',
    headerName: 'PatientId',
  },
  { sortable: false, minWidth: 120, field: 'ClaimNr', headerName: 'ClaimNr' },
  {
    sortable: true,
    minWidth: 120,
    field: 'GroupNr',
    headerName: 'GroupNr',
    cellClassName(params: GridCellParams): string {
      return 'cell-group-nr'
    },
  },
  {
    sortable: true,
    minWidth: 135,
    field: 'ServiceDate',
    headerName: 'ServiceDate',
    ...valueFormatterDate,
  },
  {
    sortable: true,
    minWidth: 145,
    field: 'PaymentDate',
    headerName: 'PaymentDate',
    ...valueFormatterDate,
  },
  {
    sortable: true,
    minWidth: 180,
    field: 'Providers',
    headerName: 'Providers',
  },
  { sortable: false, minWidth: 100, field: 'CostKey', headerName: 'CostKey' },
  // {sortable: false, minWidth: 180, field: 'CostKeyDescr', headerName: 'CostKeyDescr'},
  {
    sortable: false,
    minWidth: 75,
    field: 'TypicalPPOPrice',
    headerName: 'TypicalPPOPrice',
  },
  {
    sortable: false,
    minWidth: 75,
    field: 'TypicalHrrPpoPrice',
    headerName: 'TypicalHrrPpoPrice',
  },
  {
    sortable: false,
    minWidth: 75,
    field: 'PaidAmount',
    headerName: 'PaidAmount',
  },
  {
    sortable: false,
    minWidth: 40,
    field: 'ClaimType',
    headerName: 'ClaimType',
  },
  {
    sortable: false,
    minWidth: 40,
    field: 'ReviewState',
    headerName: 'ReviewState',
  },
  {
    sortable: false,
    minWidth: 110,
    field: 'ReviewDate',
    headerName: 'ReviewDate',
    ...valueFormatterDate,
  },
  { sortable: false, minWidth: 180, field: 'Notes', headerName: 'Notes' },
]

interface props {
  reviewGroups: typed.GroupRecord[]
  filterFields: typed.GroupFilterFields
  setFilterFields: Function
  reviewGroupIndex: number
  setReviewGroupIndex(v: number): void
  setReviewGroups(v: typed.GroupRecord[]): void
  onSortGroupsTable(gsm: GridSortModel): void
}

export const filterFieldDefaults: typed.GroupFilterFields = {
  PatientID: '',
  EmployerID: null,
  ProviderName: null,
  ReviewState: null,
  ClaimNr: '',
  GroupNr: '',
  Limit: 300,
  SortBy: [
    // server side treats null as ID,desc by default // ['ID', 'desc'], //'id',
    // {field: 'ID', sort: 'desc' as GridSortDirection}
  ] as GridSortModel,
}

export default React.memo(function GroupDisplay({
  filterFields,
  setFilterFields,
  reviewGroupIndex,
  setReviewGroupIndex,
  reviewGroups,
  onSortGroupsTable,
}: props) {
  const [employers, setEmployers] = useState<typed.EmployerRecord[]>([])
  const [selectedEmployer, setSelectedEmployer] =
    useState<typed.EmployerRecord | null>(null)
  const [providerNames, setProviderNames] = useState<
    typed.ProviderNameRecord[]
  >([])
  const [selectedProviderName, setSelectedProviderName] =
    useState<typed.ProviderNameRecord | null>(null)
  const [trackedPage, setTrackedPage] = useState(0)
  const { show: showSnackbar } = useSnackbar()

  /*
    Load employers once, when this component is created
  */
  useEffect(() => {
    Promise.all([loadEmployers(), loadProviders()])
      .then(
        ([emps, provs]: [
          typed.EmployerRecord[],
          typed.ProviderNameRecord[],
        ]) => {
          setEmployers(emps)
          setProviderNames(provs)
        }
      )
      .catch((e: any) => {
        showSnackbar(e.message, SnackbarTypeError)
      })
  }, [showSnackbar])

  /*
    On employer selection change
  */
  useEffect(() => {
    if (
      (filterFields.EmployerID && !selectedEmployer) ||
      (selectedEmployer && selectedEmployer.ID !== filterFields.EmployerID)
    ) {
      const emp = employers.filter((e: typed.EmployerRecord) => {
        return e.ID === filterFields.EmployerID
      })
      setSelectedEmployer(emp[0] || null)
    }
  }, [filterFields, employers, selectedEmployer])

  /*
    On provider selection change
  */
  useEffect(() => {
    if (
      (filterFields.ProviderName && !selectedProviderName) ||
      (selectedProviderName &&
        selectedProviderName.Name !== filterFields.ProviderName)
    ) {
      const pn = providerNames.filter((n: typed.ProviderNameRecord) => {
        return n.Name === filterFields.ProviderName
      })
      setSelectedProviderName(pn[0] || null)
    }
  }, [filterFields, providerNames, selectedProviderName])

  /*
    Clear input fields when clicking the input adornment
  */
  const onClickClearField = (
    merge: any
  ): { (event: React.MouseEvent<SVGElement, MouseEvent>): void } => {
    return (_: React.MouseEvent) => {
      setFilterFields((prev: typed.GroupFilterFields) => ({
        ...prev,
        ...merge,
      }))
    }
  }

  // when the page changes, ensure the review group at the top of the list becomes the
  // auto-selected one
  const onPageChange = (p: number) => {
    setTrackedPage(p)
    const reviewGroupIndex = p * ROWS_PER_PAGE
    if (reviewGroupIndex < reviewGroups.length) {
      setReviewGroupIndex(reviewGroupIndex)
    }
  }

  const onChangeReviewState = (value: any) => {
    setFilterFields((prev: typed.GroupFilterFields) => ({
      ...prev,
      ReviewState: value,
    }))
  }

  return (
    <>
      <div className="custom-filters">
        <Grid
          container
          spacing={2}
          justify="space-between"
          style={{ alignItems: 'center' }}>
          <Grid item xs>
            {employers.length >= 1 && (
              <Autocomplete
                options={employers}
                getOptionLabel={(opt: any): string => {
                  return opt.Name || ''
                }}
                getOptionSelected={(v: any): boolean =>
                  +v.ID === filterFields.EmployerID
                }
                value={selectedEmployer}
                defaultValue={null}
                onChange={(_: any, e: typed.EmployerRecord | null): void => {
                  if (!e) {
                    setFilterFields({ ...filterFields, EmployerID: null })
                    setSelectedEmployer(null)
                    return
                  }
                  setFilterFields({ ...filterFields, EmployerID: e.ID })
                  setSelectedEmployer(e)
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Employer"
                    placeholder="Select Employer"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                style={{ minWidth: 250 }}
              />
            )}
          </Grid>
          <Grid item xs>
            {providerNames.length >= 1 && (
              <Autocomplete
                options={providerNames}
                getOptionLabel={(opt: any): string => {
                  return opt.Name || ''
                }}
                getOptionSelected={(v: any): boolean =>
                  v.Name === filterFields.ProviderName
                }
                value={selectedProviderName}
                defaultValue={null}
                onChange={(
                  _: any,
                  e: typed.ProviderNameRecord | null
                ): void => {
                  if (!e) {
                    setFilterFields({ ...filterFields, ProviderName: null })
                    setSelectedProviderName(null)
                    return
                  }
                  setFilterFields({ ...filterFields, ProviderName: e.Name })
                  setSelectedProviderName(e)
                }}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Provider"
                    placeholder="Select Provider"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
                style={{ minWidth: 250 }}
              />
            )}
          </Grid>
          <Grid item xs>
            <TextField
              label="Patient ID"
              variant="outlined"
              onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => {
                const val = ev.target.value
                setFilterFields((prev: typed.GroupFilterFields) => ({
                  ...prev,
                  PatientID: val,
                }))
              }}
              fullWidth
              size="small"
              value={filterFields.PatientID}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: !!filterFields.PatientID ? (
                  <InputAdornment position="end">
                    <IconCancel
                      className="clear-icon"
                      color="action"
                      fontSize="small"
                      onClick={onClickClearField({ PatientID: '' })}
                    />
                  </InputAdornment>
                ) : null,
              }}
            />
          </Grid>
          <Grid item xs>
            <TextField
              label="Claim #"
              onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => {
                const val = ev.target.value
                setFilterFields((prev: typed.GroupFilterFields) => ({
                  ...prev,
                  ClaimNr: val,
                }))
              }}
              fullWidth
              variant="outlined"
              size="small"
              value={filterFields.ClaimNr}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: !!filterFields.ClaimNr ? (
                  <InputAdornment position="end">
                    <IconCancel
                      className="clear-icon"
                      color="action"
                      fontSize="small"
                      onClick={onClickClearField({ ClaimNr: '' })}
                    />
                  </InputAdornment>
                ) : null,
              }}
            />
          </Grid>
          <Grid item xs>
            <TextField
              label="Group #"
              onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => {
                const val = ev.target.value
                setFilterFields((prev: typed.GroupFilterFields) => ({
                  ...prev,
                  GroupNr: val,
                }))
              }}
              fullWidth
              variant="outlined"
              size="small"
              value={filterFields.GroupNr}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: !!filterFields.GroupNr ? (
                  <InputAdornment position="end">
                    <IconCancel
                      className="clear-icon"
                      color="action"
                      fontSize="small"
                      onClick={onClickClearField({ GroupNr: '' })}
                    />
                  </InputAdornment>
                ) : null,
              }}
            />
          </Grid>
          <Grid item xs>
            <ReviewStateAutoSuggest
              value={filterFields.ReviewState}
              variant="outlined"
              onChange={onChangeReviewState}
            />
          </Grid>
        </Grid>
      </div>

      <DataGrid
        autoHeight
        density="compact"
        rows={reviewGroups}
        columns={tableCols}
        className="groups-table"
        pageSize={ROWS_PER_PAGE}
        page={trackedPage}
        onPageChange={onPageChange}
        rowsPerPageOptions={[ROWS_PER_PAGE]}
        onRowClick={({ row }: any) => {
          for (let i = 0; i < reviewGroups.length; i++) {
            if (reviewGroups[i].GroupNr === row.GroupNr) {
              setReviewGroupIndex(i)
              break
            }
          }
        }}
        getRowClassName={({ row }: any): string => {
          if (!row || !reviewGroups[reviewGroupIndex]) {
            return ''
          }
          return row.GroupNr === reviewGroups[reviewGroupIndex].GroupNr
            ? 'active-group'
            : ''
        }}
        disableColumnMenu={true}
        sortingMode="server"
        onSortModelChange={(sm: GridSortModel) => {
          onPageChange(0)
          onSortGroupsTable(sm)
        }}
        sortModel={filterFields.SortBy}
        components={{
          Toolbar: () => (
            <GridToolbarContainer>
              <GridToolbarColumnsButton />
            </GridToolbarContainer>
          ),
        }}
      />
    </>
  )
})

// API handler is V1 at time of this implementing; so errors come back different than v2
async function loadEmployers(): Promise<any> {
  const req = (await api.get(
    `/employer?filter=${JSON.stringify({ noLimit: true, active: true })}`
  )) as any
  if (req.error) {
    if (req.Error && req.Error.Message) {
      throw new Error(req.Error.Message)
    }
    throw new Error('Unable to load employers')
  }
  return (req.Data || [])
    .filter((e: any) => e.IsCustomer)
    .map((e: any) => {
      return { ID: e.ID, Name: e.Name }
    })
    .sort((a: any, b: any) => {
      const nameA = a.Name.toUpperCase()
      const nameB = b.Name.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
}

async function loadProviders(): Promise<any> {
  const req = (await api.get(
    `/savings_review/claim_summary_review_providers`
  )) as any
  if (req.error) {
    if (req.Error && req.Error.Message) {
      throw new Error(req.Error.Message)
    }
    throw new Error('Unable to load employers')
  }
  return req.Data || []
}
