import React, { useState, useRef } from 'react'
import * as BillingEntityTable2 from '../../BillingEntities/BillingEntityTable2'
import { Chip } from '@material-ui/core'
import BtnRetireBillingEntities from '../../BillingEntities/BtnRetireBillingEntities'
import BtnCreateNewBillingEntity from '../../BillingEntities/BtnCreateNew'
import { ShapeBillingEntity } from '../../BillingEntities/types'

interface props {
  orgID?: number | null
}

export default function DetailTabBillingEntity({
  orgID,
}: props): React.ReactElement | null {
  const [checkedRows, setCheckedRows] = useState<Array<any>>([])
  const refTable = useRef<any>(null)

  if (!orgID) return null

  if (!orgID) {
    return (
      <span>
        OrganizationID is required (if organization is unsaved, save it first)
      </span>
    )
  }

  return (
    <>
      <BillingEntityTable2.Table
        ref={refTable}
        passFilters={{ OrganizationIDs: [orgID], isRetired: 'no' }}
        onCheckHandler={setCheckedRows}
        LeftHeaderItems={
          <>
            <BillingEntityTable2.StandardFilterSearch />
            &nbsp;&nbsp;
            <BillingEntityTable2.FilterCreationSource />
            &nbsp;&nbsp;
            <BillingEntityTable2.FilterIsRetired />
            &nbsp;
            <Chip label={`OrgID: ${orgID}`} style={{ marginLeft: '5px' }} />
          </>
        }
        RightHeaderItems={
          <>
            <BtnRetireBillingEntities
              ids={checkedRows?.map((row: any) => row.ID)}
              onDone={() => refTable.current?.refresh?.()}
            />
            &nbsp;
            {!!orgID && (
              <BtnCreateNewBillingEntity
                onSave={(be: ShapeBillingEntity) =>
                  refTable.current?.refresh?.()
                }
                organizationID={orgID}
                size="medium"
              />
            )}
          </>
        }
      />
    </>
  )
}
