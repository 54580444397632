import React, { useState } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { putIgnoreElig } from '../../../actions/EngineeringActions'
import { renderNotesField, SetterArgs } from '../../ViewHelpers'

import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
} from '@material-ui/core'

export const IgnoreDialog = ({
  open,
  selected,
  onClose,
  onCancel,
}: {
  open: boolean
  selected: number[]
  onClose: () => void
  onCancel: () => void
}) => {
  const [data, setData] = useState<any>({ Notes: '' })
  const [isProcessingIgnore, setIsProcessingIgnore] = useState(false)

  function setter({ name, value }: SetterArgs) {
    setData((curr: any) => ({ ...curr, [name]: value }))
  }

  const doIgnore = () => {
    setIsProcessingIgnore(true)
    putIgnoreElig({
      EligInboundIDs: selected,
      Notes: data.Notes,
    }).then(() => {
      onClose()
    })
  }

  return (
    <Dialog open>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              You have selected {selected.length} file(s) to ignore.
            </Typography>
            <Typography variant="body2" color="textSecondary">
              This will permanently IGNORE files. Use this when you want
              something to disappear from these lists.
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            {renderNotesField({
              name: 'Notes',
              label: 'Notes',
              setter,
              value: data.Notes || '',
              opts: {
                variant: 'outlined',
                size: 'small',
                margin: 'none',
              },
            })}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DesignSuite2023.GridLR
          left={
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          }
          right={
            <Button color="primary" variant="contained" onClick={doIgnore}>
              Ignore{' '}
              {isProcessingIgnore && (
                <>
                  &nbsp;
                  <DesignSuite2023.LoadingSpinner size={15} />
                </>
              )}
            </Button>
          }
        />
      </DialogActions>
    </Dialog>
  )
}
