import React from 'react'
import {
  Button,
  Step as MUIStep,
  StepContent,
  StepLabel,
  Stepper as MUIStepper,
} from '@material-ui/core'

export interface Step {
  id: string
  initialLabel: string
  completedLabel: string
  component: React.ReactNode
  isValid: () => boolean
}

interface StepperProps {
  steps: Step[]
  orientation?: 'vertical' | 'horizontal'

  setValid: (allValid: boolean) => void
}

export const Stepper: React.FC<StepperProps> = (props: StepperProps) => {
  const [activeStep, setActiveStep] = React.useState(0)
  const [dirtySteps, setDirtySteps] = React.useState<number[]>([])
  const { setValid, steps, orientation = 'vertical' } = props

  const back = () => {
    setDirtySteps([...dirtySteps, activeStep])
    setActiveStep(activeStep - 1)
  }

  const next = () => {
    setDirtySteps([...dirtySteps, activeStep])
    setActiveStep(activeStep + 1)
  }

  const isLastStep = activeStep === steps.length - 1

  const showCompletedLabel = (stepIndex: number, isValid: Step['isValid']) => {
    if (stepIndex === activeStep) return false
    return dirtySteps.includes(stepIndex) && isValid()
  }

  const allValid = steps.map((s) => s.isValid()).filter((v) => !v).length === 0

  React.useEffect(() => {
    setValid(allValid)
  }, [setValid, allValid])

  return (
    <MUIStepper orientation={orientation} activeStep={activeStep}>
      {steps.map((s, i) => (
        <MUIStep key={s.initialLabel}>
          <StepLabel>
            {showCompletedLabel(i, s.isValid)
              ? s.completedLabel
              : s.initialLabel}
          </StepLabel>
          <StepContent>
            <div style={{ width: '300px', paddingBottom: '30px' }}>
              {s.component}
            </div>
            <Button disabled={activeStep === 0} onClick={back}>
              Back
            </Button>
            {!isLastStep && (
              <Button
                variant="contained"
                color="primary"
                disabled={!s.isValid()}
                onClick={next}>
                Next
              </Button>
            )}
          </StepContent>
        </MUIStep>
      ))}
    </MUIStepper>
  )
}
