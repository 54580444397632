import React from 'react'
import styles from './styles.module.css'
import { useAuth } from '../../../providers/Auth'

import loglevel from 'loglevel'

import { Typography, Fade } from '@material-ui/core'
var log = loglevel.getLogger('Home')
log.setLevel('warn')

export default () => {
  const auth = useAuth()
  return (
    <div className={styles.root}>
      {auth.loading ? (
        <Typography>Syncing with Okta...</Typography>
      ) : (
        <Fade in={!auth.loading}>
          <Typography style={{ paddingTop: '2rem' }}>
            Welcome to the Zero Admin Application.
            <br />
            <small>Select an option from the left menu to begin.</small>
          </Typography>
        </Fade>
      )}
    </div>
  )
}
