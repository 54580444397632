import React from 'react'
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'

export default function DialogConfirmHostKeyInfo({
  hostKeyInfo,
  doClose,
  onConfirm,
}: any): React.ReactElement {
  return (
    <Dialog open={!!hostKeyInfo} maxWidth="md">
      <DialogTitle>Confirm Host Key Details</DialogTitle>
      <DialogContent>
        <pre>{JSON.stringify(hostKeyInfo, null, '  ')}</pre>
        <p>
          Note: you still need to Save the recipient after confirming these
          details.
        </p>
      </DialogContent>
      <DialogActions className="dialog-footerbar">
        <Grid container spacing={2} justify="space-between">
          <Grid item xs="auto">
            <Button color="secondary" variant="outlined" onClick={doClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button color="primary" variant="contained" onClick={onConfirm}>
              Confirm
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
