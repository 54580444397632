import * as apiHelper from './api-helper'

export function moneyFormat(f) {
  return '$' + floatFormat(f)
}

export function floatFormat(f) {
  return parseFloat(Math.round(f * 100.0) / 100.0).toFixed(2)
}

export function isProduction() {
  return apiHelper.url === 'https://api.zero.health'
}
