import React, { useEffect, useState, useRef, forwardRef } from 'react'
import { getRelatedClaimsByZClaimID } from '../../../../actions/ZClaimActions'
import * as ClaimTable from '../ClaimTable'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'

interface props {
  zClaimID: number | null
}

export default forwardRef(function DisplayRelatedClaims(
  { zClaimID }: props,
  ref: any
): React.ReactElement | null {
  const [relatedZClaimIDs, setRelatedZClaimIDs] = useState<number[]>([])
  const { catchAPIError } = useErrorHandlers()
  const refClaimTable = useRef<any>(null)

  React.useImperativeHandle(
    ref,
    () => ({
      refresh: loadLinkedClaims,
    }),
    []
  )

  useEffect(() => {
    if (!zClaimID) return
    loadLinkedClaims()
  }, [zClaimID])

  useEffect(() => {
    if (!relatedZClaimIDs?.length) return
    if (!refClaimTable.current) return
    refClaimTable.current?.setFilterImmediate({
      IncludeZClaimIDs: relatedZClaimIDs,
    })
  }, [relatedZClaimIDs])

  function loadLinkedClaims() {
    getRelatedClaimsByZClaimID(zClaimID!)
      .then((res: any) => {
        if (res.error) throw res
        if (!res.Data?.RelatedZClaimIDs?.length) return
        setRelatedZClaimIDs(res.Data.RelatedZClaimIDs || [])
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading zclaim limit info',
        })
      )
  }

  if (!relatedZClaimIDs.length) return null

  return (
    <>
      <hr />
      <ClaimTable.Table
        ref={refClaimTable}
        passFilters={{ IncludeZClaimIDs: relatedZClaimIDs }}
        // passFilters={{ RelatedToZClaimID: zClaimID }}
        LeftHeaderItems={
          <>
            <h5 style={{ margin: 0 }}>
              {relatedZClaimIDs.length} Related Claim(s) Detected
            </h5>
          </>
        }
      />
    </>
  )
})
