import React from 'react'
import PropTypes from 'prop-types'
import QSearcher from '../../components/QSearcher'
import { withStyles } from '@material-ui/core/styles'
import {
  Collapse,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemAvatar,
} from '@material-ui/core'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank'
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import _ from 'lodash'

import { ApiActions } from '../../actions'
const { search } = ApiActions

const styles = (theme) => {
  return {
    selected: {
      backgroundColor: 'green',
    },
    current: {
      backgroundColor: 'green',
    },
    pendingRemoval: {
      backgroundColor: 'red',
    },
    manageWindow: {
      minHeight: 485,
      minWidth: 550,
    },
    item: {
      cursor: 'pointer',
    },
  }
}

class groupManager extends React.Component {
  state = {
    groupSearchResults: [],
    selectedGroups: [],
    groupsPendingRemoval: [],
    currentListOpen: true,
  }

  doFindGroups = ({ q, pageSize, page }) => {
    if (q.length === 0 || q.length > 2) {
      return search('/access_group', { filter: { q }, page: 1, pageSize: 5 })
    }

    return new Promise((resolve, reject) => {
      resolve({ Data: [] })
    })
  }

  handleGroupsQuery = (q) => {
    const { searchParams } = this.state
    this.doFindGroups(Object.assign({}, searchParams, { q }))
      .then((res) => {
        console.debug('Retrieving groups: ', res)
        this.setState({ groupSearchResults: res.Data })
      })
      .catch((err) => console.error('failed fetching groups', err))
  }

  toggleSelected = (ID, group) => {
    return () => {
      const { selectedGroups, groupsPendingRemoval } = this.state

      const selected = _.find(selectedGroups, { ID })
      if (!selected) {
        selectedGroups.push(group)
      } else {
        // mutates the existing group
        _.remove(selectedGroups, { ID })
      }
      this.setState({ selectedGroups })
      this.props.onUpdate({ selectedGroups, groupsPendingRemoval })
      console.debug(
        'Selecting a group: ',
        ID,
        group,
        ', currently selected: ',
        selectedGroups
      )
    }
  }

  toggleCurrent = (ID, group) => {
    return () => {
      const { groupsPendingRemoval, selectedGroups } = this.state

      const pendingRemoval = _.find(groupsPendingRemoval, { ID })

      if (!pendingRemoval) {
        groupsPendingRemoval.push(group)
      } else {
        _.remove(groupsPendingRemoval, { ID })
      }
      this.setState({ groupsPendingRemoval })
      this.props.onUpdate({ selectedGroups, groupsPendingRemoval })
      console.debug(
        'Toggling a current group: ',
        ID,
        group,
        ', all pending removal: ',
        groupsPendingRemoval
      )
    }
  }

  toggleShowCurrent = () => {
    const { currentListOpen } = this.state
    this.setState({ currentListOpen: !currentListOpen })
  }

  renderCurrentListItem = (group, avatarClass) => {
    const { classes } = this.props
    const name = `${group.Name}`

    return (
      <ListItem disableGutters>
        <ListItemAvatar key={group.ID}>
          <Avatar className={avatarClass}>
            <RadioButtonChecked className={classes.selected} />
          </Avatar>
          <ListItemText
            primary={name}
            secondary={
              'This Group is currently active. Manage using "Current Groups"'
            }
            secondaryTypographyProps={{ style: { maxWidth: '280px' } }}
          />
        </ListItemAvatar>
      </ListItem>
    )
  }

  renderResults = () => {
    const { groupSearchResults, selectedGroups } = this.state
    const { classes, currentGroups } = this.props

    return (
      <List>
        {groupSearchResults.map((group) => {
          const selected =
            _.find(selectedGroups, { ID: group.ID }) !== undefined
          const current = _.find(currentGroups, { ID: group.ID }) !== undefined

          const avatarClass = selected
            ? classes.selected
            : current
              ? classes.current
              : classes.notSelected

          if (current) {
            return this.renderCurrentListItem(group, avatarClass)
          }
          return (
            <ListItem
              disableGutters
              onClick={this.toggleSelected(group.ID, group)}>
              <ListItemAvatar key={group.ID} button>
                <Avatar className={avatarClass}>
                  {selected ? (
                    <CheckCircleOutline className={classes.selected} />
                  ) : (
                    <CheckBoxOutlineBlank className={classes.notSelected} />
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={group.Name}
                secondary={`${group.NumberOfUsers} users`}
              />
            </ListItem>
          )
        })}
      </List>
    )
  }

  renderSelected = () => {
    const { selectedGroups, currentListOpen, groupsPendingRemoval } = this.state
    const { classes, currentGroups } = this.props

    return (
      <div>
        <List>
          <ListItem>
            <ListItemText primary="Groups to Add" />
          </ListItem>
          {selectedGroups.map((group) => {
            return (
              <ListItem
                disableGutters
                onClick={this.toggleSelected(group.ID, group)}>
                <ListItemAvatar key={group.ID} button>
                  <Avatar className={classes.selected}>
                    <CheckCircleOutline className={classes.selected} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={group.Name}
                  secondary={`${group.NumberOfUsers} users`}
                />
              </ListItem>
            )
          })}
        </List>
        {currentGroups && currentGroups.length > 0 && (
          <List>
            <ListItem button onClick={this.toggleShowCurrent}>
              <ListItemText primary="Current Groups" />
              {currentListOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={currentListOpen} timeout="auto" unmountOnExit>
              {currentGroups.map((group) => {
                const pendingRemoval = _.find(groupsPendingRemoval, {
                  ID: group.ID,
                })

                const avatarClass = pendingRemoval
                  ? classes.pendingRemoval
                  : classes.current
                return (
                  <ListItem
                    disableGutters
                    onClick={this.toggleCurrent(group.ID, group)}>
                    <ListItemAvatar key={group.ID} button>
                      <Avatar className={avatarClass}>
                        {pendingRemoval ? (
                          <CheckBoxOutlineBlank
                            className={classes.pendingRemoval}
                          />
                        ) : (
                          <CheckCircleOutline className={classes.current} />
                        )}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={group.Name}
                      secondary={`${group.NumberOfUsers} users`}
                    />
                  </ListItem>
                )
              })}
            </Collapse>
          </List>
        )}
      </div>
    )
  }

  render() {
    const { classes } = this.props
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <QSearcher
            search={this.handleGroupsQuery}
            className={classes.searchField}
          />
          <div>{this.renderResults()}</div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>{this.renderSelected()}</div>
        </Grid>
      </Grid>
    )
  }
}

groupManager.propTypes = {
  classes: PropTypes.object.isRequired,
}

export const GroupManager = withStyles(styles)(groupManager)

class groupManagerDialog extends React.Component {
  state = {
    added: [],
    removed: [],
  }

  componentWillUnmount() {
    this.setState({
      added: [],
      remove: [],
    })
  }

  handleCancel = () => {
    this.setState({ added: [], removed: [] })
    this.props.onCancel()
  }

  handleConfirm = () => {
    const { added, removed } = this.state
    this.setState({ added: [], removed: [] })
    this.props.onConfirm({ added, removed })
  }

  trackChanges = ({ selectedGroups, groupsPendingRemoval }) => {
    const added = selectedGroups
    const removed = groupsPendingRemoval
    console.debug(
      'Generating diff of groups under management: ',
      'Added: ',
      added,
      ' Removed: ',
      removed
    )

    this.setState({ added, removed })
  }

  hasChanges = () => {
    const { added, removed } = this.state

    return !(added.length === 0 && removed.length === 0)
  }

  renderActionText = () => {
    const { added, removed } = this.state
    let addedText = ''
    let removedText = ''
    let separator = ''

    if (added.length !== 0) {
      addedText = `Add ${added.length} group${added.length > 1 ? 's' : ''}`
    }
    if (removed.length !== 0) {
      removedText = `Remove ${removed.length} group${removed.length > 1 ? 's' : ''}`
    }
    if (added.length !== 0 && removed.length !== 0) {
      separator = ', '
    }

    return (
      <span>
        {addedText}
        {separator}
        {removedText}
      </span>
    )
  }

  render = () => {
    // open/closed state is managed via the handleCancel/handleConfirm callbacks on the parent component
    const { classes, currentGroups, open } = this.props
    const hasChanges = this.hasChanges()

    return (
      <div>
        <Dialog
          className={classes.dialog}
          open={open}
          onClose={this.handleCancel}>
          <DialogTitle>Manage Groups</DialogTitle>
          <DialogContent className={classes.manageWindow}>
            <DialogContentText>
              Please use this lookup table to manage the groups.
            </DialogContentText>
            <br />
            <GroupManager
              currentGroups={currentGroups}
              onUpdate={this.trackChanges}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.handleConfirm}
              disabled={!hasChanges}
              color="primary">
              {hasChanges ? this.renderActionText() : <span>No Changes</span>}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

groupManagerDialog.propTypes = {
  currentGroups: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
}

groupManagerDialog.defaultProps = {
  currentGroups: [],
}

export const GroupManagerDialog = withStyles(styles)(groupManagerDialog)
