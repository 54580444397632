import React from 'react'
import dateTime from '../utils/dateTime'
import utils from '../utils'

const minWidth = (n: number) => ({ style: { minWidth: n + 'px' } })
const READ_PERMISSION = 'r:employer_stoploss'

interface StopLossModel {
  columns: any
  validators: {
    [key: string]: { isValid: (val: any, fields: any) => boolean; msg: string }
  }
  hasAccess: () => boolean
}

const StopLoss: StopLossModel = {
  columns: {
    EmployerName: {
      name: 'Employer Name',
      details: { sortName: 'Employer.Name', ...minWidth(200) },
    },
    Period: {
      name: 'Period',
      details: {
        dataFormat: periodFormatter,
        sortName: 'Stoploss.ContractStartDate',
        ...minWidth(150),
      },
    },
    IncurredAndPayment: {
      name: 'Incurred/Payment',
      details: { dataFormat: contractTermsFormatter },
    },
    Active: {
      name: 'Active',
      details: { dataFormat: activeFormatter, ...minWidth(100) },
    },
    Status: {
      name: 'Status',
      details: { dataFormat: statusFormatter, ...minWidth(200) },
    },
    Partners: {
      name: 'Partners',
      details: { dataFormat: partnersFormatter, ...minWidth(200) },
    },
    ReportingNotes: { name: 'Reporting Notes', details: { ...minWidth(200) } },
    GeneralNotes: { name: 'General Notes', details: { ...minWidth(200) } },
  },
  validators: {
    EmployerID: {
      isValid: (val: any, fields: any) => !!(val && val > 0),
      msg: 'Employer is required',
    },
    ContractStartDate: {
      isValid: (val: any, fields: any) => {
        const hasValue = !!val
        if (!hasValue) return true

        return dateTime.parse(val).isValid()
      },
      msg: 'Contract Start Date is required',
    },
    ContractEndDate: {
      isValid: (val: any, fields: any) => {
        const hasValue = !!val
        if (!hasValue) return true

        return dateTime.parse(val).isValid()
      },
      msg: 'Contract End Date is required',
    },
    IncurredMonths: {
      isValid: (val: any, fields: any) => {
        const hasValue = !!val
        if (!hasValue) return true

        return Number.isInteger(+val)
      },
      msg: 'Incurred Months are required',
    },
    PaymentMonths: {
      isValid: (val: any, fields: any) => {
        const hasValue = !!val
        if (!hasValue) return true

        return Number.isInteger(+val)
      },
      msg: 'Payment Months are required',
    },
  },
  hasAccess: () => {
    return utils.checkPerms(READ_PERMISSION)
  },
}

function periodFormatter(_cell: any, row: any) {
  if (!row.ContractStartDate && !row.ContractEndDate) {
    return `Not Set`
  }
  const csd = dateTime.parse(row.ContractStartDate)
  const ced = dateTime.parse(row.ContractEndDate)
  return `${csd.format(dateTime.formats.AmericanDate, 'N/A')} - ${ced.format(dateTime.formats.AmericanDate, 'N/A')}`
}

function contractTermsFormatter(_cell: any, row: any) {
  if (row.IncurredMonths === 0 && row.PaymentMonths === 0) {
    return `Not Set`
  }
  return `${row.IncurredMonths}/${row.PaymentMonths}`
}

function activeFormatter(_cell: any, row: any): string {
  return row.IsActive ? 'Active' : 'Inactive'
}

function statusFormatter(_cell: any, row: any) {
  return (
    <div>
      <p>Has Stop Loss: {row.HasStopLoss ? 'Yes' : 'No'}</p>
      <p>ZERO Included: {row.IsZeroIncluded ? 'Yes' : 'No'}</p>
    </div>
  )
}

function partnersFormatter(_cell: any, row: any) {
  return (
    <div>
      <p>Stop Loss Carrier: {row.StopLossCarrierPartnerName}</p>
      {row.CoordinatingPartnerID ? (
        <p>Coordinating: {row.CoordinatingPartnerName}</p>
      ) : null}
      {row.StopLossCaptiveID ? <p>Captive: {row.StopLossCaptiveName}</p> : null}
    </div>
  )
}

export default StopLoss
