import React, { forwardRef, useContext } from 'react'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import { getStopLossRecords } from '../../actions/StopLossActions'
import { FormControlLabel, Switch } from '@material-ui/core'
import StopLossModel from '../../models/StopLoss'

export * from '../../hooks/useStandardTableSetup'

export const defaultColumns = StopLossModel.columns

const defaultSort = { col: 'Employer.Name', dir: 'asc' }

export const Table = forwardRef(function StopLossTableList(
  {
    customColumns = defaultColumns,
    apiEndpoint = getStopLossRecords,
    fnLinkOnRowClick = (row: any) => `/stop_loss_contract/${row.ID}`,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      fnLinkOnRowClick,
      DataTableProps: {
        ...DataTableProps,
        rowOptsApplier(row: any) {
          return !row.IsActive ? { className: 'tr-warning' } : null
        },
      },
    },
    ref
  )

  return <>{TableDisplay}</>
})

export function FilterStatus(): React.ReactElement {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <FormControlLabel
      label="Show Inactive"
      control={
        <Switch
          color="primary"
          checked={filter.IncludeInactive}
          onChange={(e: any) => {
            if (e.target.checked) {
              setFilterImmediate({ ...filter, IncludeInactive: true })
              return
            }
            setFilterImmediate({ ...filter, IncludeInactive: false })
          }}
        />
      }
    />
  )
}
