import React, { forwardRef, useEffect, useState } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import { getSesNotifications } from '../../../actions/MemberActions'
import dateTime from '../../../utils/dateTime'
import styled from 'styled-components'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { Button } from '@material-ui/core'

export * from '../../../hooks/useStandardTableSetup'

export const defaultColumns = Object.freeze({
  ID: { name: 'ID' },
  CreatedAt: {
    name: 'Created At',
    details: {
      dataFormat: dateTime.cellFormatter(dateTime.formats.PrettyVerbose),
    },
  },
  ToAddress: { name: 'Recipient' },
  Status: { name: 'Status' },
  NotificationPayload: {
    name: 'Payload',
    details: {
      dataFormat: (cell: any) => {
        return <ViewPayload cell={cell} />
      },
    },
  },
})

const defaultSort = { col: 'ses_notification.date', dir: 'desc' }

/*
Note: this is different than most other table implementations where we
export the table, then allow the callers to customize the filters and all
the things that get passed in... the SES Notifications table on the backend
doesn't have routes built adhering to the normal "list" type things, it only
takes an email address and that's it. With that in mind, we export another
wrapper component below that handles that.
*/
const Table = forwardRef(function SESNotificationsTable(
  {
    customColumns = defaultColumns,
    // apiEndpoint = getSesNotifications,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      // apiEndpoint,
      defaultSort,
    },
    ref
  )

  return <>{TableDisplay}</>
})

export default function TableSESNotifications({
  email,
  ...remaining
}: { email?: string | null } & Partial<any>): React.ReactElement | null {
  if (!email)
    return <div style={{ color: 'red' }}>No email address provided.</div>

  return (
    <Table {...remaining} apiEndpoint={() => getSesNotifications({ email })} />
  )
}

const StyledPayloadView = styled.div`
  dialog {
    border: 0;
    padding: 1rem;

    .titler {
      margin-bottom: 0.5rem;
    }
    h3 {
      margin: 0;
    }
    pre {
      margin: 0;
    }
  }
`

function ViewPayload({ cell }: any): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const r = React.useRef<any>(null)

  useEffect(() => {
    if (!isOpen || !r?.current) return
    r.current.showModal()
  }, [isOpen, r])

  return (
    <StyledPayloadView>
      <Button onClick={() => setIsOpen(true)} size="small">
        Click to view
      </Button>
      {isOpen && (
        // @ts-ignore
        <dialog
          ref={r}
          style={{ maxWidth: '1000px' }}
          // @ts-ignore
          onClose={() => setIsOpen(false)}>
          <DesignSuite2023.GridLR
            className="titler"
            left={<h3>SES Notification</h3>}
            right={
              <Button
                className="btn-close"
                variant="outlined"
                color="secondary"
                size="small"
                onClick={() => setIsOpen(false)}>
                Close
              </Button>
            }
          />
          <pre style={{ whiteSpace: 'break-spaces' }}>{cell}</pre>
        </dialog>
      )}
    </StyledPayloadView>
  )
}
