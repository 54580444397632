import React, { useState } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { putResetElig } from '../../../actions/EngineeringActions'
import { renderNotesField, SetterArgs } from '../../ViewHelpers'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'

export const ResetDialog = ({
  open,
  selected,
  onClose,
  onCancel,
}: {
  open: boolean
  selected: number[]
  onClose: () => void
  onCancel: () => void
}) => {
  const [data, setData] = useState<any>({ Notes: '' })
  const [isProcessingReset, setIsProcessingReset] = useState(false)

  function setter({ name, value }: SetterArgs) {
    setData((curr: any) => ({ ...curr, [name]: value }))
  }

  const doReset = () => {
    setIsProcessingReset(true)
    putResetElig({
      EligInboundIDs: selected,
      Notes: data.Notes,
    }).then(() => {
      onClose()
    })
  }

  return (
    <Dialog open>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              You have selected {selected.length} file(s) to reset.
            </Typography>
            <Typography variant="body2" color="textSecondary">
              This will reset files back to "inbound". It will allow you re-run
              hooks and matchers.
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Accordion defaultExpanded={false}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                Notes (Optional)
              </AccordionSummary>
              <AccordionDetails>
                {renderNotesField({
                  name: 'Notes',
                  label: 'Notes',
                  setter,
                  value: data.Notes || '',
                  opts: {
                    variant: 'outlined',
                    size: 'small',
                    margin: 'none',
                  },
                })}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DesignSuite2023.GridLR
          left={
            <Button color="secondary" variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          }
          right={
            <Button color="primary" variant="contained" onClick={doReset}>
              Reset{' '}
              {isProcessingReset && (
                <>
                  &nbsp;
                  <DesignSuite2023.LoadingSpinner size={15} />
                </>
              )}
            </Button>
          }
        />
      </DialogActions>
    </Dialog>
  )
}
