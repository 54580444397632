import utils from '../utils'
import goZeroUsers from './GoZeroUsers'

const READ_PERMISSION = 'r:referrer'

interface columnItem {
  name: string
  details?: any
}

interface columns {
  Name: columnItem
  Website: columnItem
  Owner: columnItem
  Notes: columnItem
}

interface referrerModel {
  columns: columns
  validators: {
    [key: string]: { isValid: (val: any, fields: any) => boolean; msg: string }
  }
  hasAccess: () => boolean
}

export default {
  columns: {
    Name: {
      name: 'Name',
      details: { sortName: 'referrer.name' },
    },
    Owner: {
      name: 'Owner',
      details: { sortName: 'referrer.owner' },
    },
    Website: {
      name: 'Website',
      details: {},
    },
    Notes: {
      name: 'Notes',
      details: {},
    },
  },
  validators: {
    Name: {
      isValid: (val: any, fields: any) => !((val || '').length === 0),
      msg: 'Name is required',
    },
  },
  hasAccess: () => utils.checkPerms(READ_PERMISSION) && goZeroUsers.hasAccess(),
} as referrerModel
