import React, { useState, useRef, useEffect } from 'react'
import { Button, Grid, DialogContentText } from '@material-ui/core'
import { Table } from '../Files/FilesTable'
import {
  deleteFiles,
  saveNewComponentFeeScheduleFile,
  getFileTypes,
} from '../../actions/FileActions'
import { getComponentFeeScheduleFiles } from '../../actions/ComponentFeeScheduleActions'
import { File } from '../Files/types'
import * as FileUpload from '../../components/FileUpload'
import FileUploadModal from '../Files/FileUploadModal'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import styled from 'styled-components'

const DeleteButton = styled(Button)`
  margin-right: 10px;
`
const StyledFilterText = styled(DialogContentText)`
  margin-bottom: 4px;
`

interface ComponentFeeScheduleFileTableContainerProps {
  id: number
}

interface FileData {
  FileTypeID: number
}

const ComponentFeeScheduleFileTableContainer = ({
  id,
}: ComponentFeeScheduleFileTableContainerProps) => {
  const [selectedFiles, setSelectedFiles] = useState<Array<number>>([])
  const [fileData, setFileData] = useState<FileData>()
  const { catchAPIError } = useErrorHandlers()
  const refFileTable = useRef()

  useEffect(() => {
    const payload = { filter: { Hashes: ['component_fee_schedule'] } }
    getFileTypes(payload).then((res: any) => {
      setFileData({ FileTypeID: res[0].ID })
    })
  }, [])

  const deleteSelectedFile = () => {
    deleteFiles(selectedFiles)
      .then(() => {
        //@ts-ignore
        refFileTable.current?.refresh()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to delete file; please contact Engineering',
        })
      )
  }

  const handleChecked = (selected: Array<File>) => {
    if (selected.length === 0) setSelectedFiles([])
    setSelectedFiles(selected.map((file) => file.ID))
  }

  const handleSave = () => {
    //@ts-ignore
    refFileTable.current?.refresh()
  }

  const apiPostFile = (
    payload: FileUpload.postFileArgs
  ): Promise<FileUpload.postFileResponse> => {
    return saveNewComponentFeeScheduleFile(id, payload).then(
      (res: FileUpload.postFileResponse) => res
    )
  }

  const apiEndpoint = (): Promise<any> => {
    return getComponentFeeScheduleFiles(Number(id))
  }

  const renderFilter = () => (
    <StyledFilterText>File Type: Component Fee Schedule</StyledFilterText>
  )

  return (
    <Table
      ref={refFileTable}
      apiEndpoint={apiEndpoint}
      customFileTypeFilter={renderFilter}
      onCheckHandler={handleChecked}
      DataTableProps={{
        pagination: false,
      }}
      RightHeaderItems={
        <Grid>
          {selectedFiles.length ? (
            <DeleteButton
              variant="contained"
              color="secondary"
              onClick={deleteSelectedFile}>
              Delete file
            </DeleteButton>
          ) : null}
          <FileUploadModal
            FileUploadProps={{
              onSuccess: handleSave,
              apiPostFile,
              defaultFileData: fileData,
            }}
            customFileTypeFilter={renderFilter}
          />
        </Grid>
      }
    />
  )
}

export default ComponentFeeScheduleFileTableContainer
