import React from 'react'
import {
  CheckCircleOutline as IconPayable,
  HighlightOff as IconError,
  PauseCircleFilled as IconPended,
  RadioButtonUnchecked as IconDenied,
  Toll as IconPartiallyDenied,
} from '@material-ui/icons'
import styled from 'styled-components'

export enum AdjStatuses {
  pended = 'pended',
  error = 'error',
  denied = 'denied',
  partiallyDenied = 'partially_denied',
  payable = 'payable',
}

export enum FundStatuses {
  in_process = 'in_process',
  processed = 'processed',
  invoiced = 'invoiced',
  vendors_paid = 'vendors_paid',
  historical_vba = 'historical_vba',
}

export const StatusColors = {
  [AdjStatuses.pended]: '#6a6a89',
  [AdjStatuses.error]: 'red',
  [AdjStatuses.denied]: '#9f952e',
  [AdjStatuses.partiallyDenied]: '#9f952e',
  [AdjStatuses.payable]: 'green',
}

const StyledStatus = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  small {
    display: inline-block;
    padding-left: 0.15rem;
    font-weight: 600;

    .fs {
      text-transform: uppercase;
      font-weight: 400;
    }
  }
`

export default function DisplayStatus({
  adjStatus,
  fundStatus,
  isSuspended = false,
  colorized = true,
}: {
  adjStatus: AdjStatuses
  fundStatus?: FundStatuses | null
  isSuspended?: boolean
  colorized?: boolean
}): React.ReactElement | null {
  let dispAdjStatus: React.ReactElement | null = null

  if (isSuspended) {
    return (
      <StyledStatus
        style={{
          color: colorized ? StatusColors[AdjStatuses.pended] : 'inherit',
        }}>
        <IconPended />
        <small>Suspended</small>
      </StyledStatus>
    )
  }

  const fs = !!fundStatus ? (
    <span className="fs">&nbsp;({fundStatus})</span>
  ) : null

  switch (adjStatus) {
    case 'pended':
      dispAdjStatus = (
        <StyledStatus
          style={{ color: colorized ? StatusColors[adjStatus] : 'inherit' }}>
          <IconPended />
          <small>Pended{fs}</small>
        </StyledStatus>
      )
      break

    case 'error':
      dispAdjStatus = (
        <StyledStatus
          style={{ color: colorized ? StatusColors[adjStatus] : 'inherit' }}>
          <IconError />
          <small>Error{fs}</small>
        </StyledStatus>
      )
      break

    case 'denied':
      dispAdjStatus = (
        <StyledStatus
          style={{ color: colorized ? StatusColors[adjStatus] : 'inherit' }}>
          <IconDenied />
          <small>Denied{fs}</small>
        </StyledStatus>
      )
      break

    case 'partially_denied':
      dispAdjStatus = (
        <StyledStatus
          style={{ color: colorized ? StatusColors[adjStatus] : 'inherit' }}>
          <IconPartiallyDenied />
          <small>Partially Denied{fs}</small>
        </StyledStatus>
      )
      break

    case 'payable':
      dispAdjStatus = (
        <StyledStatus
          style={{ color: colorized ? StatusColors[adjStatus] : 'inherit' }}>
          <IconPayable />
          <small>Payable{fs}</small>
        </StyledStatus>
      )
  }

  return dispAdjStatus
}
