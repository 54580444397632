import * as api from '../services/thezerocard/api-helper'
import { Action } from './Types'
import * as model from '../models/Partner'
export const GET_PARTNERS = 'GET_PARTNERS'
export const GET_PARTNER_BY_ID = 'GET_PARTNER_BY_ID'

export type APIResponsePartner = Promise<
  { Data: model.PartnerRecordShape } & Partial<any>
>

interface Filter {
  q: string
}

interface Params {
  filter: Filter
}

export const getPartners = (params: Params): Action => {
  return {
    type: GET_PARTNERS,
    payload: api.search('/partner', params),
  }
}

export const getPartnerById = (partnerId: number): Action => {
  return {
    type: GET_PARTNER_BY_ID,
    payload: api.get(`/partner/${partnerId}`),
  }
}

export function getPartner(id: number): APIResponsePartner {
  return api.get(`/partner/${id}`) as any
}

export function putUpdatePartner(
  payload: model.PartnerRecordShape
): APIResponsePartner {
  return api.put(`/partner/${payload.ID}`, payload) as any
}

export function postCreatePartner(
  payload: model.PartnerRecordShape
): APIResponsePartner {
  return api.post('/partner', payload) as any
}

export function getPartnerContactTypes(): APIResponsePartner {
  return api.get('/contact_type/partner') as any
}

export function savePartnerContact(
  partnerId: number | null,
  contact: any
): APIResponsePartner {
  return api.post(`/partner/${partnerId}/contact`, contact) as any
}
