import React, { useContext } from 'react'
import { Button } from '@material-ui/core'
import styled from 'styled-components'
import { ClaimDetailContext } from '../index'
import { FastViewType } from '../FastViews'
import DesignSuite2023 from '../../../../../components/DesignSuite2023'
import { putZClaimLineFeeSchedulePrice } from '../../../../../actions/ZClaimActions'
import useErrorHandlers from '../../../../../hooks/useErrorHandlers'
import * as helpers from '../../../helpers'

const StyledButton = styled(Button)`
  padding: 0rem !important;
  min-width: 0 !important;
  line-height: 1;

  .MuiButton-label {
    padding: 0 0.35rem;
    line-height: 1;
  }
`

interface props {
  readOnly?: boolean
  ZClaimLineObj: any // @todo: ts
  onFeeSchedulePriceSelected?: (ZClaimLineObj: any, fspObj: any) => void
}

export default function DisplayOrPickLinkedBundle({
  readOnly = false,
  ZClaimLineObj,
  onFeeSchedulePriceSelected,
}: props): React.ReactElement | null {
  const { catchAPIError } = useErrorHandlers()
  const ctx = useContext(ClaimDetailContext)

  function openFeeScheduleViewInSelectMode(): void {
    if (!ctx || !ctx?.launchFastView) {
      return console.warn(
        'Missing claim detail context; can not open fee schedule fast view'
      )
    }
    ctx.toggleLineTargeted(ZClaimLineObj.ID) // highlight the line the price is being selected for
    ctx.launchFastView(FastViewType.FEE_SCHEDULE, {
      presetSearch: ZClaimLineObj?.ProcedureCode || '',
      onSelectFeeSchedulePrice,
      onFastViewClose: () => {
        ctx.toggleLineTargeted(null) // un-highlight the line
        ctx.doReload()
      },
    })
  }

  function onSelectFeeSchedulePrice(fspObj: any) {
    // if onFeeSchedulePriceSelected is provided, then we invoke that
    // WITHOUT hitting the API directly
    if (onFeeSchedulePriceSelected) {
      onFeeSchedulePriceSelected(ZClaimLineObj, fspObj)
      return
    }

    putZClaimLineFeeSchedulePrice(ZClaimLineObj.ZClaimID, {
      ZClaimLineID: ZClaimLineObj.ID,
      FeeSchedulePriceID: fspObj.ID,
    })
      .then(() => {})
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to set fee schedule price',
        })
      )
      .finally(() => ctx.launchFastView(null))
  }

  function doClearFeeSchedulePrice() {
    putZClaimLineFeeSchedulePrice(ZClaimLineObj.ZClaimID, {
      ZClaimLineID: ZClaimLineObj.ID,
      ClearFeeSchedulePrice: true,
    })
      .then((res: any) => {
        if (res.error) throw res
        ctx.doReload()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to clear fee schedule price',
        })
      )
      .finally(() => ctx.launchFastView(null))
  }

  if (!ZClaimLineObj) return null

  if (!!ZClaimLineObj.AssociatedBundleLineUUID) {
    return (
      <>
        (part of bundle on line{' '}
        {helpers.UUIDShorty(ZClaimLineObj.AssociatedBundleLineUUID)})
      </>
    )
  }

  if (!!ZClaimLineObj.LinkedCostKey?.Code) {
    return (
      <>
        <DesignSuite2023.Tooltip title="Edit assigned fee schedule price">
          <StyledButton
            disabled={readOnly}
            size="small"
            variant="outlined"
            onClick={openFeeScheduleViewInSelectMode}
            endIcon={<DesignSuite2023.CommonIcons.IconEdit />}>
            {ZClaimLineObj.LinkedCostKey?.Code}
          </StyledButton>
        </DesignSuite2023.Tooltip>
        <DesignSuite2023.Tooltip title="Clear currently assigned fee schedule price">
          <StyledButton
            disabled={readOnly}
            size="small"
            variant="outlined"
            endIcon={<DesignSuite2023.CommonIcons.IconCancel />}
            onClick={doClearFeeSchedulePrice}
            style={{ marginLeft: '0.25rem' }}>
            Clear
          </StyledButton>
        </DesignSuite2023.Tooltip>
        <div style={{ display: 'block', paddingTop: '0.15rem' }}>
          <span
            style={{
              whiteSpace: 'nowrap',
              display: 'inline-block',
              background: '#c8f0dd',
              fontSize: '90%',
              borderRadius: '3px',
              lineHeight: 1,
              padding: '0.1rem 0.25rem',
            }}>
            {ZClaimLineObj.LinkedCostKeyCategory?.Path}
          </span>
        </div>
      </>
    )
  }

  return (
    <StyledButton
      disabled={readOnly}
      size="small"
      variant="outlined"
      onClick={openFeeScheduleViewInSelectMode}
      endIcon={<DesignSuite2023.CommonIcons.IconSearch />}>
      Select
    </StyledButton>
  )
}
