import React from 'react'
import DesignSuite2023 from '../../../../../components/DesignSuite2023'
import * as helpers from '../../../helpers'
import * as customInput from './CustomInput'
import ExCodesDisplay from '../../ExCodesDisplay'
import { ShapeExCodeOpt } from '../../../types'
// import { safeSumCol } from './index'
import { props } from './837p'
import CustomDateInput from './CustomDateInput'
import { AdjStatuses } from '../../Status'

export default function Rxngo({
  // newClaim = false,
  readOnly = false,
  isReversal = false, // interesting case here as Rxngo sends us negative claims *all the time*
  elmntCheckAll,
  fnMakeRowCheckbox,
  lines,
  fnGetCellClass,
  fnTrackChange,
  // refresh,
  onAssignLineExCode,
  handleClearExCode,
  Actions,
  claimStatus,
  // summary,
  calculations,
  // sourceType,
}: props): React.ReactElement {
  function doTrackChange(memID: string, field: string, cv: any) {
    fnTrackChange(memID, field, cv)

    if (field === 'AmntAllowed') {
      fnTrackChange(memID, 'AmntPayable', cv)
      let disc = (
        Number.parseFloat(lines?.[memID]?.['AmntBilled'] || '') -
        Number.parseFloat(cv)
      ).toFixed(2) as any
      if (cv === null || isNaN(disc)) {
        disc = null
      }
      fnTrackChange(memID, 'AmntPPODiscount', disc)
    }
  }

  return (
    <table>
      <thead>
        <tr>
          <th
            className="center"
            style={{ width: '1%', background: 'transparent' }}>
            <DesignSuite2023.Tooltip
              title={
                <p style={{ margin: 0, maxWidth: 450 }}>
                  These are "stable identifiers". If line sequence numbers
                  change (say you swap the sequence of lines 1 &amp; 2), then
                  referring to "line #1" in the claim history is misleading.
                  These values will always stay the same for a given line.
                </p>
              }>
              <DesignSuite2023.CommonIcons.IconInfo
                fontSize="small"
                style={{ color: '#888' }}
              />
            </DesignSuite2023.Tooltip>
          </th>
          <th className="center" style={{ width: 30 }}>
            <DesignSuite2023.Tooltip title="Toggle / Untoggle All">
              {elmntCheckAll}
            </DesignSuite2023.Tooltip>
          </th>
          <th style={{ width: 110 }}>
            <DesignSuite2023.Tooltip title="Fill Date">
              <span>Fill Date</span>
            </DesignSuite2023.Tooltip>
          </th>
          <th style={{ width: 50 }}>
            <DesignSuite2023.Tooltip title="NDC">
              <span>NDC</span>
            </DesignSuite2023.Tooltip>
          </th>

          <th style={{ width: 90 }}>
            <DesignSuite2023.Tooltip title="$ Allowed Amount">
              <span>AllowedAmnt</span>
            </DesignSuite2023.Tooltip>
          </th>
          <th style={{ width: 90 }}>
            <DesignSuite2023.Tooltip title="$ PPO Discount (computed automatically)">
              <span>PPODisc</span>
            </DesignSuite2023.Tooltip>
          </th>
          <th style={{ width: 90 }}>
            <DesignSuite2023.Tooltip title="$ Payable Amount">
              <span>PayableAmnt</span>
            </DesignSuite2023.Tooltip>
          </th>
          <th>
            <DesignSuite2023.Tooltip title="Line-level Ex(planation) Codes">
              <span>ExCodes</span>
            </DesignSuite2023.Tooltip>
          </th>
        </tr>
      </thead>
      <tbody>
        {Object.values(lines).map((row) => (
          <tr key={row._memID}>
            <td>
              <span className="stable-identifier">
                {row.ID >= 1
                  ? //@ts-ignore
                    helpers.UUIDShorty(row?.ZClaimLineUUID)
                  : '(unsaved)'}
              </span>
            </td>
            <td style={{ textAlign: 'center' }}>{fnMakeRowCheckbox(row)}</td>
            <td className={fnGetCellClass(row._memID, 'ServiceDateStart')}>
              <CustomDateInput
                disabled={readOnly}
                name="ServiceDateStart"
                value={row.ServiceDateStart}
                setter={({ value }: any) => {
                  doTrackChange(row._memID, 'ServiceDateStart', value)
                }}
              />
            </td>
            <td className={fnGetCellClass(row._memID, 'NDC')}>
              <customInput.Input
                disabled={readOnly}
                value={row.NationalDrugCode}
                onChange={(nextVal: string) => {
                  doTrackChange(row._memID, 'NDC', nextVal)
                }}
              />
            </td>

            <td
              className={fnGetCellClass(row._memID, 'AmntAllowed')}
              style={{ whiteSpace: 'nowrap' }}>
              <customInput.Input
                disabled={readOnly}
                value={row.AmntAllowed}
                restrictInput={customInput.rgxFloatOnly}
                textPrefix="$"
                onChange={(nextVal: string) => {
                  doTrackChange(row._memID, 'AmntAllowed', nextVal)
                }}
                style={{ display: 'inline-block' }}
              />
            </td>
            <td className={fnGetCellClass(row._memID, 'AmntPPODiscount')}>
              <customInput.Input
                disabled
                value={row.AmntPPODiscount}
                textPrefix="$"
                onChange={(nextVal: any) => {
                  doTrackChange(row._memID, 'AmntPPODiscount', nextVal)
                }}
              />
            </td>
            <td className={fnGetCellClass(row._memID, 'AmntPayable')}>
              <customInput.Input
                // disabled={readOnly}
                disabled
                value={row.AmntPayable}
                textPrefix="$"
                onChange={(nextVal: any) => {
                  doTrackChange(row._memID, 'AmntPayable', nextVal)
                }}
              />
            </td>
            <td>
              {/* @todo: disable showing excodes until line is persisted */}
              <ExCodesDisplay
                readOnly={readOnly}
                list={row.LineExCodes || []}
                handleClearExCode={(eco: ShapeExCodeOpt) => {
                  handleClearExCode(eco, row._memID)
                }}
                onExCodeAssigned={(eco: ShapeExCodeOpt) => {
                  onAssignLineExCode(eco, row._memID)
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={4}>{Actions}</td>
          <td className={`sumd ${isReversal && 'reversal'}`}>
            {calculations?.sumAllowed}
          </td>
          <td className={`sumd ${isReversal && 'reversal'}`}>
            {calculations?.sumPPODiscount}
          </td>
          <td className={`sumd ${isReversal && 'reversal'}`}>
            {claimStatus === AdjStatuses.denied ? (
              <>$0</>
            ) : (
              calculations?.sumPayable
            )}
          </td>
          <td></td>
        </tr>
      </tfoot>
    </table>
  )
}
