import utils from './'

export const canExecEngineering = () => {
  return utils.checkPerms('a:engineering')
}
export const canImportFacilities = () => {
  return utils.checkPerms('a:import_facilities')
}
export const canExecETL = () => {
  return utils.checkPerms('a:etl')
}
