import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import {
  clearSearchResults,
  OpportunityAnalysisActions,
  FileActions,
} from '../../actions'
import queryString from 'query-string'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import DetailView, {
  defaultStyleCreator,
} from '../../components/DetailView/DetailView'

import { Grid, Button } from '@material-ui/core'

const { getFileClaims } = FileActions
const {
  getShowGroupOptions,
  getLimitOptions,
  clearSaveResult,
  SAVE_GROUP_SUMMARY,
  SAVE_GROUP_LINES,
} = OpportunityAnalysisActions
const styles = (theme) => {
  const defaults = defaultStyleCreator(theme)

  return Object.assign(defaults, {
    field: {
      margin: '10px',
      width: 200,
    },
  })
}

export class OpportunityAnalysisSearchBar extends DetailView {
  constructor(props) {
    super(props)
    const { params } = props
    const fileClaimsId = params.fileClaimsId * 1 || -1

    this.state = {
      selectedFileClaim: {},
      sortOptions: [
        {
          value: 'default',
          label: 'Default',
        },
        {
          value: 'savings',
          label: 'Savings',
        },
        {
          value: 'patient',
          label: 'Patient',
        },
        {
          value: 'service_date',
          label: 'Service Date',
        },
      ],
      fields: {
        FileClaimsId: fileClaimsId || -1,
        ShowGroup: params.showGroup * 1 || 2,
        Limit: params.limit * 1 || 50,
        CostKey: params.costKey || '',
        Sort: params.sort || 'default',
        GroupNr: params.groupNr || '',
      },
    }
  }

  componentDidMount() {
    this.props.getFileClaims()
    this.props.getShowGroupOptions()
    this.props.getLimitOptions()
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (
      props.saveResult &&
      (props.saveResult.type === SAVE_GROUP_SUMMARY ||
        props.saveResult.type === SAVE_GROUP_LINES)
    ) {
      this.handleSearch(null, false)
      this.props.clearSaveResult()
    }

    if (props.fileClaims.length >= 1) {
      for (let i = 0; i <= props.fileClaims.length - 1; i++) {
        if (props.fileClaims[i].id === this.state.fields.FileClaimsId) {
          this.setState({ selectedFileClaim: props.fileClaims[i] })
          break
        }
      }
    }
  }

  isValidSearchState() {
    return (
      this.isGroupNrValid() || (this.isFileClaimsValid() && this.isLimitValid())
    )
  }

  handleSearch = (e, showLoadingIndicator) => {
    if (!this.isValidSearchState()) {
      this.setState({ showValidations: true })
      return
    }
    const { GroupNr, FileClaimsId, ShowGroup, CostKey, Limit, Sort } =
      this.state.fields
    let params
    if (GroupNr) {
      params = {
        groupNr: GroupNr,
        fileClaimsId: -1,
        showGroup: -1,
        costKey: '',
        limit: Limit,
        sort: Sort,
      }
    } else {
      params = {
        fileClaimsId: FileClaimsId,
        showGroup: ShowGroup,
        costKey: CostKey,
        limit: Limit,
        sort: Sort,
      }
    }

    const opts = {
      showLoadingIndicator,
    }

    this.props.history.push(`?${queryString.stringify(params)}`)
    showLoadingIndicator !== false && this.props.clearSearchResults()
    this.props.search(params, opts)
  }

  isGroupNrValid = () => {
    const { fields } = this.state
    return fields.GroupNr && fields.GroupNr !== ''
  }

  isFileClaimsValid = () => {
    const { fields } = this.state
    return fields.FileClaimsId && fields.FileClaimsId !== ''
  }

  isLimitValid = () => {
    const { fields } = this.state
    return fields.Limit && fields.Limit !== ''
  }

  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.handleSearch()
    }
  }

  onFileSelect = (_, v) => {
    if (!v) {
      return
    }
    this.handleSelectChange(`FileClaimsId`)({ target: { value: v.id } })
    this.setState({ selectedFileClaim: v })
  }

  render() {
    return (
      <div>
        <Grid container spacing={2} wrap="wrap">
          <Grid item xs={12} sm={8}>
            {this.props.fileClaims && this.props.fileClaims.length ? (
              <Autocomplete
                options={this.props.fileClaims || []}
                getOptionLabel={(opt) => opt.label}
                getOptionSelected={(v) =>
                  +v.id === +this.state.fields.FileClaimsId
                }
                value={this.state.selectedFileClaim}
                defaultValue={{}}
                onChange={this.onFileSelect}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Select file..."
                    placeholder="Lorem ipsum"
                    onKeyPress={this.onKeyPress}
                  />
                )}
              />
            ) : (
              <div>Loading...</div>
            )}
            {this.renderTextField('CostKey', 'Cost Key', {
              autoFocus: true,
              onKeyPress: this.onKeyPress,
            })}
            {this.renderSelect('Limit', 'Limit', this.props.limitOptions, {
              onKeyPress: this.onKeyPress,
            })}
            {this.renderSelect(
              'ShowGroup',
              'Status',
              this.props.showGroupOptions,
              { onKeyPress: this.onKeyPress }
            )}
            {this.renderSelect('Sort', 'Sort', this.state.sortOptions, {
              onKeyPress: this.onKeyPress,
            })}
            {this.renderTextField('GroupNr', 'Group Number', {
              onKeyPress: this.onKeyPress,
            })}
          </Grid>
          <Grid item xs={12} lg={2} style={{ paddingTop: '30px' }}>
            <Button
              variant="contained"
              onClick={this.handleSearch}
              style={{ float: 'left' }}>
              Search
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    fileClaims: state.files.fileClaims || [],
    showGroupOptions: state.opportunityAnalysis.showGroupOptions || [],
    limitOptions: state.opportunityAnalysis.limitOptions || [],
    saveResult: state.opportunityAnalysis.saveResult || {},
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getFileClaims,
      getShowGroupOptions,
      getLimitOptions,
      clearSearchResults,
      clearSaveResult,
    },
    dispatch
  )
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(OpportunityAnalysisSearchBar)
