import React, { forwardRef, useContext, useEffect, useState } from 'react'
import ContactModel from '../../models/Contact'
import { ContactTypeData, ContactTypeOwner } from './types'
import ContactTypeFilter from './ContactTypeFilter'
import ConfirmDialog from '../../components/ConfirmDialog'
import { deleteContactsV2, saveContactV2 } from '../../actions/ContactActions'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import { ContactActions } from '../../actions'
import utils from '../../utils'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import { Button, Switch, Typography } from '@material-ui/core'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import styled from 'styled-components'
import useQueryParamsGen2, { SCOPES } from '../../hooks/useQueryParamsGen2'
import DialogEditContact from './DialogEditContact'
// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../hooks/useStandardTableSetup'

export const defaultColumns = Object.freeze(ContactModel.columns)

const defaultSort = { col: 'Contact.LastName', dir: 'asc' }

const SelectedButton = styled(Button)`
  margin-right: 10px;
`

export const Table = forwardRef(function ContactTableList(
  {
    customColumns = defaultColumns,
    apiEndpoint = defaultAPIEndpoint,
    onRowClick,
    onCheckHandler,
    disableCheckHandler = false,
    handleSaveNewContact,
    RightHeaderItems,
    contactTypes = [],
    initPageSize = 100,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const [formattedTypes, setFormattedTypes] = useState<any[]>([])
  const [selectedContacts, setSelectedContacts] = useState<any[]>([])
  const { showDurationShort: showSnackbarDuration } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()
  const { setQueryData } = useQueryParamsGen2({
    scope: SCOPES.EDIT_CONTACT_INFO,
  })

  useEffect(() => {
    // we have some reducers that do this formatting that need to be pulled out
    if (
      contactTypes &&
      contactTypes.length > 0 &&
      contactTypes[0].key !== undefined
    ) {
      setFormattedTypes(contactTypes)
      return
    }

    const types = utils.getSelectOptions(contactTypes, {
      valueKey: 'ID',
      labelKey: 'Description',
    })
    setFormattedTypes(types)
  }, [contactTypes])

  const { TableDisplay, refresh } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      onRowClick: handleOnRowClick,
      onCheckHandler: disableCheckHandler ? undefined : handleSelected,
      defaultSort,
      initPageSize,
      RightHeaderItems: RightHeaderItems || (
        <>
          {renderSelectedButtons()}
          <Button
            color="primary"
            variant="contained"
            onClick={() => setQueryData({ editContactID: 0 })}>
            Add New
          </Button>
        </>
      ),
      DataTableProps: {
        rowOptsApplier: (row: any) =>
          !row.Active ? { className: 'tr-warning' } : null,
        rowsPerPage: [10, 25, 50, 100],
      },
    },
    ref
  )

  function handleOnRowClick(row: any, cmdClicked: boolean): void {
    if (!!onRowClick) return onRowClick(row, cmdClicked)
    setQueryData({ editContactID: row.ID })
  }

  function handleSelected(selected: any[]): void {
    if (selected.length === 0) return setSelectedContacts([])
    setSelectedContacts(selected)
  }

  function renderSelectedButtons() {
    if (!selectedContacts.length) return <></>
    return (
      <>
        <SelectedButton onClick={copyEmails} color="primary" variant="outlined">
          Copy Emails
        </SelectedButton>
        <SelectedButton
          onClick={handleDeleteContacts}
          color="secondary"
          variant="contained">
          Remove
        </SelectedButton>
      </>
    )
  }

  function copyEmails() {
    const emails = selectedContacts.map((item) => item.Email)
    utils.updateClipboard(
      emails.join(','),
      () => {
        showSnackbarDuration('Copied to clipboard!', SnackbarTypeSuccess)
      },
      () => {
        showSnackbarDuration(
          'Unable to copy emails for some reason!',
          SnackbarTypeError
        )
      }
    )
  }

  const handleOnClose = () => {
    setQueryData({})
  }
  const handleSaveNew = (contact: any) => {
    setQueryData({})
    handleSaveNewContact(contact)
      .then(() => {
        showSnackbarDuration('Contact Added Successfully!', SnackbarTypeSuccess)
        refresh()
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to Add New Contact, please contact Engineering',
        })
      )
  }
  const handleEditContact = (contact: any) => {
    saveContactV2(contact)
      .then(() => {
        showSnackbarDuration(
          'Contact Updated Successfully!',
          SnackbarTypeSuccess
        )
        refresh()
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to Update Contact, please contact Engineering',
        })
      )
      .finally(() => {
        setQueryData({})
      })
  }

  function handleDeleteContacts() {
    ConfirmDialog({
      content: `Do you really want to delete the selected Contact(s)?`,
      onConfirm() {
        const ids = selectedContacts.map((contact) => contact.ID)
        deleteContactsV2(ids).then((res: any) => {
          if (res.Error) {
            showSnackbarDuration(
              'There was a problem deleting the selected contacts. Please contact engineering.',
              SnackbarTypeError
            )
          } else {
            showSnackbarDuration('Contact(s) Deleted!', SnackbarTypeSuccess)
            refresh()
          }
        })
      },
    })
  }

  return (
    <>
      {TableDisplay}

      <DialogEditContact
        handleClose={handleOnClose}
        addNew={handleSaveNew}
        save={handleEditContact}
        contactTypes={formattedTypes}
      />
    </>
  )
})

export const SwitchInactive = (): React.ReactElement => {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <>
      <Switch
        color="primary"
        onChange={(e: any) =>
          setFilterImmediate({ ...filter, IncludeInactive: e.target.checked })
        }
        checked={filter.IncludeInactive}
      />
      <Typography>Show Inactive</Typography>
    </>
  )
}

export const FilterIsPrimaryContact = (): React.ReactElement => {
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)

  return (
    <>
      <Switch
        color="primary"
        onChange={(e: any) =>
          setFilterImmediate({ ...filter, IsPrimary: e.target.checked })
        }
        checked={filter.IsPrimary}
      />
      <Typography>Is Primary</Typography>
    </>
  )
}

interface ContactTypeFilterProps {
  entityType: ContactTypeOwner
}

export function ContactTypeTableFilter({
  entityType,
}: ContactTypeFilterProps): React.ReactElement {
  const { filter, setFilter } = useContext(stdTableSetup.baseContext)

  const handleChange = (ct: ContactTypeData | null) => {
    if (!ct) return setFilter({ ...filter, ContactTypeIDs: null })
    setFilter({ ...filter, ContactTypeIDs: [ct.ID] })
  }

  return <ContactTypeFilter entityType={entityType} onChange={handleChange} />
}

function defaultAPIEndpoint(payload: any): Promise<any> {
  return ContactActions.findContacts(payload).payload
}
