import React, { forwardRef } from 'react'
import styled from 'styled-components'

enum Colors {
  GREEN = '#009688',
  YELLOW = '#c6c639',
  RED = '#f50057',
  GREY = '#999',
}

/*
This export style forces usage of the component to be "namespaced" (serious quote unquote)...
In short, it should be used like this:

import StatusDot from 'path/to/StatusDot'

function Component() {
  ...
  return (
    <StatusDot.Dot color={StatusDot.Colors.GREEN} />
  )
}
*/
export default {
  Colors,
  Dot: styled(
    forwardRef<any, any>(
      ({ color = Colors.GREY, ...spread }: { color?: string }, ref: any) => {
        return (
          <span
            {...spread}
            style={{ background: color, boxShadow: `0 0 6px ${color}` }}
            ref={ref}></span>
        )
      }
    )
  )`
    line-height: 1;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-left: 0.35rem;
    margin-right: 0.35rem;
    border-radius: 50%;
    background: #999;
    box-shadow: 0 0 6px #999;
  `,
}
