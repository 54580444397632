import React, { useEffect, useState } from 'react'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {
  getSearchPGPKeys,
  getPGPKey,
} from '../../../actions/EngineeringActions'
import debounce from 'lodash/debounce'

interface DataItem {
  ID: number
  Name: string
}

interface Props {
  selectedPGPKeyPublicID: number | null
  onChange(pgpKeyPublicID: number | null, pgpKey: any): void
  AutoCompleteProps?: object
  TextFieldProps?: object
  disabled?: boolean
  disableClear?: boolean
  enableAddNew?: boolean
  withFilters?: object
}

export default function PGPKeySearcher({
  selectedPGPKeyPublicID,
  onChange,
  AutoCompleteProps = {},
  TextFieldProps = {},
  disableClear = false,
  enableAddNew = false,
  withFilters = {},
  ...props
}: Props): React.ReactElement | null {
  const [query, setQuery] = useState<string>('')
  const [data, setData] = useState<Array<DataItem>>(Array<DataItem>())
  const [current, setCurrent] = useState<DataItem | null>(null)
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (!selectedPGPKeyPublicID) return
    if (current && current.ID === selectedPGPKeyPublicID) return
    getPGPKey(selectedPGPKeyPublicID)
      .then((res: any) => {
        if (res.error) throw res
        setCurrent({ ID: res.Data?.ID, Name: res.Data?.Name })
        // onChange(res.Data?.ID, res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Search failed; please contact Engineering',
        })
      )
  }, [selectedPGPKeyPublicID, current, setCurrent])

  useEffect(() => {
    getSearchPGPKeys({ filter: { q: query, nolimit: true, ...withFilters } })
      .then((res: any) => {
        if (res.error) throw res
        if (!(res.Data || []).length) return
        setData(
          res.Data?.map((p: any) => {
            return { ID: p.ID, Name: p.Name, ...p }
          })
        )
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Search failed; please contact Engineering',
        })
      )
  }, [query, setData])

  const onSelectionChange = (_: any, b: DataItem | null, reason: string) => {
    setCurrent(b)
    onChange(b ? b.ID : null, b)
  }

  const onInputChange = debounce((ev: any, q: string, r: string) => {
    if (r === 'reset') return
    setQuery(q)
  }, 350)

  return (
    <>
      <Autocomplete
        disabled={props.disabled}
        options={data}
        getOptionLabel={(opt: DataItem) => opt.Name}
        getOptionSelected={(opt: DataItem) => {
          return current ? opt.ID === current.ID : false
        }}
        defaultValue={null}
        value={current}
        onChange={onSelectionChange}
        onInputChange={onInputChange}
        disableClearable={disableClear}
        renderInput={(params: any) => (
          <TextField
            {...params}
            label="PGP Key (**Public Key**)"
            placeholder="Start typing to search..."
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: true }}
            {...TextFieldProps}
          />
        )}
        {...AutoCompleteProps}
      />
    </>
  )
}
