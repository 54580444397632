import React from 'react'
import {
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  OutlinedInput,
  Tooltip,
  InputAdornment,
} from '@material-ui/core'
import DesignSuite2023 from '../../components/DesignSuite2023'
import { reasons } from '../../models/Loa'

interface props {
  onChange(reason: string | null): void
  selectedReason: string | null
}

export default function LOAStatusPicker({
  onChange,
  selectedReason,
}: props): React.ReactElement {
  const handleChange = (e: any, sel: any) => {
    if (!sel.props.value || sel.props.value === 'none') return onChange(null)
    onChange(sel.props.value)
  }

  const tooltip = (
    <InputAdornment position="start">
      <Tooltip title="Reason why LOA was done and not added to fee schedule">
        <DesignSuite2023.CommonIcons.IconInfo fontSize="small" />
      </Tooltip>
    </InputAdornment>
  )

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      style={{ width: '100%' }}>
      <InputLabel shrink>Reason</InputLabel>
      <Select
        autoWidth
        value={selectedReason || 'none'}
        onChange={handleChange}
        input={
          <OutlinedInput notched label="Reason" startAdornment={tooltip} />
        }>
        <MenuItem key="none" value="none">
          None
        </MenuItem>
        {reasons.map((reason: any) => (
          <MenuItem key={reason.value} value={reason.value}>
            {reason.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
