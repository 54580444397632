import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {
  Grid,
  Button,
  Paper,
  Divider,
  Tabs,
  Tab,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  List,
  ListItemIcon,
} from '@material-ui/core'
import { Check, Note } from '@material-ui/icons'
import queryString from 'query-string'
import _ from 'lodash'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

import Models from '../../models'
import ComponentFeeScheduleFileTableContainer from './ComponentFeeScheduleFileContainer'
import {
  STATUS_DRAFT,
  STATUS_PRODUCTION,
} from '../../models/ComponentFeeSchedule'
import PriceTable from '../Prices/PriceTable'
import { OrganizationSearcherV2 } from '../../components/Searchers'
import { FeeScheduleRelationsManager } from './FeeScheduleRelationsManager.js'
import AddComponentPriceDialog from './AddComponentPriceDialog.js'
import ComponentFeeScheduleImport from './ComponentFeeScheduleImport'
import { renderSelectField } from '../ViewHelpers'
import DetailView, {
  defaultStyleCreator,
} from '../../components/DetailView/DetailView'
import {
  ComponentFeeScheduleActions,
  SnackbarActions,
  PriceActions,
  clearSaveResult,
} from '../../actions'
import dateTime from '../../utils/dateTime'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
const {
  cloneComponentFeeSchedule,
  moveComponentToProduction,
  getNewComponentFeeSchedule,
  saveNewComponentFeeSchedule,
  saveComponentFeeSchedule,
  getComponentFeeSchedule,
  getComponentFeeScheduleTypes,
  SAVE_NEW_COMPONENT_FEE_SCHEDULE,
  MOVE_COMPONENT_TO_PRODUCTION,
  CLONE_COMPONENT_FEE_SCHEDULE,
} = ComponentFeeScheduleActions
const { findComponentPrices } = PriceActions
const { setSnackbarMessage } = SnackbarActions

const styles = (theme) => {
  const defaults = defaultStyleCreator(theme)

  return Object.assign(
    {
      slider: {
        padding: '22px 0px',
      },
      childContainer: {
        minHeight: 600,
      },
      center: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      },
      field: {
        marginLeft: 8,
      },
      productionIndicator: {
        fontSize: 38,
      },
      noPrices: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 20,
      },
    },
    defaults
  )
}

export class ComponentFeeSchedule extends DetailView {
  constructor(props) {
    super(props)
    const { match, location } = props

    this.state = {
      saving: false,
      id: match.params.id,
      fields: Object.assign({}, Models.ComponentFeeSchedule.editFields),
      tabValue: 0,
      manageFacilitiesOpen: false,
      applyToOrgOpen: false,
      addPriceOpen: false,
      org: location.state ? location.state.org : null,
      // See PriceTable.js; it really needs to be refactored to use modern table
      // component patterns (specifically, where you can pass a REF to it and *this*
      // component can call .refresh() on it)
      priceTableUpdateCounter: 0,
    }

    this.tabs = {
      prices: 0,
      files: 1,
    }
  }

  handleAddPrice = () => {
    this.setState({ addPriceOpen: true })
  }

  renderChildTabs() {
    const { componentFeeSchedule, classes } = this.props
    if (!componentFeeSchedule || !componentFeeSchedule.ID) return <div />
    return (
      <div>
        <Tabs
          value={this.state.tabValue || 0}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="inherit">
          <Tab value={this.tabs.prices} label="Prices" />
          <Tab value={this.tabs.files} label="Files" />
        </Tabs>
        <div className={classes.childContainer}>
          {this.renderChildTabContent()}
        </div>
      </div>
    )
  }

  renderChildTabContent() {
    const { componentFeeSchedule } = this.props
    const { tabValue, fields } = this.state
    const { ID } = componentFeeSchedule
    const isProd = fields.Status === STATUS_PRODUCTION

    if (tabValue === this.tabs.prices) {
      return (
        <>
          <div style={{ marginBottom: '1rem' }}>
            <PriceTable
              fsId={ID * 1}
              production={isProd}
              allowEditing={!isProd}
              canDeactivate={!(isProd && fields.EndDate)}
              isComponent={true}
              updateCounter={this.state.priceTableUpdateCounter}
            />
          </div>

          <Grid container spacing={4} justify="space-between">
            <Grid item xs="auto">
              <ComponentFeeScheduleImport
                componentFeeScheduleID={ID * 1}
                onSuccess={() => {
                  this.setState({
                    priceTableUpdateCounter:
                      this.state.priceTableUpdateCounter + 1,
                  })
                }}
              />
            </Grid>
            <Grid item xs="auto">
              <Button
                onClick={this.handleAddPrice}
                color="secondary"
                variant="contained">
                Add Price
              </Button>
            </Grid>
          </Grid>
        </>
      )
    }

    if (tabValue === this.tabs.files) {
      return <ComponentFeeScheduleFileTableContainer id={ID * 1} />
    }

    return null
  }

  componentWillUnmount() {
    this.props.getNewComponentFeeSchedule()
    this.props.clearSaveResult()
  }

  componentDidMount() {
    const {
      match,
      history,
      getComponentFeeSchedule,
      getNewComponentFeeSchedule,
      clearSaveResult,
      getComponentFeeScheduleTypes,
    } = this.props
    const { location } = history
    const qsParams = queryString.parse(location.search)
    const fsId = match.params.id
    clearSaveResult()
    getComponentFeeScheduleTypes()

    if (fsId) {
      this.setState({
        fields: Object.assign({}, Models.ComponentFeeSchedule.editFields, {
          ID: fsId,
        }),
      })
      this.goFindPrices(fsId)
      getComponentFeeSchedule({ id: fsId }).then((res) => {
        this.setState({ loading: false, tabValue: +qsParams.active_tab || 0 })
      })
    } else {
      getNewComponentFeeSchedule()
      this.setState({
        fields: Object.assign({}, Models.ComponentFeeSchedule.editFields),
        loading: false,
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newFeeSchedule = nextProps.componentFeeSchedule
    const curFeeSchedule = this.state.fields
    const { saveResult } = nextProps
    const { history } = this.props
    const { location } = history
    const qsParams = queryString.parse(location.search)

    if (!this.state.tabValue && +qsParams.active_tab)
      this.setState({ tabValue: +qsParams.active_tab || 0 })

    if (
      newFeeSchedule.ID !== curFeeSchedule.ID ||
      curFeeSchedule.UpdatedAt !== newFeeSchedule.UpdatedAt
    ) {
      const newFields = Object.assign(
        {},
        this.state.fields,
        _.omitBy(newFeeSchedule, _.isNull)
      )

      if (this.state.org) newFields.OrganizationID = this.state.org
      newFields.StartDate = dateTime.parse(newFields.StartDate).format()
      newFields.EndDate = dateTime.parse(newFields.EndDate).format()

      this.setState({
        fields: newFields,
        originalFields: newFields,
        changedFields: {},
      })
    }

    if (saveResult && saveResult !== this.props.saveResult) {
      if (saveResult.error) {
        const msg = `An error occurred while saving: ${saveResult.message}`
        this.props.setSnackbarMessage(msg, 'error')
      } else if (saveResult.type === SAVE_NEW_COMPONENT_FEE_SCHEDULE) {
        this.props.setSnackbarMessage(
          'Fee schedule successfully saved!',
          'success'
        )
        this.props.history.push(
          `/component_fee_schedule/${nextProps.componentFeeSchedule.ID}`
        )
      } else if (saveResult.type === MOVE_COMPONENT_TO_PRODUCTION) {
        this.props.setSnackbarMessage(
          'Moved to production successfully',
          'success'
        )
        this.setState({
          fields: {
            ...this.state.fields,
            Status: STATUS_PRODUCTION,
          },
        })
      } else if (saveResult.type === CLONE_COMPONENT_FEE_SCHEDULE) {
        this.props.setSnackbarMessage(
          'Fee schedule has been successfully cloned as a new draft',
          'success'
        )
        this.handleCloneSuccess(nextProps.componentFeeSchedule)
      } else {
        this.props.setSnackbarMessage(
          'Fee schedule successfully saved!',
          'success'
        )
      }
    }
  }

  goFindPrices = (fsId) => {
    const payload = {
      filter: { q: '', show: 'all', feeScheduleId: fsId },
      page: 1,
      pageSize: 10,
      sort: ['price', 'asc'],
    }
    this.props.findComponentPrices(payload)
  }

  validators = Object.freeze(Models.ComponentFeeSchedule.validators)

  handleSave = (e) => {
    this.props.clearSaveResult()
    if (this.validToSave()) {
      this.executeSave()
    }
  }

  reloadTabs = () => {
    const { tabValue } = this.state
    const { loading } = this.tabs
    this.setState({ tabValue: loading })
    setTimeout(() => {
      this.setState({ tabValue })
    }, 200)
  }

  handleManagedUpdate = () => {
    this.reloadTabs()
    this.setState({
      manageFacilitiesOpen: false,
      applyToOrgOpen: false,
      addPriceOpen: false,
    })
  }

  handleDialogCancel = () => {
    this.setState({
      manageFacilitiesOpen: false,
      applyToOrgOpen: false,
      addPriceOpen: false,
    })
  }

  handleMoveToProduction = () => {
    this.setState({ moveToProductionOpen: true })
  }

  confirmMoveToProduction = () => {
    this.setState({ moveToProductionOpen: false })
    this.moveToProduction()
  }

  renderConfirmMoveToProductionDialog = () => {
    return (
      <div>
        <Dialog
          open={this.state.moveToProductionOpen}
          onClose={() => {
            this.setState({ moveToProductionOpen: false })
          }}>
          <DialogTitle>Confirm Move from Draft to Production</DialogTitle>
          <DialogContent>
            Once in production, you will no longer be able to alter the dates on
            this schedule, or the dates on the prices associated with it.
          </DialogContent>
          <DialogActions>
            <Button onClick={this.confirmMoveToProduction} color="secondary">
              Confirm
            </Button>
            <Button
              onClick={() => {
                this.setState({ moveToProductionOpen: false })
              }}
              color="secondary">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  moveToProduction = () => {
    const { fields } = this.state
    if (fields.Status !== STATUS_PRODUCTION) {
      const fsToSave = Object.assign({}, this.state.fields)

      fsToSave.StartDate = dateTime
        .parse(fsToSave.StartDate)
        .format(dateTime.formats.ISODate)
      fsToSave.EndDate = dateTime
        .parse(fsToSave.EndDate)
        .format(dateTime.formats.ISODate)

      this.props.moveComponentToProduction(fsToSave.ID, fsToSave)
      return
    }

    this.props.setSnackbarMessage(
      'Cannot move to production, it is already in production',
      'error'
    )
  }

  handleAddNew = () => {
    this.props.history.push('/component_fee_schedule')
    window.location.reload()
  }

  handleClone = () => {
    const { componentFeeSchedule } = this.props
    const { ID } = componentFeeSchedule

    this.props.cloneComponentFeeSchedule({ id: ID })
  }

  handleCloneSuccess = (clonedFeeSchedule) => {
    this.props.history.push(`/component_fee_schedule/${clonedFeeSchedule.ID}`)
    window.location.reload()
  }

  renderOrgSearch = () => {
    return (
      <OrganizationSearcherV2
        selectedOrganizationID={this.state.fields.OrganizationID}
        onChange={this.handleOrgSelected}
      />
    )
  }

  handleOrgSelected = (orgID, obj) => {
    this.setState({
      fields: {
        ...this.state.fields,
        OrganizationID: orgID,
        OrganizationName: obj.Name,
      },
    })
  }

  renderOrgLink = () => {
    const { org } = this.state
    const { classes } = this.props
    const { OrganizationID, OrganizationName } = this.state.fields

    const name = org ? org.Name : OrganizationName
    const id = org ? org.ID : OrganizationID

    if (name && id) {
      const href = `${window.location.origin}/organization/${id}`
      return (
        <div className={classes.field}>
          <Typography variant="body1">
            Organization: <br />
            <a target="_blank" rel="noopener noreferrer" href={href}>
              {name}
            </a>
          </Typography>
        </div>
      )
    }
  }

  formatTypes = (types) => {
    const typeObjArray = []
    types.map((type) => typeObjArray.push({ label: type, value: type }))
    return typeObjArray
  }

  setter = (obj) => {
    this.setState({ fields: { ...this.state.fields, Type: obj.value } })
  }

  executeSave = () => {
    this.setState({ saving: true })
    const fsToSave = Object.assign({}, this.state.fields)

    fsToSave.StartDate = dateTime
      .parse(fsToSave.StartDate)
      .format(dateTime.formats.ISODate)
    fsToSave.EndDate = dateTime
      .parse(fsToSave.EndDate)
      .format(dateTime.formats.ISODate)

    if (fsToSave.ID) this.props.saveComponentFeeSchedule(fsToSave.ID, fsToSave)
    else {
      this.props.saveNewComponentFeeSchedule(fsToSave)
    }
  }

  renderContent = () => {
    const { classes, componentFeeSchedule, prices, org, componentTypes } =
      this.props
    const { fields } = this.state
    const { ID } = componentFeeSchedule
    const hasPrices = prices && prices.length
    return (
      <div>
        <Paper className={classes.mainContent} elevation={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3} className={classes.wideCardColumn}>
              <Card>
                <CardHeader
                  title="Component Fee Schedule Details"
                  subheader="Details about this specific fee schedule."
                />
                <CardContent>
                  {ID && (
                    <div>
                      <TextField
                        disabled
                        label="ID"
                        defaultValue={ID}
                        className={classes.wideField}
                        autoFocus
                      />
                    </div>
                  )}
                  {this.renderOrgLink()}
                  {!fields.OrganizationID && this.renderOrgSearch()}
                  {org && !ID && <Typography>Organization:</Typography>}
                  {org && !ID && <Typography>{org.Name}</Typography>}
                  {this.renderTextField('Name', 'Name', { wide: true })}
                  <div className={classes.field}>
                    {renderSelectField({
                      name: 'ComponentType',
                      label: 'Component Type',
                      items: componentTypes,
                      setter: this.setter,
                      value: fields.Type,
                      opts: {
                        disabled: fields.Status === STATUS_PRODUCTION,
                        wide: true,
                      },
                    })}
                  </div>
                  {this.renderNotesField({ style: { width: '100%' } })}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={3} className={classes.cardColumn}>
              <Card>
                <CardHeader
                  title="Activation Details"
                  subheader="When you activate, these properties will lock."
                />
                <CardContent>
                  {/* {this.renderDateField('StartDate', 'Start Date', {
                    disabled: fields.Status === STATUS_PRODUCTION,
                    showHelper: true,
                    helperText: 'The first day the fee schedule is active',
                  })} */}
                  <ManagedDateInput
                    name="StartDate"
                    label="Start Date"
                    value={this.state.fields.DateStart}
                    setter={({ name, value }) =>
                      this.handleFieldUpdate(name, value)
                    }
                    disabled={fields.Status === STATUS_PRODUCTION}
                    showHelper={true}
                    helperText={'The first day the fee schedule is active'}
                  />
                  {/* {this.renderDateField('EndDate', 'End Date', {
                    disabled:
                      fields.Status === STATUS_PRODUCTION &&
                      componentFeeSchedule.EndDate,
                    showHelper: true,
                    helperText:
                      'The first day the fee schedule will NOT be active.',
                  })} */}
                  <ManagedDateInput
                    name="EndDate"
                    label="End Date"
                    value={this.state.fields.EndDate}
                    setter={({ name, value }) =>
                      this.handleFieldUpdate(name, value)
                    }
                    disabled={
                      fields.Status === STATUS_PRODUCTION &&
                      componentFeeSchedule.EndDate
                    }
                    showHelper={true}
                    helperText={
                      'The first day the fee schedule will NOT be active'
                    }
                  />
                </CardContent>
              </Card>
            </Grid>
            {fields.Status === STATUS_DRAFT && ID && (
              <Grid item xs={12} sm={3} className={classes.cardColumn}>
                <Card>
                  <CardHeader
                    title="Draft Details"
                    subheader="Information about this draft fee schedule"
                  />
                  <CardContent className={classes.center}>
                    <Note
                      className={classes.productionIndicator}
                      color="primary"
                    />
                    <Typography>
                      This is a <strong>DRAFT</strong> component fee schedule.
                    </Typography>
                    <br />
                    <Typography>
                      You may adjust any information on this fee schedule, add
                      prices, remove prices as needed while it is draft mode.
                    </Typography>
                    <br />
                    <Typography>
                      When you are ready to put this fee schedule into
                      production, go through this checklist and then hit "Move
                      to Production"
                    </Typography>
                    <br />
                    <List>
                      <ListItemIcon>
                        <Check />
                        Has a start date.
                      </ListItemIcon>
                      <ListItemIcon>
                        <Check />
                        Has at least 1 price
                      </ListItemIcon>
                    </List>
                    <br />
                    <Button
                      color="secondary"
                      disabled={!hasPrices}
                      variant="contained"
                      onClick={this.handleMoveToProduction}>
                      Move to Production
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {fields.Status === STATUS_PRODUCTION && (
              <Grid item xs={12} sm={3} className={classes.cardColumn}>
                <Card>
                  <CardHeader
                    title="Production Details"
                    subheader="Details on when this fee schedule was moved from a draft into production"
                  />
                  <CardContent className={classes.center}>
                    <Check
                      className={classes.productionIndicator}
                      color="secondary"
                    />
                    <Typography>This is a production fee schedule.</Typography>
                    <Typography>
                      Activated by: {fields.StatusModifiedByUsername} on{' '}
                      {dateTime.parse(fields.StatusUpdatedAt).local().format()}
                    </Typography>
                    <br />
                    <Typography>
                      This fee schedule is now locked. If you need to extend the
                      dates on the fee schedule, or create a copy, please use
                      the "Clone fee schedule" option.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}
            <Grid item xs={12} sm={6} />
            <Grid item sm={6} lg={6}>
              {this.state.saveResult && this.state.saveResult.error && (
                <Typography type="title">
                  {this.state.saveResult.message}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <div className={classes.saveButtonContainer}>
                {this.renderStatusIcon()}
                <Button
                  color="secondary"
                  style={{ float: 'right', marginBottom: 15 }}
                  className={classes.button}
                  variant="contained"
                  onClick={this.handleSave}>
                  Save
                </Button>
                {ID && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{ float: 'right' }}
                    className={classes.button}
                    onClick={this.handleAddNew}>
                    Add Another FeeSchedule
                  </Button>
                )}
                {ID && (
                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{ float: 'right' }}
                    className={classes.button}
                    onClick={this.handleClone}>
                    Clone as Draft
                  </Button>
                )}
              </div>
            </Grid>
            <Grid item xs={12}>
              {componentFeeSchedule && ID && this.renderChanges()}
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.sectionDivider} />
            </Grid>
            <Grid item xs={12}>
              {this.renderChildTabs()}
            </Grid>
            {componentFeeSchedule && ID && this.renderConfirmUnsavedChanges()}
            {this.renderConfirmMoveToProductionDialog()}
          </Grid>
        </Paper>
      </div>
    )
  }

  render() {
    const { fields, manageFacilitiesOpen, applyToOrgOpen, addPriceOpen, org } =
      this.state
    const { getError } = this.props
    const { OrganizationID, OrganizationName, Status } = fields
    const isProd = Status === STATUS_PRODUCTION
    const name = org ? org.Name : OrganizationName
    const orgId = org ? org : OrganizationID

    return (
      <div>
        {getError ? this.renderError(getError.message) : this.renderContent()}
        {fields && fields.ID !== 0 && (
          <div>
            <FeeScheduleRelationsManager
              fsId={fields.ID}
              onConfirm={this.handleManagedUpdate}
              onCancel={this.handleDialogCancel}
              facilitiesOpen={manageFacilitiesOpen}
              orgId={orgId}
              orgName={name}
              orgOpen={applyToOrgOpen}
              isProd={isProd}
            />
            {addPriceOpen && (
              <AddComponentPriceDialog
                isProd={isProd}
                fsId={fields.ID}
                open={addPriceOpen}
                onSave={this.handleManagedUpdate}
                onCancel={this.handleDialogCancel}
              />
            )}
          </div>
        )}
      </div>
    )
  }
}

ComponentFeeSchedule.propTypes = {
  componentFeeSchedule: PropTypes.object,
}

ComponentFeeSchedule.defaultProps = {
  componentFeeSchedule: {},
}

function mapStateToProps(state) {
  return {
    componentFeeSchedule:
      state.componentFeeSchedules.componentFeeSchedule || {},
    componentTypes: state.componentFeeSchedules.componentTypes || [],
    saveResult: state.componentFeeSchedules.saveResult,
    fileSaveResult: state.componentFeeSchedules.fileSaveResult,
    getError: state.componentFeeSchedules.getError,
    prices: state.prices.prices,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      moveComponentToProduction,
      cloneComponentFeeSchedule,
      saveNewComponentFeeSchedule,
      saveComponentFeeSchedule,
      getNewComponentFeeSchedule,
      getComponentFeeSchedule,
      getComponentFeeScheduleTypes,
      setSnackbarMessage,
      clearSaveResult,
      findComponentPrices,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ComponentFeeSchedule)
