import React, { forwardRef, useContext, useState } from 'react'
import * as stdTableSetup from '../../hooks/useStandardTableSetup'
import { findOrgs } from '../../actions/OrganizationActions'
import { ActiveFormatter } from '../../utils/ReactFormatters'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import isBoolean from 'lodash/isBoolean'
// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../hooks/useStandardTableSetup'

export const defaultColumns = Object.freeze({
  Name: {
    name: 'Name',
    details: { width: '250px', sortName: 'Organization.Name' },
  },
  IsActive: {
    name: 'Active Provider',
    details: {
      width: '140px',
      dataFormat: (_: any, row: any) => (
        <ActiveFormatter active={row.IsActive} />
      ),
    },
  },
  Ranking: { name: 'Ranking' },
  Notes: { name: 'Notes' },
})

const defaultSort = { col: 'Organization.Name', dir: 'asc' }

export const Table = forwardRef(function OrgTableList(
  {
    customColumns = defaultColumns,
    apiEndpoint = findOrgs,
    fnLinkOnRowClick = (row: any) => `/organization/${row.ID}`,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      fnLinkOnRowClick,
      DataTableProps: {
        rowOptsApplier(row: any) {
          return row && row.IsActive ? null : { className: 'tr-warning' }
        },
        ...DataTableProps,
      },
    },
    ref
  )

  return <>{TableDisplay}</>
})

// Filter by active/inactive status
// note: material ui's select component *sucks* for using any value={}'s that aren't
// a string... YMMV
export function FilterStatus(): React.ReactElement {
  const [internalID] = useState(`${Math.round(Math.random() * 10000000000000)}`)
  const { filter, setFilterImmediate } = useContext(stdTableSetup.baseContext)
  const [items] = useState<Array<any>>([
    { value: 'all', label: 'Any' },
    { value: 'true', label: 'Active' },
    { value: 'false', label: 'Inactive' },
  ])

  const onChange = (e: any, sel: any) => {
    switch (sel.props?.value) {
      case 'true':
        setFilterImmediate({ activeStatus: true })
        return
      case 'false':
        setFilterImmediate({ activeStatus: false })
        return
    }
    setFilterImmediate({ activeStatus: null })
  }

  const v = isBoolean(filter.activeStatus) ? `${filter.activeStatus}` : 'all'

  return (
    <FormControl
      variant="outlined"
      margin="none"
      size="small"
      style={{ minWidth: 120 }}>
      <InputLabel shrink id={internalID}>
        Active Status
      </InputLabel>
      <Select
        labelId={internalID}
        value={v}
        onChange={onChange}
        label="Active Status"
        autoWidth>
        {items.map((item: any) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
