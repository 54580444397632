import * as api from '../services/thezerocard/api-helper'
export const FIND_REFERRALS = 'FIND_REFERRALS'
export const GET_REFERRAL_STATUSES = 'GET_REFERRAL_STATUSES'

export const findReferrals = (params) => {
  return {
    type: FIND_REFERRALS,
    payload: api.search('/referral', params),
  }
}

export const getReferralStatuses = () => {
  return {
    type: GET_REFERRAL_STATUSES,
    payload: api.get('/referral_statuses'),
  }
}

export const findReferralsV2 = (params) => {
  return api.search('/referral', params)
}
