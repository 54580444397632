import React, { useState } from 'react'
import { Chip, Popover } from '@material-ui/core'
import { randomID } from '../helpers'
import styled from 'styled-components'

const StyledConfirmDeletePopover = styled.div`
  padding: 0.5rem 0.65rem;
  font-size: 90%;
  color: #fff;
  background: #222;

  .yes {
    color: yellow;
    font-weight: bold;
    cursor: pointer;
  }
`

export default function DeletableChip({
  popMessage = 'Confirm?',
  onConfirm = () => {},
  ...props
}: any): React.ReactElement {
  const [id] = useState(randomID())
  const [popoverAnchor, setPopoverAnchor] = useState<any>(null)

  if (!props?.label) {
    console.warn('DeletableChip missing label prop (required)')
  }

  function onClickConfirm() {
    setPopoverAnchor(null)
    onConfirm && onConfirm()
  }

  return (
    <>
      <Chip
        id={id}
        size="small"
        {...props}
        style={{ margin: '0 2px', ...(props.style || {}) }}
        onDelete={(ev: any) => {
          setPopoverAnchor(ev.currentTarget)
        }}
      />
      <Popover
        id={id}
        open={!!popoverAnchor}
        anchorEl={popoverAnchor}
        onClose={() => setPopoverAnchor(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <StyledConfirmDeletePopover>
          {popMessage}{' '}
          <span className="yes" onClick={onClickConfirm}>
            Yes
          </span>
        </StyledConfirmDeletePopover>
      </Popover>
    </>
  )
}
