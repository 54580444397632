import React from 'react'
import NumberFormat from 'react-number-format'
import { Link } from 'react-router-dom'
import utils from '../utils'
import { fileTypeLinksFormatter } from '../utils/ReactFormatters'
import dateTime from '../utils/dateTime'

const READ_PERMISSION = 'r:loa'

export const columns = Object.freeze({
  ID: { name: 'ID' },
  Member: {
    name: 'Member',
    details: {
      dataFormat(_: any, row: LOADecoratedShape): any {
        const name = getMemberName(row)
        return (
          <span>
            {name}
            <br />
            {dateTime.parse(row.Member?.Dob).format()}
          </span>
        )
      },
    },
  },
  DateOfService: {
    name: 'Date of Service',
    details: {
      sortName: 'loa.date_of_service',
      dataFormat: dateTime.cellFormatter(),
    },
  },
  Price: {
    name: 'Price',
    details: {
      width: '125px',
      dataFormat(_: any, row: LOADecoratedShape): any {
        return (
          <span>
            Price:{' '}
            <NumberFormat
              value={utils.priceFormatter(row.Price)}
              displayType="text"
              thousandSeparator
              prefix="$"
            />
            <br />
            Typical:{' '}
            <NumberFormat
              value={utils.priceFormatter(row.TypicalPPOPrice)}
              displayType="text"
              thousandSeparator
              prefix="$"
            />
          </span>
        )
      },
    },
  },
  Employer: {
    name: 'Employer',
    details: {
      sortName: 'employer.name',
      dataFormat(_: any, row: LOADecoratedShape): any {
        return (
          <Link to={`/employer/${row.Employer?.ID}`} target="_blank">
            {row.Employer?.Name}
          </Link>
        )
      },
    },
  },
  Organization: {
    name: 'Org',
    details: {
      sortName: 'organization.name',
      dataFormat(_: any, row: LOADecoratedShape): any {
        return (
          <Link to={`/organization/${row.Organization?.ID}`} target="_blank">
            {row.Organization?.Name}
          </Link>
        )
      },
    },
  },
  Status: {
    name: 'Status',
    details: {
      width: '100px',
      dataFormat(_: any, row: LOADecoratedShape): any {
        return row.Status?.Descr
      },
    },
  },
  CostKey: {
    name: 'Codes',
    details: {
      sortName: 'loa.main_cpt_code',
      dataFormat(_: any, row: LOADecoratedShape): any {
        return (
          <span>
            CostKey: {row.CostKey?.Code}
            <br />
            MainCPT: {row.MainCptCode}
          </span>
        )
      },
    },
  },
  Files: { name: 'Files', details: { dataFormat: fileTypeLinksFormatter } },
})

// used in conjunction with 'useDiffChangedFields' hook
export const diffChangedFields = Object.freeze([
  { name: 'ContractDate', label: 'Contract Date' },
  { name: 'DateOfService', label: 'Date Of Service' },
  { name: 'Price', label: 'Price' },
  { name: 'ReferralID', label: 'Referral' },
  { name: 'TypicalPPOPrice', label: 'Typical PPO Price' },
  { name: 'BundleContents', label: 'Bundle Contents' },
  { name: 'EmployerId', label: 'Employer' },
  { name: 'OrganizationId', label: 'Organization' },
  { name: 'EmployerMemberId', label: 'Member' },
  { name: 'StatusId', label: 'Status' },
  { name: 'Reason', label: 'Reason' },
  { name: 'CostKeyId', label: 'Cost Key' },
  { name: 'MainCptCode', label: 'Main CPT' },
  { name: 'Notes', label: 'Notes' },
  { name: 'IsSplit', label: 'Split' },
])

export function getMemberName(l: LOADecoratedShape): string {
  if (!l.Member) return ``
  const { FirstName, LastName, FirstNameAka, LastNameAka } = l.Member
  return `${FirstNameAka || FirstName} ${LastNameAka || LastName}`
}

export function hasAccess() {
  return utils.checkPerms(READ_PERMISSION)
}

export const reasons = [
  {
    label: 'Provider did not want to add',
    value: 'Provider did not want to add',
  },
  { label: 'Below 30% savings', value: 'Below 30% savings' },
  {
    label: 'No or little data on code(s)',
    value: 'No or little data on code(s)',
  },
  { label: 'Procedure rarely happens', value: 'Procedure rarely happens' },
  { label: 'LOA only provider', value: 'LOA only provider' },
]

export interface LOARecordShape {
  ID: number | null
  CreatedAt: string | null
  UpdatedAt: string | null
  DeletedAt: string | null
  ContractDate: string | null
  DateOfService: string | null
  Price: string | null
  ReferralID: number | null
  TypicalPPOPrice: string | null
  BundleContents: string | null
  EmployerId: number | null
  OrganizationId: number | null
  EmployerMemberId: number | null
  StatusId: number | null
  Reason: string | null
  CostKeyId: number | null
  MainCptCode: string | null
  Notes: string | null
  IsSplit: boolean | null
  CreatedByUserID: number | null
  ModifiedByUserID: number | null
}

/*
Note: this is far more representative of the LOA query result, where the record
comes back decorated with a bunch of metadata.
*/
export interface LOADecoratedShape extends LOARecordShape {
  // The following are not part of the core LOA model, but returned from any endpoint
  ModifiedByUsername?: string | null
  IntegrationID?: string | null
  Status?: {
    ID: number | null
    Descr: string | null
  }
  Member?: {
    ID: number | null
    FirstName: string | null
    MiddleName: string | null
    LastName: string | null
    Suffix: string | null
    Honorific: string | null
    FirstNameAka: string | null
    LastNameAka: string | null
    Dob: string | null
  }
  Organization?: {
    ID: number | null
    Name: string | null
  }
  Employer?: {
    ID: number | null
    Name: string | null
    IsCustomer: boolean | null
    LOAProcessType: string | null
    PHANotes: string | null
  }
  CostKey?: {
    ID: number | null
    Code: string | null
    Descr: string | null
  }
}

export default {
  columns,
  getMemberName,
  hasAccess,
}
