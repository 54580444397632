import React, { useEffect, useState } from 'react'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { ReferrerActions } from '../../actions'
import debounce from 'lodash/debounce'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import './styles.scss'
const { findReferrers, getReferrer } = ReferrerActions

interface DataItem {
  ID: number
  Name: string
}

interface Props {
  selectedReferrerID: number | null
  onChange(referrerID: number | null, referrer: any): void
  AutoCompleteProps?: object
  TextFieldProps?: object
  disabled?: boolean
  disableClear?: boolean
}

const ReferrerSearcher = ({
  selectedReferrerID,
  onChange,
  AutoCompleteProps = {},
  TextFieldProps = {},
  disableClear = false,
  ...props
}: Props) => {
  const [query, setQuery] = useState<string>('')
  const [data, setData] = useState<Array<DataItem>>(Array<DataItem>())
  const [active, setActive] = useState<DataItem | null>(null)
  const snackbar = useSnackbar()

  useEffect(() => {
    if (!selectedReferrerID) {
      return
    }
    if (active && active.ID === selectedReferrerID) {
      return
    }
    getReferrer(selectedReferrerID)
      .then((res: any) => {
        setActive({ ID: res.ID, Name: res.Name })
      })
      .catch((err: string) => {
        snackbar.show(err, SnackbarTypeError)
      })
  }, [selectedReferrerID, active])

  useEffect(() => {
    findReferrers({})
      .then((res: any) => {
        setData(
          res.map((p: any) => {
            return { ID: p.ID, Name: p.Name, ...p }
          })
        )
      })
      .catch((err: string) => {
        snackbar.show(err, SnackbarTypeError)
      })
  }, [query])

  const onInputChange = debounce((ev: any, q: string, r: string) => {
    setQuery(q)
  }, 350)

  const onSelectionChange = (_: any, b: DataItem | null, reason: string) => {
    setActive(b)
    onChange(b ? b.ID : null, b)
  }

  return (
    <Autocomplete
      classes={{ root: 'autocomplete-root' }}
      disabled={props.disabled}
      options={data}
      getOptionLabel={(opt: DataItem) => opt.Name}
      getOptionSelected={(opt: DataItem) => {
        return active ? opt.ID === active.ID : false
      }}
      defaultValue={null}
      value={active}
      onChange={onSelectionChange}
      onInputChange={onInputChange}
      disableClearable={disableClear}
      renderInput={(params: any) => (
        <TextField
          {...params}
          label="Referrer"
          placeholder="Start typing to search referrers..."
          {...TextFieldProps}
        />
      )}
      {...AutoCompleteProps}
    />
  )
}

export default ReferrerSearcher
