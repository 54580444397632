import React from 'react'
import { TextField } from '@material-ui/core'

type Props = React.ComponentProps<typeof TextField> & {
  id: string
}

const NotesField: React.FC<Props> = (props) => {
  // setting a key prevents label overlap because material-ui is wonky
  // https://stackoverflow.com/questions/57455970/material-ui-textfield-outline-label-is-overlapping-with-border-when-conditionall
  return <TextField key={props.id} {...props} />
}

export default NotesField
