import { AUTO_SUGGEST_SELECTED, CLEAR_AUTO_SUGGEST_SELECTION } from '../actions'

export default function (state = null, action) {
  switch (action.type) {
    case AUTO_SUGGEST_SELECTED:
      return {
        ...state,
        [action.stateKey]: action.selected ? { ...action.selected } : null,
      }
    case CLEAR_AUTO_SUGGEST_SELECTION:
      return {
        ...state,
        [action.payload]: null,
      }
    default:
      return {
        ...state,
      }
  }
}
