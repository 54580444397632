import React, { useEffect, useState } from 'react'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import {
  renderNotesField as RenderNotesField,
  SetterArgs,
} from '../../../components/Inputs/standard'
import {
  postVoidInvoice,
  PostVoidInvoiceParams,
} from '../../../actions/ZClaimFundingActions'
import styled from 'styled-components'
import dateTime from '../../../utils/dateTime'

interface voidInvoiceInfo {
  InvoiceID: number
  EmployerName: string
  InvoiceAmount: string
}

type props = {
  data: voidInvoiceInfo
  disabled: boolean
  hidden: boolean
  onClose?(): void
}

const StyledContent = styled.div`
  .autocomplete-root {
    width: auto;
    .MuiFormControl-fullWidth {
      min-width: 0;
    }
  }
`

function initialPostVoidInvoiceParams(): PostVoidInvoiceParams {
  return {
    voidDate: dateTime.now().format(dateTime.formats.ISODate),
    notes: null,
  }
}

export default function VoidInvoice({
  data,
  disabled,
  hidden,
  onClose,
}: props): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [isWorking, setIsWorking] = useState(false)
  const [postVoidInvoiceParams, setPostVoidInvoiceParams] =
    useState<PostVoidInvoiceParams>(initialPostVoidInvoiceParams())

  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  useEffect(() => {
    setPostVoidInvoiceParams(initialPostVoidInvoiceParams())
    if (!isOpen) onClose?.()
  }, [isOpen])

  const setter = ({ name, value }: SetterArgs) => {
    setPostVoidInvoiceParams((prev) => ({ ...prev, [name]: value }))
  }

  const handleClose = () => {
    onClose?.()
    setIsOpen(false)
  }

  function doPostVoidInvoice() {
    setIsWorking(true)
    let params: PostVoidInvoiceParams = postVoidInvoiceParams

    postVoidInvoice(data.InvoiceID, params)
      .then((res: any) => {
        showSnackbar(`Void invoice posted OK`, SnackbarTypeSuccess)
        handleClose()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed posting void invoice',
        })
      )
      .finally(() => setIsWorking(false))
  }

  return (
    <>
      <Button
        variant="outlined"
        disabled={disabled}
        hidden={hidden}
        size="small"
        color="secondary"
        onClick={() => {
          setIsOpen(true)
        }}>
        Void Invoice
      </Button>

      <Dialog
        open={isOpen}
        onClose={() => {
          // no-op: keep dialog open unless finished or user clicks cancel
        }}
        maxWidth={'lg'}>
        <DialogTitle>Void Invoice: {data.EmployerName}</DialogTitle>
        <DialogContent style={{ width: '1080px' }}>
          <StyledContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3}>
                <ManagedDateInput
                  name="voidDate"
                  label="Void Date"
                  value={postVoidInvoiceParams.voidDate}
                  setter={setter}
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  {formatAmount(data.InvoiceAmount)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <RenderNotesField
                  name="notes"
                  label="Notes"
                  value={postVoidInvoiceParams.notes}
                  setter={setter}
                />
              </Grid>
            </Grid>
          </StyledContent>
        </DialogContent>

        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <>
                <Button
                  disabled={isWorking}
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </>
            }
            right={
              <>
                <Button
                  disabled={isWorking || !postVoidInvoiceParams.voidDate}
                  color="primary"
                  variant="outlined"
                  onClick={doPostVoidInvoice}>
                  Void Invoice
                  {isWorking && (
                    <>
                      &nbsp;
                      <DesignSuite2023.LoadingSpinner
                        size={20}
                        show={isWorking}
                      />
                    </>
                  )}
                </Button>
              </>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

function formatAmount(amount: string): string {
  return `$${amount.toLocaleString()}`
}
