import React, { useState, useEffect, useRef } from 'react'
import { postPracticeFacilityImport } from '../../actions/OrganizationActions'
import papa from 'papaparse'
import isNumber from 'lodash/isNumber'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  RadioGroup,
  Radio,
  Tooltip,
  CircularProgress,
} from '@material-ui/core'
import { Info as IconInfo } from '@material-ui/icons'
require('./practiceFacilitiesImport.scss')

interface props {
  orgID: number | null
  onImportSuccess?(): void
}

interface dryRunResultPlan {
  New: any // map: { [CSVLine#] : true }
  Omissions: any[]
  Matches: any // map { [CSVLine#] : <match data> }
  Runnable: boolean
  PreviouslyTermdCount: number
}

interface importResults {
  NewRecords: number
  TermdByOmission: number
  Updates: number
  PreviouslyTermdCount: number
  FileObj: any // a file record
}

enum matchedAction {
  Ignore = 'ignore',
  Update = 'update',
}

const columns = [
  { name: 'Name', required: true },
  { name: 'Address1', required: false },
  { name: 'Address2', required: false },
  { name: 'City', required: false },
  { name: 'State', required: false },
  { name: 'Zip', required: false },
  { name: 'Phone', required: false },
  { name: 'Fax', required: false },
  { name: 'Email', required: false },
  { name: 'NPI', required: false },
  { name: 'LaunchDate', required: false },
  { name: 'TerminationDate', required: false },
  { name: 'ExternalID', required: true },
]

const defaultCSVMap = {
  Name: 1,
  Address1: 2,
  Address2: 3,
  City: 4,
  State: 5,
  Zip: 6,
  Phone: 7,
  Fax: 8,
  Email: 9,
  NPI: 10,
  LaunchDate: 11,
  TerminationDate: 12,
  ExternalID: 13,
}

const requiredCols: any = {
  Name: true,
  ExternalID: true,
}

export default function DialogPracticeFacilitiesImport({
  orgID,
  onImportSuccess,
}: props & Partial<any>): React.ReactElement {
  const { catchAPIError } = useErrorHandlers()
  const [fileObj, setFileObj] = useState<File | null>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [csvRows, setCSVRows] = useState<any[]>([])
  const [csvErrors, setCSVErrors] = useState<any[] | null>(null)
  const [firstRowIsHeader, setFirstRowIsHeader] = useState(true)
  const [csvMapping, setCSVMapping] = useState<any>({})
  const [dryRunPlan, setDryRunPlan] = useState<dryRunResultPlan | null>(null)
  const [mustRecalcPlan, setMustRecalcPlan] = useState(false)
  const [applyOmissions, setApplyOmissions] = useState(true)
  const [defaultLaunchDateTodayIfUnset, setDefaultLaunchDateTodayIfUnset] =
    useState(true)
  const [actionWithMatch, setActionWithMatch] = useState<matchedAction>(
    matchedAction.Update
  )
  const [readyToRun, setReadyToRun] = useState(false)
  const [importReport, setImportReport] = useState<importResults | null>(null)
  const [isWorking, setIsWorking] = useState(false)
  const refFileInput = useRef<HTMLInputElement>(null)

  useEffect(doRestart, [isOpen])

  /*
    Watch the selected file obj from the file input, and if it changes, re-parse the CSV file
  */
  useEffect(() => {
    if (!fileObj) {
      doRestart()
      return
    }
    // reset CSV errors first
    setCSVErrors(null)
    // execute parsing
    papa.parse(fileObj, {
      complete(results: any) {
        if (Array.isArray(results.errors) && results.errors.length >= 1) {
          setCSVErrors(results.errors)
          return
        }
        setCSVRows(results.data || [])
      },
    })
  }, [fileObj, setCSVRows])

  /*
    Watch the dryRunPlan, and propagate the 'Runnable' boolean to readyToRun state. In this
    way, we let the backend drive whether the plan should be considered runnable
  */
  useEffect(() => {
    if (!dryRunPlan) {
      setReadyToRun(false)
      return
    }
    setReadyToRun(dryRunPlan.Runnable)
  }, [dryRunPlan])

  /*
    Important: watch the firstRowIsHeader and csv column mappings (eg. relevant configurations),
    and if a dryRunPlan already exists, then when those ^ things change, we need to recalculate the
    plan.
  */
  useEffect(() => {
    if (!dryRunPlan) {
      return
    }
    setDryRunPlan(null)
    setMustRecalcPlan(true)
    setActionWithMatch(matchedAction.Update)
    setApplyOmissions(true)
  }, [firstRowIsHeader, csvMapping])

  function doRestart() {
    setFileObj(null)
    setCSVRows([])
    setCSVErrors(null)
    setFirstRowIsHeader(true)
    setCSVMapping({})
    setDryRunPlan(null)
    setMustRecalcPlan(false)
    setApplyOmissions(true)
    setActionWithMatch(matchedAction.Update)
    setReadyToRun(false)
    setImportReport(null)
    setIsWorking(false)
    if (refFileInput.current) {
      refFileInput.current.value = ''
    }
  }

  function canRunImport(): boolean {
    if (actionWithMatch === null) {
      return false
    }
    if (!readyToRun) {
      return false
    }
    return true
  }

  function doImportDryRun() {
    send()
      .then((res: any) => {
        // @todo: techdebt: legacy of different response shapes and not always trigger promise.Reject from API helpers
        if (res.error) {
          throw res
        }
        setDryRunPlan(res.Data)
        setMustRecalcPlan(false)
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Planning stage (dry run) for the facility import failed; will not be able to continue',
        })
      )
  }

  function doRunImport() {
    send({
      execute: true,
      actionWithMatch,
      applyOmissions,
      defaultLaunchDateTodayIfUnset,
    })
      .then((res: any) => {
        // @todo: techdebt: legacy of different response shapes and not always trigger promise.Reject from API helpers
        if (res.error) {
          throw res
        }
        setImportReport(res.Data)
        onImportSuccess && onImportSuccess()
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Import failed; please see API logs (or contact engineering) for more info',
        })
      )
  }

  function send(mergeProps = {}) {
    if (!fileObj) {
      return
    }
    if (orgID === null) {
      return
    }
    setIsWorking(true)
    const data = new FormData()
    data.append('file', fileObj, fileObj.name)
    data.append(
      'instructions',
      JSON.stringify({
        ...mergeProps,
        firstRowIsHeader,
        csvMapping,
      })
    )
    return postPracticeFacilityImport(orgID, data).finally(() => {
      setIsWorking(false)
    })
  }

  function onFileChange() {
    const f = (refFileInput as any).current?.files[0]
    if (!f) {
      setFileObj(null)
    }
    setFileObj(f)
  }

  function setDefaultCSVMap() {
    setCSVMapping({ ...defaultCSVMap })
  }

  function hasRequiredColsMapped(): boolean {
    for (let k in requiredCols) {
      if (!isNumber(csvMapping[k])) {
        return false
      }
    }
    return true
  }

  function displayStep(is = ''): boolean {
    let step = 'default'
    if (!!csvErrors) {
      step = 'csverrlist'
    }
    if (!!importReport) {
      step = 'report'
    }
    return step === is
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          setIsOpen(true)
        }}>
        Import Facilities
      </Button>

      <Dialog id="practice-facilities-import" open={isOpen} maxWidth={false}>
        <DialogTitle>Import Practice Facilities</DialogTitle>
        {displayStep('csverrlist') && csvErrors && (
          <DialogContent>
            <div className="csv-errors">
              <h4>Invalid CSV File</h4>
              <p>
                The file you selected cannot be parsed as a valid CSV file.
                Details below...
              </p>
              <ul>
                {csvErrors.map((e: any, index: number) => (
                  <li key={index}>
                    [<strong>Code:</strong> {e.code}] <strong>Row:</strong>{' '}
                    {e.row} / <strong>Index</strong> {e.index}: {e.message}
                  </li>
                ))}
              </ul>
            </div>
          </DialogContent>
        )}

        {displayStep('report') && importReport && (
          <DialogContent>
            <div className="import-report">
              <strong>New Facilities</strong>{' '}
              <span>{importReport.NewRecords}</span>
              <strong>Updates</strong> <span>{importReport.Updates}</span>
              <strong>Terminations</strong>{' '}
              <span>{importReport.TermdByOmission}</span>
              <strong>File Info</strong>{' '}
              <span>
                {importReport.FileObj?.S3Key.split('/').slice(-1)[0]} (FileID:{' '}
                {importReport.FileObj?.ID})
              </span>
            </div>
          </DialogContent>
        )}

        {displayStep('default') && (
          <DialogContent>
            <Grid
              container
              spacing={2}
              justify="space-between"
              alignItems="center">
              <Grid item xs>
                {/* important, set the style to display:none, DO NOT stop it from rendering though (as we need the <input /> still in the DOM for file object access) */}
                <div style={{ display: !!fileObj ? 'none' : 'block' }}>
                  <p>
                    Start by selecting a CSV file to import. The import will
                    <br />
                    not run immediately; this is a guided process.
                  </p>
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      className="file-picker-btn"
                      variant="contained"
                      component="label"
                      color="primary">
                      {(!!fileObj && fileObj.name) || 'Select File'}
                      <input
                        type="file"
                        ref={refFileInput}
                        onChange={onFileChange}
                        accept=".csv"
                      />
                    </Button>
                  </div>
                </div>
                {!!fileObj && <strong>File: {fileObj.name}</strong>}
              </Grid>
              {!!fileObj && (
                <>
                  <Grid item xs="auto">
                    <FormControlLabel
                      className="checkbox-control"
                      label="First row in CSV file contains headers"
                      control={
                        <Checkbox
                          checked={firstRowIsHeader}
                          onChange={(
                            ev: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setFirstRowIsHeader(ev.target.checked)
                          }}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <Button variant="outlined" onClick={setDefaultCSVMap}>
                      Use Default CSV Mapping
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>

            {csvRows.length >= 1 && (
              <>
                <CSVDisplay
                  dryRunPlan={dryRunPlan}
                  csvMapping={csvMapping}
                  setCSVMapping={setCSVMapping}
                  csvRows={csvRows}
                  firstRowIsHeader={firstRowIsHeader}
                />

                {!!dryRunPlan && (
                  <>
                    <section>
                      <h4>
                        Action With Matches <small>Required</small>
                      </h4>
                      <RadioGroup
                        row
                        name="matched-action"
                        value={actionWithMatch}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                          setActionWithMatch(ev.target.value as matchedAction)
                        }}>
                        <FormControlLabel
                          value={matchedAction.Ignore}
                          control={<Radio />}
                          label="Ignore"
                        />
                        <FormControlLabel
                          value={matchedAction.Update}
                          control={<Radio />}
                          label="Update"
                        />
                      </RadioGroup>

                      {dryRunPlan.PreviouslyTermdCount >= 1 && (
                        <>
                          <h4>Termination Date Changes</h4>
                          <p>
                            We detected{' '}
                            <strong>{dryRunPlan.PreviouslyTermdCount}</strong>{' '}
                            facilities already having a termination date set,
                            which will be changed to whatever value you define
                            in the import (potentially empty/NULL). Note: this
                            will <u>NOT</u> propagate changes to any associated
                            fee schedules and/or prices.
                          </p>
                        </>
                      )}
                    </section>

                    <DisplayOmissions
                      dryRunPlan={dryRunPlan}
                      applyOmissions={applyOmissions}
                      setApplyOmissions={setApplyOmissions}
                    />

                    <section>
                      <h4>
                        Launch Date <small>Required</small>
                      </h4>
                      <FormControlLabel
                        className="checkbox-control"
                        label="For NEW facilities without a launch date assigned in the CSV file, use today as the default."
                        control={
                          <Checkbox
                            checked={defaultLaunchDateTodayIfUnset}
                            onChange={(
                              ev: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setDefaultLaunchDateTodayIfUnset(
                                ev.target.checked
                              )
                            }}
                          />
                        }
                      />
                    </section>
                  </>
                )}
              </>
            )}

            {mustRecalcPlan && (
              <section>
                <p>
                  <strong>
                    After changing the CSV configurations, the import plan must
                    be regenerated.
                  </strong>
                </p>
              </section>
            )}
          </DialogContent>
        )}
        {isWorking && (
          <DialogActions className="dialog-action-is-working">
            <CircularProgress size={30} />
          </DialogActions>
        )}
        {!isWorking && (
          <DialogActions>
            <Grid container spacing={2} justify="space-between">
              {displayStep('report') && (
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                      setIsOpen(false)
                    }}>
                    Close
                  </Button>
                </Grid>
              )}
              {!displayStep('report') && (
                <>
                  <Grid item xs="auto">
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        setIsOpen(false)
                      }}>
                      Cancel
                    </Button>
                    {!!fileObj && (
                      <>
                        &nbsp;&nbsp;
                        <Button variant="outlined" onClick={doRestart}>
                          Restart
                        </Button>
                      </>
                    )}
                  </Grid>
                  {!!fileObj && (
                    <Grid item xs="auto">
                      {(!readyToRun || mustRecalcPlan) && (
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={!hasRequiredColsMapped()}
                          onClick={doImportDryRun}>
                          Generate Plan
                        </Button>
                      )}
                      {readyToRun && !mustRecalcPlan && (
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={!canRunImport()}
                          onClick={doRunImport}>
                          Run Import
                        </Button>
                      )}
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </DialogActions>
        )}
      </Dialog>
    </>
  )
}

function CSVDisplay({
  dryRunPlan,
  csvMapping,
  setCSVMapping,
  csvRows,
  firstRowIsHeader,
}: any): any {
  return (
    <TableContainer className="csv-results" component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ whiteSpace: 'nowrap' }}>Row #</TableCell>
            {!!dryRunPlan && <TableCell>Plan</TableCell>}
            {columns.map((def: any, index: number) => (
              <TableCell key={index}>
                <div className="thead-wrapper">
                  {def.name}
                  {def.required && (
                    <Tooltip title="Field mapping is required and every row must contain a value">
                      <IconInfo />
                    </Tooltip>
                  )}
                </div>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell />
            {!!dryRunPlan && <TableCell />}
            {columns.map((def: any, index: number) => (
              <TableCell key={index}>
                <CSVColumnPicker
                  columnName={def.name}
                  csvMapping={csvMapping}
                  setCSVMapping={setCSVMapping}
                  csvRows={csvRows}
                  firstRowIsHeader={firstRowIsHeader}
                />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {csvRows
            .slice(firstRowIsHeader ? 1 : 0)
            .map((row: any[] = [], rowIndex: number) => (
              <TableRow key={`row-${rowIndex}`}>
                <TableCell key={'rowNumber'}>{rowIndex + 1}</TableCell>
                {!!dryRunPlan && (
                  <DryRunPlanDisplay
                    rowIndex={firstRowIsHeader ? rowIndex + 1 : rowIndex}
                    dryRunPlan={dryRunPlan}
                  />
                )}
                {columns.map((def: any) => (
                  <TableCell key={`row-${rowIndex}-cell-${def.name}`}>
                    {row[csvMapping[def.name]]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function CSVColumnPicker({
  columnName,
  csvMapping,
  setCSVMapping,
  csvRows,
  firstRowIsHeader,
}: any): any {
  const fcProps: any = {}
  if (requiredCols[columnName]) {
    fcProps['error'] = !isNumber(csvMapping[columnName])
  }

  return (
    <FormControl variant="outlined" size="small" {...fcProps}>
      <Select
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        value={isNumber(csvMapping[columnName]) ? csvMapping[columnName] : ''}
        onChange={(ev: React.ChangeEvent<{ value: unknown }>) => {
          const v = ev.target.value
          if (!isNumber(v)) {
            setCSVMapping((obj: any) => {
              const n = { ...obj }
              delete n[columnName]
              return n
            })
            return
          }
          setCSVMapping((obj: any) => ({ ...csvMapping, [columnName]: v }))
        }}
        displayEmpty>
        <MenuItem value="">
          <em>CSV Column</em>
        </MenuItem>
        {(csvRows[0] || []).map((colName: string, index: number) => {
          return (
            <MenuItem value={index} key={index}>
              {firstRowIsHeader ? colName : `Column ${index}`}
            </MenuItem>
          )
        })}
      </Select>
      {isNumber(csvMapping[columnName]) && (
        <Button
          className="skip-field-btn"
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            setCSVMapping((obj: any) => {
              const n = { ...obj }
              delete n[columnName]
              return n
            })
          }}>
          SKIP THIS COLUMN &nbsp;
          <Tooltip title="No values for this column will be adjusted on any records">
            <IconInfo />
          </Tooltip>
        </Button>
      )}
    </FormControl>
  )
}

function DryRunPlanDisplay({ rowIndex, dryRunPlan }: any): any {
  if (!dryRunPlan) {
    return null
  }
  if (dryRunPlan.New[rowIndex]) {
    return <TableCell className="cell-is-new">Create New {rowIndex}</TableCell>
  }
  if (dryRunPlan.Matches[rowIndex]) {
    return (
      <TableCell className="cell-is-match">
        Matched Existing {rowIndex}
      </TableCell>
    )
  }
  return <TableCell>{rowIndex}</TableCell>
}

function DisplayOmissions({
  dryRunPlan,
  applyOmissions,
  setApplyOmissions,
}: any): any {
  const [show, setShow] = useState(false)

  if (!dryRunPlan) {
    return null
  }

  return (
    <>
      <section>
        <h4>
          {dryRunPlan.Omissions.length}(s) Omission Detected &nbsp;
          <Button
            variant="outlined"
            size="small"
            onClick={() => {
              setShow(!show)
            }}>
            {show ? 'Hide' : 'Show'}
          </Button>
        </h4>
        <p>
          By choosing <em>Apply Omissions</em>, it means any facilities not
          found in the import (but part of the same organization){' '}
          <strong>
            will be deactivated, as well as their associated fee schedules.
          </strong>
        </p>
        <FormControlLabel
          className="checkbox-control"
          label="Apply Omissions?"
          control={
            <Checkbox
              checked={applyOmissions}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                setApplyOmissions(ev.target.checked)
              }}
            />
          }
        />
        {!!show && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>FacilityID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Address1</TableCell>
                <TableCell>Address2</TableCell>
                <TableCell>City</TableCell>
                <TableCell>State</TableCell>
                <TableCell>Zip</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Fax</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>NPI</TableCell>
                <TableCell>LaunchDate</TableCell>
                <TableCell>TerminationDate</TableCell>
                <TableCell>ExternalID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dryRunPlan.Omissions.map((r: any) => {
                return (
                  <TableRow key={`pfid-${r.ID}`}>
                    <TableCell>{r.ID}</TableCell>
                    <TableCell>{r.Name}</TableCell>
                    <TableCell>{r.Address.Address1}</TableCell>
                    <TableCell>{r.Address.Address2}</TableCell>
                    <TableCell>{r.Address.City}</TableCell>
                    <TableCell>{r.Address.State}</TableCell>
                    <TableCell>{r.Address.Zip}</TableCell>
                    <TableCell>{r.Phone}</TableCell>
                    <TableCell>{r.Fax}</TableCell>
                    <TableCell>{r.Email}</TableCell>
                    <TableCell>{r.Npi}</TableCell>
                    <TableCell>{r.LaunchDate}</TableCell>
                    <TableCell>{r.TerminationDate}</TableCell>
                    <TableCell>{r.ExternalId}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        )}
      </section>
    </>
  )
}
