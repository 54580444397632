import React, { useState, ReactElement } from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
} from '@material-ui/core'
import * as FileUpload from '../../components/FileUpload'
import { FileTypeEntities } from './FileTypeFilter'
import styled from 'styled-components'

const StyledDialogContent = styled(DialogContent)`
  min-width: 520px;
}`

interface UploadModalProps {
  entityType?: readonly FileTypeEntities[]
  customFileTypeFilter?: () => ReactElement
  FileUploadProps?: FileUpload.props
}

const FileUploadModal = ({
  entityType,
  customFileTypeFilter,
  FileUploadProps,
}: UploadModalProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setModalOpen(true)}>
        Upload File
      </Button>
      <Dialog open={modalOpen}>
        <DialogTitle>Upload File</DialogTitle>
        <StyledDialogContent>
          <FileUpload.Uploader
            {...FileUploadProps}
            CustomFileDisplay={(props: any) => {
              return (
                <FileUpload.DefaultSingleFileInput
                  {...props}
                  fileTypeEntities={entityType}
                  customFileTypeFilter={customFileTypeFilter}
                />
              )
            }}
          />
        </StyledDialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setModalOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FileUploadModal
