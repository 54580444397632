import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import Models from '../../models'
import {
  renderPercentageField,
  RenderPriceField,
  renderSelectField,
  renderSwitchField,
  renderTextField,
  SetterArgs,
} from '../ViewHelpers'
import {
  BillingConfigContainerProps,
  BillingConfigEditFields,
} from '../../models/types/Employer'
import { EmployerActions, SnackbarActions } from '../../actions'
import _ from 'lodash'
import { PartnerSearcher } from '../../components/Searchers'
import { Launch as IconLaunch } from '@material-ui/icons'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
//@ts-ignore
import styled from 'styled-components'

const { getEmployerBillingConfig, saveEmployerBillingConfig } = EmployerActions
const { setSnackbarMessage } = SnackbarActions

const billingTypeOptions = [
  { label: 'PEPM', value: 2 },
  { label: 'Service', value: 1 },
]

const StyledNewWindowIcon = styled(IconLaunch)`
  position: relative;
  top: -3px;
`

/*
  This is a first example of how to build new detail pages (or pieces of detail pages) with functional components, vs class components.
  ** Why functional vs. class? **
  Functional components can utilize useEffect, which gives a much clearer and consistent way
  to determine how and when to fetch new data. They are also easier to fully integrate with TypeScript
*/
const BillingConfig = ({
  employerID,
  employerName,
  saveResult,
  setSnackbarMessage,
  ...props
}: BillingConfigContainerProps) => {
  const [fields, setFields]: [BillingConfigEditFields, any] = useState(
    Models.Employer.billingFields
  )
  const [changedFields, setChangedFields]: [any, any] = useState({})
  const [originals, setOriginals]: [BillingConfigEditFields, any] = useState(
    Models.Employer.billingFields
  )
  let canUpdate = Models.Employer.canUpdateBilling()
  if (props.forceDisableEditing === true) {
    canUpdate = false
  }

  /* on first load and subsequently if the employerID ever changes, re-fect the billing config */
  useEffect(() => {
    props.getEmployerBillingConfig({ employerID })
  }, [employerID]) // eslint-disable-line

  /* on first load and if the config is ever re-fetched, update it in the state */
  useEffect(() => {
    if (props.billingConfig) {
      setFields(props.billingConfig)
      setOriginals(props.billingConfig)
    }
  }, [props.billingConfig])

  useEffect(() => {
    if (saveResult) {
      if (saveResult.saved) {
        setSnackbarMessage(
          'Billing information updated successfully!',
          'success'
        )
        setChangedFields({})
      } else {
        if (saveResult.message) {
          setSnackbarMessage(saveResult.message, 'error')
        } else {
          setSnackbarMessage('Error saving billing info!', 'error')
        }
      }
    }
  }, [saveResult, setSnackbarMessage])

  /*
     This will likely look the same in every detail component, but im not sure if we can centralize
     it with much less effort. (no matter what, we need to share the local state)
  */
  const setter = ({ name, value }: SetterArgs) => {
    const newFields = Object.assign({}, fields, { [name]: value })

    setFields(newFields)
    setChangedFields(Object.assign({}, changedFields, { [name]: value }))
  }

  const getTotalFee = () => {
    if (!fields) return '0 %'
    const {
      FractionalRateFarley,
      FractionalRateService,
      DoNotBillFarleyFeeToCustomer,
    } = fields

    let fee = Number(FractionalRateService) * 1
    if (DoNotBillFarleyFeeToCustomer) {
      return `${fee} %`
    }
    fee = fee + Number(FractionalRateFarley) * 1
    return `${fee} %`
  }

  const getTotalSalesCommission = () => {
    if (!fields) return '0 %'
    const { FractionalRateBroker, FractionalRateSales } = fields
    const commission =
      Number(FractionalRateBroker) * 1 + Number(FractionalRateSales) * 1
    return `${commission}%`
  }

  const updateBillingInformation = () => {
    const transformed = Models.Employer.transformBilling(fields)

    props.saveEmployerBillingConfig({ body: transformed, employerID })
  }

  /* We can make this nicer, but it's a first stab at an edit log */
  const renderChangedFields = () => {
    if (_.isEmpty(changedFields)) return <div />

    return (
      <List>
        {Object.keys(changedFields)
          .map((field: string, i) => {
            // @ts-ignore-line
            const origValue = originals[field]
            const changedValue = changedFields[field]
            const whatChanged = `${origValue} --> ${changedValue}`
            // eslint-disable-next-line
            if (origValue != changedValue) {
              return (
                <ListItem key={`${field}-${i}`}>
                  <ListItemText primary={field} secondary={whatChanged} />
                </ListItem>
              )
            }
            return null
          })
          .filter((v) => {
            return !!v
          })}
      </List>
    )
  }

  const renderBrokerPartnerPicker = () => {
    return (
      <PartnerSearcher
        selectedPartnerID={fields.BrokerPartnerID}
        onChange={(partnerID: number | null) => {
          setter({ name: 'BrokerPartnerID', value: partnerID })
        }}
        TextFieldProps={{
          label: (
            <span>
              Broker Partner &nbsp;
              {fields.BrokerPartnerID && (
                <a
                  href={`/partner/${fields.BrokerPartnerID}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <StyledNewWindowIcon fontSize="small" />
                </a>
              )}
            </span>
          ),
        }}
      />
    )
  }

  const renderSalesPartnerPicker = () => {
    return (
      <PartnerSearcher
        selectedPartnerID={fields.SalesPartnerID}
        onChange={(partnerID: number | null) => {
          setter({ name: 'SalesPartnerID', value: partnerID })
        }}
        TextFieldProps={{
          label: (
            <span>
              Sales Partner &nbsp;
              {fields.SalesPartnerID && (
                <a
                  href={`/partner/${fields.SalesPartnerID}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <StyledNewWindowIcon fontSize="small" />
                </a>
              )}
            </span>
          ),
        }}
      />
    )
  }

  const renderOtherPartnerPicker = () => {
    return (
      <PartnerSearcher
        selectedPartnerID={fields.OtherPartnerID}
        onChange={(partnerID: number | null) => {
          setter({ name: 'OtherPartnerID', value: partnerID })
        }}
        TextFieldProps={{
          label: (
            <span>
              Other Partner &nbsp;
              {fields.OtherPartnerID && (
                <a
                  href={`/partner/${fields.OtherPartnerID}`}
                  target="_blank"
                  rel="noopener noreferrer">
                  <StyledNewWindowIcon fontSize="small" />
                </a>
              )}
            </span>
          ),
        }}
      />
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={3}>
        <Card>
          <CardHeader title="Configuration" />
          <CardContent>
            {renderSelectField({
              name: 'BillingType',
              label: 'Billing Type',
              items: billingTypeOptions,
              setter,
              value: fields.BillingType,
              opts: { disabled: !canUpdate },
            })}
            {RenderPriceField({
              name: 'RatePEPM',
              label: 'Rate PEPM',
              value: fields.RatePEPM,
              setter,
              opts: { disabled: !canUpdate },
            })}
            {renderSwitchField({
              name: 'BillByDivision',
              label: 'Bill by Division',
              value: fields.BillByDivision,
              setter,
              opts: { disabled: !canUpdate },
            })}
            {renderSwitchField({
              name: 'BillingBreakoutFees',
              label: 'Breakout Invoice Fees',
              value: fields.BillingBreakoutFees,
              setter,
              opts: { disabled: !canUpdate },
            })}
            {renderPercentageField({
              name: 'FractionalRateFeeBasis',
              label: 'Fee Basis %',
              setter,
              value: fields.FractionalRateFeeBasis,
              opts: { disabled: !canUpdate },
            })}
            {renderSelectField({
              name: 'BillingMethod',
              label: 'Billing Method',
              items: Models.Employer.billingMethodOptions,
              setter,
              value: fields.BillingMethod,
              opts: { disabled: !canUpdate },
            })}
            {renderSelectField({
              name: 'ACHWeekday',
              label: 'ACH Debit Weekday',
              items: Models.Employer.achWeekdayOptions,
              setter,
              value: fields.ACHWeekday,
              opts: { disabled: !canUpdate },
            })}
            {renderTextField({
              name: 'QuickbooksHandle',
              label: 'Quickbooks Handle',
              setter,
              value: fields.QuickbooksHandle,
              opts: { disabled: !canUpdate },
            })}
            <ManagedDateInput
              name="RateChangeReminder"
              label="Rate Change Reminder Date"
              value={fields.RateChangeReminder}
              setter={setter}
              disabled={!canUpdate}
            />
            {renderTextField({
              name: 'RateChangeNote',
              label: 'Rate Change Note',
              setter,
              value: fields.RateChangeNote,
              opts: { disabled: !canUpdate, multiline: true, rows: 5 },
            })}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Card>
          <CardHeader title="Fees" />
          <CardContent>
            {renderPercentageField({
              name: 'FractionalRateService',
              label: 'Service Rate',
              value: fields.FractionalRateService,
              setter,
              opts: { disabled: !canUpdate },
            })}
            {renderPercentageField({
              name: 'FractionalRateFarley',
              label: 'Farley Rate',
              value: fields.FractionalRateFarley,
              setter,
              opts: { disabled: !canUpdate },
            })}
            {renderSwitchField({
              name: 'DoNotBillFarleyFeeToCustomer',
              label: 'Exclude Farley from fees',
              value: fields.DoNotBillFarleyFeeToCustomer,
              setter,
              opts: { disabled: !canUpdate },
            })}
            <Typography> Total Fee: {getTotalFee()}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Card>
          <CardHeader title="Commissions" />
          <CardContent>
            <Typography>Broker</Typography>
            <br />
            {renderBrokerPartnerPicker()}
            {renderTextField({
              name: 'BrokerName',
              label: 'Broker Name',
              setter,
              value: fields.BrokerName,
              opts: { disabled: !canUpdate },
            })}
            {renderTextField({
              name: 'BrokerCompany',
              label: 'Broker Company',
              setter,
              value: fields.BrokerCompany,
              opts: { disabled: !canUpdate },
            })}
            {renderPercentageField({
              name: 'FractionalRateBroker',
              label: 'Broker Rate',
              setter,
              value: fields.FractionalRateBroker,
              opts: { disabled: !canUpdate },
            })}
          </CardContent>
          <Divider></Divider>
          <CardContent>
            <Typography>Sales</Typography>
            {renderSalesPartnerPicker()}
            {renderTextField({
              name: 'Salesperson',
              label: 'Salesperson',
              setter,
              value: fields.Salesperson,
              opts: { disabled: !canUpdate },
            })}
            {renderPercentageField({
              name: 'FractionalRateSales',
              label: 'Sales Rate',
              setter,
              value: fields.FractionalRateSales,
              opts: { disabled: !canUpdate },
            })}
          </CardContent>
          <Divider></Divider>
          <CardContent>
            <Typography>
              Total Commission: {getTotalSalesCommission()}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Card>
          <CardHeader title="Other Partner" />
          <CardContent>
            {renderOtherPartnerPicker()}
            {renderTextField({
              name: 'OtherRateDescription',
              label: 'Other Rate Description',
              setter,
              value: fields.OtherRateDescription,
              opts: { disabled: !canUpdate },
            })}
            {renderPercentageField({
              name: 'FractionalRateOther',
              label: 'Other Rate',
              setter,
              value: fields.FractionalRateOther,
              opts: { disabled: !canUpdate },
            })}
          </CardContent>
        </Card>
        <Card>
          <CardHeader title="Quick Summary" />
          <CardContent>
            <p>
              <strong>Salesperson:</strong> {fields.Salesperson}
              <br />
              <strong>Employer Name:</strong> {employerName}
              <br />
              <strong>Total Fee:</strong> {getTotalFee()}
              <br />
              <strong>Fee Basis:</strong> {fields.FractionalRateFeeBasis}
              <br />
              <strong>Sales Rate (Commission):</strong>{' '}
              {fields.FractionalRateSales}
              <br />
              <strong>Broker Company:</strong> {fields.BrokerCompany}
              <br />
              <strong>Broker Name:</strong> {fields.BrokerName}
              <br />
              <strong>Broker Rate (Commission):</strong>{' '}
              {fields.FractionalRateBroker}
              <br />
              <strong>Other Rate:</strong> {fields.FractionalRateOther}
              <br />
              <strong>Other Rate Description:</strong>{' '}
              {fields.OtherRateDescription || '---'}
              <br />
            </p>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={9}>
        <Card>
          <CardContent>
            {renderTextField({
              name: 'BillingNotes',
              label: 'Billing Notes',
              setter,
              value: fields.BillingNotes,
              opts: {
                disabled: !canUpdate,
                multiline: true,
                rows: 12,
                maxRows: 30,
              },
            })}
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={3}>
        <Button
          color="primary"
          style={{ marginLeft: 15 }}
          variant="contained"
          disabled={!canUpdate || _.isEmpty(changedFields)}
          onClick={updateBillingInformation}>
          Update
        </Button>
        {renderChangedFields()}
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state: any) => {
  return {
    billingConfig: state.employers.billingConfig || null,
    getError: state.employers.getError,
    saveResult: state.employers.saveResult,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    { getEmployerBillingConfig, saveEmployerBillingConfig, setSnackbarMessage },
    dispatch
  )
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(BillingConfig)
