import React, { useEffect, useState } from 'react'
import DesignSuite2023 from '../../../../../components/DesignSuite2023'
import { Dialpad as IconCalculator } from '@material-ui/icons'
import { Button, Popover, Typography, TextField } from '@material-ui/core'
import styled from 'styled-components'
import { randomID } from '../../../helpers'

const StyledPopover = styled.form`
  padding: 0.75rem;
  font-size: 90%;
  color: #fff;
  background: #222;
  width: 330px;
  // height: 300px;
  overflow: hidden;
  overflow-y: scroll;

  .sectiond {
    padding-bottom: 0.75rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px dashed #555;
  }

  .displayer {
    display: flex;
    align-items: center;
    justify-content: center;

    .start-value,
    .result-value {
      font-size: 115%;
      font-weight: bold;
      padding: 0.35rem 0.5rem;
      display: inline-block;
      border-radius: 5px;
    }

    .start-value {
      background: linear-gradient(45deg, #4b4798, #0f583a 100%);
    }
    .result-value {
      background: linear-gradient(45deg, #728e47, #0f583a 100%);
    }
  }

  .shortcuts {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;

    .cell {
      text-align: center;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 5px;
      line-height: 1;
      padding: 0.5rem;
      display: inline-block;
      border: 1px solid transparent;
      cursor: pointer;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
        border-color: #fff;
      }

      span {
        font-size: 130%;
        display: block;
        margin-bottom: 0.25rem;
      }
    }
  }
`

const StyledTextField = styled(TextField)`
  margin-bottom: 0.75rem;

  .MuiInputLabel-root {
    color: inherit;
    opacity: 0.65;
  }
  .MuiInputBase-root.MuiOutlinedInput-root {
    color: #fff;
    background: rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(3px);

    &:hover {
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: #fff;
      }
    }
    .MuiInputBase-input {
      color: inherit;
    }
    fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #fff;
    }
  }

  .Mui-focused {
    .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused {
      fieldset.MuiOutlinedInput-notchedOutline {
        border-color: #fff;
      }
    }
  }
`

interface props {
  startingValue: string | null
  onResult?(result: string | null): void
}

export default function Calculator({
  startingValue,
  onResult,
}: props): React.ReactElement | null {
  const [id] = useState(randomID())
  const [popoverAnchor, setPopoverAnchor] = useState<any>(null)
  const [multiplier, setMultiplier] = useState<string | null>('1.00')
  const [result, setResult] = useState<string | null>(null)

  useEffect(() => {
    if (!popoverAnchor) {
      setMultiplier('1.00')
      setResult(null)
      return
    }
    calculate()
  }, [popoverAnchor, startingValue, multiplier])

  function calculate() {
    if (!startingValue || !multiplier) return
    let res = (Number(startingValue) * Number(multiplier)).toFixed(2)
    setResult(res || null)
  }

  function openPopover(ev: any) {
    setPopoverAnchor(ev.currentTarget)
  }

  function onAccept(ev: any) {
    ev?.preventDefault()
    onResult?.(result)
    setPopoverAnchor(null)
  }

  function formatNumber(num: string | null): string {
    if (!num) return ''
    return num.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  if (!Number(startingValue)) return null

  return (
    <>
      <DesignSuite2023.Tooltip title="Open calculator tool">
        <IconCalculator
          fontSize="inherit"
          style={{ cursor: 'pointer' }}
          onClick={openPopover}
        />
      </DesignSuite2023.Tooltip>

      <Popover
        id={id}
        open={!!popoverAnchor}
        anchorEl={popoverAnchor}
        onClose={() => setPopoverAnchor(null)}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        transformOrigin={{ vertical: -15, horizontal: 'center' }}
        transitionDuration={0}
        onSubmit={onAccept}>
        <StyledPopover>
          <div className="displayer sectiond">
            <span className="start-value">${formatNumber(startingValue)}</span>
            &nbsp;&nbsp;X&nbsp;&nbsp;
            <StyledTextField
              variant="outlined"
              size="small"
              margin="none"
              autoFocus
              style={{ width: 80 }}
              value={multiplier}
              onChange={(ev: any) => setMultiplier(ev.target.value)}
            />
            &nbsp;&nbsp;=&nbsp;&nbsp;
            <span className="result-value" onClick={onAccept}>
              ${formatNumber(result)}
            </span>
          </div>

          <Typography variant="caption">Common Multipliers</Typography>
          <div className="shortcuts sectiond">
            <button
              className="cell"
              type="button"
              onClick={() => setMultiplier('1.75')}>
              <span>1.75</span>
              <small>(175%)</small>
            </button>
            <button
              className="cell"
              type="button"
              onClick={() => setMultiplier('1.50')}>
              <span>1.50</span>
              <small>(150%)</small>
            </button>
            <button
              className="cell"
              type="button"
              onClick={() => setMultiplier('1.25')}>
              <span>1.25</span>
              <small>(125%)</small>
            </button>
            <button
              className="cell"
              type="button"
              onClick={() => setMultiplier('0.90')}>
              <span>0.90</span>
              <small>(90%)</small>
            </button>
            <button
              className="cell"
              type="button"
              onClick={() => setMultiplier('0.80')}>
              <span>0.80</span>
              <small>(80%)</small>
            </button>
            <button
              className="cell"
              type="button"
              onClick={() => setMultiplier('0.75')}>
              <span>0.75</span>
              <small>(75%)</small>
            </button>
            <button
              className="cell"
              type="button"
              onClick={() => setMultiplier('0.50')}>
              <span>0.50</span>
              <small>(50%)</small>
            </button>
            <button
              className="cell"
              type="button"
              onClick={() => setMultiplier('0.30')}>
              <span>0.30</span>
              <small>(30%)</small>
            </button>
          </div>

          <div>
            <Button
              variant="contained"
              color="primary"
              size="small"
              fullWidth
              onClick={onAccept}
              type="submit">
              Accept
            </Button>
          </div>
        </StyledPopover>
      </Popover>
    </>
  )
}
