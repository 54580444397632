import React, { useEffect, useMemo, useState } from 'react'
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import useQueryParamsGen2, { SCOPES } from '../../hooks/useQueryParamsGen2'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import Models from '../../models'
import { getContactV2 } from '../../actions/ContactActions'
import {
  renderNotesField as RenderNotesField,
  renderPhoneExtField as RenderPhoneExtField,
  renderPhoneField as RenderPhoneField,
  RenderSelectField2,
  renderSwitchField as RenderSwitchField,
  renderTextField as RenderTextField,
  SetterArgs,
} from '../ViewHelpers'
import DesignSuite2023, { Divider } from '../../components/DesignSuite2023'
import useSnackbar from '../../hooks/useSnackbar'

export type ContactTypeOptions = {
  key: number
  value: number
  label: string
}

export type DialogEditContactProps = {
  contactTypes: ContactTypeOptions[]
  handleClose: () => void
  addNew: (contact: any) => void
  save: (contact: any) => void
}

export default function DialogEditContact({
  contactTypes,
  addNew,
  save,
  handleClose,
}: DialogEditContactProps) {
  const [loading, setLoading] = useState<boolean>(false)
  const [errors, setErrors] = useState<any>({})
  const [contact, setContact] = useState<any>({})
  const { queryData } = useQueryParamsGen2({
    scope: SCOPES.EDIT_CONTACT_INFO,
  })
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration } = useSnackbar()

  const validators = Object.freeze(Models.Contact.validators)

  const contactTypeByID = useMemo(() => {
    const m = new Map<number, string>()
    contactTypes.forEach((el) => {
      m.set(el.key, el.label)
    })
    return m
  }, [contactTypes])

  useEffect(() => {
    if (queryData.editContactID === undefined) {
      setContact({})
      return
    }

    if (queryData.editContactID === 0) {
      setContact({ ...Models.Contact.editFields })
      return
    }

    setLoading(true)
    getContactV2(queryData.editContactID)
      .then((res: any) => {
        if (res.error) throw res
        setContact(res.Data)
      })
      .catch(catchAPIError({ defaultMessage: 'Failed to load contact' }))
      .finally(() => setLoading(false))
  }, [queryData.editContactID])

  if (loading) return <CircularProgress />

  if (queryData.editContactID === undefined) return null

  const setter = ({ name, value }: SetterArgs) => {
    setContact({ ...contact, [name]: value })
  }

  const parentSetter = ({ name, value }: SetterArgs, parent: string) => {
    setContact({ ...contact, [parent]: { ...contact[parent], [name]: value } })
  }

  function isValid(): boolean {
    const currentErrors: any = {}
    let valid = true

    Object.keys(validators).forEach((key) => {
      const currentValid = validators[key].isValid(contact[key])
      if (!currentValid) {
        currentErrors[key] = validators[key].msg
        valid = false
      }
    })
    setErrors(currentErrors)
    return valid
  }

  function handleSave() {
    if (!isValid()) {
      showForDuration('Please correct the errors', 'error', 3000)
      return
    }

    if (queryData.editContactID === 0) {
      addNew(contact)
      return
    }
    save(contact)
  }

  return (
    <Dialog open={true} onClose={handleClose} maxWidth="md" fullWidth={true}>
      <DialogTitle>
        <DesignSuite2023.GridLR
          left={
            <Typography variant={'h6'}>
              {queryData.editContactID !== 0
                ? 'Edit Contact'
                : 'Add new Contact'}
            </Typography>
          }
          right={
            <>
              <RenderSwitchField
                name={'IsPrimary'}
                label={'Is Primary Contact'}
                value={contact.IsPrimary}
                setter={setter}
              />
              <RenderSwitchField
                name={'Active'}
                label={'Active'}
                value={contact.Active}
                setter={setter}
              />{' '}
            </>
          }
        />
      </DialogTitle>
      <DialogContent>
        {/* Name Information */}

        <DialogContentText>Name &amp; Designations</DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <RenderSelectField2
              name={'Salutation'}
              label={'Salutation'}
              value={contact.Salutation}
              setter={setter}
              items={Models.Contact.salutations}
            />
          </Grid>
          <Grid item xs={3}>
            <RenderTextField
              name={'FirstName'}
              label={'First Name'}
              value={contact.FirstName}
              use2023Styles={true}
              setter={setter}
              opts={{ error: !!errors.FirstName, helperText: errors.FirstName }}
            />
          </Grid>

          <Grid item xs={3}>
            <RenderTextField
              name={'LastName'}
              label={'Last Name'}
              value={contact.LastName}
              use2023Styles={true}
              setter={setter}
              opts={{ error: !!errors.LastName, helperText: errors.LastName }}
            />
          </Grid>

          <Grid item xs={1}>
            <RenderSelectField2
              name={'Suffix'}
              label={'Suffix'}
              value={contact.Suffix}
              setter={setter}
              items={Models.Contact.suffixes}
            />
          </Grid>

          <Grid item xs={2}>
            <RenderTextField
              name={'Honorific'}
              label={'Honorific'}
              value={contact.Honorific}
              use2023Styles={true}
              setter={setter}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <RenderTextField
              name={'Company'}
              label={'Company'}
              value={contact.Company}
              use2023Styles={true}
              setter={setter}
            />
          </Grid>
          <Grid item xs={4}>
            <RenderTextField
              name={'Title'}
              label={'Title'}
              value={contact.Title}
              use2023Styles={true}
              setter={setter}
            />
          </Grid>
        </Grid>

        <Divider />
        <DialogContentText>Contact Information</DialogContentText>

        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <RenderTextField
                  name={'Email'}
                  label={'Email'}
                  value={contact.Email}
                  use2023Styles={true}
                  setter={setter}
                />
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <RenderPhoneField
                      name={'Phone'}
                      label={'Phone'}
                      value={contact.Phone}
                      use2023Styles={true}
                      setter={setter}
                      opts={{
                        error: !!errors.Phone,
                        helperText: errors.Phone,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <RenderPhoneExtField
                      name={'PhoneExt'}
                      label={'Ext'}
                      value={contact.PhoneExt}
                      use2023Styles={true}
                      setter={setter}
                      opts={{
                        error: !!errors.PhoneExt,
                        helperText: errors.PhoneExt,
                      }}
                    />
                  </Grid>
                </Grid>
                <RenderPhoneField
                  name={'Mobile'}
                  label={'Mobile'}
                  value={contact.Mobile}
                  use2023Styles={true}
                  setter={setter}
                />
                <RenderPhoneField
                  name={'Fax'}
                  label={'Fax'}
                  value={contact.Fax}
                  use2023Styles={true}
                  setter={setter}
                />
              </Grid>
              <Grid item xs={6}>
                <RenderTextField
                  name={'Address1'}
                  label={'Address 1'}
                  value={contact.Address?.Address1}
                  use2023Styles={true}
                  setter={(args: SetterArgs) => parentSetter(args, 'Address')}
                />
                <RenderTextField
                  name={'Address2'}
                  label={'Address 2'}
                  value={contact.Address?.Address2}
                  use2023Styles={true}
                  setter={(args: SetterArgs) => parentSetter(args, 'Address')}
                />
                <RenderTextField
                  name={'City'}
                  label={'City'}
                  value={contact.Address?.City}
                  use2023Styles={true}
                  setter={(args: SetterArgs) => parentSetter(args, 'Address')}
                />
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <RenderTextField
                      name={'State'}
                      label={'State'}
                      value={contact.Address?.State}
                      use2023Styles={true}
                      setter={(args: SetterArgs) =>
                        parentSetter(args, 'Address')
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <RenderTextField
                      name={'Zip'}
                      label={'Zip'}
                      value={contact.Address?.Zip}
                      use2023Styles={true}
                      setter={(args: SetterArgs) =>
                        parentSetter(args, 'Address')
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={5}>
            <RenderNotesField
              name={'Notes'}
              label={'Notes'}
              value={contact.Notes}
              use2023Styles={true}
              setter={setter}
              opts={{ rows: 11, rowsMax: 11 }}
            />
          </Grid>
        </Grid>

        <Divider />
        <DialogContentText>Contact Type</DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RenderSelectField2
              name={'ContactTypeIDs'}
              label={'Type(s)'}
              value={contact.ContactTypeIDs || []}
              items={contactTypes}
              SelectProps={{
                multiple: true,
                renderValue: (selected: any) => (
                  <Box>
                    {selected.map((key: number) => (
                      <Chip
                        color={'primary'}
                        key={key}
                        label={contactTypeByID.get(key)}
                        style={{ margin: '0px 2px' }}
                      />
                    ))}
                  </Box>
                ),
                error: !!errors.ContactTypeIDs,
                helperText: errors.ContactTypeIDs,
              }}
              FormControlProps={{
                margin: 'none',
                size: 'md',
                fullWidth: true,
              }}
              setter={setter}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <DesignSuite2023.GridLR
          right={
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
          }
          left={
            <Button onClick={handleClose} color="secondary">
              Cancel
            </Button>
          }
        />
      </DialogActions>
    </Dialog>
  )
}
