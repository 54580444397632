import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Link,
  RouteComponentProps,
  useHistory,
  withRouter,
} from 'react-router-dom'
import {
  Button,
  Card as CCard,
  CardContent,
  CardHeader,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Tooltip as CTooltip,
  Typography,
} from '@material-ui/core'
import Info from '@material-ui/icons/Info'
import PartnerSearcher from '../../components/Searchers/PartnerSearcher'
import EmployerSearcher from '../../components/Searchers/EmployerSearcher'
import * as api from '../../services/thezerocard/api-helper'
import * as T from './types'
import * as utils from './utils'
import { useStopLossForm } from './hooks'
import { setSnackbarMessage } from '../../actions/SnackbarActions'
import * as ContactTable from '../Contacts/ContactTable'
import { EmployerActions } from '../../actions'
import dateTime from '../../utils/dateTime'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
//@ts-ignore
import styled from 'styled-components'

const { getEmployerContactTypesV2 } = EmployerActions

const SectionDivider = styled(Divider)`
  margin-top: 20px;
  margin-bottom: 20px;
`

const StopLossChip = styled(Chip)`
  margin-left: 10px;
`

const gridStyle = {
  margin: '10px',
  minWidth: '340px',
}

const sepStyle = {
  fontSize: '24px',
  padding: '16px 10px 0px 10px',
}

const ActionButton = styled(Button)`
  margin: 8px;
`

const Card: React.FC<T.CardProps> = ({ title, subheader, children }) => {
  return (
    <CCard>
      <CardHeader title={title} subheader={subheader} />
      <CardContent>{children}</CardContent>
    </CCard>
  )
}

const Tooltip: React.FC<{ title: string; style?: any }> = ({
  title,
  style,
}) => (
  <CTooltip title={title}>
    <Info
      color="disabled"
      style={{
        height: '18px',
        width: '18px',
        marginTop: '35px',
        marginLeft: '15px',
        ...style,
      }}
    />
  </CTooltip>
)

const Terms: React.FC<{
  form: any
  errors: any
}> = ({ form, errors }) => {
  return (
    <Card title="Terms" subheader="Terms and details of the contract.">
      <div style={{ display: 'flex', marginBottom: '10px' }}>
        <ManagedDateInput
          name="ContractStartDate"
          label="Contract Start"
          value={form.data.ContractStartDate}
          setter={({ name, value }) => form.setters.ContractStartDate(value)}
          error={!!errors.ContractStartDate}
          helperText={errors.ContractStartDate}
        />
        <p style={sepStyle}> - </p>
        <ManagedDateInput
          name="ContractEndDate"
          label="Contract End"
          value={form.data.ContractEndDate}
          setter={({ name, value }) => form.setters.ContractEndDate(value)}
          error={!!errors.ContractEndDate}
          helperText={errors.ContractEndDate}
        />
        <Tooltip
          title="Contract end date should be the next day after the contract ends."
          style={{ marginTop: '25px' }}
        />
      </div>
      <div style={{ display: 'flex' }}>
        <TextField
          label="Incurred Months"
          type="number"
          value={form.data.IncurredMonths}
          onChange={(e) => form.setters.IncurredMonths(e.target.value)}
          error={!!errors.IncurredMonths}
          helperText={errors.IncurredMonths}
        />
        <p style={sepStyle}> / </p>
        <TextField
          label="Payment Months"
          type="number"
          value={form.data.PaymentMonths}
          onChange={(e) => form.setters.PaymentMonths(e.target.value)}
          error={!!errors.PaymentMonths}
          helperText={errors.PaymentMonths}
        />
        <Tooltip
          title="Incurred months represent the period that services may be performed under this contract. Payment months specify the window of time that claims can be paid."
          style={{ marginTop: '25px' }}
        />
      </div>
      <FormControlLabel
        label="Active"
        style={{ width: '150px' }}
        control={
          <Checkbox
            checked={form.data.IsActive}
            value={form.data.IsActive}
            onChange={(e) => form.setters.IsActive(e.target.checked)}
          />
        }
      />
      <FormControlLabel
        label="Has Stop Loss?"
        control={
          <Checkbox
            checked={form.data.HasStopLoss}
            value={form.data.HasStopLoss}
            onChange={(e) => form.setters.HasStopLoss(e.target.checked)}
          />
        }
      />
      <FormControlLabel
        label="Is ZERO Included in Stop Loss?"
        control={
          <Checkbox
            checked={form.data.IsZeroIncluded}
            value={form.data.IsZeroIncluded}
            onChange={(e) => form.setters.IsZeroIncluded(e.target.checked)}
          />
        }
      />
    </Card>
  )
}

const Partners: React.FC<{ form: any; errors: any }> = ({ form }) => {
  const width = '85%'
  return (
    <Card title="Partners" subheader="Different partner responsibilities.">
      <div style={{ display: 'flex' }}>
        <PartnerSearcher
          onChange={(partnerID: number) =>
            form.setters.StopLossCarrierPartnerID(partnerID)
          }
          selectedPartnerID={form.data.StopLossCarrierPartnerID}
          TextFieldProps={{
            label: 'Stop Loss Carrier',
            margin: 'normal',
          }}
          AutoCompleteProps={{
            style: { width },
          }}
        />
        <Tooltip title="Who provides the stop loss coverage for this contract." />
      </div>
      <div style={{ display: 'flex' }}>
        <PartnerSearcher
          onChange={(partnerID: number) =>
            form.setters.CoordinatingPartnerID(partnerID)
          }
          selectedPartnerID={form.data.CoordinatingPartnerID}
          TextFieldProps={{
            label: 'Coordinating Partner',
            margin: 'normal',
          }}
          AutoCompleteProps={{
            style: { width },
          }}
        />
        <Tooltip title="The Coordinating partner is an entity that acquires all the paid claims from multiple entities (e.g. TPA, PBM, ZERO, … ) groups them together and sends them off to the Stoploss Carrier." />
      </div>
      <div style={{ display: 'flex' }}>
        <PartnerSearcher
          onChange={(partnerID: number) =>
            form.setters.StopLossCaptiveID(partnerID)
          }
          selectedPartnerID={form.data.StopLossCaptiveID}
          TextFieldProps={{
            label: 'Stoploss Captive',
            margin: 'normal',
          }}
          AutoCompleteProps={{
            style: { width },
          }}
        />
        <Tooltip title="The Stoploss captive is a group purchasing entity through which the employer buys Stop Loss insurance which gives the captive more negotiating power." />
      </div>
    </Card>
  )
}

const Notes: React.FC<{ form: any; errors: any }> = ({ form }) => {
  return (
    <Card title="Notes" subheader="Various notes on the contract.">
      <TextField
        label="Reporting Notes"
        margin="normal"
        value={form.data.ReportingNotes}
        onChange={(e) => form.setters.ReportingNotes(e.target.value)}
        fullWidth
        multiline
      />
      {/* <TextField
        label="Laser Notes"
        margin="normal"
        value={form.data.LaserNotes}
        onChange={(e) => form.setters.LaserNotes(e.target.value)}
        fullWidth
        multiline
      /> */}
      <TextField
        label="General Notes"
        margin="normal"
        value={form.data.GeneralNotes}
        onChange={(e) => form.setters.GeneralNotes(e.target.value)}
        fullWidth
        multiline
      />
    </Card>
  )
}

const Employer: React.FC<{
  form: any
  errors: any
  contractID: any
}> = ({ form, contractID, errors }) => {
  const [employer, setEmployer] = useState<any>(null)
  const dispatch = useDispatch()
  const employerID = form.data.EmployerID

  const loadEmployer = async () => {
    if (!employerID) return
    const res: any = await api.get(`/employer/${employerID}`)
    if (res.Data) {
      setEmployer(res.Data)
    } else if (res.error) {
      dispatch(setSnackbarMessage('Error loading employer.', 'error'))
    }
  }
  React.useEffect(() => {
    loadEmployer()
  }, [employerID])

  return (
    <div style={{ marginBottom: '8px' }}>
      <Card title="Employer" subheader="Employer details">
        <Grid container spacing={2}>
          {typeof contractID === 'undefined' && (
            <Grid item xs={12}>
              <EmployerSearcher
                selectedEmployerID={form.data.EmployerID}
                onChange={(employerId, employer) => {
                  if (employer && employer.PlanRenewalDate) {
                    const renewalDate = dateTime
                      .parse(employer.PlanRenewalDate)
                      .format()
                    form.setData({
                      ...form.data,
                      EmployerID: employerId,
                      ContractStartDate: renewalDate,
                    })
                  } else {
                    form.setters.EmployerID(employerId)
                  }
                }}
                TextFieldProps={{
                  error: !!errors.EmployerID,
                  helperText: errors.EmployerID,
                  style: { marginBottom: '10px' },
                }}
              />
            </Grid>
          )}
          {employer && typeof contractID !== 'undefined' && (
            <>
              <Grid item xs={12}>
                <Typography variant={'body1'}>
                  <Link to={`/employer/${employerID}`} target="_blank">
                    {employer.Name}{' '}
                  </Link>
                </Typography>
                <Divider></Divider>
              </Grid>
            </>
          )}
          {employer && (
            <>
              <Grid item xs={4}>
                <Typography variant={'caption'}>Launch Date</Typography>
                <Typography variant={'body2'}>
                  {dateTime.parse(employer.LaunchDate).format()}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant={'caption'}>Termination Date</Typography>
                <Typography variant={'body2'}>
                  {dateTime.parse(employer.TerminationDate).format()}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Card>
    </div>
  )
}

const ButtonGroup: React.FC<T.ButtonGroupProps> = ({
  back,
  save,
  clone,
  del,
  contractId,
}) => {
  return (
    <Grid container direction="column" alignItems="flex-end">
      <Button onClick={back}>{`< Back to Stop Loss`}</Button>
      <Grid container direction="row" justify="flex-end">
        <ActionButton color="primary" variant="contained" onClick={save}>
          Save
        </ActionButton>
        {contractId ? (
          <ActionButton variant="contained" onClick={clone}>
            Clone
          </ActionButton>
        ) : null}
        {contractId ? (
          <ActionButton color="secondary" variant="contained" onClick={del}>
            Delete
          </ActionButton>
        ) : null}
      </Grid>
    </Grid>
  )
}

const StopLossDetail: React.FC<
  RouteComponentProps<{ id: string }, any, any>
> = (props: any) => {
  const contractId = props.match.params.id

  const history = useHistory()
  const dispatch = useDispatch()

  const { form, save, clone, del, errors } = useStopLossForm(contractId)

  const [contactTypes, setContactTypes] = useState<any>(null)

  const maybeLoadContract = async () => {
    if (contractId && String(form.data.ID) !== contractId) {
      const res: any = await api.get(`/stoploss/${contractId}`)
      if (res.Data) {
        form.setData(utils.toFormData(res.Data))
      } else if (res.error) {
        dispatch(
          setSnackbarMessage('Error loading Stop Loss contracts.', 'error')
        )
      }
    }
  }

  const back = () => {
    history.push('/stop_loss_contracts')
  }

  React.useEffect(() => {
    maybeLoadContract()
    getEmployerContactTypesV2().then((res: any) => setContactTypes(res))
    // teardown
    return () => form.reset()
  }, [contractId])

  if ((contractId && !form.data.ID) || !contactTypes) return null

  return (
    <div>
      <Paper style={{ padding: '20px' }} elevation={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} style={gridStyle}>
            <Employer form={form} errors={errors} contractID={contractId} />
            <Terms form={form} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={3} style={gridStyle}>
            <Partners form={form} errors={errors} />
          </Grid>
          <Grid item xs={12} sm={3} style={gridStyle}>
            <Notes form={form} errors={errors} />
          </Grid>
        </Grid>
        <ButtonGroup
          back={back}
          save={save}
          clone={clone}
          del={del}
          contractId={contractId}
        />
        <SectionDivider />
        <ContactTable.Table
          disableCheckHandler
          passFilters={{
            EmployerID: form.data.EmployerID,
            ContactTypeIDs: [T.CONTACT_TYPE_STOP_LOSS],
            IncludeInactive: false,
          }}
          LeftHeaderItems={
            <>
              <ContactTable.StandardFilterSearch />
              <StopLossChip label="Contact Type: Stop Loss" />
              <ContactTable.SwitchInactive />
              <ContactTable.FilterIsPrimaryContact />
            </>
          }
          RightHeaderItems={<></>}
        />
      </Paper>
    </div>
  )
}

export default withRouter(StopLossDetail)
