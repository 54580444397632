// @ts-ignore
import React, { forwardRef } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import { getInboundPreStagePlan } from '../../../actions/EngineeringActions'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { ShapeEligInbound } from './types'
import styled from 'styled-components'
import dateTime from '../../../utils/dateTime'

// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../../hooks/useStandardTableSetup'

const StyledS3Loc = styled.span`
  small {
    display: block;
    font-weight: 600;
  }

  display: block;
  overflow-wrap: anywhere;
  max-width: 350px;
`

const StyledPre = styled.pre`
  background: #222;
  color: #fff;
  border: 0;
  margin: 0;
  font-size: 90%;
`

export const defaultColumns = Object.freeze({
  ID: { name: 'ID' },
  Inbound: {
    name: 'Inbound',
    details: {
      sortName: 'elig_inbound.s3_key',
      dataFormat(_: any, row: ShapeEligInbound): any {
        return (
          <DesignSuite2023.Tooltip title={`${row.S3Bucket}/${row.S3Key}`}>
            <StyledS3Loc>
              <small>[bucket: {row.S3Bucket}]</small>
              {row.S3Key}
            </StyledS3Loc>
          </DesignSuite2023.Tooltip>
        )
      },
    },
  },
  S3LastModified: {
    name: 'File Received At',
    details: {
      sortName: 'elig_inbound.s3_last_modified',
      dataFormat(_: any, row: ShapeEligInbound): any {
        return dateTime
          .parse(row.S3LastModified)
          .local()
          .format(dateTime.formats.PrettyVerbose)
      },
    },
  },
  NotesAndSubset: {
    name: 'Extra',
    details: {
      dataFormat(_: any, row: ShapeEligInbound): any {
        return (
          <div>
            {!!row.FileEligUploadSubsetHandle && (
              <small>Subset: {row.FileEligUploadSubsetHandle}</small>
            )}
            {!!row.FileEligUploadFileRecordNotes && (
              <p>
                <strong>Notes:</strong> {row.FileEligUploadFileRecordNotes}
              </p>
            )}
          </div>
        )
      },
    },
  },
  Matched: {
    name: 'Hooks',
    details: {
      dataFormat(_: any, row: ShapeEligInbound): any {
        if (row.NoMatchedHooks) {
          return (
            <DesignSuite2023.AlertWarning style={{ minWidth: 300 }}>
              No matching hooks (will pass through **as is**).
            </DesignSuite2023.AlertWarning>
          )
        }
        return (
          <DesignSuite2023.Tooltip
            title={
              row.IsFallback ? `Fallback` : `Matched by regex: ${row.Regex}`
            }>
            <span>{row.Handle}</span>
          </DesignSuite2023.Tooltip>
        )
      },
    },
  },
  Hooks: {
    name: 'Hook Details',
    details: {
      dataFormat(_: any, row: ShapeEligInbound): any {
        if (row.NoMatchedHooks) {
          return <DesignSuite2023.CommonIcons.IconDeny />
        }
        return (
          <DesignSuite2023.Tooltip
            interactive
            title={
              <StyledPre>
                {JSON.stringify(row.ConfiguredHooks, null, '  ')}
              </StyledPre>
            }>
            <DesignSuite2023.CommonIcons.IconPeek />
          </DesignSuite2023.Tooltip>
        )
      },
    },
  },
})

const defaultSort = { col: 'elig_inbound.s3_last_modified', dir: 'desc' }

export const Table = forwardRef(function TableEligInboundStaged(
  {
    customColumns = defaultColumns,
    apiEndpoint = apiEndpointGetInbound,
    // fnLinkOnRowClick = (row:LOADecoratedShape) => `/loa/${row.ID}`,
    DataTableProps = {},
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
      initPageSize: 100,
      // fnLinkOnRowClick
      DataTableProps: {
        ...DataTableProps,
        density: 'small',
        rowsPerPage: [100, 250],
      },
    },
    ref
  )

  return <>{TableDisplay}</>
})

// In order to support secondary sort, we need a way to merge the secondary one
// with the sort that would be set by the UI. If we want to further non-hacky support
// of secondary sorts, it's gonna be a bigger lift down to the DataTable component.
function apiEndpointGetInbound(params: any): Promise<any> {
  if (!params.sort?.includes('elig_inbound.s3_key')) {
    params.sort = [...params.sort, 'elig_inbound.s3_key', 'asc']
  }
  return getInboundPreStagePlan(params)
}
