import React, { useEffect, useRef, useState } from 'react'
import { useReferrerForm } from './hooks'
import * as api from '../../services/thezerocard/api-helper'
import useQueryParamsGen2 from '../../hooks/useQueryParamsGen2'
import { FormTextField } from '../../components/Inputs'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Tab,
  Tabs,
} from '@material-ui/core'
import * as ContactTable from '../Contacts/ContactTable'
import ReferrerFileTableContainer from './ReferrerFileTableContainer'
import GoZeroUsersTab from './tabGoZeroUsers'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import * as EmployerTable from '../Employers/EmployerTable'
import EmployerManagerV2 from '../Employers/EmployerManagerV2'
import { Employer } from '../../models/types/Employer'
import { findGroupEmployersV2 } from '../../actions/GroupActions'
import {
  getReferrer,
  getReferrerContactTypes,
  getReferrerEmployers,
  updateReferrerEmployers,
} from '../../actions/ReferrerActions'
import utils from '../../utils'
import EmployerAccessCard from '../../components/EmployerAccessCard/EmployerAccessCard'

const childTabs = {
  contacts: 'cts',
  files: 'fls',
  gozero: 'gz',
  employers: 'emps',
}

export default function ReferrerDetail({
  history,
  location,
  ...props
}: any): any {
  const { queryData, setQueryData } = useQueryParamsGen2({ scope: 'rfrDetail' })
  const [childTabVal, setChildTabVal] = useState<string>(
    queryData.childTab || childTabs.contacts
  )
  const [contactTypes, setContactTypes] = useState<any[]>()
  const [employers, setEmployers] = useState<Employer[]>([])
  const [referrerCanAccessAllEmployers, setReferrerCanAccessAllEmployers] =
    useState<boolean>(false)
  const refEmployersTable = useRef()
  const referrerID: number | null = +props?.match?.params?.id || null
  const { catchAPIError } = useErrorHandlers()
  const { form, save, errors } = useReferrerForm(referrerID)

  useEffect(
    function () {
      if (!referrerID) return

      getReferrer(referrerID)
        .then((res: any) => form.setData(res))
        .catch(
          catchAPIError({
            defaultMessage:
              'Failed to load referrer; please contact engineering',
          })
        )

      fetchReferrerEmployers()

      getReferrerContactTypes()
        .then((res: any) =>
          setContactTypes(
            utils.getSelectOptions(res.Data, {
              valueKey: 'ID',
              labelKey: 'Description',
            })
          )
        )
        .catch(
          catchAPIError({
            defaultMessage:
              'Failed to fetch referrer Contact Types; please contact engineering',
          })
        )
    },
    [referrerID]
  )

  useEffect(() => {
    setQueryData({ childTab: childTabVal })
  }, [childTabVal])

  function fetchReferrerEmployers(): Promise<any> {
    return getReferrerEmployers(referrerID!)
      .then((res: any) => {
        setEmployers(res.Data.Employers)
        setReferrerCanAccessAllEmployers(res.Data.AllEmployers)
        //@ts-ignore
        refEmployersTable?.current?.refresh()
        return res
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to fetch referrer employers; please contact engineering',
        })
      )
  }

  function handleUpdateReferrerEmployers(ids: number[]): Promise<void> {
    return updateReferrerEmployers({
      referrerID: referrerID!,
      payload: { EmployerIDs: ids, AllEmployers: false },
    })
      .then(() => fetchReferrerEmployers())
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to update referrer employers; please contact engineering',
        })
      )
  }

  function handleChangeAccesslevel(): Promise<void> {
    return updateReferrerEmployers({
      referrerID: referrerID!,
      payload: {
        EmployerIDs: [],
        AllEmployers: !referrerCanAccessAllEmployers,
      },
    })
      .then(() => fetchReferrerEmployers())
      .then(({ Data }: any) => {
        if (Data?.AllEmployers) {
          if (childTabIs(childTabs.employers)) {
            setChildTabVal(childTabs.contacts)
          }
          return
        }
        setChildTabVal(childTabs.employers)
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to update referrer employers; please contact engineering',
        })
      )
  }

  function handleSaveNewContact(contact: any): Promise<any> {
    return api.post(`/referrer/${referrerID}/contact`, contact)
  }

  function childTabIs(x: string): boolean {
    return childTabVal === x
  }

  function backToReferrers(): void {
    const qs = location.state?.prevSearch ? location.state.prevSearch : ''
    history.push(`/referrers${qs}`)
  }

  const employerGroupFilters = {
    NoLimit: true,
    accessGroupIDs: [form.data.DefaultGoZeroAccessGroupID],
  }

  return (
    <>
      <Paper elevation={2} style={{ padding: '2rem', marginBottom: '1rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Card>
              <CardHeader title="General" />
              <CardContent>
                <FormTextField
                  form={form}
                  errors={errors}
                  fieldName="Name"
                  label="Name"
                  placeholder="Ben Franklin"
                />
                <FormTextField
                  form={form}
                  errors={errors}
                  fieldName="Website"
                  label="Website"
                  placeholder="https://..."
                />
                <FormTextField
                  form={form}
                  errors={errors}
                  fieldName="Owner"
                  label="Owner"
                  placeholder="Dave Davies"
                />
                <FormTextField
                  form={form}
                  errors={errors}
                  fieldName="Notes"
                  label="Notes"
                  placeholder="..."
                  multiline
                  rows={3}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <EmployerAccessCard
              EntityID={referrerID}
              DefaultGoZeroAccessGroupID={form.data.DefaultGoZeroAccessGroupID}
              EntityCanAccessAllEmployers={referrerCanAccessAllEmployers}
              HandleChangeAccessLevel={handleChangeAccesslevel}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} justify="space-between">
          <Grid container direction="column" alignItems="flex-end">
            <Button onClick={backToReferrers}>{'< Back to Referrers'}</Button>
            <Button variant="contained" color="primary" onClick={save}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {referrerID && (
        <Paper elevation={2} style={{ padding: '2rem' }}>
          <Tabs
            value={childTabVal}
            onChange={(_: any, v: any) => setChildTabVal(v)}
            indicatorColor="primary"
            textColor="inherit"
            style={{ margin: '-2rem -2rem 1rem' }}>
            <Tab value={childTabs.contacts} label="Contacts" />
            <Tab value={childTabs.files} label="Files" />
            <Tab value={childTabs.gozero} label="GoZERO" />
            {!referrerCanAccessAllEmployers ? (
              <Tab value={childTabs.employers} label="Employers" />
            ) : null}
          </Tabs>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {childTabIs(childTabs.contacts) && (
                <ContactTable.Table
                  passFilters={{
                    ReferrerID: Number(referrerID),
                    IncludeInactive: false,
                  }}
                  handleSaveNewContact={handleSaveNewContact}
                  contactTypes={contactTypes}
                  LeftHeaderItems={
                    <>
                      <ContactTable.StandardFilterSearch />
                      &nbsp;
                      <ContactTable.ContactTypeTableFilter entityType="referrer" />
                      &nbsp;
                      <ContactTable.SwitchInactive />
                      <ContactTable.FilterIsPrimaryContact />
                    </>
                  }
                />
              )}
              {childTabIs(childTabs.files) && (
                <ReferrerFileTableContainer id={referrerID || 0} />
              )}
              {childTabIs(childTabs.gozero) && (
                <GoZeroUsersTab referrerID={referrerID} />
              )}
              {childTabIs(childTabs.employers) &&
                !referrerCanAccessAllEmployers && (
                  <EmployerTable.Table
                    ref={refEmployersTable}
                    apiEndpoint={findGroupEmployersV2}
                    passFilters={employerGroupFilters}
                    LeftHeaderItems={
                      <EmployerTable.StandardFilterSearch autoFocus />
                    }
                    RightHeaderItems={
                      <EmployerManagerV2
                        saveEmployers={handleUpdateReferrerEmployers}
                        currentEmployers={employers}
                      />
                    }
                  />
                )}
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  )
}
