import React, { useRef } from 'react'
import { Button, ButtonProps } from '@material-ui/core'
import DisplayBillingEntity, {
  PartialBillingEntity,
} from './DisplayBillingEntity'
import { ShapeBillingEntity, BillingEntityCreationSource } from './types'
import DesignSuite2023 from '../../components/DesignSuite2023'

interface props {
  size?: ButtonProps['size']
  variant?: ButtonProps['variant']
  color?: ButtonProps['color']
  buttonLabel?: string
  tooltip?: string | null
  onSave?: (be: ShapeBillingEntity) => void
  organizationID?: number | null
  creationSource?: BillingEntityCreationSource
  showToggleIsContracted?: boolean
  initValues?: Partial<PartialBillingEntity>
}

export default function BtnCreateNewBillingEntity({
  size = 'small',
  variant = 'outlined',
  color = 'primary',
  buttonLabel = 'Create New',
  tooltip = null,
  onSave: propOnSave,
  organizationID = null,
  creationSource = BillingEntityCreationSource.Manual,
  showToggleIsContracted = false,
  initValues,
}: props): React.ReactElement | null {
  const refDisplay = useRef<any>(null)

  function onSave(be: ShapeBillingEntity) {
    propOnSave?.(be)
  }

  function onClick() {
    refDisplay?.current?.openEmpty(initValues || {})
  }

  return (
    <>
      <DesignSuite2023.Tooltip title={tooltip}>
        <Button onClick={onClick} variant={variant} size={size} color={color}>
          {buttonLabel}
        </Button>
      </DesignSuite2023.Tooltip>

      <DisplayBillingEntity
        ref={refDisplay}
        onSave={onSave}
        organizationID={organizationID}
        creationSource={creationSource}
        showToggleIsContracted={showToggleIsContracted}
      />
    </>
  )
}
