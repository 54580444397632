import React, { useEffect, useState, useCallback } from 'react'
import Models from '../../models'
import { DataTable } from '../DataTable'
import { Button, Grid } from '@material-ui/core'
import DialogAddGoZeroUser from './DialogAddUser'
import DialogAddGoZeroUsers from './DialogAddUsers'
import util from '../../reducers/utils'
import { get as _get } from 'lodash'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'

interface Props {
  fnSearchUsersToAdd(params: any): Promise<any>
  fnGetUsers(): Promise<any>
  fnGetAllGoZeroUserIDs(): Promise<any>
  fnPutToggleAdmin(userID: number, to: boolean): Promise<any>
  fnPostSendPasswordResetEmail(userID: number): Promise<any>
  fnPutGoZeroUsers(payload: any): Promise<any>
  fnCreateGoZeroUser(payload: any): Promise<any>
  fnDeleteGoZeroUsers(payload: any): Promise<any>
  customColumns?: any // object with more column definitions; get merged if provided
}

const errMsgUnknown =
  'An unknown error occurred; please contact Engineering for assistance'

export default function GoZeroUserList(props: Props) {
  const [loading, setLoading] = useState<boolean>(true)
  const [userList, setUserList] = useState<Array<any>>([])
  const [allUserIDsList, setAllUserIDsList] = useState<Array<number>>([])
  const [searchErr, setSearchErr] = useState<Object | null>(null)
  const [openDialogCreateUser, setOpenDialogCreateUser] =
    useState<boolean>(false)
  const [checkedRows, setCheckedRows] = useState<Array<any>>([])
  const snackbar = useSnackbar()

  const doLoadGoZeroUsers = useCallback((): any => {
    return Promise.all([props.fnGetUsers(), props.fnGetAllGoZeroUserIDs()])
      .then(([usersPaginated, allUserIDs]: [any, any]) => {
        setLoading(false)
        if (usersPaginated.error) {
          throw usersPaginated
        }
        setUserList(usersPaginated.Data || [])
        setAllUserIDsList(allUserIDs.Data || [])
      })
      .catch((e: any) => {
        setSearchErr(util.errorResponse(e))
      })
  }, [props])

  useEffect(() => {
    doLoadGoZeroUsers()
  }, [doLoadGoZeroUsers])

  const notificationText = ['Off', 'Instant', 'Nightly']

  const cols = Object.assign({}, Models.GoZeroUsers.columns, {
    MakeAdmin: {
      name: 'Make Admin',
      details: {
        width: 200,
        dataFormat: (cell: any, row: any) => {
          const isAdmin = row.CanManageOtherUsers
          const text = isAdmin ? 'Remove Admin' : 'Make Admin'
          const color = isAdmin ? 'secondary' : 'primary'
          return (
            <Button
              color={color}
              variant="outlined"
              onClick={doToggleAdmin.bind(null, row.UserID, !isAdmin)}>
              {text}
            </Button>
          )
        },
      },
    },
    GoZeroNotifications: {
      name: 'Notifications',
      details: {
        dataFormat: (cell: number) =>
          notificationText[cell] ? notificationText[cell] : 'Default',
      },
    },
    SendEmail: {
      name: 'Send Email',
      details: {
        width: 200,
        dataFormat: (cell: any, row: any) => {
          return (
            <Button
              color="primary"
              variant="outlined"
              onClick={doSendPasswordResetEmail.bind(null, row.UserID)}>
              Send Set Password Email
            </Button>
          )
        },
      },
    },
    ...props.customColumns,
  })

  function doToggleAdmin(userID: number, to: boolean): any {
    const successMessage = to
      ? 'User successfully made Admin'
      : 'Admin priveleges successfully removed'
    props
      .fnPutToggleAdmin(userID, to)
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        snackbar.show(successMessage, SnackbarTypeSuccess)
        return doLoadGoZeroUsers()
      })
      .catch(handleAPIError)
  }

  function doSendPasswordResetEmail(userID: number): any {
    props
      .fnPostSendPasswordResetEmail(userID)
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        snackbar.show('Password reset email sent OK', SnackbarTypeSuccess)
      })
      .catch(handleAPIError)
  }

  const onAddUsers = (list: Array<any> | null) => {
    props
      .fnPutGoZeroUsers({
        UserIDs: (list || []).map((v: any) => {
          return v.ID
        }),
      })
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        snackbar.show('User(s) successfully added!', SnackbarTypeSuccess)
        return doLoadGoZeroUsers()
      })
      .catch(handleAPIError)
  }

  const onCreateNewUser = (u: any) => {
    props
      .fnCreateGoZeroUser(u)
      .then((res: any) => {
        setOpenDialogCreateUser(false)
        if (res.error) {
          throw res
        }
        snackbar.show('User successfully created!', SnackbarTypeSuccess)
        return doLoadGoZeroUsers()
      })
      .catch(handleAPIError)
  }

  const onCheckRow = (selected: any) => {
    setCheckedRows(selected ? selected : [])
  }

  const onClickRemoveSelected = () => {
    props
      .fnDeleteGoZeroUsers({
        IDs: (checkedRows || []).map((v: any) => {
          return v.ID
        }),
      })
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        snackbar.show('User(s) successfully removed!', SnackbarTypeSuccess)
        return doLoadGoZeroUsers()
      })
      .catch(handleAPIError)
  }

  function handleAPIError(e: any) {
    const msg = _get(e, 'Error.Message', null)
    if (msg) {
      snackbar.show(msg, SnackbarTypeError)
      return
    }
    snackbar.show(errMsgUnknown, SnackbarTypeError)
  }

  /*
    fnCheckUserAlreadyInGroup is a callback function passed to the DialogAddGoZeroUser*s*
    component, where that component passes in a userID to see if its already a member of the
    group/set, and can display feedback in the UI as necessary. Note that we keep track of 
    all the users IDs in the group, in this component, to use for this comparison. We *cannot* 
    use the paginated results list of users because that would be an incomplete picture, so we 
    always reload the full 'allUserIDsList' as necessary.
  */
  const fnCheckUserAlreadyInGroup = (userID: number) => {
    return allUserIDsList.includes(userID)
  }

  return (
    <div>
      <DataTable
        columns={cols}
        data={userList}
        loading={loading}
        pagination={false}
        error={searchErr}
        keyProp="ID"
        checkHandler={onCheckRow}
      />

      <div>
        <Grid container spacing={2} justify="space-between">
          {checkedRows.length ? (
            <Grid item xs="auto">
              <Button
                style={{ marginTop: 15 }}
                color="primary"
                variant="outlined"
                onClick={onClickRemoveSelected}>
                Remove Selected User(s)
              </Button>
            </Grid>
          ) : null}
          <Grid item xs={checkedRows.length ? 'auto' : 12}>
            <DialogAddGoZeroUsers
              fnSearchUsersToAdd={props.fnSearchUsersToAdd}
              onConfirm={onAddUsers}
              inGroupChecker={fnCheckUserAlreadyInGroup}
            />
            <Button
              style={{ float: 'right', marginTop: 15 }}
              color="secondary"
              variant="contained"
              onClick={setOpenDialogCreateUser.bind(null, true)}>
              Add New User
            </Button>
          </Grid>
        </Grid>
      </div>

      <DialogAddGoZeroUser
        open={openDialogCreateUser}
        onCancel={setOpenDialogCreateUser.bind(null, false)}
        onCreateNewUser={onCreateNewUser}
      />
    </div>
  )
}
