import utils from '../utils/'
import { Checkbox, TextField } from '@material-ui/core'

interface ModelProps {
  columns: cols
  hasAccess: any
}

interface cols {
  Description: col
  Hash: col
  ID: col
  IsEmployerMemberType: col
  IsEmployerType: col
  IsLoaType: col
  IsOrganizationType: col
  IsPHIType: col
  IsPartnerType: col
  IsReferrerType: col
  ManagedInternally: col
}

interface col {
  name: string
  details?: any
}

const FileType: ModelProps = {
  columns: {
    ID: { name: 'ID', details: { editable: false, hidden: true } },
    Description: {
      name: 'Description',
      details: { width: '200px', editable: true, EditComponent: TextField },
    },
    Hash: { name: 'Hash', details: { editable: false } },
    IsEmployerMemberType: {
      name: 'Employer Member',
      details: {
        dataFormat: utils.boolCheckboxFormatter,
        EditComponent: Checkbox,
        valProperty: 'checked',
        width: '90px',
        editable: true,
      },
    },
    IsEmployerType: {
      name: 'Employer',
      details: {
        dataFormat: utils.boolCheckboxFormatter,
        EditComponent: Checkbox,
        valProperty: 'checked',
        width: '70px',
        editable: true,
      },
    },
    IsLoaType: {
      name: 'LOA',
      details: {
        dataFormat: utils.boolCheckboxFormatter,
        EditComponent: Checkbox,
        valProperty: 'checked',
        width: '50px',
        editable: true,
      },
    },
    IsOrganizationType: {
      name: 'Org',
      details: {
        dataFormat: utils.boolCheckboxFormatter,
        EditComponent: Checkbox,
        valProperty: 'checked',
        width: '50px',
        editable: true,
      },
    },
    IsPHIType: {
      name: 'PHI',
      details: {
        dataFormat: utils.boolCheckboxFormatter,
        EditComponent: Checkbox,
        valProperty: 'checked',
        width: '50px',
        editable: true,
      },
    },
    IsPartnerType: {
      name: 'Partner',
      details: {
        dataFormat: utils.boolCheckboxFormatter,
        EditComponent: Checkbox,
        valProperty: 'checked',
        width: '70px',
        editable: true,
      },
    },
    IsReferrerType: {
      name: 'Referrer',
      details: {
        dataFormat: utils.boolCheckboxFormatter,
        EditComponent: Checkbox,
        valProperty: 'checked',
        width: '90px',
        editable: true,
      },
    },
    ManagedInternally: {
      name: 'Internal',
      details: {
        dataFormat: utils.boolCheckboxFormatter,
        editable: false,
        width: '90px',
      },
    },
  },
  hasAccess: () => true,
}

export default FileType
