import React, { useState, useEffect } from 'react'
import DesignSuite2023 from '../../../../components/DesignSuite2023'
import helpers from '../../helpers'
import {
  putZClaimDiagnosisCodes,
  putZClaimProcedureCodes,
} from '../../../../actions/ZClaimActions'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../../hooks/useSnackbar'
import {
  // Button,
  IconButton,
  InputAdornment,
} from '@material-ui/core'
import styled from 'styled-components'
import StatusDot from '../StatusDot'

const StyledCodesList = styled.div`
  .code-list {
    .code-list-item {
      margin-right: 0.5rem;
      padding: 0.45rem 0 0.35rem;
      display: inline-block;
    }
  }
`

interface props {
  zClaimID?: number | null
  codes?: string[]
  title?: React.ReactElement
  onCodeChange?(codes: string[]): void
  action?: (zClaimID: number, data: any) => Promise<any>
  readOnly?: boolean
  markDirty?: (v: boolean) => void
}

export const displayCodeListActions = {
  putZClaimDiagnosisCodes,
  putZClaimProcedureCodes,
}

export default React.forwardRef(function DisplayCodesList(
  {
    zClaimID,
    codes,
    title,
    onCodeChange,
    readOnly = false,
    markDirty,
    action = () =>
      Promise.reject({
        Error: { Message: 'Cannot save code list without an action' },
      }),
  }: props,
  ref: any
): React.ReactElement {
  const [trackedCodes, setTrackedCodes] = useState<string[]>(codes || [])
  const [isDirty, setIsDirty] = useState(false)
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  React.useImperativeHandle(
    ref,
    () => ({
      isDirty,
      doSave,
    }),
    [isDirty, trackedCodes]
  )

  useEffect(() => {
    markDirty?.(isDirty)
  }, [isDirty])

  useEffect(() => {
    setIsDirty((codes || []).toString() !== (trackedCodes || []).toString())
  }, [codes, trackedCodes])

  useEffect(() => {
    onCodeChange && onCodeChange(trackedCodes)
    if ((codes || []).toString() === trackedCodes.toString()) {
      return
    }
  }, [trackedCodes])

  function addEmptyPlaceholder() {
    setTrackedCodes((curr: any) => {
      const x = [...curr, '']
      return x
    })
  }

  function onRemove(index: number): () => void {
    return () => {
      setTrackedCodes((curr: any) => {
        const x = [...curr]
        x.splice(index, 1)
        return x
      })
    }
  }

  function doSave(): Promise<any> {
    if (!zClaimID) {
      return Promise.reject({
        Error: { Message: 'ZClaimID required to save codes' },
      })
    }
    return new Promise((rslv, rej) => {
      action(zClaimID, { codes: trackedCodes })
        .then(() => {
          showSnackbar('Codes updated OK', SnackbarTypeSuccess)
          rslv(null)
        })
        .catch(
          catchAPIError({
            defaultMessage: 'Failed updating codes',
            withError: rej,
          })
        )
    })
  }

  return (
    <StyledCodesList>
      {!!title && (
        <helpers.FlexAlign spaced>
          <helpers.FlexAlign tag="h5">
            {title}
            {isDirty && <StatusDot.Dot color={StatusDot.Colors.YELLOW} />}
          </helpers.FlexAlign>
        </helpers.FlexAlign>
      )}

      <div className="code-list">
        {trackedCodes.map((code: string, idx: number) => {
          const positionKey = String.fromCharCode(97 + idx)
          return (
            <div className="code-list-item" key={positionKey}>
              <helpers.ExtraSmallTextField
                disabled={readOnly}
                value={code}
                style={{ maxWidth: 120 }}
                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                  setIsDirty(true)
                  setTrackedCodes((curr: any) => {
                    curr[idx] = ev.target.value
                    return [...curr]
                  })
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {positionKey.toUpperCase()}
                    </InputAdornment>
                  ),
                  endAdornment: readOnly ? null : (
                    <InputAdornment position="end">
                      <IconButton
                        size="small"
                        edge="end"
                        onClick={onRemove(idx)}>
                        <DesignSuite2023.CommonIcons.IconCancel fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )
        })}

        {!readOnly && (
          <IconButton size="small" onClick={addEmptyPlaceholder}>
            <DesignSuite2023.CommonIcons.IconAdd fontSize="inherit" />
          </IconButton>
        )}
      </div>

      {/* <small>needs save? {isDirty ? 'true' : 'false'}</small> */}
    </StyledCodesList>
  )
})
