// @ts-ignore
import React, { useState, forwardRef } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import { getEligInboundStaged } from '../../../actions/EngineeringActions'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { ShapeEligInboundStaged } from './types'
import EditEligibilityFileConfigDetails from '../../Employers/EditEligibilityFileConfigDetails'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import dateTime from '../../../utils/dateTime'

// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../../hooks/useStandardTableSetup'

const StyledS3Loc = styled.span`
  small {
    display: block;
    font-weight: 600;
  }

  display: block;
  overflow-wrap: anywhere;
  min-width: 250px;
`

const StyledCenter = styled.div`
  width: 100%;
  text-align: center;
`

const StyledNoWrap = styled.span`
  white-space: nowrap;
`

export const defaultColumns = Object.freeze({
  ID: { name: 'ID' },
  CreatedAt: {
    name: 'CreatedAt',
    details: {
      sortName: 'created_at',
      dataFormat: dateTime.cellFormatter(dateTime.formats.PrettyVerbose),
    },
  },
  // S3Bucket: {name: 'S3Bucket'},
  // S3Key: {name: 'S3Key'},
  S3Key: {
    name: 'File',
    details: {
      sortName: 's3_key',
      dataFormat(_: any, row: ShapeEligInboundStaged): any {
        return (
          <DesignSuite2023.Tooltip title={`${row.S3Bucket}/${row.S3Key}`}>
            <StyledS3Loc>
              <small>[bucket: {row.S3Bucket}]</small>
              {row.S3Key}
            </StyledS3Loc>
          </DesignSuite2023.Tooltip>
        )
      },
    },
  },
  AppliedHooks: {
    name: <StyledNoWrap>Applied Hooks</StyledNoWrap>,
    details: {
      width: 130,
      dataFormat(_: any, row: ShapeEligInboundStaged): any {
        if (!row.AppliedHooks) {
          return <StyledCenter>none</StyledCenter>
        }
        const tip = JSON.stringify(row.AppliedHooks, null, '  ')
        return (
          <StyledCenter>
            <DesignSuite2023.Tooltip
              interactive
              title={
                <DesignSuite2023.StyledPre>{tip}</DesignSuite2023.StyledPre>
              }>
              <DesignSuite2023.CommonIcons.IconPeek />
            </DesignSuite2023.Tooltip>
          </StyledCenter>
        )
      },
    },
  },
})

export const columnsWithMatchWarnings = Object.freeze({
  ...defaultColumns,
  HookWarnings: {
    name: <StyledNoWrap>Hook Warnings</StyledNoWrap>,
    details: {
      dataFormat(_: any, row: ShapeEligInboundStaged): any {
        return (
          <DesignSuite2023.StyledAlertGroup>
            {row.HookWarnings?.map((w: any) => (
              <DesignSuite2023.AlertWarning key={w}>
                {w}
              </DesignSuite2023.AlertWarning>
            ))}
          </DesignSuite2023.StyledAlertGroup>
        )
      },
    },
  },
  MatchWarnings: {
    name: <StyledNoWrap>Match Warnings</StyledNoWrap>,
    details: {
      dataFormat(_: any, row: ShapeEligInboundStaged): any {
        return (
          <DesignSuite2023.StyledAlertGroup>
            {row.MatchWarnings?.map((w: any) => (
              <DesignSuite2023.AlertError key={w}>
                {w}
              </DesignSuite2023.AlertError>
            ))}
          </DesignSuite2023.StyledAlertGroup>
        )
      },
    },
  },
})

export const columnsWithMatchInfo = Object.freeze({
  ...defaultColumns,
  MatchedEligFileConfigID: {
    name: <StyledNoWrap>Elig File Config</StyledNoWrap>,
    details: {
      dataFormat(_: any, row: ShapeEligInboundStaged): any {
        return (
          <ViewMatchedFileConfig
            eligFileConfigID={row.MatchedEligFileConfigID}
            label={row.EmployerName}
            employerID={row.EmployerID}
          />
        )
      },
    },
  },
  MatchedEligFileConfigAt: {
    name: <StyledNoWrap>Matched File Config At</StyledNoWrap>,
    details: {
      dataFormat: dateTime.cellFormatter(dateTime.formats.PrettyVerbose),
    },
  },
})

export const columnsWithProcessableFile = Object.freeze({
  ...defaultColumns,
  ProcessableFile: {
    name: 'Processable',
    details: {
      dataFormat(_: any, row: ShapeEligInboundStaged): any {
        const { FileName, ...rowNoFileName } = row.ProcessableFile // ditch the filename because it is redundant and long
        return (
          <DesignSuite2023.StyledPre>
            {JSON.stringify(rowNoFileName, null, '  ')}
          </DesignSuite2023.StyledPre>
        )
      },
    },
  },
  Details: {
    name: <StyledNoWrap>Details</StyledNoWrap>,
    details: {
      dataFormat(_: any, row: ShapeEligInboundStaged): any {
        return (
          <div>
            {row.OnHold ? <div>On Hold</div> : null}
            {row.ProcessableFileProcessDate ? (
              <div>Process Date: {row.ProcessableFileProcessDate}</div>
            ) : null}
            {row.Notes ? <div>{row.Notes}</div> : null}
          </div>
        )
      },
    },
  },
  ProcessableFileWarnings: {
    name: <StyledNoWrap>Processable File Warnings</StyledNoWrap>,
    details: {
      dataFormat(_: any, row: ShapeEligInboundStaged): any {
        return (
          <DesignSuite2023.StyledAlertGroup>
            {row.ProcessableFileWarnings?.map((w: any) => (
              <DesignSuite2023.AlertWarning key={w}>
                {w}
              </DesignSuite2023.AlertWarning>
            ))}
          </DesignSuite2023.StyledAlertGroup>
        )
      },
    },
  },
})

const defaultSort = { col: 'created_at', dir: 'desc' }

const rowOptsApplier = (row: any) => {
  if (row.OnHold) {
    return { className: 'tr-warning' }
  }
  return null
}

const EligFileConfigBtn = styled(Button)`
  max-width: 240px;
`
export const Table = forwardRef(function TableEligInboundStaged(
  {
    customColumns = defaultColumns,
    apiEndpoint = getEligInboundStaged,
    DataTableProps = {},
    // fnLinkOnRowClick = (row:LOADecoratedShape) => `/loa/${row.ID}`,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      DataTableProps: {
        ...DataTableProps,
        rowOptsApplier,
        rowsPerPage: [100, 250],
      },
      customColumns,
      apiEndpoint,
      defaultSort,
      initPageSize: 100,
      // fnLinkOnRowClick
    },
    ref
  )

  return <>{TableDisplay}</>
})

function ViewMatchedFileConfig({
  label,
  eligFileConfigID,
  employerID,
}: any): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <DesignSuite2023.Tooltip title="Click to view elig file configs">
        <EligFileConfigBtn
          size="small"
          onClick={setIsOpen.bind(null, true)}
          variant="outlined">
          {label}
        </EligFileConfigBtn>
      </DesignSuite2023.Tooltip>

      {/* THIS LOOKS WEIRD: normally you'd want to use open={isOpen}, but the dialog is wired up
    incorrectly and fires hooks and things (eg. sends a request to the API) BEFORE it becomes
    visible. Idk if this is a redux thing or just incorrectly setup - but the safer bet is to
    let react render/unmount entirely */}
      {isOpen && (
        <EditEligibilityFileConfigDetails
          // @ts-ignore
          configID={eligFileConfigID}
          employerID={employerID} // @todo: nope
          open={true}
          handleClose={setIsOpen.bind(null, false)}
        />
      )}
    </>
  )
}
