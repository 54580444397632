import React, { useState, useRef, useEffect } from 'react'
import { Button, Grid } from '@material-ui/core'
import { Table, FileTableFilter, FileTableSearcher } from '../Files/FilesTable'
import FileUploadModal from '../Files/FileUploadModal'
import FileEditModal from '../Files/FileEditModal'
import { FileTypeEntities } from '../Files/FileTypeFilter'
import { deleteFiles } from '../../actions/FileActions'
import { File } from '../Files/types'
import useQueryParamsGen2 from '../../hooks/useQueryParamsGen2'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import styled from 'styled-components'

const DeleteButton = styled(Button)`
  margin-right: 10px;
`
const Spacer = styled(Grid)`
  width: 10px;
`

interface ReferrerFileTableContainerProps {
  id: number
}

const ReferrerFileTableContainer = ({
  id,
}: ReferrerFileTableContainerProps) => {
  const [REFERRER_ENTITY_TYPE] = useState([FileTypeEntities.Referrer])
  const REFERRER_FILE_DATA = { ReferrerID: id }
  const { queryData, setQueryData } = useQueryParamsGen2({
    scope: 'referrerFilesContainer',
  })
  const [selectedFiles, setSelectedFiles] = useState<Array<number>>([])
  const [editID, setEditID] = useState<any | null>(queryData.editID || null)
  const defaultFilters = { ReferrerID: Number(id) }
  const { catchAPIError } = useErrorHandlers()
  const refFileTable = useRef()

  useEffect(() => {
    let newQueryData = Object.assign({}, queryData)
    if (!editID) delete newQueryData.editID
    if (editID && !queryData.editID) newQueryData = { ...queryData, editID }
    setQueryData(newQueryData)
  }, [editID])

  const deleteSelectedFile = () => {
    deleteFiles(selectedFiles)
      .then(() => {
        //@ts-ignore
        refFileTable.current?.refresh()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to delete file; please contact Engineering',
        })
      )
  }

  const handleChecked = (selected: Array<File>) => {
    if (selected.length === 0) setSelectedFiles([])
    setSelectedFiles(selected.map((file) => file.ID))
  }

  const refreshTableList = () => {
    //@ts-ignore
    refFileTable.current?.refresh()
  }

  return (
    <Table
      ref={refFileTable}
      passFilters={defaultFilters}
      onCheckHandler={handleChecked}
      entityType={REFERRER_ENTITY_TYPE}
      onRowClick={(row: File) => {
        setEditID(row.ID)
      }}
      LeftHeaderItems={
        <>
          <FileTableSearcher />
          <Spacer />
          <FileTableFilter entityType={REFERRER_ENTITY_TYPE} />
        </>
      }
      RightHeaderItems={
        <Grid>
          {selectedFiles.length ? (
            <DeleteButton
              variant="contained"
              color="secondary"
              onClick={deleteSelectedFile}>
              Delete file
            </DeleteButton>
          ) : null}
          <FileUploadModal
            FileUploadProps={{
              onSuccess: refreshTableList,
              defaultFileData: REFERRER_FILE_DATA,
            }}
            entityType={REFERRER_ENTITY_TYPE}
          />
          {editID ? (
            <FileEditModal
              recordID={editID}
              handleClose={() => setEditID(null)}
              onSuccess={refreshTableList}
              entityType={REFERRER_ENTITY_TYPE}
              beforeSend={(v: File): File => {
                if (!v.ReferrerID) {
                  throw new Error('Must Select a Referrer')
                }
                return v
              }}
            />
          ) : null}
        </Grid>
      }
    />
  )
}

export default ReferrerFileTableContainer
