import React, { useState, useEffect } from 'react'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import * as api from '../../services/thezerocard/api-helper'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

interface invoiceTypeRecord {
  ID: number
  Descr: string
}

interface props {
  onChange?(InvoiceType: invoiceTypeRecord | null): void
  selectedID?: number
}

export default function InvoiceTypeFilter({
  onChange,
  selectedID,
  ...otherProps
}: props & Partial<any>): React.ReactElement {
  const [data, setData] = useState([])
  const [selected, setSelected] = useState<any>(null)
  const { showDurationShort: showSnackbarDuration } = useSnackbar()
  const { style, TextFieldProps, ...remainingProps } = otherProps

  useEffect(() => {
    const res = api.get('/invoice_types')
    if (!res) {
      showSnackbarDuration(
        'Indeterminate error: invoice types may not have loaded correctly',
        SnackbarTypeError
      )
      return
    }

    res
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        setData(res.Data)
        if (selectedID && selectedID > 0) {
          setSelected(res.Data.find((x: any) => x.ID === selectedID))
        }
      })
      .catch((err: any) => {
        if (err.Error?.Message) {
          showSnackbarDuration(err.Error.Message, SnackbarTypeError)
          return
        }
        showSnackbarDuration('Failed loading invoice types', SnackbarTypeError)
      })
  }, [setData, showSnackbarDuration])

  return (
    <Autocomplete
      options={data}
      getOptionLabel={(opt: any) => {
        return opt.Descr || ''
      }}
      getOptionSelected={(opt: any) => {
        return selected ? selected.ID === opt.ID : false
      }}
      value={selected}
      defaultValue={null}
      onChange={(_: any, opt: any | null) => {
        if (!opt) {
          setSelected(null)
          onChange && onChange(null)
          return
        }
        setSelected(opt)
        onChange && onChange(opt)
      }}
      renderInput={(params: any) => (
        <TextField
          {...params}
          variant="outlined"
          label="Invoice Type"
          placeholder="Choose..."
          size="small"
          InputLabelProps={{ shrink: true }}
          style={{ minWidth: 160 }}
          {...TextFieldProps}
        />
      )}
      style={{ ...style }}
      {...remainingProps}
    />
  )
}
