const bundler = {
  columns: {
    ID: { name: 'ID', details: {} },
    Name: { name: 'Name', details: {} },
  },
  editFields: {
    ID: 0,
    Name: '',
  },
  /* TODO: remove after hooking up bundlers for real */
  ZEROCARD: {
    ID: 1,
    Name: 'ZeroCard',
  },
}

export default bundler
