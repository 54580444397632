import dateTime from '../../utils/dateTime'

function parseString(v) {
  return { result: !v ? null : v, ok: !!v, _original: v }
}

function parseBool(v) {
  const r = { result: null, ok: false, _original: v }
  if (v === true || v === 'true' || v === '1' || v === 1 || v === 'yes') {
    r.result = true
    r.ok = true
  }
  if (v === false || v === 'false' || v === '0' || v === 0 || v === 'no') {
    r.result = false
    r.ok = true
  }
  return r
}

function parseInteger(v) {
  const r = { result: null, ok: false, _original: v }
  if (isNaN(parseInt(v, 10)) === false) {
    r.result = parseInt(v, 10)
    r.ok = true
  }
  return r
}

function parseDate(v) {
  const r = { result: null, ok: false, _original: v }
  const m = dateTime.parse(v)
  if (m.isValid()) {
    r.result = m.format(dateTime.formats.ISODate)
    r.ok = true
  }
  return r
}

export default {
  string: {
    parser: parseString,
    displayable(v) {
      const r = parseString(v)
      return { ...r, show: r.result === null ? '<NULL>' : r.result }
    },
  },
  bool: {
    parser: parseBool,
    displayable(v) {
      const r = parseBool(v)
      return {
        ...r,
        show: r.result === null ? '<NULL>' : r.result ? 'TRUE' : 'FALSE',
      }
    },
  },
  integer: {
    parser: parseInteger,
    displayable(v) {
      const r = parseInteger(v)
      return { ...r, show: r.result === null ? '<NULL>' : r.result }
    },
  },
  date: {
    parser: parseDate,
    displayable(v) {
      const r = parseDate(v)
      return { ...r, show: r.result === null ? '<NULL>' : r.result }
    },
  },
}
