import React, { useState, useEffect, useRef } from 'react'
import * as api from '../../../../../services/thezerocard/api-helper'
import useErrorHandlers from '../../../../../hooks/useErrorHandlers'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
  SnackbarTypeWarning,
} from '../../../../../hooks/useSnackbar'
import DesignSuite2023 from '../../../../../components/DesignSuite2023'
import {
  postZClaimSourceFileIngest,
  postZClaimSourceFileScanNew,
  postZClaimProcessSources,
} from '../../../../../actions/ZClaimActions'
import * as SourceFileTable from './SourceFileTable'
import { Button, IconButton } from '@material-ui/core'
import { Undo as IconUndo, BlurLinear as IconDoWork } from '@material-ui/icons'
import styled from 'styled-components'
import ConfirmDialog from '../../../../../components/ConfirmDialog'
import { canExecEngineering } from '../../../../../utils/perms'

const StyledWorkflow = styled.div`
  .std-table {
    .header-items {
      margin-top: -0.5rem;
    }
  }
`

export default function WorkflowSourceFiles(): React.ReactElement | null {
  const refSourceFileTable = useRef<any>(null)
  const [selectedSourceFiles, setSelectedSourceFiles] = useState([])
  const [canIngest, setCanIngest] = useState(false)
  const [isIngesting, setIsIngesting] = useState(false)
  const [canProcess, setCanProcess] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [displayTestParseError, setDisplayTestParseError] =
    useState<React.ReactElement | null>(null)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const [canEngeeringOnly] = useState<boolean>(canExecEngineering())

  useEffect(() => {
    if (!selectedSourceFiles.length) {
      setCanIngest(false)
      setCanProcess(false)
      return
    }
    let canIngest = true,
      canProcess = true
    selectedSourceFiles.forEach((b: any) => {
      if (canIngest && b.Ingested) {
        canIngest = false
      }
      if (canProcess && !b.Ingested) {
        canProcess = false
      }
    })
    setCanIngest(canIngest)
    setCanProcess(canProcess)
  }, [selectedSourceFiles])

  function onClickIngestSourceFile() {
    if (isIngesting) return
    setIsIngesting(true)
    const sfIDs = selectedSourceFiles.map((b: any) => b.ID)
    postZClaimSourceFileIngest({ sourceFileIDs: sfIDs })
      .then((res: any) => {
        showSnackbar(
          `SourceFile ingestion OK (${res.Data?.Duration})`,
          SnackbarTypeSuccess,
          4000
        )
        if (res.Data?.ErroredSourceFiles?.length >= 1) {
          // @todo: improve the detail of reporting back to users before this gets promoted from Advanced tools
          showSnackbar(
            `One or more sourceFiles errored (but others may have succeeded)`,
            SnackbarTypeWarning
          )
        }
      })
      .catch(catchAPIError({ defaultMessage: 'SourceFile ingestion failed' }))
      .finally(() => {
        refSourceFileTable.current?.refresh()
        setIsIngesting(false)
      })
  }

  function onClickProcessSources() {
    if (isProcessing) return
    setIsProcessing(true)
    const sourceFileIDs = selectedSourceFiles.map((b: any) => b.ID)
    postZClaimProcessSources({ sourceFileIDs })
      .then((res: any) => {
        showSnackbar(
          `Sources processed OK (${res.Data?.Duration})`,
          SnackbarTypeSuccess
        )
      })
      .catch(catchAPIError({ defaultMessage: 'Source processing failed' }))
      .finally(() => {
        refSourceFileTable.current?.refresh()
        setIsProcessing(false)
      })
  }

  function onClickScanNew() {
    postZClaimSourceFileScanNew()
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar(
          `Scan completed OK (${res.Data?.Duration})`,
          SnackbarTypeSuccess
        )
        refSourceFileTable.current?.refresh()
      })
      .catch(catchAPIError({ defaultMessage: 'SourceFile ingestion failed' }))
  }

  function onClickRollback() {
    ConfirmDialog({
      content: 'Continue? This will permanantly remove all associated records.',
      onConfirm() {
        const sourceFileIDs = selectedSourceFiles.map((b: any) => b.ID)
        api
          .post(`/zclaims/dev_tools/rollback_source_file`, { sourceFileIDs })
          .then((res: any) => {
            if (res.error) throw res
            showSnackbar(`SourceFiles rolled back`, SnackbarTypeSuccess)
            refSourceFileTable.current?.refresh()
          })
          .catch(
            catchAPIError({ defaultMessage: 'Failed rolling back sourceFiles' })
          )
      },
    })
  }

  function onClickTestParse() {
    const sourceFileIDs = selectedSourceFiles.map((b: any) => b.ID)
    if (sourceFileIDs.length !== 1) {
      showSnackbar(
        'Can only test parse one sourceFile at a time',
        SnackbarTypeWarning
      )
      return
    }
    api
      .post(`/zclaims/dev_tools/test_parse/${sourceFileIDs[0]}`)
      .then((res: any) => {
        if (res.Data?.OK) {
          showSnackbar(`SourceFile parsed successfully`, SnackbarTypeSuccess)
          return
        }
        showSnackbar(`SourceFile failed to parse`, SnackbarTypeError)
        setDisplayTestParseError(
          <div style={{ padding: '1rem' }}>
            <DesignSuite2023.StyledPre>
              {res.Data?.ErrorMessage ||
                'Failed parsing file but details unknown... See logs.'}
            </DesignSuite2023.StyledPre>
          </div>
        )
      })
      .catch(catchAPIError({ defaultMessage: 'Failed parsing sourceFile' }))
  }

  function onClickHideFiles() {
    const sourceFileIDs = selectedSourceFiles.map((b: any) => b.ID)
    api
      .post(`/zclaims/dev_tools/hide_source_files`, { sourceFileIDs })
      .then((res: any) => {
        if (res?.error) throw res
        showSnackbar(`SourceFile(s) hidden successfully`, SnackbarTypeSuccess)
        refSourceFileTable.current?.refresh()
      })
      .catch(catchAPIError({ defaultMessage: 'Failed parsing sourceFile' }))
  }

  return (
    <StyledWorkflow>
      <SourceFileTable.Table
        ref={refSourceFileTable}
        onCheckHandler={setSelectedSourceFiles}
        enableURLReflection="zclaims.workflows.sourceFileTable"
        LeftHeaderItems={
          <>
            <h4>SourceFiles</h4>
            &nbsp;&nbsp;
            <SourceFileTable.StandardFilterSearch />
            &nbsp;&nbsp;
            <SourceFileTable.FilterSourceFileState />
            &nbsp;&nbsp;
            <SourceFileTable.FilterSourceType />
            &nbsp;&nbsp;
            <SourceFileTable.FilterIsHidden />
          </>
        }
        RightHeaderItems={
          <>
            {!!selectedSourceFiles.length && (
              <>
                {canIngest && (
                  <>
                    <DesignSuite2023.Tooltip title="Ingest (or try to *re*-ingest) selected file(s)">
                      <span>
                        <Button
                          disabled={isIngesting}
                          variant="outlined"
                          size="small"
                          onClick={onClickIngestSourceFile}>
                          Ingest SourceFile
                          {isIngesting && (
                            <>
                              &nbsp;
                              <DesignSuite2023.LoadingSpinner size={15} />
                            </>
                          )}
                        </Button>
                      </span>
                    </DesignSuite2023.Tooltip>
                  </>
                )}
                &nbsp;&nbsp;
                {canProcess && (
                  <span>
                    <DesignSuite2023.Tooltip title='Once a sourceFile is ingested, it generates source records, but still needs to be "processed" to turn into ZClaims.'>
                      <Button
                        disabled={isProcessing}
                        variant="outlined"
                        size="small"
                        onClick={onClickProcessSources}>
                        Process Sources
                        {isProcessing && (
                          <>
                            &nbsp;&nbsp;
                            <DesignSuite2023.LoadingSpinner size={15} />
                          </>
                        )}
                      </Button>
                    </DesignSuite2023.Tooltip>
                  </span>
                )}
                &nbsp;&nbsp;
              </>
            )}
            <DesignSuite2023.Tooltip title="Scan for new sourceFiles (837 files)">
              <Button variant="outlined" size="small" onClick={onClickScanNew}>
                (Re)scan Files
              </Button>
            </DesignSuite2023.Tooltip>
          </>
        }
        DataTableProps={{
          LeftFooterItems: (
            <>
              {canEngeeringOnly && !!selectedSourceFiles.length && (
                <>
                  &nbsp;&nbsp;
                  <small>
                    <strong>DevTools:</strong>
                  </small>
                  &nbsp;&nbsp;
                  {canProcess && (
                    <DesignSuite2023.Tooltip title="Rollback this sourceFile (remove all associated records, and set sourceFile back to original uningested state)">
                      <IconButton size="small" onClick={onClickRollback}>
                        <IconUndo fontSize="small" />
                      </IconButton>
                    </DesignSuite2023.Tooltip>
                  )}
                  {selectedSourceFiles.length === 1 && (
                    <DesignSuite2023.Tooltip title="Dry-run parser on file (no data changes committed)">
                      <IconButton size="small" onClick={onClickTestParse}>
                        <IconDoWork fontSize="small" />
                      </IconButton>
                    </DesignSuite2023.Tooltip>
                  )}
                  <DesignSuite2023.Tooltip title="Hide selected files to prevent accidental ingest">
                    <IconButton size="small" onClick={onClickHideFiles}>
                      <DesignSuite2023.CommonIcons.IconHide />
                    </IconButton>
                  </DesignSuite2023.Tooltip>
                </>
              )}
            </>
          ),
        }}
      />

      <DesignSuite2023.Drawer
        width="50vw"
        open={!!displayTestParseError}
        onClose={() => setDisplayTestParseError(null)}>
        {displayTestParseError}
      </DesignSuite2023.Drawer>
    </StyledWorkflow>
  )
}
