import React, { ReactElement } from 'react'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import ConfirmDialog from '../../../components/ConfirmDialog'
import { bulkDeleteEmployerMarketingActivity } from '../../../actions/EmployerActions'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import { Button } from '@material-ui/core'
import styled from 'styled-components'

const ButtonContainer = styled.div`
  margin-right: 10px;
`

interface deleteButtonProps {
  employerID: number
  selectedActivities: any[]
  onSuccess: () => void
}

export default function ButtonDeleteMarketingActivity({
  employerID,
  selectedActivities,
  onSuccess,
}: deleteButtonProps): ReactElement {
  const { showDurationShort: showSnackbarDuration } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  function handleDeleteActivities() {
    if (!selectedActivities.length) return
    ConfirmDialog({
      content: `Do you really want to delete the selected Activities?`,
      onConfirm() {
        const activityIDs = selectedActivities.map((activity) => activity.ID)
        bulkDeleteEmployerMarketingActivity(employerID, {
          ActivityIDs: activityIDs,
        })
          .then(() => {
            showSnackbarDuration('Activities Deleted!', SnackbarTypeSuccess)
            onSuccess()
          })
          .catch(
            catchAPIError({ defaultMessage: 'Failed to delete activities' })
          )
      },
    })
  }

  return (
    <ButtonContainer>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleDeleteActivities}>
        Delete Activities
      </Button>
    </ButtonContainer>
  )
}
