import util from './utils'
import { CostKeyActions, CLEAR_SAVE_RESULT } from '../actions'
const {
  SAVE_NEW_COST_KEY_CODE,
  SAVE_COST_KEY_CODE,
  GET_COST_KEY_CODES,
  GET_ALL_COST_KEY_KEYWORDS,
  GET_PRICES_FOR_COST_KEY,
  GET_COST_KEY_CODE,
  DELETE_COST_KEY_CODE,
  GET_COST_KEY_CATEGORIES,
} = CostKeyActions

export default function (state = null, action) {
  switch (action.type) {
    case GET_COST_KEY_CODES:
      if (action.payload.error) {
        return {
          ...state,
          getCodesError: util.errorResponse(action),
        }
      }
      return {
        ...state,
        codes: action.payload.Data,
        count: action.payload.Meta.Total,
        getCodesError: null,
      }
    case GET_COST_KEY_CODE:
      if (action.payload.error) {
        return {
          ...state,
          getCodeError: util.errorResponse(action),
        }
      }
      return {
        ...state,
        costKey: action.payload.Data,
        getCodeError: null,
      }
    case GET_COST_KEY_CATEGORIES:
      if (action.payload.error) {
        return {
          ...state,
          getCostKeyCategoriesError: util.errorResponse(action),
        }
      }
      return {
        ...state,
        costKeyCategories: action.payload.Data,
        getCostKeyCategoriesError: null,
      }
    case GET_ALL_COST_KEY_KEYWORDS:
      if (action.payload.error) {
        return {
          ...state,
          getAllError: util.errorResponse(action),
        }
      }
      return {
        ...state,
        keywords: action.payload.Data,
        getAllError: null,
      }
    case GET_PRICES_FOR_COST_KEY:
      if (action.payload.error) {
        return {
          ...state,
          getPricesError: util.errorResponse(action),
        }
      }
      return {
        ...state,
        prices: action.payload.Data,
        getPricesError: null,
      }
    case SAVE_NEW_COST_KEY_CODE:
    case DELETE_COST_KEY_CODE:
    case SAVE_COST_KEY_CODE:
      if (action.payload.error) {
        return {
          ...state,
          saveResult: util.errorResponse(action),
        }
      }
      return {
        ...state,
        costKey: action.payload.Data,
        saveResult: {
          saved: true,
          type: action.type,
        },
      }
    case CLEAR_SAVE_RESULT:
      return {
        ...state,
        saveResult: null,
      }
    default:
      return {
        ...state,
      }
  }
}
