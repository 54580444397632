import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Cancel as IconCancel,
  Edit as IconEdit,
  FirstPage as IconFirstPage,
  InsertDriveFile as InsertDriveFileIcon,
  KeyboardArrowLeft as IconKeyboardArrowLeft,
  KeyboardArrowRight as IconKeyboardArrowRight,
  LastPage as IconLastPage,
  List as IconList,
  Save as IconSave,
} from '@material-ui/icons'
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@material-ui/core'
import isArray from 'lodash/isArray'
import isFunction from 'lodash/isFunction'
import isEmpty from 'lodash/isEmpty'
import utils from '../../utils'
import omit from 'lodash/omit'
import papa from 'papaparse'

const ActionCell = (props) => {
  const [anchorEl, setAnchorEl] = React.useState()
  const close = () => setAnchorEl(null)
  const open = (evt) => setAnchorEl(evt.currentTarget)
  const { row, rowActions } = props

  if (rowActions.length > 0) {
    return (
      <TableCell padding="checkbox">
        <IconButton
          onClick={(evt) => {
            evt.stopPropagation()
            open(evt)
          }}>
          <IconList fontSize="inherit" />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={close}>
          {rowActions.map(({ name, action }) => (
            <MenuItem
              onClick={(evt) => {
                evt.stopPropagation()
                close()
                action(row)
              }}>
              {name}
            </MenuItem>
          ))}
        </Menu>
      </TableCell>
    )
  }

  return null
}

class EnhancedTableHead extends React.Component {
  render() {
    const {
      columns,
      sortable,
      isCheckable,
      hasActions,
      canEdit,
      toggleCheckAll,
      checkAllIsChecked,
      sortHandler,
    } = this.props
    const filteredCols = Object.keys(columns).filter((col) => {
      const { details } = columns[col]
      if (details && details.hidden) return false
      return true
    })

    return (
      <TableHead>
        <TableRow>
          {isCheckable ? (
            <TableCell padding="checkbox">
              <Checkbox
                onChange={(ev) => toggleCheckAll(ev)}
                checked={checkAllIsChecked}
              />
            </TableCell>
          ) : null}
          {hasActions ? (
            <TableCell padding="checkbox">Actions</TableCell>
          ) : null}
          {canEdit ? (
            <TableCell padding="checkbox" style={{ width: 92 }}>
              Edit
            </TableCell>
          ) : null}

          {filteredCols.map((col, i) => {
            const { name, details } = columns[col]
            const colSpan = details && details.colSpan ? details.colSpan : 1
            const sortEnabled = !!(details && details.sortName)

            // Sorting is NOT enabled
            if (!sortEnabled || !sortHandler) {
              return (
                <TableCell
                  key={i}
                  align="left"
                  padding="default"
                  colSpan={colSpan}>
                  {name}
                </TableCell>
              )
            }

            // Sorting IS enabled
            return (
              <TableCell
                key={i}
                align="left"
                padding="default"
                colSpan={colSpan}
                sortDirection={sortable.dir ? sortable.dir : false}>
                <TableSortLabel
                  active={sortable.col === details.sortName}
                  direction={sortable.dir ? sortable.dir : 'asc'}
                  onClick={() =>
                    sortHandler({
                      col: details.sortName,
                      dir: sortable.dir === 'asc' ? 'desc' : 'asc',
                    })
                  }>
                  {name}
                </TableSortLabel>
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
    )
  }
}

EnhancedTableHead.propTypes = {
  rowCount: PropTypes.number.isRequired,
  sortable: PropTypes.object,
  sortHandler: PropTypes.func,
  isCheckable: PropTypes.bool.isRequired,
  toggleCheckAll: PropTypes.func.isRequired,
  checkAllIsChecked: PropTypes.bool.isRequired,
}

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    // minWidth: 1020
  },
  container: {
    maxHeight: '65vh',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
})

class DataTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialized: false,
      page: 1,
      pageSize: props.initPageSize || 10,
      isCheckable: isFunction(props.checkHandler),
      editingRows: {},
      checkedItems: {},
      checkAllIsChecked: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { initPage, initPageSize } = nextProps
    const { initialized } = this.state
    if (!initialized && initPage && initPageSize) {
      this.setState({
        page: initPage,
        pageSize: initPageSize,
        initialized: true,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.data === prevProps.data) {
      return true
    }

    this.setState({
      checkedItems: {},
      editingRows: {},
      checkAllIsChecked: false,
    })
    this.uncheckAllRows()
  }

  /*
    This is a legacy complication of trying to manage the pagination state internally
    vs letting it be passed in. In recent table implementations, the pagination data 
    is accurately tracked by the caller (and thats where we want it to live anyways, 
    since the caller is in charge of issuing API calls as well). But this DataTable 
    component also tracks page state internally for the pagination footer (makes sense),
    but its easy to get caller's pagination state and internal pagination state out of
    sync. HENCE - this is a hack to let callers invoke this method whenever necessary,
    and it'll update the internal state to  match. This has to be done by passing a ref. 
    Search for the method name to find where/how this is used.
  */
  forceSetPagination = ({ page, pageSize }) => {
    this.setState({ page, pageSize })
  }

  handleChangePage = (event, page) => {
    const { pageSize } = this.state
    const nonIndexPage = page + 1
    this.setState({
      page: nonIndexPage,
      editingRows: {},
      checkAllIsChecked: false,
    })
    this.props.onChangePage({ page: nonIndexPage, pageSize })
    this.uncheckAllRows()
  }

  handleChangeRowsPerPage = (event) => {
    const { page } = this.state
    const pageSize = event.target.value
    this.setState({ pageSize })
    this.props.onChangeRowsPerPage({ pageSize, page, checkAllIsChecked: false })
    this.uncheckAllRows()
  }

  renderNoResults = () => {
    return (
      <TableRow>
        <TableCell
          variant="footer"
          colSpan={(Object.keys(this.props?.columns) || []).length + 1 || 10}>
          No Results
        </TableCell>
      </TableRow>
    )
  }

  _getRowKey = (row) => {
    // To avoid display bugs - default to making the rowKey a random number if
    // keyProp isn't set
    if (!this.props.keyProp) {
      return `${Math.round(Math.random() * 1000000000)}`
    }
    // Sometimes need compound properties to form a unique key; so keyProp
    // *can* be an array
    if (isArray(this.props.keyProp)) {
      return this.props.keyProp
        .map((k) => {
          return row[k]
        })
        .join('_')
    }
    // Normally its just a string mapping to a rowColumn though...
    return row[this.props.keyProp]
  }

  _handleToggleRowCheck = (key, row) => {
    if (this.state.checkedItems[key]) {
      const v = { checkedItems: omit(this.state.checkedItems, key) }
      this.setState(v)
      this.handleCheckboxCheck(v.checkedItems)
      return
    }
    const v = { checkedItems: { ...this.state.checkedItems, [key]: row } }
    this.setState(v)
    this.handleCheckboxCheck(v.checkedItems)
  }

  _toggleCheckAll = (ev) => {
    const isChecked = ev.target.checked
    this.setState({ checkAllIsChecked: isChecked })

    if (isChecked) {
      const all = {}
      this.props.data.forEach((row) => {
        const include = this.props.isRowCheckable
          ? this.props.isRowCheckable(row)
          : true
        if (include) {
          all[this._getRowKey(row)] = row
        }
      })
      this.setState({ checkedItems: all })
      this.handleCheckboxCheck(all)
      return
    }

    this.uncheckAllRows()
  }

  handleCheckboxCheck = (rowsByObjKey) => {
    this.props.checkHandler(Object.values(rowsByObjKey))
  }

  // When an event occurs that should cause the table to deselect all currently
  // checked rows (ie. changing a page, a search, etc), this will update the state
  // AND propagate that to the checkHandler prop (which is either a no-op, or
  // something the parent component passes in, expecting to receive the list of
  // checked rows every time it changes)
  uncheckAllRows = () => {
    this.setState({ checkedItems: {} })
    isFunction(this.props.checkHandler) && this.props.checkHandler([])
  }

  handleEditCell = (rowKey, col, valProperty) => {
    return (e) => {
      const { editingRows } = this.state

      this.setState({
        editingRows: {
          ...editingRows,
          [rowKey]: {
            ...editingRows[rowKey],
            [col]: valProperty ? e.target[valProperty] : e.target.value,
          },
        },
      })
    }
  }

  toggleEdit = (rowKey, row) => {
    return () => {
      const { onEditRow } = this.props

      this.setState({
        editingRows: {
          ...this.state.editingRows,
          [rowKey]: row,
        },
      })
      onEditRow(row, rowKey)
    }
  }

  onCancelEdit = (rowKey, row) => {
    return () => {
      this.setState({
        editingRows: {
          ...this.state.editingRows,
          [rowKey]: undefined,
        },
      })
    }
  }

  onSaveRow = (rowKey) => {
    return () => {
      const { editingRows } = this.state
      const { onSaveRow } = this.props

      const rowToSave = editingRows[rowKey]
      onSaveRow(rowToSave, rowKey)
        .then((res) => {
          // with a good save result, we will remove it from the list of rows being edited
          this.setState({
            editingRows: {
              ...editingRows,
              [rowKey]: undefined,
            },
          })
        })
        .catch((err) => {
          console.log('we tried to save, but failed broh')
        })
    }
  }

  renderRowEditSave = (rowKey, row, editing) => {
    const { onEditRow, allowEditing, handleCustomEdit, isRowEditable } =
      this.props

    if (isFunction(onEditRow) && allowEditing) {
      if (!isRowEditable(row)) return <TableCell padding="checkbox" />
      if (!editing) {
        return (
          <TableCell padding="checkbox">
            <IconButton
              onClick={
                handleCustomEdit
                  ? () => handleCustomEdit(row)
                  : this.toggleEdit(rowKey, row)
              }>
              <IconEdit fontSize="small" />
            </IconButton>
          </TableCell>
        )
      } else {
        return (
          <TableCell padding="checkbox">
            <IconButton onClick={this.onCancelEdit(rowKey)}>
              <IconCancel fontSize="small" />
            </IconButton>
            <IconButton onClick={this.onSaveRow(rowKey)}>
              <IconSave fontSize="small" />
            </IconButton>
          </TableCell>
        )
      }
    }

    return null
  }

  renderRowSavable = (rowKey) => {
    const { onSave } = this.props

    if (isFunction(onSave)) {
      return (
        <TableCell padding="checkbox">
          <IconButton onClick={this.onSaveRow(rowKey)}>
            <IconSave fontSize="inherit" />
          </IconButton>
        </TableCell>
      )
    }

    return null
  }

  renderEditCell = ({ row, details, rowKey, col, index }) => {
    const value = row[col]
    if (!details) {
      return (
        <TableCell key={index} padding="default" align="left">
          {value}
        </TableCell>
      )
    }

    const { editable, EditComponent, valProperty, editComponentOpts } = details
    if (editable && EditComponent !== undefined) {
      const opts = editComponentOpts || {}
      const valProp = valProperty || 'value'
      const valProps = {
        [valProp]: value,
      }
      return (
        <TableCell key={index} padding="default" align="left">
          <EditComponent
            {...opts}
            {...valProps}
            key={index}
            onChange={this.handleEditCell(rowKey, col, valProperty)}
          />
        </TableCell>
      )
    }

    return this.renderCell({ details, row, col, index })
  }

  generateCopyLink = (content) => {
    const { updateClipboard } = utils
    const cb = () => {}
    const err = () => {}

    return (
      <IconButton
        style={{ float: 'right' }}
        size="small"
        name="view-link"
        onClick={() => {
          updateClipboard(content, cb, err)
        }}>
        <InsertDriveFileIcon style={{ fontSize: 3 }} />
      </IconButton>
    )
  }

  renderCell = ({ details, row, col, index }) => {
    if (!details) {
      const content = row[col]
      return (
        <TableCell key={index} padding="default" align="left">
          {content}
        </TableCell>
      )
    }

    // notice: detailsNoFormat contains all of the 'details' properties from the model definition - *except* dataFormat AND
    // sortName, because they're extracted in the destructuring syntax used below. All the detailsNoFormat properties will be spread
    // onto the <TableCell />. We're pulling out sortName because the <TableCell/> component will throw a warning about an unknown
    // prop if we pass it in; so sortName is purposefully unused anywhere.
    const {
      dataFormat,
      sortName,
      copy,
      editable,
      EditComponent,
      ...detailsNoFormat
    } = details
    const content = dataFormat ? dataFormat(row[col], row) : row[col]
    const copyBtn = copy ? this.generateCopyLink(row[col]) : ''
    return (
      <TableCell
        key={index}
        padding="default"
        align="left"
        {...detailsNoFormat}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
          {content}
          {copyBtn}
        </div>
      </TableCell>
    )
  }

  _onRowClick = (event, row) => {
    const { target } = event
    const cmdClicked = event.metaKey

    // no click event if target is a link or checkbox
    if (target && (target.type === 'checkbox' || target.href)) return

    return this.props.onRowClick(event, row, cmdClicked)
  }

  renderResultRows = () => {
    const { isCheckable, checkedItems } = this.state
    const { data, columns, rowOptsApplier, isRowCheckable } = this.props
    const { _getRowKey, _handleToggleRowCheck } = this

    if (!data || data.length === 0) {
      return this.renderNoResults()
    }

    return data.map((row, i) => {
      const { editingRows } = this.state
      let rowOpts = {}
      if (rowOptsApplier && rowOptsApplier(row) !== null) {
        rowOpts = rowOptsApplier(row)
      }
      const rowKey = _getRowKey(row)
      const editing = editingRows[rowKey] !== undefined
      let canCheck = true
      if (!!isRowCheckable) canCheck = isRowCheckable(row)

      return (
        <TableRow
          {...rowOpts}
          hover
          tabIndex={-1}
          onClick={(event) => this._onRowClick(event, row)}
          key={rowKey}>
          {isCheckable ? (
            <TableCell padding="checkbox">
              <Checkbox
                disabled={!canCheck}
                checked={!!checkedItems[rowKey]}
                onChange={() => _handleToggleRowCheck(rowKey, row)}
              />
            </TableCell>
          ) : null}
          <ActionCell row={row} rowActions={this.props.rowActions} />
          {this.renderRowEditSave(rowKey, row, editing)}
          {Object.keys(columns).map((col, ix) => {
            const { details } = columns[col]
            if (details && details.hidden) return false
            if (editing)
              return this.renderEditCell({
                row: editingRows[rowKey],
                details,
                rowKey,
                col,
                index: ix,
              })
            return this.renderCell({ details, row, col, index: ix })
          })}
        </TableRow>
      )
    })
  }

  renderError = () => {
    return (
      <TableRow>
        <TableCell variant="footer">
          {this.props.getError}. If you need help, please report this to
          support@zero.health
        </TableCell>
      </TableRow>
    )
  }

  render() {
    const {
      classes,
      className,
      data,
      customToolbar,
      columns,
      error,
      loading,
      count,
      pagination,
      csvExportable,
      rowsPerPage,
      rowActions,
      onEditRow,
      allowEditing,
      LeftFooterItems,
      stickyHeader,
      density,
    } = this.props
    const { pageSize, page } = this.state
    let pageIndex = page - 1

    // make sure we dont end up on a page that wont have any data
    if (count && count - page * pageSize <= pageSize * -1) {
      if (page !== 1) {
        this.handleChangePage({}, 0)
        pageIndex = 0
      }
    }
    // if there are no records, and page is a non-zero, set the pageIndex to 0 (for now)
    // to prevent the DataTable from freaking out w/ an invalid prop. NOTE: since this component
    // will (probably) be re-rendering many times, setting pageIndex in this way shouldn't occur
    // once the *expected* set of values (props) are passed in... but during initialization - its easy
    // for this to get messed up. If you want to see the problem, nav to any page after the first, then
    // refresh the page.
    if (count === 0 && page >= 1) {
      pageIndex = 0
    }

    // issue warning if data is a falsey value
    if (!data)
      console.warn(
        'Expected table results to look like [], received falsey value instead'
      )

    return (
      <Paper
        className={classes.root + ` ${className}`}
        style={{ position: 'relative' }}
        elevation={4}>
        {customToolbar}
        <div
          id={this.props.id}
          className={`datatable-common-wrapper ${classes.tableWrapper}`}>
          {loading ? <LinearProgress /> : <div />}
          <TableContainer className={stickyHeader ? classes.container : null}>
            <Table
              size={density}
              className={classes.table}
              aria-labelledby="tableTitle"
              stickyHeader={stickyHeader}>
              <EnhancedTableHead
                sortable={this.props.sortable}
                sortHandler={this.props.sortHandler}
                rowCount={data ? data.length : 0}
                columns={columns}
                canEdit={isFunction(onEditRow) && allowEditing}
                hasActions={!isEmpty(rowActions)}
                isCheckable={this.state.isCheckable}
                toggleCheckAll={this._toggleCheckAll}
                checkAllIsChecked={this.state.checkAllIsChecked}
              />
              <TableBody>
                {error !== null ? this.renderError() : this.renderResultRows()}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center">
          <Grid item xs={12} md="auto">
            {!!LeftFooterItems && (
              <div style={{ padding: '1rem' }}>{LeftFooterItems}</div>
            )}
          </Grid>
          <Grid item xs={12} md="auto">
            {pagination && (
              <TablePagination
                ActionsComponent={CustomPaginationActions}
                rowsPerPageOptions={rowsPerPage}
                component="div"
                count={count}
                rowsPerPage={pageSize}
                page={pageIndex}
                backIconButtonProps={{
                  'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'Next Page',
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            )}
          </Grid>
        </Grid>
        {csvExportable && (
          <Grid container>
            <Grid item xs={12}>
              <Button
                onClick={this.exportCSV}
                variant="outlined"
                color="primary"
                style={{ margin: '1rem' }}>
                Export CSV
              </Button>
            </Grid>
          </Grid>
        )}
      </Paper>
    )
  }

  formatData = (data) => {
    const { columns } = this.props

    return data.map((row) => {
      return Object.keys(row).map((key) => {
        if (columns[key].details && columns[key].details.dataFormat) {
          return columns[key].details.dataFormat(row, row)
        }

        return row[key]
      })
    })
  }

  exportCSV = (ev) => {
    const formatted = this.formatData(this.props.data)
    formatted.unshift(Object.keys(this.props.columns))

    const asCSV = papa.unparse(formatted)
    const b = new Blob([asCSV], {
      type: 'text/csv;charset=utf-8',
    })

    const dlURL = URL.createObjectURL(b)
    const a = document.createElement('a')
    a.href = dlURL
    a.download = this.props.csvExportableFilename
    a.click()
    a.remove()
  }
}

DataTable.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  error: PropTypes.object,
  columns: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  onRowClick: PropTypes.func.isRequired,
  rowOptsApplier: PropTypes.func,
  sortable: PropTypes.object.isRequired,
  sortHandler: PropTypes.func,
  pagination: PropTypes.bool,
  csvExportable: PropTypes.bool,
  csvExportableFilename: PropTypes.string,
  rowsPerPage: PropTypes.array,
  checkHandler: PropTypes.func,
  rowActions: PropTypes.arrayOf(PropTypes.object),
  handleCustomEdit: PropTypes.func,
  onEditRow: PropTypes.func,
  onSaveRow: PropTypes.func,
  isRowEditable: PropTypes.func,
  isRowCheckable: PropTypes.func,
  keyProp: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  loading: PropTypes.bool,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  count: PropTypes.number,
  stickyHeader: PropTypes.bool,
  density: PropTypes.string,
}

DataTable.defaultProps = {
  className: '',
  onRowClick: () => {},
  isRowEditable: () => true,
  sortable: { col: null, dir: null },
  initPage: 1,
  initPageSize: 10,
  pagination: true,
  csvExportable: false,
  csvExportableFilename: 'export.csv',
  rowsPerPage: [5, 10, 25],
  error: null,
  checkHandler: null,
  rowActions: [],
  allowEditing: true,
  keyProp: null,
  loading: false,
  onChangePage() {
    /* no-op */
  },
  onChangeRowsPerPage() {
    /* no-op */
  },
  stickyHeader: false,
  density: 'medium',
}

export default withStyles(styles)(DataTable)

// https://v4.mui.com/components/tables/#custom-pagination-options
function CustomPaginationActions(props /*: TablePaginationActionsProps*/) {
  const { count, page, rowsPerPage, onChangePage } = props

  function onFirstPageClick(ev) {
    onChangePage(ev, 0)
  }

  function onBackPageClick(ev) {
    onChangePage(ev, Math.max(page - 1, 0))
  }

  function onFwdPageClick(ev) {
    onChangePage(ev, page + 1)
  }

  function onLastPageClick(ev) {
    onChangePage(ev, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div style={{ display: 'inline-flex', margin: '0 1rem' }}>
      <IconButton
        size="small"
        aria-label="First page"
        onClick={onFirstPageClick}
        disabled={page === 0}>
        <IconFirstPage />
      </IconButton>
      <IconButton
        size="small"
        aria-label="Previous page"
        onClick={onBackPageClick}>
        <IconKeyboardArrowLeft />
      </IconButton>
      <IconButton
        size="small"
        aria-label="Next page"
        onClick={onFwdPageClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        <IconKeyboardArrowRight />
      </IconButton>
      <IconButton
        size="small"
        aria-label="Last page"
        onClick={onLastPageClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        <IconLastPage />
      </IconButton>
    </div>
  )
}
