import util from './utils'
import utils from '../utils'
import Models from '../models'
import dateTime from '../utils/dateTime'
import { EmployerActions, CLEAR_SAVE_RESULT } from '../actions'
const {
  GET_ELIGIBILITY_FILE_CONFIGS,
  GET_ELIGIBILITY_FILE_CONFIG,
  GET_NEW_ELIGIBILITY_FILE_CONFIG,
  POST_ELIGIBILITY_FILE_CONFIG,
  PUT_ELIGIBILITY_FILE_CONFIG,
  GET_EMPLOYERS,
  GET_NEW_EMPLOYER,
  GET_EMPLOYER_BY_ID,
  SAVE_NEW_EMPLOYER,
  GET_PLAN_EXCLUSIONS,
  GET_FILES_FOR_EMPLOYER,
  DELETE_EMPLOYER,
  GET_EMPLOYER_CONTACT_TYPES,
  GET_TEAM_ZERO_USERS,
  MANAGE_TEAM_ZERO_USERS,
  MANAGE_TEAM_ZERO_ADMIN_USERS,
  GET_EMPLOYER_BILLING_CONFIG,
  SAVE_EMPLOYER_BILLING_CONFIG,
  UPDATE_EMPLOYER_CORE,
  UPDATE_EMPLOYER_DETAIL,
} = EmployerActions
const { transform } = Models.Employer

export default function (state = null, action) {
  switch (action.type) {
    case GET_ELIGIBILITY_FILE_CONFIGS: {
      if (action.payload.error) {
        return {
          ...state,
          getEligibilityFileConfigsError: util.errorResponse(action),
        }
      }

      return {
        ...state,
        eligibilityFileConfigs: action.payload.Data,
        getEligibilityFileConfigsError: null,
      }
    }
    case GET_NEW_ELIGIBILITY_FILE_CONFIG: {
      return {
        ...state,
        eligSaveResult: null,
        eligibilityFileConfigDetail: action.payload,
        getEligibilityFileConfigError: null,
      }
    }
    case GET_ELIGIBILITY_FILE_CONFIG: {
      if (action.payload.error) {
        return {
          ...state,
          getEligibilityFileConfigError: util.errorResponse(action),
        }
      }

      return {
        ...state,
        getEligibilityFileConfigError: null,
        eligSaveResult: null,
        eligibilityFileConfigDetail: action.payload.Data,
      }
    }

    case POST_ELIGIBILITY_FILE_CONFIG: {
      if (action.payload.error) {
        return {
          ...state,
          eligSaveResult: util.errorResponse(action),
        }
      }
      return {
        ...state,
        eligibilityFileConfigDetail: action.payload.Data,
        eligSaveResult: {
          saved: true,
          type: action.type,
        },
      }
    }

    case CLEAR_SAVE_RESULT: {
      return {
        ...state,
        saveResult: null,
        getError: null,
        eligSaveResult: null,
      }
    }

    case PUT_ELIGIBILITY_FILE_CONFIG: {
      if (action.payload.error) {
        return {
          ...state,
          eligSaveResult: util.errorResponse(action),
        }
      }
      return {
        ...state,
        eligibilityFileConfigDetail: action.payload.Data,
        eligSaveResult: {
          saved: true,
          type: action.type,
        },
      }
    }

    case GET_EMPLOYERS: {
      if (action.payload.error) {
        return {
          ...state,
          employers: [],
          count: 0,
          getError: util.errorResponse(action),
        }
      }

      return {
        ...state,
        getError: null,
        employers: action.payload.Data,
        count: action.payload.Meta.Total,
      }
    }
    case GET_PLAN_EXCLUSIONS: {
      if (action.payload.error) {
        return {
          ...state,
          getPlanExclusionsError: util.errorResponse(action),
        }
      }

      return {
        ...state,
        getPlanExclusionsError: null,
        exclusions: action.payload.Data.reduce((coll, cur) => {
          coll[cur.ID] = cur
          return coll
        }, {}),
      }
    }
    case GET_TEAM_ZERO_USERS: {
      if (action.payload.error) {
        return {
          ...state,
          getTeamZeroUsersError: util.errorResponse(action),
        }
      }

      return {
        ...state,
        teamZeroUsers: action.payload.Data,
      }
    }
    case MANAGE_TEAM_ZERO_USERS:
    case MANAGE_TEAM_ZERO_ADMIN_USERS: {
      if (action.payload.error) {
        return {
          ...state,
          manageTeamZeroUsersError: util.errorResponse(action),
        }
      }

      return {
        ...state,
      }
    }
    case GET_EMPLOYER_CONTACT_TYPES: {
      if (action.payload.error) {
        return {
          ...state,
          getContactTypesError: util.errorResponse(action),
        }
      }

      return {
        ...state,
        contactTypes: action.payload.Data,
      }
    }
    case GET_FILES_FOR_EMPLOYER: {
      if (action.payload.error) {
        return {
          ...state,
          getFilesError: util.errorResponse(action),
        }
      }

      return {
        ...state,
        getFilesError: null,
        files: util.indexFileTypes(action.payload.Data),
      }
    }
    case GET_NEW_EMPLOYER: {
      if (action.payload.error) {
        return {
          ...state,
          getError: util.errorResponse(action),
        }
      }

      return {
        ...state,
        employer: {},
        invoices: [],
        files: [],
        contacts: [],
        teamZeroUsers: [],
        getError: null,
      }
    }
    case GET_EMPLOYER_BY_ID: {
      if (action.payload.error) {
        return {
          ...state,
          getError: util.errorResponse(action),
        }
      }

      return {
        ...state,
        getError: null,
        employer: transform(action.payload.Data),
      }
    }
    case UPDATE_EMPLOYER_DETAIL:
      return {
        ...state,
        employerDetail: action.payload.Data,
      }
    case UPDATE_EMPLOYER_CORE:
      return {
        ...state,
        employerCore: action.payload.Data,
      }
    case SAVE_NEW_EMPLOYER:
    case DELETE_EMPLOYER:
      if (action.payload.error) {
        return {
          ...state,
          saveResult: util.errorResponse(action),
        }
      }

      return {
        ...state,
        employer: action.payload.Data,
        saveResult: {
          saved: true,
          type: action.type,
        },
      }
    case SAVE_EMPLOYER_BILLING_CONFIG:
      if (action.payload.error) {
        return {
          ...state,
          saveResult: util.errorResponse(action),
        }
      }

      return {
        ...state,
        billingConfig: transformBilling(action.payload.Data),
        saveResult: {
          saved: true,
          type: action.type,
        },
      }
    case GET_EMPLOYER_BILLING_CONFIG: {
      if (action.payload.error) {
        return {
          ...state,
          getError: util.errorResponse(action),
        }
      }

      return {
        ...state,
        billingConfig: transformBilling(action.payload.Data),
      }
    }
    default:
      return {
        ...state,
      }
  }
}

function transformBilling(data) {
  if (data) {
    const transformedRateChangeReminder = dateTime
      .parse(data.RateChangeReminder)
      .format()

    return Object.assign({}, data, {
      FractionalRateOther: utils.convertFromRate(data.FractionalRateOther),
      FractionalRateService: utils.convertFromRate(data.FractionalRateService),
      FractionalRateFarley: utils.convertFromRate(data.FractionalRateFarley),
      FractionalRateFeeBasis: utils.convertFromRate(
        data.FractionalRateFeeBasis
      ),
      FractionalRateBroker: utils.convertFromRate(data.FractionalRateBroker),
      FractionalRateSales: utils.convertFromRate(data.FractionalRateSales),
      RateChangeReminder: transformedRateChangeReminder,
    })
  }

  return data
}
