import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Collapse,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItemAvatar,
} from '@material-ui/core'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank'
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Models from '../../models'
import _ from 'lodash'

const styles = (theme) => {
  return {
    selected: {
      backgroundColor: 'green',
    },
    current: {
      backgroundColor: 'green',
    },
    pendingRemoval: {
      backgroundColor: 'red',
    },
    manageWindow: {
      minHeight: 485,
      minWidth: 600,
    },
    item: {
      cursor: 'pointer',
    },
  }
}

class bundlerManager extends React.Component {
  state = {
    bundlerSearchResults: [],
    selectedBundlers: [],
    bundlersPendingRemoval: [],
    currentListOpen: true,
    searchParams: {
      q: '',
    },
  }

  doFindBundlers = ({ q, pageSize, page }) => {
    /* TODO: when we hook up real bundlers, replace using this line
    if (q.length === 0 || q.length > 2) {
      return search('/bundler', { filter: { q }, page: 1, pageSize: 15 })
    }
    */

    return new Promise((resolve, reject) => {
      resolve({ Data: [Models.Bundler.ZEROCARD] })
    })
  }

  UNSAFE_componentWillMount() {
    this.handleBundlersQuery('')
  }

  /* TODO: This will handle the query change when we hookup bundlers */
  handleBundlersQuery = (q) => {
    this.doFindBundlers({ q })
      .then((res) => {
        console.debug('Retrieving bundlers: ', res)
        this.setState({ bundlerSearchResults: res.Data })
      })
      .catch((err) => console.error('failed fetching bundlers', err))
  }

  toggleSelected = (ID, bundler) => {
    return () => {
      const { selectedBundlers, bundlersPendingRemoval } = this.state

      const selected = _.find(selectedBundlers, { ID })
      if (!selected) {
        selectedBundlers.push(bundler)
      } else {
        // mutates the existing group
        _.remove(selectedBundlers, { ID })
      }
      this.setState({ selectedBundlers })
      this.props.onUpdate({ selectedBundlers, bundlersPendingRemoval })
      console.debug(
        'Selecting a bundler: ',
        ID,
        bundler,
        ', currently selected: ',
        selectedBundlers
      )
    }
  }

  toggleCurrent = (ID, bundler) => {
    return () => {
      const { bundlersPendingRemoval, selectedBundlers } = this.state

      const pendingRemoval = _.find(bundlersPendingRemoval, { ID })

      if (!pendingRemoval) {
        bundlersPendingRemoval.push(bundler)
      } else {
        _.remove(bundlersPendingRemoval, { ID })
      }
      this.setState({ bundlersPendingRemoval })
      this.props.onUpdate({ selectedBundlers, bundlersPendingRemoval })
      console.debug(
        'Toggling a current bundler: ',
        ID,
        bundler,
        ', all pending removal: ',
        bundlersPendingRemoval
      )
    }
  }

  toggleShowCurrent = () => {
    const { currentListOpen } = this.state
    this.setState({ currentListOpen: !currentListOpen })
  }

  renderCurrentListItem = (bundler, avatarClass) => {
    const { classes } = this.props

    return (
      <ListItemAvatar key={bundler.ID}>
        <Avatar className={avatarClass}>
          <RadioButtonChecked className={classes.selected} />
        </Avatar>
        <ListItemText
          primary={bundler.Name}
          secondary={
            'This Bundler is currently active. Manage using "Current Bundlers"'
          }
        />
      </ListItemAvatar>
    )
  }

  renderResults = () => {
    const { bundlerSearchResults, selectedBundlers } = this.state
    const { classes, currentBundlers } = this.props

    return (
      <List>
        {bundlerSearchResults.map((bundler) => {
          const selected =
            _.find(selectedBundlers, { ID: bundler.ID }) !== undefined
          const current =
            _.find(currentBundlers, { ID: bundler.ID }) !== undefined

          const avatarClass = selected
            ? classes.selected
            : current
              ? classes.current
              : classes.notSelected
          if (current) {
            return this.renderCurrentListItem(bundler, avatarClass)
          }
          return (
            <ListItemAvatar
              key={bundler.ID}
              button
              onClick={this.toggleSelected(bundler.ID, bundler)}>
              <Avatar className={avatarClass}>
                {selected ? (
                  <CheckCircleOutline className={classes.selected} />
                ) : (
                  <CheckBoxOutlineBlank className={classes.notSelected} />
                )}
              </Avatar>
              <ListItemText primary={bundler.Name} secondary={bundler.ID} />
            </ListItemAvatar>
          )
        })}
      </List>
    )
  }

  renderSelected = () => {
    const { selectedBundlers, currentListOpen, bundlersPendingRemoval } =
      this.state
    const { classes, currentBundlers } = this.props

    return (
      <div>
        <List>
          <ListItem>
            <ListItemText primary="Bundlers to Add" />
          </ListItem>
          {selectedBundlers.map((bundler) => {
            return (
              <ListItemAvatar
                key={bundler.ID}
                button
                onClick={this.toggleSelected(bundler.ID, bundler)}>
                <Avatar className={classes.selected}>
                  <CheckCircleOutline className={classes.selected} />
                </Avatar>
                <ListItemText primary={bundler.Name} secondary={bundler.ID} />
              </ListItemAvatar>
            )
          })}
        </List>
        {currentBundlers && currentBundlers.length > 0 && (
          <List>
            <ListItem button onClick={this.toggleShowCurrent}>
              <ListItemText primary="Current Bundlers" />
              {currentListOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={currentListOpen} timeout="auto" unmountOnExit>
              {currentBundlers.map((bundler) => {
                const pendingRemoval = _.find(bundlersPendingRemoval, {
                  ID: bundler.ID,
                })

                const avatarClass = pendingRemoval
                  ? classes.pendingRemoval
                  : classes.current
                return (
                  <ListItemAvatar
                    key={bundler.ID}
                    button
                    onClick={this.toggleCurrent(bundler.ID, bundler)}>
                    <Avatar className={avatarClass}>
                      {pendingRemoval ? (
                        <CheckBoxOutlineBlank
                          className={classes.pendingRemoval}
                        />
                      ) : (
                        <CheckCircleOutline className={classes.current} />
                      )}
                    </Avatar>
                    <ListItemText
                      primary={bundler.Name}
                      secondary={bundler.ID}
                    />
                  </ListItemAvatar>
                )
              })}
            </Collapse>
          </List>
        )}
      </div>
    )
  }

  render() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div>{this.renderResults()}</div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div>{this.renderSelected()}</div>
        </Grid>
      </Grid>
    )
  }
}

bundlerManager.propTypes = {
  classes: PropTypes.object.isRequired,
}

export const BundlerManager = withStyles(styles)(bundlerManager)

class bundlerManagerDialog extends React.Component {
  state = {
    added: [],
    removed: [],
  }

  componentWillUnmount() {
    this.setState({
      added: [],
      remove: [],
    })
  }

  handleCancel = () => {
    this.setState({ added: [], removed: [] })
    this.props.onCancel()
  }

  handleConfirm = () => {
    const { added, removed } = this.state
    this.setState({ added: [], removed: [] })
    this.props.onConfirm({ added, removed })
  }

  trackChanges = ({ selectedBundlers, bundlersPendingRemoval }) => {
    const added = selectedBundlers
    const removed = bundlersPendingRemoval
    console.debug(
      'Generating diff of bundlers under management: ',
      'Added: ',
      added,
      ' Removed: ',
      removed
    )

    this.setState({ added, removed })
  }

  hasChanges = () => {
    const { added, removed } = this.state

    return !(added.length === 0 && removed.length === 0)
  }

  renderActionText = () => {
    const { added, removed } = this.state
    let addedText = ''
    let removedText = ''
    let separator = ''

    if (added.length !== 0) {
      addedText = `Add ${added.length} ${added.length > 1 ? 'bundlers' : 'bundler'}`
    }
    if (removed.length !== 0) {
      removedText = `Remove ${removed.length} ${removed.length > 1 ? 'bundlers' : 'bundler'}`
    }
    if (added.length !== 0 && removed.length !== 0) {
      separator = ', '
    }

    return (
      <span>
        {addedText}
        {separator}
        {removedText}
      </span>
    )
  }

  render = () => {
    // open/closed state is managed via the handleCancel/handleConfirm callbacks on the parent component
    const { classes, currentBundlers, open } = this.props
    const hasChanges = this.hasChanges()

    return (
      <div>
        <Dialog
          className={classes.dialog}
          open={open}
          onClose={this.handleCancel}>
          <DialogTitle>Manage Bundlers</DialogTitle>
          <DialogContent className={classes.manageWindow}>
            <DialogContentText>
              Please use this lookup table to manage the bundlers.
            </DialogContentText>
            <br />
            <BundlerManager
              currentBundlers={currentBundlers}
              onUpdate={this.trackChanges}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.handleConfirm}
              disabled={!hasChanges}
              color="primary">
              {hasChanges ? this.renderActionText() : <span>No Changes</span>}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

bundlerManagerDialog.propTypes = {
  currentBundlers: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

bundlerManagerDialog.defaultProps = {
  currentBundlers: [],
}

export const BundlerManagerDialog = withStyles(styles)(bundlerManagerDialog)
