import React, { useState, useEffect } from 'react'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import * as EmployerTable from '../Employers/EmployerTable'
import { putAddNetworkEntities } from '../../actions/NetworkActions'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
} from '@material-ui/core'
import _pick from 'lodash/pick'
import styled from 'styled-components'

const StyledDialogContent = styled(DialogContent)`
  .header-items {
    margin-top: 0 !important;
    h3 {
      margin: 0;
      padding: 0;
    }
  }
`

const customColsEmpTable = _pick(EmployerTable.defaultColumns, [
  'Name',
  'Exclusions',
  'LaunchDate',
  'TerminationDate',
  'DisplayAddress',
])

export interface props {
  networkID: number
  onClose?(): void
}

export default function DialogAddEmployer({
  networkID,
  onClose,
}: props): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState<Array<any>>([])
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  useEffect(() => {
    setSelected([])
  }, [isOpen])

  function doClose() {
    setIsOpen(false)
    onClose && onClose()
  }

  function doAdd() {
    putAddNetworkEntities(networkID, {
      EmployerIDs: selected.map((e: any) => e.ID),
    })
      .then((_: any) => {
        showSnackbar('Added to network OK!', SnackbarTypeSuccess)
      })
      .catch(
        catchAPIError({ defaultMessage: 'Failed adding record to network' })
      )
      .finally(doClose)
  }

  function isDisabled() {
    return (selected || []).length === 0
  }

  if (!networkID) return null
  return (
    <>
      <Tooltip title="(EngineeringOnly): launch bulk-add workflow">
        <Button variant="outlined" onClick={() => setIsOpen(true)}>
          Add Employer(s)
        </Button>
      </Tooltip>

      <Dialog open={isOpen} maxWidth="lg">
        <StyledDialogContent>
          <EmployerTable.Table
            onRowClick={() => {}}
            passFilters={{ isCustomer: true, active: true }}
            customColumns={customColsEmpTable}
            onCheckHandler={setSelected}
            LeftHeaderItems={
              <>
                <h3>Add Employer To Network</h3>
              </>
            }
            RightHeaderItems={
              <>
                <EmployerTable.StandardFilterSearch autoFocus />
              </>
            }
            DataTableProps={{
              LeftFooterItems: (
                <>
                  <small>
                    <strong>
                      Inactive and "prospect" employers are not displayed.
                    </strong>
                  </small>
                </>
              ),
            }}
          />
        </StyledDialogContent>
        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button color="secondary" variant="outlined" onClick={doClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                disabled={isDisabled()}
                color="primary"
                variant="contained"
                onClick={doAdd}>
                Proceed
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}
