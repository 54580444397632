import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { SnackbarActions } from '../actions'

export const SnackbarTypeError = 'error'
export const SnackbarTypeSuccess = 'success'
export const SnackbarTypeWarning = 'warning'

export default function useSnackbar() {
  const dispatch = useDispatch()

  const shortDuration = 3500
  const longDuration = 20000

  const show = useCallback(
    (msg: string | React.ReactNode, type: string) => {
      dispatch(SnackbarActions.setSnackbarMessage(msg, type))
    },
    [dispatch]
  )

  const close = useCallback(() => {
    dispatch(SnackbarActions.clearSnackbar())
    dispatch(SnackbarActions.closeSnackbar())
  }, [dispatch])

  const showForDuration = useCallback(
    (
      msg: string | React.ReactNode,
      type: string,
      duration: number = 8000 /* ms */
    ) => {
      show(msg, type)
      setTimeout(close, duration)
    },
    [show, close]
  )

  const showDurationShort = useCallback(
    (msg: string | React.ReactNode, type: string) => {
      show(msg, type)
      setTimeout(close, shortDuration)
    },
    [show, close]
  )

  const showDurationLong = useCallback(
    (msg: string | React.ReactNode, type: string) => {
      show(msg, type)
      setTimeout(close, longDuration)
    },
    [show, close]
  )

  return {
    show,
    close,
    showForDuration,
    showDurationShort,
    showDurationLong,
  }
}
