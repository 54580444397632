import * as shapes from './types'
import React, { useEffect, useState } from 'react'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import {
  RenderSelectField2,
  SetterArgs,
} from '../../components/Inputs/standard'
import { editEmployerMemberDivision } from '../../actions/MemberActions'
import DesignSuite2023 from '../../components/DesignSuite2023'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import { EmployerActions } from '../../actions'

const { getEmployerDivisions } = EmployerActions

interface props {
  Trigger: any // React.ReactComponent
  empMemRec: shapes.shapeEmployerMember | null
  onSaved?(): void
}

interface Division {
  Name: string
  ID: number
}

export default function DialogSetSubsetHandle({
  Trigger,
  empMemRec,
  onSaved,
}: props): React.ReactElement | null {
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const [isOpen, setIsOpen] = useState(false)
  const [divisions, setDivisions] = useState<Array<Division>>([])
  const [payload, setPayload] = useState<any>({
    DivisionID: empMemRec?.DivisionID || null,
  })

  useEffect(() => {
    if (isOpen && empMemRec?.EmployerId) {
      setPayload({
        DivisionID: empMemRec?.DivisionID,
      })

      getEmployerDivisions(empMemRec?.EmployerId)
        .then((res: Array<Division>) => {
          setDivisions(res)
        })
        .catch(
          catchAPIError({
            defaultMessage: `Failed fetching employer divisions; please contact Engineering`,
          })
        )
    }
  }, [isOpen])

  function setter({ name, value }: SetterArgs) {
    setPayload((curr: any) => ({ ...curr, [name]: value }))
  }

  function doSave() {
    editEmployerMemberDivision({
      ...payload,
      EmployerMemberID: empMemRec?.ID,
    })
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar('Division updated OK!', SnackbarTypeSuccess)
        onSaved && onSaved()
      })
      .catch(catchAPIError({ defaultMessage: 'Failed updating division' }))
      .finally(() => setIsOpen(false))
  }

  if (!empMemRec) return null

  return (
    <>
      <DesignSuite2023.Tooltip title="Manually set division">
        <Trigger onClick={() => setIsOpen(true)} />
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>Change Member Division</DialogTitle>
        <DialogContent>
          {divisions && divisions.length > 0 && (
            <RenderSelectField2
              name="DivisionID"
              label="Division"
              value={payload.DivisionID}
              setter={setter}
              items={divisions.map((d) => ({ label: d.Name, value: d.ID }))}
              FormControlProps={{
                margin: 'none',
                size: 'small',
                fullWidth: true,
              }}
            />
          )}
          {(divisions && divisions.length === 0) ||
            (divisions == null && (
              <Typography>This member's employer has no divisions</Typography>
            ))}
        </DialogContent>
        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            }
            right={
              <>
                {divisions && divisions.length > 0 && (
                  <Button color="primary" variant="contained" onClick={doSave}>
                    Save
                  </Button>
                )}
              </>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
