import { Network } from '../views/Networks/NetworksAutocomplete'
import { Spec } from '../components/SpecializationsAutocomplete'
import { ShapeAddress } from '../components/Address'
export const validators = {
  PosCode: {
    isValid: (val: any) => (val && val.length > 2 ? false : true),
    msg: 'POS Code too long',
  },
}

export interface PracticeFacilityRecordShape extends Record<string, any> {
  ID: number | null
  ExternalId: string | null
  Name: string | null
  Address: ShapeAddress | null
  AddressId: number | null
  CreatedByUserID: string | null
  ModifiedByUserID: string | null
  CreatedAt: string | null
  UpdatedAt: string | null
  ConfigurableNetworks: Network[]
  ConfigurableNetworkIDs: Number[] | undefined
  Specializations: Spec[]
  Phone: string | null
  Fax: string | null
  Email: string | null
  PosCode: string | null
  Npi: string | null
  Fastpass: boolean
  Notes: string | null
  LaunchDate: string | null
  TerminationDate: string | null
  IsActive?: boolean | null
}

export const diffChangedFields = Object.freeze([
  { name: 'Name', label: 'Name' },
  { name: 'ExternalId', label: 'External ID' },
  { name: 'Notes', label: 'Notes' },
  { name: 'Phone', label: 'Phone' },
  { name: 'Fax', label: 'Fax' },
  { name: 'Email', label: 'Email' },
  { name: 'PosCode', label: 'POS Code' },
  { name: 'Fastpass', label: 'Fastpass' },
  { name: 'LaunchDate', label: 'Launch Date' },
  { name: 'TerminationDate', label: 'Termination Date' },
])
