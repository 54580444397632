import React, { useState, useEffect, useCallback } from 'react'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import { debounce } from 'lodash'
import { Searchbox } from '../../components/Inputs'
import { List, ListItem, ListItemText } from '@material-ui/core'
import { BillingPlanActions } from '../../actions'
import styled from 'styled-components'
import dateTime from '../../utils/dateTime'
const { findComponentPrices } = BillingPlanActions

interface ComponentPricePickerProps {
  billingPlanID?: number
  fsID: number
  onAdd(fsp: any): void
}

export const ComponentPricePicker: React.FC<ComponentPricePickerProps> = ({
  fsID,
  onAdd,
}) => {
  const { show: showSnackbar } = useSnackbar()
  const [query, setQuery] = useState<string>('')
  const [results, setResults] = useState<any[]>([])
  // eslint-disable-next-line
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (!fsID) return
    if (query && query.length !== 0 && query.length >= 3) {
      loadPrices(query)
      return
    }
    loadPrices('')
  }, [query, fsID])

  const loadPrices = useCallback(
    debounce((q: string) => {
      setLoading(true)
      findComponentPrices(q, fsID)
        .then((prices: any) => {
          setResults(prices)
        })
        .catch((err: any) => {
          showSnackbar(err, SnackbarTypeError)
        })
        .finally(() => {
          setLoading(false)
        })
    }, 200),
    [setResults, showSnackbar, setLoading, findComponentPrices, fsID]
  )

  const handleClick = (id: number) => {
    return () => {
      onAdd(id)
    }
  }

  return (
    <PickerContainer>
      <div>
        <Searchbox
          fullWidth
          placeholder="Find a price"
          value={query}
          onChange={(ev: any) => {
            const q = ev.target?.value
            setQuery(q)
          }}
        />
      </div>
      <div>
        <List>
          {results && results.length > 0 ? (
            results.map((cp) => {
              return (
                <StyledListItem
                  key={cp.ID}
                  button
                  onClick={handleClick(cp.ID as number)}>
                  <ListItemText
                    primary={<PriceDisplayer cp={cp} />}
                    secondary={
                      <span>
                        {dateTime
                          .parse(cp.StartDate)
                          .format(dateTime.formats.AmericanDate, 'N/A')}{' '}
                        -{' '}
                        {dateTime
                          .parse(cp.EndDate)
                          .format(dateTime.formats.AmericanDate, 'N/A')}
                      </span>
                    }
                  />
                </StyledListItem>
              )
            })
          ) : (
            <div />
          )}
        </List>
      </div>
    </PickerContainer>
  )
}

const PriceDisplayer = ({ cp }: any) => {
  const renderPrice = (cp: any) => {
    let string = `CPT: ${cp.CPTCode} for ${cp.Price}`
    if (cp.Units) string += ` @ ${cp.Units} unit(s)`
    return string
  }

  return (
    <div>
      <span>{renderPrice(cp)}</span>
      {cp.DRGCode ? <div>DRG: {cp.DRGCode}</div> : null}
    </div>
  )
}

const StyledListItem = styled(ListItem)`
  border-radius: 6px;
`

const PickerContainer = styled.div`
  padding-top: 10px;
`
