import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from '@material-ui/core'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar from '../../hooks/useSnackbar'
import styled from 'styled-components'
import DesignSuite2023 from '../../components/DesignSuite2023'
import { EmployerSearcher } from '../../components/Searchers'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
import { deactivateMembersForEmployer } from '../../actions/EmployerActions'

export default function DeactivateMembers(): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const [employer, setEmployer] = useState<any | null>(null)
  const [deactivationDate, setDeactivationDate] = useState<string | null>(null)

  useEffect(() => {
    if (!isOpen) {
      return
    }
    setEmployer(null)
    setDeactivationDate(null)
  }, [isOpen])

  function deactivateMembers() {
    if (
      !employer?.TerminationDate ||
      deactivationDate !== employer.TerminationDate
    ) {
      showSnackbar(
        "Deactivation Date does not match the employer's deactivation date",
        'error'
      )
      return
    }

    deactivateMembersForEmployer(employer.ID, {
      DeactivationDate: deactivationDate,
    })
      .then(() => {
        showSnackbar('Members Deactivated', 'success')
        setIsOpen(false)
      })
      .catch(catchAPIError({ defaultMessage: 'Failed to deactivate members' }))
  }

  function isDisabled() {
    return employer === null || deactivationDate === null
  }

  return (
    <>
      <DesignSuite2023.Tooltip
        placement="left"
        title="Deactivate all members for an employer (dialog)">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsOpen(true)
          }}>
          Deactivate Members
        </Button>
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen}>
        <StyledDialogContent>
          <>
            <EmployerSearcher
              selectedEmployerID={employer?.EmployerID}
              onChange={(_, employer) => setEmployer(employer)}
            />
            <ManagedDateInput
              name="DeactivationDate"
              label="Deactivation Date"
              setter={({ name, value }) => {
                setDeactivationDate(value)
              }}
            />
          </>
        </StyledDialogContent>

        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  setIsOpen(false)
                }}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                disabled={isDisabled()}
                color="primary"
                variant="contained"
                onClick={deactivateMembers}>
                Deactivate Members
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

const StyledDialogContent = styled(DialogContent)`
  min-width: 400px;
`
