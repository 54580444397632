// @ts-ignore
import React, { forwardRef } from 'react'
import * as stdTableSetup from '../../../hooks/useStandardTableSetup'
import { getSearchPGPKeys } from '../../../actions/EngineeringActions'
// This re-exports all the standard exports from the UseStandardTableSetup
// hook (like props interface, etc)
export * from '../../../hooks/useStandardTableSetup'

export const defaultColumns = Object.freeze({
  ID: { name: 'ID', details: {} },
  CreatedAt: { name: 'CreatedAt', details: {} },
  Name: { name: 'Name', details: {} },
  Handle: { name: 'Handle', details: {} },
  Expiration: { name: 'Expiration', details: {} },
  PublicKeyFingerprint: { name: 'Fingerprint', details: {} },
  SFTPaasRecipientName: { name: 'RecipientName', details: {} },
  CreatedByUserName: { name: 'CreatedBy', details: {} },
})

const defaultSort = { col: 'name', dir: 'asc' }

export const Table = forwardRef(function PGPKeyTableList(
  {
    customColumns = defaultColumns,
    apiEndpoint = getSearchPGPKeys,
    ...passThrough
  }: stdTableSetup.props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const { TableDisplay } = stdTableSetup.useStandardTableSetup(
    {
      ...passThrough,
      customColumns,
      apiEndpoint,
      defaultSort,
    },
    ref
  )

  return <>{TableDisplay}</>
})
