import React, { useState } from 'react'
import ClaimDetail from './ClaimDetail'
import DesignSuite2023, {
  DrawerProps,
} from '../../../components/DesignSuite2023'

type props = DrawerProps & {
  zClaimID?: number | null
}

export default React.forwardRef<props, any>(function (
  {
    zClaimID,
    disableEscapeKeyDown = true,
    onClose: propOnClose,
    ...spread
  }: props,
  ref: any
): React.ReactElement | null {
  const [component, setComponent] = useState<React.ReactElement | null>(null)

  function onOpen() {
    setComponent(
      <ClaimDetail zClaimID={zClaimID} doClose={() => ref?.current?.close()} />
    )
  }

  function onClose() {
    setComponent(null)
    propOnClose?.()
  }

  return (
    <DesignSuite2023.Drawer
      {...spread}
      ref={ref}
      width="80vw"
      disableEscapeKeyDown={disableEscapeKeyDown}
      onOpen={onOpen}
      onClose={onClose}>
      {component}
    </DesignSuite2023.Drawer>
  )
})
