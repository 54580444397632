export const FIND_BUNDLERS = 'FIND_BUNDLERS'

/* TODO: remove when we hookup bundlers */
const ZEROCARD_BUNDLER = {
  ID: 1,
  Name: 'ZeroCard',
}

export const findBundlers = (params) => {
  return {
    type: FIND_BUNDLERS,
    /* TODO: remove when we hookup bundlers */
    payload: new Promise((resolve, reject) => {
      console.debug(
        'Pulling bundlers from local memory, not from the server!!!'
      )
      resolve({ Data: [ZEROCARD_BUNDLER], Meta: { Total: 1 } })
    }), // api.search('/bundler', params)
  }
}
