import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  BootstrapTable,
  TableHeaderColumn,
  ButtonGroup,
} from 'react-bootstrap-table'
import _ from 'lodash'
import './ChildTable.css'
import { CircularProgress } from '@material-ui/core'

const KEY_COLUMN = 'KeyColumn'
const styles = (theme) => {
  return {
    legend: {
      paddingTop: 5,
    },
    legendLabel: {
      paddingTop: 5,
      display: 'inline-block',
    },
  }
}

class ChildTable extends Component {
  constructor(props) {
    super(props)

    this.renderTableColumns = this.renderTableColumns.bind(this)
    this.renderRowSaveStyle = this.renderRowSaveStyle.bind(this)
  }

  defaultCellFormatter(cell, row) {
    return `<span title="${cell}" data-selectable>${cell}</span>`
  }

  renderRowSaveStyle(row, rowIdx) {
    const { cellEdit, rowSaveResults } = this.props

    if (cellEdit && rowSaveResults) {
      if (rowSaveResults[row.ID] !== undefined) {
        return rowSaveResults[row.ID].success ? 'tr-saved' : 'tr-error'
      }
    }

    return ''
  }

  createCustomToolBar = (props) => {
    return (
      <div className="child-table-custom-toolbar" style={{ width: '100%' }}>
        <div className="col-xs-8 col-sm-4 col-md-4">
          {props.components.btnGroup}
        </div>
        <div className="col-xs-8 col-sm-4 col-md-4">
          {this.renderTableLegend()}
        </div>
        <div className="col-xs-8 col-sm-4 col-md-4">
          <div className="col-xs-4" />
          <div className="col-xs-8" style={{ padding: 0 }}>
            {props.components.searchPanel}
          </div>
        </div>
      </div>
    )
  }

  createCustomButtonGroup = (props) => {
    return (
      <ButtonGroup className="my-custom-class" sizeClass="btn-group-md">
        {props.deleteBtn}
      </ButtonGroup>
    )
  }

  renderTableLegend() {
    const { legendItems, classes } = this.props
    if (!legendItems || legendItems.length === 0) return <div />

    const items = _.map(legendItems, (item, key) => {
      const className = `legend-item ${item.color}`
      return (
        <div key={key}>
          <div className={className} />
          <div className={classes.legendLabel}>{item.label}</div>
        </div>
      )
    })

    return <div className={classes.legend}>{items}</div>
  }

  renderTableColumns() {
    const keyCol = this.props.columns[KEY_COLUMN]

    return _.map(this.props.columns, (colDetails, colKey) => {
      if (colKey === KEY_COLUMN) {
        return
      } // we want to ignore this one
      const isKey = colKey === keyCol

      let colName
      let details = {}
      if (typeof colDetails === 'object') {
        colName = colDetails.name
        details = colDetails.details
      } else if (typeof colDetails === 'string') {
        colName = colDetails
      }
      if (details && !details.dataFormat)
        details.dataFormat = this.defaultCellFormatter

      return (
        <TableHeaderColumn
          {...details}
          key={colKey}
          isKey={isKey}
          dataField={colKey}
          dataAlign="center"
          tdStyle={{ whiteSpace: 'normal', width: '100%' }}
          thStyle={{ whiteSpace: 'normal' }}
          dataSort={colDetails?.details?.sortz || false}>
          {colName}
        </TableHeaderColumn>
      )
    })
  }

  baseClickHandler = (customHandler) => {
    return (row, meta, rowIndex, { target, nativeEvent }) => {
      const selectable =
        target && target.attributes && target.attributes['data-selectable']
      const isTD = target.tagName === 'TD'

      if (target.name === 'view-link' || selectable || !isTD) {
        return
      }
      if (customHandler) {
        return customHandler(row, meta, rowIndex, { target, nativeEvent })
      }
    }
  }

  render() {
    const columns = this.renderTableColumns()
    const { classes } = this.props
    const { options, ...propsNoOpts } = this.props

    let opts
    if (this.props.loading) {
      opts = { noDataText: <CircularProgress className={classes.progress} /> }
    } else {
      opts = {
        noDataText: this.props.noDataText || 'There is no data to display',
      }
    }
    if (!columns || columns.length === 0) return
    if (options)
      opts = Object.assign(
        {
          toolBar: this.createCustomToolBar,
          btnGroup: this.createCustomButtonGroup,
        },
        opts,
        options
      )
    if (options && options.onRowClick)
      opts.onRowClick = this.baseClickHandler(options.onRowClick)

    return (
      <div>
        <BootstrapTable
          data={this.props.rows}
          options={opts}
          trClassName={this.renderRowSaveStyle}
          {...propsNoOpts}>
          {columns}
        </BootstrapTable>
      </div>
    )
  }
}

ChildTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default withStyles(styles)(ChildTable)
