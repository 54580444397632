const regexTests = {
  'YYYY-MM-DD': new RegExp(/(\d{4})-(\d{2})-(\d{2})/),
  email: new RegExp(
    // eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ),
  alphanum: new RegExp(/^[a-z0-9]+$/i),
}

export default {
  is7Digits: {
    label: 'Is 7 Digits',
    test(casted) {
      return casted.result ? (casted.result + '').length === 7 : false
    },
  },
  inputIs1Or0: {
    label: 'Input Is 0|1',
    test(casted) {
      if (casted._original === '0' || casted._original === '1') {
        return true
      }
    },
  },
  inputIs1Or0OrEmpty: {
    label: 'Input Is 0|1|Empty',
    test(casted) {
      if (
        casted._original === '0' ||
        casted._original === '1' ||
        casted._original === ''
      ) {
        return true
      }
      return false
    },
  },
  dateStandard: {
    label: 'Input is YYYY-MM-DD',
    test(casted) {
      if (regexTests['YYYY-MM-DD'].test(casted._original)) {
        return true
      }
      return false
    },
  },
  email: {
    label: 'Email',
    test(casted) {
      if (regexTests.email.test(casted._original)) {
        return true
      }
      return false
    },
  },
  alphaNumOnly: {
    label: 'AlphaNumeric Only',
    test(casted) {
      if (regexTests.alphanum.test(casted._original)) {
        return true
      }
      return false
    },
  },
}
