import React, { useEffect, useState } from 'react'
import ChildTable from '../../components/ChildTable/ChildTable'
import {
  RenderSelectField2,
  renderTextField,
  SetterArgs,
} from '../../views/ViewHelpers'
import { EmployerSearcher } from '../../components/Searchers'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useQueryParamsGen2 from '../../hooks/useQueryParamsGen2'
import { searchForProvider } from '../../actions'
import { getEmployerMemberLimitedByID } from '../../actions/EligibilityActions'
import DesignSuite2023 from '../../components/DesignSuite2023'
import utils from '../../utils'
import Models from '../../models'
import { Button, Chip, Grid, Paper, Typography } from '@material-ui/core'
import styled from 'styled-components'

const PaperWrapper = styled(Paper)`
  padding: 1.5rem 1rem 0.75rem;
`

const StyledHR = styled.hr`
  margin: 1rem 0;
`

interface EmployerMemberInfo {
  EmployerID: number
  Member: {
    ID: number
    FirstName: string
    LastName: string
  }
}

export default function ProviderSearch2({ location }: any): React.ReactElement {
  const urlParamsFromFirstLoad = React.useMemo((): any => {
    const onPageLoadSearchParams = new URLSearchParams(location.search)
    return {
      query: onPageLoadSearchParams.get('query') || '',
      zipcode: onPageLoadSearchParams.get('zipcode') || '',
      distance: onPageLoadSearchParams.get('distance') || '25mi',
      employerID:
        parseInt(onPageLoadSearchParams.get('employerID') || '') || null,
      employerMemberID:
        parseInt(onPageLoadSearchParams.get('employerMemberID') || '') || null,
    }
  }, [])
  const { queryData, setQueryData } = useQueryParamsGen2()
  const [isSearching, setIsSearching] = useState<boolean>(false)
  const [filter, setFilter] = useState({
    ...urlParamsFromFirstLoad,
    ...(queryData.filter || {}),
  })
  const [validations, setValidations] = useState<any>({})
  const [results, setResults] = useState<Array<any>>([])
  const [empMemInfo, setEmpMemInfo] = useState<EmployerMemberInfo | null>(null)
  const [isReady, setIsReady] = useState(false)
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (!filter.employerMemberID) {
      setIsReady(true)
      return
    }
    getEmployerMemberLimitedByID(filter.employerMemberID)
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        setter({ name: 'employerID', value: res.Data?.EmployerID })
        setEmpMemInfo(res.Data)
        setIsReady(true)
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed loading employer member to scope provider search',
        })
      )
  }, [])

  // When the page first loads, if the data passed in the URL yields enough
  // info that the search would be considered valid, then send the search right away
  // (use case example: think about a user hitting the back button in the browser).
  // Caveat - and the reason we have the 'isReady' check - is that if an employerMemberID
  // is passed in the URL, we need to preload that data (see useEffect above), THEN issue
  // the search (again, if valid)
  useEffect(() => {
    if (!isReady) return
    validate(true) && doSearch()
  }, [isReady])

  useEffect(() => {
    setQueryData({ filter })
  }, [filter])

  function setter({ name, value }: SetterArgs): void {
    setFilter((curr: any) => ({ ...curr, [name]: value }))
  }

  function validate(skipSetDisplayFeedback = false): boolean {
    const queryIsValid = filter.query.length >= 2
    const zipIsValid =
      filter.zipcode &&
      utils.isInteger(filter.zipcode) &&
      filter.zipcode.length === 5

    if (skipSetDisplayFeedback) {
      if (!queryIsValid || !zipIsValid) return false
      return true
    }

    const valData = {} as any
    if (!queryIsValid) {
      valData.query = {
        error: true,
        FormHelperTextProps: { error: true },
        helperText: '2 or more characters required',
      }
    }
    if (!zipIsValid) {
      valData.zipcode = {
        error: true,
        FormHelperTextProps: { error: true },
        helperText: !!filter.zipcode ? 'Invalid zip' : 'Required',
      }
    }

    setValidations(valData)
    return Object.keys(valData).length === 0
  }

  function onFormSubmit(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault()
    validate() && doSearch()
  }

  /*
    TLDR: employerMemberID is a value that can be passed as a URL (from say, an external application like
    Zendesk), and its tracked in 'filters' state - BUT the API route won't do anything with it (this component
    does poach the EmployerID from the loaded employer member data though, if passed), and that's important.
    Anyways - that's why we're omitting employerMemberID here; because it has no effect.
  */
  function doSearch() {
    setIsSearching(true)

    const payload = {
      query: filter.query,
      zipcode: filter.zipcode,
      distance: filter.distance,
      employerIDs: !!filter.employerID ? [filter.employerID] : [],
    }

    searchForProvider(payload)
      .then((res: any) => {
        if (res.error) throw res
        setResults(res.Data || [])
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Search failed; please contact Engineering if you see this',
        })
      )
      .finally(() => {
        setIsSearching(false)
      })
  }

  function clearEmployerMemberID() {
    setFilter((f: any) => ({ ...f, employerMemberID: null }))
  }

  return (
    <PaperWrapper elevation={2}>
      <form onSubmit={onFormSubmit}>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} md="auto">
            {renderTextField({
              name: 'query',
              label: 'Search for...',
              value: filter.query,
              setter,
              opts: {
                ...(validations?.query || {}),
                style: { maxWidth: 250 },
                autoFocus: true,
                variant: 'outlined',
                size: 'small',
                margin: 'none',
              },
            })}
          </Grid>
          <Grid item xs={12} md="auto">
            {renderTextField({
              name: 'zipcode',
              label: 'Zip Code',
              value: filter.zipcode,
              setter,
              opts: {
                ...(validations?.zipcode || {}),
                style: { maxWidth: 110 },
                variant: 'outlined',
                size: 'small',
                margin: 'none',
              },
            })}
          </Grid>
          <Grid item xs={12} md="auto">
            <RenderSelectField2
              name="distance"
              label="Distance"
              value={filter.distance}
              setter={setter}
              items={[
                { label: '10 Miles', value: '10mi' },
                { label: '25 Miles', value: '25mi' },
                { label: '50 Miles', value: '50mi' },
                { label: '100 Miles', value: '100mi' },
                { label: '1000 Miles', value: '1000mi' },
              ]}
              FormControlProps={{
                margin: 'none',
                size: 'small',
              }}
            />
          </Grid>
          <Grid item xs={12} md="auto">
            <EmployerSearcher
              disabled={!!filter.employerMemberID}
              selectedEmployerID={filter.employerID}
              onChange={(employerID: number | null) => {
                setter({ name: 'employerID', value: employerID })
              }}
              TextFieldProps={{
                variant: 'outlined',
                size: 'small',
                InputLabelProps: { shrink: true },
                style: { minWidth: 250, maxWidth: 350 },
                helperText:
                  (filter.employerMemberID &&
                    'Disabled while Employer Member present') ||
                  '',
              }}
              AutoCompleteProps={{
                style: { display: 'inline-block', maxWidth: 350 },
              }}
            />
          </Grid>
          {filter.employerMemberID && empMemInfo && (
            <Grid item xs={12} md="auto">
              <Chip
                label={`${empMemInfo.Member.FirstName} ${empMemInfo.Member.LastName} (Employer Member ID: ${filter.employerMemberID})`}
                onDelete={clearEmployerMemberID}
              />
            </Grid>
          )}
          <Grid item xs={12} md="auto">
            <Button variant="contained" type="submit">
              Search
            </Button>
          </Grid>
          <Grid item xs={12} md="auto">
            <DesignSuite2023.AlertInfo>
              Friendly reminder: updates to data in other parts of the system
              (cost keys, fee schedules, organizations, practice facilities,
              configurable networks) may not be reflected in search for up to 24
              hours.
            </DesignSuite2023.AlertInfo>
          </Grid>
        </Grid>
      </form>

      <StyledHR />

      <ChildTable
        columns={Models.ProviderSearch.columns}
        rows={results}
        // trClassName={classes.childRow}
        loading={isSearching}
        // @ts-ignore
        expandableRow={(row: any) => true}
        expandComponent={(row: any) => (
          <ChildTable
            columns={Models.BundlePrices.columns}
            loading={false}
            rows={row.Prices}
            // trClassName={classes.childRow}
            // @ts-ignore
            pagination
            search
          />
        )}
        expandColumnOptions={{ expandColumnVisible: true }}
        noDataText="No results"
        hover
        search
        pagination
      />

      <Typography variant="caption">
        ATTENTION: <span style={{ color: 'red' }}>**</span> indicates a provider
        that is <strong>NOT</strong> accepting referrals in GoZERO.
      </Typography>
    </PaperWrapper>
  )
}
