import util from './utils'
import { EligibilityActions, CLEAR_SEARCH_RESULTS } from '../actions'
const { SEARCH_FOR_ELIGIBILITY } = EligibilityActions

export default function (state = null, action) {
  switch (action.type) {
    case SEARCH_FOR_ELIGIBILITY: {
      if (action.payload.error) {
        return {
          ...state,
          getError: util.errorResponse(action),
        }
      }

      return {
        results: action.payload.Data,
      }
    }
    case CLEAR_SEARCH_RESULTS:
      return {
        ...state,
        results: [],
      }
    default:
      return {
        ...state,
      }
  }
}
