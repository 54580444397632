import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App'

import './styles/index.scss'
// @todo: 2024 refactor: GET RID OF BOOTSTRAP ONCE AND FOR ALL
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/css/bootstrap-theme.css'
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
// @todo: 2024 refactor: GET RID OF BOOTSTRAP ONCE AND FOR ALL
import { AuthProvider } from './providers/Auth'
import { ConfigProvider } from './providers/Config'
import ZeroThemeProvider from './styles/ZeroThemeProvider'
import checkRelease from './CheckRelease'

// https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-client-rendering-apis
createRoot(document.getElementById('root')).render(
  <ZeroThemeProvider>
    <ConfigProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ConfigProvider>
  </ZeroThemeProvider>
)

checkRelease()
