import React, { useState, useEffect } from 'react'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import * as CostKeyTable2 from '../CostKeys/CostKeyTable2'
import { putAddNetworkEntities } from '../../actions/NetworkActions'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Tooltip,
} from '@material-ui/core'
import _pick from 'lodash/pick'
import styled from 'styled-components'

const StyledDialogContent = styled(DialogContent)`
  .header-items {
    margin-top: 0 !important;
    h3 {
      margin: 0;
      padding: 0;
    }
  }
`

const customColsCKTable = _pick(CostKeyTable2.defaultColumns, [
  'Code',
  'Category',
  'Descr',
])

export interface props {
  networkID: number
  onClose?(): void
}

export default function DialogAddCostKey({
  networkID,
  onClose,
}: props): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState<Array<any>>([])
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()

  useEffect(() => {
    setSelected([])
  }, [isOpen])

  function doClose() {
    setIsOpen(false)
    onClose && onClose()
  }

  function doAdd() {
    putAddNetworkEntities(networkID, {
      CostKeyCodeIDs: selected.map((e: any) => e.ID),
    })
      .then((_: any) => {
        showSnackbar('Added to network OK!', SnackbarTypeSuccess)
      })
      .catch(
        catchAPIError({ defaultMessage: 'Failed adding record to network' })
      )
      .finally(doClose)
  }

  function isDisabled() {
    return (selected || []).length === 0
  }

  if (!networkID) return null
  return (
    <>
      <Tooltip title="(EngineeringOnly): launch bulk-add workflow">
        <Button variant="outlined" onClick={() => setIsOpen(true)}>
          Add Cost Keys(s)
        </Button>
      </Tooltip>

      <Dialog open={isOpen} maxWidth="lg">
        <StyledDialogContent>
          <CostKeyTable2.Table
            onRowClick={() => {}}
            passFilters={{ ExcludeDeprecated: true }}
            customColumns={customColsCKTable}
            onCheckHandler={setSelected}
            LeftHeaderItems={
              <>
                <h3>Add Cost Key To Network</h3>
              </>
            }
            RightHeaderItems={
              <>
                <CostKeyTable2.StandardFilterSearch autoFocus />
              </>
            }
            DataTableProps={{
              LeftFooterItems: (
                <>
                  <small>
                    <strong>Deprecated cost keys are not displayed.</strong>
                  </small>
                </>
              ),
            }}
          />
        </StyledDialogContent>
        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button color="secondary" variant="outlined" onClick={doClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                disabled={isDisabled()}
                color="primary"
                variant="contained"
                onClick={doAdd}>
                Proceed
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}
