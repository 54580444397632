import React, { useState, useEffect, useRef, forwardRef } from 'react'
import * as api from '../../../services/thezerocard/api-helper'
import useSnackbar, { SnackbarTypeError } from '../../../hooks/useSnackbar'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import SendLogTableList from './sendLogTableList'
import CredentialManager from './credentialsManager'
import AdhocFileSend from './adhocFileSend'
import DialogSendTestFile from './dialogSendTestFile'
import DialogConfirmHostKeyInfo from './dialogConfirmHostKey'
import PGPKeySearcher from '../PGPKeys/PGPKeySearcher'
import * as DialogPGPKey from '../../../views/Engineering/PGPKeys/DialogPGPKey'
import * as types from './types'
import * as pgpTypes from '../PGPKeys/types'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import DialogLinkRecipient from './dialogLinkRecipient'
import styled from 'styled-components'
import {
  CircularProgress,
  // Typography,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tabs,
  Tab,
  InputAdornment,
  IconButton,
  Link,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Clear as IconClear } from '@material-ui/icons'
require('./dialogRecipientStyle.scss')

const Linkish = styled.strong`
  text-decoration: underline;
  cursor: pointer;
`

const StyledPGPActions = styled.div`
  button {
    margin-right: 0.5rem;
  }
`

const StyledChildRecipientsList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-column-gap: 1rem;
  grid-row-gap: 0.5rem;
  white-space: nowrap;
  margin-top: 0.5rem;

  button {
    text-align: left;
    justify-content: flex-start;
  }
`

interface props {
  onDialogClose?(): void
}

export default forwardRef(function DialogRecipient(
  { onDialogClose }: props,
  ref: any
): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [recipID, setCurrentRecipientID] = useState<number | null>(null)

  useEffect(() => {
    if (!isOpen) {
      setCurrentRecipientID(null)
    }
  }, [isOpen])

  // Expose a public API for the component (open, close), making it easier to track
  // relevant state in a self-contained manner instead of balancing between (potentially multiple)
  // parent components.
  React.useImperativeHandle(ref, () => ({
    open: (recipientID?: number | null) => {
      setCurrentRecipientID(recipientID || null)
      setIsOpen(true)
    },
    close: () => {
      setIsOpen(false)
    },
  }))

  // function instead of a const to 'hoist' and make available in useEffect higher up
  function handleClose() {
    setIsOpen(false)
    setCurrentRecipientID(null)
    onDialogClose && onDialogClose()
  }

  if (!isOpen) return null

  return (
    <Dialog
      className="dialog-recipient"
      open={isOpen}
      onClose={handleClose}
      maxWidth={false}>
      {recipID && recipID >= 1 && (
        <ExistingRecipient
          handleClose={handleClose}
          recipientID={recipID}
          setCurrentRecipientID={setCurrentRecipientID}
        />
      )}
      {!recipID && (
        <NewRecipient
          handleClose={handleClose}
          setCurrentRecipientID={setCurrentRecipientID}
        />
      )}
    </Dialog>
  )
})

function ExistingRecipient({
  recipientID,
  handleClose,
  setCurrentRecipientID,
}: any): any {
  const tabItems = { info: 0, viewLog: 1, sendFiles: 2, creds: 3 }
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [recipient, setRecipient] = useState<types.recipient>(
    {} as types.recipient
  )
  const [tabVal, setTabVal] = useState(tabItems.info)
  const [showTestFileDialog, setShowTestFileDialog] = useState(false)
  const [childRecipients, setChildRecipients] = useState<types.recipient[]>([])
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (!recipientID) {
      setIsLoading(false)
      setIsSaving(false)
      setRecipient({} as types.recipient)
      setTabVal(tabItems.info)
      setShowTestFileDialog(false)
      setChildRecipients([])
      return
    }
    refresh()
  }, [recipientID])

  const onClickUpdate = () => {
    setIsSaving(true)
    api
      .put(`/engineering/sftpaas/recipient/${recipientID}`, recipient)
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        setRecipient(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Request failed; please contact engineering',
        })
      )
      .finally(() => {
        setIsSaving(false)
      })
  }

  function refresh() {
    setIsLoading(true)
    Promise.all([
      api.get(`/engineering/sftpaas/recipient/${recipientID}`),
      api.search(`/engineering/sftpaas/recipient`, {
        filter: { ParentRecipientID: recipientID, NoLimit: true },
      }),
    ])
      .then(([resRecip, resKids]) => {
        if (resRecip.error) {
          throw resRecip
        }
        if (resKids.error) {
          throw resKids
        }
        setRecipient(resRecip.Data)
        setChildRecipients(resKids.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Request failed; please contact engineering',
        })
      )
      .finally(() => {
        setIsLoading(false)
      })
  }

  if (isLoading || !recipient.ID) {
    return <CircularProgress />
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        justify="space-between"
        alignItems="center"
        className="dialog-pseudo-title">
        <Grid item xs="auto">
          <h2>
            Recipient <strong>{recipient.Name}</strong>
          </h2>
        </Grid>
        <Grid item xs="auto">
          <Button color="secondary" variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </Grid>
      </Grid>

      <DialogContent className="dialog-recipient-body">
        <div className="tab-wrap">
          <Tabs
            value={tabVal}
            onChange={(_: any, v: number) => {
              setTabVal(v)
            }}
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="inherit">
            <Tab value={tabItems.info} label="Info" />
            <Tab value={tabItems.viewLog} label="Send Log" />
            <Tab value={tabItems.sendFiles} label="Send Files (Adhoc)" />
            <Tab value={tabItems.creds} label="Credentials" />
          </Tabs>
        </div>

        {tabVal === tabItems.info && (
          <>
            <RecipientFields
              recipient={recipient}
              setRecipient={setRecipient}
              setCurrentRecipientID={setCurrentRecipientID}
              ExtraItems={
                <>
                  {!!childRecipients?.length && (
                    <>
                      <DesignSuite2023.AlertInfo>
                        <span>
                          This recipient has {childRecipients.length} linked
                          children (eg. recipients with alternate
                          configurations, but that use <strong>this</strong>{' '}
                          recipient's credentials).
                        </span>
                      </DesignSuite2023.AlertInfo>
                      <StyledChildRecipientsList>
                        <strong>Name</strong>
                        <strong>Handle</strong>
                        <strong>Default Destination Path</strong>
                        {childRecipients.map((r: types.recipient) => (
                          <>
                            <Button
                              key={`${r.ID}-name`}
                              size="small"
                              variant="text"
                              onClick={() => setCurrentRecipientID(r.ID)}>
                              {r.Name}
                            </Button>
                            <div key={`${r.ID}-handle`}>
                              <code>{r.Handle}</code>
                            </div>
                            <div key={`${r.ID}-dest`}>
                              <code>{r.DefaultDestPath}</code>
                            </div>
                          </>
                        ))}
                      </StyledChildRecipientsList>
                      <DesignSuite2023.Divider />
                    </>
                  )}

                  <Grid
                    container
                    spacing={2}
                    justify="space-between"
                    alignItems="flex-start">
                    <Grid item xs="auto">
                      <DesignSuite2023.Tooltip title="There is another step, this will not trigger sending immediately">
                        <Button
                          variant="outlined"
                          onClick={setShowTestFileDialog.bind(null, true)}>
                          Send A Test File
                        </Button>
                      </DesignSuite2023.Tooltip>
                      &nbsp;
                      <DialogLinkRecipient
                        existingRecipient={recipient}
                        onSave={refresh}
                      />
                    </Grid>
                    <Grid item xs="auto" style={{ textAlign: 'right' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onClickUpdate}>
                        {isSaving === true && (
                          <CircularProgress
                            size={20}
                            style={{ marginRight: '0.5rem' }}
                          />
                        )}
                        Save Recipient
                      </Button>
                      <span className="last-updated-at">
                        Last Updated At: {recipient.UpdatedAt}
                      </span>
                    </Grid>
                  </Grid>
                </>
              }
            />
          </>
        )}

        {tabVal === tabItems.viewLog && (
          <SendLogTableList
            passFilters={{ recipientID: recipient.ID }}
            disableFilterRecipient
          />
        )}

        {tabVal === tabItems.sendFiles && (
          <AdhocFileSend
            recipient={recipient}
            setTabViewLog={() => {
              setTabVal(tabItems.viewLog)
            }}
          />
        )}

        {tabVal === tabItems.creds && (
          <>
            {!!recipient.ParentRecipientID && (
              <DesignSuite2023.AlertWarning style={{ marginBottom: '1rem' }}>
                Credentials cannot be managed for this recipient since it is
                linked to a parent recipient. Go back to the Info tab and click
                to view the original recipient to manage credentials.
              </DesignSuite2023.AlertWarning>
            )}
            {!recipient.ParentRecipientID && (
              <CredentialManager
                recipientID={recipient.ID}
                recipientHandle={recipient.Handle}
              />
            )}
          </>
        )}
      </DialogContent>

      <DialogSendTestFile
        recipientID={recipient.ID}
        isOpen={showTestFileDialog}
        setIsOpen={setShowTestFileDialog}
      />
    </>
  )
}

function NewRecipient({ handleClose, setCurrentRecipientID }: any): any {
  const [recipient, setRecipient] = useState<types.recipient>(
    {} as types.recipient
  )
  const [credentials, setCredentials] = useState<types.credentials>(
    {} as types.credentials
  )
  const [hostKeyInfo, setHostKeyInfo] = useState<any>(null)
  const { catchAPIError } = useErrorHandlers()

  const onCredentialsChange = (c: types.credentials) => {
    setCredentials(c)
  }

  const handleSave = () => {
    api
      .post('/engineering/sftpaas/recipient', {
        Name: recipient.Name,
        DefaultDestPath: recipient.DefaultDestPath,
        HostKeyFingerprint: recipient.HostKeyFingerprint,
        Notes: recipient.Notes,
        Credentials: credentials,
        NotifiableEmails: recipient.NotifiableEmails,
      })
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        setCurrentRecipientID && setCurrentRecipientID(res.Data.ID)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Request failed; please contact engineering',
        })
      )
  }

  return (
    <>
      <DialogTitle className="dialog-titlebar">Manage Recipient</DialogTitle>
      <DialogContent className="dialog-recipient-body">
        <DialogContentText className="dialog-text">
          Note: credentials are NOT stored in the database. However, you can{' '}
          <strong>&nbsp;set them&nbsp;</strong>
          here, and we'll pass through to the storage destination (AWS SSM).
        </DialogContentText>
        <RecipientFields recipient={recipient} setRecipient={setRecipient} />
        <CredentialManager
          recipientHandle={recipient.Handle}
          onCredentialsChange={onCredentialsChange}
          onTestConnectionHostKeyInfo={setHostKeyInfo}
        />
      </DialogContent>
      <DialogActions className="dialog-footerbar">
        <Grid container spacing={2} justify="space-between">
          <Grid item xs="auto">
            <Button color="secondary" variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button color="primary" variant="contained" onClick={handleSave}>
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>

      <DialogConfirmHostKeyInfo
        hostKeyInfo={hostKeyInfo}
        doClose={() => {
          setHostKeyInfo(null)
        }}
        onConfirm={() => {
          setRecipient({
            ...recipient,
            HostKeyFingerprint: hostKeyInfo.HostKeyFingerprint,
          })
          setHostKeyInfo(null)
        }}
      />
    </>
  )
}

function RecipientFields({
  recipient,
  setRecipient,
  setCurrentRecipientID = () => {},
  ExtraItems,
}: {
  recipient: types.recipient
  setRecipient: any
  setCurrentRecipientID?: (v: number | null) => void
  ExtraItems?: React.ReactNode
}): any {
  const { show: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()
  const [checkingHostkey, setCheckingHostkey] = useState(false)
  const [hostKeyInfo, setHostKeyInfo] = useState<any>(null)
  const [parentRecipient, setParentRecipient] =
    useState<types.recipient | null>(null)
  const refPGPDialog = useRef<DialogPGPKey.DialogAPI>(null)

  const persisted = !!recipient.ID

  useEffect(() => {
    if (!recipient?.ParentRecipientID) return
    api
      .get(`/engineering/sftpaas/recipient/${recipient.ParentRecipientID}`)
      .then((res: any) => {
        if (res.error) throw res
        setParentRecipient(res.Data as types.recipient)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to fetch parent recipient',
        })
      )
  }, [recipient?.ParentRecipientID])

  const addEmailField = () => {
    setRecipient((curr: types.recipient): types.recipient => {
      const NotifiableEmails = curr.NotifiableEmails || []
      NotifiableEmails.push('')
      return { ...curr, NotifiableEmails }
    })
  }

  const rmEmailField = (idx: number) => {
    setRecipient((curr: types.recipient): types.recipient => {
      const NotifiableEmails = curr.NotifiableEmails || []
      NotifiableEmails.splice(idx, 1)
      return { ...curr, NotifiableEmails }
    })
  }

  const doResetHostKey = () => {
    if (!persisted) {
      // note: this should never happen anyways...
      showSnackbar(
        'This cannot be done while Recipient is unsaved.',
        SnackbarTypeError
      )
      return
    }
    setCheckingHostkey(true)
    api
      .get(`/engineering/sftpaas/recipient/${recipient.ID}/hostkey-info`)
      .then((res: any) => {
        if (res.error) throw res
        setHostKeyInfo(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Updating known host key failed.',
        })
      )
      .finally(() => {
        setCheckingHostkey(false)
      })
  }

  function onClickViewPGPKey() {
    if (!recipient.PGPKeyPublicID) return
    refPGPDialog.current?.editPGPKeyPublicID(recipient.PGPKeyPublicID)
  }

  function onClickAddNewPGPKey() {
    refPGPDialog.current?.open()
  }

  function onNewPGPKey(v: pgpTypes.PGPKey) {
    setRecipient(
      (curr: types.recipient): types.recipient => ({
        ...curr,
        PGPKeyPublicID: v.ID,
      })
    )
  }

  return (
    <>
      {persisted && !recipient.HostKeyFingerprint && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Alert
              severity="warning"
              style={{ marginBottom: '1rem', alignItems: 'center' }}>
              <strong>Warning</strong> Known host key fingerprint is missing;
              sending to this Recipient will fail until this is fixed. **Note**
              you will need a user set up on the recipient server before
              validating.**
              <DesignSuite2023.Tooltip title="Note: the Credentials must be valid and able to connect; this will *ignore* checking the host key on connection and record it back onto this Recipient.">
                <Button size="small" color="primary" onClick={doResetHostKey}>
                  {checkingHostkey ? 'Checking... ' : 'Click here to update'}
                  {checkingHostkey && <CircularProgress size={15} />}
                </Button>
              </DesignSuite2023.Tooltip>
            </Alert>
          </Grid>
        </Grid>
      )}

      {!!parentRecipient && (
        <DesignSuite2023.AlertWarning style={{ marginBottom: '1rem' }}>
          This recipient was linked from{' '}
          <Button
            variant="text"
            size="small"
            onClick={() => setCurrentRecipientID(parentRecipient.ID)}>
            {parentRecipient.Name}
          </Button>{' '}
          and uses that recipient's credentials.
        </DesignSuite2023.AlertWarning>
      )}

      <Grid container spacing={2}>
        <Grid item xs={persisted ? 4 : 6}>
          <TextField
            fullWidth
            size="small"
            label="Name"
            placeholder="..."
            variant="outlined"
            value={recipient.Name || ''}
            onChange={(ev: any) => {
              setRecipient({ ...recipient, Name: ev.target.value })
            }}
          />
        </Grid>
        <Grid item xs={persisted ? 4 : 6}>
          <TextField
            fullWidth
            size="small"
            label="Default Destination Path"
            placeholder="~/zerocard_drops/folder_a"
            variant="outlined"
            value={recipient.DefaultDestPath || ''}
            onChange={(ev: any) => {
              setRecipient({ ...recipient, DefaultDestPath: ev.target.value })
            }}
          />
        </Grid>
        {persisted && (
          <Grid item xs={4}>
            <TextField
              fullWidth
              disabled
              size="small"
              label="Handle"
              variant="outlined"
              value={recipient.Handle}
              helperText="immutable and auto-set during creation"
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={5}
            size="small"
            label="Notes"
            placeholder="..."
            variant="outlined"
            value={recipient.Notes || ''}
            onChange={(ev: any) => {
              setRecipient({ ...recipient, Notes: ev.target.value })
            }}
            InputProps={{ style: { fontSize: 14 } }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
        <Grid item xs={12}>
          <TextField
            disabled
            fullWidth
            size="small"
            label="Known Host Key Fingerprint"
            variant="outlined"
            value={
              recipient.HostKeyFingerprint ||
              (persisted && !recipient.HostKeyFingerprint
                ? '!missing!'
                : 'Use Test Connection when setting up credentials below...')
            }
            helperText={
              <>
                <Linkish
                  onClick={() => {
                    setRecipient({ ...recipient, HostKeyFingerprint: null })
                  }}>
                  Clear
                </Linkish>{' '}
                (Clearing will trigger an "update fingerprint" warning up top,
                which you should use immediately).
              </>
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={9}>
          <div className="notifiable-emails">
            <h5>Notifiable Emails</h5>
            {!!recipient.NotifiableEmails &&
              recipient.NotifiableEmails.map((x: any, idx: number) => {
                return (
                  <TextField
                    key={idx}
                    autoFocus
                    variant="outlined"
                    size="small"
                    label="Email"
                    placeholder="x@y.com"
                    value={x || ''}
                    onChange={(ev: any) => {
                      const v = ev.target.value
                      setRecipient((curr: types.recipient): types.recipient => {
                        const NotifiableEmails = curr.NotifiableEmails || []
                        NotifiableEmails[idx] = v
                        return { ...curr, NotifiableEmails }
                      })
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={rmEmailField.bind(null, idx)}
                            edge="end">
                            <IconClear />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )
              })}
            <Button variant="outlined" onClick={addEmailField}>
              Add
            </Button>
          </div>
        </Grid>
        {!parentRecipient && (
          <Grid item xs={3}>
            <h5>Use PGP Encryption</h5>
            {persisted ? (
              <>
                <PGPKeySearcher
                  enableAddNew
                  selectedPGPKeyPublicID={recipient.PGPKeyPublicID}
                  withFilters={{ sftpaasRecipientID: recipient.ID }}
                  onChange={(id: number | null) => {
                    setRecipient((curr: types.recipient) => ({
                      ...curr,
                      PGPKeyPublicID: id,
                    }))
                  }}
                  TextFieldProps={{
                    style: { marginTop: '0.35rem' },
                  }}
                />

                <StyledPGPActions>
                  {!!recipient.PGPKeyPublicID && (
                    <Link
                      component="button"
                      variant="inherit"
                      underline="always"
                      onClick={onClickViewPGPKey}>
                      View Details
                    </Link>
                  )}
                  <Link
                    component="button"
                    variant="inherit"
                    underline="always"
                    onClick={onClickAddNewPGPKey}>
                    Setup a new key
                  </Link>
                </StyledPGPActions>

                <DialogPGPKey.Dialog
                  ref={refPGPDialog}
                  onSave={onNewPGPKey}
                  sftpaasRecipientID={recipient.ID}
                />
              </>
            ) : (
              <small>Recipient must be saved before setting a PGP key.</small>
            )}
          </Grid>
        )}
      </Grid>
      <hr />

      {!!ExtraItems && ExtraItems}

      <DialogConfirmHostKeyInfo
        hostKeyInfo={hostKeyInfo}
        doClose={() => {
          setHostKeyInfo(null)
        }}
        onConfirm={() => {
          setRecipient({
            ...recipient,
            HostKeyFingerprint: hostKeyInfo.HostKeyFingerprint,
          })
          setHostKeyInfo(null)
        }}
      />
    </>
  )
}
