import React, { useState, useEffect } from 'react'
import * as api from '../../../services/thezerocard/api-helper'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { IconButton } from '@material-ui/core'
import { DeveloperMode as IconDeveloperMode } from '@material-ui/icons'
import styled from 'styled-components'

const StyledDisplay = styled.div`
  padding: 1rem;

  pre {
    counter-reset: line;
  }
  code {
    display: block;
    padding-left: 6ex;
    position: relative;
    &:before {
      counter-increment: line;
      content: counter(line);
      display: inline-block;
      opacity: 0.5;
      position: absolute;
      left: 0;
    }
    &:empty {
      display: none;
    }
  }
`

interface props {
  uuid: string
  action: (uuid: string) => Promise<any>
  btnSize?: 'small' | 'medium'
}

export default function EDISourceViewer({
  uuid,
  action = (uuid: string) =>
    Promise.reject('No action provided for EDISourceViewer'),
  btnSize = 'medium',
}: props): React.ReactElement {
  const { catchAPIError } = useErrorHandlers()
  const [source, setSource] = useState<string | null>(null)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (isOpen) return
    setSource(null)
  }, [isOpen])

  function loadSourceEDI() {
    action(uuid)
      .then((res: any) => {
        if (res.error) throw res
        const edi = res.Data?.Source.split('~').map(
          (s: string, idx: number): any => (
            // @ts-ignore
            <code key={`[${idx}]${s}`}>{s}~</code>
          )
        )
        setSource(edi)
        setIsOpen(true)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading EDI',
          withError(err: any) {
            console.log(err) /* @todo */
          },
        })
      )
  }

  return (
    <>
      <DesignSuite2023.Tooltip title="View raw EDI">
        <IconButton
          size={btnSize}
          onClick={(ev: any) => {
            ev.stopPropagation()
            loadSourceEDI()
          }}>
          <IconDeveloperMode fontSize="inherit" />
        </IconButton>
      </DesignSuite2023.Tooltip>

      <DesignSuite2023.Drawer open={isOpen} onClose={() => setIsOpen(false)}>
        <StyledDisplay>
          <DesignSuite2023.StyledPre>{source}</DesignSuite2023.StyledPre>
        </StyledDisplay>
      </DesignSuite2023.Drawer>
    </>
  )
}

export function EDISourceViewerActionLoadClaim(uuid: string) {
  return api.get(`/zclaims/tools/claim_edi_source/${uuid}`)
}

export function EDISourceViewerActionLoadSourceFile(uuid: string) {
  return api.get(`/zclaims/tools/source_file_edi_source/${uuid}`)
}
