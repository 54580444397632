import * as api from '../services/thezerocard/api-helper'
export const SAVE_CONTACT = 'SAVE_CONTACT'
export const SAVE_NEW_CONTACT = 'SAVE_NEW_CONTACT'
export const DELETE_CONTACT = 'DELETE_CONTACT'
export const DELETE_CONTACTS = 'DELETE_CONTACTS'
export const GET_CONTACT = 'GET_CONTACT'
export const FIND_CONTACTS = 'FIND_CONTACTS'

export const findContacts = (params) => {
  return {
    type: FIND_CONTACTS,
    payload: api.search('/contact', params),
  }
}

export const getContact = (params) => {
  const { contactId } = params

  return {
    type: GET_CONTACT,
    payload: api.get(`/contact/${contactId}`),
  }
}

export const saveContact = (body) => {
  const { ID } = body
  return {
    type: SAVE_CONTACT,
    payload: api.put(`/contact/${ID}`, body),
  }
}

export const saveNewContact = (body) => {
  return {
    type: SAVE_NEW_CONTACT,
    payload: api.post('/contact', body),
  }
}

export const deleteContact = (params) => {
  const { contactId } = params
  return {
    type: DELETE_CONTACT,
    payload: api.del(`/contact/${contactId}`),
  }
}

export const deleteContacts = (ids) => {
  return {
    type: DELETE_CONTACTS,
    payload: api.del('/contact', ids),
  }
}

export const getContactV2 = (id) => {
  return api.get(`/contact/${id}`)
}

export const deleteContactsV2 = (ids) => {
  return api.del('/contact', ids)
}

export const saveContactV2 = (body) => {
  const { ID } = body
  return api.put(`/contact/${ID}`, body)
}
