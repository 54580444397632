import React from 'react'
import styled from 'styled-components'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { Typography, IconButton } from '@material-ui/core'

export const StyledCustomGrid = styled.div<{
  templateColumns?: string
  colGap?: string
  rowGap?: string
}>`
  display: grid;
  grid-template-columns: ${(props: any) =>
    props.templateColumns || 'repeat(5, 1fr)'};
  grid-column-gap: ${(props: any) => props.colGap || '0.5rem'};
  grid-row-gap: ${(props: any) => props.rowGap || '0.15rem'};

  .full-width {
    grid-column: 1 / -1;
  }
  hr.row-spacer {
    grid-column: 1 / -1;
    height: 0.75rem;
    width: 100%;
    visibility: hidden;
    margin: 0;
    padding: 0;
  }
  h4 {
    margin: 0 0 0.5rem;
  }

  .small-caption {
    max-width: 520px;
    line-height: 1.3;
    font-size: 65%;
  }
`

export const StyledSpanBlock = styled.span<{
  colorAlert?: boolean
  colorOK?: boolean
}>`
  display: block;
  position: relative;
  ${(props: any) => props.colorAlert && 'color: firebrick;'}
  ${(props: any) => props.colorOK && 'color: green;'}
`

export const StyledBtnEdit = styled(
  React.forwardRef(({ ...spread }: any, ref: any) => (
    <IconButton size="small" {...spread} ref={ref}>
      <DesignSuite2023.CommonIcons.IconEdit fontSize="small" />
    </IconButton>
  ))
)`
  position: absolute;
  top: -4px;
`

export const StyledTextLabel = styled(
  React.forwardRef((props: any, ref: any) => (
    <Typography
      component="div"
      variant="subtitle2"
      color="textSecondary"
      {...props}
      ref={ref}>
      {props.children}
    </Typography>
  ))
)`
  position: relative;
`

export const StyledTextValue = React.forwardRef((props: any, ref: any) => (
  <Typography component="div" variant="body2" {...props} ref={ref}>
    {props.children}
  </Typography>
))

export const StyledLoader = styled.div`
  text-align: center;

  .loader {
    padding: 1rem;
    margin: 0 auto;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    strong {
      display: inline-block;
    }
  }
`
