import * as api from '../../../services/thezerocard/api-helper'
import React from 'react'
import DetailView, {
  defaultStyleCreator,
} from '../../../components/DetailView/DetailView'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { OrgActions, SnackbarActions } from '../../../actions'
import _ from 'lodash'
import RenderInstructions from './Instructions'
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import ErrantLines from './ErrantLines'

const { setSnackbarMessage } = SnackbarActions
const { getOrganization } = OrgActions

const styles = (theme) => {
  return Object.assign(
    {
      hr: {
        margin: 0,
      },
      contentPadding: {
        padding: 20,
      },
      fileInfo: {
        position: 'relative',
      },
      shipIt: {
        '& button': {
          marginTop: '0.5rem',
          display: 'block',
        },
      },
      feedback: {
        marginTop: '0.5rem',
      },
      showProcessing: {
        padding: '1rem',
        textAlign: 'center',
      },
    },
    defaultStyleCreator(theme)
  )
}

export class PriceImport extends DetailView {
  constructor(props) {
    super(props)
    this.refFilePicker = React.createRef()
    this._fileObj = null
    this.state = this.initialState = {
      orgID: this.props.match.params.id,
      dateStart: null,
      errors: [],
      plans: [],
      errantLines: [],
      report: null,
      fileInfo: null,
      processing: false,
      currTab: 0,
    }
  }

  componentDidMount = () => {
    getOrganization(this.state.orgID)
      .then((res) => {
        this.setState({ org: res, orgID: res.ID })
      })
      .catch((e) => {
        console.error('Failed to retrieve organization for import', e)
        this.props.setSnackbarMessage(
          _.get(
            e,
            'Error.Message',
            'Unable to retrieve organization for import.'
          ),
          'error'
        )
      })
  }

  resetState = (andMerge = {}) => {
    this.setState({ ...this.initialState, ...andMerge })
  }

  resetFileInput = () => {
    this._fileObj = this.refFilePicker.current.value = null
  }

  onSelectFile = () => {
    this._fileObj = this.refFilePicker.current.files[0]
    this.resetState({
      fileInfo: {
        name: this._fileObj.name,
        size: this._fileObj.size,
        lastMod: new Date(this._fileObj.lastModified).toISOString(),
        type: this._fileObj.type,
      },
    })
  }

  onSetDateStart = (ev) => {
    this.setState({ dateStart: ev.target.value })
  }
  onClickPlan = () => {
    this.send({ persist: false })
  }
  onClickSend = () => {
    this.send({ persist: true })
  }
  onClickReset = () => {
    this.resetFileInput()
    this.resetState()
  }

  send = ({ persist }) => {
    this.setState({ processing: true })
    const file = this._fileObj,
      data = new FormData(),
      params = {
        persist: persist === true,
        dateStart: this.state.dateStart,
      }
    data.append('file', file, file.name)
    data.append('instructions', JSON.stringify(params))
    api
      .postFormData(`/organization/${this.state.orgID}/price_import`, data)
      .then((res) => {
        this.setState({ processing: false })
        if (res.Error) {
          throw res
        }
        const plans = _.get(res, 'Data.Plans', [])
        const errantLines = _.get(res, 'Data.ErrantLines', [])
        if (plans.length) {
          return this.setState({ plans, errantLines })
        }
        this.setState({ report: res.Data })
      })
      .catch((e) => {
        const list = _.get(e, 'errList', [])
        this.resetFileInput()
        this.resetState({ errors: list })
        this.props.setSnackbarMessage(
          _.get(e, 'Error.Message', 'Error occurred during processing'),
          'error'
        )
      })
  }

  _renderOrganizationLink = (id, name) => {
    const href = `${window.location.origin}/organization/${id}`
    return (
      <a target="_blank" rel="noopener noreferrer" href={href}>
        {name}
      </a>
    )
  }

  _renderPlans = () => {
    const { classes } = this.props
    const { fileInfo, plans } = this.state
    if (!plans.length) {
      return
    }
    return (
      <TableContainer component={Paper} className={classes.feedback}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Practice Facility</TableCell>
              <TableCell align="right">Price Count</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plans.map((pl, index) => {
              return (
                <TableRow key={`${index}.${fileInfo.name}.${fileInfo.lastMod}`}>
                  <TableCell component="th" scope="row">
                    {pl.PracticeFacilities.map((pf) => {
                      return <div key={`${pf.ID}:pl.PriceCount`}>{pf.Name}</div>
                    })}
                  </TableCell>
                  <TableCell align="right">{pl.PriceCount}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  _renderErrors = () => {
    const { classes } = this.props
    const { errors } = this.state
    if (!errors.length) {
      return
    }
    return (
      <TableContainer component={Paper} className={classes.feedback}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1" color="error">
                  Errors ({errors.length})
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {errors.map((msg) => {
              return (
                <TableRow>
                  <TableCell>{msg}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  _renderErrantLines = () => {
    if (!this.state.errantLines || !this.state.errantLines.length) {
      return null
    }
    return (
      <>
        <ErrantLines
          errantLines={this.state.errantLines}
          fileName={this.state.fileInfo.name}
        />
      </>
    )
  }

  _renderTabbedContent = () => {
    const { currTab, errantLines, processing } = this.state
    const tabs = [
      {
        label: 'Summary',
        display: () => {
          return (
            <>
              {this._renderErrors()}
              {!processing && this._renderPlans()}
            </>
          )
        },
        show: true,
      },
      {
        label: `Failed Lines (${errantLines?.length || 0})`,
        display: () => {
          return this._renderErrantLines()
        },
        show: !!errantLines?.length,
      },
    ]
    return (
      <DesignSuite2023.Section>
        <DesignSuite2023.StyledTabs value={this.state.currTab}>
          {tabs.map((tab, index) => {
            return tab.show ? (
              <DesignSuite2023.StyledTab
                key={index}
                label={tab.label}
                onClick={() => this.setState({ ...this.state, currTab: index })}
              />
            ) : null
          })}
        </DesignSuite2023.StyledTabs>
        {tabs[currTab] && tabs[currTab].display()}
      </DesignSuite2023.Section>
    )
  }

  _renderReport = () => {
    const { classes } = this.props
    const { report } = this.state

    return (
      <Grid item xs={12} className={classes.contentPadding}>
        <Typography variant="h6">Success!</Typography>
        <Typography variant="body2" paragraph>
          Created <strong>{report.CountNewFeeSchedules}</strong> fee schedules.
          All fee schedules are in Draft status and will need to be subsequently
          actived.
        </Typography>
        <Card raised>
          <CardContent>
            <Typography variant="caption">
              <strong>ImportID:</strong> {report.Import.ID}
              <br />
              <strong>Name:</strong> {report.Import.Name}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    )
  }

  setRecordAdapter = ({ name, value }) => {
    this.setState({ [name]: value })
  }

  _renderImport = () => {
    const { classes } = this.props
    const { errors, plans, report, fileInfo, dateStart, processing } =
      this.state
    const { setRecordAdapter } = this

    return (
      <Grid item xs={12} className={classes.contentPadding}>
        {fileInfo ? (
          <Card variant="outlined">
            <CardContent className={classes.fileInfo}>
              <DesignSuite2023.GridLR
                left={
                  <>
                    <Typography variant="h6">Import File Info</Typography>
                    <Typography variant="subtitle2">{fileInfo.name}</Typography>
                    <Typography variant="caption">
                      Size: {fileInfo.size}
                    </Typography>
                    <br />
                    <Typography variant="caption">
                      Last Modified: {fileInfo.lastMod}
                    </Typography>
                    <br />
                    <Typography variant="caption">
                      Type: {fileInfo.type}
                    </Typography>
                  </>
                }
                right={
                  <>
                    <div style={{ textAlign: 'right' }}>
                      <Button
                        size="small"
                        color="primary"
                        onClick={this.onClickReset}>
                        Reset
                      </Button>
                    </div>
                    <div className={classes.shipIt}>
                      {/* {renderDateField({
                      name: 'dateStart',
                      label: 'Start Date',
                      value: this.state.dateStart,
                      setter: setRecordAdapter
                    })} */}
                      <ManagedDateInput
                        name="dateStart"
                        label="Start Date"
                        value={this.state.dateStart}
                        setter={setRecordAdapter}
                      />
                      <Button
                        variant="outlined"
                        size="small"
                        fullWidth
                        onClick={this.onClickPlan}
                        disabled={!dateStart || !!errors.length}>
                        Dry Run (Get Plans)
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        fullWidth
                        onClick={this.onClickSend}
                        disabled={!plans.length || !!errors.length}>
                        Do Import
                      </Button>
                    </div>
                  </>
                }
              />
            </CardContent>
          </Card>
        ) : null}
        <div style={{ display: fileInfo ? 'none' : 'block' }}>
          <Button
            variant="contained"
            component="label"
            color="primary"
            fullWidth>
            Select File
            <input
              style={{ display: 'none' }}
              type="file"
              ref={this.refFilePicker}
              onChange={this.onSelectFile}
              accept=".csv"
            />
          </Button>
        </div>
        {processing ? (
          <div className={classes.showProcessing}>
            <CircularProgress />
          </div>
        ) : null}
        {!!(plans.length || errors.length) && this._renderTabbedContent()}
        {!errors.length && !fileInfo && !report && !processing && (
          <RenderInstructions />
        )}
      </Grid>
    )
  }

  render() {
    const { org } = this.state
    const { classes } = this.props
    const { report } = this.state

    return (
      <Paper elevation={2} style={{ maxWidth: 1200, margin: '0 auto' }}>
        {!org || !org.ID ? (
          <div>Loading...</div>
        ) : (
          <Grid container>
            <Grid item xs={12} className={classes.contentPadding}>
              <Card raised>
                <CardContent>
                  <Typography variant="h6">
                    {this._renderOrganizationLink(org.ID, org.Name)}
                  </Typography>
                  <Typography variant="caption">
                    <strong>ID:</strong> {org.ID}
                  </Typography>
                  <br />
                  <Typography variant="caption">
                    {org.IsActive
                      ? 'Organization is currently active'
                      : 'Organization is NOT currently active'}
                  </Typography>
                  <br />
                  <Typography variant="caption">
                    {org.AcceptingReferrals
                      ? 'Organization is currently accepting referrals in GoZero'
                      : 'Organization is NOT currently accepting referrals in GoZero'}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Divider className={classes.hr} />
            </Grid>
            {report ? this._renderReport() : this._renderImport()}
          </Grid>
        )}
      </Paper>
    )
  }
}

export default compose(
  withStyles(styles),
  withRouter,
  connect(null, function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setSnackbarMessage }, dispatch)
  })
)(PriceImport)
