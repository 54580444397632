import React, { ReactElement, useState } from 'react'
import {
  RenderSelectField2,
  SelectItem,
} from '../../../components/Inputs/standard'

import * as ZClaimsEmployerInvoiceTable from './zClaimsEmployerInvoiceTable'
import InvoiceTable from '../../Invoices/InvoiceTable'

export interface UnifiedInvoicesProps {
  EmployerID: number
}

export default function UnifiedInvoices({
  EmployerID,
}: UnifiedInvoicesProps): ReactElement {
  const [source, setSource] = useState('zclaims')

  const options: SelectItem[] = [
    { label: 'ZClaims', value: 'zclaims' },
    { label: 'Legacy', value: 'legacy' },
  ]

  return (
    <>
      <RenderSelectField2
        name={'Source'}
        label={'Source'}
        value={source}
        setter={({ value }) => setSource(value)}
        items={options}
      />
      {source === 'legacy' && (
        <InvoiceTable
          disableSearch
          passFilters={{
            withFiles: true,
            employerID: EmployerID,
          }}
        />
      )}

      {source === 'zclaims' && (
        <ZClaimsEmployerInvoiceTable.Table EmployerID={EmployerID} />
      )}
    </>
  )
}
