import React, { useState, useEffect, useCallback } from 'react'
import _ from 'lodash'
import {
  Grid,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import {
  Add as IconAdd,
  HighlightOff as IconRemove,
  CheckBox as IconCheckBox,
} from '@material-ui/icons'
import './styles.css'
import useErrorHandlers from '../../hooks/useErrorHandlers'

interface Props {
  fnSearchUsersToAdd(params: any): Promise<any>
  onConfirm: (_: any) => void
  inGroupChecker?(userID: number): any
}

export default (props: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [searchQ, setSearchQ] = useState<string>('')
  const [userSearchResults, setUserSearchResults] = useState<Array<any>>([])
  const [selectedUsers, setSelectedUsers] = useState<Array<any>>([])
  const [selectedUsersIDMap, setSelectedUsersIDMap] = useState({})
  const { catchAPIError } = useErrorHandlers()

  const doSearchUsers = useCallback(
    _.debounce((v: any) => {
      if (!v || v.length <= 2) {
        return
      }
      // @ts-ignore
      props
        .fnSearchUsersToAdd({ q: v })
        .then((r: any) => {
          if (r?.error) throw new Error(r)
          setUserSearchResults(r.Data)
        })
        .catch(catchAPIError({ defaultMessage: 'User search failed' }))
    }, 500),
    []
  )

  const onTextInput = (ev: any) => {
    setSearchQ(ev.target.value)
    doSearchUsers(ev.target.value)
  }

  const onClickAdd = (u: any) => {
    for (let i = 0; i < selectedUsers.length; i++) {
      if (selectedUsers[i].ID === u.ID) {
        console.log('User already staged for adding...')
        return
      }
    }
    setSelectedUsers([u, ...selectedUsers])
    setSelectedUsersIDMap(
      Object.assign({}, selectedUsersIDMap, {
        [u.ID]: true,
      })
    )
  }

  const onClickRemove = (u: any) => {
    setSelectedUsers(
      selectedUsers.filter((v: any) => {
        return v.ID !== u.ID
      })
    )
    const mapped = Object.assign({}, selectedUsersIDMap)
    // @ts-ignore
    delete mapped[u.ID]
    setSelectedUsersIDMap(mapped)
  }

  const onConfirm = () => {
    setIsOpen(false)
    props.onConfirm(selectedUsers)
  }

  const onClearSearch = () => {
    setSearchQ('')
    setUserSearchResults([])
  }

  // when re-opening the dialog, always reset its state back to empty
  useEffect(() => {
    if (isOpen) {
      setSearchQ('')
      setUserSearchResults([])
      setSelectedUsers([])
      setSelectedUsersIDMap({})
    }
  }, [isOpen])

  return (
    <>
      {/* @todo: yuck; these styles shouldn't be associated w/ this button here, but
    this was a quick cleanup to address SD-1718. @todo: see where this component is
    used and allow either injecting 'TriggerButtonStyle' prop, or just styling it in
    the parent */}
      <Button
        style={{ float: 'right', marginTop: 15, marginLeft: 15 }}
        color="primary"
        variant="contained"
        onClick={() => {
          setIsOpen(true)
        }}>
        Add Existing User
      </Button>

      <Dialog
        className="dialog-user-picker-searcher-thing"
        maxWidth="lg"
        open={isOpen}>
        <DialogTitle style={{}}>Manage Users: goZERO</DialogTitle>
        <DialogContent>
          <TextField
            label="User Search"
            variant="outlined"
            fullWidth
            value={searchQ}
            onChange={onTextInput}
            style={{ marginBottom: '1rem' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="clear search" onClick={onClearSearch}>
                    <IconRemove />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Grid container spacing={2}>
            {!!userSearchResults.length ? (
              <Grid item xs="auto">
                <h5>Search Results:</h5>
                {userSearchResults.map((u: any) => {
                  // @ts-ignore: use already selected to add, so don't show them in results list
                  if (selectedUsersIDMap[u.ID]) {
                    return null
                  }
                  if (props.inGroupChecker && props.inGroupChecker(u.ID)) {
                    return (
                      <label key={u.ID} className="pick-user">
                        <div style={{ padding: '0 0.75rem' }}>
                          <IconCheckBox color="primary" />
                        </div>
                        <div>
                          <span>
                            {u.FirstName} {u.LastName}{' '}
                            <span className="display-email">({u.Email})</span>
                          </span>
                          <small>Already in group</small>
                        </div>
                      </label>
                    )
                  }
                  return (
                    <label
                      key={u.ID}
                      onClick={onClickAdd.bind(null, u)}
                      className="pick-user">
                      <div>
                        <IconButton>
                          <IconAdd />
                        </IconButton>
                      </div>
                      <div>
                        {u.FirstName} {u.LastName}{' '}
                        <span className="display-email">({u.Email})</span>
                      </div>
                    </label>
                  )
                })}
              </Grid>
            ) : null}

            {!!selectedUsers.length ? (
              <Grid item xs="auto">
                <h5>Users Being Added:</h5>
                {!!selectedUsers.length ? (
                  <div>
                    {selectedUsers.map((u: any) => {
                      return (
                        <label
                          key={u.ID}
                          onClick={onClickRemove.bind(null, u)}
                          className="pick-user">
                          <div>
                            <IconButton>
                              <IconRemove />
                            </IconButton>
                          </div>
                          <div>
                            {u.FirstName} {u.LastName}{' '}
                            <span className="display-email">({u.Email})</span>
                          </div>
                        </label>
                      )
                    })}
                  </div>
                ) : null}
              </Grid>
            ) : null}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button
                color="secondary"
                onClick={() => {
                  setIsOpen(false)
                }}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                color="primary"
                onClick={onConfirm}
                disabled={selectedUsers.length === 0}>
                Add ({selectedUsers.length} Users)
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}
