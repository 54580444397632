import util from './utils'
import { FeeScheduleActions, CLEAR_SAVE_RESULT } from '../actions'
const {
  GET_NEW_FEE_SCHEDULE,
  RESET_FEE_SCHEDULE_STATE,
  SAVE_FEE_SCHEDULE_ENTITIES,
  APPLY_TO_ORGANIZATION,
} = FeeScheduleActions

export default function (state = null, action) {
  switch (action.type) {
    case GET_NEW_FEE_SCHEDULE:
      return {
        ...state,
        feeSchedule: {},
      }
    case SAVE_FEE_SCHEDULE_ENTITIES:
    case APPLY_TO_ORGANIZATION:
      if (action.payload.error) {
        return {
          ...state,
          saveResult: util.errorResponse(action),
        }
      }
      return {
        ...state,
        saveResult: {
          saved: true,
          type: action.type,
        },
      }
    case CLEAR_SAVE_RESULT:
      return {
        ...state,
        saveResult: null,
      }
    case RESET_FEE_SCHEDULE_STATE:
      return {
        ...state,
        feeSchedules: [],
        saveResult: null,
        getFeeScheduleError: null,
      }
    default:
      return { ...state }
  }
}
