import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import * as T from './types'
import * as api from '../../services/thezerocard/api-helper'
import ReferrerModel from '../../models/Referrer'

export const useReferrerForm = (referrerID: number | null) => {
  const history = useHistory()
  const [attemptedSave, setAttemptedSave] = useState(false)
  const snackbar = useSnackbar()

  const form = useForm<T.ReferrerForm>(
    {
      Name: '',
      Website: '',
      Owner: '',
      Notes: '',
      DefaultGoZeroAccessGroupID: null,
    },
    ReferrerModel.validators
  )

  const save = async () => {
    setAttemptedSave(true)
    if (form.isValid()) {
      const res: any = referrerID
        ? await api.put(`/referrer/${referrerID}`, form.data)
        : await api.post(`/referrer`, form.data)
      if (res.error) {
        snackbar.show('Failed saving referrer', SnackbarTypeError)
        return
      }
      snackbar.show(
        `Success ${referrerID ? 'saving' : 'creating'} referrer`,
        SnackbarTypeSuccess
      )
      if (!referrerID) {
        history.push(`/referrer/${res.Data.ID}`)
      }
    }
  }

  return { form, save, errors: attemptedSave ? form.errors : {} }
}
