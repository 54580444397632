import { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { getFilesForEmployer } from '../../../actions/EmployerActions'

export interface FileImportProps {
  employerID: number
}

export interface SelectFileHandlerConfig {
  employerID: number
  fnResetAll(isProcessing: boolean): void
  actioner(params: any): Promise<any>
  withResults?(data: any): void
}

export const useImportFile = () => {
  const refFileInput = useRef<HTMLInputElement>(null)
  const [processing, setProcessing] = useState(false)
  const [errors, setError] = useState<any>()
  const [result, setResult] = useState<any>(null)

  const baseReset = (isProcessing: boolean) => {
    setProcessing(isProcessing)
    setError(null)
    setResult(null)
  }

  const onSelectFileHandler = (params: SelectFileHandlerConfig) => {
    return () => {
      const { employerID, actioner, withResults, fnResetAll } = params
      fnResetAll(true)
      const files = refFileInput.current && refFileInput.current.files
      if (!files) {
        return
      }
      const file = files[0]
      const formData = new FormData()
      formData.append(`file`, file, file.name)
      actioner({ employerID, formData })
        .then(({ Data, errList, error }: any) => {
          if (errList) {
            setError(errList)
            return
          }
          if (error) {
            setError(['Unknown error occurred. File was not processed...'])
            return
          }
          setResult(Data)
          withResults && withResults(Data)
        })
        .catch((e: Error) => {
          alert(
            'An error occurred we did not know how to handle. Please contact engineering.'
          )
          setError(e)
        })
        .finally(() => {
          setProcessing(false)
          if (refFileInput.current) {
            // @ts-ignore
            refFileInput.current.value = null
          }
        })
    }
  }

  return {
    refFileInput,
    processing,
    errors,
    result,
    baseReset,
    onSelectFileHandler,
  }
}

export const useRefreshFiles = (employerId: number) => {
  const dispatch = useDispatch()
  return () => {
    dispatch(getFilesForEmployer({ employerId }))
  }
}
