import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { OpportunityAnalysisActions } from '../../actions'
import queryString from 'query-string'

import DetailView, {
  defaultStyleCreator,
} from '../../components/DetailView/DetailView'
import ChildTable from '../../components/ChildTable/ChildTable'
import SummaryDetail from './SummaryDetail'
import OpportunityAnalysisSearchBar from './OpportunityAnalysisSearchBar'
import Models from '../../models'

import { Paper, Divider } from '@material-ui/core'

const { getGroupsToReview } = OpportunityAnalysisActions
const styles = (theme) => {
  const defaults = defaultStyleCreator(theme)

  return Object.assign(defaults, {
    field: {
      width: 200,
    },
  })
}

export class OpportunityAnalysis extends DetailView {
  constructor(props) {
    super(props)
    const { location } = props
    const params = queryString.parse(location.search)

    this.state = {
      searching: false,
      selectedSummaryId: 0,
      params,
      error: '',
    }
  }

  handleRowSelect = (row, _isSelected, _e) => {
    if (row && row !== null) {
      this.setState({
        selectedSummaryId: row.ID,
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.results.length) {
      const currentRow = nextProps.results.find(
        (r) => r.ID === this.state.selectedSummaryId
      )

      if (!currentRow) {
        this.setState({
          selectedSummaryId: nextProps.results[0].ID,
        })
      }
    }
  }

  renderResults() {
    const options = {
      sizePerPageList: [
        {
          text: '5',
          value: 5,
        },
        {
          text: '10',
          value: 10,
        },
        {
          text: '50',
          value: 50,
        },
      ],
    }

    const selectRow = {
      mode: 'radio', // multi select
      clickToSelect: true,
      selected: [this.state.selectedSummaryId],
      onSelect: this.handleRowSelect,
      bgColor: '#5cb85c',
    }

    const noDataText = 'No results.'

    return (
      <ChildTable
        columns={Models.OpportunityAnalysis.columns}
        rows={this.props.results}
        trClassName={this.rowFormatter}
        loading={this.state.searching}
        options={options}
        noDataText={noDataText}
        selectRow={selectRow}
        pagination
        search
      />
    )
  }

  searchForGroups = (params, opts) => {
    if (params.costKey && params.fileClaimsId === -1) {
      return this.setState({
        error:
          'Failed validation: A file *must* be selected when using cost key filter not valid',
      })
    }
    const show =
      opts.showLoadingIndicator !== undefined ? opts.showLoadingIndicator : true
    this.setState({ searching: show, error: '' })
    this.props.getGroupsToReview(params).then((res) => {
      this.setState({ searching: false })
    })
  }

  render() {
    const { classes, history, getError } = this.props
    const { params, error, selectedSummaryId } = this.state

    return (
      <div>
        <Paper className={classes.mainContent} elevation={2}>
          <OpportunityAnalysisSearchBar
            search={this.searchForGroups}
            params={params}
            history={history}
          />
          <Divider className={classes.sectionDivider} />
          {getError || error
            ? this.renderError(error || getError.message)
            : this.renderResults()}
          <br />
          <SummaryDetail summaryId={selectedSummaryId} />
        </Paper>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    results: state.opportunityAnalysis.results || [],
    saveResult: state.opportunityAnalysis.saveResult || {},
    getError: state.opportunityAnalysis.getError,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getGroupsToReview }, dispatch)
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(OpportunityAnalysis)
