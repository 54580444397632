import React, { useState } from 'react'
import { postMoveClaimsToFundingInProcess } from '../../../actions/ZClaimActions'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import { Button } from '@material-ui/core'
import DesignSuite2023 from '../../../components/DesignSuite2023'

interface props {
  zClaimIDs: Array<number>
  onComplete?(): void
  before?(): Promise<any>
  disabled?: boolean
  tooltip?: string
  label?: string
}

export default React.forwardRef(function ButtonMoveToFunding(
  {
    zClaimIDs,
    onComplete,
    before = () => Promise.resolve(),
    disabled = false,
    tooltip = 'Move the selected claims to funding flow ("in-process")',
    label = 'Mark Funding/In-Process',
  }: props,
  ref: any
): React.ReactElement {
  const [isWorking, setIsWorking] = useState(false)
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  function doAdjudicate() {
    setIsWorking(true)

    return before()
      .then(() => postMoveClaimsToFundingInProcess({ zClaimIDs }))
      .then(() => {
        showSnackbar('Adjudication completed OK', SnackbarTypeSuccess)
        onComplete?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed adjudicating claim(s)',
        })
      )
      .finally(() => setIsWorking(false))
  }

  return (
    <DesignSuite2023.Tooltip title={tooltip}>
      <span>
        <Button
          ref={ref}
          disabled={isWorking || disabled}
          fullWidth
          variant="contained"
          color="primary"
          size="small"
          onClick={doAdjudicate}>
          {label}
          {isWorking && (
            <>
              &nbsp;
              <DesignSuite2023.LoadingSpinner size={20} show={isWorking} />
            </>
          )}
        </Button>
      </span>
    </DesignSuite2023.Tooltip>
  )
})
