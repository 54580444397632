export interface BillingPlan {
  ID?: number
  Name: string
  CreatedAt?: string
  UpdatedAt?: string
  DeletedAt?: string
  CreatedByUserID?: string
  FeeSchedulePriceID?: number
  Notes: string
  Components?: Component[]
  BillingPlanModifiedByUser?: {
    Email: string
    FirstName: string
    LastName: string
  }
}

interface Component {
  ID: number
  UpdatedAt: string
  Type: string
  Name: string
  ComponentModifiedByUser: {
    FirstName: string
    LastName: string
    Email: string
  }
  Lines: ComponentLine[]
}

interface ComponentLine {
  CPTCode: string
  DRGCode?: string
  Price: string
  Units: number
  Notes: string
  ComponentLineID: number
}

interface BillingPlanModel {
  columns: cols
  hasAccess: any
  validators: {
    [key: string]: { isValid: (val: any, fields: any) => boolean; msg: string }
  }
}

interface col {
  name: string
  details?: any
}

interface cols {
  Name: col
  Notes: col
}

export const Model: BillingPlanModel = {
  columns: {
    Name: {
      name: 'Name',
      details: {},
    },
    Notes: {
      name: 'Notes',
      details: {},
    },
  },
  validators: {
    Name: {
      isValid: (val: any, fields: any) => !((val || '').length === 0),
      msg: 'Name is required',
    },
  },
  hasAccess: () => true, //utils.checkPerms(READ_PERMISSION)
}
