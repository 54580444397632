import React, { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import PageInvoiceableView from './viewInvoiceable'
import PageInvoicedView from './viewInvoiced'
import { useSetFrameHeaderMid } from '../../../components/AppFrame'
import useQueryParamsGen2 from '../../../hooks/useQueryParamsGen2'

const SUB_VIEW_INVOICEABLE = 'invoiceable'
const SUB_VIEW_INVOICED = 'invoiced'

export default function PageInvoicing(): React.ReactElement {
  const { queryData, setQueryData } = useQueryParamsGen2({
    scope: 'zclaims.invoicing.subview',
  })
  const [subView, setSubView] = useState(
    queryData['subView'] || SUB_VIEW_INVOICEABLE
  )

  useEffect(() => {
    setQueryData({ subView })
  }, [subView])

  useSetFrameHeaderMid(() => {
    return (
      <>
        <Button
          size="small"
          variant={subView === SUB_VIEW_INVOICEABLE ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_INVOICEABLE)}>
          Invoiceable
        </Button>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <Button
          size="small"
          variant={subView === SUB_VIEW_INVOICED ? 'contained' : 'text'}
          onClick={() => setSubView(SUB_VIEW_INVOICED)}>
          Invoiced
        </Button>
      </>
    )
  }, [subView])

  switch (subView) {
    case SUB_VIEW_INVOICEABLE:
      return <PageInvoiceableView />

    case SUB_VIEW_INVOICED:
      return <PageInvoicedView />
  }

  return (
    <span>Invalid state: try clicking To Invoice or Invoiced above...</span>
  )
}
