import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import Models from '../../models'
import { DataTable } from '../../components/DataTable'
import QSearcher from '../../components/QSearcher'
import { PartnerActions } from '../../actions'
import { defaultStyleCreator } from '../../components/DetailView/DetailView'
import _ from 'lodash'

const styles = (theme: any) => {
  const defaults = defaultStyleCreator(theme)
  return Object.assign(defaults, {
    searchField: {
      display: 'inline-flex',
      marginTop: '16px',
    },
  })
}

interface Props {
  initParams: {
    filter?: any
    page?: any
    pageSize?: any
    sortable?: any
  }
  location: {
    search?: any
  }
  history: {
    push?: any
  }
  autoFocus: boolean
  getPartners?: any
  persistSearch?: any
  count?: number
  loading?: boolean
  searchError?: any
  classes: {
    searchField?: any
  }
  page?: number
  pageSize?: number
  partners?: any
}

interface State {
  page: number
  pageSize: number
  sortable: {
    col: string
    dir: string
  }
  initialized: boolean
  loading: boolean
  filter: {
    q: string
  }
  partners: any
  employers?: any
}

class PartnerTable extends React.Component<Props, State> {
  static defaultProps: Props

  state: State = {
    page: 1,
    pageSize: 10,
    sortable: { col: 'Partner.Name', dir: 'asc' },
    initialized: false,
    loading: false,
    filter: {
      q: '',
    },
    partners: [],
  }

  UNSAFE_componentWillMount() {
    const { filter, page, pageSize, sortable, initialized } = this.state
    const { initParams } = this.props

    if (!initialized) {
      if (!_.isEmpty(initParams)) {
        this.findPartners({ ...initParams })
        // @ts-ignore
        this.setState({ ...initParams })
      } else {
        this.findPartners({ filter, page, pageSize, sortable })
      }

      this.setState({ initialized: true })
    }
  }

  handleRowClick = (_: any, { ID }: any, cmdClicked: boolean) => {
    const redirectPath = `/partner/${ID}`
    if (cmdClicked) {
      window.open(redirectPath, `_blank`)
      return
    }
    this.props.history.push({
      pathname: redirectPath,
      state: { prevSearch: this.props.location.search },
    })
  }

  handleQueryChange = (q: string) => {
    const { filter, sortable } = this.state
    filter.q = q
    this.setState({ filter })
    this.findPartners({ filter, page: 1, pageSize: 10, sortable })
  }

  findPartners = ({ filter, page, pageSize, sortable }: any) => {
    this.setState({ loading: true })
    if (filter.q.length === 0 || filter.q.length > 2) {
      this.props.persistSearch({ filter, page, pageSize, sortable })
      const payload: any = { filter, page, pageSize, sortable }

      this.props.getPartners(payload).then((res: any) => {
        this.setState({ loading: false })
      })
    }
  }

  handleChangePage = ({ page, pageSize }: any) => {
    const { filter, sortable } = this.state
    this.setState({ page, pageSize })
    this.findPartners({ filter, page, pageSize, sortable })
  }

  rowOptsApplier = (row: any) => {
    if (!Models.Partner.isActive(row)) {
      return { className: 'tr-warning' }
    }
    return null
  }

  sortHandler = ({ col, dir }: any) => {
    const sortable = { col, dir }
    this.setState({ sortable })
    const { filter, page, pageSize } = this.state
    this.findPartners({ filter, page, pageSize, sortable })
  }

  getShowFilterKey = (val: any) => {
    let key = 'all'
    switch (val) {
      case null:
        key = 'all'
        break
      case true:
        key = 'is_customer'
        break
      case false:
        key = 'is_prospect'
        break
      default:
        return key
    }

    return key
  }

  getShowFilterValue = (val: any) => {
    let filterValue = null
    switch (val) {
      case 'all':
        filterValue = null
        break
      case 'is_customer':
        filterValue = true
        break
      case 'is_prospect':
        filterValue = false
        break
      default:
        return filterValue
    }

    return filterValue
  }

  handleShowChange = (val: any) => {
    const { filter, sortable } = this.state
    this.setState({ filter })
    this.findPartners({ filter, page: 1, pageSize: 10, sortable })
  }

  renderTable = () => {
    const { searchError, loading, initParams, count, partners } = this.props
    const { page, pageSize } = initParams
    if (!_.isEmpty(initParams) && loading) {
      return <div />
    }
    if (partners && partners.length > 0) {
      return (
        <DataTable
          keyProp="ID"
          sortable={this.state.sortable}
          sortHandler={this.sortHandler}
          columns={Models.Partner.columns}
          data={partners}
          loading={loading}
          onRowClick={this.handleRowClick}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangePage}
          count={count}
          initPage={page * 1}
          initPageSize={pageSize * 1}
          rowOptsApplier={this.rowOptsApplier}
          error={searchError}
        />
      )
    }
    return <div />
  }

  render() {
    const { classes, initParams, autoFocus } = this.props
    const { filter } = initParams
    const q = filter ? filter.q : ''

    return (
      <div>
        <div>
          <QSearcher
            label="Partner Lookup"
            search={this.handleQueryChange}
            // @ts-ignore
            classNames={classes.searchField}
            default={q}
            autoFocus={autoFocus}
          />
          {this.renderTable()}
        </div>
      </div>
    )
  }
}

PartnerTable.defaultProps = {
  page: 1,
  pageSize: 10,
  initParams: {},
  location: {},
  history: {},
  classes: {},
  autoFocus: false,
}

function mapStateToProps(state: State) {
  return {
    partners: state.partners.partners || [],
    count: state.partners.count || 0,
  }
}

const { getPartners } = PartnerActions
function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({ getPartners }, dispatch)
}

export default compose(
  // @ts-ignore
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
  // @ts-ignore
)(PartnerTable)
