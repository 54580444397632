import React, { useState, useEffect, useRef } from 'react'
import { canExecEngineering } from '../../../utils/perms'
import * as FeeScheduleTable from '../../FeeSchedules/FeeScheduleTable2'
import { OrgActions, FeeScheduleActions } from '../../../actions'
import useSnackbar, {
  SnackbarTypeSuccess,
  SnackbarTypeError,
} from '../../../hooks/useSnackbar'
import isNumber from 'lodash/isNumber'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Button,
  Typography,
  Chip,
} from '@material-ui/core'
import dateTime from '../../../utils/dateTime'

interface props {
  organizationID: number | null
}

export default function OrgDetailFeeSchedules({
  organizationID,
}: props): React.ReactElement | null {
  const refTable = useRef()

  function refreshTableView() {
    // @ts-ignore
    refTable.current?.refresh()
  }

  if (!organizationID) {
    return null
  }

  return (
    <>
      <FeeScheduleTable.Table
        ref={refTable}
        passFilters={{ organizationID }}
        LeftHeaderItems={
          <>
            <FeeScheduleTable.StandardFilterSearch autoFocus />
            &nbsp;&nbsp;
            <FeeScheduleTable.FilterStatus />
            &nbsp;&nbsp;
            <Chip label={`OrgID: ${organizationID}`} />
          </>
        }
      />

      {canExecEngineering() && (
        <Grid
          container
          spacing={2}
          justify="space-between"
          alignItems="center"
          style={{ marginTop: '0.75rem' }}>
          <Grid item xs={12} md="auto">
            <BtnManageTerminations
              organizationID={organizationID}
              onTerminations={refreshTableView}
            />
          </Grid>
          <Grid item xs={12} md="auto">
            <BtnManageImports
              organizationID={organizationID}
              onSuccess={refreshTableView}
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}

/*
Dialog for "activate fee schedules for import" button
*/
function BtnManageImports({
  onSuccess,
}: props & Partial<any>): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [importID, setImportID] = useState<number | null>(null)
  const { showDurationLong: showSnackbarDuration } = useSnackbar()

  // reset modal state whenever it (re)opens
  useEffect(() => {
    isOpen && setImportID(null)
  }, [isOpen])

  function onConfirm() {
    FeeScheduleActions.activateImportedFeeSchedules(importID)
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        showSnackbarDuration(
          'Fee schedules moved to production OK',
          SnackbarTypeSuccess
        )
        onSuccess && onSuccess()
      })
      .catch((err: any) => {
        if (err.Error?.Message) {
          showSnackbarDuration(err.Error.Message, SnackbarTypeError)
          return
        }
        showSnackbarDuration('Operation failed', SnackbarTypeError)
      })
      .finally(() => {
        setIsOpen(false)
      })
  }

  function isValid(): boolean {
    return isNumber(importID) && importID >= 1
  }

  return (
    <>
      <Button
        onClick={() => {
          setIsOpen(true)
        }}
        variant="contained"
        color="primary">
        Activate Fee Schedules for import
      </Button>

      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}>
        <DialogTitle>Activate All Draft Fee Schedules?</DialogTitle>
        <DialogContent>
          <Typography>Please input an import id to activate</Typography>
          <div style={{ padding: '0.5rem 0' }}>
            <TextField
              label="Import ID"
              value={importID || ''}
              type="number"
              onChange={(ev: any) => {
                setImportID(ev.target?.valueAsNumber || null)
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  setIsOpen(false)
                }}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                color="primary"
                variant="contained"
                disabled={!isValid()}
                onClick={onConfirm}>
                Confirm
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

/*
Dialog for terminating active fee schedules
*/
function BtnManageTerminations({
  organizationID,
  onTerminations,
}: props & Partial<any>): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [terminateInputs, setTerminateInputs] = useState<any>({})
  const { showDurationLong: showSnackbarDuration } = useSnackbar()

  // reset modal state whenever it (re)opens
  useEffect(() => {
    if (isOpen) {
      setTerminateInputs({
        fsDateEnd: null,
        termNotes: null,
      })
    }
  }, [isOpen])

  function onConfirm() {
    const payload = {
      DateEnd: dateTime
        .parse(terminateInputs.fsDateEnd)
        .format(dateTime.formats.ISODate),
      Notes: terminateInputs.termNotes,
    }
    OrgActions.terminateAllFeeSchedules(organizationID, payload)
      .then((res: any) => {
        if (res.error) {
          throw res
        }
        showSnackbarDuration(
          'All active production fee schedules terminated!',
          SnackbarTypeSuccess
        )
        onTerminations && onTerminations()
      })
      .catch((err: any) => {
        if (err.Error?.Message) {
          showSnackbarDuration(err.Error.Message, SnackbarTypeError)
          return
        }
        showSnackbarDuration(
          'Failed terminating active fee schedules',
          SnackbarTypeError
        )
      })
      .finally(() => {
        setIsOpen(false)
      })
  }

  function isValid(): boolean {
    if (!terminateInputs.termNotes) {
      return false
    }
    return dateTime.parse(terminateInputs.fsDateEnd).isValid()
  }

  return (
    <>
      <Button
        onClick={() => {
          setIsOpen(true)
        }}
        variant="contained"
        color="secondary">
        Terminate all active Fee Schedules
      </Button>

      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}>
        <DialogTitle>Terminate All Active Production Fee Schedules</DialogTitle>
        <DialogContent>
          <Typography>
            Please select a date to deactivate and provide some context
          </Typography>
          <hr style={{ marginBottom: 0 }} />
          {/* <DateInput
          label='Deactivation Date'
          onChange={(ev : any) => {
            const v = ev.target?.value
            setTerminateInputs((curr:any) => ({...curr, fsDateEnd: v}))
          }}
          value={terminateInputs.fsDateEnd}
          shrink={true}
        /> */}
          <ManagedDateInput
            name="fsDateEnd"
            label="Deactivation Date"
            value={terminateInputs.fsDateEnd}
            setter={({ name, value }) =>
              setTerminateInputs((curr: any) => ({ ...curr, fsDateEnd: value }))
            }
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Notes"
            placeholder="Enter notes here"
            value={terminateInputs.termNotes || ''}
            InputLabelProps={{ shrink: true }}
            onChange={(ev: any) => {
              const v = ev.target?.value
              setTerminateInputs((curr: any) => ({
                ...curr,
                termNotes: v || null,
              }))
            }}
          />
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  setIsOpen(false)
                }}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                color="primary"
                variant="contained"
                disabled={!isValid()}
                onClick={onConfirm}>
                Confirm
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}
