import React, { useEffect, useState, forwardRef } from 'react'
import useErrorHandlers from '../../../../hooks/useErrorHandlers'
import { getZClaimHistoryByID } from '../../../../actions/ZClaimActions'
import { AccountBox } from '@material-ui/icons'
import { randomID } from '../../helpers'
import dateTime from '../../../../utils/dateTime'
import * as Status from '../Status'
import styled from 'styled-components'

const StyledTimeline = styled.div`
  margin-top: 1rem;
  border-right: 2px solid #ccc;
  // border-image: linear-gradient(0deg, rgb(85 127 229), rgb(255 119 209)) 1;
  font-size: 85%;

  .markered {
    display: block;
    position: relative;
    z-index: 2;
    line-height: 1;

    &::before {
      content: '';
      background: #fff;
      width: 13px;
      height: 13px;
      display: block;
      position: absolute;
      top: 50%;
      right: -0.5rem;
      transform: translateY(-45%) translateX(60%);
      border-radius: 50%;
      border: 3px solid #ccc;
      outline: 2px solid #fff;
    }

    &.filled {
      &::before {
        background: #ccc;
      }
    }
  }

  aside {
    text-align: right;
    padding: 0 0.5rem 0 0;
    margin: 0 0 0.75rem 0;
    position: relative;

    time {
      font-size: 90%;
      font-weight: 400;
      line-height: 1;
      color: #999;
      text-transform: uppercase;
      margin: 0 2px 4px 0;
      display: block;
    }

    p {
      display: inline-block;
      margin: 0;
      padding: 0.4rem 0.65rem;
      line-height: 1.3;
      background: #e9e9e9;
      border-radius: 5px;
      border-top-right-radius: 0;
      position: relative;
      overflow: hidden;
      padding-bottom: 1.55rem;
      max-width: 100%;
      word-break: break-word;

      .message {
        display: block;
        padding-bottom: 0.25rem;
        margin-bottom: 0.25rem;
        border-bottom: 1px dotted #bbb;
      }
    }

    cite {
      line-height: 1;
      display: inline-flex;
      align-items: center;
      font-style: normal;
      background: #d1d1d1;
      color: #888;
      padding: 0.2rem 0.35rem 0.15rem;
      position: absolute;
      right: 0;
      bottom: 0;
      border-top-left-radius: 5px;

      svg {
        margin-top: -1px;
      }
    }

    &.start {
      ::before {
        content: '';
        background: #fff;
        top: 0;
        right: 0;
        bottom: 0;
        width: 3px;
        right: -3px;
        display: block;
        position: absolute;
      }

      p {
        background: transparent;
        font-weight: bold;
        padding: 0;
        margin-right: -0.9rem;
        position: relative;
        z-index: 1;
      }
    }
  }
`

interface props {
  zClaimID: number | null
  currentStatus: Status.AdjStatuses | null
}

interface item {
  _key: string
  Timestamp: string
  User: {
    ID: number
    Name: string
  }
  Events: string[]
}

export default forwardRef(function ClaimTimeline(
  { zClaimID, currentStatus }: props & Partial<any>,
  ref: any
): React.ReactElement | null {
  const [items, setItems] = useState<item[]>([])
  const { catchAPIError } = useErrorHandlers()

  React.useImperativeHandle(
    ref,
    () => ({
      refresh,
    }),
    []
  )

  useEffect(() => {
    setItems([])
  }, [])

  useEffect(() => {
    if (!zClaimID) return
    refresh()
  }, [zClaimID])

  function refresh() {
    if (!zClaimID) return
    getZClaimHistoryByID(zClaimID)
      .then((res: { Data: item[] } & Partial<any>) => {
        setItems(
          (res.Data || []).map((it: item) => ({
            ...it,
            _key: randomID(),
          }))
        )
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to load patient src record',
        })
      )
  }

  function gradient(s: Status.AdjStatuses | null): string {
    if (!s) return ``
    const topColor =
      s === Status.AdjStatuses.payable ? '#17b117' : Status.StatusColors[s]
    return `linear-gradient(0deg, #5bbcd4, ${topColor}) 1`
  }

  if (!zClaimID) return null

  return (
    <StyledTimeline style={{ borderImage: gradient(currentStatus) }}>
      {items.map((v: item) => (
        <aside key={v._key}>
          <div className="markered">
            <time dateTime={v.Timestamp}>
              {dateTime
                .parse(v.Timestamp)
                .local()
                .format(dateTime.formats.PrettyVerbose)}
            </time>
          </div>
          <p>
            {(v.Events || []).map((str: string, idx: number) => (
              <span className="message" key={`${str}.${idx}`}>
                {str}
              </span>
            ))}
            <cite>
              <AccountBox fontSize="inherit" style={{ fontSize: 15 }} />{' '}
              {v.User.Name}
            </cite>
          </p>
        </aside>
      ))}
    </StyledTimeline>
  )
})
