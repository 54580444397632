import * as T from './types'

export const removeCommas = (currency: string | null) =>
  currency ? currency.replace(/,/g, '') : ''

export const addCommas = (currency: string | null) => {
  if (
    !!currency &&
    (currency.slice(-1) === '.' || currency.slice(-2) === '.0')
  ) {
    return currency
  }
  if (!currency) {
    return ''
  }
  const n = Number(removeCommas(currency))
  if (!n) {
    return ''
  }
  return n.toLocaleString('en-US', { maximumFractionDigits: 2 })
}

export const toRequestData = (data: any): T.StopLossRequest => ({
  ...data,
  IncurredMonths: +data.IncurredMonths,
  PaymentMonths: +data.PaymentMonths,
  SpecificDeductibleAmount: removeCommas(data.SpecificDeductibleAmount),
  AggregateDeductibleAmount: removeCommas(data.AggregateDeductibleAmount),
  SpecificMax: removeCommas(data.SpecificMax),
  AggregateMax: removeCommas(data.AggregateMax),
})

export const toFormData = (data: T.StopLossRequest): T.StopLossForm => ({
  ...data,
  IncurredMonths: data.IncurredMonths ? +data.IncurredMonths : null,
  PaymentMonths: data.PaymentMonths ? +data.PaymentMonths : null,
})
