import utils from '../utils'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Tooltip, Typography } from '@material-ui/core'
import {
  Info as IconInfo,
  Visibility as IconVisibility,
  VisibilityOff as IconVisibilityOff,
} from '@material-ui/icons'
import dateTime from '../utils/dateTime'
import styled from 'styled-components'

const ADD_LOA_PERM = 'c:loa'
const ADD_ELIG_PERM = 'c:eligibility'
const READ_PERMISSION = 'r:eligibility'
const READ_PERMISSION_SRC = 'r:eligibility_src'

const Eligibility = {
  columns: {
    Name: {
      name: 'Name',
      details: {
        dataFormat: nameFormatter,
        // dataSort: true,
        width: '200px',
      },
    },
    IsCurrentlyActive: {
      name: 'Active',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
    Retired: {
      name: 'Retired',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
    IsSubscriber: {
      name: 'Subscriber',
      details: { dataFormat: utils.boolYesNoFormatter },
    },
    DOB: { name: 'DOB', details: { width: '130px' } },
    EmployerName: {
      name: 'Employer',
      details: {
        dataFormat: employerFormatter,
        // dataSort: true,
        width: '200px',
      },
    },
    IntegrationID: {
      name: 'Int ID',
      details: { /*dataSort: true,*/ width: '125px' },
    },
    ActivationDate: {
      name: 'Activation Start/End',
      details: {
        dataFormat: activationDateStartEndFormatter,
        width: '180px',
      },
    },
    DisplayAddress: {
      name: 'Zip',
      details: {
        dataFormat(_, row) {
          return (
            <Tooltip
              title={
                <Typography variant="body2">{row?.Address?.Display}</Typography>
              }
              placement="top">
              <span style={{ whiteSpace: 'nowrap' }}>
                {row?.Address?.Zip}{' '}
                <IconInfo
                  fontSize="small"
                  color="disabled"
                  style={{ cursor: 'context-menu' }}
                />
              </span>
            </Tooltip>
          )
        },
      },
    },
    PHANotes: {
      name: 'PHA Notes',
      details: {
        dataFormat(_, row) {
          if (!!row.PHANotes) {
            return (
              <Tooltip
                title={<Typography variant="body2">{row?.PHANotes}</Typography>}
                placement="top">
                <IconVisibility
                  fontSize="small"
                  style={{ cursor: 'context-menu' }}
                />
              </Tooltip>
            )
          }
          return <IconVisibilityOff fontSize="small" color="disabled" />
        },
      },
    },
  },
  addFields: {
    FirstName: '',
    LastName: '',
    Email: '',
    RelationToSubscriber: 'I',
    DateOfBirth: '',
    SSN: '',
    Address1: '',
    Address2: '',
    City: '',
    State: '',
    Zip: '',
    BenefitStart: '',
    BenefitEnd: '',
    EmployerID: 0,
    WorkPhone: '',
    HomePhone: '',
    CarrierName: '',
    PlanName: '',
    Notes: '',
    DivisionID: 0,
    UseAddressID: null, // not displayed anywhere or bound to a field; safe to null
  },
  validators: {
    FirstName: {
      isValid: (val) => {
        return val && val.length > 1
      },
      msg: 'First Name is required',
    },
    LastName: {
      isValid: (val) => {
        return val && val.length > 1
      },
      msg: 'Last Name is required',
    },
    DateOfBirth: {
      isValid: dateTime.validationFns.dateOfBirth,
      msg: 'Date of Birth is not valid',
    },
    BenefitStart: {
      isValid: dateTime.validationFns.benefitStart,
      msg: 'Benefit Start date is not valid',
    },
    HomePhone: {
      isValid: (val) => {
        return !val || val.length === 10
      },
      msg: 'Home Phone must be valid or empty',
    },
    WorkPhone: {
      isValid: (val) => {
        return !val || val.length === 10
      },
      msg: 'Work Phone must be valid or empty',
    },
    SSN: {
      isValid: (val) => {
        return !val || (val && val.length === 9)
      },
      msg: 'A valid ssn is required',
    },
    Address1: {
      isValid: (val) => {
        return val && val.length > 1
      },
      msg: 'Address 1 is required',
    },
    City: {
      isValid: (val) => {
        return val && val.length > 1
      },
      msg: 'City is required',
    },
    State: {
      isValid: (val) => {
        return val && val.length > 1
      },
      msg: 'State is required',
    },
    Zip: {
      isValid: (val) => {
        return val && val.length > 1
      },
      msg: 'Zip is required',
    },
  },
  excludeFromVBAExportReasons: [
    { label: 'None', value: '' },
    {
      label: 'Overlapping coverage: active member',
      value: 'overlapping_coverage_active_member',
    },
    {
      label: 'Overlapping coverage: termed member',
      value: 'overlapping_coverage_termed_member',
    },
    { label: 'SSN issue: termed member', value: 'ssn_issue_termed_member' },
  ],
  subsetHandles: [
    { label: 'COBRA', value: 'cobra' },
    { label: 'Default', value: 'default' },
    { label: 'Deps', value: 'deps' },
    { label: 'HSA', value: 'hsa' },
    { label: 'Manual', value: 'manual' },
    { label: 'PPO', value: 'ppo' },
    { label: 'Retirees', value: 'retirees' },
    { label: 'Subs', value: 'subs' },
  ],
  relationships: [
    { label: 'Child', value: 'C' },
    { label: 'Spouse', value: 'S' },
    { label: 'Dependent', value: 'D' },
    { label: 'Unknown', value: 'U' },
  ],
  srcColumns: {
    BenefitStart: {
      name: 'Benefit Activation',
      details: { dataFormat: benefitDateFormatter },
    },
    BenefitEnd: {
      name: 'Benefit Deactivation',
      details: { dataFormat: benefitDateFormatter },
    },
    RecordType: {
      name: 'Record Type',
      details: { dataFormat: recordTypeFormatter },
    },
    Observations: {
      name: 'Observations',
      details: {
        dataFormat(cell, row) {
          return <DisplayObservations row={row} />
        },
      },
    },
    CreatedByUsername: {
      name: 'UpdatedBy',
    },
    Notes: {
      name: 'Notes',
      details: {
        dataFormat(cell) {
          return cell ? (
            <span
              style={{
                whiteSpace: 'pre-line',
                display: 'block',
                width: 320,
                maxHeight: 100,
                overflow: 'scroll',
              }}>
              {cell}
            </span>
          ) : null
        },
      },
    },
    IsSubscriber: { name: 'Subscriber', details: {} },
    FirstName: { name: 'First', details: {} },
    MiddleName: { name: 'Middle', details: {} },
    LastName: { name: 'Last', details: {} },
    Suffix: { name: 'Suffix', details: {} },
    DateOfBirth: { name: 'DOB', details: { colSpan: 3 } },
    SSN: { name: 'SSN', details: { colSpan: 4 } },
    Gender: { name: 'Gender', defaults: {} },
    SubscriberSSN: {
      name: 'SubscriberSSN',
      details: { colSpan: 4 },
    },
    Email: { name: 'Email', details: { colSpan: 4 } },
    RelationToSubscriber: {
      name: 'Relation To Subscriber',
      details: { colSpan: 4 },
    },
    CoverageLevel: {
      name: 'Coverage Level',
      details: { colSpan: 4 },
    },
    PlanName: { name: 'Plan Name', details: { colSpan: 4 } },
    Division: { name: 'Division', details: { colSpan: 4 } },
    MD5: { name: 'MD5', details: {} },
  },
  canAddElig: canAddElig,
  canAddLoa: canAddLoa,
  hasAccess: () => {
    return utils.checkPerms(READ_PERMISSION)
  },
  hasAccessSrc: () => {
    return utils.checkPerms(READ_PERMISSION_SRC)
  },
  subsetHandleFormatter,
  excludeFromVBAExportReasonsFormatter,
}

function canAddElig() {
  return utils.checkPerms(ADD_ELIG_PERM)
}

function canAddLoa() {
  return utils.checkPerms(ADD_LOA_PERM)
}

function employerFormatter(cell, row) {
  return (
    <div>
      <Link to={`/employer/${row.EmployerID}`} target="_blank" name="view-link">
        {row.EmployerName}
      </Link>
      <br />
      {row.DivisionName || ''}
    </div>
  )
}

function activationDateStartEndFormatter(cell, row) {
  const start = dateTime.parse(row.ActivationDate)
  const end = dateTime.parse(row.DeactivationDate)
  return (
    <div>
      {start.isValid() ? (
        <span title={start.format(dateTime.formats.PrettyVerbose)}>
          {start.format(dateTime.formats.Calendar)}
        </span>
      ) : (
        'N/A'
      )}
      <br />
      {end.isValid() ? (
        <span title={end.format(dateTime.formats.PrettyVerbose)}>
          {end.format(dateTime.formats.Calendar)}
        </span>
      ) : (
        'N/A'
      )}
    </div>
  )
}

function nameFormatter(cell, row) {
  const { FirstName, FirstNameAka, MiddleName, LastName, LastNameAka } = row
  let name = `${FirstName}`
  if (MiddleName) name = `${name} ${MiddleName}`
  name = `${name} ${LastName}`
  const akaName = `${FirstNameAka} ${LastNameAka}`
  const differentFirstNameAka = FirstNameAka && FirstName !== FirstNameAka
  const differentLastNameAka = LastNameAka && LastName !== LastNameAka

  if (differentFirstNameAka || differentLastNameAka) {
    name = (
      <p>
        {name}
        <br />
        aka {akaName}
      </p>
    )
  }

  return name
}

const StyledObservations = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  font-size: 11px;
  white-space: nowrap;
  grid-column-gap: 0.75rem;
  grid-row-gap: 0.25rem;
`

function DisplayObservations({ row }) {
  const [expanded, setExpanded] = React.useState(false)
  const { Observations } = row
  const hasMore = Observations && Observations.length > 4

  if (!Observations || Observations.length === 0) {
    return null
  }

  return (
    <StyledObservations>
      <strong>Created</strong>
      <strong>FileEligID</strong>
      <strong>Subset</strong>
      <strong>Processed</strong>

      {Observations.slice(0, expanded ? 1000 : 4).map((v, idx) => {
        return (
          <>
            <span key={`${row.ID}_${idx}_a`}>{v.CreatedAt}</span>
            <span key={`${row.ID}_${idx}_b`}>{v.FileEligID}</span>
            <span key={`${row.ID}_${idx}_c`}>{v.SubsetHandle}</span>
            <span key={`${row.ID}_${idx}_d`}>
              {JSON.stringify(v.WasProcessed)}
            </span>
          </>
        )
      })}

      {hasMore && (
        <Button
          variant="text"
          style={{ fontSize: 10, marginTop: '0.35rem', gridColumn: '1/-1' }}
          onClick={() => setExpanded(!expanded)}>
          <strong>
            {expanded ? 'Collapse' : `${Observations.length - 4} more`}
          </strong>
        </Button>
      )}
    </StyledObservations>
  )
}

function benefitDateFormatter(cell, row) {
  if (row.RecordType === 6 || row.RecordType === 7) {
    return ''
  }
  return dateTime.parse(cell).format(dateTime.formats.AmericanDate, 'N/A')
}

function recordTypeFormatter(_, row) {
  let text = '---'
  switch (row.RecordType) {
    case 1:
      text = 'Imported'
      break
    case 2:
      text = 'Manual Addition'
      break
    case 3:
      text = 'Omission'
      break
    case 4:
      text = 'Edit Activation Date'
      break
    case 5:
      text = 'Edit Deactivation Date'
      break
    case 6:
      text = 'Flag As Problematic'
      break
    case 7:
      text = 'Removed Problematic Flag'
      break
    case 8:
      text = 'Edit Subset Handle'
      break
    case 9:
      text = 'Edit Skip Update Until'
      break
    case 10:
      text = 'Manual Member Edit'
      break
    default:
      text = ''
      break
  }
  return <span style={{ whiteSpace: 'nowrap' }}>{text}</span>
}

function subsetHandleFormatter(row) {
  switch (row) {
    case 'cobra':
      row = 'COBRA'
      break
    case 'default':
      row = 'Default'
      break
    case 'deps':
      row = 'Deps'
      break
    case 'hsa':
      row = 'HSA'
      break
    case 'manual':
      row = 'Manual'
      break
    case 'ppo':
      row = 'PPO'
      break
    case 'retirees':
      row = 'Retirees'
      break
    case 'subs':
      row = 'Subs'
      break
    default:
      row = 'Unknown'
      break
  }
  return row
}
function excludeFromVBAExportReasonsFormatter(row) {
  switch (row) {
    case 'overlapping_coverage_active_member':
      row = 'Overlapping coverage: active member'
      break
    case 'overlapping_coverage_termed_member':
      row = 'Overlapping coverage: termed member'
      break
    case 'ssn_issue_termed_member':
      row = 'SSN issue: termed member'
      break
    default:
      row = ''
      break
  }
  return row
}

export default Eligibility
