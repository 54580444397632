import React, { useState, useEffect } from 'react'
import {
  getEmployerPlanByID,
  postEmployerPlan,
  putEmployerPlan,
} from '../../../actions/EmployerActions'
import ManagedDateInput from '../../../components/Inputs/managedDateInput'
import {
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'
import {
  renderTextField as RenderTextField,
  renderNotesField as RenderNotesField,
  SetterArgs,
} from '../../ViewHelpers'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import { ShapeEmployerPlan } from '../types'
import useErrorHandlers from '../../../hooks/useErrorHandlers'

interface props {
  employerPlanID?: number | null
  employerID: number
  trigger?: (props: any) => React.ReactElement
  onSave?: () => void
}

export default function EmployerPlanDetail({
  employerPlanID: propEmployerPlanID,
  employerID,
  trigger,
  onSave,
}: props): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const [employerPlanID, setEmployerPlanID] = useState<number | null>(
    propEmployerPlanID || null
  )
  const [data, setData] = useState<ShapeEmployerPlan>({
    EmployerID: employerID,
  } as ShapeEmployerPlan)
  const { catchAPIError } = useErrorHandlers()

  useEffect(() => {
    if (!isOpen) {
      setData({ EmployerID: employerID } as ShapeEmployerPlan)
      setEmployerPlanID(null)
      return
    }
  }, [isOpen])

  useEffect(() => {
    if (!employerPlanID) return
    load(employerPlanID)
  }, [employerPlanID])

  function setter({ name, value }: SetterArgs) {
    setData((prev) => ({ ...prev, [name]: value }))
  }

  function doSave() {
    const fn = data.ID ? putEmployerPlan : postEmployerPlan
    fn(employerID, data)
      .then((res: any) => {
        setIsOpen(false)
        onSave?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed saving employer plan',
        })
      )
  }

  function load(employerPlanID: number) {
    if (!employerPlanID) return
    getEmployerPlanByID(employerPlanID)
      .then(({ Data }: any) => {
        if (!Data) throw {} // trigger default error message
        setData(Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed loading employer plan',
        })
      )
  }

  return (
    <>
      {!!trigger &&
        trigger({
          openEmployerPlanID: (id: number) => {
            setEmployerPlanID(id)
            setIsOpen(true)
          },
        })}
      {!trigger && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsOpen(true)}>
          New Employer Plan
        </Button>
      )}

      <Dialog open={isOpen} maxWidth={false}>
        <DialogContent style={{ width: '800px' }}>
          {!!data.ID && (
            <DesignSuite2023.AlertWarning style={{ marginBottom: '1rem' }}>
              Making changes to the employer plan start or end date(s) will
              affect <strong>a lot</strong> of things (for example: all claims
              associated with the employer).
            </DesignSuite2023.AlertWarning>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <RenderTextField
                name="Label"
                label="Plan Label"
                value={data.Label}
                setter={setter}
                use2023Styles
                opts={{ margin: 'dense' }}
              />
            </Grid>
            <Grid item xs={3}>
              <ManagedDateInput
                name="StartDate"
                label="Start Date"
                value={data.StartDate}
                setter={setter}
              />
            </Grid>
            <Grid item xs={3}>
              <ManagedDateInput
                name="EndDate"
                label="End Date"
                value={data.EndDate}
                setter={setter}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RenderNotesField
                name="Descr"
                label="Description"
                value={data.Descr}
                setter={setter}
                use2023Styles
                opts={{ margin: 'dense', rows: 7 }}
              />
            </Grid>
          </Grid>

          {/* <pre>{JSON.stringify(data, null, '  ')}</pre> */}
        </DialogContent>

        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            }
            right={
              <Button variant="contained" color="primary" onClick={doSave}>
                Save
              </Button>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
