import util from './utils'
import { PartnerActions, CLEAR_SAVE_RESULT } from '../actions'
const { GET_PARTNERS, GET_PARTNER_BY_ID } = PartnerActions

export default function (state = null, action) {
  switch (action.type) {
    case GET_PARTNERS: {
      if (action.payload.error) {
        return {
          ...state,
          partners: [],
          count: 0,
          getError: util.errorResponse(action),
        }
      }

      return {
        ...state,
        partners: action.payload.Data,
        count: action.payload.Meta.Total,
      }
    }

    case GET_PARTNER_BY_ID: {
      if (action.payload.error) {
        return {
          ...state,
          getError: util.errorResponse(action),
        }
      }

      return {
        ...state,
        partner: action.payload.Data,
      }
    }

    case CLEAR_SAVE_RESULT: {
      return {
        ...state,
        saveResult: null,
      }
    }

    default:
      return {
        ...state,
      }
  }
}
