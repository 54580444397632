import React, { useState } from 'react'
import { Button, Paper } from '@material-ui/core'
import * as EligibilityTable from './EligibilityTable2'
import Models from '../../models'
import styled from 'styled-components'

const PaperWrapper = styled(Paper)`
  padding: 0.25rem 1rem 1rem;
`

const ButtonNoWrap = styled(Button)`
  white-space: nowrap;
`

export default function EligibilityConsole({ history, location }: any) {
  const [urlEmployerID] = useState(
    parseInt(new URLSearchParams(location.search).get('employerID') || '') ||
      null
  )

  const handleAddNew = () => {
    history.push(`/eligibility`)
  }

  return (
    <PaperWrapper elevation={2}>
      <EligibilityTable.Table
        enableURLReflection
        passFilters={urlEmployerID && { employerIDs: [urlEmployerID] }}
        LeftHeaderItems={<EligibilityTable.DefaultFullyComposedForm />}
        RightHeaderItems={
          Models.Eligibility.canAddElig() && (
            <ButtonNoWrap
              variant="contained"
              color="primary"
              onClick={handleAddNew}>
              Add New
            </ButtonNoWrap>
          )
        }
      />
    </PaperWrapper>
  )
}
