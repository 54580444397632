import React, { useState, useEffect } from 'react'
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import styled from 'styled-components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { getUserByIDV2 } from '../../../actions/UserActions'
import { retrySendSFTP } from '../../../actions/EngineeringActions'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import dateTime from '../../../utils/dateTime'

interface SendLogProps {
  id: number
  retriedAt: string | null
  retriedByUserID: string | null
  retryCount: number | null
  previousSendErrors: string | null
  sendError: string | null
  onSuccess: () => void
}

const RetryButton = styled(Button)`
  margin-right: 15px;
`

const FailedStatus = styled(Typography)`
  color: #3f51b5;
  text-decoration: underline;
  cursor: pointer;
`

const ErrorText = styled(Typography)`
  margin-bottom: 5px;
`

const DialogRetrySendLog = ({
  id,
  retriedAt,
  retriedByUserID,
  retryCount,
  previousSendErrors,
  sendError,
  onSuccess,
}: SendLogProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [user, setUser] = useState<any>()
  const { catchAPIError } = useErrorHandlers()
  const snackbar = useSnackbar()

  useEffect(() => {
    if (!retriedByUserID) return
    getUserByIDV2(retriedByUserID)
      .then((res: any) => setUser(res))
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to fetch User, please contact engineering',
        })
      )
  }, [retriedByUserID])

  const handleSend = () => {
    retrySendSFTP(id)
      .then(() => {
        snackbar.show('SFTP Retry Initiated Successfully', SnackbarTypeSuccess)
        onSuccess()
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to Retry Sending of SFTP, please contact engineering',
        })
      )
      .finally(() => setIsOpen(false))
  }

  return (
    <>
      <FailedStatus onClick={() => setIsOpen(true)}>failed</FailedStatus>
      <Dialog classes={{ paper: 'retryDialog' }} open={isOpen}>
        <DialogTitle>Retry SFTP Send</DialogTitle>
        <DialogContent>
          {!retryCount ? (
            <DialogContentText>
              No one has tried to resend this SFTP yet.
            </DialogContentText>
          ) : (
            <Grid>
              <DialogContentText>
                Last retried at {dateTime.parse(retriedAt).local().format()} by{' '}
                {user?.FirstName} {user?.LastName}
              </DialogContentText>
              <DialogContentText>
                Total Number of Retries: {retryCount}
              </DialogContentText>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  Send Errors
                </AccordionSummary>
                <AccordionDetails>
                  {retryCount > 1 ? (
                    <Grid container direction="column">
                      {previousSendErrors
                        ?.split('\n')
                        .map((error) => (
                          <ErrorText key={error}>{error}</ErrorText>
                        ))}
                    </Grid>
                  ) : (
                    <Typography>{sendError}</Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <RetryButton color="primary" variant="contained" onClick={handleSend}>
            Retry
          </RetryButton>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DialogRetrySendLog
