import { getRefundInvoiceDetail } from '../../../actions/ZClaimFundingActions'
import React, { useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import styled from 'styled-components'
import ChipInformationDisplay from './ChipInformationDisplay'
import dateTime from '../../../utils/dateTime'
import DesignSuite2023 from '../../../components/DesignSuite2023'
import VoidInvoice from './VoidInvoice'
import { canExecEngineering } from '../../../utils/perms'

interface employerRefundPayment {
  PaymentID: number
  Date: string | null
  PayType: string
  CheckNumber: number
  ACHInfo: string
  Amount: string
  AppliedAmount: string
  Notes: string
  IsVoid: boolean
}

interface refundInvoiceInfo {
  InvoiceID: number
  EmployerID: number
  EmployerName: string
  InvoiceNumber: number
  InvoiceDate: string | null
  InvoiceAmount: string
  AppliedAmount: string
  OpenBalance: string
  IsVoid: boolean
  VoidInvoiceNotes: string
  EmployerRefundPayments: employerRefundPayment[]
}

interface invoiceReceiptDetailProps {
  invoiceID: number
  doClose?(): void
}

const DetailDiv = styled.div`
  margin-bottom: 16px;
`

export default function RefundInvoiceDetail({
  invoiceID,
  doClose,
}: invoiceReceiptDetailProps): React.ReactElement {
  const { catchAPIError } = useErrorHandlers()
  const [data, setData] = useState<refundInvoiceInfo>({
    InvoiceID: 0,
    EmployerID: 0,
    EmployerName: '',
    InvoiceNumber: 0,
    InvoiceDate: null,
    InvoiceAmount: '0',
    AppliedAmount: '0',
    OpenBalance: '0',
    IsVoid: false,
    VoidInvoiceNotes: '',
    EmployerRefundPayments: [],
  })
  const [canEngineeringOnly] = useState<boolean>(canExecEngineering())

  onclose = doClose ? doClose : null

  useEffect(() => {
    getData()
  }, [])

  function getData() {
    return getRefundInvoiceDetail(invoiceID)
      .then((res: any) => {
        setData(res.Data)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to get invoice receipt data',
        })
      )
  }

  if (!data) {
    return <></>
  }

  return (
    <>
      <Container>
        <Card variant={'outlined'} style={{ marginTop: '20px' }}>
          <CardHeader title={<div>{data.EmployerName}</div>} />
          <CardContent>
            <DetailDiv>
              <ChipInformationDisplay
                label="Invoice Number"
                value={
                  <span>
                    {data.InvoiceNumber.toString()}&nbsp;
                    <span style={{ color: 'red' }}>[Refund]</span>
                  </span>
                }
              />
              <ChipInformationDisplay
                label="Invoice Date"
                value={dateTime
                  .parse(data.InvoiceDate)
                  .format(dateTime.formats.AmericanDate, 'n/a')}
              />
              <ChipInformationDisplay
                label="Invoice Amount"
                value={formatAmount(data.InvoiceAmount)}
                opts={{
                  style: { color: 'red' },
                }}
              />
              <ChipInformationDisplay
                label="Applied amount"
                value={formatAmount(data.AppliedAmount)}
              />
              <ChipInformationDisplay
                label="Open Balance"
                value={formatAmount(data.OpenBalance)}
              />
              <div style={{ marginTop: '16px' }}>
                <DesignSuite2023.GridLR
                  left={null}
                  right={
                    <div style={{ paddingRight: '16px' }}>
                      {data.IsVoid ? (
                        <>
                          <Typography variant="h4" style={{ color: 'red' }}>
                            This invoice has been voided
                          </Typography>
                          {data.VoidInvoiceNotes && (
                            <Typography variant="h5">
                              {data.VoidInvoiceNotes}
                            </Typography>
                          )}
                        </>
                      ) : (
                        <>
                          <VoidInvoice
                            data={data}
                            disabled={
                              data.OpenBalance !== data.InvoiceAmount ||
                              !canEngineeringOnly
                            }
                            hidden={false}
                            onClose={getData}
                          />
                          <div style={{ paddingTop: '10px' }}>
                            Please see an engineer to assist in voiding the
                            invoice
                          </div>
                        </>
                      )}
                      {data.OpenBalance !== data.InvoiceAmount &&
                      !data.IsVoid ? (
                        <div style={{ paddingTop: '10px' }}>
                          To void invoice, first void payments
                        </div>
                      ) : null}
                    </div>
                  }
                />
              </div>
            </DetailDiv>
            {data.EmployerRefundPayments &&
              data.EmployerRefundPayments.length > 0 && (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Payment ID</TableCell>
                        <TableCell>Payment Date</TableCell>
                        <TableCell>Payment Type</TableCell>
                        <TableCell>Check / ACH</TableCell>
                        <TableCell>Notes</TableCell>
                        <TableCell>Payment Amount</TableCell>
                        <TableCell>Applied Amount</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.EmployerRefundPayments.map((payment) => (
                        <TableRow key={payment.PaymentID}>
                          <TableCell>
                            <span>
                              {payment.PaymentID}
                              {payment.IsVoid && (
                                <span style={{ color: 'red' }}> [Void]</span>
                              )}
                            </span>
                          </TableCell>
                          <TableCell>
                            {dateTime.parse(payment.Date).format()}
                          </TableCell>
                          <TableCell>{payment.PayType}</TableCell>
                          <TableCell>
                            {payment.ACHInfo
                              ? payment.ACHInfo
                              : payment.CheckNumber}
                          </TableCell>
                          <TableCell>{payment.Notes}</TableCell>
                          <TableCell>{formatAmount(payment.Amount)}</TableCell>
                          <TableCell>
                            {formatAmount(payment.AppliedAmount)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
          </CardContent>
        </Card>
      </Container>
    </>
  )
}

function formatAmount(amount: string | number): string {
  if (!amount) {
    return `$0.00`
  }

  if (typeof amount === 'number') {
    amount = amount.toString()
  }

  if (amount === '0') {
    return `$0.00`
  }

  return `$${amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
}
