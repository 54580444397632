import React from 'react'

export class NoMatch extends React.Component {
  render() {
    return (
      <div className="ui inverted red raised very padded text container segment">
        <strong>Error!</strong> No route found matching:
        <div className="ui inverted black segment">
          <code>{window.location.pathname}</code>
        </div>
      </div>
    )
  }
}

export default NoMatch
