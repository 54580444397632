import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from '../../hooks/useForm'
import useSnackbar, {
  SnackbarTypeError,
  SnackbarTypeSuccess,
} from '../../hooks/useSnackbar'
import * as api from '../../services/thezerocard/api-helper'
import { BillingPlan, Model } from '../../models/BillingPlan'

export const useBillingPlanForm = ({
  billingPlanID,
  feeSchedulePriceID,
}: {
  billingPlanID: number | undefined
  feeSchedulePriceID: number
}) => {
  const history = useHistory()
  const [attemptedSave, setAttemptedSave] = useState(false)
  const snackbar = useSnackbar()

  const form = useForm<BillingPlan>(
    {
      Name: '',
      Notes: '',
    },
    Model.validators
  )

  const save = async () => {
    setAttemptedSave(true)
    if (form.isValid()) {
      const res: any = billingPlanID
        ? await api.put(`/billing_plan/${billingPlanID}`, form.data)
        : await api.post(
            `/fee_schedule_price/${feeSchedulePriceID}/billing_plan`,
            { ...form.data, FeeSchedulePriceID: feeSchedulePriceID }
          )
      if (res.error) {
        snackbar.show('Failed saving billing plan', SnackbarTypeError)
        return false
      }
      snackbar.show(
        `Success ${billingPlanID ? 'saving' : 'creating'} billing plan`,
        SnackbarTypeSuccess
      )
      if (!billingPlanID) {
        history.push(
          `/fee_schedule_price/${feeSchedulePriceID}/billing_plan/${res.Data.ID}`
        )
      }
      return true
    }
    return false
  }

  return { form, save, errors: attemptedSave ? form.errors : {} }
}
