import utils from '../utils'

const EDIT_MEMBER_PERMISSION = 'c:eligibility'

const Member = {
  fields: {
    Retired: false,
    FirstName: '',
    LastName: '',
    Email: '',
    MemberID: 0,
    IsSubscriber: true,
    RelationToSubscriber: 'I',
    DateOfBirth: '',
    SSN: '',
    SubscriberSSN: '',
    Address1: '',
    Address2: '',
    City: '',
    State: '',
    Zip: '',
    BenefitStart: '',
    BenefitEnd: '',
    EmployerID: 0,
    WorkPhone: '',
    HomePhone: '',
  },
  relationships: [
    { label: 'Self', value: 'I' },
    { label: 'Child', value: 'C' },
    { label: 'Spouse', value: 'S' },
    { label: 'Dependent', value: 'D' },
    { label: 'Unknown', value: 'U' },
  ],
  canEdit: () => utils.checkPerms(EDIT_MEMBER_PERMISSION),
}

export default Member
