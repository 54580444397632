import React, { useEffect, useState, useCallback } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { SnackbarActions, searchDRGCode } from '../../actions'
import debounce from 'lodash/debounce'
const { setSnackbarMessage } = SnackbarActions

interface DataItem {
  Code: string
  Descr: string
}

interface Props {
  selectedDRGCode: string | null
  onChange(drgCode: string | null, selectedObj: any): void
  setSnackbarMessage(message: string, level: string): void
  AutoCompleteProps?: object
  TextFieldProps?: object
}

const DRGCodeSearcher = ({
  selectedDRGCode,
  onChange,
  setSnackbarMessage,
  AutoCompleteProps = {},
  TextFieldProps = {},
  ...props
}: Props) => {
  const [query, setQuery] = useState<string>('')
  const [data, setData] = useState<Array<DataItem>>(Array<DataItem>())
  const [active, setActive] = useState<DataItem | null>(null)

  const showGenericErr = useCallback(() => {
    setSnackbarMessage(
      'Uh oh, something went awry searching for DRG Codes. Try again later.',
      'error'
    )
  }, [setSnackbarMessage])

  useEffect(() => {
    if (!selectedDRGCode) {
      return
    }
    if (active && active.Code === selectedDRGCode) {
      return
    }
    const req = searchDRGCode({ filter: { code: selectedDRGCode } })
    if (req && req.payload) {
      req.payload
        .then((r: any) => {
          if (r.error) {
            return showGenericErr()
          }
          setActive({ Code: r.Data.Code, Descr: r.Data.Descr })
        })
        .catch(showGenericErr)
    }
  }, [selectedDRGCode, active, showGenericErr])

  useEffect(() => {
    if (!query.length) {
      return
    }
    const req = searchDRGCode({ filter: { q: query } })
    if (req && req.payload) {
      req.payload
        .then((r: any) => {
          if (r.error) {
            return showGenericErr()
          }
          setData(r.Data)
        })
        .catch(showGenericErr)
    }
  }, [query, showGenericErr])

  const onInputChange = debounce((ev: any, q: string, r: string) => {
    setQuery(q)
  }, 250)

  const onSelectionChange = (_: any, b: DataItem | null, reason: string) => {
    setActive(b)
    onChange(b ? b.Code : null, b)
  }

  return (
    <Autocomplete
      options={data}
      getOptionLabel={(opt: DataItem) => `${opt.Code} (${opt.Descr})`}
      getOptionSelected={(opt: DataItem) => {
        return active ? opt.Code === active.Code : false
      }}
      defaultValue={null}
      value={active}
      onChange={onSelectionChange}
      onInputChange={onInputChange}
      renderInput={(params: any) => (
        <TextField
          {...params}
          label="DRG Code"
          placeholder="Start typing to search..."
          {...TextFieldProps}
        />
      )}
      {...AutoCompleteProps}
    />
  )
}

export default connect(
  (state: any) => {
    return {}
  },
  (dispatch: any) => {
    return bindActionCreators({ setSnackbarMessage }, dispatch)
  }
)(DRGCodeSearcher)
