import React, { useState, useEffect, useCallback, ReactElement } from 'react'
import {
  Grid,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Link,
} from '@material-ui/core'
import FileTypeFilter from './FileTypeFilter'
import FileIcon from '../../components/FileUpload/FileIcon'
import { File } from './types'
import { bytesToFriendly } from '../../utils'
import styled from 'styled-components'
import {
  getFile,
  getPresignedUrlForDownload,
  saveFile,
} from '../../actions/FileActions'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import { FileTypeData } from './types'
import {
  PartnerSearcher,
  EmployerSearcher,
  OrganizationSearcherV2,
  ReferrerSearcher,
} from '../../components/Searchers'
import { Alert } from '@material-ui/lab'
import ManagedDateInput from '../../components/Inputs/managedDateInput'
import dateTime from '../../utils/dateTime'

const StyledDialogContent = styled(DialogContent)`
  min-width: 520px;

  .file-info {
    margin-bottom: 20px;
    flex-wrap: nowrap;

    .file-extension-icon {
      cursor: pointer;
    }

    .mini-grid {
      max-width: 100%;
      display: grid;
      grid-template-columns: min-content minmax(375px, 0px);
      column-gap: 1rem;
      align-items: start;

      .wrap-name {
        cursor: pointer;
        display: block;
        max-width: 100%;
        white-space: pre-wrap;
        word-wrap: break-word;
      }
    }
  }
`

const CustomInputsContainer = styled.div`
  padding: 1rem 0 0.5rem;
`

const CustomInputsDefaultItem = styled.div`
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 0;
  }
`

const CustomAlert = styled(Alert)`
  margin-bottom: 1rem;
`

export interface FileEditModalProps {
  recordID: number | null
  getFile?: (id: number) => Promise<any>
  beforeSend?: (arg: File) => File
  handleClose?: () => void
  onSuccess?: (payload: File) => void
  customFileTypeFilter?: () => ReactElement
  CustomInputs?: React.FC<customInputProps> & any
  entityType?: any[]
}

export interface customInputProps {
  record: File
  setter(data: Partial<File>): void
}

interface DateProps {
  error: boolean
  helperText: string
}

export default function RowEditModal({
  recordID,
  getFile = getFileDefault,
  beforeSend = (v) => v, // no-op, but always callable
  handleClose = () => {}, // no-op, but always callable
  onSuccess = () => {}, // no-op, but always callable
  customFileTypeFilter,
  entityType,
  CustomInputs = CustomInputsDefaultPickers,
}: FileEditModalProps) {
  const [data, setData] = useState<File | null>(null)
  const [dateProps, setDateProps] = useState({} as DateProps)
  const [origFileTypeData, setOrigFileTypeData] = useState<FileTypeData | null>(
    null
  )
  const [fileTypeWarnings, setFileTypeWarnings] = useState<Array<string>>([])
  const { catchAPIError } = useErrorHandlers()
  const snackbar = useSnackbar()
  const fileTypeErrorMessage = 'File Type is Required'

  useEffect(() => {
    if (!recordID) {
      setData(null)
      setDateProps({} as DateProps)
      setOrigFileTypeData(null)
      setFileTypeWarnings([])
      return
    }
    getFile(recordID)
      .then((res: File) => {
        setData(
          Object.assign({}, res, {
            EffectiveDate:
              res.EffectiveDate && dateTime.parse(res.EffectiveDate).format(),
          })
        )
        setOrigFileTypeData(res.FileType)
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Failed to get file information; please contact Engineering',
        })
      )
  }, [recordID])

  useEffect(() => {
    if (!data) return
    if (data.EffectiveDate && !dateTime.parse(data.EffectiveDate).isValid()) {
      return setDateProps({
        error: true,
        helperText: 'Invalid date',
      })
    }
    setDateProps({
      error: false,
      helperText: '',
    })
  }, [data?.EffectiveDate])

  useEffect(() => {
    if (!origFileTypeData || !data) return
    if (data.FileTypeId === origFileTypeData.ID) return

    const warns: Array<string> = []
    const msger = (ent: string) =>
      `This file was previously assigned a File Type that tracked an associated ${ent}, but the new File Type (${data.FileType.Description}) does not. The ${ent} will be removed from this file. **This cannot be undone if you proceed with the selected File Type**.`
    // en ingles: "if the original file type had IsEmployerType:true, but the new file type doesn't
    // (IsEmployerType:false), and EmployerId is a non-null value..."
    if (
      origFileTypeData.IsEmployerType &&
      !data.FileType.IsEmployerType &&
      !!data.EmployerId
    ) {
      warns.push(msger('Employer'))
    }
    if (
      origFileTypeData.IsOrganizationType &&
      !data.FileType.IsOrganizationType &&
      !!data.OrganizationId
    ) {
      warns.push(msger('Organization'))
    }
    if (
      origFileTypeData.IsPartnerType &&
      !data.FileType.IsPartnerType &&
      !!data.PartnerId
    ) {
      warns.push(msger('Partner'))
    }
    if (
      origFileTypeData.IsReferrerType &&
      !data.FileType.IsReferrerType &&
      !!data.ReferrerID
    ) {
      warns.push(msger('Referrer'))
    }
    setFileTypeWarnings(warns)
  }, [data?.FileTypeId, data?.FileType])

  const handleSave = () => {
    if (dateProps.error) return
    if (!data) return
    let payload = { ...data } as File
    // re-format (and validate) EffectiveDate prior to shipping to backend
    if (data.EffectiveDate) {
      const ed = dateTime.parse(data?.EffectiveDate)
      if (!ed.isValid()) {
        snackbar.show('Invalid effective date.', SnackbarTypeError)
        return
      }
      payload.EffectiveDate = ed.format(dateTime.formats.ISODate)
    }
    // validate FileTypeId is set
    if (!payload.FileTypeId) {
      snackbar.show('File Type cannot be empty.', SnackbarTypeError)
      return
    }
    // insurance (this should never happen, but if it does, its a big risk that this component
    // is messing up its' state tracking stuff... which could cause huge problems)
    if (payload.FileTypeId !== payload.FileType.ID) {
      snackbar.show(
        'An unexpected state exists with the FileType configuration. For safety, this file update is being aborted. Please contact engineering ASAP if you see this.',
        SnackbarTypeError
      )
      return
    }
    // invoke the beforeSend hook, which is purposefully designed to allow the beforeSend
    // function to throw an error, which will abort the save. 'beforeSend' enables callers
    // to validate/mutate data as they see fit for any given use case
    try {
      payload = beforeSend(payload)
    } catch (e) {
      snackbar.show(
        (e as any)?.message ||
          'An unknown error occurred attempting to update this file.',
        SnackbarTypeError
      )
      console.log('FileUpdateModal.beforeSend hook errored with: ', e)
      return
    }
    // if we get here, send it, and invoke callbacks
    saveFile(payload)
      .then(() => {
        handleClose()
        onSuccess(payload)
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to update file; please contact Engineering',
        })
      )
  }

  const mergeData = useCallback(
    (v: Partial<File>) => {
      // @ts-ignore ; anyone know why typescript doesn't like this? its perfectly valid
      setData((current: File): File => {
        return { ...current, ...v }
      })
    },
    [setData]
  )

  function undoFileTypeChange() {
    if (!origFileTypeData) return
    mergeData({
      FileTypeId: origFileTypeData.ID,
      FileType: origFileTypeData,
    })
    setFileTypeWarnings([])
  }

  const handleDownload = () => {
    getPresignedUrlForDownload(data?.ID)
      .then((res: any) => {
        if (!res.url)
          return snackbar.show(
            'Unable to find Download URL; please contact engineering',
            SnackbarTypeError
          )
        const newTab = window.open()
        if (newTab) newTab.location.href = res.url
      })
      .catch(
        catchAPIError({
          defaultMessage:
            'Unable to get Presigned URL for Download; please contact engineering',
        })
      )
  }

  if (!data) return null
  return (
    <Dialog open={!!data} onClose={handleClose}>
      <DialogTitle>Update File</DialogTitle>
      <StyledDialogContent>
        <Grid className="file-info" container spacing={2} alignItems="center">
          <Grid item xs="auto" onClick={handleDownload}>
            <FileIcon color="#3F51B5" ext={data.S3Key.split('.').pop()} />
          </Grid>
          <Grid item xs="auto">
            <div className="mini-grid">
              <div>FileName:</div>
              <div>
                <Link
                  className="wrap-name"
                  underline="always"
                  onClick={handleDownload}>
                  {data.S3Key.split('/').pop()}
                </Link>
              </div>
              <div>Size:</div>
              <div>{bytesToFriendly(data.Size)}</div>
              <div>FileID:</div>
              <div>{data.ID}</div>
            </div>
          </Grid>
        </Grid>
        {!!fileTypeWarnings.length && (
          <>
            <CustomAlert className="alert-error" severity="error">
              {fileTypeWarnings.map((w: string) => (
                <p>{w}</p>
              ))}
              <Button
                size="small"
                color="secondary"
                variant="contained"
                onClick={undoFileTypeChange}>
                Undo File Type Change
              </Button>
            </CustomAlert>
          </>
        )}
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={8}>
            {!!customFileTypeFilter ? (
              customFileTypeFilter()
            ) : (
              <FileTypeFilter
                entityTypes={entityType}
                defaultValue={data.FileTypeId || null}
                onChange={(ft: FileTypeData | null) => {
                  if (!ft || !ft?.ID) {
                    mergeData({ FileTypeId: ft ? ft.ID : null })
                    return
                  }
                  mergeData({
                    FileTypeId: ft ? ft.ID : null,
                    FileType: ft,
                  })
                }}
                errorMessage={fileTypeErrorMessage}
              />
            )}
          </Grid>
          <Grid item xs={4}>
            <ManagedDateInput
              label="Effective Date"
              name="EffectiveDate"
              margin="none"
              value={data.EffectiveDate}
              setter={({ name, value }) => {
                mergeData({ [name]: value })
              }}
            />
          </Grid>
        </Grid>
        {CustomInputs && <CustomInputs record={data} setter={mergeData} />}
        <TextField
          multiline
          fullWidth
          label="Notes"
          rows="3"
          name="Notes"
          margin="normal"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          value={data.Notes || ''}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            mergeData({ Notes: ev.target.value })
          }}
        />
      </StyledDialogContent>
      <DialogActions>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs="auto">
            <Button variant="contained" color="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

/*
CustomInputsDefaultPickers is the default form implementation that supports showing
re-assignable entities, depending on the existing file configuration **and the settings
of the FileType**.
*/
export function CustomInputsDefaultPickers({
  record,
  setter,
}: customInputProps): React.ReactElement | null {
  const { FileType: fType, FileTypeId } = record

  if (!FileTypeId) return null

  return (
    <CustomInputsContainer>
      {fType && fType.IsOrganizationType && (
        <CustomInputsDefaultItem>
          <OrganizationSearcherV2
            disableClear
            selectedOrganizationID={record.OrganizationId}
            onChange={(id: number | null) => {
              setter({ OrganizationId: id })
            }}
            TextFieldProps={{
              variant: 'outlined',
              size: 'small',
              InputLabelProps: { shrink: true },
            }}
          />
        </CustomInputsDefaultItem>
      )}
      {fType && fType.IsPartnerType && (
        <CustomInputsDefaultItem>
          <PartnerSearcher
            disableClear
            selectedPartnerID={record.PartnerId}
            onChange={(id: number | null) => {
              setter({ PartnerId: id })
            }}
            TextFieldProps={{
              variant: 'outlined',
              size: 'small',
              InputLabelProps: { shrink: true },
            }}
          />
        </CustomInputsDefaultItem>
      )}
      {fType && fType.IsEmployerType && (
        <CustomInputsDefaultItem>
          <EmployerSearcher
            disableClear
            selectedEmployerID={record.EmployerId}
            onChange={(id: number | null) => {
              setter({ EmployerId: id })
            }}
            TextFieldProps={{
              variant: 'outlined',
              size: 'small',
              InputLabelProps: { shrink: true },
            }}
          />
        </CustomInputsDefaultItem>
      )}
      {fType && fType.IsReferrerType && (
        <CustomInputsDefaultItem>
          <ReferrerSearcher
            disableClear
            selectedReferrerID={record.ReferrerID}
            onChange={(id: number | null) => {
              setter({ ReferrerID: id })
            }}
            TextFieldProps={{
              variant: 'outlined',
              size: 'small',
              InputLabelProps: { shrink: true },
            }}
          />
        </CustomInputsDefaultItem>
      )}
    </CustomInputsContainer>
  )
}

const getFileDefault = (id: number): Promise<any> => {
  return getFile(id)
}
