import React from 'react'
// @ts-ignore
import MaskedInput from 'react-text-mask'
import { createNumberMask } from 'text-mask-addons'

export const SSNMask = (props: any) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholder="XXX-XX-XXXX"
    />
  )
}

export const MonthDayMask = (props: any) => {
  const { inputRef, ...other } = props
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/[0-1]/, /[0-9]/, '/', /[0-3]/, /[0-9]/]}
      placeholder="MM/DD"
      width={250}
    />
  )
}

export const PhoneNumberMask = (props: any) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholder="(___)___-____"
    />
  )
}

export const PhoneExtMask = (props: any) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      guide={false}
      placeholder="-----"
    />
  )
}

// @todo: component was breaking in a way that caused the entire page to explode;
// need to update the mask library as it hasn't been maintained in ~5 years
// const EmailMask = (props) => {
//   const { inputRef, ...other } = props

//   return (<MaskedInput
//     {...other}
//     ref={(ref) => {
//       inputRef(ref ? ref.inputElement : null)
//     }}
//     // mask={emailMask}
//     // mask={[/\.*/]}
//     placeholder='you@somedomain.com'
//   />)
// }

export const PercentageMask = (props: any) => {
  const { inputRef, ...other } = props

  const maskOptions = {
    prefix: '',
    suffix: '',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false,
  }
  const percentMask = createNumberMask({ ...maskOptions })

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={percentMask}
      placeholder="0.0"
    />
  )
}
