import React, { ReactElement, useState } from 'react'
import {
  postSendInvoiceCreatedNotification,
  PostSendInvoiceNotificationsParams,
} from '../../../actions/ZClaimFundingActions'
import { Button } from '@material-ui/core'
import { invoicedRecord } from './InvoicedTable'
import useErrorHandlers from '../../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../../hooks/useSnackbar'
import DesignSuite2023 from '../../../components/DesignSuite2023'

interface props {
  invoicedRecords: invoicedRecord[]
  onComplete: () => void
  disabled: boolean
}

export default function ButtonSendInvoiceCreatedNotification({
  invoicedRecords,
  onComplete,
  disabled = false,
}: props): ReactElement {
  const [isWorking, setIsWorking] = useState(false)
  const { showForDuration: showSnackbar } = useSnackbar()
  const { catchAPIError } = useErrorHandlers()

  function doPostSendInvoiceCreatedNotifications() {
    setIsWorking(true)
    const params: PostSendInvoiceNotificationsParams = {
      invoiceIDs: invoicedRecords.map((record) => record.InvoiceID),
    }

    postSendInvoiceCreatedNotification(params)
      .then(() => {
        showSnackbar(
          'Invoice Created Notifications Created',
          SnackbarTypeSuccess
        )
        onComplete?.()
      })
      .catch(
        catchAPIError({
          defaultMessage: 'Failed to create Notifications',
        })
      )
      .finally(() => setIsWorking(false))
  }

  return (
    <>
      <Button
        fullWidth
        disabled={disabled || isWorking}
        variant="outlined"
        color="primary"
        size="small"
        style={{ height: '50px' }}
        onClick={() => doPostSendInvoiceCreatedNotifications()}>
        Send Created Notification
        {isWorking && (
          <>
            &nbsp;
            <DesignSuite2023.LoadingSpinner size={20} show={isWorking} />
          </>
        )}
      </Button>
    </>
  )
}
