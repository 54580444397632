import * as api from '../services/thezerocard/api-helper'

interface ReferrerParams {}

export function findReferrers(params: ReferrerParams): Promise<any> {
  return api
    .search('/referrer', params)
    .then((res: any) => res.Data)
    .catch((err: any) => {
      if (err.Error?.Message) throw err.Error.Message
      throw 'Failed to find referrers, please contact Engineering'
    })
}

export function getReferrer(id: number): Promise<any> {
  return api
    .get(`/referrer/${id}`)
    .then((res: any) => res.Data)
    .catch((err: any) => {
      if (err.Error?.Message) throw err.Error.Message
      throw 'Failed to fetch referrer, please contact Engineering'
    })
}

type updateReferrerEmployersParams = {
  referrerID: number
  payload: any
}

export function updateReferrerEmployers(
  params: updateReferrerEmployersParams
): Promise<any> {
  return api.put(`/referrer/${params.referrerID}/employers`, params.payload)
}

export function getReferrerEmployers(referrerID: number): Promise<any> {
  return api.get(`/referrer/${referrerID}/employers`)
}

export function getReferrerContactTypes(): Promise<any> {
  return api.get('/contact_type/referrer') as any
}
