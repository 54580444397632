import React, { useState, useEffect } from 'react'
import { editEmployerMemberPlanName } from '../../actions/MemberActions'
import DesignSuite2023 from '../../components/DesignSuite2023'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
import { renderTextField as RenderTextField, SetterArgs } from '../ViewHelpers'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'
import * as shapes from './types'

interface props {
  Trigger: any // React.ReactComponent
  empMemRec: shapes.shapeEmployerMember | null
  onSaved?(): void
}

export default function DialogSetPlanName({
  Trigger,
  empMemRec,
  onSaved,
}: props): React.ReactElement | null {
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const [isOpen, setIsOpen] = useState(false)
  const [payload, setPayload] = useState<any>({
    PlanName: empMemRec?.PlanName || null,
  })

  useEffect(() => {
    if (!isOpen) return
    setPayload({
      PlanName: empMemRec?.PlanName || null,
    })
  }, [isOpen])

  function setter({ name, value }: SetterArgs) {
    setPayload((curr: any) => ({ ...curr, [name]: value }))
  }

  function doSave() {
    editEmployerMemberPlanName({ ...payload, EmployerMemberID: empMemRec?.ID })
      .then((res: any) => {
        if (res.error) throw res
        showSnackbar('Plan name updated OK!', SnackbarTypeSuccess)
        onSaved && onSaved()
      })
      .catch(catchAPIError({ defaultMessage: 'Failed updating plan name' }))
      .finally(() => setIsOpen(false))
  }

  if (!empMemRec) return null

  return (
    <>
      <DesignSuite2023.Tooltip title="Manually set plan name">
        <Trigger onClick={() => setIsOpen(true)} />
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>
          Change Plan Name
          <small style={{ display: 'block' }}>
            Note: this is an eligibility related concept; if you do not know
            what this is, leave.
          </small>
        </DialogTitle>
        <DialogContent>
          <RenderTextField
            name="PlanName"
            label="Plan Name"
            value={payload.PlanName}
            setter={setter}
            opts={{
              margin: 'none',
              variant: 'outlined',
              size: 'small',
            }}
          />

          {/* <pre>{JSON.stringify(payload, null, '  ')}</pre> */}
        </DialogContent>
        <DialogActions>
          <DesignSuite2023.GridLR
            left={
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            }
            right={
              <Button color="primary" variant="contained" onClick={doSave}>
                Save
              </Button>
            }
          />
        </DialogActions>
      </Dialog>
    </>
  )
}
