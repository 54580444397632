import React, { useState } from 'react'
import InvoiceReceiptDetail from './InvoiceReceiptDetail'
import { StandardFilterSearch, Table } from './InvoicedClaimTable'
import DesignSuite2023, {
  DrawerProps,
} from '../../../components/DesignSuite2023'
import { Card, CardContent, Container } from '@material-ui/core'
import { invoicedRecord } from './InvoicedTable'
import RefundInvoiceDetail from './RefundInvoiceDetail'

type props = DrawerProps & {
  data: invoicedRecord
  onClose?(): void
}

export default React.forwardRef<props, any>(function (
  { data, disableEscapeKeyDown = true, onClose: propOnClose, ...spread }: props,
  ref: any
): React.ReactElement | null {
  const [component, setComponent] = useState<React.ReactElement | null>(null)

  function onOpen() {
    if (data.IsRefund) {
      setComponent(
        <>
          <RefundInvoiceDetail invoiceID={data.InvoiceID} />
          <Container>
            <Card variant={'outlined'} style={{ marginTop: '20px' }}>
              <CardContent>
                <Table
                  passFilters={{ InvoiceID: data.InvoiceID }}
                  dataTableProps={{
                    keyProp: 'ZClaimID',
                  }}
                  BeforeTable={pageColumnLeft}
                />
              </CardContent>
            </Card>
          </Container>
        </>
      )
    } else {
      setComponent(
        <>
          <InvoiceReceiptDetail invoiceID={data.InvoiceID} />
          <Container>
            <Card variant={'outlined'} style={{ marginTop: '20px' }}>
              <CardContent>
                <Table
                  passFilters={{ InvoiceID: data.InvoiceID }}
                  dataTableProps={{
                    keyProp: 'ZClaimID',
                  }}
                  BeforeTable={pageColumnLeft}
                />
              </CardContent>
            </Card>
          </Container>
        </>
      )
    }
  }

  function onClose() {
    setComponent(null)
    propOnClose?.()
  }

  return (
    <DesignSuite2023.Drawer
      {...spread}
      ref={ref}
      width="80vw"
      disableEscapeKeyDown={disableEscapeKeyDown}
      onOpen={onOpen}
      onClose={onClose}>
      {component}
    </DesignSuite2023.Drawer>
  )
})

const pageColumnLeft = (
  <div className="lefty">
    <div style={{ paddingBottom: '1rem', marginTop: '8px' }}>
      <StandardFilterSearch
        autoFocus
        label="Search"
        InputLabelProps={{ shrink: true }}
      />
    </div>
  </div>
)
