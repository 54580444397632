import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'
import useErrorHandlers from '../../hooks/useErrorHandlers'
import useSnackbar from '../../hooks/useSnackbar'
import styled from 'styled-components'
import DesignSuite2023 from '../../components/DesignSuite2023'
import { queueOpportunityAnalysisTask } from '../../actions/OpportunityAnalysisActions'

const CURRENT_ALGO_VERSION = 3

export default function QueueOppAnalysisTask(): React.ReactElement | null {
  const [isOpen, setIsOpen] = useState(false)
  const { catchAPIError } = useErrorHandlers()
  const { showForDuration: showSnackbar } = useSnackbar()
  const [algoVersion, setAlgoVersion] = useState<number | null>(
    CURRENT_ALGO_VERSION
  )

  useEffect(() => {
    if (!isOpen) {
      return
    }
    setAlgoVersion(CURRENT_ALGO_VERSION)
  }, [isOpen])

  function queueOppAnalysis() {
    queueOpportunityAnalysisTask({ AlgoVersion: algoVersion })
      .then(() => {
        showSnackbar('Opp Analysis Task Queued', 'success')
        setIsOpen(false)
      })
      .catch(
        catchAPIError({ defaultMessage: 'Failed to queue Opp Analysis Task' })
      )
  }
  function isDisabled() {
    return algoVersion === null
  }

  return (
    <>
      <DesignSuite2023.Tooltip placement="left" title="Queue Opp Analysis Task">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsOpen(true)
          }}>
          Run Opp Analysis
        </Button>
      </DesignSuite2023.Tooltip>

      <Dialog open={isOpen}>
        <StyledDialogContent>
          <>
            <Typography variant="h6">
              Select Algorithm Version for Opportunity Analysis
            </Typography>
            <TextField
              id={'algoVersion'}
              label={'Algo Version'}
              type={'number'}
              value={algoVersion || ''}
              onChange={(e) => {
                setAlgoVersion(e.target.value ? parseInt(e.target.value) : null)
              }}
            />
          </>
        </StyledDialogContent>

        <DialogActions>
          <Grid container spacing={2} justify="space-between">
            <Grid item xs="auto">
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  setIsOpen(false)
                }}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                color="primary"
                variant="contained"
                disabled={isDisabled()}
                onClick={queueOppAnalysis}>
                Queue Opp Analysis
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

const StyledDialogContent = styled(DialogContent)`
  min-width: 400px;
`
