import React, { useState, useEffect, useCallback } from 'react'
import useSnackbar, { SnackbarTypeError } from '../../hooks/useSnackbar'
import { debounce } from 'lodash'
import { Searchbox } from '../../components/Inputs'
import { List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { IconX, IconCheck } from '../../utils'
import { BillingPlanActions } from '../../actions'
import { STATUS_PRODUCTION } from '../../models/ComponentFeeSchedule'
import styled from 'styled-components'
import dateTime from '../../utils/dateTime'
const { findComponentFS } = BillingPlanActions

interface ComponentFSPickerProps {
  onAdd(fs: any): void
}

export const ComponentFSPicker: React.FC<ComponentFSPickerProps> = ({
  onAdd,
}) => {
  const { show: showSnackbar } = useSnackbar()
  const [query, setQuery] = useState<string>('')
  const [results, setResults] = useState<any[]>([])
  const [selected, setSelected] = useState<number | null>(null)
  // eslint-disable-next-line
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (query && query.length !== 0 && query.length >= 3) {
      loadFS(query)
      return
    }
    loadFS('')
  }, [query])

  const loadFS = useCallback(
    debounce((q: string) => {
      setLoading(true)
      findComponentFS(q)
        .then((feeSchedules: any) => {
          setResults(feeSchedules)
        })
        .catch((err: any) => {
          showSnackbar(err, SnackbarTypeError)
        })
        .finally(() => {
          setLoading(false)
        })
    }, 200),
    [setResults, showSnackbar, setLoading, findComponentFS]
  )

  const handleClick = (fs: any) => {
    return () => {
      setSelected(fs.ID)
      onAdd(fs)
    }
  }

  return (
    <PickerContainer>
      <div>
        <Searchbox
          placeholder="Find a component fee schedule"
          fullWidth
          value={query}
          onChange={(ev: any) => {
            const q = ev.target?.value
            setQuery(q)
          }}
        />
      </div>
      <div>
        <List>
          {results && results.length > 0 ? (
            results.map((fs) => {
              return (
                <StyledListItem
                  key={fs.ID}
                  button
                  onClick={handleClick(fs as number)}
                  selected={fs.ID === selected}>
                  <ListItemText
                    primary={<FeeScheduleDisplayer fs={fs} />}
                    secondary={
                      <span>
                        {dateTime.parse(fs.StartDate).format()} -{' '}
                        {dateTime
                          .parse(fs.EndDate)
                          .format(dateTime.formats.AmericanDate, 'N/A')}
                      </span>
                    }
                  />
                </StyledListItem>
              )
            })
          ) : (
            <div />
          )}
        </List>
      </div>
    </PickerContainer>
  )
}

const FeeScheduleDisplayer = ({ fs }: any) => {
  const isProduction = fs.Status === STATUS_PRODUCTION
  let icon = isProduction ? <IconCheck /> : <IconX />

  return (
    <div>
      <span>
        {fs.Name} {icon}
      </span>
      <Typography variant="body1" color="textSecondary">
        {fs.Type}
      </Typography>
    </div>
  )
}

const StyledListItem = styled(ListItem)`
  border-radius: 6px;
`

const PickerContainer = styled.div`
  padding-top: 10px;
`
