import React from 'react'
import { Button } from '@material-ui/core'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import Models from '../../models'
import { DataTable } from '../../components/DataTable'
import { EmployerActions, SnackbarActions } from '../../actions'
import { UserManagerDialog } from '../Users/UserManager'
import DialogAddTeamZeroUser from './Dialog/AddTeamZeroUser'
import utils from '../../utils'
import _ from 'lodash'
import { renderSwitchField } from '../../components/Inputs/standard'

const { setSnackbarMessage } = SnackbarActions

interface Props {
  getTeamZeroUsers?: any
  tzUsers?: any
  count: number
  manageTeamZeroUsers?: any
  manageTeamZeroAdminUsers?: any
  sendTeamZeroPasswordEmail?: any
  setSnackbarMessage?: any
  searchError?: any
  employerID: number
}

interface State {
  loading: boolean
  userMgrOpen: boolean
  dialogCreateTZUserOpen: boolean
  employers: {
    teamZeroUsers?: any
    count?: number
    getTeamZeroUsersError?: any
  }
  showZeroUsers: boolean
  cols: any
}

interface ConfirmPayload {
  added: any
  removed: any
  permissionHandle: any
}

interface TzPerm {
  UserInfo: UserInfo
  UserID: number
}

interface UserInfo {
  Email: string
  FirstName: string
  LastName: string
}

class TeamZeroUsersTable extends React.Component<Props, State> {
  static defaultProps: Props

  state: State = {
    loading: false,
    userMgrOpen: false,
    dialogCreateTZUserOpen: false,
    employers: {},
    cols: {},
    showZeroUsers: false,
  }

  UNSAFE_componentWillMount() {
    this.findTzUsers()
    this.setState({
      cols: this.injectSyntheticCols(Models.TeamZeroUsers.columns),
    })
  }

  findTzUsers = () => {
    const { employerID } = this.props
    this.props.getTeamZeroUsers({ employerID })
  }

  onConfirmUsers = (body: ConfirmPayload) => {
    const { added, removed } = body
    let editedMessage = 'TeamZERO users successfully updated!'
    if (added.length)
      editedMessage = editedMessage + ` ${added.length} User(s) added.`
    if (removed.length)
      editedMessage = editedMessage + ` ${removed.length} User(s) removed.`
    this.props
      .manageTeamZeroUsers({
        employerID: this.props.employerID,
        body: {
          AddedUserIDs: utils.getIds(added),
          RemovedUserIDs: utils.getIds(removed),
        },
      })
      .then((res: any) => {
        this.setState({ userMgrOpen: false })
        this.setState({ dialogCreateTZUserOpen: false })
        this.props.setSnackbarMessage(editedMessage, 'success')
        this.findTzUsers()
      })
  }

  onSuccessCreateTZUser = () => {
    this.setState({ dialogCreateTZUserOpen: false })
    this.findTzUsers()
  }

  onCancelUserManager = () => {
    this.setState({ userMgrOpen: false })
    this.findTzUsers()
  }

  onUpdateUserManager = () => {
    this.findTzUsers()
  }

  toggleAdmin = (userID: number, add: boolean) => {
    return () => {
      const { employerID } = this.props
      const AddedUserIDs = add ? [userID] : []
      const RemovedUserIDs = add ? [] : [userID]
      const snackbarText = add
        ? 'User successfully made Admin!'
        : 'User successfully removed!'
      this.props
        .manageTeamZeroAdminUsers({
          employerID,
          body: { AddedUserIDs, RemovedUserIDs },
        })
        .then((res: any) => {
          this.props.setSnackbarMessage(snackbarText, 'success')
          this.findTzUsers()
        })
    }
  }

  renderToggleAdmin = (cell: any, row: any) => {
    const isAdmin = row.CanManageOtherUsers
    const text = isAdmin ? 'Remove Admin' : 'Make Admin'
    const color = isAdmin ? 'secondary' : 'primary'
    return (
      <Button
        color={color}
        variant="outlined"
        onClick={this.toggleAdmin(row.UserID, !isAdmin)}>
        {text}
      </Button>
    )
  }

  sendSetPasswordEmail = (userID: number) => {
    const { employerID } = this.props
    this.props
      .sendTeamZeroPasswordEmail({ employerID, body: { userID } })
      .then((res: any) => {
        if (res.payload.Error) {
          const errs = _.get(res.payload, 'errList', [])
          if (errs.length > 0) {
            this.props.setSnackbarMessage(errs.join('\n'), 'error')
          }
          return
        }
        this.props.setSnackbarMessage('Sent password reset email', 'success')
      })
  }

  renderSendEmail = (cell: any, row: any) => {
    return (
      <Button
        color="primary"
        variant="outlined"
        onClick={this.sendSetPasswordEmail.bind(this, row.UserID)}>
        Send Set Password Email
      </Button>
    )
  }

  injectSyntheticCols = (cols: any): any => {
    const makeAdminCol = {
      name: 'Make Admin',
      details: { dataFormat: this.renderToggleAdmin, width: 200 },
    }
    const sendEmailCol = {
      name: 'Send Email',
      details: { dataFormat: this.renderSendEmail, width: 200 },
    }
    return Object.assign(
      {},
      cols,
      { MakeAdmin: makeAdminCol, SendEmail: sendEmailCol },
      Models.TeamZeroUsers.permissionColumns
    )
  }

  permToUser = (perms: Array<TzPerm>) => {
    if (!perms || perms.length === 0) {
      return []
    }
    return perms.map((p: TzPerm) => {
      return {
        ID: p.UserID,
        ...p.UserInfo,
      }
    })
  }

  renderTable = () => {
    const { searchError, tzUsers } = this.props
    const { loading, cols } = this.state

    let filteredUsers = tzUsers

    if (!this.state.showZeroUsers) {
      filteredUsers = tzUsers.filter(
        (user: any) => user.UserInfo?.Email?.indexOf('@zero.health') === -1
      )
    }
    return (
      <DataTable
        keyProp="ID"
        columns={cols}
        data={filteredUsers}
        loading={loading}
        pagination={false}
        error={searchError}
      />
    )
  }

  render() {
    const { tzUsers, employerID } = this.props
    const { userMgrOpen, dialogCreateTZUserOpen } = this.state
    const formattedUsers = this.permToUser(tzUsers)

    return (
      <div>
        {renderSwitchField({
          name: 'Show Zero Users',
          label: 'Show ZERO Users',
          value: this.state.showZeroUsers,
          setter: ({ name, value }: any) => {
            this.setState({ ...this.state, showZeroUsers: value })
          },
        })}

        <div>{this.renderTable()}</div>
        <div>
          <Button
            style={{ float: 'right', marginTop: 15 }}
            onClick={() => {
              this.setState({ userMgrOpen: true })
            }}
            color="primary"
            variant="contained">
            Manage Users
          </Button>
          <Button
            style={{ float: 'right', marginTop: 15, marginRight: 15 }}
            onClick={() => {
              this.setState({ dialogCreateTZUserOpen: true })
            }}
            color="secondary"
            variant="contained">
            Add New User
          </Button>
        </div>
        <UserManagerDialog
          open={userMgrOpen}
          currentUsers={formattedUsers}
          onConfirm={this.onConfirmUsers}
          onCancel={this.onCancelUserManager}
          onUpdate={this.onUpdateUserManager}
          searchPath={`/employer/${employerID}/users`}
          resourceName="teamZERO"
        />
        <DialogAddTeamZeroUser
          open={dialogCreateTZUserOpen}
          employerID={employerID}
          onCancel={() => {
            this.setState({ dialogCreateTZUserOpen: false })
          }}
          onSuccess={this.onSuccessCreateTZUser}
        />
      </div>
    )
  }
}

TeamZeroUsersTable.defaultProps = {
  getTeamZeroUsers: () => {},
  tzUsers: [],
  count: 0,
  manageTeamZeroUsers: () => {},
  manageTeamZeroAdminUsers: () => {},
  sendTeamZeroPasswordEmail: () => {},
  searchError: null,
  employerID: 0,
}

function mapStateToProps(state: State) {
  return {
    tzUsers: state.employers.teamZeroUsers || [],
    count: state.employers.teamZeroUsers
      ? state.employers.teamZeroUsers.length
      : 0,
    searchError: state.employers.getTeamZeroUsersError || null,
  }
}

const {
  manageTeamZeroUsers,
  manageTeamZeroAdminUsers,
  sendTeamZeroPasswordEmail,
  getTeamZeroUsers,
} = EmployerActions
function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    {
      getTeamZeroUsers,
      manageTeamZeroUsers,
      manageTeamZeroAdminUsers,
      sendTeamZeroPasswordEmail,
      setSnackbarMessage,
    },
    dispatch
  )
}
// @ts-ignore
export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
  // @ts-ignore
)(TeamZeroUsersTable)
