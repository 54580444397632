import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core'
import history from '../../utils/history'
import React from 'react'
import ConfirmDialog from '../ConfirmDialog'
import useSnackbar, { SnackbarTypeSuccess } from '../../hooks/useSnackbar'
// @ts-ignore
import styled from 'styled-components'

const AccessSectionText = styled(Typography)`
  margin-top: 10px;
`
const ChangeAccessButton = styled(Button)`
  margin-top: 10px;
`

const GroupLink = styled(Typography)`
  cursor: pointer;
  color: #337ab7;
  margin-top: 10px;
`

export type EmployerAccessCardProps = {
  EntityID: number | null
  DefaultGoZeroAccessGroupID: number | null
  EntityCanAccessAllEmployers: boolean
  HandleChangeAccessLevel(): Promise<any>
}

export default function EmployerAccessCard({
  EntityID,
  DefaultGoZeroAccessGroupID,
  EntityCanAccessAllEmployers,
  HandleChangeAccessLevel,
}: EmployerAccessCardProps) {
  const { showForDuration: showSnackbarDuration } = useSnackbar()
  const curAccessDescr = EntityCanAccessAllEmployers
    ? 'access ALL employers'
    : 'access LIMITED employers'
  const requestedAccessDescr = !EntityCanAccessAllEmployers
    ? 'access ALL employers'
    : 'access LIMITED employers'

  function changeLevelDialog(): void {
    ConfirmDialog({
      confirmButtonText: 'Continue',
      content: (
        <div>
          <Typography>
            Changing <strong>{curAccessDescr}</strong> to{' '}
            <strong>{requestedAccessDescr}</strong>
          </Typography>
          <br />
          <Typography>
            This action will give access to{' '}
            {!EntityCanAccessAllEmployers
              ? 'ALL ZERO eligible members'
              : 'LIMITED ZERO eligible members'}
            .
          </Typography>
        </div>
      ),
      onConfirm(): void {
        HandleChangeAccessLevel().then(() =>
          showSnackbarDuration(
            `Access set to ${requestedAccessDescr}`,
            SnackbarTypeSuccess
          )
        )
      },
    })
  }

  return (
    <>
      <Card>
        <CardHeader title="Employer Access" />
        <CardContent>
          {EntityID ? (
            <>
              <AccessSectionText>
                Currently has access to{' '}
                <strong>
                  {EntityCanAccessAllEmployers
                    ? 'ALL EMPLOYERS'
                    : 'LIMITED EMPLOYERS'}
                </strong>
                .
              </AccessSectionText>
              {!EntityCanAccessAllEmployers ? (
                <AccessSectionText>
                  View and manage employers in the "Employers" tab below.
                </AccessSectionText>
              ) : null}
              <ChangeAccessButton
                variant="contained"
                color="secondary"
                onClick={changeLevelDialog}>
                Change Access Level
              </ChangeAccessButton>
              <GroupLink
                variant="body1"
                onClick={() =>
                  history.push(`/group/${DefaultGoZeroAccessGroupID}`)
                }>
                View the default access group
              </GroupLink>
            </>
          ) : (
            <AccessSectionText>
              Employer access can be set after initial save.
            </AccessSectionText>
          )}
        </CardContent>
      </Card>
    </>
  )
}
