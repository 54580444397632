import React from 'react'
import { get } from 'lodash'
import DetailView, {
  defaultStyleCreator,
} from '../../components/DetailView/DetailView'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import queryString from 'query-string'
import models from '../../models'
import { canExecEngineering } from '../../utils/perms'
import { UserGroupManager } from '../Users/UserGroupManager'
import * as GroupTable2 from '../Groups/GroupTable2'
import {
  SnackbarActions,
  UserActions,
  EngineeringActions,
  clearSaveResult,
} from '../../actions'
import { DialogChangeEmail } from './ChangeEmailDialog'
import OpenInNew from '@material-ui/icons/OpenInNew'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconEdit from '@material-ui/icons/Edit'
import { InfoOutlined } from '@material-ui/icons'
import {
  Avatar,
  Grid,
  InputLabel,
  Paper,
  Tabs,
  Tab,
  Switch,
  Button,
  Typography,
  CircularProgress,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import styled from 'styled-components'

const { setSnackbarMessage } = SnackbarActions
const {
  getUserByID,
  clearUser,
  getUserGroupMembership,
  saveUser,
  saveNewUser,
  resetUserState,
  sendVerificationEmail,
  sendChangePasswordRequest,
  deleteUser,
  getAuth0Config,
  saveToggleUserIsTest,
  changeUserEmail,
  offboardUser,
} = UserActions

const styles = (theme) => {
  return Object.assign(
    {
      root: {
        flexGrow: 1,
      },
      buttonGroup: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      buttonGroupItem: {
        marginTop: '5px',
      },
    },
    defaultStyleCreator(theme)
  )
}

const BigAvatar = withStyles({
  root: {
    width: 100,
    height: 100,
  },
})(Avatar)

const OffboardSpinner = styled(CircularProgress)`
  height: 20px !important;
  width: 20px !important;
`

const TooltipIcon = styled(InfoOutlined)`
  height: 16px;
  margin-top: -4px;
`

const optsTextField = {
  fullWidth: true,
  style: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
  },
}

export class UserDetail extends DetailView {
  constructor(props) {
    super(props)
    this.state = this.initialState = {
      saving: false,
      offBoarding: false,
      offBoardBlob: '',
      routeParamUserID: +get(props, 'match.params.id', 0) || null,
      tabValue: 0,
      manageGroupsOpen: false,
      changeEmailOpen: false,
      canDelete: canExecEngineering(),
      showConfig: canExecEngineering(),
      showAuth0User: canExecEngineering(),
      auth0Config: null,
      auth0UserInfo: null,
      canManage: true,
      availablePermissionGroups: [],
      permissionGroups: {},
      fields: Object.assign(
        {
          Groups: [], // canonical record of user's group memberships, kept updated by "watching" state.permissionGroups
        },
        models.User.editFields
      ), // most of the fields defaults come from here!
    }
    this.tabs = {
      permissionGroups: 0,
      accessGroups: 1,
      loading: 2,
    }
  }

  componentDidMount() {
    const { routeParamUserID } = this.state
    // load the user
    if (routeParamUserID > 0) {
      this.loadUser(routeParamUserID)
    }
    // load all available groups
    EngineeringActions.getPermissionGroups()
      .then((res) => {
        this.setState({
          availablePermissionGroups: get(res, 'Data', []),
        })
      })
      .catch((e) => {
        console.warn(e)
      })
    getAuth0Config()
      .then((res) => {
        this.setState({
          auth0Config: get(res, 'Data', []),
        })
      })
      .catch((e) => {
        console.warn(e)
      })
  }

  componentWillUnmount() {
    this.props.clearUser()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { history } = this.props
    const { location } = history
    const qsParams = queryString.parse(location.search)

    this.setState({ tabValue: +qsParams.active_tab || 0 })

    if (nextProps.saveResult) {
      this.setState({ saving: false })
      if (nextProps.saveResult.saved === true) {
        nextProps.clearSaveResult()
        this.props.setSnackbarMessage('User updated!', 'success')
        if (!this.state.routeParamUserID) {
          this.props.history.push(`/user/${nextProps.user.ID}`)
        }
        return
      }
      // Otherwise save result should be an error
      nextProps.clearSaveResult()
      this.props.setSnackbarMessage(nextProps.saveResult.message, 'error')
    }

    if (nextProps.sentEmail !== this.props.sentEmail) {
      if (nextProps.sentEmail) {
        this.props.setSnackbarMessage('Email sent!', 'success')
        return
      }

      if (nextProps.sentEmailError !== null) {
        this.props.setSnackbarMessage(nextProps.sentEmailError.message, 'error')
      }
    }
  }

  loadUser = (id) => {
    const { showAuth0User } = this.state
    this.props.getUserByID({ userID: id }).then((res) => {
      const data = get(res, 'payload.Data', {})
      if (id !== data.ID) {
        // If this ever happens, it means the user data returned by the API was
        // incorrect, so bail...
        this.props.setSnackbarMessage(
          'A serious error occurred, please contact engineering',
          'error'
        )
        return this.props.history.push('/users')
      }
      const { fields } = this.state

      this.setState({
        fields: {
          ...fields,
          ExtUsername: data.ExtUsername || fields.ExtUsername,
          FirstName: data.FirstName || fields.FirstName,
          LastName: data.LastName || fields.LastName,
          Email: data.Email || fields.Email,
          IsActive: data.IsActive,
          IsGoZero: data.IsGoZero,
          IsTest: data.IsTest,
          ZendeskUserID: data.ZendeskUserID || fields.ZendeskUserID,
        },
        canManage: data.MemberID === null,
      })

      showAuth0User &&
        EngineeringActions.getAuth0User({ auth0UserID: data.ExtID })
          .then((res) => {
            if (res.Error) {
              this.setState({
                canManage: false,
              })
            } else {
              this.setState({
                auth0UserInfo: get(res, 'Data', []),
              })
            }
          })
          .catch((e) => {
            console.warn(e)
          })

      // now load the user's group memberships
      getUserGroupMembership({ userID: data.ID }).then((res) => {
        const groupData = get(res, 'Data', []) || []

        const permissionGroups = groupData.reduce((acc, cur) => {
          acc[cur.id] = true
          return acc
        }, {})
        this.setState({
          permissionGroups,
          fields: {
            ...this.state.fields,
            Groups: Object.keys(permissionGroups),
          },
        })
      })
    })
  }

  doNavBackToUsers = () => {
    this.props.history.push('/users')
  }

  handleSave = () => {
    const { fields } = this.state
    const payload = Object.assign({}, fields)
    this.setState({ saving: true, showValidations: false })

    if (this.props.user.ID) {
      return this.props.saveUser({ userID: this.props.user.ID }, payload)
    }
    return this.props.saveNewUser(payload)
  }

  toggleChangeEmailDialog = (value) => {
    return () => {
      this.setState({
        changeEmailOpen: value,
      })
    }
  }

  handleChangeEmailRequest = ({ email }) => {
    this.props
      .changeUserEmail({
        userID: this.props.user.ID,
        newEmail: email,
      })
      .then(({ payload }) => {
        if (payload.error || payload.Error) {
          return this.props.setSnackbarMessage(
            `Problem updating email: ${payload.Error}`,
            'error'
          )
        }
        this.loadUser(this.props.user.ID)
      })
    this.setState({ changeEmailOpen: false })
  }

  handleDelete = () => {
    const { canDelete } = this.state
    const { user } = this.props
    const { ID } = user

    if (ID && canDelete) {
      this.setState({ deleting: true })
      this.props
        .deleteUser({ id: ID })
        .then((res) => {
          if (res && res.payload.error) {
            const { Error } = res.payload
            this.props.setSnackbarMessage(
              `Unable to delete user, it is likely in use somewhere. ${Error.Message}`,
              'error'
            )
            this.setState({ deleting: false })
            return
          }
          this.props.setSnackbarMessage('User deleted!', 'success')
          this.setState({ deleting: false })
          this.props.history.push('/users')
        })
        .catch((err) => {
          this.props.setSnackbarMessage(`${err}`, 'error')
          this.setState({ deleting: false })
        })
    }
  }

  handleAddNew = () => {
    this.props.history.push('/user')
    this.loadUser(this.props.user.ID)
  }

  handleGroupClick = (e, { ID }) => {
    if (e.nativeEvent && e.nativeEvent.target.name === 'view-link') {
      return
    }
    const redirectPath = `/group/${ID}`
    this.props.history.push({
      pathname: redirectPath,
      state: { prevSearch: this.props.location.search },
    })
  }

  reloadTabs = () => {
    const { tabValue } = this.state
    const { loading } = this.tabs
    this.setState({ tabValue: loading })
    setTimeout(() => {
      this.setState({ tabValue })
    }, 200)
  }

  handleManagedUpdate = () => {
    // force update of users table
    this.reloadTabs()
    this.setState({ manageGroupsOpen: false })
  }

  // we can manage all the dialogs closed state together
  handleDialogCancel = () => {
    this.setState({ manageGroupsOpen: false })
  }

  handleSendWelcomeEmail = (application) => {
    return () => {
      const { auth0Config } = this.state
      const { user } = this.props
      const clientID = auth0Config.Clients[application]

      sendVerificationEmail({ userID: user.ID, clientID })
        .then((res) => {
          this.props.setSnackbarMessage(`Email sent!`, 'success')
        })
        .catch((err) => {
          this.props.setSnackbarMessage(
            `There was a problem sending the welcome email: ${err}`,
            'error'
          )
        })
    }
  }

  handleSendPasswordRequest = (application) => {
    return () => {
      const { auth0Config } = this.state
      const { user } = this.props
      const clientID = auth0Config.Clients[application]

      sendChangePasswordRequest({ userID: user.ID, clientID })
        .then((res) => {
          this.props.setSnackbarMessage(`Email sent!`, 'success')
        })
        .catch((err) => {
          this.props.setSnackbarMessage(
            `There was a problem sending the password request: ${err}`,
            'error'
          )
        })
    }
  }

  // this handler works in a special way: notice that we're still invoking
  // handleSwitchChange - which is the default method called by the renderSwitchField
  // component to change the value in state; we're doing this so we can hijack it and
  // issue a direct API request, immediately, upon the value changing, instead of
  // sending the entire User payload to /update
  handleOnToggleIsTestFlag = (e) => {
    const { user } = this.props
    const { checked } = e.target
    this.handleSwitchChange('IsTest')(e)
    saveToggleUserIsTest({ UserID: user.ID, IsTest: checked }).then((res) => {
      if (res.Error) {
        console.error(res.errList)
        this.props.setSnackbarMessage(
          'Failed updating user is test flag; please notify engineering',
          'error'
        )
        return
      }
      this.props.setSnackbarMessage(
        `Updated user OK (marked as test account: ${checked ? 'true' : 'false'})`,
        'success'
      )
    })
  }

  renderAuth0Config = () => {
    const { auth0Config } = this.state
    if (!auth0Config) return <div />
    const tenant = auth0Config.Tenant
    const tenantShortName = tenant.split('.')[0]

    return (
      <div>
        <Typography>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://manage.auth0.com/dashboard/us/${tenantShortName}`}>
            <OpenInNew fontSize="small" /> Management Console
          </a>
        </Typography>
        {Object.keys(auth0Config).map((key) => {
          const data = auth0Config[key]
          if (typeof data === 'object') {
            return (
              <div key={key}>
                <Typography variant="body1">
                  <strong>{key}</strong>: <pre>{JSON.stringify(data)}</pre>
                </Typography>
              </div>
            )
          }

          return (
            <div key={key}>
              <Typography variant="body1">
                <strong>{key}:</strong> {`${auth0Config[key]}`}
              </Typography>
            </div>
          )
        })}
      </div>
    )
  }

  renderAuth0UserInfo = () => {
    const { auth0UserInfo } = this.state
    if (!auth0UserInfo) return <div />
    const { EmailVerified, Email, UserID, LastLogin } = auth0UserInfo
    const verified = EmailVerified ? 'true' : 'false'

    return (
      <div>
        <Typography variant="body1">
          <strong>UserID</strong>: {UserID}
        </Typography>
        <Typography variant="body1">
          <strong>Email</strong>: {Email}
        </Typography>
        <Typography variant="body1">
          <strong>Verified</strong>: {verified}
        </Typography>
        <Typography variant="body1">
          <strong>Last Login</strong>: {LastLogin}
        </Typography>

        <Accordion style={{ marginTop: 10 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="user-info-details">
            <Typography>Full Details</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: 'block' }}>
            {Object.keys(auth0UserInfo).map((key) => {
              const data = auth0UserInfo[key]
              if (typeof data === 'object') {
                return (
                  <div key={key}>
                    <Typography variant="body1">
                      <strong>{key}</strong>: <pre>{JSON.stringify(data)}</pre>
                    </Typography>
                  </div>
                )
              }

              return (
                <div key={key}>
                  <Typography variant="body1">
                    <strong>{key}:</strong> {`${auth0UserInfo[key]}`}
                  </Typography>
                </div>
              )
            })}
          </AccordionDetails>
        </Accordion>
      </div>
    )
  }

  renderTabs() {
    const { user } = this.props
    return (
      <div>
        <Tabs
          value={this.state.tabValue}
          onChange={this.handleTabChange}
          indicatorColor="primary"
          textColor="inherit">
          <Tab value={this.tabs.permissionGroups} label="Permission Groups" />
          {user.ID && (
            <Tab value={this.tabs.accessGroups} label="Access Groups" />
          )}
        </Tabs>
        <div className={this.props.classes.childContainer}>
          {this.renderTabContent()}
        </div>
      </div>
    )
  }

  renderTabContent() {
    const { classes, user } = this.props
    const { tabValue } = this.state

    if (tabValue === this.tabs.permissionGroups) {
      return this.renderPermissionGroups()
    } else if (tabValue === this.tabs.accessGroups) {
      const { ID } = user
      return (
        <div>
          {!!ID && ( // async issue; ID isn't always ready yet and there aren't any other 'isLoaded' type checks
            <GroupTable2.Table
              passFilters={{ userIds: [ID] }}
              LeftHeaderItems={
                <>
                  <GroupTable2.StandardFilterSearch />
                  &nbsp;&nbsp;
                  <GroupTable2.FilterOrganization />
                  &nbsp;&nbsp;
                  <GroupTable2.FilterPracticeFacility />
                </>
              }
              RightHeaderItems={
                <>
                  <Button
                    style={{ float: 'right', marginTop: 15 }}
                    onClick={() => {
                      this.setState({ manageGroupsOpen: true })
                    }}
                    className={classes.manageGroups}
                    color="primary"
                    variant="contained">
                    Manage Groups
                  </Button>
                </>
              }
            />
          )}
        </div>
      )
    }
  }

  handleOffboardUser = () => {
    const { user } = this.props
    if (!user.ID) return
    this.setState({ offBoarding: true })
    this.props
      .offboardUser(user.ID)
      .then(({ payload }) => {
        if (payload.error || payload.Error) {
          return this.props.setSnackbarMessage(
            `Problem offboarding user: ${payload.Error}`,
            'error'
          )
        }
        this.setState({ offBoardBlob: JSON.stringify(payload.Data, null, 2) })
        this.loadUser(user.ID)
        this.props.setSnackbarMessage(
          'User Offboarded Successfully!',
          'success'
        )
      })
      .finally(() => this.setState({ offBoarding: false }))
  }

  renderOffboardSuccessModal = () => {
    const { offBoardBlob } = this.state
    return (
      <Dialog
        classes={{ paper: 'dialogWide' }}
        open={!!offBoardBlob}
        onClose={() => this.setState({ offBoardBlob: '' })}>
        <DialogTitle>Offboard Success</DialogTitle>
        <DialogContent>
          <pre>{offBoardBlob}</pre>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => this.setState({ offBoardBlob: '' })}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  render() {
    const { user, classes, groups } = this.props
    const {
      manageGroupsOpen,
      auth0Config,
      showConfig,
      showAuth0User,
      auth0UserInfo,
      canManage,
      offBoarding,
    } = this.state
    const editingUser = +user.ID > 0
    const encodedEmail = encodeURIComponent(user.Email)

    return (
      <div className={classes.root} id="user-detail">
        <Paper className={classes.mainContent} elevation={2}>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="flex-start">
            <Grid item xs={12}>
              <Button
                onClick={this.doNavBackToUsers}
                color="primary"
                variant="outlined"
                className={classes.button}>{`< Back To Users`}</Button>
            </Grid>
            {editingUser ? (
              <Grid
                container
                spacing={3}
                direction="row"
                justify="flex-start"
                alignItems="center"
                style={{ padding: 12 }}>
                <Grid item style={{ textAlign: 'center' }}>
                  <BigAvatar alt="avatar" src={user.Avatar} />
                </Grid>
                <Grid item style={{ textAlign: 'left' }}>
                  <Typography variant="h3">
                    {user.FirstName} {user.LastName} - {user.Email}
                  </Typography>
                  <Typography variant="body1">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://app.sigmacomputing.com/zero-health/workbook/Engineering-Reporting-33mGcqRXC9NdWp0hSvD5jN?user_profile_email=${encodedEmail}&:nodeId=p3jFAmv8la5jnzXVKMSlw`}>
                      <OpenInNew size="small" /> Investigate in Sigma
                    </a>
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
            {showConfig ? (
              <Grid item xs={6}>
                <Card>
                  <CardHeader
                    title="Auth0 Configuration"
                    subheader="Details on what APIs and Clients are being accessed on this page"
                  />
                  <CardContent style={{ paddingTop: 0 }}>
                    {auth0Config ? (
                      this.renderAuth0Config()
                    ) : (
                      <CircularProgress />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {showAuth0User && editingUser ? (
              <Grid item xs={6}>
                <Card>
                  <CardHeader
                    title="Auth0 User Info"
                    subheader="Details on the Auth0 User associated with this User"
                  />
                  <CardContent style={{ paddingTop: 0 }}>
                    {auth0UserInfo ? (
                      this.renderAuth0UserInfo()
                    ) : (
                      <CircularProgress />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ) : null}
            {editingUser ? (
              <Grid item xs={12}>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start">
                  <Grid item sm={4}>
                    <Card>
                      <CardHeader
                        title="goZERO"
                        subheader="Use this to manage users who will primarily use the goZERO app"
                      />
                      <CardContent
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.handleSendPasswordRequest('GOZERO')}>
                          Send password email
                        </Button>
                        <Button
                          variant="contained"
                          onClick={this.handleSendWelcomeEmail('GOZERO')}>
                          Send re-invite email
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={4}>
                    <Card>
                      <CardHeader
                        title="teamZERO"
                        subheader="Use this to manage users who will primarily use the teamZERO app"
                      />
                      <CardContent
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.handleSendPasswordRequest('TEAMZERO')}>
                          Send password email
                        </Button>
                        <Button
                          variant="contained"
                          onClick={this.handleSendWelcomeEmail('TEAMZERO')}>
                          Send re-invite email
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={4}>
                    <Card>
                      <CardHeader
                        title="Admin"
                        subheader="Use this to manage users who will primarily use the Admin app"
                      />
                      <CardContent
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={this.handleSendPasswordRequest('ADMIN')}>
                          Send password email
                        </Button>
                        <Button
                          variant="contained"
                          onClick={this.handleSendWelcomeEmail('ADMIN')}>
                          Send re-invite email
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
            <Grid item>
              {!canManage ? (
                <Alert severity="error">
                  <AlertTitle>Cannot manage user</AlertTitle>
                  This user is either a myZERO user or cannot be found in the
                  current tenant. You cannot currently manage this user in the
                  admin application. Please contact engineering if you have any
                  concerns.
                </Alert>
              ) : null}
              <Card style={{ marginTop: '10px' }}>
                <CardHeader
                  title="User Information"
                  subheader="Set the user properties and group memberships"
                />
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start">
                    <Grid item sm={3} xs={12}>
                      {this.renderTextField(
                        'ExtUsername',
                        'Username',
                        optsTextField
                      )}
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      {this.renderTextField(
                        'FirstName',
                        'First Name',
                        optsTextField
                      )}
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      {this.renderTextField(
                        'LastName',
                        'Last Name',
                        optsTextField
                      )}
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      {this.renderTextField(
                        'ZendeskUserID',
                        'Zendesk User ID',
                        optsTextField
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      {this.renderTextField('Email', 'Email', {
                        ...optsTextField,
                        disabled: editingUser,
                      })}
                      {editingUser ? (
                        <small>
                          Existing user emails cannot be updated here, use the
                          button below. If you do not see a button below,
                          contact engineering for access.
                        </small>
                      ) : null}
                    </Grid>
                    {editingUser ? (
                      <Grid container alignItems="center" direction="row">
                        <Button
                          variant="outlined"
                          color="primary"
                          disabled={!models.User.canUpdate()}
                          onClick={() =>
                            this.setState({ changeEmailOpen: true })
                          }
                          className={classes.button}>
                          <IconEdit style={{ marginRight: 3 }} /> Change Email
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          disabled={
                            !models.User.canOffboard() || !!user.OffboardedAt
                          }
                          onClick={this.handleOffboardUser}
                          className={classes.button}>
                          {offBoarding ? <OffboardSpinner /> : `Offboard User`}
                        </Button>
                        {user.OffboardedAt ? (
                          <Typography>
                            Offboarded At: {user.OffboardedAt}
                          </Typography>
                        ) : null}
                      </Grid>
                    ) : null}
                    <Grid item sm={1} xs={12}>
                      <InputLabel shrink>
                        Active
                        <Tooltip title="Setting to inactive will disable the user in Auth0">
                          <TooltipIcon />
                        </Tooltip>
                      </InputLabel>
                      <div>
                        {this.renderSwitchField(
                          'IsActive',
                          this.state.fields.IsActive ? 'Yes' : 'No'
                        )}
                      </div>
                    </Grid>
                    <Grid item sm={1} xs={12}>
                      <InputLabel shrink>Go Zero</InputLabel>
                      <div>
                        {this.renderSwitchField(
                          'IsGoZero',
                          this.state.fields.IsGoZero ? 'Yes' : 'No'
                        )}
                      </div>
                    </Grid>
                    <Grid item sm={2} xs={12}>
                      <InputLabel shrink>Is Test User</InputLabel>
                      <div>
                        <Tooltip title="Changing this will update right away; you do not need to hit the Save button below">
                          {this.renderSwitchField(
                            'IsTest',
                            this.state.fields.IsTest ? 'Yes' : 'No',
                            { onChange: this.handleOnToggleIsTestFlag }
                          )}
                        </Tooltip>
                      </div>
                    </Grid>
                    <Grid item sm={12}>
                      {this.renderTabs()}
                    </Grid>
                    <Divider />
                    {canManage ? (
                      <>
                        <Grid item sm={4} style={{ textAlign: 'center' }}>
                          <div
                            style={{
                              margin: '0 auto',
                              width: '380px',
                              display: 'inline-block',
                            }}>
                            <Button
                              size="large"
                              fullWidth
                              disabled={
                                !editingUser || !models.User.canUpdate()
                              }
                              variant="outlined"
                              color="primary"
                              className={classes.button}
                              onClick={this.handleAddNew}>
                              Add Another User
                            </Button>
                          </div>
                        </Grid>
                        <Grid item sm={4} style={{ textAlign: 'center' }}>
                          {this.state.saving ? (
                            <CircularProgress className={classes.progress} />
                          ) : (
                            <div
                              style={{
                                margin: '0 auto',
                                width: '380px',
                                display: 'inline-block',
                              }}>
                              <Button
                                size="large"
                                fullWidth
                                color="primary"
                                disabled={!models.User.canUpdate()}
                                variant="contained"
                                className={classes.button}
                                onClick={this.handleSave}>
                                Save
                              </Button>
                            </div>
                          )}
                        </Grid>
                        <Grid item sm={4} style={{ textAlign: 'center' }}>
                          {this.state.canDelete && this.state.deleting ? (
                            <CircularProgress className={classes.progress} />
                          ) : (
                            <div
                              style={{
                                margin: '0 auto',
                                width: '380px',
                                display: 'inline-block',
                              }}>
                              <Button
                                size="large"
                                fullWidth
                                color="secondary"
                                variant="outlined"
                                disabled={!models.User.canUpdate()}
                                className={classes.button}
                                onClick={this.handleDelete}>
                                Delete
                              </Button>
                            </div>
                          )}
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </CardContent>
              </Card>
              <UserGroupManager
                userId={user.ID}
                open={manageGroupsOpen}
                currentGroups={groups}
                onConfirm={this.handleManagedUpdate}
                onCancel={this.handleDialogCancel}
              />
              <DialogChangeEmail
                handleSave={this.handleChangeEmailRequest}
                handleClose={this.toggleChangeEmailDialog(false)}
                open={this.state.changeEmailOpen}
                currentEmail={this.state.fields.Email}
              />
            </Grid>
          </Grid>
        </Paper>
        {this.renderOffboardSuccessModal()}
      </div>
    )
  }

  renderPermissionGroups = () => {
    const { permissionGroups } = this.state

    return this.state.availablePermissionGroups.map((g) => {
      const isChecked = !!permissionGroups[g.id]
      return (
        <Grid item xs={12} sm={4} md={6} key={g.id}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Switch
                checked={isChecked}
                value={g.id}
                onChange={this.handleToggleGroup(g.id)}
              />
            </Grid>
            <Grid item xs={10}>
              <span style={{ display: 'block' }}>{g.name}</span>
              <small>{g.description}</small>
            </Grid>
          </Grid>
        </Grid>
      )
    })
  }

  handleToggleGroup = (name) => (event) => {
    const permissionGroups = Object.assign({}, this.state.permissionGroups, {
      [name]: event.target.checked,
    })
    this.setState({
      permissionGroups,
      fields: {
        ...this.state.fields,
        Groups: Object.keys(permissionGroups).filter((v) => {
          return permissionGroups[v] === true
        }),
      },
    })
  }
}

function mapStateToProps(state) {
  return {
    group: state.groups.groups || {},
    user: state.users.user || {},
    getUserErr: state.users.getUserErr,
    sentEmail: state.users.sentEmail,
    sentEmailError: state.users.sentEmailError,
    // saveError: state.users.saveError
    saveResult: state.users.saveResult,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserByID,
      clearUser,
      setSnackbarMessage,
      saveUser,
      saveNewUser,
      clearSaveResult,
      resetUserState,
      sendVerificationEmail,
      deleteUser,
      changeUserEmail,
      offboardUser,
    },
    dispatch
  )
}

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(UserDetail)
