import React from 'react'
import { Paper, Grid, Button } from '@material-ui/core'
import ReferrerTableList from './referrerTableList'

export default function Referrers({ ...props }: any): any {
  const handleAddNew = () => {
    props.history && props.history.push('/referrer')
  }

  return (
    <div>
      <Paper elevation={2} style={{ padding: '2rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ReferrerTableList
              RightHeaderItems={
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddNew}>
                    Add New
                  </Button>
                </>
              }
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  )
}
